import { Plugin, PluginKey } from "prosemirror-state";
import { Extension } from "../core/extension";
import { Image } from "./image";
import { createLink, mapSlice, matchAllPlus, updateLink } from "../utils/link";
import { generateYoutubeEmbedSrc, prependHTTPS } from "../utils/regex";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { _findSelectedNodeOfType } from "../utils/prosemirror";

export const LINK_TOOLTIP_PLUGIN_KEY = new PluginKey('link_tooltip')

/**
 * 참고: https://prosemirror.net/examples/tooltip/
 *      https://discuss.prosemirror.net/t/edit-and-update-link/3785
 */
class LinkTooltipPluginView {
  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   */
  constructor(view) {
    this.view = view
    this.CSS = 'link-input-dialog'

    this.tooltip = document.createElement('div')
    this.tooltip.classList.add(this.CSS)
    this.tooltip.style.display = 'none'

    this.form = document.createElement('form')
    this.form.classList.add(`${this.CSS}__form`)
    this.form.addEventListener('submit', this._onSubmit.bind(this))

    this.input = document.createElement('input')
    this.input.classList.add(`${this.CSS}__input`)
    this.input.type = 'text'
    this.input.placeholder = 'https://www.example.com'

    this.button = document.createElement('button')
    this.button.classList.add(`${this.CSS}__button`)
    this.button.type = 'submit'
    this.button.textContent = '적용'

    this.form.appendChild(this.input)
    this.form.appendChild(this.button)
    this.tooltip.appendChild(this.form)
    view.dom.parentNode.appendChild(this.tooltip)

    this.__hideTooltip = this._hideTooltip.bind(this)

    document.addEventListener('mousedown', this.__hideTooltip)
  }

  /**
   *
   * @param {SubmitEvent} event
   */
  _onSubmit(event) {
    event.preventDefault()

    const url = prependHTTPS(this.input.value.trim())

    updateLink(url)(this.view.state, this.view.dispatch)
    this.tooltip.style.display = 'none'
  }

  _showTooltip() {
    const state = this.view.state
    const view = this.view
    const imageNode = _findSelectedNodeOfType(this.view.state.schema.nodes[Image.name])(
      state.selection,
    )
    let href = ''

    if (imageNode && imageNode.node.marks[0]?.type.name === Link.name) {
      const linkMark = imageNode.node.marks[0]
      href = linkMark.attrs.href || ''
    } else {
      const linkMark = state.selection.$anchor
        .marks()
        .find(mark => mark.type.name === this.view.state.schema.marks[Link.name].name)
      href = linkMark?.attrs.href || ''
    }

    this.tooltip.style.display = 'flex'

    const {from, to} = state.selection
    // These are in screen coordinates
    const start = view.coordsAtPos(from)
    const end = view.coordsAtPos(to)
    // The box in which the tooltip is positioned, to use as base
    const box = this.tooltip.offsetParent.getBoundingClientRect()
    // Find a center-ish x position from the selection endpoints (when
    // crossing lines, end may be more to the left)
    const left = Math.max((start.left + end.left) / 2, start.left + 3)

    this.tooltip.style.left = left - box.left + 'px'
    this.tooltip.style.bottom = box.bottom - start.top + 'px'
    this.input.value = href
  }

  /**
   *
   * @param {MouseEvent} event
   */
  _hideTooltip(event) {
    event.stopPropagation()
    if (event.target.closest(`.${this.CSS}`)) return
    this.tooltip.style.display = 'none'
  }

  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   * @param {import('prosemirror-state').EditorState} lastState
   * @returns
   */
  update(view, lastState) {
    if (LINK_TOOLTIP_PLUGIN_KEY.getState(view.state) === 'open') {
      this._showTooltip()
      return
    }

    // Don't do anything if the document/selection didn't change
    if (
      lastState &&
      lastState.doc.eq(view.state.doc) &&
      lastState.selection.eq(view.state.selection)
    )
      return

    const state = view.state
    const imageNode = _findSelectedNodeOfType(view.state.schema.nodes[Image.name])(
      state.selection,
    )

    if (imageNode) {
      if (imageNode.node.marks[0]?.type.name === 'link') {
        this._showTooltip()
        return
      }
    }

    const isSelectionFromLink = view.state.schema.marks[Link.name].isInSet(
      state.selection.$anchor.marks(),
    )

    /**
     * Selection 의 시작점이 링크인 경우에만 툴팁을 보여줍니다.
     */
    if (isSelectionFromLink) {
      this._showTooltip()
      return
    }

    this.tooltip.style.display = 'none'
    this.input.value = ''
  }

  destroy() {
    this.tooltip.remove()
    document.removeEventListener('mousedown', this.__hideTooltip)
  }
}

const linkTooltip = new Plugin({
  key: LINK_TOOLTIP_PLUGIN_KEY,
  state: {
    init() {
      return undefined // or 'open'
    },
    apply(tr) {
      const meta = tr.getMeta(this)
      if (meta) {
        return meta
      }
    },
  },
  view(editorView) {
    return new LinkTooltipPluginView(editorView)
  },
})

const pasteLink = (regexp) => {
  return new Plugin({
    props: {
      handlePaste: function handlePastedLink(view, rawEvent, slice) {
        if (!rawEvent.clipboardData) {
          return false
        }

        const text = rawEvent.clipboardData.getData('text/plain')
        const html = rawEvent.clipboardData.getData('text/html')
        const isPlainText = text && !html
        const isYoutubeLink =
          (text.startsWith('https://youtu.be') ||
            text.startsWith('https://www.youtube.com/watch?')) &&
          !text.includes(' ')

        // 유튜브 링크를 붙여넣을 경우 iframe 노드를 생성합니다.
        if (isYoutubeLink) {
          const tr = view.state.tr
          const {from} = view.state.selection
          const {url, videoId} = generateYoutubeEmbedSrc(text)
          const iframeNode = view.state.schema.nodes.iframe.create({ // TODO: nodes[Iframe.name]
            src: url,
            'data-platform': 'youtube',
            'data-youtube-id': videoId
          })
          tr.insert(from, iframeNode)
          view.dispatch(tr)
          return false
        }

        if (!isPlainText || view.state.selection.empty) {
          return false
        }

        const {state, dispatch} = view
        const match = matchAllPlus(regexp, text)
        const singleMatch = match.length === 1 && match.every(m => m.match)

        // Only handle if paste has one URL
        if (!singleMatch) {
          return false
        }

        return createLink(text)(state, dispatch)
      },
    },
  })
}

const markPasteRule = (regexp, type, getAttrs) => {
  return new Plugin({
    props: {
      transformPasted: function transformPasted(slice) {
        return mapSlice(slice, node => {
          if (!node.isText) {
            return node
          }
          const text = node.text
          const matches = matchAllPlus(regexp, text)
          return matches.map(({start, end, match, subString}) => {
            let newNode = node.cut(start, end)
            if (match) {
              var attrs =
                getAttrs instanceof Function ? getAttrs(subString) : getAttrs
              newNode = newNode.mark(type.create(attrs).addToSet(node.marks))
            }
            return newNode
          })
        })
      },
    },
  })
}

export const Link = Extension.Create({
  name: 'link',

  type: 'mark',

  defineSpec() {
    return {
      attrs: {
        href: {default: null},
        download: {default: null},
        target: {default: null},
        style: {default: null},
      },
      inclusive: false,
      toDOM: node => [
        'a',
        {
          ...node.attrs,
          class: MEDISTREAM_SCHEMA_STYLE.marks.link,
          rel: 'noopener noreferrer nofollow',
        },
        0,
      ],
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: dom => ({
            href: dom.getAttribute('href'),
            download: dom.getAttribute('download'),
            // target: dom.getAttribute('target'), // 운영상의 이유로 target 을 제거합니다.
            // style: dom.getAttribute('style'),  // 스타일 통합을 위해 style 을 제거합니다.
          }),
        },
      ],
    }
  },
    
  addCommands() {
    return {
      createLink,
      updateLink,
    }
  },

  addPlugins() {
    return [
      linkTooltip,
      pasteLink(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      ),
      markPasteRule(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
        this.schema.marks[this.name],
        match => ({ href: match }),
      ),
    ]
  },
})