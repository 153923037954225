import { useUserInfo } from "api/mypage/hooks";
import { useUserBadgesQuery } from "api/user/hooks";
import SideMenu from "component/mypage/SideMenu";
import Popup from "component/Popup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { cls } from "utils/util";

const MyImplant = () => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);
  const [alert, setAlert] = useState(false);
  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();
  const { data: badges, isLoading: isBadgesLoading } = useUserBadgesQuery(token?.uid);

  const onClick = () => {
    if (userInfo && userInfo.is_owner) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).ChannelIO("openChat");
    } else {
      setAlert(true);
    }
  };

  if (isUserInfoLoading || isBadgesLoading) return <></>;

  const implantBadges = badges
    ?.filter((badge) => badge.name.includes("임플란트"))
    .sort((a, b) => a.sort - b.sort);

  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="myImplant" />
            <div className="con__layout">
              <section className="my__home" style={{ marginBottom: "76.5px" }}>
                <article className="section__tit__box" style={{ marginBottom: "23px" }}>
                  <div className="tit-box">
                    <span className="tit-txt">임플란트 수가 인증</span>
                  </div>
                </article>
                {implantBadges && implantBadges.length > 0 ? (
                  <StatusMessage
                    icons={[implantBadges[0].imgUrl]}
                    title={
                      <div className="flex items-center gap-2">
                        <div>
                          <span className="text-primary-800">{implantBadges[0].description}</span>{" "}
                          인증 완료
                        </div>
                        <button
                          onClick={onClick}
                          className="text-xs border-[1px] border-solid border-secondary-400 rounded text-secondary-600 p-1.5 py-0.5 flex items-center"
                        >
                          <i className="ii ii-refresh text-secondary-600 mr-1 text-sm"></i>
                          재인증하기
                        </button>
                      </div>
                    }
                    button={
                      <button
                        disabled
                        className="bg-primary-800 hover:bg-primary-900 text-white font-bold text-sm rounded w-full lg:w-[144px] h-[44px] ml-auto disabled:opacity-50"
                      >
                        보유중
                      </button>
                    }
                  />
                ) : (
                  <StatusMessage
                    icons={[
                      "/img/implant60.svg",
                      "/img/implant100.svg",
                      "/img/implant125.svg",
                      "/img/implant150.svg",
                    ]}
                    title={<>임플란트 수가를 인증하고 뱃지를 획득하세요.</>}
                    description={
                      <>원내 가격 상담표 / 덴트웹 청구 비용/ 그에 준하는 자료만 있으면 인증 가능!</>
                    }
                    button={
                      <button
                        onClick={onClick}
                        className="bg-primary-800 hover:bg-primary-900 text-white font-bold text-sm rounded w-full lg:w-[144px] h-[44px] ml-auto"
                      >
                        수가 인증하기
                      </button>
                    }
                  />
                )}
                <div>
                  <Description />
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      {alert && (
        <Popup
          setPopup={setAlert}
          title="알림"
          setConfirm={() => {
            navigate("/mypage/myhospital");
          }}
          confirmBtn="인증하기"
        >
          <div>
            개원의 사업자정보 등록 승인 후<br />
            임플란트 뱃지 등록이 가능합니다.
          </div>
        </Popup>
      )}
    </div>
  );
};

export default MyImplant;

const StatusMessage = ({
  icons,
  title,
  description,
  button,
}: {
  title: JSX.Element;
  description?: JSX.Element;
  icons: string[];
  button?: JSX.Element;
}) => {
  return (
    <div
      className={cls(
        "flex flex-col lg:flex-row items-center self-stretch rounded-md mb-4",
        "gap-5 p-4 bg-primary-50",
      )}
    >
      <div>
        {icons && icons.length === 1 ? (
          <img className="w-[75px]" src={icons[0]} alt="col-span-4" />
        ) : (
          <div className="lg:w-[75px] grid grid-cols-4 gap-2">
            {icons.map((icon) => (
              <img key={icon} className="lg:col-span-2 col-span-1" src={icon} alt="" />
            ))}
          </div>
        )}
      </div>
      <div className="font-PretendardVariable text-base">
        <div className="font-semibold text-[14px] mb-2">{title}</div>
        <div className="text-[13px] mb-2">{description}</div>
      </div>
      {button}
    </div>
  );
};

const Description = () => {
  const contents = [
    <>
      [수가 인증하기] 버튼을 눌러 자료를 첨부해 주세요. <br />{" "}
      <span className="text-primary-800">
        "원내 가격 상담표" or "덴트웹 청구 비용" or "그에 준하는 자료"
      </span>{" "}
      중 최소 1개 이상 첨부를 해주세요. (중복 첨부 가능)
    </>,
    <>
      뱃지는 "국산 임플란트 중 가장 낮은 수가" 기준으로 60만원 이상부터 부여됩니다. 단, 골이식,
      가이드 수술 등 특수 케이스는 제외됩니다.
    </>,
    <>
      첨부 자료 검토와 함께, 요양기관 정보마당 신고자료 비급여 신고 등 여러 인증 과정을 확인한 후,
      뱃지가 부여됩니다.
    </>,
    <>검토에는 영업일 기준 최대 3일이 소요될 수 있습니다.</>,
  ];

  return (
    <div className="mt-4">
      <div className="text-sm font-semibold mb-2 flex items-center gap-1">
        <i className="ii ii-info-circle-outline text-lg"></i>인증방법
      </div>
      {contents.map((content, idx) => (
        <ol key={idx} className="text-[14px] text-secondary-600">
          <li className="flex gap-1">
            <div>{idx + 1}.</div>
            <div>{content}</div>
          </li>
        </ol>
      ))}
    </div>
  );
};
