/**
 * @typedef {{
 *  editor: import('./editor').IntegrationEditor
 *  transaction: import('prosemirror-state').Transaction
 * }} OnTransactionParams
 */

export class EventEmitter {
  constructor() {
    this.callbacks = {};
  }

  /**
   * 현재는 transaction 이벤트만 사용하고 있습니다.
   * 추후에 다른 이벤트가 추가된다면 (예: mount, focus 등) 이곳에 overload 타입을 추가해주세요.
   * 
   * @overload
   * @param {'transaction'} event
   * @param {(fn: OnTransactionParams) => void} callback
   * 
   * @overload
   * @param {'autosave'} event
   * @param {() => void} callback
   * 
   * @overload
   * @param {string} event
   * @param {() => void} callback
   */
  on(event, callback) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    
    this.callbacks[event].push(callback);
  }

  /**
   * 
   * @param {string} event 
   * @param  {...any} args 
   */
  emit(event, ...args) {
    const callbacks = this.callbacks[event];
    
    if (callbacks) {
      callbacks.forEach(callback => callback.apply(this, args));
    }
    
    return this;
  }

  removeAllListeners() {
    this.callbacks = {};
  }
}
