import { cls, api } from "../../utils/util";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import CommentItem from "./CommentItem";

const QnaComment = ({
  item, //댓글정보
  isBestComment, //베스트댓글
  getLikeInfo, //좋아요기능
  likeClicked, //좋아요기능
  setDeleteUUID, //삭제기능
  setNewReply, //수정, 대댓글기능
  reportComment, //신고기능
  commentBoxRef, //댓글창 스크롤
  highlightedCid, //댓글창 스크롤
  isMyContent, //내가 쓴 글인지
  article_num,
}) => {
  const token = useRecoilValue(tokenState);
  const isReply = item.cid ? true : false;
  const queryClient = useQueryClient();
  const modifyComment = () => {
    setNewReply({ ...item, image: item.img_link });
  };

  const writerSelect = useMutation((data) => api.post(`/qna/writer_select/${article_num}`, data), {
    onSuccess: () => queryClient.invalidateQueries(["qnaArticle", article_num]),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const adminSelect = useMutation((data) => api.post(`/qna/admin_select/${article_num}`, data), {
    onSuccess: () => queryClient.invalidateQueries(["qnaArticle", article_num]),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const answerWriterSelect = async (uuid) => {
    if (window.confirm("채택하시겠습니까? 채택 후에는 변경할 수 없습니다")) {
      const data = {
        cid: uuid,
      };
      writerSelect.mutate(data);
    }
  };

  const answerAdminSelect = async (uuid) => {
    if (window.confirm("관리자 - 채택하시겠습니까?")) {
      const data = {
        cid: uuid,
      };
      adminSelect.mutate(data);
    }
  };
  return (
    <div
      className={cls(
        item.writer_selected_at
          ? "bg-[#ebffff]/50"
          : item.admin_selected_at
          ? "bg-[#edebff]/50"
          : isReply
          ? "bg-gray-200/40"
          : "",
      )}
      ref={highlightedCid === item.uuid ? commentBoxRef : null}
    >
      <CommentItem
        item={item}
        setNewReply={setNewReply}
        getLikeInfo={getLikeInfo}
        likeClicked={likeClicked}
        isBestComment={isBestComment}
      >
        {token.user_id === "admin" && !isReply && !item.admin_selected_at && (
          <button
            className="text-[13px] bg-white border-[1px] border-solid border-gray-300 rounded-sm px-2 tracking-tighter h-[23px] mr-3"
            onClick={() => answerAdminSelect(item.uuid)}
          >
            <i className="ii ii-medal-outline relative top-[-1px] mr-1" />
            관리자 선정
          </button>
        )}
        {isMyContent && !item.writer_selected_at && !item.ismine && (
          <button
            className="text-[13px] bg-white border-[1px] border-solid border-gray-300 rounded-sm px-2 tracking-tighter h-[23px]"
            onClick={() => answerWriterSelect(item.uuid)}
          >
            <i className="ii ii-trophy-outline relative top-[-1px] mr-1" />
            답변 선정
          </button>
        )}
        {item.ismine ? (
          <div className="ml-auto">
            <button className="text-[13px] text-gray-600 mr-10" onClick={() => modifyComment(item)}>
              수정
            </button>
            <button className="text-[13px] text-gray-600" onClick={() => setDeleteUUID(item.uuid)}>
              삭제
            </button>
          </div>
        ) : (
          <button className="text-[13px] text-gray-600 ml-auto" onClick={() => reportComment(item)}>
            <i className="ii ii-alarm-light-outline text-gray-600 text-[16px] mr-1 relative top-[-1px]" />
            신고
          </button>
        )}
      </CommentItem>
    </div>
  );
};

export default QnaComment;
