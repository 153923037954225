import { StaticImg } from "../../utils/util";

const NoItem = ({ title, content }: { title: string; content: string }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={StaticImg("klass/order/complete_icon.svg")} alt="img" className="mb-5" />
      <div className="text-xl font-semibold mb-3">{title}</div>
      <div className="text-sm text-gray-500 text-center">{content}</div>
    </div>
  );
};

export default NoItem;
