import { CellSelection } from "@medistream/prosemirror-tables";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { Heading } from "./heading";
import { Paragraph } from "./paragraph";

/**
 * @type {(fn: string) => import('prosemirror-state').Command}
 */
const setTextColor = (color) => (state, dispatch) => {
  const tr = state.tr
  const selection = tr.selection
  const {from, to} = state.selection

  if (selection instanceof CellSelection) {
    const heading = state.schema.nodes[Heading.name]
    const paragraph = state.schema.nodes[Paragraph.name]
    const tasks = []
    const allowedNodeTypes = new Set([heading, paragraph])

    selection.forEachCell((cell, pos) => {
      cell.content.forEach((node, offset) => {
        const resolvedNode = state.doc.resolve(pos + offset + 1).nodeAfter
        if (allowedNodeTypes.has(node.type)) {
          tasks.push({
            pos: pos + offset + 1,
            posEnd: pos + offset + 1 + resolvedNode.nodeSize,
          })
        }
      })
    })

    if (!tasks.length) return false

    tasks.forEach(job => {
      const {pos, posEnd} = job

      color === 'reset-style'
      ? tr.removeMark(pos, posEnd, state.schema.marks[TextColor.name])
      : tr.addMark(pos, posEnd, state.schema.marks[TextColor.name].create({style: `color: ${color};`}))
    })
    
    dispatch(tr)
    return true
  }
  
  if (color === 'reset-style') {
    tr.removeMark(from, to, state.schema.marks[TextColor.name])

    return dispatch(tr)
  }
  
  tr.addMark(from, to, state.schema.marks[TextColor.name].create({style: `color: ${color};`}))

  dispatch(tr)
  return true
}

/**
 * 글자의 색상은 span 태그의 color 속성으로 표현됩니다.
 * 
 * 사내 콘텐츠 생산 직원분들은 외부 에디터에서 작성한 글을 붙여넣는 일이 잦습니다.
 * 이 때, font-color 속성값이 따라오는 경우가 있습니다.
 * 다크모드 대응에 어려움을 겪고 있어, 바탕색과 비슷한 색상이 따라오는 경우 제거하는 로직을 추가했습니다.
 * 
 * 만약, 외부 에디터에서 작성한 글을 붙여넣을 때 모든 글자색을 무시하길 원하는 경우 TextColor_Safe 를 사용하세요.
 */
export const TextColor = Extension.Create({
  name: 'text_color',

  type: 'mark',

  defineSpec() {
    return {

      attrs: {
        style: {
          default: null
        },
      },
      inclusive: false,
      toDOM: node => ['span', { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.text_color }, 0],
      parseDOM: [
        {
          tag: 'span',
          style: 'color',
          getAttrs: dom => {
            if (dom.style?.color) {
              /**
               * MARK: Google Docs 에서 작성한 글을 붙여넣을 때 간헐적으로 따라오는 스타일을 제거합니다.
               *       예) <span style="color: black;"></span>
              */
             if (dom.style.color === 'rgb(255, 255, 255)') return false
             if (dom.style.color === 'white') return false
             if (dom.style.color === '#ffffff') return false
             if (dom.style.color === '#fff') return false
             if (dom.style.color === 'rgb(0, 0, 0)') return false
             if (dom.style.color === 'black') return false
             if (dom.style.color === '#000000') return false
             if (dom.style.color === '#000') return false
             if (dom.style.color === 'rgb(29, 28, 29)') return false
             return {
               style: `color: ${dom.style.color};`
              }
            }
            return false
          },
          consuming: false
        },
      ],
    }
  },
    
    addCommands() {
      return {
        setTextColor,
    }
  }
})

/**
 * 외부의 글을 붙여 넣을 때 모든 글자색을 무시하길 원하는 경우 사용합니다.
 */
export const TextColor_Safe = Extension.Create({
  name: 'text_color',

  type: 'mark',

  defineSpec() {
    return {
      attrs: {
        style: {
          default: null
        },
      },
      inclusive: false,
      toDOM: node => ['span', { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.text_color }, 0],
    }
  },
  
  addCommands() {
    return {
      setTextColor,
    }
  }
})
