import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation } from "react-router-dom";

const OutdatedReloader = () => {
  const { pathname } = useLocation();
  const { data: packageInfo } = useQuery(
    ["packageJson", pathname],
    () => {
      return axios.get(window.location.origin + "/package.json");
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
    },
  );

  useEffect(() => {
    if (!packageInfo || !packageInfo?.data?.version) return;
    const currentAppVersion = import.meta.env.VITE_APP_VERSION;
    const latestAppVersion = packageInfo?.data?.version;
    // 현재와 버전이 다르면...
    if (latestAppVersion && latestAppVersion !== currentAppVersion) {
      // MARK: 마지막 새로고침 시도 기록을 참조하여 새로고침은 1번만 발생하게끔 합니다.
      //       새로고침 이미 했음에도 예기치 못 한 문제 발생해서 소스코드 업데이트가 온전히 되지 않을 경우,
      //       무한 새로고침에 빠질 수 있기 때문에 이를 방지하기 위함입니다.
      if (window.localStorage.getItem("version-refresh:latest") === latestAppVersion) {
        return;
      }
      window.localStorage.setItem("version-refresh:current", currentAppVersion);
      window.localStorage.setItem("version-refresh:latest", latestAppVersion);
      window.location.assign(window.location.origin + pathname);
    }
  }, [packageInfo, pathname]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default OutdatedReloader;
