import { useState, useRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { api, scrollTopList } from "../../utils/util";
import CommentTrashPopup from "../board/CommentTrashPopup";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import CommentEditor from "./CommentEditor";
import BestComment from "./BestComment";
import QnaComment from "./QnaComment";

function QnaCommentList({ current, content, article_num, reportComment }) {
  const location = useLocation();
  const param = new URLSearchParams(location && location.search);
  const token = useRecoilValue(tokenState);
  const queryClient = useQueryClient();
  const bestComments = content.comments
    .filter((item) => !item.deleted && item.like >= 5)
    .sort((a, b) => b.like - a.like)
    .slice(0, 2); //최대 2개
  const isTextAreaDisabled = content.cate_forum === "doctor" && token.user_type === "student";
  const [newComment, setNewComment] = useState({
    uuid: null,
    cid: null,
    content: null,
    image: null,
  });
  const [newReply, setNewReply] = useState({
    uuid: null,
    cid: null,
    content: null,
    image: null,
    target: null,
    targetNick: null,
  });
  const [deleteUUID, setDeleteUUID] = useState(false);

  const getLikeInfo = (cid) => {
    const info = content.likes && content.likes.find((like_info) => like_info.cid === cid);
    return info && info.liked;
  };

  const likeChanged = useMutation((data) => api.post(`/${current}/like/${article_num}`, data), {
    onSuccess: () => queryClient.invalidateQueries(`${current}Article`),
  });

  const likeClicked = (comment, curLiked) => {
    if (comment ? comment.ismine : content.ismine) {
      alert("내가 작성한 글은 추천할 수 없습니다.");
      return;
    }
    const cid = comment ? comment.uuid : null;
    const data = {
      cid: cid ?? null,
      liked: !curLiked,
    };

    likeChanged.mutate(data);
  };

  const commentDelete = useMutation((uuid) => api.delete(`/${current}/comment/delete/${uuid}`), {
    onSuccess: () => {
      setDeleteUUID(false);
      queryClient.invalidateQueries(`${current}Article`);
    },
    onError: (err) => {
      alert(err?.response?.data?.msg);
    },
  });

  const [highlightedCid, setHighlightedCid] = useState(
    param.get("cid") ? parseInt(param.get("cid")) : null,
  );
  const commentBoxRef = useRef(null);

  useEffect(() => {
    if (!highlightedCid) return;
    commentBoxRef.current && scrollTopList(commentBoxRef);
    setHighlightedCid(null);
  }, [highlightedCid]);

  return (
    <div className="w-full">
      <div className="p-[18px] border-b-[1px] border-solid border-gray-200">
        <span className="text-[18px] font-semibold">
          댓글
          <span className="text-primary-800 ml-1">
            {content.comments && content.comments.length}
          </span>
        </span>
      </div>
      {bestComments.map((item) => (
        <div key={item.uuid}>
          <BestComment
            item={item}
            getLikeInfo={getLikeInfo}
            likeClicked={likeClicked}
            setNewReply={setNewReply}
            setHighlightedCid={setHighlightedCid}
          />
        </div>
      ))}
      {content.comments &&
        content.comments
          .filter((item) => !item.cid)
          .sort((a, b) => a.uuid - b.uuid)
          .map((item) => (
            <div key={item.uuid}>
              {newReply.uuid && newReply.uuid === item.uuid ? (
                <CommentEditor
                  disabled={isTextAreaDisabled}
                  current={current}
                  newComment={newReply}
                  setNewComment={setNewReply}
                />
              ) : (
                <QnaComment
                  item={item}
                  isBestComment={bestComments.map((item) => item.uuid).includes(item.uuid)}
                  getLikeInfo={getLikeInfo}
                  likeClicked={likeClicked}
                  setDeleteUUID={setDeleteUUID}
                  setNewReply={setNewReply}
                  reportComment={reportComment}
                  commentBoxRef={commentBoxRef}
                  highlightedCid={highlightedCid}
                  isMyContent={content.ismine}
                  article_num={article_num}
                />
              )}
              {/* 대댓글 버튼을 누르면, 해당 댓글 바로 하단에 textarea 생김 */}
              {!newReply.uuid &&
                newReply.cid &&
                newReply.target === newReply.cid &&
                newReply.cid === item.uuid && (
                  <CommentEditor
                    current={current}
                    target={item.unick}
                    disabled={isTextAreaDisabled}
                    newComment={newReply}
                    setNewComment={setNewReply}
                  />
                )}
              {/* <!-- 대댓글 --> */}
              {content.comments
                .filter((reply) => reply.cid === item.uuid)
                .sort((a, b) => {
                  return a.reg_dttm - b.reg_dttm;
                })
                .map((reply) => (
                  <div key={reply.uuid}>
                    {newReply.uuid && newReply.uuid === reply.uuid ? (
                      <CommentEditor
                        current={current}
                        target={item.unick}
                        disabled={isTextAreaDisabled}
                        newComment={newReply}
                        setNewComment={setNewReply}
                      />
                    ) : (
                      <QnaComment
                        item={reply}
                        isBestComment={bestComments.map((item) => item.uuid).includes(reply.uuid)}
                        getLikeInfo={getLikeInfo}
                        likeClicked={likeClicked}
                        setDeleteUUID={setDeleteUUID} //삭제기능
                        setNewReply={setNewReply} //수정, 대댓글기능
                        reportComment={reportComment} //신고기능
                        commentBoxRef={commentBoxRef} //댓글창 스크롤
                        highlightedCid={highlightedCid} //댓글창 스크롤
                        isMyContent={content.ismine}
                        article_num={article_num}
                      />
                    )}
                    {!newReply.uuid && newReply.cid && newReply.target === reply.uuid && (
                      <CommentEditor
                        current={current}
                        target={reply.unick}
                        newComment={newReply}
                        setNewComment={setNewReply}
                      />
                    )}
                  </div>
                ))}
            </div>
          ))}
      <CommentEditor
        current={current}
        disabled={isTextAreaDisabled}
        newComment={newComment}
        setNewComment={setNewComment}
      />
      {deleteUUID && (
        <CommentTrashPopup
          setPopup={setDeleteUUID}
          deleteFunc={() => {
            commentDelete.mutate(deleteUUID);
          }}
        />
      )}
    </div>
  );
}

export default QnaCommentList;
