import { useEffect } from "react";
import { location1, location2 } from "../../constants";

// TODO : select multiple or single
const RegionFilter = ({ useLoc, single, withOnline }) => {
  const [loc, setLoc] = useLoc;

  useEffect(() => {
    setLoc({ ...loc, loc1: "서울", loc2: ["전체"] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const toggleLoc2 = (item) => {
  //   if (single) {
  //     if (item.endsWith("전체")) return;

  //     setLoc({ ...loc, loc2: [item] });
  //     return;
  //   }

  //   if (item.endsWith("전체")) {
  //     // 전체 클릭
  //     if (loc.loc2.length === location2[loc.loc1].length) {
  //       setLoc({ ...loc, loc2: [] });
  //     } else {
  //       setLoc({ ...loc, loc2: [...location2[loc.loc1]] });
  //     }
  //   } else {
  //     if (!loc.loc2.find((l) => l === item)) {
  //       setLoc({ ...loc, loc2: loc.loc2.concat(item) });
  //     } else {
  //       setLoc({ ...loc, loc2: loc.loc2.filter((l) => l !== item) });
  //     }
  //   }
  // };

  // const doneButton = () => {
  //   if (loc.loc1 === "세종특별자치시" || (loc.loc1 && loc.loc2.length)) {
  //     setLoc({ ...loc, view: false });
  //   }
  // };

  return (
    <section className="region__choice">
      <div className="depth1__box">
        <ul>
          {(withOnline ? ["온라인"].concat(location1) : location1).map((item, i) => {
            return (
              <li key={i}>
                <button
                  className={loc.loc1 === item ? "on" : ""}
                  onClick={() => setLoc({ ...loc, loc1: item })}
                >
                  {item}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="depth2__box">
        <ul>
          {location2[loc.loc1] &&
            [loc.loc1 + "전체"].concat(location2[loc.loc1]).map((item, idx) => {
              return (
                <li key={`${item}${idx}`}>
                  <button
                    onClick={() => setLoc({ ...loc, loc2: [item] })}
                    className={
                      (!single && idx === 0
                        ? loc.loc2.length === location2[loc.loc1].length
                          ? "all on"
                          : "all"
                        : "") + (loc.loc2.find((l) => l === item) ? " on" : "")
                    }
                  >
                    {item}
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
    </section>
  );
};

export default RegionFilter;
