import React, { useEffect } from "react";
import { Img, api, tokenUpdateInApiOption } from "../../utils/util";
import { useSetRecoilState } from "recoil";
import { tokenState } from "../../state";
import axios from "axios";
import Loading from "../../component/Loading";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

const KakaoLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const param = new URLSearchParams(location && location.search);
  const pathname = param.get("state");
  const CLIENT_ID = "41dfd85184340e94222564dcd9f8d11a";
  const REDIRECT_URI = `https://${window.location.host}/sendKakaoToken`;
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${location.pathname}`;
  const token = "4343862ba930313b03abdd8b03cf78f4";

  useEffect(() => {
    const js = document.createElement("script");
    js.src = "//developers.kakao.com/sdk/js/kakao.min.js";
    document.body.append(js);
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(token);
    }
  }, []);

  const setToken = useSetRecoilState(tokenState);
  const checkUserInfo = (userInfo) => {
    return (
      userInfo &&
      userInfo.has_birthyear &&
      userInfo.has_birthday &&
      userInfo.has_gender &&
      userInfo.has_phone_number &&
      userInfo.name &&
      userInfo.phone_number.startsWith("+82")
    );
  };
  const doLogin = (data) => {
    localStorage.clear();
    try {
      window.webkit.messageHandlers.moreden.postMessage({
        action: "token",
        data: JSON.stringify(data),
      });
    } catch (e) {}
    try {
      window.android.setToken(JSON.stringify(data));
    } catch (e) {}
    localStorage.setItem("token", JSON.stringify(data));
    queryClient.clear();
    tokenUpdateInApiOption(data.access_token);

    return data;
  };

  const getQueryString = () => {
    const param = new URLSearchParams(location && location.search);
    const code = param.get("code");
    const data = [
      ["grant_type", "authorization_code"],
      ["client_id", "41dfd85184340e94222564dcd9f8d11a"],
      ["redirect_uri", `https://${window.location.host}/sendKakaoToken`],
      ["code", code],
    ];
    const queryString = new URLSearchParams(data);
    return queryString;
  };

  const checkUser = async () => {
    const queryString = getQueryString();
    const kakaoRes = await axios.post("https://kauth.kakao.com/oauth/token", queryString, {
      headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
    });
    const kakaoToken = kakaoRes.data.access_token;
    window.Kakao.Auth && window.Kakao.Auth.setAccessToken(kakaoToken);
    const data = {
      token: kakaoToken,
    };

    await api
      .post("/user/verifyKakaoToken", data)
      .then((res) => {
        const verifyStatus = res.data.status;
        if (verifyStatus === "sync") {
          setToken({ ...doLogin(res.data) });
          navigate("/syncAccount", {
            state: {
              loginType: "kakao",
              kakaoToken: kakaoToken,
              nickname: res.data.user_nickname,
              pathname: pathname,
            },
            replace: true,
          });
        }
        if (verifyStatus === "failed") {
          const userInfo = res.data.kakao_token.kakao_account;
          checkUserInfo(userInfo)
            ? navigate("/join", {
                state: {
                  kakaoToken: kakaoToken,
                  loginType: "kakao",
                  birthday: `${userInfo.birthyear}-${userInfo.birthday.slice(
                    0,
                    2,
                  )}-${userInfo.birthday.slice(2, 4)}`,
                  phone: `0${userInfo.phone_number.slice(4).replaceAll("-", "")}`,
                  genderCd: userInfo.gender === "male" ? "M" : "F",
                  name: userInfo.name,
                },
                replace: true,
              })
            : navigate("/phoneVerify", {
                state: {
                  kakaoToken: kakaoToken,
                  loginType: "kakao",
                },
                replace: true,
              });
        }
        if (verifyStatus === "login") {
          setToken({ ...doLogin(res.data) });
          if (!res.data.user_type) {
            navigate("/verifyPending", { replace: true });
            return;
          }
          navigate(pathname, { replace: true });
        }
      })
      .catch((e) => {
        console.log(e);
        alert(
          "치과의사 면허증의 명의 일치 여부 확인을 위해 면허증과 동일한 명의의 휴대폰 번호 인증이 필요합니다.\n휴대폰 번호가 연동된 카카오 계정으로 재시도 부탁드립니다.",
        );
        navigate("/");
      });
  };

  useEffect(() => {
    if (location?.pathname === "/sendKakaoToken") {
      checkUser();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <div>
      {location && location.pathname === "/sendKakaoToken" ? (
        <Loading />
      ) : (
        <a id="kakao-login-btn" href={KAKAO_AUTH_URL}>
          <img src={Img("", "img/kakao_login_original.png")} alt="kakao" />
          <div>카카오로 시작하기</div>
        </a>
      )}
    </div>
  );
};

export default KakaoLogin;

export const kakaoLogout = () => {
  try {
    window.Kakao.Auth && window.Kakao.Auth.logout();
  } catch {
    alert("로그아웃을 다시 시도해주세요");
  }
};
