import { Dispatch, SetStateAction } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { publicApi } from "../../utils/util";

const CoursePopup = ({
  title,
  setPopup,
}: {
  title?: string;
  setPopup: Dispatch<SetStateAction<string>>;
}) => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);
  const queryClient = useQueryClient();
  const { id } = useParams();

  const registrationFreeCourse = useMutation(
    () => (publicApi as any).post(`/class/courses/${id}/voucher`),
    {
      onSuccess: () => {
        alert("수강신청이 완료되었습니다.");
        setPopup("");
        queryClient.invalidateQueries("classCourses");
        window.scrollTo(0, 0);
      },
    },
  );

  const handleClick = () => {
    if (!token) {
      navigate("/login", { state: id });
      return;
    } else {
      registrationFreeCourse.mutate();
    }
  };

  return (
    <div>
      <div
        className="fixed left-0 right-0 top-0 bottom-0 z-[92] bg-[rgba(0,0,0,0.4)]"
        onClick={() => setPopup("")}
      ></div>
      <div className="fixed z-[93] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <div className="bg-white pt-[30px] px-7 pb-5 min-w-[320px] lg:w-[514px] shadow-custom rounded-lg relative">
          <div className="mb-3 overflow-hidden break-all text-[25px] leading-8 font-semibold text-gray-900">
            무료강의 수강신청
          </div>
          <div className="text-base font-medium text-gray-900 mb-8 w-3/4 truncate">
            {title}
            <br /> 수강신청 하시겠습니까?
          </div>

          <div className="lg:flex items-center space-y-3 lg:space-y-0 lg:space-x-4">
            <button
              className="w-full h-[53px] flex items-center justify-center bg-primary-800 text-[#fff] text-xl font-semibold rounded-md"
              onClick={() => handleClick()}
            >
              확인
            </button>

            <button
              className="w-full lg:w-[177px] h-[53px] flex items-center justify-center border-[1px] border-solid border-gray-300 rounded-md text-xl font-semibold text-gray-900"
              onClick={() => setPopup("")}
            >
              취소
            </button>
          </div>

          <div className="absolute top-4 right-5 cursor-pointer" onClick={() => setPopup("")}>
            <i className="ii ii-remove text-2xl text-gray-900"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePopup;
