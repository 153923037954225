import { Img } from "../../utils/util";

const Badge = ({ item_level, style }) => {
  if (typeof item_level === "number")
    return (
      <div className="user_level_badge" style={style}>
        <div className="box">
          <span className="number">
            <b>Lv.</b>
            <span>{item_level}</span>
          </span>
        </div>
      </div>
    );

  return (
    <div className="user_level_badge special" style={style}>
      <div className="box">
        <img src={Img("", `img/rank_badge/rank_badge_${item_level}.svg`)} alt="" />
      </div>
    </div>
  );
};

export default Badge;
