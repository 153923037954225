import { Plugin, PluginKey } from "prosemirror-state";
import { Extension } from "../core/extension";
import { DOMParser, DOMSerializer, Fragment, Slice } from "prosemirror-model";
import { Paragraph } from "./paragraph";

const EXTENSION_NAME = 'html_insert'

export const HTML_INSERT_PLUGIN_KEY = new PluginKey(EXTENSION_NAME)

/**
 * HTML 을 입력할 수 있는 에디터 팝업을 표시합니다.
 *
 * @type {import('prosemirror-state').Command}
 */
const displayHtmlInsertInput = (state, dispatch, view) => {
  if (dispatch) {
    const tr = state.tr.setMeta(HTML_INSERT_PLUGIN_KEY, 'open')
    dispatch(tr)
    return true
  }
}

class htmlInsertPluginView {
  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   */
  constructor(view) {
    this.view = view
    this.CSS = 'html-insert-dialog'

    this.serializer = DOMSerializer.fromSchema(view.state.schema)
    this.domParser = DOMParser.fromSchema(view.state.schema)

    this.background = document.createElement('div')
    this.background.classList.add(`${this.CSS}-background`)
    this.background.style.display = 'none'

    this.dialog = document.createElement('div')
    this.dialog.classList.add(this.CSS)

    this.form = document.createElement('form')
    this.form.classList.add(`${this.CSS}__form`)
    this.form.addEventListener('submit', this._onSubmit.bind(this))

    this.input = document.createElement('textarea')
    this.input.classList.add(`${this.CSS}__input`)
    this.input.spellcheck = false

    this.csGuide = document.createElement('div')
    this.csGuide.style.display = 'flex'
    this.csGuide.style.flexDirection = 'column'

    this.addToCartGuide = document.createElement('span')
    this.addToCartGuide.textContent = '장바구니 위젯 코드 복사하기📋'
    this.addToCartGuide.style.cursor = 'pointer'
    this.addToCartGuide.style.margin = '4px 0'
    this.addToCartGuide.onclick = () => {
      window.navigator.clipboard?.writeText('<template id="addToCart" data-target="" data-option="" data-image=""></template>')
    }

    this.productPreviewGuide = document.createElement('span')
    this.productPreviewGuide.textContent = '상품 위젯 코드 복사하기📋'
    this.productPreviewGuide.style.cursor = 'pointer'
    this.productPreviewGuide.style.margin = '4px 0'
    this.productPreviewGuide.onclick = () => {
      window.navigator.clipboard?.writeText('<template id="productPreview" data-target=""></template>')
    }

    this.downloadCouponGuide = document.createElement('span')
    this.downloadCouponGuide.textContent = '쿠폰 위젯 코드 복사하기📋'
    this.downloadCouponGuide.style.cursor = 'pointer'
    this.downloadCouponGuide.style.margin = '4px 0'
    this.downloadCouponGuide.onclick = () => {
      window.navigator.clipboard?.writeText('<template id="downloadCoupon" data-target="" data-comment=""></template>')
    }

    this.marketingAgreementGuide = document.createElement('span')
    this.marketingAgreementGuide.textContent = '마케팅 수신동의 프로모션 위젯 코드 복사하기📋'
    this.marketingAgreementGuide.style.cursor = 'pointer'
    this.marketingAgreementGuide.style.margin = '4px 0'
    this.marketingAgreementGuide.onclick = () => {
      window.navigator.clipboard?.writeText('<template id="marketingAgreement" data-target=""></template>')
    }

    this.teamProductGuide = document.createElement('span')
    this.teamProductGuide.textContent = '팀구매 위젯 코드 복사하기📋'
    this.teamProductGuide.style.cursor = 'pointer'
    this.teamProductGuide.style.margin = '4px 0'
    this.teamProductGuide.onclick = () => {
      window.navigator.clipboard?.writeText('<template id="teamProduct" data-target=""></template>')
    }

    this.button = document.createElement('button')
    this.button.classList.add(`${this.CSS}__button`)
    this.button.type = 'submit'
    this.button.textContent = '대체하기'

    this.form.appendChild(this.input)
    this.form.appendChild(this.button)
    this.form.appendChild(this.csGuide)
    this.dialog.appendChild(this.form)
    this.background.appendChild(this.dialog)
    this.csGuide.appendChild(this.addToCartGuide)
    this.csGuide.appendChild(this.productPreviewGuide)
    this.csGuide.appendChild(this.downloadCouponGuide)
    this.csGuide.appendChild(this.marketingAgreementGuide)
    this.csGuide.appendChild(this.teamProductGuide)
    view.dom.parentNode.appendChild(this.background)

    this.__hideDialog = this._hideDialog.bind(this)
    this.__handleClose = this._handleClose.bind(this)

    document.addEventListener('mousedown', this.__hideDialog)
    document.addEventListener('keydown', this.__handleClose)
  }

  /**
   * 에디터의 내용을 textarea 속의 HTML로 대체합니다.
   * @param {SubmitEvent} event
   */
  _onSubmit(event) {
    event.preventDefault()
    try {
      const htmlString = this.input.value
      const template = document.createElement('template')
  
      template.innerHTML = htmlString
  
      const parsedHTML = this.domParser.parse(template.content)
      const tr = this.view.state.tr.replace(
        0,
        this.view.state.doc.content.size,
        new Slice(Fragment.from(parsedHTML), 0, 0)
      )
  
      this.view.dispatch(tr)
    } catch (error) {
      console.error(error)
      const pNode = this.view.state.schema.nodes[Paragraph.name].create(
        null,
        this.view.state.schema.text('올바른 HTML을 입력해주세요.'),
      )
      this.view.dispatch(this.view.state.tr.replaceSelectionWith(pNode))
    }
  }

  async _prettifyHTML(html) {
    try {
      const { default: prettify } = await import('../utils/prettify')
      return prettify(html)
    } catch (error) {
      console.error(error)
      return html
    }
  }

  /**
   *
   * @param {MouseEvent} event
   */
  _hideDialog(event) {
    event.stopPropagation()
    if (event.target.closest(`.${this.CSS}`)) return
    this.background.style.display = 'none'
  }

  /**
   * 
   * @param {KeyboardEvent} event 
   */
  _handleClose(event) {
    if (event.key === 'Escape') {
      this.background.style.display = 'none'
    }
  }

  /**
   * 모달이 열리면 텍스트 에디터의 내용을 HTML로 가져와서 textarea 에 넣어줍니다.
   * @param {import('prosemirror-view').EditorView} view
   */
  update(view) {
    if (HTML_INSERT_PLUGIN_KEY.getState(view.state) === 'open') {
      const virtualNode = document.createElement('div')
      const contentHTML = this.serializer.serializeFragment(this.view.state.doc.content)
      virtualNode.append(contentHTML)
      this._prettifyHTML(virtualNode.innerHTML).then((html) => {
        this.background.style.display = 'flex'
        this.input.value = html
        this.input.focus()
        virtualNode.remove()
      })
      return
    }
    this.background.style.display = 'none'
  }

  destroy() {
    this.dialog.remove()
    document.removeEventListener('mousedown', this.__hideDialog)
    document.removeEventListener('keydown', this.__handleClose)
  }
}

const htmlInsertPlugin = new Plugin({
  key: HTML_INSERT_PLUGIN_KEY,
  state: {
    init() {
      return undefined // or 'open'
    },
    apply(tr) {
      return tr.getMeta(this)
    },
  },
  view(editorView) {
    return new htmlInsertPluginView(editorView)
  },
})

export const HtmlInsert = Extension.Create({
  name: EXTENSION_NAME,

  addCommands() {
    return {
      displayHtmlInsertInput
    }
  },

  addPlugins() {
    return [htmlInsertPlugin]
  }
})
