import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const DetectUrl = ({ children }) => {
  const [embedIframe, setEmbedIframe] = useState("");
  const urlreg =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

  const embed_types = {
    youtube: { includes: "youtu", endpoint: "https://youtube.com/oembed" },
  };

  const cur_type = Object.keys(embed_types).find((item) =>
    children.toLowerCase().includes(embed_types[item].includes)
  );

  useEffect(() => {
    cur_type &&
      axios
        .get(
          `${embed_types[cur_type].endpoint}?format=json&url=${children.replace(
            /[^\x20-\x7E]/g,
            ""
          )}`
        )
        .then((res) => {
          setEmbedIframe(res.data.html);
        })
        .catch(() => {
          setEmbedIframe("");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return children.split(urlreg).map((con) =>
    urlreg.test(con) ? (
      <span key={con}>
        <a
          className="content__url"
          href={con.startsWith("http") ? con : `http://${con}`}
          target="_blank"
          rel="noopener noreferrer "
        >
          {con}
        </a>
        {embedIframe && (
          <div
            className="embed__video-wrap mt-2"
            dangerouslySetInnerHTML={{ __html: embedIframe }}
          ></div>
        )}
      </span>
    ) : (
      con
    )
  );
};

export default DetectUrl;
