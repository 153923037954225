import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import ProductUnit from "component/market/product/ProductUnit";
import { useHomeProductGroupProducts, useHomeProductGroups } from "api/market/hooks";
import { useEffect, useState } from "react";
import { cls, marketUrl } from "utils/util";
import Blur from "page/user/Blur";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { useHomeSegment } from "hooks/useHomeSegment";

export default function MarketCuration() {
  const [targetGroupId, setTargetGroupId] = useState("");

  const { data: productGroups } = useHomeProductGroups();

  useEffect(() => {
    if (!productGroups?.length) return;
    setTargetGroupId(productGroups[0].groupId);
  }, [productGroups]);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-5">
        <h2 className="font-bold text-lg">모어덴 마켓</h2>
        <Link
          to={marketUrl()}
          className="cursor-pointer text-[13px] text-gray-600 hover:underline z-10"
        >
          +더보기
        </Link>
      </div>
      <div className="scroll_none overflow-x-scroll flex flex-nowrap my-5">
        {productGroups?.length > 0 &&
          productGroups.map((item, idx) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  setTargetGroupId(item.groupId);
                }}
                className={cls(
                  item.groupId === targetGroupId
                    ? "bg-gray-900 text-white"
                    : "bg-white hover:bg-secondary-100",
                  "cursor-pointer shadow-md inline-block py-1 px-3 rounded-md mr-4 mb-2 whitespace-nowrap z-10",
                )}
              >
                {item.name}
              </div>
            );
          })}
      </div>
      <ProductGroupItems groupId={targetGroupId} />
    </div>
  );
}

const ProductGroupItems = ({ groupId }) => {
  const token = useRecoilValue(tokenState);
  const isAuthorizedUser = token && token.user_type;

  const { sendTrackingEvent } = useHomeSegment();

  const { data: products, isLoading: productsLoading } = useHomeProductGroupProducts(
    {
      groupId: groupId,
    },
    { enabled: !!groupId, staleTime: 15 * 60 * 1000, cacheTime: 15 * 60 * 1000 },
  );

  return (
    <div onClick={() => sendTrackingEvent({ name: "마켓" })} className="relative">
      <Swiper
        modules={[Autoplay, Navigation]}
        className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
        slidesPerView={3.45}
        initialSlide={4}
        spaceBetween={12}
        parallax={true}
        autoplay={{
          delay: 3000,
        }}
        loop={true}
        navigation={{
          nextEl: `.market-swiper-next-btn`,
        }}
      >
        {productsLoading
          ? [...Array(5).keys()].map((key) => (
              <SwiperSlide key={key}>
                <ProductUnit isLoadingUnit={true} />
              </SwiperSlide>
            ))
          : products?.hits?.map((product) => (
              <SwiperSlide key={product.id}>
                <ProductUnit
                  id={product.id}
                  name={product.meta_title || product.product.meta_title || product.name}
                  regularPrice={product.regular_price}
                  salePrice={product.sale_price}
                  discountPercent={Math.round(
                    ((product.regular_price - product.sale_price) / product.regular_price) * 100,
                  )}
                  thumbnail={product.images[0]?.url}
                  categoryIds={product?.category_ids || []}
                  productCode={product?.product_code}
                  brandName={product?.brand?.englishName}
                  isSoldOut={product?.is_sold_out}
                  isLoadingUnit={false}
                />
              </SwiperSlide>
            ))}
      </Swiper>
      {!isAuthorizedUser && (
        <div className="absolute -bottom-[3px] w-full h-[30px] left-0 backdrop-blur-sm z-10">
          <Blur />
        </div>
      )}
      <div>
        <button className="market-swiper-next-btn absolute w-9 h-9 rounded-full shadow-custom z-20 -right-5 top-[72px] bg-white">
          <i className="ii-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};
