import { useEffect, useRef } from "react";

let loadSRT = function (url, callback) {
  let httpRequest = new XMLHttpRequest();
  httpRequest.open("GET", url, true);
  httpRequest.send(null);

  httpRequest.onreadystatechange = function () {
    if (httpRequest.readyState === XMLHttpRequest.DONE) {
      let subtitles = window.parser.fromSrt(httpRequest.responseText, true);

      for (let i in subtitles) {
        subtitles[i] = {
          start: subtitles[i].startTime / 1000,
          end: subtitles[i].endTime / 1000,
          text: subtitles[i].text,
        };
      }

      callback(subtitles);
    }
  };
};

let openFullscreen = function (elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

const YTSubtitle = ({ video, subtitle }) => {
  const youtubeRef = useRef(null);
  const fullscreenRef = useRef(null);
  const isSetSubtitle = useRef(false);

  useEffect(() => {
    if (!youtubeRef.current || isSetSubtitle.current || !subtitle) return;
    isSetSubtitle.current = true;
    loadSRT(subtitle, function (subtitles) {
      new window.YoutubeExternalSubtitle.Subtitle(youtubeRef.current, subtitles);
    });
  }, [youtubeRef, subtitle]);

  return (
    <div id="youtube-subtitle">
      <div ref={fullscreenRef} id="fullscreen-container" style={{ width: "100%", height: 400 }}>
        <iframe
          ref={youtubeRef}
          title="video"
          width="100%"
          height="100%"
          src={video}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <button id="fullscreen-btn" onClick={() => openFullscreen(fullscreenRef.current)}>
        전체화면
      </button>
    </div>
  );
};

export default YTSubtitle;
