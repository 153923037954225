import { TextSelection } from "prosemirror-state";
import { pollSVG } from "../assets/icons";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { BlockQuote } from "./blockquote";

/**
 * @type {(fn: { id: string, title: string }) => import('prosemirror-state').Command}
 */
export const createPoll = (pollData) => (state, dispatch) => {
  if (!state.selection.empty) return false

  const pollNode = state.schema.nodes[Poll.name].create({ 'poll-id': pollData.id, 'poll-title': pollData.title });

  const selectionHead = state.selection.$head
  const isInsideBlockquote =
    selectionHead.node(selectionHead.depth - 1).type.name === BlockQuote.name
  const isInEmptyParagraphNode =
    selectionHead.node(selectionHead.depth).content.size === 0

  // 인용문 안에 있을 경우 인용문 밖에 poll 노드를 삽입합니다.
  if (isInsideBlockquote) {
    const insertAt = selectionHead.after(selectionHead.depth - 1)
    const tr = state.tr.insert(insertAt, pollNode)
    const tr2 = tr.setSelection(TextSelection.create(tr.doc, insertAt + 1))

    dispatch(tr2)
    return true
  }

  // 빈 Paragraph 블록에 커서가 있을 경우 poll 노드로 교체합니다.
  if (isInEmptyParagraphNode) {
    const tr = state.tr.replaceSelectionWith(pollNode)

    dispatch(tr)
    return true
  }

  // 다음 블럭에 poll 노드를 삽입합니다.
  const posAfterCurNode = selectionHead.end(selectionHead.depth)
  const tr = state.tr.insert(posAfterCurNode, pollNode)
  const tr2 = tr.setSelection(TextSelection.create(tr.doc, posAfterCurNode + 2))

  dispatch(tr2)
  return true
}

class PollNodeView {
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos

    const pollId = node.attrs['poll-id']
    const pollTitle = node.attrs['poll-title']

    this.dom = document.createElement('div');
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.nodes.custom)

    const iconWrapper = document.createElement('div')
    iconWrapper.innerHTML = pollSVG
    iconWrapper.className = MEDISTREAM_SCHEMA_STYLE.etc.iconWrapper

    const typeDiv = document.createElement('div')
    typeDiv.innerHTML = '투표'
    typeDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.typeDiv

    const questionText = pollTitle || 'Poll question'
    const titleDiv = document.createElement('div')
    titleDiv.innerHTML = questionText
    titleDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.titleDiv

    pollId && this.dom.setAttribute('poll-id', pollId)
    pollTitle && this.dom.setAttribute('poll-title', pollTitle)

    this.dom.appendChild(iconWrapper)
    this.dom.appendChild(typeDiv)
    this.dom.appendChild(titleDiv)
  }
}

export const pollNodeView = (node, view, getPos) =>
  new PollNodeView(node, view, getPos)

export const Poll = Extension.Create({
  name: 'poll',

  type: 'node',

  defineSpec() {
    return {
      attrs: {
        'poll-id': { default: '' },
        'poll-title': { default: '' },
      },
      group: 'block',
      marks: '',
      selectable: true,
      draggable: true,
      toDOM(node) {
        return [
          'poll',
          {
            class: MEDISTREAM_SCHEMA_STYLE.nodes.poll,
            'poll-id': node.attrs['poll-id'] || null,
            'poll-title': node.attrs['poll-title'] || null,
          }
        ]
      },
      parseDOM: [
        {
          tag: 'poll',
          getAttrs: (dom) => ({
            ['poll-id']: dom.getAttribute('poll-id') || null,
            ['poll-title']: dom.getAttribute('poll-title') || null,
          })
        }
      ]
    }
  },

  addCommands() {
    return {
      createPoll
    }
  },

  addNodeView() {
    return pollNodeView
  }
})
