import { Plugin, PluginKey } from "prosemirror-state";
import { Extension } from "../core/extension";
import { prependHTTPS } from "../utils/regex";

const EXTENSION_NAME = 'remote_menu'

export const REMOTE_MENU_PLUGIN_KEY = new PluginKey(EXTENSION_NAME)

/**
 * 
 * @param {import('../core/editor').IntegrationEditor} editor 
 */
const remoteMenu = (editor) => new Plugin({
  key: REMOTE_MENU_PLUGIN_KEY,
  state: {
    init() {
      return undefined
    },
    apply(tr) {
      return tr.getMeta(this)
    }
  },
  view(_editorView) {
    return {
      update(view) {
        if (!REMOTE_MENU_PLUGIN_KEY.getState(view.state)) return
        
        const state = REMOTE_MENU_PLUGIN_KEY.getState(view.state)

        if (state === 'toggle-strong' && editor.commands.toggleStrong) {
          return editor.commands.toggleStrong(view.state, view.dispatch)
        }
        if (state === 'toggle-em' && editor.commands.toggleEm) {
          return editor.commands.toggleEm(view.state, view.dispatch)
        }
        if (state === 'toggle-underline' && editor.commands.toggleUnderline) {
          return editor.commands.toggleUnderline(view.state, view.dispatch)
        }
        if (state === "toggle-highlighted" && editor.commands.toggleMoredenHighlighted) {
          return editor.commands.toggleMoredenHighlighted(view.state, view.dispatch)
        }
        if (state === 'toggle-del' && editor.commands.toggleDel) {
          return editor.commands.toggleDel(view.state, view.dispatch)
        }
        if (state?.type === 'setTextFormat') {
          if (state.textFormat === 'heading') {
            return editor.commands.setBlockToHeading(view.state, view.dispatch)
          }
          if (state.textFormat === 'paragraph') {
            return editor.commands.setBlockToParagraph(view.state, view.dispatch)
          }
        }
        if (state?.type === 'setFontSize' && editor.commands.setFontSize) {
          return editor.commands.setFontSize(state.fontSize)(view.state, view.dispatch);
        }
        if (state === 'toggle-blockquote' && editor.commands.wrapInBlockquote) {
          return editor.commands.wrapInBlockquote(view.state, view.dispatch)
        }
        if (state === 'toggle-hr' && editor.commands.addHorizontalRuleNextLine) {
          return editor.commands.addHorizontalRuleNextLine(view.state, view.dispatch)
        }
        if (state === 'toggle-embed' && editor.commands.displayIframeTooltip) {
          return editor.commands.displayIframeTooltip(view.state, view.dispatch)
        }
        if (state?.type === 'insertLink' && editor.commands.updateLink) {
          return editor.commands.updateLink(prependHTTPS(state.value.trim()))(view.state, view.dispatch, view)
        }
        if (state === 'displayHTMLDialog' && editor.commands.displayHtmlInsertInput) {
          return editor.commands.displayHtmlInsertInput(view.state, view.dispatch)
        }
        if (state === 'alignLeft' && editor.commands.alignTextLeft) {
          return editor.commands.alignTextLeft(view.state, view.dispatch)
        }
        if (state === 'alignCenter' && editor.commands.alignTextCenter) {
          return editor.commands.alignTextCenter(view.state, view.dispatch)
        }
        if (state === 'alignRight' && editor.commands.alignTextRight) {
          return editor.commands.alignTextRight(view.state, view.dispatch)
        }
        if (state?.type === 'setTextColor' && editor.commands.setTextColor) {
          return editor.commands.setTextColor(state.color)(view.state, view.dispatch)
        }
        if (state?.type === 'setTextBackgroundColor' && editor.commands.setTextBackgroundColor) {
          return editor.commands.setTextBackgroundColor(state.color)(view.state, view.dispatch)
        }
        if (state?.type === 'before-drag-upload' && editor.commands.displayUploadIndicator) {
          return editor.commands.displayUploadIndicator(state.id, state.coords)(view.state, view.dispatch, view)
        }
        if (state?.type === 'before-upload' && editor.commands.displayUploadIndicator) {
          return editor.commands.displayUploadIndicator(state.id)(view.state, view.dispatch, view)
        }
        if (state?.type === 'upload-failure-message' && editor.commands.insertFailureMessage) {
          const { id, message } = state
          alert(message)
          return editor.commands.insertFailureMessage(id)(view.state, view.dispatch)
        }
        if (state?.type === 'image' && editor.commands.insertImage) {
          const { urls, id } = state
          return editor.commands.insertImage(urls, id)(view.state, view.dispatch, view)
        }
        if (state?.type === 'video' && editor.commands.insertVideo) {
          const { videos, id } = state
          return editor.commands.insertVideo(videos, id)(view.state, view.dispatch, view)
        }
        if (state?.type === 'file' && editor.commands.insertFile) {
          const { urls, id } = state
          return editor.commands.insertFile(urls, id)(view.state, view.dispatch, view)
        }
        if (state === 'addTable' && editor.commands.insertTable) {
          return editor.commands.insertTable(view.state, view.dispatch)
        }
        if (state?.type === 'setCellAttrs' && editor.commands.setCellAttr) {
          const colorData = state.color
          const color = colorData === 'reset-style' ? '' : colorData
          return editor.commands.setCellAttr('background', color)(view.state, view.dispatch, view)
        }
        if (state === 'mergeCells' && editor.commands.mergeCells) {
          return editor.commands.mergeCells(view.state, view.dispatch)
        }
        if (state === 'splitCell' && editor.commands.splitCell) {
          return editor.commands.splitCell(view.state, view.dispatch)
        }
        if (state === 'headerColumn' && editor.commands.toggleHeaderColumn) {
          return editor.commands.toggleHeaderColumn(view.state, view.dispatch)
        }
        if (state === 'headerRow' && editor.commands.toggleHeaderRow) {
          return editor.commands.toggleHeaderRow(view.state, view.dispatch)
        }
        if (state === 'removeColumn' && editor.commands.deleteColumn) {
          return editor.commands.deleteColumn(view.state, view.dispatch)
        }
        if (state === 'removeRow' && editor.commands.deleteRow) {
          return editor.commands.deleteRow(view.state, view.dispatch)
        }
        if (state === 'addRowAbove' && editor.commands.addRowBefore) {
          return editor.commands.addRowBefore(view.state, view.dispatch)
        }
        if (state === 'addRowBelow' && editor.commands.addRowAfter) {
          return editor.commands.addRowAfter(view.state, view.dispatch)
        }
        if (state === 'addColumnRight' && editor.commands.addColumnAfter) {
          return editor.commands.addColumnAfter(view.state, view.dispatch)
        }
        if (state === 'addColumnLeft' && editor.commands.addColumnBefore) {
          return editor.commands.addColumnBefore(view.state, view.dispatch)
        }
        if (state === 'toggleCellBorder' && editor.commands.toggleCellBorder) {
          return editor.commands.toggleCellBorder(view.state, view.dispatch, view)
        }
        if (state === 'addCallout' && editor.commands.addCallout) {
          return editor.commands.addCallout(view.state, view.dispatch)
        }
        if (state === 'addCustomTemplate' && editor.commands.addCustomTemplate) {
          return editor.commands.addCustomTemplate(view.state, view.dispatch)
        }
        if (state === 'addDirectContactButton' && editor.commands.addDirectContactButton) {
          return editor.commands.addDirectContactButton(view.state, view.dispatch)
        }
        if (state?.type === 'addEmoji' && editor.commands.insertEmoji) {
          const { emoji } = state
          return editor.commands.insertEmoji(emoji)(view.state, view.dispatch)
        }
        if (state?.type === 'addPoll' && editor.commands.createPoll) {
          const { pollData } = state
          return editor.commands.createPoll(pollData)(view.state, view.dispatch)
        }
        if (state === 'wrap-in-bullet-list' && editor.commands.wrapInBulletList) {
          return editor.commands.wrapInBulletList(view.state, view.dispatch)
        }
        if (state === 'wrap-in-ordered-list' && editor.commands.wrapInOrderedList) {
          return editor.commands.wrapInOrderedList(view.state, view.dispatch)
        }
      }
    }
  }
})

export const RemoteMenu = Extension.Create({
  name: EXTENSION_NAME,

  addPlugins() {
    return [remoteMenu(this.editor)]
  }
})
