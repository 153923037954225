import { api } from "../../utils/util";
import { useEffect, useState } from "react";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation, useNavigate } from "react-router-dom";
import ZoomImg from "../ZoomImg";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import dayjs from "dayjs";
import { EDU_ROUTE } from "App";

const ClassInfo = ({ cid, item, registration }) => {
  const location = useLocation();
  const [content, setContent] = useState(null);
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();
  useEffect(() => {
    api.get(`/class/course/${cid}/article`).then((res) => {
      setContent(res.data.article);
    });
  }, [cid]);

  const onClick = () => {
    api.post(`/class/course/${cid}/register`).then((res) => {
      window.location.reload();
    });
  };

  return (
    <div className="class_info__box">
      <div className="left-content">
        <ZoomImg>
          {content && (
            <div id="froala_view">
              <FroalaEditorView model={content.content} />
            </div>
          )}
        </ZoomImg>
      </div>
      <div className="right-content">
        <div className="payment-box">
          <div className="txt">
            <b>{item.course.title}</b>
            <br />
            {item.course.sub_title}
          </div>
          <div className="detail">
            {token && token.uid ? (
              registration ? (
                <button
                  className="enroll"
                  onClick={() => navigate(`/${EDU_ROUTE}/${cid}?tab=curriculum`)}
                >
                  강의실 입장하기
                </button>
              ) : (
                <button
                  className="enroll before"
                  onClick={onClick}
                  disabled={dayjs(item.course.conceal_at) < dayjs()}
                >
                  수강 신청
                </button>
              )
            ) : (
              <button className="enroll before" onClick={() => navigate(`/login`)}>
                로그인 후 수강 신청
              </button>
            )}
            {navigator.share ? (
              <button
                onClick={() =>
                  navigator.share({
                    title: item.course.title,
                    text: item.course.sub_title,
                    url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                  })
                }
              >
                <i
                  className="ii ii-export"
                  style={{ marginRight: 5, position: "relative", top: -1 }}
                ></i>
                공유
              </button>
            ) : (
              <CopyToClipboard
                text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
                onCopy={() => alert("링크를 복사하였습니다")}
              >
                <button>
                  <i
                    className="ii ii-export"
                    style={{ marginRight: 5, position: "relative", top: -1 }}
                  ></i>
                  공유
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassInfo;
