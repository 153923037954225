import { liftListItem, sinkListItem, splitListItem } from "prosemirror-schema-list";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { Heading } from "./heading";

export const ListItem = Extension.Create({
  name: 'list_item',

  type: 'node',

  defineSpec(extensions) {
    const heading = extensions.find(extension => extension.name === Heading.name)
    const contentExpression = heading ? '(paragraph|heading) block*' : 'paragraph block*'

    return {
      content: contentExpression,
      defining: true,
      toDOM() {
        return ['li', {class: MEDISTREAM_SCHEMA_STYLE.nodes.li}, 0]
      },
      parseDOM: [{tag: 'li'}],
    }
  },

  addCommands() {
    return {
      splitListItem: splitListItem(this.schema.nodes[this.name]),
      liftListItem: liftListItem(this.schema.nodes[this.name]),
      sinkListItem: sinkListItem(this.schema.nodes[this.name]),
    }
  },

  addKeyboardShortcuts() {
    return {
      'Enter': this.editor.commands.splitListItem,
      'Tab': this.editor.commands.sinkListItem,
      'Shift-Tab': this.editor.commands.liftListItem,
    }
  },
})
