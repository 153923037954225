import { api, Img, noticeDay, outResource } from "../../utils/util";
import HeaderColor from "../../component/contents/HeaderColor";
import { useState, useEffect } from "react";
import ClassCurriculumList from "../../component/class/ClassCurriculumList";
import ClassNotice from "../../component/class/ClassNotice";
import ClassWriteNotice from "./ClassWriteNotice";
import ClassInfo from "../../component/class/ClassInfo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { headerColorState, tokenState } from "../../state";
import Loading from "../../component/Loading";
import { Link } from "react-router-dom";
import ClassCheckAuth from "../../component/class/ClassCheckAuth";
import { EDU_ROUTE } from "App";

const ClassDetail = () => {
  const location = useLocation();
  const [item, setItem] = useState({ load: false });
  const cid = parseInt({ ...useParams() }.cid || 0);
  const urlParam = new URLSearchParams(location.search);
  const tab = urlParam.get("tab") || "info";
  const navigate = useNavigate();
  const setHeaderColor = useSetRecoilState(headerColorState);
  const token = useRecoilValue(tokenState);
  const certificateLink = {
    18: "https://docs.google.com/forms/d/e/1FAIpQLSfTCovZGHwRRWN6XEte-yLNGZtbIyHH8sxePxfTYsd_RBlvBA/viewform",
    19: "https://forms.gle/gsap2e3yu4sJCXXWA",
  };

  const modify = () => {
    api
      .get(`/class/course/${cid}/article`)
      .then((res) => {
        navigate(`/${EDU_ROUTE}/modify/${item.course.cid}`, {
          state: { ...item, ...res.data.article },
        });
      })
      .catch(() => {
        navigate(`/${EDU_ROUTE}/modify/${item.course.cid}`, {
          state: { ...item, content: "", text_content: "" },
        });
      });
  };

  useEffect(() => {
    api.get(`/class/course/${cid}`).then((res) => {
      setItem({ ...res.data, load: true });
    });

    setHeaderColor("white");
    return () => setHeaderColor(null);
  }, [navigate, setHeaderColor, cid]);

  if (!item.load) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderColor>
        <div className="curation-detail class">
          <div className="cover-img class" style={{ backgroundColor: item.course.header_color }}>
            <div className="title-box detail">
              <div className="preview">
                <img src={Img(item.course.header_pc)} alt="" />
              </div>
              <div className="detail-info">
                <div className="txt">
                  <b>{item.course.title}</b>
                  <br />
                  {item.course.sub_title}
                </div>
                <span className="sub-txt">
                  {noticeDay(item.course.release_at)} ~ {noticeDay(item.course.conceal_at)}
                </span>
              </div>
            </div>
          </div>

          {token && token.user_id === "admin" && (
            <div className="modify__btn" onClick={modify}>
              <div className="submit__box">
                <button className="submit-btn">수정</button>
              </div>
            </div>
          )}

          <div className="wrapper">
            <div className="inner">
              <div className="main_tab">
                <div className="ongoing">
                  <Link to={`/${EDU_ROUTE}/${cid}?tab=info`} replace>
                    <button className={tab === "info" ? "selected" : ""}>강의 소개</button>
                  </Link>
                  <Link to={`/${EDU_ROUTE}/${cid}?tab=curriculum`} replace>
                    <button className={tab === "curriculum" ? "selected" : ""}>강의실 입장</button>
                  </Link>
                  <Link to={`/${EDU_ROUTE}/${cid}?tab=notice`} replace>
                    <button className={tab === "notice" ? "selected" : ""}>공지사항 및 자료</button>
                  </Link>
                  <div
                    onClick={() => outResource(certificateLink[cid])}
                    style={{ display: "inline" }}
                  >
                    <button>수료증 신청</button>
                  </div>
                </div>
              </div>
              {tab === "info" && (
                <ClassInfo cid={cid} item={item} registration={item.registration} />
              )}
              {tab === "curriculum" && (
                <ClassCheckAuth registration={item.registration} cid={cid}>
                  <ClassCurriculumList cid={cid} item={item} registration={item.registration} />
                </ClassCheckAuth>
              )}
              {tab === "notice" && (
                <ClassCheckAuth registration={item.registration} cid={cid}>
                  <ClassNotice cid={cid} />
                </ClassCheckAuth>
              )}
              {tab === "notice_write" && token && token.user_id === "admin" && (
                <ClassWriteNotice cid={cid} />
              )}
            </div>
          </div>
        </div>
      </HeaderColor>
    </div>
  );
};

export default ClassDetail;
