import { Link } from "react-router-dom";
import { CLASS_ROUTE } from "../../App";
import { marketUrl } from "utils/util";

function SideMenu(props) {
  const menuList = [
    { name: "나의 정보", path: "/mypage", current: "mypage" },
    { name: "나의 강의실", path: `/mypage/${CLASS_ROUTE}`, current: "class" },
    { name: "주문내역", path: marketUrl("/mypage/mycheckout"), current: "mycheckout" },
    { name: "나의 포인트", path: marketUrl("/mypage/mypoint"), current: "mypoint" },
    { name: "나의 쿠폰", path: marketUrl("/mypage/mycoupon"), current: "mycoupon" },
    { name: "결제수단 관리", path: marketUrl("/mypage/mypayment"), current: "mypayment" },
    { name: "마켓 직원 계정 관리", path: marketUrl("/mypage/mysubuser"), current: "mysubuser" },
    { name: "개원의 사업자등록정보", path: "/mypage/myhospital", current: "myhospital" },
    { name: "임플란트 수가인증", path: "/mypage/myimplant", current: "myimplant" },
    { name: "내가 쓴 글", path: "/mypage/mypost", current: "mypost" },
    { name: "내가 스크랩한 글", path: "/mypage/myscrap", current: "my_scrap" },
    { name: "구독 관리", path: "/mypage/mysubscription", current: "mysubscription" },
    { name: "치크루팅 요청내역", path: "/mypage/mychicruiting", current: "mychicruiting" },
    { name: "공지사항", path: "/mypage/notice", current: "notice" },
    { name: "차단된 사용자", path: "/mypage/block", current: "block" },
    { name: "알림", path: "/mypage/alarm", current: "alarm" },
  ];

  const chicruitingMenuList = [
    { name: "공고 지원자 관리", path: "/chicruiting/company/mypage" },
    { name: "병원 프로필", path: `/chicruiting/company/mypage/profile` },
    { name: "설정", path: "/chicruiting/company/mypage/settings" },
  ];

  return (
    <div className="menu__layout">
      {props.isUnified && (
        <div className="text-xs font-semibold mb-1 text-secondary-500">모어덴</div>
      )}
      <nav className="lnb">
        {menuList.map((menu, index) => {
          const isMyHospitalMenu = menu.path.includes("myhospital");
          return (
            <div className={`lnb-menu-box ${isMyHospitalMenu ? "event-badge" : ""}`} key={index}>
              <Link to={menu.path} className={props.current === menu.current ? "on" : "relative"}>
                {menu.name}
                {menu.path.includes("subuser") && (
                  <span className="absolute -right-1 -top-1 flex items-center justify-center rounded-[10px] bg-red-500 px-2 text-[12px] text-white">
                    NEW
                  </span>
                )}
              </Link>
            </div>
          );
        })}
      </nav>
      {props.isUnified && (
        <>
          <div className="text-xs font-semibold mt-5 mb-1 text-secondary-500">치크루팅</div>
          <nav className="lnb">
            {chicruitingMenuList.map((menu, index) => {
              return (
                <div className={`lnb-menu-box`} key={index}>
                  <Link to={menu.path}>{menu.name}</Link>
                </div>
              );
            })}
          </nav>
        </>
      )}
    </div>
  );
}

export default SideMenu;
