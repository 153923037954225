import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { KlassProductInfo } from "types/klass";
import { useProducts } from "api/market/product/hooks";
import ProductUnit from "component/market/product/ProductUnit";

const KlassBookSwiper = ({ lectureProductId }: { lectureProductId?: string }) => {
  const { data: relatedProductIds } = useProducts(
    {
      ids: [lectureProductId],
      showAll: true,
    },
    {
      enabled: !!lectureProductId,
      select: (response: KlassProductInfo) => {
        return response?.hits?.[0]?.related_product_ids || [];
      },
    },
  ) as { data: string[] };

  const { data: relatedProductInfoList } = useProducts(
    {
      ids: relatedProductIds || [],
    },
    {
      enabled: !!relatedProductIds?.length,
      select: (response: KlassProductInfo) => {
        return response?.hits || [];
      },
    },
  ) as { data: KlassProductInfo["hits"] };

  if (!relatedProductIds?.length || !relatedProductInfoList?.length) return null;

  return (
    <div className="mt-[50px] p-[1px]">
      <h3 className="text-[#333] font-bold text-[22px] mb-[5px] px-4 lg:px-0">강의 연관 도서 📚</h3>
      <div className="lg:w-[694px] relative">
        <Swiper
          modules={[Navigation]}
          slidesPerView={4}
          className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events lg:-ml-2.5"
          // loop={true}
          parallax={true}
          slideToClickedSlide={true}
          navigation={{
            nextEl: ".book-swiper-next-btn",
            prevEl: ".book-swiper-prev-btn",
          }}
        >
          {relatedProductInfoList.map((product: { [key: string]: any }) => {
            return (
              <SwiperSlide key={product.id}>
                <div className="p-[10px] m-1 cursor-pointer group">
                  <ProductUnit
                    key={product.id}
                    id={product.id}
                    name={
                      product?.meta_title || product?.product?.meta_title || product?.name || ""
                    }
                    regularPrice={product.regular_price}
                    salePrice={product.sale_price}
                    discountPercent={Math.round(
                      ((product.regular_price - product.sale_price) / product.regular_price) * 100,
                    )}
                    thumbnail={product?.images?.[0]?.url || product?.image}
                    categoryIds={product?.category_ids || []}
                    productCode={product?.product_code}
                    brandName={product?.brand?.englishName}
                    isSoldOut={product?.is_sold_out}
                    isLoadingUnit={false}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default KlassBookSwiper;
