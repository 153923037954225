import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { BASE_URL, cls, api, Img, marketUrl } from "../../utils/util";
import AlarmHeader from "./AlarmHeader";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { headerColorState } from "state";
import { EDU_ROUTE, CLASS_ROUTE } from "App";
import HeaderFlag from "./HeaderFlag";

import WingBanner from "component/advertisment/banner";
import { isMarketOnlyUser, myPageUrl } from "utils/marketUtil";

const Header = ({ current }) => {
  const token = useRecoilValue(tokenState);
  const location = useLocation();
  const navigate = useNavigate();
  const headerColor = useRecoilValue(headerColorState);
  const user = token && token.user_type;
  const [scrolled, setScrolled] = useState(window.scrollY > 60);
  const { data: userCount, isLoading: userCountLoading } = useQuery(
    "utilUserCount",
    () => axios.get(`${BASE_URL}/util/user_count`).then((res) => res.data),
    { cacheTime: Infinity, staleTime: 15 * 60 * 1000 },
  );
  const { data: boardList } = useQuery(
    "boardCateCd",
    () => api.get("/board/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
      enabled: !!(user === "student" || user === "doctor"),
    },
  );
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  const tabs = [
    {
      label: "클래스",
      value: "class",
      url: "/class",
      subTabs: [
        { label: "클래스", value: "class", url: `/${CLASS_ROUTE}` },
        { label: "보수교육", value: `edu`, url: `/${EDU_ROUTE}` },
        { label: "통치", value: "tongtest", url: "/tongtest" },
      ],
    },
    {
      label: "마켓",
      value: "market",
      url: marketUrl(),
      subTabs: [],
    },
    { label: "콘텐츠", value: "contents", url: "/contents", subTabs: [] },
    { label: "게시판", value: "board", url: "/post", subTabs: [] },
    {
      label: "Q&A",
      value: "qna",
      url: "/qna",
      subTabs: [
        { label: "치과의사", value: "doctor", url: "/qna/doctor" },
        { label: "치대생", value: "student", url: "/qna/student" },
      ],
    },
    { label: "임상포럼", value: "forum", url: "/forum", subTabs: [] },
    {
      label: "개원/경영",
      value: "management",
      url: "/management",
      subTabs: [
        { label: "컨설팅", value: "consulting", url: "/management/consulting" },
        { label: "금융/대출", value: "loan", url: "/management/loan" },
        { label: "기공소", value: "lab", url: "/management/lab" },
        { label: "병원 양도/임대", value: "estate", url: "/management/estate" },
        { label: "중고장터", value: "market", url: "/management/market" },
      ],
    },
    { label: "치크루팅", value: "chicruiting", url: "/chicruiting/company/mypage", subTabs: [] },
    {
      label: "구인구직",
      value: "recruit",
      url: "/recruit/doctor",
      subTabs: [],
    },

    {
      label: "세미나",
      value: "seminar",
      url: "/management/seminar",
      subTabs: [
        { label: "세미나", value: "seminar", url: "/management/seminar" },
        { label: "주간세미나", value: "seminar_weekly", url: "/management/seminar_weekly" },
      ],
    },
    { label: "상영관", value: "expo", url: "/expo", subTabs: [] },
    { label: "전치제", value: "festival", url: "/festival", subTabs: [] },
  ];

  const filteredTabs = tabs.filter(
    ({ value }) => !isMarketOnlyUser(token?.user_type) || value === "market",
  );

  const currentHasSubTab = tabs.find(
    (tab) => current === tab.value && (tab.subTabs.length > 0 || tab.value === "board"),
  );

  const onPageScroll = () => {
    setScrolled(window.scrollY > 60);
  };

  useEffect(() => {
    window.addEventListener("scroll", onPageScroll, false);
    return () => {
      window.removeEventListener("scroll", onPageScroll, false);
    };
  }, []);

  const isShowHeaderWingBanner = useMemo(() => {
    const locationPath = location.pathname.split("/")[1];
    return !["market"].includes(locationPath);
  }, [location.pathname]);

  return (
    <section className={cls(scrolled ? (currentHasSubTab ? "pb-[155px]" : "pb-[120px]") : "")}>
      <header
        className={cls(
          scrolled ? "fixed" : "relative",
          "top-0 left-0 right-0 flex flex-col z-[100] w-[1200px] mx-auto",
        )}
      >
        {isShowHeaderWingBanner && (
          <>
            <div
              className={cls(
                "2xl:flex hidden",
                "absolute flex flex-col gap-[12px] w-[120px]",
                scrolled ? "top-[56px] left-[-140px]" : "top-[114px] left-[-140px]",
              )}
            >
              <WingBanner name="pc_all_wing_left_top" page="all" />
              <WingBanner name="pc_all_wing_left_middle" page="all" />
              <WingBanner name="pc_all_wing_left_bottom" page="all" />
            </div>
            <div
              className={cls(
                "2xl:flex hidden",
                "absolute flex flex-col gap-[12px] w-[120px]",
                scrolled ? "top-[56px] right-[-140px]" : "top-[114px] right-[-140px]",
              )}
            >
              <WingBanner name="pc_all_wing_right_top" page="all" />
              <WingBanner name="pc_all_wing_right_middle" page="all" />
              <WingBanner name="pc_all_wing_right_bottom" page="all" />
            </div>
          </>
        )}{" "}
        <div
          className={cls(
            scrolled ? "hidden" : "w-[1200px] mx-auto flex justify-between items-center h-[60px]",
          )}
        >
          <Link to="/">
            <Logo color={headerColor} />
          </Link>
          <div className="flex items-center mt-3 mr-3 gap-4">
            <form
              className="text-[14px] relative"
              onSubmit={(e) => {
                e.preventDefault();
                const q = searchKeyword.trim();
                navigate(`/search?indexUid=all&q=${q}`);
                setSearchKeyword("");
              }}
            >
              <i className="ii ii-search absolute left-[1px] px-2.5 h-full flex items-center leading-[24px] text-[24px] text-gray-800"></i>
              <input
                type="search"
                className="w-[320px] h-[40px] pl-[44px] py-2 rounded-md bg-gray-50 border border-solid border-secondary-200 placeholder-primary-800 focus:ring-1 focus:ring-primary-800 search-cancel:appearance-none focus:placeholder-opacity-0"
                value={searchKeyword}
                onChange={handleSearchKeyword}
                placeholder={'"공동구매"를 검색해 보세요'}
              />
              {searchKeyword && (
                <i
                  className="ii ii-remove-circle h-full leading-[20px] text-[20px] text-gray-800 absolute top-0 right-[1px] cursor-pointer px-2.5 flex items-center"
                  onClick={() => setSearchKeyword("")}
                ></i>
              )}
            </form>
            <div className="flex items-center gap-2">
              {user && !isMarketOnlyUser(user) && (
                <AlarmHeader>
                  <i
                    className={cls(
                      headerColor
                        ? `text-${headerColor} hover:bg-white/10`
                        : "text-gray-600 hover:bg-gray-100",
                      "ii ii-bell-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
                    )}
                  />
                </AlarmHeader>
              )}
              <Link to={myPageUrl(token?.user_type)}>
                <i
                  className={cls(
                    headerColor
                      ? `text-${headerColor} hover:bg-white/10`
                      : "text-gray-600 hover:bg-gray-100",
                    "ii ii-user-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
                  )}
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          className={cls(
            !scrolled && currentHasSubTab ? "border-b-[1px] h-[95px]" : "",
            "border-solid border-gray-200 border-t-[1px] border-opacity-70",
          )}
        >
          <nav
            className={cls(
              scrolled ? "bg-white border-b-[1px]" : "",
              currentHasSubTab ? "border-b-[1px]" : "",
              "border-solid border-gray-300 = border-opacity-70",
            )}
          >
            <div className="w-[1200px] mx-auto flex">
              {filteredTabs.map((tab) => {
                return (
                  <ul
                    className="relative px-4 first:pl-2 whitespace-nowrap flex-shrink-0"
                    key={tab.label}
                  >
                    {!scrolled && <HeaderFlag value={tab.value} />}
                    <TabItem current={current} tab={tab} color={scrolled ? null : headerColor}>
                      {!scrolled && tab.value === "board" && current === tab.value && (
                        <nav className="mt-2 w-[1050px] scroll_none overflow-scroll absolute flex text-gray-600">
                          {boardList &&
                            boardList.map((subTab) => {
                              return (
                                <ul
                                  className="relative px-4 first:pl-0 whitespace-nowrap"
                                  key={subTab.cate_name}
                                >
                                  <BoardSubTabItem tab={subTab} />
                                </ul>
                              );
                            })}
                        </nav>
                      )}
                      {!scrolled && tab.subTabs.length > 0 && current === tab.value && (
                        <nav className="absolute w-0 flex text-gray-600">
                          {tab.subTabs.map((subTab) => {
                            return (
                              <ul
                                className="mt-2 relative px-4 first:pl-0 whitespace-nowrap"
                                key={subTab.label}
                              >
                                <SubTabItem
                                  pathname={location.pathname}
                                  tab={subTab}
                                  color={scrolled ? null : headerColor}
                                />
                              </ul>
                            );
                          })}
                        </nav>
                      )}
                    </TabItem>
                  </ul>
                );
              })}
            </div>
          </nav>
        </div>
      </header>
    </section>
  );
};

export default Header;

const TabItem = ({ current, tab, children, color }) => {
  return (
    <>
      <Link
        className={cls(
          color ? `text-${color}` : "",
          current === tab.value
            ? color
              ? "text-opacity-100 font-bold"
              : "text-primary-800 font-medium"
            : color
            ? "text-opacity-50 font-medium hover:text-opacity-100 hover:font-bold"
            : "font-medium hover:text-primary-800",
          "text-[14px] py-4 flex",
        )}
        to={tab.url}
      >
        {tab.value === "chicruiting" ? (
          <img
            src={Img("", color ? `img/chicruiting_logo_${color}.svg` : `img/chicruiting_logo.svg`)}
            alt=""
            className="w-[73px]"
          />
        ) : (
          tab.label
        )}
      </Link>
      {children}
    </>
  );
};

const SubTabItem = ({ pathname, tab, color }) => {
  // url이 /management/seminar_weekly일 때 /management/seminar도 하이라이트 되는 문제 해결
  const seminarException =
    pathname.includes("/management/seminar_weekly") && tab.url === "/management/seminar";

  // url이 /recruit이거나 /recruit/chicruiting일 때 치과위생사가 하이라이트 되도록 함
  const recruitException =
    (pathname === "/recruit" && tab.url === "/recruit/hygienist") ||
    (pathname.includes("/recruit/chicruiting") && tab.url === "/recruit/hygienist");

  return (
    <Link
      className={cls(
        color ? `text-${color}` : "",
        (pathname.includes(tab.url) && !seminarException) || recruitException
          ? color
            ? `text-opacity-100 font-bold border-b-[3px] border-solid border-${color}`
            : `text-primary-800 font-medium border-b-[3px] border-solid border-primary-800`
          : color
          ? "text-opacity-50 hover:text-opacity-100"
          : "text-gray-600 hover:text-primary-800",
        "text-[14px] py-[9px]",
      )}
      to={tab.url}
    >
      {tab.label}
    </Link>
  );
};

const BoardSubTabItem = ({ tab }) => {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const board = parseInt(urlParam.get("cate")) || 0;

  return (
    <Link
      className={cls(
        board === tab.cate_cd
          ? "text-primary-800 border-b-[3px] border-solid border-primary-800"
          : "text-gray-600",
        "text-[14px] py-[9px] hover:text-primary-800",
      )}
      to={`/post?cate=${tab.cate_cd}`}
    >
      {tab.cate_name}
    </Link>
  );
};

const Logo = ({ color }) => {
  return (
    <svg width="138" height="24" viewBox="0 0 138 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2029_18543)">
        <path
          d="M29.0615 21.8495H26.6521V8.96493H29.0615V10.5794C29.8465 9.3956 31.1465 8.64246 32.796 8.64246C34.8265 8.64246 36.3421 9.60989 37.1271 11.2514C37.8304 9.74512 39.2644 8.64246 41.3493 8.64246C44.3532 8.64246 46.3021 10.6605 46.3021 13.9686V21.8495H43.8927V14.3451C43.8927 12.2209 42.7288 10.8748 40.8071 10.8748C38.8855 10.8748 37.6672 12.2188 37.6672 14.3451V21.8495H35.285V14.3451C35.285 12.2209 34.0939 10.8748 32.1722 10.8748C30.2505 10.8748 29.0594 12.2188 29.0594 14.3451V21.8495H29.0615Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M49.0088 15.3937C49.0088 11.655 51.9039 8.64246 55.6132 8.64246C59.3226 8.64246 62.2449 11.655 62.2449 15.3937C62.2449 19.1324 59.3498 22.172 55.6132 22.172C51.8766 22.172 49.0088 19.1324 49.0088 15.3937ZM59.8333 15.3937C59.8333 12.8659 58.075 10.8748 55.6383 10.8748C53.2017 10.8748 51.4161 12.8659 51.4161 15.3937C51.4161 17.9215 53.2017 19.9396 55.6383 19.9396C58.075 19.9396 59.8333 17.8945 59.8333 15.3937V15.3937Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M67.4802 21.8495H65.0708V8.96493H67.4802V10.5794C68.1836 9.3956 69.293 8.64246 70.727 8.64246C71.5392 8.64246 72.2425 8.83178 72.703 9.20627L71.7024 11.4387C71.2691 11.1432 70.6474 10.9269 70.0236 10.9269C68.4536 10.9269 67.4802 12.2709 67.4802 14.4242V21.8474V21.8495Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M73.2704 15.3937C73.2704 11.655 76.0043 8.64246 79.6843 8.64246C83.3644 8.64246 86.0711 11.5198 86.0711 15.1524C86.0711 15.4748 86.0438 15.8244 85.9894 16.0137H75.7049C75.975 18.3272 77.681 20.0478 80.1428 20.0478C81.6039 20.0478 82.9039 19.484 83.7684 18.6226L85.0411 20.2642C83.9861 21.3939 82.1461 22.1741 80.0611 22.1741C76.1089 22.1741 73.2683 19.1345 73.2683 15.3958L73.2704 15.3937ZM75.7594 14.4263H83.6072C83.2283 12.0316 81.74 10.6876 79.7095 10.6876C77.6789 10.6876 76.1089 12.275 75.7573 14.4263H75.7594Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M101.712 3.26225V21.8495H98.3839V20.4514C97.4377 21.5541 96.1106 22.2261 94.4317 22.2261C90.9945 22.2261 88.2062 19.4278 88.2062 15.4207C88.2062 11.4137 90.8312 8.58835 94.4317 8.58835C96.1378 8.58835 97.4628 9.20626 98.3839 10.2569V4.87464C98.3839 4.70404 98.476 4.548 98.6246 4.46478L101.239 2.98762C101.451 2.86904 101.714 3.02091 101.714 3.26225H101.712ZM98.3839 15.3937C98.3839 13.3215 96.95 11.6821 94.9739 11.6821C92.9978 11.6821 91.5094 13.3236 91.5094 15.3937C91.5094 17.4638 92.9706 19.1324 94.9739 19.1324C96.9772 19.1324 98.3839 17.4638 98.3839 15.3937Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M103.942 15.4207C103.942 11.655 106.757 8.58832 110.492 8.58832C114.226 8.58832 117.014 11.4407 117.014 15.1523C117.014 15.5559 116.987 16.0136 116.933 16.2279H107.324C107.649 18.0297 109.031 19.3217 111.032 19.3217C112.332 19.3217 113.441 18.7578 114.199 17.9776L115.903 20.21C114.93 21.3397 113.087 22.2281 110.896 22.2281C106.864 22.2281 103.94 19.1615 103.94 15.4228L103.942 15.4207ZM107.379 14.1558H113.632C113.253 12.327 112.089 11.3845 110.546 11.3845C108.894 11.3845 107.758 12.5142 107.379 14.1558Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M122.422 21.8495H119.12V8.96489H122.422V10.2298C123.234 9.18126 124.479 8.58832 126.074 8.58832C129.078 8.58832 131 10.6855 131 14.0496V21.8495H127.699V14.5323C127.699 12.7847 126.671 11.68 125.074 11.68C123.477 11.68 122.422 12.7826 122.422 14.5323V21.8495V21.8495Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M17.5336 0.416282C17.5336 0.0979646 17.1861 -0.101764 16.9077 0.0563545L13.2695 2.09941L13.2905 6.73686C11.9633 5.95251 10.4142 5.50312 8.75841 5.51144C3.89982 5.53225 -0.0209718 9.4644 -3.86369e-05 14.2953C0.0229879 19.1242 3.97936 23.0231 8.83796 23.0002C13.6421 22.9794 17.5273 19.1325 17.5943 14.3786H17.5985L17.5357 0.416282H17.5336ZM8.81912 18.7539C6.3197 18.7643 4.2829 16.7607 4.27034 14.2745C4.25987 11.7904 6.27574 9.76608 8.77725 9.7536C11.2767 9.74319 13.3135 11.7467 13.326 14.2329C13.3365 16.7171 11.3206 18.7414 8.81912 18.7539V18.7539Z"
          fill={color ? color : "#4A25AA"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2029_18543">
          <rect width="131" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
