/* eslint-disable react-hooks/rules-of-hooks */

import { resizedImg } from "../../utils/util";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AdsCampaign } from "../../types/advertisement";
import { useCallback } from "react";

interface RollingBannerProps {
  data?: Array<AdsCampaign>;
  // 1. data: Array<AdsCampaign> : 광고 데이터
  identifier: string;
  // 2. identifier: string : 광고 식별자 유일해야함
  disableControl?: boolean;
  // 3. disableControl?: boolean : 컨트롤러 사용여부
  customControlPanel?: JSX.Element;
}

function RollingBanner(props: RollingBannerProps) {
  const { data: adsCampaign, identifier, disableControl, customControlPanel } = props;

  if (adsCampaign === undefined || adsCampaign.length === 0) return <></>;

  const isOnlyOneCampaign = adsCampaign.length === 1;

  const ControlPanel = useCallback(() => {
    if (isOnlyOneCampaign) {
      return <></>;
    }

    if (customControlPanel) {
      return customControlPanel;
    }

    if (!disableControl) {
      return (
        <div className="nav__box flex">
          <div className={`groupbuying-fraction ${identifier}-fraction`} />
          <div className={`swiper-button-prev1 cursor ${identifier}-prev`}>←</div>
          <div className={`swiper-button-next1 cursor ${identifier}-next`}>→</div>
        </div>
      );
    }

    return <></>;
  }, []);

  return (
    <>
      <section className="home-main-banner">
        <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
          onSlideChange={(s) => {
            if (adsCampaign && adsCampaign[s.realIndex]?.segment)
              window.analytics.track("Ads Viewed", adsCampaign[s.realIndex].segment);
          }}
          navigation={{
            nextEl: `.${identifier}-next`,
            prevEl: `.${identifier}-prev`,
          }}
          pagination={{
            clickable: true,
            el: `.${identifier}-fraction`,
            type: "custom",
            renderCustom: function (_swiper, current, total) {
              return `${current}/<span style="color: #ccc">${total}</span>`;
            },
          }}
          slidesPerView="auto"
          loop={true}
          parallax={true}
          slideToClickedSlide={true}
          autoplay={{
            delay: 5000,
          }}
          wrapperTag="ul"
          speed={500}
        >
          {adsCampaign.map(
            ({
              inventoryWidth: width,
              inventoryHeight: height,
              segment,
              link,
              advertisementFile: { url },
            }) => {
              const bannerClicked = () => {
                if (!segment) return;
                window.analytics.track("Ads Clicked", segment);
              };

              const tmpLink = (() => {
                if (link.indexOf('http') === 0) {
                  return link;
                }
                if (link === "/") {
                  return "https://market.moreden.co.kr/";
                }
                if (link.includes("/product/")) {
                  return `https://market.moreden.co.kr${link}`;
                }
                return link;
              })(); // FIXME: 추후 서브도메인 구분 가능할 경우 해당 로직 삭제

              return (
                <SwiperSlide
                  tag="li"
                  key={segment.creative}
                  style={{ cursor: "pointer" }}
                  className="swiper-slide"
                >
                  <Link className="inline-block" to={tmpLink} onClick={bannerClicked}>
                    <img
                      className={"rounded-md"}
                      src={resizedImg(url, width * 2)}
                      style={{
                        objectFit: "cover",
                        width: `${width}px`,
                        minHeight: `${height}px`,
                        objectPosition: "center top",
                      }}
                    />
                  </Link>
                </SwiperSlide>
              );
            },
          )}
        </Swiper>
        <ControlPanel />
      </section>
    </>
  );
}

export default RollingBanner;
