import React, { useEffect, useMemo } from "react";
import { CLASS_ROUTE } from "App";
import { Link, useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import CourseCard from "component/klass/CourseCard";
import { createHighlightedTextWrapper } from "utils/searchUtil";
import { useSearchSegemnet } from "hooks/useSearchSegment";

const SearchResultClass = ({ searchResult, hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  // ---------- 검색 결과 총 개수 ----------
  const resultsTotalCount = useMemo(() => {
    return searchResult?.[0]?.estimatedTotalHits || 0;
  }, [searchResult]);

  // ---------- 검색 리스트 ----------
  const classCourseList = useMemo(() => {
    if (!searchResult) return [];
    return searchResult.reduce((acc, cur) => {
      if (cur?.hits) {
        return [...acc, ...cur.hits];
      }
      return acc;
    }, []);
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  // ---------- 하이라이트 텍스트 (보라색 강조) 생성 ----------
  const [searchParams] = useSearchParams();
  const params = {
    q: searchParams.get("q") || "",
  };
  const highlightTextWrapper = createHighlightedTextWrapper(params.q);

  return (
    <div className="mt-[20px]">
      <div className="flex flex-col gap-[32px]">
        {resultsTotalCount > 0 ? (
          <div className="lg:ml-[-10px] grid grid-cols-2 lg:grid-cols-3">
            <GridCourseCard
              classCourseList={classCourseList}
              highlightedText={highlightTextWrapper}
            />
          </div>
        ) : (
          <p>검색 결과가 없습니다.</p>
        )}
      </div>
      {hasNextPage && !isFetchingNextPage && <div className="mt-[20px]" ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultClass;

const GridCourseCard = ({ classCourseList, highlightedText }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  if (!classCourseList) return null;

  return (
    <>
      {classCourseList.map((course) => {
        return (
          <Link
            className="block"
            to={`/${CLASS_ROUTE}/course/${course.id}`}
            key={course.id}
            onClick={() => handleSearchItemClick({ id: course.id, title: course.title })}
          >
            <CourseCard
              thumbnail={course.thumbnailImageUrl}
              lecturers={course.lecturers}
              title={course.title}
              regularPrice={course.product?.regularPrice}
              salePrice={course.product?.salePrice}
              topic={course.groupedBadges?.topics}
              level={course.groupedBadges?.level?.join(",")}
              isFree={course.isFree}
              highlightedText={highlightedText}
            />
          </Link>
        );
      })}
    </>
  );
};
