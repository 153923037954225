import { Route, Routes } from "react-router-dom";
import MyPost from "./MyPost";
import Notice from "./Notice";
import NoticeDetail from "./NoticeDetail";
import MyScrap from "./MyScrap";
import PointHistory from "../../component/mypage/PointHistory";
import Alarm from "./Alarm";
import MyPage from "./MyPage";
import Block from "../../component/mypage/Block";
import ChannelTalk from "../../component/ChannelTalk";
import MyHospital from "./MyHospital";
import MyChicruiting from "./MyChicruiting";
import MyKlass from "./MyKlass";
import { CLASS_ROUTE } from "App";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import MySubscription from "./MySubscription";
import MyImplant from "./MyImplant";

function MyPageRoute() {
  return (
    <section className="relative min-h-[100vh]">
      <Header current="" />
      <article className="pt-10 pb-64">
        <Routes>
          <Route path="/*" element={<MyPage />} />
          <Route path="/myhospital" element={<MyHospital />} />
          <Route path="/myimplant" element={<MyImplant />} />
          <Route path="/mypost" element={<MyPost />} />
          <Route path="/mychicruiting" element={<MyChicruiting />} />
          <Route path="/myscrap" element={<MyScrap />} />
          <Route path="/block" element={<Block />} />
          <Route path="/point/:type" element={<PointHistory />} />
          <Route path="/notice/:bid" element={<NoticeDetail />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/alarm" element={<Alarm />} />
          <Route path={`/${CLASS_ROUTE}`} element={<MyKlass />} />
          <Route path="/mysubscription" element={<MySubscription />} />
        </Routes>
      </article>
      <Footer />
      <ChannelTalk />
    </section>
  );
}

export default MyPageRoute;
