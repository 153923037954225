import React from "react";
import { Link } from "react-router-dom";
import { api } from "../../utils/util";
import { useQuery } from "react-query";
import Blur from "../../page/user/Blur";
import CensoredData from "page/user/CensoredData";

// 실시간 HOT 구인공고
function HotRecruit() {
  const { data: recruitHotState, isLoading: recruitHotStateLoading } = useQuery(
    "managementRecruitHot",
    () => api.get("management/recruit/hot").then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );
  return (
    <div className="col-span-2">
      <span className="flex justify-between items-center pb-3">
        <b className="text-[20px]">실시간 HOT 구인공고</b>
        <Link
          to="/recruit/doctor"
          className="cursor-pointer text-[13px] text-gray-600 hover:underline"
        >
          + 더보기
        </Link>
      </span>
      <div className="h-[230px]">
        <Blur>
          <div className="hard-blur h-full">
            {!recruitHotStateLoading &&
              recruitHotState &&
              recruitHotState.slice(0, 5).map((item, i) => {
                return (
                  <Link
                    key={item.bid}
                    to={`/recruit/doctor/${item.bid}`}
                    className="border-solid border-b-[1px] text-gray-900 hover:text-primary-800 border-b-gray-100 flex justify-between items-center h-[20%] cursor-pointer"
                  >
                    <div className="!text-primary-800 bg-primary-100 rounded-sm text-[13px] w-[100px] text-center py-1 mr-3">
                      {(item.location1 + " " + item.location2).slice(0, 6)}
                    </div>
                    <div className="ellipsis w-full mr-3 font-medium">
                      <CensoredData>{item.title}</CensoredData>
                    </div>
                  </Link>
                );
              })}
          </div>
        </Blur>
      </div>
    </div>
  );
}

export default HotRecruit;
