import Badge from "./badge";
import ProfileBox from "./profileBox";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import Badges from "./badges";

const Nickname = ({ uid, unick, level, isDetail, isOwner }) => {
  const token = useRecoilValue(tokenState);
  const [popup, setPopup] = useState(false);
  const hasUid = uid && uid > 0 ? true : false;

  const checkAdmin = (unick) => {
    if (level === "admin") return "모어덴";
    if (level === "admin_class") return "모어덴클래스";
    return unick;
  };

  return (
    <div
      className={hasUid ? "nick-wrapper w-full cursor" : "w-full nick-wrapper"}
      onMouseOver={() => setPopup(true)}
      onMouseOut={() => setPopup(false)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="nick-box">
        {level && (
          <Badge
            item_level={level}
            style={
              isDetail && { position: "relative", transform: "translate(0%, 0%)", marginRight: 10 }
            }
          />
        )}
        <span className="nick mr-2 truncate">{checkAdmin(unick)}</span>
        <Badges isOwner={isOwner} uid={uid} />
      </div>
      {token && hasUid && popup && <ProfileBox uid={uid} isOwner={isOwner} />}{" "}
    </div>
  );
};

export default Nickname;
