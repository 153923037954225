import { useRecoilValue } from "recoil";
import { headerColorState } from "../../state";

const HeaderColor = ({ children }) => {
  const HeaderColor = useRecoilValue(headerColorState);
  if (!HeaderColor) return children;
  if (HeaderColor === "black") return <div className="contents__black-header">{children}</div>;
  return <div className="contents__white-header">{children}</div>;
};

export default HeaderColor;
