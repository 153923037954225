import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./page/Home";
import { useRecoilValue } from "recoil";
import { tokenState } from "./state";
import { useState } from "react";
import Login from "./page/user/Login";
import Logout from "./page/user/Logout";
import Verify from "./page/user/Verify";
import BusinessInfo from "./page/footer/BusinessInfo";
import Service from "./page/footer/Service";
import Privacy from "./page/footer/Privacy";
import Board from "./page/board/Board";
import Management, { PublicManagement } from "./page/management/Management";
import TongTest from "./page/tongtest/TongTest";
import Festival from "./page/festival/Festival";
import IEAlert from "./component/ie_alert";
import { parseJSON } from "./utils/util";
import Qna from "./page/qna/Qna";
import Forum from "./page/forum/Forum";
import Join from "./page/user/Join";
import PhoneVerify from "./page/user/PhoneVerify";
import Recommender from "./page/user/Recommender";
import FindId from "./page/user/FindId";
import FindPwd from "./page/user/FindPwd";
import RequestSignup from "./page/user/RequestSignup";
import analytics from "./firebase";
import { setUserId } from "firebase/analytics";
import Class from "./page/class/Class";
import KakaoLogin from "./page/user/KakaoLogin";
import SendAppleToken from "./page/user/SendAppleToken";
import SyncAccount from "./page/user/SyncAccount";
import Recruit from "./page/recruit/Recruit";
import Contents from "./page/contents/Contents";
import ExpoRoute from "./component/expo/ExpoRoute";
import MyPageRoute from "./page/mypage/MyPageRoute";
import ProfilePage from "page/profile/ProfilePage";
import KlassRoute from "page/mordern_class/KlassRoute";
import Seminar, { SeminarWeeklyRoute } from "./page/management/seminar/Seminar";
import Auth from "./page/user/Auth";
import OutdatedReloader from "component/OutdatedReloader";
import PageTracker from "component/PageTracker";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Chicruiting from "page/chicruiting/Chicruiting";
import SearchPage from "page/search/SearchPage";
import CheckCI from "./component/CheckCI";
import UnloginChicruiting from "page/chicruiting/UnloginChicruiting";
import PortalRoot from "component/PortalRoot";
import { isMarketOnlyUser } from "utils/marketUtil";

export const CLASS_ROUTE = "class";
export const EDU_ROUTE = "edu";

function App() {
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const [isClosed, setIsClosed] = useState(parseJSON(sessionStorage.getItem("ie_closed")));
  const rcode = new URLSearchParams(location.search).get("rcode");
  const isIE = navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode === true;

  analytics && token && token.uid && setUserId(analytics, token.uid);

  if (rcode && !localStorage.getItem("rcode")) {
    localStorage.setItem("rcode", rcode);
  }

  if (isIE && !isClosed) {
    return <IEAlert continueIE={setIsClosed} />;
  }

  // 모어덴 마켓 전용 계정
  if (token && token?.user_type && isMarketOnlyUser(token.user_type)) {
    return (
      <>
        <Routes>
          <Route element={<PageTracker />}>
            <Route path="/footer/business/*" element={<BusinessInfo />} />
            <Route path="/footer/service/*" element={<Service />} />
            <Route path="/footer/privacy/*" element={<Privacy />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="/logout/*" element={<Logout />} />
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<RequestSignup />} />
          </Route>
        </Routes>
        <PortalRoot />
      </>
    );
  }

  // 커뮤니티 계정
  if (token && token.uid && token?.user_type) {
    return (
      <>
        <Routes>
          <Route element={<OutdatedReloader />}>
            <Route element={<PageTracker />}>
              <Route element={<CheckCI />}>
                <Route path="/" element={<Home />} />
                <Route path="/recruit/*" element={<Recruit />} />
                <Route path="/chicruiting/*" element={<Chicruiting />} />
                <Route path="/syncAccount/*" element={<SyncAccount />} />
                <Route path="/festival/*" element={<Festival />} />
                <Route path="/expo/*" element={<ExpoRoute />} />
                <Route path="/post/*" element={<Board />} />
                <Route path="/tongtest/*" element={<TongTest />} />
                <Route path={`/${EDU_ROUTE}/*`} element={<Class />} />
                <Route path={`/${CLASS_ROUTE}/*`} element={<KlassRoute />} />
                <Route path="/search/*" element={<SearchPage />} />
                <Route path="/contents/*" element={<Contents />} />
                <Route path="/qna/*" element={<Qna />} />
                <Route path="/forum/*" element={<Forum />} />
                <Route path="/management/seminar/*" element={<Seminar />} />
                <Route path="/management/seminar_weekly/*" element={<SeminarWeeklyRoute />} />
                <Route path="/management/*" element={<Management />} />
                <Route path="/mypage/*" element={<MyPageRoute />} />
                <Route path="/footer/business" element={<BusinessInfo />} />
                <Route path="/sendKakaoToken/*" element={<KakaoLogin />} />
                <Route path="/footer/service/*" element={<Service />} />
                <Route path="/footer/privacy/*" element={<Privacy />} />
                <Route path="/profile/:uid" element={<ProfilePage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
              <Route path="/logout/*" element={<Logout />} />
            </Route>
          </Route>
        </Routes>
        <PortalRoot />
      </>
    );
  }

  // 비로그인 이거나 면허 승인 대기중인 계정
  return (
    <>
      <Routes>
        <Route element={<PageTracker />}>
          <Route path={`/${EDU_ROUTE}/*`} element={<Class />} />
          <Route path={`/${CLASS_ROUTE}/*`} element={<KlassRoute />} />
          <Route path="/recruit/*" element={<Recruit />} />
          <Route path="/chicruiting/*" element={<UnloginChicruiting />} />
          <Route path="/contents/*" element={<Contents />} />
          <Route path="/festival/*" element={<Festival />} />
          <Route path="/expo/*" element={<ExpoRoute />} />
          <Route path="/tongtest/*" element={<TongTest />} />
          <Route path="/management/seminar/*" element={<Seminar />} />
          <Route path="/management/seminar_weekly/*" element={<SeminarWeeklyRoute />} />
          <Route path="/management/*" element={<PublicManagement />} />
          <Route path="/footer/business/*" element={<BusinessInfo />} />
          <Route path="/footer/service/*" element={<Service />} />
          <Route path="/footer/privacy/*" element={<Privacy />} />
          <Route path="/join/*" element={<Join />} />
          <Route path="/phoneVerify/*" element={<PhoneVerify />} />
          <Route path="/recommender/*" element={<Recommender />} />
          <Route path="/verifyPending/*" element={<Verify />} />
          <Route path="/findid/*" element={<FindId />} />
          <Route path="/findpw/*" element={<FindPwd />} />
          <Route path="/sendKakaoToken/*" element={<KakaoLogin />} />
          <Route path="/syncAccount/*" element={<SyncAccount />} />
          <Route path="/sendAppleToken/*" element={<SendAppleToken />} />
          <Route path="/login/*" element={<Login />} />
          <Route path="/logout/*" element={<Logout />} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<RequestSignup />} />
        </Route>
      </Routes>
      <PortalRoot />
    </>
  );
}

export default App;
