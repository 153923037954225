import { Link } from "react-router-dom";
import { writeDayMMDD } from "../../utils/util";
import dayjs from "dayjs";

const RecruitListTable = ({ articles, param, bid }) => {
  return (
    <div className="list">
      <div className="list-head">
        <div className="w15p left">지역</div>
        <div className="w15p left">병원</div>
        <div className="w60p left">제목</div>
        <div className="w10p">작성일</div>
      </div>

      {articles &&
        articles.map((item, i) => {
          return (
            <Link
              className="width-max"
              key={item.bid}
              to={`/recruit/hygienist/${item.bid}?${param}`}
              onClick={(event) => {
                event.stopPropagation();
                window.scrollTo(0, 0);
              }}
            >
              <div className={item.bid === bid ? "slim content current" : "slim content"}>
                <div className="w15p left">
                  <span className="accent">
                    <b>{item.location1}</b> {" " + item.location2}
                  </span>
                </div>
                <div className="w15p left">
                  <span className="left-hospital">{item.hospital_name}</span>
                </div>
                <div className="w60p left">
                  {dayjs(item.terminate_at) < dayjs() && "[마감] "}
                  {item.title}
                </div>
                <div className="w10p">
                  <span>{writeDayMMDD(item.reg_dttm)}</span>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default RecruitListTable;
