import { useRecoilState } from "recoil";
import {
  swipeableDrawerContent,
  isOpenedSwipeableDrawer,
  isHiddenSwipeableDrawer,
  useModalContent,
  isOpenedUseModal,
} from "./atoms";
import { DrawerContentState, ModalContentState } from "types/common";

export const useRcaSwipeableDrawer = () => {
  const [drawerContent, setDrawerContent] =
    useRecoilState<DrawerContentState>(swipeableDrawerContent);
  const [isOpenedDrawer, setIsOpen] = useRecoilState<boolean>(isOpenedSwipeableDrawer);
  const [isHiddenDrawer, setIsHidden] = useRecoilState<boolean>(isHiddenSwipeableDrawer);

  const openDrawer = (
    component: React.ComponentType<React.ReactNode>,
    componentProps: Record<string, unknown> = {},
    drawerProps: Record<string, unknown> = {},
  ) => {
    setDrawerContent({ component, componentProps, drawerProps });
    setIsOpen(true);
  };
  const closeDrawer = () => {
    setIsOpen(false);
  };
  const hideDrawer = () => {
    setIsHidden(true);
  };
  const showDrawer = () => {
    setIsHidden(false);
  };

  return {
    isOpenedDrawer,
    isHiddenDrawer,
    openDrawer,
    closeDrawer,
    hideDrawer,
    showDrawer,
    drawerContent,
  };
};

// MARK: useRcaModal을 직접 사용하지 않고 useModal을 통해 사용하는 것을 권장합니다.
export const useRcaModal = () => {
  const [modalContent, setModalContent] = useRecoilState<ModalContentState>(useModalContent);
  const [isOpenedModal, setIsOpen] = useRecoilState<boolean>(isOpenedUseModal);

  const openModal = (
    component: React.ComponentType<React.ReactNode>,
    componentProps: Record<string, unknown> = {},
    modalProps: Record<string, unknown> = {},
  ) => {
    setModalContent({ component, componentProps, modalProps });
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return {
    isOpenedModal,
    openModal,
    closeModal,
    modalContent,
  };
};
