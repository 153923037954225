import ZoomImg from "component/ZoomImg";
import YTSubtitle from "page/contents/YTSubtitle";
import ProseMirrorEditorView from "component/prosemirror/ProseMirrorEditorView";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { useNavigate, useParams } from "react-router-dom";
import Rating from "component/Rating";
import ShareButton from "component/shared/ShareButton";
import ScrapButton from "component/shared/ScrapButton";
import LikeButton from "component/shared/LikeButton";
import CommentList from "component/comment/CommentList";
import { useMutation, useQueryClient } from "react-query";
import { api, cls } from "../../utils/util";
import { ContentsArticle, Comment, Keyword, Like } from "types/contents";

const ContentsContent = ({
  content,
  setReportPopup,
}: {
  content: ContentsArticle;
  setReportPopup: any;
}) => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);
  const user = token && token.user_type;
  const bid = parseInt({ ...useParams() }.bid || "0");
  const queryClient = useQueryClient();

  const reportComment = (item: any) =>
    setReportPopup({
      category: "contents_comment",
      uuid: item.uuid,
      toggle: true,
    });

  const getLikeInfo = (cid: number | null) => {
    const info = content.likes && content.likes.find((like: Like) => like.cid === cid);
    return info && info.liked;
  };

  const likeChange = useMutation(
    (data: LikeData): Promise<LikeRes> => {
      const res = (api as any).post(`/contents/like/${bid}`, data);
      return res;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["contentsArticle", bid]),
    },
  );

  const scrapChange = useMutation(
    (data: ScrapData): Promise<ScrapRes> => {
      const res = (api as any).post(`/contents/scrap/${bid}`, data);
      return res;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["contentsArticle", bid]),
    },
  );

  const likeClicked = (comment: Comment, curLiked: boolean) => {
    if (comment ? comment.ismine : content.ismine) {
      alert("내가 작성한 글은 추천할 수 없습니다.");
      return;
    }
    const cid = comment ? comment.uuid : null;
    const data: LikeData = {
      cid: cid ?? null,
      liked: !curLiked,
    };
    likeChange.mutate(data);
  };

  const scrapClicked = () => {
    const data: ScrapData = {
      scraped: !content.scraped,
    };

    scrapChange.mutate(data);
  };

  return (
    <div className="px-4 lg:px-0 lg:w-[62.5%] w-full">
      {content.article.video_link && content.article.subtitle_link && (
        <YTSubtitle video={content.article.video_link} subtitle={content.article.subtitle_link} />
      )}
      {content.article?.contentJson ? (
        <ZoomImg>
          <ProseMirrorEditorView contentString={content.article.content} />
        </ZoomImg>
      ) : (
        <ZoomImg>
          <div id="froala_view">
            <FroalaEditorView model={content.article.content} />
          </div>
        </ZoomImg>
      )}
      <div className="mt-20 flex gap-2 justify-center">
        {content.article &&
          content.article.tag &&
          content.article.tag.split(",").map((tag: string) => {
            const keyword = content.keywords.find((item: Keyword) => item.value === tag);
            return (
              <div
                key={tag}
                className="rounded-md p-2 border-[1px] border-solid border-gray-300 cursor-pointer hover:bg-primary-100 hover:border-primary-800"
                onClick={() => {
                  const param = new URLSearchParams();
                  param.append("page", "1");
                  param.append("tag", tag);
                  navigate(`/contents/search?${param}`);
                  window.scrollTo(0, 0);
                }}
              >
                <span
                  className={cls(
                    keyword && keyword.style === "bold" ? "font-bold" : "font-normal",
                    "text-base text-black",
                  )}
                >
                  #{keyword ? keyword.label : tag}
                </span>
              </div>
            );
          })}
      </div>
      {user && (
        <>
          <Rating
            myScore={content.my_score}
            scoreSum={content.article.contents_score_sum}
            scoreCount={content.article.contents_score_count}
          />
          <div className="detail__group">
            <LikeButton
              likeCount={content.article.like}
              liked={getLikeInfo(null)}
              likeClicked={likeClicked}
            />
            <div className="flex justify-center gap-2 mt-5">
              <ScrapButton
                scrapable={!content.ismine}
                scraped={content.scraped}
                scrapClicked={scrapClicked}
              />
              <ShareButton
                title={content.article.title}
                text={`[모어덴 - 콘텐츠]\n${content.article.title}`}
                small={false}
              />
            </div>
            <CommentList
              current="contents"
              content={content}
              bid={bid}
              reportComment={reportComment}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContentsContent;

interface LikeData {
  cid: number | null;
  liked: boolean;
}

interface LikeRes {
  contents_like: number;
}

interface ScrapData {
  scraped: boolean;
}

interface ScrapRes {
  scrap: boolean;
}
