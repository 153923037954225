import { useCallback, useState, useEffect } from "react";
import { AdsCampaign, AdsInventory, BANNER_DISPLAY_TYPE } from "../../types/advertisement";
import RollingBanner from "./rolling";
import StaticBanner from "./static";
import { useAdsInventoryList, useAdsCampaign } from "api/banner/hooks";
import Loading from "component/Loading";

interface BannerProps {
  name: string;
  page: string;
  width?: string | null;
  height?: string | null;
}

const defaultAd: any = {
  segment: {},
  link: "",
  advertisementFile: { formats: {}, url: "" },
};

function Banner(props: BannerProps) {
  const { name, page, width, height } = props;

  const { data: adsInventoryList } = useAdsInventoryList({
    pageName: page,
  }) as { data: Array<AdsInventory> };

  const { data: adsCampaignList, isLoading } = useAdsCampaign({
    name,
    adsInventoryList,
    pageName: page,
  }) as { data: Array<AdsCampaign>; isLoading: boolean };

  const [ads, setAds] = useState<AdsCampaign>({ ...defaultAd });
  const [displayMethod, setDisplayMethod] = useState<BANNER_DISPLAY_TYPE>(
    BANNER_DISPLAY_TYPE.STATIC,
  );

  const getRandomInt = useCallback((min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }, []);

  useEffect(() => {
    if (!adsInventoryList) return;

    if (adsCampaignList && adsCampaignList.length > 0) {
      const displayMethod =
        adsInventoryList?.find((x) => x.inventoryName === name)?.displayMethod || "";
      setDisplayMethod(displayMethod as BANNER_DISPLAY_TYPE);
      if (displayMethod !== "rolling") {
        const selectedAds = adsCampaignList[getRandomInt(0, adsCampaignList.length)];
        setAds(selectedAds);
        window.analytics.track("Ads Viewed", selectedAds.segment);
      }
    }
  }, [adsCampaignList, adsInventoryList, name]);

  if (isLoading) return <Loading small={false} />;

  if (!adsCampaignList || adsCampaignList?.length === 0) return <></>;

  if (displayMethod === BANNER_DISPLAY_TYPE.ROLLING) {
    return <RollingBanner identifier={name} data={adsCampaignList} />;
  }

  return <StaticBanner ads={ads} bannerWidth={width} bannerHeight={height} name={name} />;
}

export default Banner;
