import Header from "./header/Header";
import { api, loadScript } from "utils/util";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Outlet } from "react-router-dom";
import ChannelTalk from "./ChannelTalk";
import { setCookie } from "../utils/util";
import { useUserInfo } from "api/mypage/hooks";

const CheckCI = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [mobileVerifiedMeta, setMobileVerifiedMeta] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const updateCI = useMutation((data) => api.post("/user/modifyPhone", data));

  const getCheckCICookie = () => {
    const cookie = document.cookie;
    if (cookie.indexOf("checkCI=pass") < 0) {
      return true;
    } else return false;
  };

  const { data: myInfo, loading: myInfoLoading } = useUserInfo();

  const fetchUserData = async (impUid) => {
    const data = {
      impUid,
    };
    try {
      const response = await api.post("/user/phoneCertification", data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  const performMobileVerification = async () => {
    await Promise.all([
      loadScript("pass-jquery", "https://code.jquery.com/jquery-1.12.4.min.js"),
      loadScript("pass-iamport", "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js"),
    ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { IMP } = window;
    IMP.init("imp60774027");

    return new Promise((resolve, reject) => {
      IMP.certification(
        {
          min_age: 17,
        },
        (res) => {
          if (res.success) {
            resolve(res);
          } else {
            reject(new Error("Mobile verification failed"));
          }
        },
      );
    });
  };

  const mobileVerifyAndFetchUserData = async (setLoading) => {
    try {
      setLoading(true);
      const res = await performMobileVerification();

      if (res.success) {
        const userData = await fetchUserData(res.imp_uid);
        setMobileVerifiedMeta(userData);
        updateCI.mutate(
          {
            phone: userData.phone,
            birthday: userData.birthday,
            genderCd: userData.gender === "male" ? "M" : "F",
            ci: userData.unique_key,
            di: userData.unique_in_site,
          },
          {
            onSuccess: () => queryClient.invalidateQueries("mypageInfo"),
            onError: () => {
              window.alert(
                "본인 인증에 실패했습니다. 추후 계정 사용이 중단될 수 있으니 고객센터로 문의해 주세요.",
              );
              setCookie("checkCI", "pass", 24);
            },
          },
        );
      }
    } catch (error) {
      console.error("An error occurred during mobile verification:", error);
    } finally {
      setLoading(false);
    }
  };

  if (getCheckCICookie() && myInfo && !myInfo?.has_verified_phone)
    return (
      <section className="relative">
        <Header />
        <article className="lg:h-[calc(100vh-250px)] flex flex-col pt-10 lg:pt-0 gap-5 justify-center items-center">
          <h1 onClick={() => console.log(mobileVerifiedMeta)} className="text-xl font-semibold">
            2차 본인인증을 완료해주세요.
          </h1>
          <div className="text-center pb-5">
            치과의사만의 커뮤니티를 유지하기 위한
            <br className="lg:hidden" /> 재 인증 절차를 실시 중입니다.
            <br />
            주기적인 인증 절차를 통해 더 안전한 치과의사만의
            <br className="lg:hidden" /> 커뮤니티를 조성하기 위함이니, 양해 부탁드립니다.
          </div>
          <button
            onClick={() => mobileVerifyAndFetchUserData(setLoading)}
            className="bg-primary-800 hover:bg-primary-900 text-white p-4 lg:px-[125px] lg:static lg:w-fit lg:rounded fixed bottom-0 w-full"
          >
            {loading ? "Loading..." : "본인 인증하기"}
          </button>
          <button
            onClick={() => navigate("/logout")}
            className="hover:underline text-secondary-600 lg:static fixed bottom-20"
          >
            로그아웃
          </button>
        </article>
        <ChannelTalk />
      </section>
    );

  return <Outlet />;
};

export default CheckCI;
