import { api } from "../../utils/util";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import Loading from "../Loading";
import SearchForm from "component/shared/SearchForm";

const ContentsKeyword = () => {
  const [searchKeyword, setSearchKeyword] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    param.append("page", 1);
    searchKeyword && param.append("tag", searchKeyword);
    navigate(`/contents/search?${param}`);
    window.scrollTo(0, 0);
  };

  const { data: keywordList, isLoading: keywordListLoading } = useQuery("contentsKeywordList", () =>
    api.get("/contents/keywordList").then((res) => res.data),
  );

  if (keywordListLoading) return <Loading />;

  return (
    <section className="w-full bg-gray-50 mb-20">
      <div className="lg:max-w-[1200px] mx-auto p-4 lg:px-0 lg:py-8">
        <div className="w-full m-0 p-0">
          <div className="flex justify-between items-center title">
            <div className="text-xl tracking-tighter font-semibold whitespace-nowrap">
              키워드로 <span className="hidden lg:inline">콘텐츠</span> 찾기 🔎
            </div>
            <SearchForm value={searchKeyword} setValue={setSearchKeyword} search={search} />
          </div>
          <div className="mt-6 flex flex-wrap">
            {keywordList.keywords.map((keyword, idx) => {
              return (
                <div
                  className="hover:bg-primary-100 hover:border-primary-800 hover:text-primary-800 bg-white border-[1.5px] border-solid border-gray-300 rounded-md px-2 py-1 lg:p-2 mr-2 mb-2 cursor-pointer"
                  key={idx}
                  onClick={() => {
                    const param = new URLSearchParams();
                    param.append("page", 1);
                    param.append("tag", keyword.value);
                    navigate(`/contents/search?${param}`);
                    window.scrollTo(0, 0);
                  }}
                >
                  <span
                    className={
                      keyword.style === "bold" ? "font-bold" : "text-sm lg:text-base font-medium"
                    }
                  >
                    #{keyword.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentsKeyword;
