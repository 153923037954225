import axios from "axios";
import { useState } from "react";
import { api } from "../../utils/util";
import { BASE_URL } from "../../utils/util";
import { useRecoilState } from "recoil";
import { tokenState } from "../../state";
import { useQueryClient } from "react-query";

const ChangeNick = ({ setPopup }) => {
  const [nick, setNick] = useState("");
  const [useable, setUseable] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const queryClient = useQueryClient();

  const checkNickname = () => {
    const markable_nick = nick.replace(/\s/g, "");
    const params = {
      nickname: markable_nick,
    };
    axios.post(`${BASE_URL}/user/checkDuplicateNickname`, params).then((res) => {
      if (res.data.duplicate === false) {
        alert("사용가능한 별명입니다");
        setUseable(true);
      } else {
        alert("중복된 별명입니다");
        setUseable(false);
      }
    });
  };

  const setMaxLen = (value, max = 30) => {
    if (value.length > max) {
      alert(`${max}글자 이하만 가능합니다.`);
      return false;
    }
    return true;
  };

  const setNickname = () => {
    if (!useable) {
      alert("닉네임 중복검사가 필요합니다");
      return;
    }
    const data = {
      nickname: nick,
    };
    api
      .post(`/user/modifyNickname`, data)
      .then((res) => {
        alert("닉네임이 성공적으로 변경되었습니다");
        setToken({ ...token, user_nickname: nick });
        queryClient.invalidateQueries("mypageInfo");
        setPopup(false);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  return (
    <>
      <div className="popup-overlay dark"></div>
      <article className="popup popup-addr" style={{ display: "block" }}>
        <div className="popup-wrap">
          <div className="head">
            <div className="popup-tit">
              <i className="ic ic-logo"></i>
              <span className="txt">닉네임 변경</span>
            </div>

            <span onClick={() => setPopup(false)} className="close-btn"></span>
          </div>
          <div className="body">
            <article className="pop__addr">
              <div className="change-nick__txt">
                <div>변경하시려는 닉네임을 입력해주세요.</div>
                <div>* 닉네임을 설정하면 30일간 변경할 수 없습니다.</div>
              </div>
            </article>

            <div className="change-nick__box">
              <input
                type="text"
                placeholder="닉네임을 입력하세요."
                value={nick}
                onChange={(e) => {
                  setUseable(false);
                  setMaxLen(e.target.value) && setNick(e.target.value);
                }}
              />
              <div className="nick-check">
                <button className="nick-check__btn" onClick={checkNickname}>
                  중복확인
                </button>
              </div>
            </div>

            <div className="popup__menu">
              <button onClick={() => setPopup(false)} className="pop-menu-btn">
                닫기
              </button>
              <button onClick={setNickname} className="pop-menu-btn type1">
                확인
              </button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default ChangeNick;
