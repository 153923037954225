import { api, resizedImg } from "../../utils/util";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Blur from "../../page/user/Blur";
import { useHomeSegment } from "hooks/useHomeSegment";

const RecommendForum = () => {
  const { sendTrackingEvent } = useHomeSegment();

  const { data: forumExpertSelectedState, isLoading: forumExpertSelectedStateLoading } = useQuery(
    "forumExpertSelected",
    () => api.get("/forum/expert_selected").then((res) => res.data),
  );

  return (
    <div className="w-full m-0 flex flex-col">
      <span className="flex justify-between items-center pb-6">
        <b className="lg:text-[20px] text-[18px]">추천 임상포럼</b>
      </span>
      {!forumExpertSelectedStateLoading && forumExpertSelectedState && (
        <div onClick={() => sendTrackingEvent({ name: "추천 임상포럼" })}>
          <Link to={`/forum/article/${forumExpertSelectedState.bid}`} className="relative group">
            <div className="absolute -top-2 -right-2 z-10">
              <PickTag />
            </div>
            <Blur>
              <div
                className="relative hard-blur w-full h-[215px] rounded-md overflow-hidden bg-gray-100"
                style={{ aspectRatio: "1.83" }}
              >
                {forumExpertSelectedState.thumbnail && (
                  <img
                    className="object-cover w-full h-full group-hover:scale-105 transition-all duration-300"
                    src={resizedImg(forumExpertSelectedState.thumbnail, 500)}
                    alt=""
                  />
                )}
                <div className="absolute w-full bottom-0 bg-black bg-opacity-70 p-3">
                  <div className="text-[14px] text-black font-semibold absolute -top-3 bg-white px-2 rounded-sm">
                    {forumExpertSelectedState.nickname}
                    <span className="text-primary-800 ml-1">
                      <span className="!text-gray-600">Lv.</span>{" "}
                      {forumExpertSelectedState.userLevel}
                    </span>
                  </div>
                  <div className="text-[18px] !text-white font-semibold w-[100%] truncate">
                    {forumExpertSelectedState.title}
                  </div>
                </div>
              </div>
            </Blur>
          </Link>
        </div>
      )}
    </div>
  );
};

export default RecommendForum;

const PickTag = () => {
  return (
    <svg width="71" height="72" viewBox="0 0 71 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3604_17975)">
        <path d="M62.9536 48.1862H70.9999V71.0082H62.9536V48.1862Z" fill="#5213B0" />
        <path d="M0.00105667 -0.00012207H22.8231V8.04614H0.00105667V-0.00012207Z" fill="#5213B0" />
        <path
          d="M71 71.0082L0 0.00936785L0.00949097 -0.00012207H25.2147C26.5848 -0.00012207 27.9004 0.544362 28.8683 1.51352L69.4864 42.1316C70.4555 43.1007 71 44.4151 71 45.7852V71.0082Z"
          fill="#761CFE"
        />
        <path
          d="M33.2122 25.2987L34.4577 26.5391L36.4092 24.5794L37.4887 25.6544C38.8754 27.0354 40.4953 27.003 41.6902 25.8032C42.8686 24.6199 42.9232 22.9957 41.5573 21.6354L39.1991 19.2869L33.2122 25.2987ZM37.4098 23.5747L39.4275 21.5486L40.3077 22.4252C41.0426 23.1653 41.0027 23.9277 40.4032 24.5214C39.8037 25.1317 39.029 25.1872 38.29 24.4512L37.4098 23.5747ZM46.5926 26.6498L45.347 25.4095L39.3601 31.4212L40.6056 32.6616L46.5926 26.6498ZM50.391 34.6003L51.6531 35.8572C52.8638 34.3093 52.6073 32.4744 51.2206 31.0934C49.6346 29.514 47.3018 29.4318 45.3462 31.3956C43.3988 33.3511 43.4616 35.6711 45.0683 37.2712C46.5962 38.7928 48.5392 38.71 49.7842 37.7338L48.5304 36.4686C47.7608 37.0005 46.8739 36.8615 46.1847 36.1834C45.2713 35.2655 45.3266 33.9561 46.6166 32.6608C47.8777 31.3945 49.2074 31.2964 50.1457 32.2226C50.8432 32.9255 50.9528 33.8368 50.391 34.6003ZM49.4722 41.4915L50.7177 42.7319L52.4294 41.0131L53.8213 40.8776L53.0178 45.0225L54.5124 46.5109L55.5532 40.932L60.4744 40.4744L58.9715 38.9776L54.1332 39.4683L54.0502 39.3856L56.7046 36.7201L55.4591 35.4797L49.4722 41.4915Z"
          fill="white"
        />
        <path
          d="M37.2734 16.6395C37.374 16.5459 37.3352 16.3777 37.2036 16.3367L35.4916 15.7931L34.0332 17.1643C33.8917 16.5159 33.5795 15.8962 33.0913 15.3776C31.6602 13.8549 29.2687 13.7783 27.7492 15.2065C26.2309 16.6348 26.1596 19.0271 27.5915 20.5492C29.0067 22.0548 31.3607 22.1453 32.8817 20.7674L32.883 20.7687L37.2741 16.6402L37.2734 16.6395ZM28.927 19.2937C28.1908 18.5103 28.2264 17.2797 29.0079 16.5442C29.7893 15.8098 31.0197 15.8485 31.7571 16.6319C32.4933 17.4154 32.4577 18.6459 31.6762 19.3815C30.8948 20.1158 29.6644 20.0772 28.927 19.2937Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3604_17975">
          <rect
            width="71"
            height="71.0083"
            fill="white"
            transform="matrix(-1 0 0 1 71 -0.00012207)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
