import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { api, htmlTextContent } from "../../utils/util";

const EssayQuestion = ({ question, isNote }) => {
  const { pid, problem_no: num, document } = question;
  const { answer, problem, solution } = JSON.parse(document);
  const [myAnswer, setMyAnswer] = useState("");
  const [clickAns, setClickAns] = useState(false);
  const [note, setNote] = useState(false);
  const token = useRecoilValue(tokenState);
  return (
    <>
      <section style={{ marginTop: 20 }} className="festival__hot__board">
        <div className="inner">
          <article className="festival__board tong__board">
            {!question.showAns && (
              <div className="tsubject-box">
                <div className="number">{num}번 문제</div>
              </div>
            )}
            <div className="ttsubject-box">{htmlTextContent(problem)}</div>
            {!isNote && (
              <section className="form__layout1-t section-first">
                <div className="inner">
                  <div className="row">
                    <div className="con">
                      <div className="tbox essay-input">
                        <textarea
                          placeholder="정답을 입력해주세요."
                          value={myAnswer}
                          onChange={(e) => setMyAnswer(e.target.value)}
                          disabled={question.showAns || clickAns}
                          tabIndex={question.num}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <div className="answer_check__container">
              <div className="answer__container">
                <div className="answer_check" onClick={() => setClickAns(true)}>
                  정답 확인
                </div>
                {(question.showAns || clickAns) && (
                  <div className="answer">{htmlTextContent(answer.join(", "))}</div>
                )}
              </div>
              {token && (question.showAns || clickAns) && !isNote && (
                <button
                  className="note__container"
                  type="button"
                  onClick={() => {
                    const data = { record: true };
                    api
                      .post(`/tongtest/pid_record/${pid}`, data)
                      .then((res) => setNote(true))
                      .catch((err) => alert("오답노트 저장중 오류가 발생했습니다"));
                  }}
                >
                  {!note ? (
                    <img
                      src={`${
                        import.meta.env.VITE_APP_PUBLIC_URL
                      }img/drawable/incorrect_answer_note.svg`}
                      alt="이미지"
                    />
                  ) : (
                    <img
                      src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/drawable/note_check.svg`}
                      alt="이미지"
                    />
                  )}
                  <span>오답노트 담기</span>
                </button>
              )}
            </div>
          </article>
        </div>
      </section>
      {(question.showAns || clickAns) && solution && (
        <section className="setting__leave section-first">
          <div className="inner">
            <div className="leave__check__box-t">
              <div className="answer">문제 해설</div>
              <div className="text-box">
                <div className="subject"></div>
                <span className="content">{htmlTextContent(solution)}</span>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const MultipleQuestion = ({ question, isNote }) => {
  const { pid, problem_no: num, document } = question;
  const { answer, choice, problem, solution } = JSON.parse(document);
  const [myAnswer, setMyAnswer] = useState({});
  const [clickAns, setClickAns] = useState(false);
  const [note, setNote] = useState(false);
  const token = useRecoilValue(tokenState);

  const checkAnswer = () => {
    const myAns = Object.keys(myAnswer).map((item) => parseInt(item) + 1);
    return myAns.sort().toString() === answer.sort().toString() ? true : false;
  };

  return (
    <>
      <section style={{ marginTop: 20 }} className="festival__hot__board">
        <div className="inner">
          <article className="festival__board tong__board">
            {!question.showAns && (
              <div className="tsubject-box">
                <div className="number">{num}번 문제</div>
              </div>
            )}
            <div className="ttsubject-box">
              {problem.split("\n").map((text, i) => (
                <p key={i}>&#8203;{text}</p>
              ))}{" "}
              {answer.length > 1 && `(정답 ${answer.length}개)`}
            </div>

            {question.showAns || clickAns ? (
              <>
                <div className="des-box">
                  {choice.map((label, idx) => (
                    <div key={idx}>
                      {checkAnswer() ? (
                        <img
                          className="right_wrong_answer"
                          src={`${
                            import.meta.env.VITE_APP_PUBLIC_URL
                          }img/drawable/right_answer.svg`}
                          alt="이미지"
                        />
                      ) : (
                        <img
                          className="right_wrong_answer"
                          src={`${
                            import.meta.env.VITE_APP_PUBLIC_URL
                          }img/drawable/wrong_answer.svg`}
                          alt="이미지"
                        />
                      )}
                      <div className="rbox">
                        <label>
                          <input
                            type="checkbox"
                            className={answer.includes(idx + 1) || myAnswer[idx] ? "checked" : ""}
                          />
                          <i className={answer.includes(idx + 1) ? "answer" : ""}></i>
                          <span className="txt">{htmlTextContent(`${idx + 1}. ${label}`)}</span>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
                {token && !isNote && (
                  <div className="answer_check__container">
                    <div className="answer__container">
                      <button
                        className="note__container"
                        type="button"
                        onClick={() => {
                          const data = {
                            record: true,
                          };
                          api
                            .post(`/tongtest/pid_record/${pid}`, data)
                            .then((res) => {
                              setNote(true);
                            })
                            .catch((err) => {
                              alert("오답노트 저장중 오류가 발생했습니다");
                            });
                        }}
                      >
                        {!note ? (
                          <img
                            src={`${
                              import.meta.env.VITE_APP_PUBLIC_URL
                            }img/drawable/incorrect_answer_note.svg`}
                            alt="이미지"
                          />
                        ) : (
                          <img
                            src={`${
                              import.meta.env.VITE_APP_PUBLIC_URL
                            }img/drawable/note_check.svg`}
                            alt="이미지"
                          />
                        )}
                        <span>오답노트 담기</span>
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="des-box">
                  {choice.map((label, idx) => (
                    <div className="rbox" key={idx}>
                      <label>
                        <input
                          type="checkbox"
                          className={myAnswer[idx] ? "checked" : ""}
                          onChange={() => {
                            answer.length === 1
                              ? setMyAnswer({ [idx]: !myAnswer[idx] })
                              : setMyAnswer({
                                  ...myAnswer,
                                  [idx]: !myAnswer[idx],
                                });
                          }}
                        />
                        <i></i>
                        <span className="txt">{htmlTextContent(`${idx + 1}. ${label}`)}</span>
                      </label>
                    </div>
                  ))}
                </div>
                <div className="answer_check__container">
                  <div className="answer__container">
                    <div className="answer_check" onClick={() => setClickAns(true)}>
                      정답 확인
                    </div>
                  </div>
                </div>
              </>
            )}
          </article>
        </div>
      </section>
      {(question.showAns || clickAns) && (
        <section className="setting__leave section-first">
          <div
            className="subject"
            style={{ fontWeight: 700, marginLeft: "20px", color: "#39e8c6" }}
          >
            문제 해설
          </div>
          <div className="inner">
            <div className="leave__check__box-t">
              <div className="text-box">
                <span className="content">{htmlTextContent(solution)}</span>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const TongtestQuestion = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(0);
  const [problems, setProblems] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const sid = parseInt(useParams().sid);

  useEffect(() => {
    api
      .get(`/tongtest/set/${sid}`)
      .then((res) => {
        setSeconds(res.data.set.test_time_second);
        setProblems(res.data.problems);
      })
      .catch((err) => {
        alert("시험 로딩에 실패하였습니다");
      });
  }, [sid]);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (isSubmit) {
        setSeconds(0);
        return;
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [seconds, isSubmit]);

  const submit = () => {
    setProblems(
      problems.map((item) => {
        return { ...item, showAns: true };
      }),
    );
    setIsSubmit(true);
    window.scrollTo(0, 850);
  };

  return (
    <>
      <article style={{ marginTop: 100 }} className="section__tit__box">
        <div className="tit-box">
          <span className="tit-txt">통합치의학 모의고사</span>
        </div>
      </article>

      <div className="ready tong__box__container">
        <article className="section__tong__box">
          <img
            className="ttest_logo"
            src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/ttest_logo.svg`}
            alt="이미지"
          />
          <div className="tong__box__text">모어덴 통합치의학 모의고사</div>
        </article>
      </div>
      <div className="time">
        남은시간 : {parseInt(seconds / 60)}분 {`${seconds % 60}`.padStart(2, "0")}초
      </div>
      <div className="wrapper">
        {problems.map((item) =>
          item.problem_type === "choice" ? (
            <MultipleQuestion question={item} key={item.pid} />
          ) : (
            <EssayQuestion question={item} key={item.pid} />
          ),
        )}
        <article className="bottom__btn__group">
          {!isSubmit ? (
            <div className="bottom__btn__box" onClick={submit}>
              제출하기
            </div>
          ) : (
            <div className="bottom__btn__box" onClick={() => navigate("/tongtest")}>
              처음으로
            </div>
          )}
        </article>
      </div>
    </>
  );
};

export default TongtestQuestion;
export { MultipleQuestion, EssayQuestion };
