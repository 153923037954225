import { Route, Routes, Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import HygienistDetail from "./HygienistDetail";
import HygienistPage from "./HygienistPage";
import HygienistWrite from "./HygienistWrite";
import DoctorPage from "./DoctorPage";
import DoctorDetail from "./DoctorDetail";
import DoctorWrite from "./DoctorWrite";
import RequestSignup from "../user/RequestSignup";
import ChiCruiting from "./ChiCruiting";
import ChiCruitingRegister from "page/chicruiting/Register";
import HygienistMain from "./HygienistMain";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";

const Recruit = () => {
  const token = useRecoilValue(tokenState);
  const isLogin = token && token.user_type;
  return (
    <section className="relative min-h-[100vh]">
      <Header current="recruit" />
      <article className="container mx-auto pb-64">
        {isLogin ? (
          <Routes>
            <Route path="/chicruiting/register" element={<ChiCruitingRegister />} />
            <Route path="/chicruiting" element={<ChiCruiting />} />
            <Route path="/hygienist/write/*" element={<HygienistWrite />} />
            <Route path="/hygienist/modify/:bid" element={<HygienistWrite />} />
            <Route path="/hygienist/:bid/*" element={<HygienistDetail />} />
            <Route path="/hygienist" element={<HygienistPage />} />
            <Route path="/doctor" element={<DoctorPage />} />
            <Route path="/doctor/:bid/*" element={<DoctorDetail />} />
            <Route path="/doctor/write/*" element={<DoctorWrite />} />
            <Route path="/doctor/modify/:bid" element={<DoctorWrite />} />
            <Route path="/*" element={<HygienistMain />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/hygienist/:bid/*" element={<HygienistDetail />} />
            <Route path="/hygienist" element={<HygienistPage />} />
            <Route path="/chicruiting" element={<ChiCruiting />} />
            <Route path="/" element={<HygienistMain />} />
            <Route path="*" element={<RequestSignup />} />
          </Routes>
        )}
      </article>
      <Footer />
    </section>
  );
};

export default Recruit;
