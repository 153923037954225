import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { watermark, getStorage, StaticImg } from "utils/util";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import Loading from "component/Loading";
import Header from "component/header/Header";
import {
  useSingleSearchInfinite,
  useMultiSearchInfinite,
  useSearchTopicList,
  useClearSearchCache,
} from "api/search/hooks";
import { useProductsInfinite } from "api/market/product/hooks";
import { SEARCH_INDICES } from "../../constants";
import SearchResultAll from "component/search/result/SearchResultAll";
import SearchResultPost from "component/search/result/SearchResultPost";
import SearchResultClass from "component/search/result/SearchResultClass";
import SearchResultContent from "component/search/result/SearchResultContent";
import SearchResultForum from "component/search/result/SearchResultForum";
import SearchResultQna from "component/search/result/SearchResultQna";
import SearchResultRecruit from "component/search/result/SearchResultRecruit";
import SearchResultSeminar from "component/search/result/SearchResultSeminar";
import SearchResultMarket from "component/search/result/SearchResultMarket";
import SearchController from "component/search/SearchController";

const SearchPage = () => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);
  const [searchParams] = useSearchParams();
  const { clearSearchCache } = useClearSearchCache();
  const params = {
    indexUid: searchParams.get("indexUid") || "all",
    q: searchParams.get("q") || "",
    searchTarget: searchParams.get("searchTarget") || "",
    topics: JSON.parse(searchParams.get("topics") || "[]"),
    sort: searchParams.get("sort")
      ? searchParams.get("sort")
      : searchParams.get("indexUid") === "market"
      ? "three_months:desc"
      : undefined,
  };

  // -------------------- IndexUidBar 출력 내용 관련 로직 --------------------
  const searchIndexes = JSON.parse(getStorage().getItem("searchIndexes") || "[]");
  const displayedUidList = useMemo(() => {
    const alwaysIncludeIndices = ["all", "market", "post", "forum", "qna"];
    const excludeIndices = ["forum_comment", "qna_comment"];

    return SEARCH_INDICES.filter((searchIndex) => {
      if (alwaysIncludeIndices.includes(searchIndex.name)) return true;
      if (excludeIndices.includes(searchIndex.name)) return false;
      return searchIndexes.includes(searchIndex.name);
    });
  }, [searchIndexes]);

  // -------------------- 선택 상황을 반영한 Navigate 로직 --------------------
  const navigateNewResult = ({ newUid, newQ, newSearchTarget, newTopics, newSort }) => {
    const searchParams = new URLSearchParams();
    searchParams.set("indexUid", newUid);
    searchParams.set("q", newQ);
    if (newSearchTarget && showRadioUidList.includes(newUid)) {
      searchParams.set("searchTarget", newSearchTarget);
    }
    if (newTopics?.length) {
      searchParams.set("topics", JSON.stringify(newTopics));
    }
    if (newUid === "market" && newSort) {
      searchParams.set("sort", newSort);
    }
    navigate(`/search?${searchParams.toString()}`);
  };

  // -------------------- 선택한 검색어 관련 로직 --------------------
  const searchKeyword = params.q;
  const handleSubmitKeyword = (submitKeyword) => {
    clearSearchCache();
    navigateNewResult({
      newUid: selectedUid,
      newQ: submitKeyword,
      newSearchTarget: selectedSearchTarget,
      newTopics: [],
    });
  };

  // -------------------- 선택한 indexUid 관련 로직 --------------------
  const selectedUid = params.indexUid;
  const handleSelectUid = (selectUid) => {
    clearSearchCache();
    navigateNewResult({
      newUid: selectUid,
      newQ: searchKeyword,
      newSearchTarget: selectedSearchTarget,
      newTopics: [],
      newSort: selectUid === "market" ? "three_months:desc" : undefined,
    });
  };

  // -------------------- 선택한 searchTarget 관련 로직 (라디오 버튼) --------------------
  const showRadioUidList = ["qna", "forum"];
  const isShowSearchTargetRadio = showRadioUidList.includes(selectedUid);
  const selectedSearchTarget = params.searchTarget;
  const handleSelectSearchTarget = (searchTarget) => {
    navigateNewResult({
      newUid: selectedUid,
      newQ: searchKeyword,
      newSearchTarget: searchTarget,
      newTopics: [],
    });
  };

  // -------------------- 선택한 sort 관련 로직 (마켓 전용) --------------------
  const selectedSort = params?.sort;
  const handleSelectSort = (sort) => {
    navigateNewResult({
      newUid: selectedUid,
      newQ: searchKeyword,
      newSearchTarget: selectedSearchTarget,
      newTopics: [],
      newSort: sort?.value || "",
    });
  };

  // -------------------- 검색 결과 관련 로직 --------------------
  const isMultiSearchUid = useMemo(() => {
    const multiSearchIndexUidList = ["all", "post"];
    return multiSearchIndexUidList.includes(selectedUid);
  }, [selectedUid]);

  // MARK: api에서 사용하는 indexUid와 url로 표시하는 indexUid가 불일치하는 케이스가 있어 대응하는 로직입니다.
  const singleSearchIndexUidForAPi = useMemo(() => {
    if (params.indexUid === "qna") {
      if (params.searchTarget === "comments") return "qna_comment";
      return "qna";
    }
    if (params.indexUid === "forum") {
      if (params.searchTarget === "comments") return "forum_comment";
      return "content"; // MARK: api에서 content로 indexUid를 설정하고, filter를 ["board_name = 임상포럼"]로 사용하여 받는다.
    }
    return selectedUid;
  }, [params.searchTarget, params.indexUid, selectedUid]);

  const singleSearchAdditionalBody = useMemo(() => {
    if (params?.indexUid === "forum" && !params?.searchTarget) {
      return {
        filter: ["board_name = 임상포럼"],
      };
    }
    return;
  }, [params.indexUid, params.searchTarget]);

  const {
    data: singleSearchResult,
    isLoading: isLoadingSingleSearch,
    isFetching: isFetchingSingleSearch,
    hasNextPage: hasNextPageSingleSearch,
    fetchNextPage: fetchNextPageSingleSearch,
    isFetchingNextPage: isFetchingNextPageSingleSearch,
  } = useSingleSearchInfinite({
    indexUid: isMultiSearchUid ? "" : singleSearchIndexUidForAPi, // indexUid값이 ""면 react-query enabled가 false로 처리되어 api 요청하지 않습니다.
    searchKeyword: params?.q || "",
    additionalBody: singleSearchAdditionalBody,
    sort: params?.indexUid === "market" ? params?.sort : undefined,
    uniqueKey: singleSearchAdditionalBody ? "singleSearchAdditionalBody" : undefined,
  });
  const isInitialLoadingSingleSearch = isLoadingSingleSearch && isFetchingSingleSearch;

  const {
    data: multiSearchResult,
    isLoading: isLoadingMultiSearch,
    isFetching: isFetchingMultiSearch,
    hasNextPage: hasNextPageMultiSearch,
    fetchNextPage: fetchNextPageMultiSearch,
    isFetchingNextPage: isFetchingNextPageMultiSearch,
  } = useMultiSearchInfinite({
    indexUid: isMultiSearchUid ? selectedUid : "", // indexUid값이 ""면 react-query enabled가 false로 처리되어 api 요청하지 않습니다.
    searchKeyword: params?.q || "",
  });
  const isInitialLoadingMultiSearch = isLoadingMultiSearch && isFetchingMultiSearch;

  // MARK: 마켓의 경우, 재고확인 및 포인트 적립 등의 추가적인 정보를 가져오기 위해 MarketBff API를 사용합니다.
  // MARK: multiSearch의 경우 마켓 검색을 결합하여 처리하기 곤란하여 별도로 호출 하여 사용합니다.
  const {
    data: marketMultiSearchResult,
    isLoading: isLoadingMarketMultiSearch,
    isFetching: isFetchingMarketMultiSearch,
  } = useProductsInfinite(
    {
      startPage: 1,
      limit: 3, // MARK: 전체 페이지에서만 사용할 용도라 3개로 제한합니다.
      q: params?.q || "",
    },
    {
      select: (data) => data?.pages || [],
      enabled: selectedUid === "all",
    },
  );
  const isInitialLoadingMarketMultiSearch =
    isLoadingMarketMultiSearch && isFetchingMarketMultiSearch;

  // -------------------- 선택한 topic 관련 로직 --------------------
  const showTopicUidList = ["qna", "forum", "seminar"];
  const isShowTopic = showTopicUidList.includes(selectedUid);
  const { data: searchTopicList } = useSearchTopicList({
    topicType: isShowTopic ? selectedUid : undefined,
  });

  // 유저가 드롭다운을 통해 선택한 토픽 리스트
  const selectedTopicList = isShowTopic ? params.topics : [];

  // 토픽 선택
  const handleSelectedTopicList = (selectedTopic) => {
    if (!selectedTopic) return;
    let newSelectedTopicList = [];
    const isAlreadySelected = selectedTopicList.includes(selectedTopic);
    if (isAlreadySelected) {
      newSelectedTopicList = selectedTopicList.filter((topic) => topic !== selectedTopic);
    } else {
      newSelectedTopicList = [...selectedTopicList, selectedTopic];
    }
    navigateNewResult({
      newUid: selectedUid,
      newQ: searchKeyword,
      newSearchTarget: selectedSearchTarget,
      newTopics: newSelectedTopicList,
    });
  };

  // 검색 결과에서 중복을 제거한 토픽 리스트
  const resultUniqueTopicList = useMemo(() => {
    // MARK: singleSearch에서만 토픽을 사용한다고 가정하고 로직을 작성합니다.
    if (!isShowTopic || !Array.isArray(singleSearchResult) || singleSearchResult.length === 0) {
      return [];
    }
    const topics = singleSearchResult.flatMap((cur) =>
      Array.isArray(cur?.hits) ? cur.hits.map((hit) => hit?.topic) : [],
    );
    return [...new Set(topics.filter(Boolean))];
  }, [singleSearchResult, isShowTopic]);

  // 드롭다운에 표시할 토픽 리스트
  const topicDropdownOptions = useMemo(() => {
    if (!searchTopicList) return [];
    const filteredTopics = searchTopicList.filter((topic) =>
      resultUniqueTopicList.includes(topic.cate_name),
    );
    const sortedTopics = filteredTopics.sort((a, b) =>
      a.cate_certi !== b.cate_certi ? (a.cate_certi ? -1 : 1) : a.cate_cd - b.cate_cd,
    );
    return sortedTopics.map((topic) => ({
      label: topic.cate_name,
      value: topic.cate_cd,
    }));
  }, [searchTopicList, resultUniqueTopicList]);

  // 유저가 선택한 토픽이 있을 경우, 필터가 적용되어 *** 화면에 출력되는 검색 결과 ***
  const singleSearchResultFilteredByTopic = useMemo(() => {
    // MARK: singleSearch에서만 토픽을 사용한다고 가정하고 로직을 작성합니다.
    if (!isShowTopic || !selectedTopicList?.length || !singleSearchResult?.length)
      return singleSearchResult;
    return singleSearchResult.map((result) => {
      return {
        ...result,
        hits: (result?.hits || []).filter((hit) => selectedTopicList.includes(hit?.topic)),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicList]);

  // -------------------- 검색 결과 총 개수 관련 로직 --------------------
  const resultsTotalCount = useMemo(() => {
    if (isLoadingMultiSearch || isLoadingSingleSearch) return;
    if (isMultiSearchUid) {
      const results = multiSearchResult?.[0]?.results || [];
      return results.reduce((acc, cur) => acc + (cur?.estimatedTotalHits || 0), 0);
    }
    if (!isMultiSearchUid) {
      return singleSearchResult?.[0]?.estimatedTotalHits || 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleSearchResult, multiSearchResult]);

  return (
    <section className="relative min-h-[100vh]">
      <Header current="" />
      <article className="pb-64">
        <div
          className="max-w-[700px] mx-[16px] lg:mx-auto"
          style={{
            background: watermark(token),
            backgroundRepeat: "space",
          }}
        >
          <div className="mt-[40px] mb-[20px]">
            <SearchController
              onSubmitKeyword={handleSubmitKeyword}
              displayedUidList={displayedUidList}
              selectedUid={selectedUid}
              onSelectedUid={handleSelectUid}
              resultsTotalCount={resultsTotalCount}
              selectedSearchTarget={selectedSearchTarget}
              onSelectedSearchTarget={handleSelectSearchTarget}
              isShowSearchTargetRadio={isShowSearchTargetRadio}
              topicDropdownOptions={topicDropdownOptions}
              selectedTopicList={selectedTopicList}
              onSelectedTopicList={handleSelectedTopicList}
            />
          </div>
          {!params?.q ? (
            <EmptyKeywordPlaceholder />
          ) : isMultiSearchUid ? (
            <MultiSearchResults
              selectedUid={selectedUid}
              onSelectUid={handleSelectUid}
              multiSearchResult={multiSearchResult}
              marketMultiSearchResult={marketMultiSearchResult}
              isLoading={isLoadingMultiSearch && isLoadingMarketMultiSearch}
              isInitialLoading={isInitialLoadingMultiSearch && isInitialLoadingMarketMultiSearch}
              hasNextPage={hasNextPageMultiSearch || false}
              fetchNextPage={fetchNextPageMultiSearch}
              isFetchingNextPage={isFetchingNextPageMultiSearch}
            />
          ) : (
            <SingleSearchResults
              selectedUid={selectedUid}
              singleSearchResult={
                isShowTopic ? singleSearchResultFilteredByTopic : singleSearchResult
              }
              isLoading={isLoadingSingleSearch}
              isInitialLoading={isInitialLoadingSingleSearch}
              hasNextPage={hasNextPageSingleSearch || false}
              fetchNextPage={fetchNextPageSingleSearch}
              isFetchingNextPage={isFetchingNextPageSingleSearch}
              selectedSort={selectedSort}
              onSelectSort={handleSelectSort}
            />
          )}
        </div>
      </article>
    </section>
  );
};
export default SearchPage;

const MultiSearchResults = ({
  selectedUid,
  onSelectUid,
  multiSearchResult,
  marketMultiSearchResult,
  isLoading,
  isInitialLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}) => {
  return (
    <>
      {isLoading && <Loading />}
      {!isInitialLoading && (
        <>
          {selectedUid === "all" && (
            <SearchResultAll
              searchResult={multiSearchResult}
              marketSearchResult={marketMultiSearchResult}
              isFetchingNextPage={isFetchingNextPage}
              onSelectUid={onSelectUid}
            />
          )}
          {selectedUid === "post" && (
            <SearchResultPost
              searchResult={multiSearchResult}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
        </>
      )}
    </>
  );
};

const SingleSearchResults = ({
  selectedUid,
  singleSearchResult,
  isLoading,
  isInitialLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  selectedSort,
  onSelectSort,
}) => {
  return (
    <>
      {isLoading && <Loading />}
      {!isInitialLoading && (
        <>
          {selectedUid === "market" && (
            <SearchResultMarket
              searchResult={singleSearchResult}
              selectedSort={selectedSort}
              onSelectSort={onSelectSort}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
          {selectedUid === "classes" && (
            <SearchResultClass
              searchResult={singleSearchResult}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
          {selectedUid === "content" && (
            <SearchResultContent
              searchResult={singleSearchResult}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
          {(selectedUid === "forum" || selectedUid === "forum_comment") && (
            <SearchResultForum
              searchResult={singleSearchResult}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
          {(selectedUid === "qna" || selectedUid === "qna_comment") && (
            <SearchResultQna
              searchResult={singleSearchResult}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
          {selectedUid === "recruit" && (
            <SearchResultRecruit
              searchResult={singleSearchResult}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
          {selectedUid === "seminar" && (
            <SearchResultSeminar
              searchResult={singleSearchResult}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
        </>
      )}
    </>
  );
};

const EmptyKeywordPlaceholder = () => {
  return (
    <div className="mt-[54px] flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center space-y-4">
        <img src={StaticImg("klass/order/complete_icon.svg")} alt="" />
        <h3 className="text-xl text-gray-900 font-semibold">모어덴 통합검색</h3>
        <div className="text-[#A0A0A0] text-base font-semibold text-center">
          검색어를 입력해주세요.
          <br />
          모어덴에 있는 모든 정보를 찾아드립니다.
        </div>
      </div>
    </div>
  );
};
