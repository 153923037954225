import { useNavigate } from "react-router";
import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "../utils/util";
import StyledTooltip from "../component/shared/StyledTooltip";
import Tooltip from "./shared/Tooltip";
import { useRecoilValue } from "recoil";
import { tokenState } from "../state";

const OwnerBadge = ({ uid, showTooltip }) => {
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();

  const handleButtonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    navigate("/mypage/myhospital");
  };

  const { data: userInfoState, isLoading } = useQuery(
    "userInfo",
    () => api?.get("user/info").then((res) => res.data),
    {
      enabled: !!token,
    },
  );

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Tooltip text="개원의" showTooltip={showTooltip}>
      <div className="w-[25px]">
        {/* 내가 인증된 상태 && 다른 유저의 뱃지는 클릭이 되지 않습니다 */}
        {userInfoState?.isOwner && userInfoState?.uid !== uid ? (
          <img className="w-[25px] h-[25px] cursor-default" src="/img/owner_badge.svg" alt="" />
        ) : (
          <div onClick={(event) => handleButtonClick(event)}>
            <img className="w-[25px] h-[25px] cursor-pointer" src="/img/owner_badge.svg" alt="" />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default OwnerBadge;
