import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../../component/Loading";
import ManageContent from "../../../component/management/ManageContent";
import { api } from "../../../utils/util";
import Banner from "component/advertisment/banner";

function EstatePage() {
  const location = useLocation();
  const [pageInfo, setPageInfo] = useState({});
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q") || "";

  useEffect(() => {
    const data = new URLSearchParams();
    page && data.append("page", page);
    q && data.append("q", q);

    api
      .get(`/management/estate/list`, {
        params: data,
      })
      .then((res) => {
        setPageInfo({ ...res.data });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, q]);

  if (!pageInfo) return <Loading current="management" />;
  return (
    <div className="">
      <div className="wrapper">
        <section className="" style={{ marginTop: "54px" }}>
          <div className="inner">
            <Banner page="EstatePage" name="pc_estate_top" width={"full"} height={"full"} />
            <article style={{ marginTop: "60px" }} className="paging">
              <Link
                to={`/management/estate/write`}
                className="write-btn"
                style={{ marginTop: "15px" }}
              >
                <i className="ic ic-write"></i>
                <span className="txt">글쓰기</span>
              </Link>
            </article>
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">병원 양도/임대</span>
              </div>
            </article>
            <div className="explain">모어덴은 부동산 중개에 책임을 지지 않습니다.</div>
          </div>
        </section>

        <section className="magazine__list mt-[60px]" style={{ marginBottom: "35px" }}>
          <div className="inner" style={{ marginTop: "-50px" }}>
            {pageInfo && (
              <ManageContent pageInfo={pageInfo} current="estate" pages={pageInfo.pages} />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default EstatePage;
