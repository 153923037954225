import BoardContent from "../../component/board/BoardContent";
import Footer from "../../component/footer/Footer";
import { api } from "../../utils/util";
import BoardMagazine from "../../component/board/magazine/BoardMagazine";
import BoardLiveComment from "../../component/board/all/BoardLiveComment";
import BoardArticleBox from "../../component/board/all/BoardLiveArticle";
import { useQuery } from "react-query";
import Loading from "../../component/Loading";
import { useLocation } from "react-router-dom";
// import Banner from "../../component/Banner";
import { PuffLoader } from "react-spinners";
import Banner from "component/advertisment/banner";

function BoardPage() {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const board = parseInt(urlParam.get("cate")) || 0;

  const { data: boardList } = useQuery(
    "boardCateCd",
    () => api.get("/board/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
      onError: (err) => {
        err.response && alert(err.response.data.msg);
      },
    },
  );

  const cate_name = boardList?.length
    ? boardList.filter((value) => value.cate_cd === board)[0].cate_name
    : "";

  const board_desc = {
    2: "치과의사 면허를 인증한 회원에게만 보여지는 게시판입니다.",
    3: "학생회원에게만 보여지는 게시판입니다.",
    4: "치과의사 면허를 인증한 여성회원에게만 보여지는 게시판입니다.",
    5: "치과의사 면허를 인증한 남성회원에게만 보여지는 게시판입니다.",
    6: "치과의사 면허를 인증한 회원에게만 보여지는 게시판입니다.",
    7: "강릉원주대 치대생에게만 보여지는 게시판입니다.",
    8: "경북대 치대생에게만 보여지는 게시판입니다.",
    9: "경희대 치대생에게만 보여지는 게시판입니다.",
    10: "단국대 치대생에게만 보여지는 게시판입니다.",
    11: "부산대 치대생에게만 보여지는 게시판입니다.",
    12: "서울대 치대생에게만 보여지는 게시판입니다.",
    13: "연세대 치대생에게만 보여지는 게시판입니다.",
    14: "원광대 치대생에게만 보여지는 게시판입니다.",
    15: "전남대 치대생에게만 보여지는 게시판입니다.",
    16: "전북대 치대생에게만 보여지는 게시판입니다.",
    17: "조선대 치대생에게만 보여지는 게시판입니다.",
  };

  const { data: pickInfo, isLoading: pickInfoLoading } = useQuery("boardListPick", () =>
    api.get("/board/list_pick").then((res) => res.data),
  );

  return (
    <>
      <div className="wrapper mt40">
        {pickInfoLoading ? (
          <div className="flexCenter center" style={{ alignItems: "center", height: "100%" }}>
            <PuffLoader color="#4a25aa" />
          </div>
        ) : (
          <section className="layout__half">
            <div className="inner">
              {/* 실시간 HOT 게시판(전체 탭인 경우) */}
              {board === 0 && pickInfo && (
                <>
                  <div className="grid grid-cols-3 gap-[45px] mb-10">
                    {/* TODO : 임시 설정으로 텍스트 교체함 */}
                    <BoardArticleBox articles={pickInfo.pick_items} title="모어덴 화제의 글" />
                    <BoardArticleBox articles={pickInfo.hot_items} title="실시간 HOT 게시글" />
                    <BoardLiveComment recent_comments={pickInfo.recent_comments} />
                  </div>
                </>
              )}
            </div>
          </section>
        )}
        <div className="inner" style={{ marginBottom: 50 }}>
          <Banner name="pc_Board_middle" page="Board" height="full" />
        </div>
        {/* 모어덴 추천 매거진(매거진 탭) */}
        {board === 1 ? (
          <BoardMagazine />
        ) : (
          <section className="magazine__list" style={{ marginBottom: "136.5px" }}>
            <div className="inner">
              {board !== 0 && board !== 1 && (
                <>
                  <section className="job_select" style={{ marginTop: "54px" }}>
                    <div className="inner">
                      <article className="section__tit__box">
                        <div className="tit-box">
                          <span className="tit-txt">{cate_name} 게시판</span>
                        </div>
                      </article>
                      <div className="explain">{board_desc[board]}</div>
                    </div>
                  </section>
                </>
              )}
              <BoardContent current="board_page" />
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default BoardPage;
