import { CLASS_ROUTE } from "App";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Course } from "types/klass";
import KlassPurchaseButton from "./KlassPurchaseButton";

type KlassPopupProps = {
  course: Course;
  defaultCourseId: string | undefined;
} & KlassPopupActions;

interface KlassPopupActions {
  setPopup: Dispatch<SetStateAction<string>>;
}

const KlassPopup: React.FC<KlassPopupProps> = ({ setPopup, course, defaultCourseId }) => {
  const navigate = useNavigate();
  const vod = course?.previews[0]?.mediaKey;

  return (
    <div>
      <div
        className="fixed left-0 right-0 top-0 bottom-0 z-[100] bg-[rgba(0,0,0,0.4)]"
        onClick={() => setPopup("")}
      ></div>
      <div className="fixed z-[110] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <div className="bg-white pt-[30px] px-7 pb-5 min-w-[320px] lg:w-[514px] shadow-custom rounded-lg relative">
          {vod ? (
            <>
              <div className="mb-3 overflow-hidden break-all text-[25px] leading-8 font-semibold text-gray-900">
                샘플 영상을 확인해보세요.
              </div>
              <div className="text-base font-medium text-gray-900 mb-8">
                강의의 일부가 샘플로 제공되어
                <br /> 무료로 시청하실 수 있습니다.
                <br />
                이후 구매하기 버튼으로 수강권을 구입하시면
                <br /> 수강기간 동안 횟수 제한없이 시청하실 수 있습니다.
              </div>
            </>
          ) : (
            <>
              <div className="mb-3 overflow-hidden break-all text-[25px] leading-8 font-semibold text-gray-900">
                샘플 영상이 없는 강의입니다.
              </div>
              <div className="text-base font-medium text-gray-900 mb-8">
                구매하기 버튼으로 수강권을 구입하시면
                <br /> 수강 기간 동안 횟수 제한 없이 시청하실 수 있습니다.
              </div>
            </>
          )}

          <div className="lg:flex items-center space-y-3 lg:space-y-0 lg:space-x-4">
            <div className="w-full h-[53px] text-xl">
              <KlassPurchaseButton
                course={course}
                defaultCourseId={defaultCourseId}
              ></KlassPurchaseButton>
            </div>
            {vod && (
              <button
                className="w-full lg:w-[177px] h-[53px] flex items-center justify-center border-[1px] border-solid border-gray-300 rounded-md text-xl font-semibold text-gray-900"
                onClick={() => {
                  navigate(`/${CLASS_ROUTE}/course/${course.courseId}?autoplay=autoplay`);
                  window.scrollTo(0, 0);
                  setPopup("");
                }}
              >
                샘플영상 <i className="ii-play ml-1"></i>
              </button>
            )}
          </div>

          <div className="absolute top-4 right-5 cursor-pointer" onClick={() => setPopup("")}>
            <i className="ii ii-remove text-2xl text-gray-900"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KlassPopup;
