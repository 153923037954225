import { memo, useState } from "react";
import ProseMirrorMenuDropDownButton from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuDropDownButton";
import ProseMirrorMenuEmojiButton from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuEmojiButton";
import ProseMirrorMenuFileButton from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuFileButton";
import ProseMirrorMenuInputButton from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuInputButton";
import ProseMirrorMenuPalette from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuPalette";
import ProseMirrorMenuToggleButton from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuToggleButton";
import ProsemirrorMenuFontSize from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuFontSize";
import ProsemirroMenuTextFormat from "../../submodules/integration-editors/prosemirror/ProseMirrorMenuTextFormat";
import {
  BoldIcon,
  ItalicsIcon,
  UnderlineIcon,
  StrikeIcon,
  TextColorIcon,
  TextHighlightIcon,
  QuoteIcon,
  AlignLeftIcon,
  AlignCenterIcon,
  AlignRightIcon,
  LineIcon,
  LinkIcon,
  ImageIcon,
  VideoIcon,
  FileIcon,
  TableIcon,
  InlineStyleIcon,
  FontSizeIcon,
  TextFormatIcon,
} from "../../submodules/integration-editors/prosemirror/assets/icons";
import { INTEGRATION_EDITOR_MENUBAR_CLASS } from "../../submodules/integration-editors/prosemirror/styles/classNames";
import {
  textColors,
  textHighlightColors,
  tableCellBackgroundColors,
} from "../../style/colorsMoreden";
import { cls } from "../../utils/util";

const ProsemirrorEditorMenubar = ({
  dispatchMetaData,
  onFontSizeOpen,
  fontNodeType,
  toggleBtnName,
  setToggleBtnName,
  uploadFiles,
  IsAdmin,
}) => {
  const menubarClassName = INTEGRATION_EDITOR_MENUBAR_CLASS;
  const [inputText, setInputText] = useState("");
  const [isTablePaletteVisible, setIsTablePaletteVisible] = useState(false);

  const handleMenuButtonClick = (e) => {
    const button = e.target.closest("button");
    if (!button) return;
    const command = button?.getAttribute("data-command-name");
    const buttonType = button?.getAttribute("data-icon-type");

    if (buttonType === "toggle") dispatchMetaData(`toggle-${command}`);
    if (buttonType === "dropdown") {
      if (command === "fontSize") onFontSizeOpen();
    }
  };

  const preventBlur = (e) => {
    if (e.target.closest(`.${INTEGRATION_EDITOR_MENUBAR_CLASS}__input-box`)) return;
    e.preventDefault();
  };

  const handleDropDownItemClick = (e) => {
    if (!e.target.closest("li")) return;

    const command = e.target.closest("li").getAttribute("data-command-name");
    dispatchMetaData(command);

    setToggleBtnName({ dropDown: false, name: "" });
  };

  return (
    <div
      className={INTEGRATION_EDITOR_MENUBAR_CLASS}
      onClick={handleMenuButtonClick}
      onMouseDown={preventBlur}
    >
      {/* 볼드 버튼 */}
      <ProseMirrorMenuToggleButton
        Name="strong"
        Description="볼드"
        tooltip="굵게 Ctrl(⌘)B"
        Icon={BoldIcon}
        menubarClassName={menubarClassName}
      />
      {/* 기울임 버튼 */}
      <ProseMirrorMenuToggleButton
        Name="em"
        Description="기울임꼴"
        tooltip="기울임꼴 Ctrl(⌘)I"
        Icon={ItalicsIcon}
        menubarClassName={menubarClassName}
      />
      {/* 밑줄 버튼 */}
      <ProseMirrorMenuToggleButton
        Name="underline"
        Description="밑줄"
        tooltip="밑줄 Ctrl(⌘)U"
        Icon={UnderlineIcon}
        menubarClassName={menubarClassName}
      />
      {/* 취소선 버튼 */}
      <ProseMirrorMenuToggleButton
        Name="del"
        Description="취소선"
        Icon={StrikeIcon}
        menubarClassName={menubarClassName}
      />
      {/* 모어덴 하이라이트 언더라인 버튼 */}
      {IsAdmin && (
        <ProseMirrorMenuToggleButton
          Name="highlighted"
          Description="인라인 스타일"
          Icon={InlineStyleIcon}
          menubarClassName={menubarClassName}
        />
      )}
      {/* 글자색 버튼 */}
      <ProseMirrorMenuDropDownButton
        Name="textColor"
        Icon={TextColorIcon}
        Description="글자색"
        setToggleBtnName={setToggleBtnName}
        menubarClassName={menubarClassName}
        ShowArrowDownIcon={false}
      >
        {toggleBtnName.dropDown && toggleBtnName.name === "textColor" && (
          <ProseMirrorMenuPalette
            Open={toggleBtnName.name === "textColor"}
            Context="text"
            Colors={[...textColors, ["reset-style", "reset-style"]]}
            dispatchMetaData={dispatchMetaData}
            menubarClassName={menubarClassName}
            cls={cls}
          />
        )}
      </ProseMirrorMenuDropDownButton>

      {/* 글자 배경색 버튼 */}
      <ProseMirrorMenuDropDownButton
        Name="textHighlight"
        Icon={TextHighlightIcon}
        Description="글자 배경"
        setToggleBtnName={setToggleBtnName}
        menubarClassName={menubarClassName}
        ShowArrowDownIcon={false}
      >
        {toggleBtnName.dropDown && toggleBtnName.name === "textHighlight" && (
          <ProseMirrorMenuPalette
            dispatchMetaData={dispatchMetaData}
            Open={toggleBtnName.name === "textHighlight"}
            Context="textHighlight"
            Colors={[...textHighlightColors, ["reset-style", "reset-style"]]}
            menubarClassName={menubarClassName}
            cls={cls}
          />
        )}
      </ProseMirrorMenuDropDownButton>
      {/* 문단 서식(제목/본문) 버튼 */}
      <ProseMirrorMenuDropDownButton
        Name="textFormat"
        Icon={TextFormatIcon}
        ShowArrowDownIcon
        Description="문단 서식"
        setToggleBtnName={setToggleBtnName}
        menubarClassName={menubarClassName}
      >
        {toggleBtnName.dropDown && toggleBtnName.name === "textFormat" && (
          <ProsemirroMenuTextFormat
            Open={toggleBtnName.name === "textFormat"}
            Context="textFormat"
            menubarClassName={menubarClassName}
            cls={cls}
            dispatchMetaData={dispatchMetaData}
          />
        )}
      </ProseMirrorMenuDropDownButton>
      {/* 글자 크기 버튼 */}
      <ProseMirrorMenuDropDownButton
        Name="fontSize"
        Icon={FontSizeIcon}
        ShowArrowDownIcon
        Description="글자 크기"
        setToggleBtnName={setToggleBtnName}
        menubarClassName={menubarClassName}
      >
        {toggleBtnName.dropDown && toggleBtnName.name === "fontSize" && (
          <ProsemirrorMenuFontSize
            Open={toggleBtnName.name === "fontSize"}
            Context="fontSize"
            NodeType={fontNodeType}
            menubarClassName={menubarClassName}
            dispatchMetaData={dispatchMetaData}
            cls={cls}
          />
        )}
      </ProseMirrorMenuDropDownButton>
      {/* 인용문 버튼 */}
      <ProseMirrorMenuToggleButton
        Name="blockquote"
        Description="인용"
        Icon={QuoteIcon}
        menubarClassName={menubarClassName}
      />
      {/* 글자 정렬 버튼 */}
      <ProseMirrorMenuDropDownButton
        Name="textAlign"
        Icon={AlignLeftIcon}
        ShowArrowDownIcon
        Description="글자 정렬"
        setToggleBtnName={setToggleBtnName}
        menubarClassName={menubarClassName}
      >
        {toggleBtnName.dropDown && toggleBtnName.name === "textAlign" && (
          <ul
            className={`${INTEGRATION_EDITOR_MENUBAR_CLASS}__dropdown textAlign`}
            style={toggleBtnName.name === "textAlign" ? { display: "block" } : { display: "none" }}
            onClick={handleDropDownItemClick}
          >
            <li
              className={`${INTEGRATION_EDITOR_MENUBAR_CLASS}__icon dropdown-item alignLeft`}
              dangerouslySetInnerHTML={{ __html: AlignLeftIcon }}
              data-command-name="alignLeft"
            ></li>
            <li
              className={`${INTEGRATION_EDITOR_MENUBAR_CLASS}__icon dropdown-item alignCenter`}
              dangerouslySetInnerHTML={{ __html: AlignCenterIcon }}
              data-command-name="alignCenter"
            ></li>
            <li
              className={`${INTEGRATION_EDITOR_MENUBAR_CLASS}__icon dropdown-item alignRight`}
              dangerouslySetInnerHTML={{ __html: AlignRightIcon }}
              data-command-name="alignRight"
            ></li>
          </ul>
        )}
      </ProseMirrorMenuDropDownButton>
      {/* 가로줄 버튼 */}
      <ProseMirrorMenuToggleButton
        Name="hr"
        Description="수평선"
        Icon={LineIcon}
        menubarClassName={menubarClassName}
      />
      {/* 링크 버튼 */}
      <ProseMirrorMenuDropDownButton
        Name="link"
        Icon={LinkIcon}
        Description="링크 삽입"
        setToggleBtnName={setToggleBtnName}
        menubarClassName={menubarClassName}
        ShowArrowDownIcon={false}
      >
        {toggleBtnName.dropDown && toggleBtnName.name === "link" && (
          <ProseMirrorMenuInputButton
            dispatchMetaData={dispatchMetaData}
            Open={toggleBtnName.name === "link"}
            InputFor={"insertLink"}
            cls={cls}
            Name="link"
            menubarClassName={menubarClassName}
            inputText={inputText}
            setInputText={setInputText}
          />
        )}
      </ProseMirrorMenuDropDownButton>

      {/* 이미지 첨부 버튼 */}
      <ProseMirrorMenuFileButton
        Name="image"
        menubarClassName={menubarClassName}
        Description="이미지 삽입"
        Icon={ImageIcon}
        cls={cls}
        uploadFiles={uploadFiles}
      ></ProseMirrorMenuFileButton>
      {/* 비디오 첨부 버튼 */}
      <ProseMirrorMenuFileButton
        Name="video"
        menubarClassName={menubarClassName}
        Description="비디오 삽입"
        tooltip={
          IsAdmin ? (
            <div>
              비디오 삽입
              <br />
              (최대 100M)
            </div>
          ) : (
            <div>
              비디오 삽입
              <br />
              (최대 20M)
            </div>
          )
        }
        Icon={VideoIcon}
        cls={cls}
        uploadFiles={uploadFiles}
      ></ProseMirrorMenuFileButton>
      {/* 파일 첨부 버튼 */}
      <ProseMirrorMenuFileButton
        Name="file"
        menubarClassName={menubarClassName}
        Description="파일 삽입"
        Icon={FileIcon}
        cls={cls}
        uploadFiles={uploadFiles}
      ></ProseMirrorMenuFileButton>
      {/* table 버튼 */}
      <ProseMirrorMenuDropDownButton
        Name="table"
        Icon={TableIcon}
        Description="표 삽입"
        ShowArrowDownIcon
        setToggleBtnName={setToggleBtnName}
        menubarClassName={menubarClassName}
      >
        <ul
          className={cls(
            INTEGRATION_EDITOR_MENUBAR_CLASS + "__dropdown table",
            toggleBtnName.dropDown && toggleBtnName.name === "table" ? "!block" : "hidden",
          )}
          onClick={handleDropDownItemClick}
        >
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="addTable"
          >
            테이블 추가
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name=""
            onMouseEnter={() => setIsTablePaletteVisible(true)}
            onMouseLeave={() => setIsTablePaletteVisible(false)}
          >
            셀 배경색
          </li>
          {isTablePaletteVisible && (
            <div onMouseEnter={() => setIsTablePaletteVisible(true)}>
              <ProseMirrorMenuPalette
                Open={isTablePaletteVisible}
                Context="table"
                Colors={[...tableCellBackgroundColors, ["reset-style", "reset-style"]]}
                dispatchMetaData={dispatchMetaData}
                menubarClassName={menubarClassName}
                cls={cls}
              />
            </div>
          )}
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="toggleCellBorder"
          >
            테두리 켜기/끄기<code>Ctrl+Alt+ㅁ</code>
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="mergeCells"
          >
            셀 합치기
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="splitCell"
          >
            셀 나누기
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="headerRow"
          >
            헤더 행으로 전환
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="headerColumn"
          >
            헤더 열로 전환
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="removeRow"
          >
            행 삭제<code>Ctrl+Alt+ㅡ</code>
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="removeColumn"
          >
            열 삭제<code>Ctrl+Alt+ㅣ</code>
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="addRowAbove"
          >
            위에 행 추가<code>Ctrl+Alt+↑</code>
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="addRowBelow"
          >
            아래에 행 추가<code>Ctrl+Alt+↓</code>
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="addColumnRight"
          >
            우측에 열 추가<code>Ctrl+Alt+→</code>
          </li>
          <li
            className={INTEGRATION_EDITOR_MENUBAR_CLASS + "__icon dropdown-text-item"}
            data-command-name="addColumnLeft"
          >
            좌측에 열 추가<code>Ctrl+Alt+←</code>
          </li>
        </ul>
      </ProseMirrorMenuDropDownButton>
      {/* 이모지 버튼 */}
      <ProseMirrorMenuEmojiButton
        Name="emoji"
        menubarClassName={menubarClassName}
        Description="이모지"
        dispatchMetaData={dispatchMetaData}
      ></ProseMirrorMenuEmojiButton>
    </div>
  );
};

export default memo(ProsemirrorEditorMenubar);
