import "../../style/addMoreden.scss";

const ProseMirrorEditorView = ({ contentString }: { contentString: string | undefined }) => {
  const contentStringWithBlankTarget = () => {
    const replaceString = contentString?.replace(
      /<a(.*?)href="(.*?)"(.*?)>(.*?)<\/a>/g,
      (match, p1, p2, p3, p4) => {
        if (p2.includes("moreden")) {
          return match;
        } else {
          return `<a${p1}href="${p2}"${p3} target="_blank">${p4}</a>`;
        }
      },
    );
    const regex = /(<video[^>]*class=["'][^"']*integration-editor__video[^"']*["'][^>]*)(>)/g;
    const replacement = '$1 controlsList="nodownload"$2';
    return replaceString?.replace(regex, replacement);
  };

  return (
    <div
      onContextMenu={(e) => {
        (e.target as any).matches("img") && e.preventDefault();
      }}
      dangerouslySetInnerHTML={{ __html: contentStringWithBlankTarget() ?? "" }}
    ></div>
  );
};

export default ProseMirrorEditorView;
