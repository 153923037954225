import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

class IframeNodeView {
  /**
   * @type {import('prosemirror-view').NodeViewConstructor}
   */
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos
    this.unresizeablePlatforms = ['youtube', 'kollus']
    this.platform = this.node.attrs['data-platform']

    this.iframe = document.createElement('iframe')
    this.iframe.src = this.node.attrs.src
    this.iframe.height = this.node.attrs.height
    this.iframe.frameBorder = 0
    this.iframe.allowFullscreen = true
    this.iframe.className = MEDISTREAM_SCHEMA_STYLE.nodes.iframe
    this.iframe.setAttribute('data-youtube-id', this.node.attrs['data-youtube-id'])
    this.iframe.setAttribute('data-platform', this.platform)
    this.iframe.setAttribute('data-embed-src', this.node.attrs.src)

    this.dom = document.createElement('div')
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.etc.iframeWrapper)

    this.dom.append(this.iframe)

    this.__onMouseDown = this._onMouseDown.bind(this)

    this._setInitialHeight()
    this._attachResizeBar()
  }

  selectNode() {
    this.dom.classList.add('ProseMirror-selectednode')
    if (this.resizeBar) {
      this.resizeBar.style.display = 'block'
    }
  }

  deselectNode() {
    this.dom.classList.remove('ProseMirror-selectednode')
    if (this.resizeBar) {
      this.resizeBar.style.display = 'none'
    }
  }

  /**
   * Youtube, Kollus 플레이어를 제외한 iframe 에는 높이를 조절하는 resize bar 를 추가합니다.
   */
  _attachResizeBar() {
    if (this.unresizeablePlatforms.includes(this.platform)) return

    this.resizeBar = document.createElement('div')
    this.resizeBar.className = MEDISTREAM_SCHEMA_STYLE.etc.iframeResizer

    this.resizeBar.addEventListener('mousedown', this.__onMouseDown)
    this.dom.append(this.resizeBar)
  }

  _setInitialHeight() {
    if (this.node.attrs.height !== 'auto') return
    if (this.platform === 'google-form') {
      this.iframe.height = 1100
    } else if (this.platform === 'etc') {
      this.iframe.height = 700
    }
  }

  _onMouseDown(e) {
    e.preventDefault()
    e.stopPropagation()

    const startY = e.pageY
    const startHeight = this.iframe.offsetHeight

    const onMouseMove = (e) => {
      const newHeight = startHeight + (e.pageY - startY)
      if (newHeight < 400) return
      this.iframe.height = newHeight
    }

    const onMouseUp = (e) => {
      const transaction = this.view.state.tr.setNodeAttribute(
        this.getPos(),
        'height',
        this.iframe.height
      )

      this.view.dispatch(transaction)
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
  }

  destroy() {
    window.removeEventListener('mousedown', this.__onMouseDown)
  }
}

export const iframeNodeView = (node, view, getPos) =>
  new IframeNodeView(node, view, getPos)

/**
 * iframe 노드에서 개별적으로 대응하는 플랫폼들은 다음과 같습니다.
 * 
 *  1. Youtube:     유튜브 임베드 기능, 썸네일(data-youtube-id) 추출 기능.
 *  2. Google Form: 구글 폼 임베드 이후 높이 조절 기능.
 *  3. 콜러스 플레이어: 콜러스 플레이어의 크기 조절을 방지하는 기능.
 * 
 * 그외의 플랫폼은 data-platform="etc" 로 분류합니다.
 * 
 * iframe 노드의 NodeView, SCSS 파일을 참고해주세요.
 */
export const Iframe = Extension.Create({
  name: 'iframe',

  type: 'node',

  defineSpec() {
    return {

      attrs: {
        src: {},
        'data-youtube-id': {default: ''},
        'data-platform': {default: 'etc'},
        frameborder: {default: 0},
        allowfullscreen: {default: true},
        class: {default: MEDISTREAM_SCHEMA_STYLE.nodes.iframe},
        height: {default: 'auto'}
      },
      group: 'block',
      marks: '',
      draggable: true,
      toDOM: node => [
        'div',
        {
          style: 'display: flex;',
        },
        [
          'iframe',
          {
            src: node.attrs.src,
            frameborder: 0,
            allowfullscreen: true,
            class: node.attrs.class,
            height: node.attrs.height,
            'data-platform': node.attrs['data-platform'],
            'data-youtube-id': node.attrs['data-youtube-id'],
          },
        ]
      ],
      parseDOM: [
        {
          tag: 'iframe',
          getAttrs(dom) {
            const src = dom.getAttribute('src') || ''
            let platform = 'etc'
            if (src.startsWith('https://www.youtube.com/embed/')) {
              platform = 'youtube'
            } else if (src.startsWith('https://docs.google.com/forms/') || src.startsWith('https://forms.gle/')) {
              platform = 'google-form'
            } else if (src.startsWith('https://v.kr.kollus.com/')) {
              platform = 'kollus'
            }
            return {
              src: dom.getAttribute('src') || '',
              frameborder: dom.getAttribute('frameborder') || 0,
              allowfullscreen: dom.getAttribute('allowfullscreen') || true,
              height: dom.getAttribute('height') || 700,
              'data-youtube-id': dom.getAttribute('data-youtube-id') || '',
              'data-platform': platform,
            }
          }
        }
      ]
    }
  },

  addNodeView() {
    return iframeNodeView
  }
})
