import { CellSelection } from '@medistream/prosemirror-tables'
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { Heading } from './heading';
import { Paragraph } from './paragraph';

/**
 * @type {(fn: string) => import('prosemirror-state').Command}
 */
export const setTextBackgroundColor = (color) => (state, dispatch) => {
  const tr = state.tr
  const selection = tr.selection
  const {from, to} = state.selection

  if (selection instanceof CellSelection) {
    const heading = state.schema.nodes[Heading.name]
    const paragraph = state.schema.nodes[Paragraph.name]
    const tasks = []
    const allowedNodeTypes = new Set([heading, paragraph])

    selection.forEachCell((cell, pos) => {
      cell.content.forEach((node, offset) => {
        const resolvedNode = state.doc.resolve(pos + offset + 1).nodeAfter
        if (allowedNodeTypes.has(node.type)) {
          tasks.push({
            pos: pos + offset + 1,
            posEnd: pos + offset + 1 + resolvedNode.nodeSize,
          })
        }
      })
    })

    if (!tasks.length) return false

    tasks.forEach(job => {
      const {pos, posEnd} = job

      color === 'reset-style'
      ? tr.removeMark(pos, posEnd, state.schema.marks[TextBackground.name])
      : tr.addMark(pos, posEnd, state.schema.marks[TextBackground.name].create({style: `background-color: ${color};`}))
    })
    
    dispatch(tr)
    return true
  }

  if (color === 'reset-style') {
    tr.removeMark(from, to, state.schema.marks[TextBackground.name])

    return dispatch(tr)
  }

  tr.addMark(from, to, state.schema.marks[TextBackground.name].create({style: `background-color: ${color}; border: 2px solid ${color};`}))

  dispatch(tr)
  return true
}

/**
 * 글자의 배경색은 <span> 태그의 background-color 속성으로 표현됩니다.
 * 
 * text_color Mark 와 마찬가지로 외부 글을 붙여넣을 때 background-color 속성값이 따라오는 경우에 대응합니다.
 */
export const TextBackground = Extension.Create({
  name: 'text_background',

  type: 'mark',

  defineSpec() {
    return {
      attrs: {
        style: {
          default: null
        }
      },
      inclusive: false,
      toDOM: node => ['span', { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.background_color }, 0],
      parseDOM: [
        {
          tag: 'span',
          style: 'background-color',
          getAttrs: dom => {
            if (dom.style?.backgroundColor) {
              /**
               * MARK: Google Docs 에서 작성한 글을 붙여넣을 때 간헐적으로 따라오는 스타일을 제거합니다.
               *       예) <span style="background-color: rgb(255, 255, 255);"></span>
              */
             if (dom.style.backgroundColor === 'transparent') return false
             if (dom.style.backgroundColor === 'rgb(255, 255, 255)') return false
             if (dom.style.backgroundColor === 'rgb(248, 248, 248)') return false
             return {
               style: `background-color: ${dom.style.backgroundColor};`
              }
            }
            return false
          },
          consuming: false
        }
      ]
    }
  },

  addCommands() {
    return {
      setTextBackgroundColor,
    }
  }
})

export const TextBackground_Safe = Extension.Create({
  name: 'text_background',

  type: 'mark',

  defineSpec() {
    return {
      attrs: {
        style: {
          default: null
        }
      },
      inclusive: false,
      toDOM: node => ['span', { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.background_color }, 0],
    }
  },

  addCommands() {
    return {
      setTextBackgroundColor,
    }
  }
})
