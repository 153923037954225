import React from "react";
import TongtestContent from "../../component/tongtest/TongtestContent";

const TongtestBoard = () => {
  return (
    <div>
      <article style={{ margin: "100px 0 50px" }} className="section__tit__box">
        <div className="tit-box">
          <span className="tit-txt">게시판</span>
        </div>
      </article>
      <section className="magazine__list">
        <TongtestContent current="board_page" />
      </section>
    </div>
  );
};

export default TongtestBoard;
