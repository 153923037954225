import axios from "axios";

const AD_URL = import.meta.env.VITE_APP_AD_URL || "https://api.adstream.biz/api/ads/moreden";

export const getAdsInventoryList = async (pageName) => {
  try {
    const { status, data } = await axios({
      method: "get",
      baseURL: AD_URL,
      url: `/${pageName}`,
    });

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error("Failed to get ads inventory list");
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAdsCampaign = async (name, adsInventoryList, userGroupCode) => {
  try {
    const inventory = adsInventoryList.find((v) => v.inventoryName === name);
    if (!inventory) return [];

    const { status, data } = await axios({
      method: "get",
      baseURL: AD_URL,
      url: `/inventory/${inventory.inventoryId}?userGroupCode=${userGroupCode}`,
    });

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Failed to get ads campaign for ${name}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
