import { StaticImg, marketUrl } from "utils/util";

export default function MarketRedirectPopup({ closePopup }: { closePopup: () => void }) {
  const redirectMarket = () => {
    window.location.replace(marketUrl("/"));
  };

  return (
    <>
      <div
        onClick={closePopup}
        className="fixed z-[110] left-0 top-0 h-full w-full bg-[#00000066]"
      ></div>
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[120] rounded-[10px] p-5 w-[500px] h-[300px] bg-white flex flex-col items-center justify-center">
        <img src={StaticImg("klass/order/complete_icon.svg")} alt="img" className="mb-5" />
        <div className="text-xl font-semibold mb-1">마켓 전용 계정으로 로그인 하셨습니다</div>
        <div className="text-sm text-gray-500 text-center">
          마켓 계정은 커뮤니티 권한이 제한됩니다
        </div>
        <button
          onClick={redirectMarket}
          className="mt-6 h-[52px] w-full items-center justify-center rounded-[6px] bg-primary-700 text-[18px] font-semibold text-white hover:bg-primary-800 disabled:cursor-not-allowed disabled:opacity-50 lg:w-[120px]"
        >
          마켓으로 이동
        </button>
      </div>
    </>
  );
}
