import { api } from "../../utils/util";
import ClassQuiz from "../../component/class/ClassQuiz";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ClassComment from "../../component/class/ClassComment";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import dayjs from "dayjs";
import LectureLoading from "./LectureLoading";
import { PuffLoader } from "react-spinners";
import { EDU_ROUTE } from "App";

const ClassLecture = () => {
  const navigate = useNavigate();
  const lid = parseInt(useParams().lid);
  const [popup, setPopup] = useState("closed");
  const [progress, setProgress] = useState(false);
  const [contents, setContents] = useState({ load: false });
  const [lecture, setLecture] = useState({ load: false });
  const [vodLoad, setVodLoad] = useState(false);
  const content_id =
    lecture.load && lecture.contents && lecture.contents.length > 0 && lecture.contents[0].cid;
  // const powered_by_list = [
  //   {
  //     url: "https://imegagen.com/ko/",
  //     img: "img/class_powered_by_0.png",
  //   },
  // ];
  const closePopup = (isDone) => {
    setPopup("closed");
    if (isDone) {
      api.get(`class/contents/${content_id}`).then((res) => {
        setContents({ ...contents, progress: res.data.progress });
      });
    }
  };

  const exitLive = () => {
    api.post(`class/contents/temp/done/${content_id}`).then((res) => {
      navigate(-1);
    });
  };

  const location = useLocation();
  const registration = location.state ? location.state.registration : null;
  const userToken = useRecoilValue(tokenState);
  const timerId = useRef(null);
  const classDttm = (dttm) => dayjs(dttm, "YYYYMMDDHHmmss").format("HH시 mm분 ss초");
  const completeDttm = (dttm) => dayjs(dttm, "YYYYMMDDHHmmss").add(2, "h").format("HH시 mm분 ss초");

  if (!registration) {
    navigate(`/${EDU_ROUTE}`, { replace: true });
  }

  useEffect(() => {
    lid &&
      api
        .get(`class/lecture/${lid}`)
        .then((res) => {
          setLecture({ ...res.data, load: true });
        })
        .catch((err) => {
          alert(err.response.data.msg);
          navigate(`/${EDU_ROUTE}`, { replace: true });
        });
    // api.get(``); 클래스 기 진행도 검사
  }, [lid, navigate]);

  useEffect(() => {
    if (!content_id) return;
    api
      .get(`class/contents/${content_id}`)
      .then((res) => setContents({ ...res.data, load: true }))
      .catch((err) => {
        alert(err.response.data.msg);
        navigate(`/${EDU_ROUTE}/${lecture.lecture.cid}`, { replace: true });
      });
  }, [content_id, lecture, navigate]);

  useEffect(() => {
    if (!contents.load) return;
    if (contents.content.contents_type === "live_youtube") return;
    let controller = new window.VgControllerClient({
      target_window: window.document.getElementById("lecture_iframe").contentWindow,
    });

    contents.content.contents_type === "live_streaming" &&
      contents.seekable_end !== null &&
      controller.on("play", function () {
        controller.play(contents.seekable_end);
      });

    controller.on("ready", function () {
      setVodLoad(true);
    });

    controller.on("pause", function () {
      const data = new FormData();
      data.append("etime", Math.trunc(controller.get_current_time()));
      api.post(`/class/lecture/progress/${lid}`, data);
    });

    controller.on("progress", function (percent, position, duration) {
      setProgress({ percent, position, duration });
    });

    controller.on("done", () => {
      const data = new FormData();
      data.append("etime", Math.trunc(contents.content.contents_time_sec));
      api.post(`/class/lecture/progress/${lid}`, data);
      lecture.quizzes.find((item) => item.lid === lid)
        ? setPopup("quiz")
        : api.post(`/class/lecture/progress/${lid}/done`, data).then(() => setPopup("done"));
    });

    timerId.current = setInterval(() => {
      if (dayjs(contents.content.disable_at, "YYYYMMDDHHmmss") < dayjs()) {
        clearInterval(timerId.current);
        alert("재생시간이 종료되었습니다");
        navigate(`class/${lecture.lecture.cid}`, { replace: true });
      }
    }, 600);

    return () => {
      clearInterval(timerId.current);
      const data = new FormData();
      data.append("etime", Math.trunc(controller.get_current_time()));
      api.post(`/class/lecture/progress/${lid}`, data);
    };
  }, [lid, contents, lecture, navigate]);

  return (
    <>
      <div className="lecture_play_box">
        <div className="lecture_left_box">
          {contents.load ? (
            <>
              <div className="lecture_info">
                <div className="title">
                  <button
                    style={{ width: "50px" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ii ii-chevron-left"></i>
                  </button>
                  {contents.content.title}
                </div>
              </div>
              <div className="lecture__video-wrap" style={{ height: "500px" }}>
                <iframe
                  className=""
                  id="lecture_iframe"
                  src={contents.link}
                  title="lecture"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                {!vodLoad && contents.content.contents_type !== "live_youtube" && (
                  <div
                    className="loading"
                    style={{
                      background: "#333",
                      width: "300px",
                      height: "170px",
                      flexDirection: "column",
                      borderRadius: "5px",
                    }}
                  >
                    <PuffLoader color="#4a25aa" />
                    <span style={{ color: "white", marginTop: "20px" }}>
                      영상을 다운로드받고 있습니다...
                    </span>
                  </div>
                )}
              </div>
              {contents.content.contents_type === "live_youtube" && (
                <div className="flex" style={{ alignItems: "center", marginTop: 15 }}>
                  <span style={{ color: "#333" }}>
                    📍<b>{completeDttm(contents.progress.lecture_started_at)}</b> 이후에 우측의
                    퇴장하기 버튼을 눌러주셔야 <b>이수완료</b>됩니다.
                  </span>
                  <button className="live_exit" onClick={exitLive}>
                    👋 퇴장하기
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="lecture_info">
                <div className="title">
                  <button
                    style={{ width: "50px" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ii ii-chevron-left"></i>
                  </button>
                  Loading...
                </div>
              </div>
              <div
                className="lecture__video-wrap"
                style={{ height: "500px", display: "flex", justifyContent: "center" }}
              >
                <LectureLoading />
                <div className="loading">
                  <PuffLoader color="#4a25aa" />
                </div>
              </div>
            </>
          )}
          <ClassComment
            current="contents"
            content={contents}
            article_num={content_id}
            setContent={setContents}
          />
        </div>
        <div className="lecture_right_box">
          <div className="lecture_info">
            <div className="user_info">
              <div className="top_box">{userToken.user_name}님</div>
              <div className="course_progress lecture">
                {contents.load &&
                  (contents.content.contents_type !== "live_youtube" ? (
                    <>
                      <div className="txt">
                        <label className="main">
                          {contents.progress && !!contents.progress.lecture_completed_at
                            ? "수강 완료"
                            : progress && `${progress.percent}% 수강중`}
                        </label>
                        <div
                          className={
                            contents.progress && !!contents.progress.lecture_completed_at
                              ? "progress done"
                              : "progress"
                          }
                        >
                          {contents.progress && !!contents.progress.lecture_completed_at
                            ? "이수완료"
                            : "수강미완료"}
                        </div>
                      </div>
                      <progress
                        id="file"
                        max={100}
                        value={
                          contents.progress && !!contents.progress.lecture_completed_at
                            ? 100
                            : progress.percent
                        }
                      ></progress>
                    </>
                  ) : (
                    <div className="txt" style={{ marginTop: 10 }}>
                      <label className="main">LIVE 수강중</label>
                      <span className="progress" style={{ marginLeft: 5 }}>
                        ⌚입장시간 : {classDttm(contents.progress.lecture_started_at)}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* {powered_by_list.map((item, idx) => {
            return (
              <a key={idx} href={item.url} target="_blank" rel="noopener noreferrer">
                <img src={Img("", item.img)} alt="" />
              </a>
            );
          })} */}
        </div>
      </div>
      {lecture.load && popup === "quiz" && (
        <ClassQuiz
          closePopup={closePopup}
          lid={lid}
          qid={lecture.quizzes.find((item) => item.lid === lid).qid}
        />
      )}
      {lecture.load && popup === "done" && (
        <div className="class-quiz-box">
          <div className="quiz_done">
            <i className="close" onClick={() => closePopup(true)} />
            <div style={{ fontSize: 60, marginBottom: 30 }}>👏</div>
            <div>
              수고하셨습니다! <b>수강완료</b>되었습니다😀.
            </div>
            <div>
              모든 강의를 수강하신 후에 <b>수료증</b>을 받으실 수 있습니다.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClassLecture;
