import SideMenu from "component/mypage/SideMenu";
import { cls, getEarlierDate, publicApi, klassExpiredAt, resizedImg } from "../../utils/util";
import dayjs from "dayjs";
import Loading from "component/Loading";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { CourseDetail, MYCoursesList } from "types/klass";
import { useEffect } from "react";
import { CLASS_ROUTE } from "App";
import NoItem from "component/shared/NoItem";

const MyKlass = () => {
  const navigate = useNavigate();

  const { data: selfCoursesList, isLoading: selfCoursesListLoading } = useQuery<
    MYCoursesList | undefined,
    Error
  >(["classStudentSelfCourses"], () =>
    publicApi?.get(`/class/students/self/courses`).then((res) => res.data),
  );

  const progressPercent = (item?: number) => {
    if (!item) return 0;
    return Number(Math.round(Number(item.toFixed(2)) * 10) / 10);
  };

  // 이어서보기 버튼 클릭시 상세페이지에서 바로 kollus실행 시키는 코드:추후 사용할 수 있어서 주석처리 합니다.
  // const linkToKlass = (item: CourseDetail, courseId: string) => {
  //   for (let j = 0; j < item.progress.chapters.length; j++) {
  //     const chapter = item.progress.chapters[j];
  //     for (let i = 0; i < chapter.lectures.length; i++) {
  //       if (!chapter.lectures[i].isCompleted) {
  //         sessionStorage.setItem("play", "true");
  //         return navigate(
  //           `/${CLASS_ROUTE}/course/${courseId}?chapterId=${chapter.chapterId}&lectureId=${chapter.lectures[i].lectureId}`,
  //           { replace: true },
  //         );
  //       } else return null;
  //     }
  //   }
  // };

  const courseStatus = (item: CourseDetail) => {
    const now = dayjs(new Date());
    const expire = dayjs(getEarlierDate(item.progress?.expireAt, item.course?.learnEndAt));
    const registration = item?.progress?.registrationStatus;

    return expire.diff(now, "day") <= 0
      ? "수강 종료"
      : registration && (selfCoursesList?.registrationStatusLabel as any)[registration];
  };

  const myKlassExpiredAt = (item: CourseDetail) => {
    if (!item?.progress?.expireAt) return "";
    if (dayjs(item.progress.expireAt).diff(dayjs()) < 0) return "수강종료";
    return klassExpiredAt(item.course, item.progress);
  };

  const buttonName = (item: CourseDetail) => {
    const nowDate = new Date();
    const expireDate =
      item.progress?.expireAt && getEarlierDate(item.progress.expireAt, item.course?.learnEndAt);

    if (
      (item?.progress.progressPct === 0 || !item?.progress.progressPct) &&
      expireDate &&
      expireDate > nowDate
    )
      return "강의 보기";
    else if (expireDate && expireDate < nowDate) return "수강 종료되었습니다";
    else return "이어서 보기";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!selfCoursesList || selfCoursesListLoading) return <Loading small />;

  return (
    <div className="relative">
      <div className="relative lg:w-[1200px] m-auto">
        <div className="flex justify-center">
          <section className="hidden lg:block lnb__layout">
            <SideMenu current="class" />
          </section>
          <div className="w-full">
            <section className="bg-gray-100 lg:bg-[#fff] lg:mt-0 w-full pt-[14px] lg:pt-0">
              <article className="section__tit__box px-5 lg:px-0">
                <div className="tit-box">
                  <span className="tit-txt flex items-center">
                    나의 강의실{" "}
                    <span className="text-base text-primary-800 ml-2">
                      {selfCoursesList?.studentCourses?.length}개
                    </span>
                  </span>
                </div>
              </article>

              {selfCoursesList?.studentCourses?.length > 0 ? (
                <div className="space-y-3 lg:space-y-0 lg:flex lg:flex-wrap gap-x-7 gap-y-6 mt-3">
                  {selfCoursesList?.studentCourses?.map((item) => {
                    return (
                      <div key={item.course.courseId}>
                        <div
                          className="w-full lg:w-[475px] lg:h-[112px] shadow-custom cursor-pointer bg-[#fff] px-4 pb-2 py-[14px] lg:px-0 lg:py-0 rounded-none lg:rounded-md"
                          onClick={() =>
                            courseStatus(item) === "수강 종료"
                              ? ""
                              : navigate(`/${CLASS_ROUTE}/course/${item.course.courseId}`)
                          }
                        >
                          <div className="flex items-center space-x-2">
                            <div className="filter lg:drop-shadow-none relative">
                              <div className="lg:w-[190px] lg:h-[112px]">
                                <img
                                  src={resizedImg(item.course?.thumbnailImage?.link)}
                                  alt=""
                                  className="w-full h-full rounded lg:-mt-1 lg:rounded-l-lg lg:rounded-r-none object-cover"
                                />
                              </div>
                              <div
                                className={cls(
                                  courseStatus(item) === "수강 전" ||
                                    courseStatus(item) === "수강 중"
                                    ? "bg-[#FAFAFA] text-secondary-800"
                                    : "bg-gray-600 text-[#fff]",
                                  "text-[15px] rounded-sm absolute -top-1 left-0 px-2 py-[1px]",
                                )}
                              >
                                {courseStatus(item)}
                              </div>
                            </div>
                            <div className="w-2/3 lg:py-3 lg:pr-4">
                              <div className="text-gray-900 text-base font-medium w-2/3 break-all overflow-hidden text-ellipsis line-clamp-3 lg:line-clamp-1">
                                {item.course?.title}
                              </div>
                              <div className="hidden lg:block">
                                <div className=" text-gray-600 text-sm font-medium">
                                  남은기한: {myKlassExpiredAt(item)}
                                  {""} | 진도율: {progressPercent(item.progress?.progressPct)}%
                                </div>
                                <progress
                                  id="file"
                                  max={100}
                                  value={progressPercent(item.progress?.progressPct)}
                                  className="bg-primary-800 h-1 rounded-full w-full"
                                />
                              </div>
                              <div className="hidden lg:block">
                                <button
                                  className={cls(
                                    buttonName(item) === "이어서 보기"
                                      ? "bg-primary-800"
                                      : "bg-secondary-800",
                                    "flex justify-center items-center disabled:bg-gray-600 px-2 space-x-1 max-w-[130px] h-6 rounded-sm",
                                  )}
                                  onClick={() =>
                                    navigate(`/${CLASS_ROUTE}/course/${item.course.courseId}`)
                                  }
                                  disabled={
                                    buttonName(item) === "수강 종료되었습니다" ||
                                    dayjs() < dayjs(item.course?.learnStartAt)
                                  }
                                >
                                  <div className="text-[#fff] text-sm font-medium">
                                    {buttonName(item)}
                                  </div>
                                  {buttonName(item) !== "수강 종료되었습니다" && (
                                    <i className="ii-play text-[#fff] text-sm"></i>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="block lg:hidden">
                            <div className="mt-[10px] mb-2 text-gray-600 text-sm font-medium">
                              남은기한: {myKlassExpiredAt(item)} | 진도율:{" "}
                              {progressPercent(item.progress?.progressPct)}%
                            </div>
                            <progress
                              id="file"
                              max={100}
                              value={progressPercent(item.progress?.progressPct)}
                              className="bg-primary-800 h-1 rounded-full w-full"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mt-[54px] flex flex-col items-center justify-center">
                  <NoItem
                    title="아직 수강하고 있는 강의가 없네요!"
                    content="새로운 강의를 둘러보고 수강해보시는 것은 어떨까요?"
                  />

                  <button
                    className="bg-primary-800 flex justify-center items-center text-base text-white rounded-md py-2 px-10 mt-16 hover:bg-primary-900"
                    onClick={() => navigate(`/${CLASS_ROUTE}`)}
                  >
                    클래스 둘러보기
                  </button>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyKlass;
