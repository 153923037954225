import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { useHomeProductGroupProducts, useHomeProductGroups } from "api/market/hooks";
import ProductUnit from "component/market/product/ProductUnit";

const GroupBuyingRecommend = () => {
  // FIXME: 24/03/31 임시 로직
  const { data: productGroups } = useHomeProductGroups();
  const { data: products, isLoading: productsLoading } = useHomeProductGroupProducts(
    {
      groupId: productGroups?.[0]?.groupId,
    },
    {
      enabled: !!productGroups?.[0]?.groupId,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    },
  );
  if (!productGroups?.[0]?.groupId || productsLoading) return;

  return (
    <div className="groupbuying-recommend recommend-list">
      <div className="title__box">
        <div>
          <b>함께 보면 좋아요👀</b>
        </div>
        <div className="button__box">
          <div className="swiper-button-prev1 cursor">{"←"}</div>
          <div className="swiper-button-next1 cursor">{"→"}</div>
        </div>
      </div>
      <Swiper
        modules={[Autoplay, Navigation]}
        className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
        slidesPerView={2}
        slidesPerGroup={1}
        setWrapperSize={true}
        loop={true}
        autoplay={{
          delay: 5000,
        }}
        navigation={{
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        }}
        speed={400}
      >
        {products?.hits?.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="mt-[10px] mr-[10px]">
              <ProductUnit
                id={product.id}
                name={product.meta_title || product.product.meta_title || product.name}
                regularPrice={product.regular_price}
                salePrice={product.sale_price}
                discountPercent={Math.round(
                  ((product.regular_price - product.sale_price) / product.regular_price) * 100,
                )}
                thumbnail={product.images[0]?.url}
                categoryIds={product?.category_ids || []}
                productCode={product?.product_code}
                brandName={product?.brand?.englishName}
                isSoldOut={product?.is_sold_out}
                isLoadingUnit={false}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GroupBuyingRecommend;
