import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Img, noticeDay, ClassDttm } from "../../utils/util";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { EDU_ROUTE } from "App";

const ClassItem = ({ item, idx, tab }) => {
  const token = useRecoilValue(tokenState);
  const course = item.Course;
  const credit = item.credit;

  if ((dayjs(course.publish_at) < dayjs() && course.aid) || (token && token.user_id === "admin")) {
    return (
      <Link
        to={`/${EDU_ROUTE}/${course.cid}?tab=${tab}`}
        state={{ item: course }}
        className="class_box"
        key={idx}
      >
        <div className="img_box scale-hover__box">
          <img src={Img(course.thumbnail_pc)} alt="" />
        </div>
        <div className="detail">
          <div className="txt">
            <b>{course.title}</b>
          </div>
          <div className="sub-txt">
            {course.sub_title}
            <hr />

            <div className="flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
              <div className="flex items-center">
                <img src={Img("", "img/ico-calendar-event.svg")} alt="달력" />
                {noticeDay(course.release_at)} ~ {noticeDay(course.conceal_at)}
              </div>
              {course.desc === "무료" && <b style={{ color: "#3DCDA2", fontSize: 18 }}>무료</b>}
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div className="class_box" key={idx}>
      <div className="img_box">
        <img src={Img(course.thumbnail_pc)} alt="" />
      </div>
      <span className="before_released">
        {ClassDttm(course.release_at)}부터
        <br />
        수강 가능합니다
      </span>
      <div className="detail blur">
        <mark>{credit}점</mark>
        <div className="txt">{course.sub_title}</div>
        <div className="sub-txt">
          {course.desc}
          <hr />
          <img src={Img("", "img/ico-calendar-event.svg")} alt="달력" />
          {noticeDay(course.release_at)} ~ {noticeDay(course.conceal_at)}
          <br />
        </div>
      </div>
    </div>
  );
};

export default ClassItem;
