import { textblockTypeInputRule } from "prosemirror-inputrules";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { setBlockType } from "prosemirror-commands";
import { Paragraph, getParagraphNodeAttrs } from "./paragraph";

const TAG_NAME_TO_LEVEL = {
  H1: 1,
  H2: 2,
  H3: 3,
  H4: 4,
  H5: 5,
  H6: 6,
}

/**
 * Given a node type and a maximum level, creates an input rule that
 * turns up to that number of `#` characters followed by a space at
 * the start of a textblock into a heading whose level corresponds to
 * the number of `#` signs.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @param {number} maxLevel
 * @returns
 */
const headingRule = (nodeType, maxLevel) => {
  return textblockTypeInputRule(
    new RegExp('^(#{1,' + maxLevel + '})\\s$'),
    nodeType,
    match => ({level: match[1].length}),
  )
}

/**
 *
 * @param {Node} node
 * @returns {Array<any>}
 */
const getAttrs = (dom) => {
  const attrs = getParagraphNodeAttrs(dom)
  const level = TAG_NAME_TO_LEVEL[dom.nodeName.toUpperCase()] || 1
  attrs.level = level
  return attrs
}

/**
 *
 * @param {HTMLElement} dom
 * @returns {Object}
 */
const toDOM = (node) => {
  const {align} = node.attrs
  const level = node.attrs.level || 1
  const attrs = {
    class: MEDISTREAM_SCHEMA_STYLE.nodes.heading,
  }

  let style = ''

  if (align && align !== 'left') {
    style += `text-align: ${align};`
  }

  style && (attrs.style = style)

  return [`h${level}`, attrs, 0]
}

export const Heading = Extension.Create({
  name: 'heading',

  type: 'node',

  defineSpec() {
    return {
      ...Paragraph.defineSpec(),
      attrs: {
        ...Paragraph.defineSpec().attrs,
        level: {default: 1},
      },
      defining: true,
      parseDOM: [
        {tag: 'h1', getAttrs},
        {tag: 'h2', getAttrs},
        {tag: 'h3', getAttrs},
        {tag: 'h4', getAttrs},
        {tag: 'h5', getAttrs},
        {tag: 'h6', getAttrs},
      ],
      toDOM,
    }
  },

  addCommands() {
    return {
      setBlockToHeading: (state, dispatch) => setBlockType(state.schema.nodes[this.name], { level: 2 })(state, dispatch),
    }
  },

  addKeyboardShortcuts() {
    return {
      'Ctrl-Alt-H': this.editor.commands.setBlockToHeading,
      'Ctrl-Alt-h': this.editor.commands.setBlockToHeading,
    }
  },

  addInputRules() {
    return [
      headingRule(this.schema.nodes[this.name], 2),
    ]
  }
})
