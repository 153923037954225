import { useSearchParams } from "react-router-dom";
import { dttm } from "utils/util";
import { highlightedText } from "utils/searchUtil";

const SearchItemRecruit = ({
  title,
  content,
  location1,
  location2,
  hospital_name,
  view,
  reg_dttm,
}) => {
  const [searchParams] = useSearchParams();
  const params = {
    indexUid: searchParams.get("indexUid") || "",
    q: searchParams.get("q") || "",
  };
  const q = params.q;
  return (
    <div className="py-4">
      <div className="overflow-hidden">
        <span className="inline-block mb-3 px-2 py-1 rounded-full text-[12px] bg-gray-100">
          {location1 + " " + location2}
        </span>
        <h4 className="mb-1 text-[16px] font-bold">{highlightedText(title, q)}</h4>
        <p className="text-[14px] text-gray-500 line-clamp-2">{highlightedText(content, q)}</p>
      </div>
      <div className="mt-2 flex justify-between">
        <p className="text-[13px] text-secondary-900">
          {hospital_name}
          {/* FIXME: Nickname 공통 컴포넌트로 대체해야 합니다.
          <Nickname
            uid={item.userUid}
            unick={item.unick}
            level={item.level}
            isOwner={item.is_owner}
          /> */}
          <span className="inline-block mx-1 text-secondary-500">&middot;</span>
          <span className="text-secondary-500">{dttm(reg_dttm)}</span>
        </p>
        <p className="text-[12px] leading-4 text-secondary-500">
          <i className="ii ii-eye text-[16px] text-secondary-500"></i> {view}
        </p>
      </div>
    </div>
  );
};
export default SearchItemRecruit;
