import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import TodayContents from "component/contents/TodayContents";
import HotContents from "component/contents/HotContents";
import ContentsKeyword from "../../component/contents/ContentsKeyword";
import ContentsList from "../../component/contents/ContentsList";
import SearchForm from "component/shared/SearchForm";
import { useState, useEffect } from "react";
import { api, cls } from "../../utils/util";
import Loading from "../../component/Loading";
import NoItem from "component/shared/NoItem";

function ContentsSearchPage() {
  const location = useLocation();
  const [pageInfo, setPageInfo] = useState({ load: false });
  const urlParam = new URLSearchParams(location.search);
  const q = urlParam.get("q") || "";
  const tag = urlParam.get("tag") || "";
  const [searchKeyword, setSearchKeyword] = useState(q);
  const page = parseInt(urlParam.get("page")) || 1;
  const navigate = useNavigate();

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    selected && param.append("page", selected);
    searchKeyword && param.append("q", searchKeyword);
    tag && param.append("tag", tag);
    navigate(`/contents/search?${param}`);
  };

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    param.append("page", 1);
    searchKeyword && param.append("q", searchKeyword);
    navigate(`/contents/search?${param}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setSearchKeyword(q);
    api
      .get("/contents/list", {
        params: {
          page: page,
          per_page: 12,
          q: q,
          tag: tag,
        },
      })
      .then((res) => {
        setPageInfo({ ...res.data, load: true });
      });
  }, [setPageInfo, page, q, tag]);

  if (!pageInfo.load) return <Loading />;

  return (
    <article>
      <section className="lg:max-w-[1200px] mx-auto">
        <div className="text-xl mb-3 lg:mb-5 tracking-tighter font-semibold animate-fadedown whitespace-nowrap lg:p-0 px-4">
          <span
            className={cls(
              tag ? "font-semibold text-tiny" : "bg-[#fffade]",
              "text-primary-800 mr-1",
            )}
          >
            {tag ? "keyword" : q}
          </span>{" "}
          {tag ? tag : "검색 결과"}
        </div>
        {pageInfo.contents && pageInfo.contents.length > 0 ? (
          <ContentsList pageInfo={pageInfo} onPageClick={onPageClick} />
        ) : (
          <div className="mt-20 mb-10">
            <NoItem title={`${tag ? tag : q}에 대한 검색 결과가 없습니다.`} />
          </div>
        )}
        <div className="w-full flex justify-center py-10">
          <SearchForm
            value={searchKeyword}
            setValue={setSearchKeyword}
            search={search}
            width="400px"
          />
        </div>
      </section>
      <ContentsKeyword />
      <section className="lg:max-w-[1200px] mx-auto">
        <div className="flex flex-col lg:flex-row lg:justify-between mb-10">
          <TodayContents recentContents={pageInfo.recent_contents} />
          <HotContents hotContents={pageInfo.hot_contents} />
        </div>
      </section>
    </article>
  );
}

export default ContentsSearchPage;
