import { Route, Routes, Navigate } from "react-router-dom";
import EstatePage from "./estate/EstatePage";
import Loan from "./loan/Loan";
import UsedMarketPage from "./usedMarket/UsedMarketPage";
import EstateWritePage from "./estate/EstateWritePage";
import EstateDetailPage from "./estate/EstateDetailPage";
import UsedMarketDetailPage from "./usedMarket/UsedMarketDetailPage";
import UsedMarketWritePage from "./usedMarket/UsedMarketWritePage";
import Consulting from "./consulting/Consulting";
import LabPage from "./lab/LabPage";
import LoanPage from "./loan/LoanPage";
import Lab from "./lab/Lab";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import RequestSignup from "../user/RequestSignup";

function Management() {
  return (
    <section className="min-h-[100vh] relative">
      <Header current="management" />
      <article className="management pb-64">
        <Routes>
          <Route path="/estate/*" element={<EstatePage />} />
          <Route path="/estate/write/*" element={<EstateWritePage />} />
          <Route path="/estate/modify/:bid/*" element={<EstateWritePage />} />
          <Route path="/estate/:bid/*" element={<EstateDetailPage />} />
          <Route path="/market/*" element={<UsedMarketPage />} />
          <Route path="/market/write/*" element={<UsedMarketWritePage />} />
          <Route path="/market/modify/:bid/*" element={<UsedMarketWritePage />} />
          <Route path="/market/:bid/*" element={<UsedMarketDetailPage />} />
          <Route path="/loan/*" element={<Loan />} />
          <Route path="/consulting/*" element={<Consulting />} />
          <Route path="/lab/*" element={<Lab />} />
          <Route path="/*" element={<Navigate replace to="/management/consulting" />} />
        </Routes>
      </article>
      <Footer />
    </section>
  );
}

export default Management;

export const PublicManagement = () => {
  return (
    <section className="min-h-[100vh] relative">
      <Header current="management" />
      <article className="management pb-64">
        <Routes>
          <Route path="/" element={<Navigate replace to="/management/consulting" />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/lab" element={<LabPage />} />
          <Route path="/loan" element={<LoanPage />} />
          <Route path="*" element={<RequestSignup />} />
        </Routes>
      </article>
      <Footer />
    </section>
  );
};
