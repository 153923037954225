import { Extension } from "../core/extension";
import { INDICATOR_PLUGIN_KEY, findIndicator } from "./indicator";

/**
 * 로딩 인디케이터가 표시된 위치에 업로드된 파일을 삽입합니다.
 * @type {() => import('prosemirror-state').Command}
 */
export const insertFile = (urls, id) => (state, dispatch, view) => {
  if (!view.state.schema.marks.link) {
    throw 'File 익스텐션을 사용하려면 Link 익스텐션이 필요합니다.'
  }

  const tr = state.tr
  const pos = findIndicator(view.state, id)
  // 파일명을 텍스트로 만들고 링크를 걸어줍니다.
  const links = urls.map(url => {
    const tokens = url.split('/')
    const fileName = tokens[tokens.length - 1]
    return view.state.schema.nodes.paragraph.create(
      null,
      view.state.schema.text(
        fileName,
        view.state.schema.marks.link.create({href: url, download: ''}),
      ),
    )
  })

  tr.insert(pos, links).setMeta(INDICATOR_PLUGIN_KEY, {
    remove: {id},
  })

  dispatch(tr)
  return true
}

export const File = Extension.Create({
  name: 'file',

  addCommands() {
    return {
      insertFile,
    }
  }
})
