import { cls } from "../../utils/util";

const LevelIcon = ({ level, background }: { level?: string; background: string }) => {
  if (!level) return null;
  return (
    <div className="inline-flex items-end space-x-[1px]">
      <div className={cls(level ? background : "bg-gray-300", "w-[2px] h-1 rounded-full")} />
      <div
        className={cls(
          level !== "입문" ? background : "bg-gray-300",
          "w-[2px] h-[5px] rounded-full",
        )}
      />
      <div
        className={cls(
          level === "중급" || level === "고급" ? background : "bg-gray-300",
          "w-[2px] h-[7px] rounded-full",
        )}
      />
      <div
        className={cls(
          level === "고급" ? background : "bg-gray-300",
          "w-[2px] h-[9px] rounded-full",
        )}
      />
    </div>
  );
};

export default LevelIcon;
