import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Img, StaticImg } from "../../../utils/util";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoanPopup from "../../../component/management/LoanPopup";

const ConsultingTd = () => {
  const location = useLocation();
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      {popup && <LoanPopup setPopup={setPopup} txt={popup} />}
      <div className="">
        <div className="inner" style={{ marginTop: 64 }}>
          <div className="video-info">
            <div className="video-info__right">
              <span>THINK DESIGN, 최고의 치과마케팅을 위해 노력하는 사람들</span>
            </div>
          </div>
          <div className="video-detail">
            <a
              className="theater-publisher"
              href="http://tdcompany.co.kr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Img("", "img/logo_td.svg")} alt="게시자" />
              <span>티디컴퍼니</span>
            </a>
            <div className="detail__group">
              <div className="menu-box-list theater__menu-box-list">
                <a href="http://tdcompany.co.kr/" target="_blank" rel="noopener noreferrer">
                  <button className="menu-box home__btn">
                    <div className="home"></div>
                    <span className="menu-txt">홈페이지</span>
                  </button>
                </a>
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: "[모어덴 - 티디컴퍼니 컨설팅]",
                        // text: adsProductState[param].title,
                        url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
          <div className="flexCenter">
            <img
              src={StaticImg("consulting/TD-top.png")}
              style={{ width: 200, marginTop: 30 }}
              alt=""
            />
          </div>
          <div className="top-article flexCenter">
            <div className="btn-flex">
              <button
                className="btn-inner-flex"
                onClick={() => setPopup("010-3613-2755 (원정학 대표)")}
              >
                <div className="icon-phone"></div>
                <div className="btn-txt">무선상담 (010)</div>
              </button>
              <button
                className="btn-inner-flex"
                style={{ marginRight: 20 }}
                onClick={() => setPopup("02-3275-1567 (고객상담센터)")}
              >
                <div className="icon-phone"></div>
                <div className="btn-txt">유선상담 (02)</div>
              </button>
            </div>
          </div>
          <div className="bottom-article">
            <img src={StaticImg("consulting/TD-1.jpeg")} alt="" />
            <img src={StaticImg("consulting/TD-2.jpeg")} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingTd;
