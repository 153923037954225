import React from "react";

function RecruitClosePopup({ setClosePopup, closeFunc }) {
  return (
    <article className="popup popup-addr" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="body">
          <article className="pop__addr">
            <div
              className="result__group"
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <div className="no-data" style={{ top: "40px" }}>
                본 공고를 마감 처리하시겠습니까?
              </div>
            </div>
          </article>
          <div className="popup__menu" style={{ marginTop: 0, borderTop: "none" }}>
            <button onClick={(e) => closeFunc()} className="pop-menu-btn type1">
              확인
            </button>
            <button onClick={(e) => setClosePopup(false)} className="pop-menu-btn">
              취소
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

export default RecruitClosePopup;
