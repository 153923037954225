const ProseMirrorMenuInputButton = ({
  Open,
  InputFor,
  Name,
  menubarClassName,
  dispatchMetaData,
  inputText,
  setInputText,
  cls,
}) => {
  const placeholder = () => {
    switch (InputFor) {
      case "insertLink":
        return "https://www.example.com";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatchMetaData({ type: InputFor, value: inputText });
    setInputText("");
  };
  return (
    <div
      className={cls(
        menubarClassName +
          "__input-box input-dialog absolute top-[calc(100%)] right-0",
        Open ? "block" : "hidden"
      )}
      data-command-name={Name}
    >
      <form onSubmit={handleSubmit} className="input-dialog__form">
        <input
          placeholder={placeholder()}
          type="text"
          className="input-dialog__input"
          onChange={(e) => setInputText(e.target.value)}
        />
        <button type="submit" className="input-dialog__button">
          추가
        </button>
      </form>
    </div>
  );
};

export default ProseMirrorMenuInputButton;
