import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import SearchItemContent from "component/search/item/SearchItemContent";

const SearchResultContent = ({ searchResult, hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  // ---------- 검색 결과 총 개수 ----------
  const resultsTotalCount = useMemo(() => {
    return searchResult?.[0]?.estimatedTotalHits || 0;
  }, [searchResult]);

  // ---------- 검색 리스트 ----------
  const contentList = useMemo(() => {
    if (!searchResult) return [];
    return searchResult.reduce((acc, cur) => {
      if (cur?.hits) {
        return [...acc, ...cur.hits];
      }
      return acc;
    }, []);
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);
  return (
    <div>
      <div className="flex flex-col gap-[32px]">
        {resultsTotalCount > 0 ? (
          <div className="grid grid-cols-2 gap-5">
            <GridContentCard contentList={contentList} />
          </div>
        ) : (
          <p>검색 결과가 없습니다.</p>
        )}
      </div>
      {hasNextPage && !isFetchingNextPage && <div className="mt-[20px]" ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultContent;

const GridContentCard = ({ contentList }) => {
  return (
    <>
      {contentList.map((content) => {
        const splitId = content.id.split("_");
        const articleNumber = parseInt(splitId[splitId.length - 1]);
        return (
          <SearchItemContent
            bid={articleNumber}
            param=""
            title={content.title}
            tag={content.tag}
            thumbnail={content.thumbnail}
            published_at={content.published_at_unix}
            key={content.id}
          />
        );
      })}
    </>
  );
};
