const ProseMirrorMenuDropDownButton = ({
  Name,
  Icon,
  ShowArrowDownIcon,
  Description,
  setToggleBtnName,
  children,
  menubarClassName,
}) => {
  return (
    <div className="group">
      <button
        onClick={() =>
          setToggleBtnName((prev) => {
            return { dropDown: !prev.dropDown, name: Name };
          })
        }
        className={
          menubarClassName +
          "__icon " +
          Name +
          (ShowArrowDownIcon ? " dropdown-preview" : "") +
          (Description ? " show-tooltip" : "")
        }
        data-command-name={Name}
        data-tooltip={Description}
        data-icon-type="dropdown"
        dangerouslySetInnerHTML={{ __html: Icon }}
      ></button>
      <div>{children}</div>
      <span className="tooltip-txt">{Description}</span>
    </div>
  );
};

export default ProseMirrorMenuDropDownButton;
