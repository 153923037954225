import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Loading from "../../component/Loading";
import { tokenState } from "../../state";
import { api, watermark, StaticImg } from "../../utils/util";
import RecruitContent from "../../component/recruit/recruitContent";
import { useQuery } from "react-query";

function HygienistPage() {
  const location = useLocation();

  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q") || "";
  const params = { page, q };

  const token = useRecoilValue(tokenState);

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["DHList", params],
    () =>
      api
        .get("/management/hygienist/list", {
          params: params,
        })
        .then((res) => res.data),
    { onError: (err) => console.log(err) },
  );

  if (pageInfoLoading || !pageInfo) return <Loading current="management" />;
  return (
    <div className="">
      <div className="wrapper" style={{ background: watermark(token), backgroundRepeat: "space" }}>
        <section className="magazine__list" style={{ marginBottom: "136.5px" }}>
          {pageInfo && (
            <div className="inner">
              <a
                href="https://airtable.com/app0ySAur2R7n119U/shrsWA3W5f1I38GuB"
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: "100%", marginTop: 10, marginBottom: 55 }}
              >
                <img
                  src={StaticImg("banner/hygienist_banner4.png")}
                  alt=""
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </a>
              <article className="section__tit__box">
                <div className="tit-box">
                  <span className="tit-txt">치과위생사 구인공고</span>
                </div>
              </article>
              <div className="explain">
                업로드 하는 공고는 치과위생사 커뮤니티 치즈톡에서 무료로 홍보됩니다.
              </div>
              {token && token.user_type === "doctor" && (
                <article className="paging" style={{ marginTop: 10 }}>
                  <Link
                    to={`/recruit/hygienist/write`}
                    style={{ marginTop: -55 }}
                    className="write-btn"
                  >
                    <i className="ic ic-write"></i>
                    <span className="txt">글쓰기</span>
                  </Link>
                </article>
              )}

              <RecruitContent pageInfo={pageInfo} current="recruit" pages={pageInfo.pages} />
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default HygienistPage;
