import { listDttm } from "../../utils/util";
import { chicruiting_requests } from "../../constants";

const ChicruitingListTable = ({ recruits, setPopup }) => {
  return (
    <div className="list">
      <div className="list-head">
        <div className="w50p left">담당업무</div>
        <div className="w20p left">급여조건</div>
        <div className="w20p left">주 근무일수</div>
        <div className="w10p">요청일</div>
      </div>
      {recruits.length &&
        recruits.map((item, idx) => {
          return (
            <div className="width-max" key={idx} onClick={() => setPopup(item)}>
              <div className="slim content">
                <div className="w50p left">
                  <span>
                    {item.expectedRoles.map((item) => {
                      return (
                        <span className="chicruiting_item">
                          {
                            chicruiting_requests[0].filter((target) => target.value === item)[0]
                              .label
                          }
                        </span>
                      );
                    })}
                  </span>
                </div>
                <div className="w20p left">
                  <span className="ellipsis">
                    {item.expectedSalery.salaryWithoutTax &&
                      `세후 ${item.expectedSalery.salaryWithoutTax}만원`}
                    {item.expectedSalery.salaryMemo && item.expectedSalery.salaryMemo}
                  </span>
                </div>
                <div className="w20p left">
                  <span className="ellipsis">
                    {item.expectedWorkingDateType.map((item) => {
                      return (
                        <span className="chicruiting_item">
                          {
                            chicruiting_requests[4].filter((target) => target.value === item)[0]
                              .label
                          }
                        </span>
                      );
                    })}
                  </span>
                </div>
                <div className="w10p">
                  <span>{listDttm(item.createdAt)}</span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ChicruitingListTable;
