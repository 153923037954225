import Nickname from "component/user/nickname";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { api, listDttm } from "../../utils/util";

const BoardListTable = ({ articles, param, board, notices, cate_name, article }) => {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const cateStudent = (cate) => (cate >= 7 && cate <= 17) || cate === 31;

  const { data: boardList } = useQuery(
    "boardCateCd",
    () => api.get("/board/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
      onError: (err) => {
        err.response && alert(err.response.data.msg);
      },
    },
  );

  const cateGender = (article) =>
    boardList && boardList.find((cate) => cate.cate_cd === article.cate_cd)?.cate_gender;

  return (
    <div className="list">
      <div className="list-head">
        <div className="w15p left">토픽</div>
        <div className="w55p left">제목</div>
        <div className="w15p left">글쓴이</div>
        <div className="w5p">작성일</div>
        <div className="w5p">조회</div>
        <div className="w5p">추천</div>
      </div>
      {notices &&
        notices.map((item, idx) => (
          <Link
            to={`/post/article/${item.bid}?${param}`}
            key={idx}
            className="width-max inline-block"
          >
            <div className="slim notice">
              <div className="w15p left">
                <span>공지사항</span>
              </div>
              <div className="w55p left">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/ico-flag-01.svg`}
                  alt="공지사항"
                />
                <span>{item.title}</span>
                <i className="ic ic-albums"></i>
              </div>
              <div className="w15p relative left">
                <Nickname uid={item.uid} unick={item.unick} level={item.level} />
              </div>
            </div>
          </Link>
        ))}
      {articles && articles.length > 0 ? (
        articles.map((item) => {
          return (
            <Link
              className="width-max inline-block"
              key={item.bid}
              to={`/post/article/${item.bid}?${urlParam}`}
              state={param ? param : ""}
              // onClick={() => window.scrollTo(0, 0)}
            >
              <div
                className={
                  item.bid === article
                    ? "slim content current"
                    : item.report_count >= 10
                    ? "slim content reported"
                    : "slim content"
                }
              >
                <div
                  className={`w15p left accent type${
                    cateStudent(item.cate_cd) ? "_student" : item?.cate_cd
                  } ${cateGender(item)}`}
                >
                  {board === 0 ? item.cate_name : cate_name}
                </div>
                <div className="w55p left">
                  {item.reported ? (
                    "다수의 신고로 블라인드 처리된 게시글입니다"
                  ) : (
                    <>
                      {item.report_count >= 10 && <i className="ic ic-reported" />}
                      <div className="max-w-[70%] truncate mr-1">{item.title}</div>[{item.comment}]
                      {item.has_img && <i className="ic ic-albums"></i>}
                    </>
                  )}
                </div>
                <div className="w15p relative left">
                  <Nickname
                    uid={item.userUid}
                    unick={item.unick}
                    level={item.level}
                    isOwner={item.is_owner}
                  />
                </div>
                <div className="w5p">
                  <span>{listDttm(item.reg_dttm)}</span>
                </div>
                <div className="w5p">
                  <span>{item.view}</span>
                </div>
                <div className="w5p">
                  <span className="accent">{item.like}</span>
                </div>
              </div>
            </Link>
          );
        })
      ) : (
        <article className="no-data">
          <img
            src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_no_history_data.svg`}
            alt="이미지"
          />
          <div className="txt">작성된 글이 없습니다.</div>
        </article>
      )}
    </div>
  );
};

export default BoardListTable;
