import TextareaAutosize from "react-textarea-autosize";
import { chicruiting_requests } from "../../constants";

export default function RegisterBox({ pageNum, answers, setAnswers }) {
  const answerIdx = pageNum - 1;
  const curQuestions = chicruiting_requests[answerIdx];
  const curAnswer = answers[answerIdx];
  const isDigit = (text) => (!text || text.match(/^\d+$/) ? true : false);

  switch (pageNum) {
    case 0:
      return (
        <div className="box0">
          <div className="info-box">
            <div className="summary">
              모어덴이 제공하는 치크루팅 서비스는
              <br />
              <span className="semi-bold">
                <span className="purple">채용수수료(70만원)</span>
              </span>{" "}
              지급에 동의해야
              <br />
              이용이 가능한 서비스입니다.
            </div>
            <div className="question">
              <span className="semi-bold">비용안내</span>
            </div>
            <div className="answer">
              지원자가 해당 서비스를 통해 채용이 되고, 3개월 근속했을 때 모어덴을 통한 매칭, 면접,
              관리 등 서비스 전반에 대한 운영에 사용되는 헤드헌팅 비용이 발생합니다.
              <br />
              <br />
              지원자가 3개월 근속했을 때 모어덴에 지급하는 비용으로 3개월 후 근속여부 확인을 통해
              청구됩니다.
              <br />
              <br />
              <b>단, 지원자가 3개월 미만 근무시에는 비용이 청구되지 않습니다.</b>
              <br />
              <br />
              *3개월 후, 헤드헌팅 서비스 비용을 지급하지 않을 경우, 서비스 이용이 제한되거나
              불이익을 받을 수 있습니다.
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div className="box1 survey">
          <div className="title">담당업무가 무엇인가요?</div>
          <div className="request-box many-items">
            <CheckboxList
              curAnswer={curAnswer}
              curQuestions={curQuestions}
              answerIdx={answerIdx}
              setAnswers={setAnswers}
            />
          </div>
        </div>
      );
    case 2:
      return (
        <div className="box1 survey">
          <div className="title">고용형태가 어떻게 되나요?</div>
          <div className="request-box">
            <CheckboxList
              curAnswer={curAnswer}
              curQuestions={curQuestions}
              answerIdx={answerIdx}
              setAnswers={setAnswers}
            />
          </div>
        </div>
      );
    case 3:
      return (
        <div className="box1 survey">
          <div className="title">희망하시는 경력을 입력해주세요.</div>
          <div className="request-box">
            {curQuestions.map((q, idx) => (
              <label className="multiple-answer" key={idx}>
                <input
                  type="radio"
                  name={q.value}
                  checked={curAnswer?.type === q.value}
                  onChange={() =>
                    setAnswers((pre) => {
                      pre[answerIdx] = { type: q.value };
                      return [...pre];
                    })
                  }
                />
                <div>{q.label}</div>
                {q.value === "senior" && (
                  <div className="flex-items-center">
                    <input
                      className="text-input short mr-10"
                      placeholder="최소"
                      value={curAnswer?.forYears?.min ? curAnswer.forYears.min : ""}
                      onChange={(e) => {
                        if (!isDigit(e.target.value)) return;
                        return setAnswers((pre) => {
                          pre[answerIdx] = {
                            type: curQuestions[1].value,
                            forYears: { ...curAnswer?.forYears, min: e.target.value },
                          };
                          return [...pre];
                        });
                      }}
                    />
                    년 &nbsp;&nbsp;-
                    <input
                      className="text-input short mr-10"
                      placeholder="최대"
                      value={curAnswer?.forYears?.max ? curAnswer.forYears.max : ""}
                      onChange={(e) => {
                        if (!isDigit(e.target.value)) return;
                        return setAnswers((pre) => {
                          pre[answerIdx] = {
                            ...curAnswer,
                            forYears: { ...curAnswer?.forYears, max: e.target.value },
                          };
                          return [...pre];
                        });
                      }}
                    />
                    년
                  </div>
                )}
              </label>
            ))}
          </div>
        </div>
      );
    case 4:
      return (
        <div className="box1 survey">
          <div className="title">급여 조건을 입력해주세요.</div>
          <div className="request-box">
            <div className="request-box">
              <label className="multiple-answer">
                <input
                  type="radio"
                  name={curQuestions[0].value}
                  checked={
                    curAnswer ? Object.keys(curAnswer).includes(curQuestions[0].value) : false
                  }
                  onChange={() =>
                    setAnswers((pre) => {
                      pre[answerIdx] = { [curQuestions[0].value]: "" };
                      return [...pre];
                    })
                  }
                />
                <div className="flex-items-center">
                  {curQuestions[0].label}
                  <input
                    className="text-input short"
                    placeholder="0"
                    value={
                      curAnswer && curAnswer[curQuestions[0].value]
                        ? curAnswer[curQuestions[0].value]
                        : ""
                    }
                    onChange={(e) => {
                      if (!isDigit(e.target.value)) return;
                      return setAnswers((pre) => {
                        pre[answerIdx] = { [curQuestions[0].value]: e.target.value };
                        return [...pre];
                      });
                    }}
                  />
                  만원
                </div>
              </label>
              <label className="multiple-answer border-none">
                <input
                  type="radio"
                  name={curQuestions.value}
                  checked={
                    curAnswer ? Object.keys(curAnswer).includes(curQuestions[1].value) : false
                  }
                  onChange={() =>
                    setAnswers((pre) => {
                      pre[answerIdx] = { [curQuestions[1].value]: "" };
                      return [...pre];
                    })
                  }
                />
                <div>{curQuestions[1].label}</div>
                <div className="notice">정확하게 입력할수록 매칭 확률이 올라갑니다.</div>
              </label>
              <div className="essay-answer">
                <TextareaAutosize
                  value={
                    curAnswer && curAnswer[curQuestions[1].value]
                      ? curAnswer[curQuestions[1].value]
                      : ""
                  }
                  onChange={(e) =>
                    setAnswers((pre) => {
                      pre[answerIdx] = { [curQuestions[1].value]: e.target.value };
                      return [...pre];
                    })
                  }
                  placeholder={`희망 연차별 금액을 입력해주세요.

ex) 1년차 220~
       2년차 230~
       3년차 240~`}
                  minRows={6}
                  maxRows={12}
                ></TextareaAutosize>
              </div>
            </div>
          </div>
        </div>
      );
    case 5:
      return (
        <div className="box1 survey">
          <div className="title">주 근무횟수를 입력해주세요.</div>
          <div className="request-box many-items">
            <CheckboxList
              curAnswer={curAnswer}
              curQuestions={curQuestions}
              answerIdx={answerIdx}
              setAnswers={setAnswers}
            />
          </div>
        </div>
      );
    case 6:
      return (
        <div className="box1 survey">
          <div className="title">근무시간을 입력해주세요.</div>
          <div className="request-box">
            <div className="essay-answer">
              <TextareaAutosize
                value={curAnswer ? curAnswer : ""}
                onChange={(e) =>
                  setAnswers((pre) => {
                    pre[answerIdx] = e.target.value;
                    return [...pre];
                  })
                }
                placeholder={`ex)

평일 09:00 ~ 18:00
목요일 09:00 ~ 20:00(야간)
토요일 09:00 ~ 13:00`}
                minRows={13}
                maxRows={20}
              ></TextareaAutosize>
            </div>
          </div>
        </div>
      );
    case 7:
      return (
        <div className="box1 survey">
          <div className="title">복리후생을 선택해주세요.</div>
          <div className="request-box">
            <CheckboxList
              curAnswer={curAnswer}
              curQuestions={curQuestions}
              answerIdx={answerIdx}
              setAnswers={setAnswers}
            />
          </div>
        </div>
      );
    case 8:
      return (
        <div className="box1 survey">
          <div className="title">병원의 총 구성원이 몇 명인가요?</div>
          <div className="request-box">
            <div className="flex-items-center">
              <input
                value={curAnswer ? curAnswer : ""}
                onChange={(e) => {
                  if (!isDigit(e.target.value)) return;
                  return setAnswers((pre) => {
                    pre[answerIdx] = e.target.value;
                    return [...pre];
                  });
                }}
                className="text-input medium"
                placeholder="0"
              />
              명
            </div>
          </div>
        </div>
      );
    case 9:
      return (
        <div className="box1 survey">
          <div className="title">간단히 병원을 소개해주세요!</div>
          <div className="request-box">
            <div className="essay-answer">
              <TextareaAutosize
                value={curAnswer ? curAnswer : ""}
                onChange={(e) =>
                  setAnswers((pre) => {
                    pre[answerIdx] = e.target.value;
                    return [...pre];
                  })
                }
                placeholder="지원자들에게 우리 병원을 어필해주세요!"
                minRows={13}
                maxRows={20}
              ></TextareaAutosize>
            </div>
          </div>
        </div>
      );
    case 10:
      return (
        <div className="box1 survey">
          <div className="title">연락받으실 담당자 정보를 입력해주세요.</div>
          <div className="request-box">
            {curQuestions.map((q, idx) => (
              <div key={idx}>
                <div className="subject">
                  {q.label}
                  {q.detail && <span className="ml-3 text-xs text-primary-800">* {q.detail}</span>}
                </div>
                <input
                  value={curAnswer ? curAnswer[curQuestions[idx].value] : ""}
                  onChange={(e) =>
                    setAnswers((pre) => {
                      pre[answerIdx] = {
                        ...curAnswer,
                        [curQuestions[idx].value]: e.target.value,
                      };
                      return [...pre];
                    })
                  }
                  className="text-input"
                  placeholder={contactManagerPlaceHolder[idx]}
                />
              </div>
            ))}
          </div>
        </div>
      );
    default:
      return <></>;
  }
}

function CheckboxList({ curAnswer, curQuestions, answerIdx, setAnswers }) {
  return (
    <>
      {curQuestions.map((q, idx) => (
        <label key={idx} className="multiple-answer">
          <input
            type="checkbox"
            name={q.value}
            checked={curAnswer ? curAnswer.includes(q.value) : false}
            onChange={() =>
              setAnswers((pre) => {
                pre[answerIdx] = curAnswer?.includes(q.value)
                  ? curAnswer.filter((a) => a !== q.value)
                  : [...(curAnswer ? curAnswer : []), q.value];
                return [...pre];
              })
            }
          />
          <div>{q.label}</div>
        </label>
      ))}
    </>
  );
}

const contactManagerPlaceHolder = [
  "성함을 입력해주세요.",
  "예시) 대표원장",
  "연락처를 입력해주세요. (-포함)",
  "이메일주소를 입력해주세요.",
];
