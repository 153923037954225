import { Link, useSearchParams } from "react-router-dom";
import { TOPICS } from "../../constants";
import { CLASS_ROUTE } from "App";
import { cls } from "../../utils/util";
import { useState } from "react";
import { TopicsMenuPopup } from "./TopicsMenu";

const TopicsNavbar = () => {
  const [topicsMenuDisplay, setTopicsMenuDisplay] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const topics = searchParams.get("topics") || "전체";
  return (
    <div className="h-[50px] border-b border-solid border-b-gray-300 relative">
      <div className="overflow-auto scroll_none h-[65px]">
        <ul className="mx-[6px] lg:mx-[-10px] text-[18px] whitespace-nowrap">
          <li className="inline-block">
            <Link
              className={cls(
                "block relative h-[50px] leading-[50px] px-[10px] lg:font-semibold text-[18px]",
                "전체" === topics ? "text-primary-800" : "text-gray-800",
              )}
              to={`/${CLASS_ROUTE}/search`}
            >
              전체
              <span
                className={cls(
                  "absolute right-[10px] bottom-0 left-[10px] h-[4px]",
                  "전체" === topics ? "bg-primary-800" : "bg-transparent",
                )}
              ></span>
            </Link>
          </li>
          {TOPICS.map((topic) => {
            return (
              <li className="inline-block" key={topic.name}>
                {/* '/' 나 '&' 과 같이 URL 구분자의 충돌을 일으킬 가능성이 큰 문자는 인코딩 합니다. */}
                <Link
                  className={cls(
                    "block relative h-[50px] leading-[50px] px-[10px] lg:font-semibold text-[18px]",
                    topic.label.replace(/\s+/g, "") === topics
                      ? "text-primary-800"
                      : "text-gray-800",
                  )}
                  to={`/${CLASS_ROUTE}/search?topics=${topic.label
                    .replace(/\s+/g, "")
                    .replace("/", encodeURIComponent("/"))
                    .replace("&", encodeURIComponent("&"))}`}
                >
                  {topic.label}
                  <span
                    className={cls(
                      "absolute right-[10px] bottom-0 left-[10px] h-[4px]",
                      topic.label.replace(/\s+/g, "") === topics
                        ? "bg-primary-800"
                        : "bg-transparent",
                    )}
                  ></span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className="pointer-events-none hidden lg:flex lg:justify-end w-[83px] h-[full] cursor-pointer absolute right-0 top-[50%] translate-y-[-50%] bg-white"
        style={{
          background: "linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 131.43%)",
        }}
      >
        <div
          onClick={() => setTopicsMenuDisplay(!topicsMenuDisplay)}
          className="pointer-events-auto hidden lg:flex lg:justify-center lg:items-center shadow-card bg-white w-[35px] h-[35px] rounded-full"
        >
          <i
            className={cls(
              topicsMenuDisplay ? "ii-chevron-up" : "ii-chevron-down",
              "text-gray-900 text-[20px]",
            )}
          ></i>
        </div>
      </div>
      {topicsMenuDisplay && <TopicsMenuPopup setTopicsMenuDisplay={setTopicsMenuDisplay} />}
    </div>
  );
};

export default TopicsNavbar;
