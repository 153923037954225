import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { publicApi, Img, cls } from "../../utils/util";
import StyledSelect from "component/shared/StyledSelect";
import LevelIcon from "./LevelIcon";
import { AllLecturers } from "types/klass";
import { TOOTH_NUMBERS } from "../../constants";

const freeSelects = [
  { param: undefined, value: "전체" },
  { param: "true", value: "무료" },
  { param: "false", value: "유료" },
];
const levelSelects = [
  { param: undefined, value: "전체" },
  { param: "입문", value: "입문" },
  { param: "초급", value: "초급" },
  { param: "중급", value: "중급" },
  { param: "고급", value: "고급" },
];
const toothNumbersZoneSelects = [
  { name: "upper R", value: "upper-right" },
  { name: "upper L", value: "upper-left" },
  { name: "lower R", value: "lower-right" },
  { name: "lower L", value: "lower-left" },
];
const limit = 200;

const CoursesFilterForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  type SearchParams = {
    q?: string;
    topics?: string;
    free?: string;
    level?: string;
    lecturers?: string;
    toothNumbers?: string;
    sort?: string;
  };

  const defaultStates = {
    free:
      freeSelects.filter((freeSelect) => {
        return freeSelect.param === searchParams.get("free");
      })[0]?.value || freeSelects[0].value,
    level:
      levelSelects.filter((levelSelect) => {
        return levelSelect.param === searchParams.get("level");
      })[0]?.value || levelSelects[0].value,
    lecturers: searchParams.get("lecturers")?.split(",") || [],
    toothNumbers:
      searchParams
        .get("toothNumbers")
        ?.split(",")
        .map((toothNumber) => {
          return parseInt(toothNumber, 10);
        }) || [],
    sort: searchParams.get("sort") || "threeMonthlySales:desc",
  };

  const [coursesCount, setCoursesCount] = useState<number>(0);
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [modalDisplay, setModalDisplay] = useState<boolean>(false);
  const [inputFree, setInputFree] = useState<string>(defaultStates.free);
  const [inputLevel, setInputLevel] = useState<string>(defaultStates.level);
  const [inputLecturers, setInputLecturers] = useState<string[]>(defaultStates.lecturers);
  const [inputToothNumbers, setInputToothNumbers] = useState<number[]>(defaultStates.toothNumbers);

  const [inputToothNumbersZone, setInputToothNumbersZone] = useState<string[]>([]);

  const [selectSort, setSelectSort] = useState<string>(defaultStates.sort);

  const { data: allLecturers, isLoading: allLecturersLoading } = useQuery<
    AllLecturers | undefined,
    Error
  >("KlassLecturers", () =>
    publicApi?.get("/class/public/lecturers").then((res) => {
      return res.data;
    }),
  );

  const serializedInputParams = useCallback(() => {
    const params: SearchParams = {};
    const inputFreeParam = freeSelects.filter((freeSelect) => {
      return freeSelect.value === inputFree;
    })[0]?.param;
    const inputLevelParam = levelSelects.filter((levelSelect) => {
      return levelSelect.value === inputLevel;
    })[0]?.param;
    if (inputFreeParam) params.free = inputFreeParam;
    if (inputLevelParam) params.level = inputLevelParam;
    if (inputLecturers.length > 0) params.lecturers = inputLecturers.join(",");
    if (inputToothNumbers.length > 0) params.toothNumbers = inputToothNumbers.join(",");
    if (selectSort) params.sort = selectSort;
    return params;
  }, [inputFree, inputLevel, inputLecturers, inputToothNumbers, selectSort]);

  const handleChangeInputFree = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setInputFree(value);
  };

  const handleChangeInputLevel = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setInputLevel(value);
  };

  const handleChangeInputLecturers = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    const value = event.currentTarget.value;
    setInputLecturers((lecturers) => {
      if (isChecked) {
        if (!lecturers.includes(value)) {
          return [...lecturers, value];
        }
      } else {
        const index = lecturers.indexOf(value);
        if (index !== -1) {
          return [...lecturers.slice(0, index), ...lecturers.slice(index + 1)];
        }
      }
      return lecturers;
    });
  };

  const handleChangeInputToothNumbers = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    const value = event.currentTarget.value;
    const numValue = parseInt(value, 10);
    setInputToothNumbers((toothNumbers) => {
      if (isChecked) {
        if (!toothNumbers.includes(numValue)) {
          return [...toothNumbers, numValue];
        }
      } else {
        const index = toothNumbers.indexOf(numValue);
        if (index !== -1) {
          return [...toothNumbers.slice(0, index), ...toothNumbers.slice(index + 1)];
        }
      }
      return toothNumbers;
    });
  };

  const handleChangeInputToothNumbersZone = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    const value = event.currentTarget.value;
    setInputToothNumbersZone((toothNumbersZone) => {
      if (isChecked) {
        if (!toothNumbersZone.includes(value)) {
          return [...toothNumbersZone, value];
        }
      } else {
        const index = toothNumbersZone.indexOf(value);
        if (index !== -1) {
          return [...toothNumbersZone.slice(0, index), ...toothNumbersZone.slice(index + 1)];
        }
      }
      return toothNumbersZone;
    });
    // 선택 또는 해제한 그룹 값에 따라 즉시 치식번호 개별 선택에도 반영합니다.
    TOOTH_NUMBERS.filter((toothNumber) => {
      return toothNumber.zone === value;
    }).forEach((toothNumber) => {
      setInputToothNumbers((toothNumbers) => {
        if (isChecked) {
          if (!toothNumbers.includes(toothNumber.id)) {
            return [...toothNumbers, toothNumber.id];
          }
        } else {
          const index = toothNumbers.indexOf(toothNumber.id);
          if (index !== -1) {
            return [...toothNumbers.slice(0, index), ...toothNumbers.slice(index + 1)];
          }
        }
        return toothNumbers;
      });
    });
  };

  const handleChangeSelectSort = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    setSelectSort(value);
  };

  const handleReset = () => {
    setInputFree(freeSelects[0].value);
    setInputLevel(levelSelects[0].value);
    setInputLecturers([]);
    setInputToothNumbers([]);
  };

  const handleSubmit = () => {
    const params: SearchParams = serializedInputParams();
    if (searchParams.get("q")) params.q = searchParams.get("q") || undefined;
    if (searchParams.get("topics")) params.topics = searchParams.get("topics") || undefined;
    setSearchParams(params);
    setModalDisplay(false);
  };

  useEffect(() => {
    const params: SearchParams = serializedInputParams();
    if (searchParams.get("q")) params.q = searchParams.get("q") || undefined;
    if (searchParams.get("topics")) params.topics = searchParams.get("topics") || undefined;
    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectSort]);

  useEffect(() => {
    const params: SearchParams = serializedInputParams();
    if (searchParams.get("q")) params.q = searchParams.get("q") || undefined;
    if (searchParams.get("topics")) params.topics = searchParams.get("topics") || undefined;
    publicApi
      ?.get("/class/public/courses/search", {
        params: {
          q: params.q,
          topics: params.topics,
          free: params.free,
          level: params.level,
          lecturers: params.lecturers,
          toothNumbers: params.toothNumbers,
          limit,
        },
      })
      .then((res) => {
        const count = res.data?.results?.estimatedTotalHits || 0;
        setCoursesCount(count);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFree, inputLevel, inputLecturers, inputToothNumbers]);

  useEffect(() => {
    let effectiveFiltersCount = 0;
    if (inputFree !== freeSelects[0].value) {
      effectiveFiltersCount += 1;
    }
    if (inputLevel !== levelSelects[0].value) {
      effectiveFiltersCount += 1;
    }
    if (inputLecturers.length > 0) {
      effectiveFiltersCount += 1;
    }
    if (inputToothNumbers.length > 0) {
      effectiveFiltersCount += 1;
    }
    setFiltersCount(effectiveFiltersCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFree, inputLevel, inputLecturers, inputToothNumbers]);

  useEffect(() => {
    type zoneCounting = {
      [index: string]: number;
      "upper-right": number;
      "upper-left": number;
      "lower-right": number;
      "lower-left": number;
    };
    const zoneChildrenSelectCounts: zoneCounting = {
      "upper-right": 0,
      "upper-left": 0,
      "lower-right": 0,
      "lower-left": 0,
    };
    TOOTH_NUMBERS.forEach((toothNumber) => {
      if (inputToothNumbers.includes(toothNumber.id)) {
        zoneChildrenSelectCounts[toothNumber.zone] += 1;
      }
    });
    Object.keys(zoneChildrenSelectCounts).forEach((zone) => {
      setInputToothNumbersZone((toothNumbersZone) => {
        if (zoneChildrenSelectCounts[zone] === 8) {
          if (!toothNumbersZone.includes(zone)) {
            return [...toothNumbersZone, zone];
          }
        } else {
          const index = toothNumbersZone.indexOf(zone);
          if (index !== -1) {
            return [...toothNumbersZone.slice(0, index), ...toothNumbersZone.slice(index + 1)];
          }
        }
        return toothNumbersZone;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputToothNumbers]);

  return (
    <>
      <button
        className={cls(
          "inline-block relative min-w-[80px] h-[35px] lg:h-[40px] leading-[35px] lg:leading-[40px] pr-[30px] pl-[8px] rounded text-left",
          filtersCount > 0
            ? "bg-primary-800 hover:bg-primary-900 text-white"
            : "border border-solid border-gray-800 bg-transparent hover:bg-gray-50 text-gray-800",
        )}
        type="button"
        onClick={() => setModalDisplay(true)}
      >
        {filtersCount || ""} 필터
        <i
          className={cls(
            "ii ii-sliders ii-weight-600",
            "text-[17px] absolute top-0 right-[8px]",
            filtersCount > 0 ? "text-white" : "text-gray-800",
          )}
        ></i>
      </button>
      <div className="block mt-6 lg:inline-block lg:mt-0 lg:ml-2.5 align-top">
        <StyledSelect
          onChange={(event) => {
            handleChangeSelectSort(event);
          }}
          value={selectSort}
          className="w-[85px] h-[35px] lg:h-[40px]"
          option={[
            {
              value: "",
              label: "정렬",
            },
            {
              value: "createdAt:desc",
              label: "최신순",
            },
            {
              value: "isFree:asc,product.salePrice:asc",
              label: "가격순",
            },
            {
              value: "title:asc",
              label: "이름순",
            },
            {
              value: "threeMonthlySales:desc",
              label: "인기순",
            },
          ]}
        />
      </div>
      {modalDisplay && (
        <div className="z-[520] relative inline-block text-left">
          <div className="z-1 fixed top-0 right-0 bottom-0 left-0 bg-black/50"></div>
          <div className="z-2 flex flex-col overflow-hidden fixed top-0 right-0 bottom-0 left-0 lg:top-[50%] lg:right-auto lg:bottom-auto lg:left-[50%] lg:transform lg:translate-x-[-50%] lg:translate-y-[-50%] w-[100%] lg:max-w-[800px] lg:max-h-[90vh] lg:rounded-lg bg-white shadow-custom">
            <button
              className="absolute top-[20px] right-[20px] w-[40px] h-[40px]"
              type="button"
              title="닫기"
              onClick={() => setModalDisplay(false)}
            >
              <i className="ii ii-remove text-[24px]"></i>
            </button>
            <form className="overflow-auto flex-1 p-[16px] lg:p-[40px]">
              <strong>가격</strong>
              <div className="my-5">
                {freeSelects.map((freeSelect) => {
                  return (
                    <label className="mr-2.5 cursor align-bottom">
                      <input
                        className="hidden peer"
                        type="radio"
                        name="free"
                        value={freeSelect.value}
                        defaultChecked={freeSelect.value === defaultStates.free}
                        onChange={(event) => {
                          handleChangeInputFree(event);
                        }}
                      />
                      <span
                        className={cls(
                          "inline-block h-[40px] leading-[40px] px-[15px] rounded border border-solid border-gray-300",
                          "hover:bg-gray-50 peer-checked:hover:bg-primary-50 peer-checked:border-primary-700 peer-checked:text-primary-800",
                        )}
                      >
                        {freeSelect.value}
                      </span>
                    </label>
                  );
                })}
              </div>
              <strong>레벨</strong>
              <div className="my-5">
                {levelSelects.map((levelSelect) => {
                  return (
                    <label className="mr-2.5 mb-2.5 cursor align-bottom">
                      <input
                        className="hidden peer"
                        type="radio"
                        name="level"
                        value={levelSelect.value}
                        checked={levelSelect.value === inputLevel}
                        onChange={(event) => {
                          handleChangeInputLevel(event);
                        }}
                      />
                      <span
                        className={cls(
                          "inline-block h-[40px] leading-[40px] px-[15px] rounded border border-solid border-gray-300",
                          "hover:bg-gray-50 peer-checked:hover:bg-primary-50 peer-checked:border-primary-700 peer-checked:text-primary-800",
                        )}
                      >
                        {levelSelect.value !== "전체" && (
                          <span className="mr-1.5">
                            <LevelIcon
                              level={levelSelect.value}
                              background={
                                levelSelect.value === inputLevel
                                  ? "bg-primary-700"
                                  : "bg-secondary-800"
                              }
                            />
                          </span>
                        )}
                        {levelSelect.value}
                      </span>
                    </label>
                  );
                })}
              </div>
              {!allLecturersLoading && allLecturers && allLecturers.lecturers.length > 0 && (
                <>
                  <strong>연자</strong>
                  {/* <div className="my-5">
                    <label className="mr-2.5 cursor align-bottom">
                      <input className="hidden peer" type="radio" name="lecturer" value="2" />
                      <span
                        className={cls(
                          "inline-block h-[40px] leading-[40px] px-[15px] rounded border border-solid border-gray-300",
                          "hover:bg-gray-50 peer-checked:hover:bg-primary-50 peer-checked:border-primary-700 peer-checked:text-primary-800",
                        )}
                      >
                        ㄱ - ㄹ
                      </span>
                    </label>
                    <label className="mr-2.5 cursor align-bottom">
                      <input className="hidden peer" type="radio" name="lecturer" value="3" />
                      <span
                        className={cls(
                          "inline-block h-[40px] leading-[40px] px-[15px] rounded border border-solid border-gray-300",
                          "hover:bg-gray-50 peer-checked:hover:bg-primary-50 peer-checked:border-primary-700 peer-checked:text-primary-800",
                        )}
                      >
                        ㅁ - ㅅ
                      </span>
                    </label>
                    <label className="mr-2.5 cursor align-bottom">
                      <input className="hidden peer" type="radio" name="lecturer" value="3" />
                      <span
                        className={cls(
                          "inline-block h-[40px] leading-[40px] px-[15px] rounded border border-solid border-gray-300",
                          "hover:bg-gray-50 peer-checked:hover:bg-primary-50 peer-checked:border-primary-700 peer-checked:text-primary-800",
                        )}
                        onClick={() => setInputLecturers(["윤형두", "이승규"])}
                      >
                        ㅇ - ㅎ
                      </span>
                    </label>
                  </div> */}
                  <div className="my-5">
                    {allLecturers?.lecturers.map((lecturer) => {
                      return (
                        <label className="mr-2.5 mb-2.5 cursor align-bottom">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="lecturers"
                            value={lecturer.name}
                            checked={inputLecturers.includes(lecturer.name)}
                            onChange={(event) => {
                              handleChangeInputLecturers(event);
                            }}
                          />
                          <span
                            className={cls(
                              "inline-block h-[40px] leading-[40px] px-[15px] rounded border border-solid border-gray-300",
                              "hover:bg-gray-50 peer-checked:hover:bg-primary-50 peer-checked:border-primary-700 peer-checked:text-primary-800",
                            )}
                          >
                            {lecturer.name}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                </>
              )}
              <strong>치식번호</strong>
              <div className="my-5">
                {toothNumbersZoneSelects.map((toothNumbersZone) => {
                  return (
                    <label className="mr-2.5 mb-2.5 cursor align-bottom">
                      <input
                        className="hidden peer"
                        type="checkbox"
                        name="toothNumbersZones"
                        value={toothNumbersZone.value}
                        checked={inputToothNumbersZone.includes(toothNumbersZone.value)}
                        onChange={handleChangeInputToothNumbersZone}
                      />
                      <span
                        className={cls(
                          "inline-block h-[40px] leading-[40px] px-[15px] rounded border border-solid border-gray-300",
                          "hover:bg-gray-50 peer-checked:hover:bg-primary-50 peer-checked:border-primary-700 peer-checked:text-primary-800",
                        )}
                      >
                        {toothNumbersZone.name}
                      </span>
                    </label>
                  );
                })}
              </div>
              <div className="grid grid-cols-2 gap-2 lg:gap-5">
                {/* upper R */}
                <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2">
                  <div className="md:order-1 grid grid-cols-4 items-end">
                    {[18, 17, 16, 15].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                  <div className="md:order-2 grid grid-cols-4 items-end">
                    {[14, 13, 12, 11].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                </div>
                {/* upper L */}
                <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2">
                  <div className="md:order-2 grid grid-cols-4 items-end">
                    {[25, 26, 27, 28].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                  <div className="md:order-1 grid grid-cols-4 items-end">
                    {[21, 22, 23, 24].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                </div>
                {/* lower R */}
                <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2">
                  <div className="md:order-2 grid grid-cols-4 items-end md:items-start">
                    {[44, 43, 42, 41].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col md:flex-col-reverse cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-0 md:mb-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                  <div className="md:order-1 grid grid-cols-4 items-end md:items-start">
                    {[48, 47, 46, 45].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col md:flex-col-reverse cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-0 md:mb-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                </div>
                {/* lower L */}
                <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2">
                  <div className="md:order-1 grid grid-cols-4 items-end md:items-start">
                    {[31, 32, 33, 34].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col md:flex-col-reverse cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-0 md:mb-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                  <div className="md:order-2 grid grid-cols-4 items-end md:items-start">
                    {[35, 36, 37, 38].map((number) => {
                      const toothNumber = TOOTH_NUMBERS.find(
                        (toothNumber) => toothNumber.id === number,
                      );
                      if (!toothNumber) return "";
                      return (
                        <label className="group flex flex-col md:flex-col-reverse cursor">
                          <input
                            className="hidden peer"
                            type="checkbox"
                            name="toothNumbers"
                            value={toothNumber.id}
                            checked={inputToothNumbers.includes(toothNumber.id)}
                            onChange={(event) => {
                              handleChangeInputToothNumbers(event);
                            }}
                          />
                          <img
                            className="block mx-auto transform scale-[60%] md:scale-[100%]"
                            src={Img("", toothNumber.img)}
                            alt=""
                          />
                          <code
                            className={cls(
                              "block w-[30px] h-[30px] leading-[30px] rounded border border-solid border-gray-300 bg-white mx-auto text-center",
                              "mt-1 md:mt-0 md:mb-4",
                              "group-hover:bg-gray-50 peer-checked:border-primary-700 peer-checked:bg-primary-50 peer-checked:text-primary-800",
                            )}
                          >
                            {toothNumber.id}
                          </code>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </form>
            <div className="flex flex-0 px-[16px] lg:px-[40px] py-[12px] bg-gray-50">
              <div className="hidden md:block flex-0 pt-[13px] truncate text-[16px]">
                <p className="inline-block text-gray-400 whitespace-nowrap">
                  가격
                  <span className="ml-1 text-gray-600">{inputFree}</span>
                </p>
                <p className="inline-block text-gray-400 whitespace-nowrap ml-3">
                  레벨
                  <span className="ml-1 text-gray-600">{inputLevel}</span>
                </p>
                {inputLecturers.length > 0 && (
                  <p className="inline-block text-gray-400 whitespace-nowrap ml-3">
                    연자
                    <span className="ml-1 text-gray-600">{inputLecturers.join(", ")}</span>
                  </p>
                )}
                {inputToothNumbers.length > 0 && (
                  <p className="inline-block text-gray-400 whitespace-nowrap ml-3">
                    치식번호
                    <span className="ml-1 text-gray-600">{inputToothNumbers.join(", ")}</span>
                  </p>
                )}
              </div>
              <div className="flex-1 whitespace-nowrap text-right">
                <button
                  className="w-[90px] h-[50px] mr-4 rounded-[5px] bg-gray-100 hover:bg-gray-200 text-gray-600 text-[18px] font-semibold align-top"
                  type="button"
                  onClick={handleReset}
                >
                  <i className="ii ii-refresh ii-weight-600 mr-1.5 text-gray-300"></i>
                  초기화
                </button>
                <button
                  className="w-[210px] h-[50px] rounded-[5px] bg-primary-800 hover:bg-primary-900 text-white text-[18px] font-semibold align-top"
                  type="button"
                  onClick={handleSubmit}
                >
                  {coursesCount || coursesCount === 0
                    ? `검색결과 총 ${coursesCount}건`
                    : "검색 조건 선택"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CoursesFilterForm;
