/**
 * 유튜브 링크를 iframe 에 삽입할 수 있는 링크로 변환합니다.
 * 현재 Youtube Shorts 링크를 지원하지 않습니다.
 *
 * @param {String} url
 * @returns {String}
 */
export const generateYoutubeEmbedSrc = url => {
  const embedUrl = 'https://www.youtube.com/embed/<%= remote_id %>'
  const REGEX =
    /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/

  const parseVideoId = ([id, params]) => {
    if (!params && id) {
      return id
    }

    const paramsMap = {
      start: 'start',
      end: 'end',
      t: 'start',
      time_continue: 'start',
      list: 'list',
    }

    params = params
      .slice(1)
      .split('&')
      .map(param => {
        const [name, value] = param.split('=')

        if (!id && name === 'v') {
          id = value

          return null
        }

        if (!paramsMap[name]) {
          return null
        }

        if (
          value === 'LL' ||
          value.startsWith('RDMM') ||
          value.startsWith('FL')
        ) {
          return null
        }

        return `${paramsMap[name]}=${value}`
      })
      .filter(param => !!param)

    return id + '?' + params.join('&')
  }

  const result = REGEX.exec(url).slice(1)
  const videoId = parseVideoId(result)
  const embedSrc = embedUrl.replace(/<%= remote_id %>/g, videoId)

  return { url: embedSrc, videoId }
}

/**
 * URL 에 따라 iframe 의 플랫폼을 정의합니다.
 *
 * @param {String} url
 * @returns {String}
 */
export const setIframePlatform = url => {
  const isGoogleForm = url.startsWith('https://docs.google.com/forms/') || url.startsWith('https://forms.gle/')

  if (isGoogleForm) {
    return 'google-form'
  }

  const isYoutube = url.startsWith('https://youtu.be') || url.startsWith('https://www.youtube.com/watch?')

  if (isYoutube) {
    return 'youtube'
  }
  
  const isKollus = url.startsWith('https://v.kr.kollus.com/')

  if (isKollus) {
    return 'kollus'
  }

  return 'etc'
}

/**
 * @param {String} url 
 * @returns {String}
 */
export const prependHTTPS = url => {
  if (
    url.length &&
    (
      !url.startsWith('http') &&
      !url.startsWith('https')
    )
  ) {
    url = 'https://' + url
  }
  return url
}
