import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from "froala-editor";
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/languages/ko.js";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/css/plugins/file.min.css";
import "froala-editor/css/plugins/video.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/draggable.min.css";
import axios from "axios";
import { api } from "../utils/util";
import { useEffect, useState } from "react";
import Loading from "./Loading";

const insertFiles = async (e, froala) => {
  try {
    const uploadedFileList = await Promise.all(
      Array.from(e.target.files).map(async (file) => {
        const res = await api.post("/util/presigned", { filename: file.name });

        const signedUrl = res.data;
        const url = new URL(signedUrl);
        url.search = "";
        await axios.put(signedUrl, file);
        return url.toString();
      }),
    );
    uploadedFileList.forEach((link) =>
      froala.html.insert(`<p><img src="${link}" class="fr-fic fr-dib"></p>`, true),
    );
  } catch (err) {
    console.log(err);
    alert("이미지 업로드에 실패했습니다.");
  }
};

Froalaeditor.RegisterCommand("insertImages", {
  title: "insertImages",
  icon: "insertImage",
  focus: true,
  showOnMobile: true,
  undo: true,
  callback: function () {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.value = "";
    input.addEventListener("change", (e) => insertFiles(e, this));
    input.click();
  },
});

const insertYoutubeVideo = (editor, url) => {
  if (!url.toLowerCase().includes("youtu")) return;
  axios.get(`https://youtube.com/oembed?format=json&url=${url}`).then((res) => {
    editor.video.insert(res.data.html);
    editor.html.insert("<br/>", true);
  });
};

const EditorAdmin = ({ content, setContent }) => {
  const [presignedUrl, setPresignedUrl] = useState(null);

  useEffect(() => {
    api?.post("/util/presigned/froala").then((res) => {
      setPresignedUrl(res.data);
    });
  }, []);

  if (!presignedUrl) return <Loading />;
  return (
    <div id="froala_editor">
      <FroalaEditor
        tag="textarea"
        config={{
          events: {
            "paste.beforeCleanup": function (clipboard_html) {
              const dom = new DOMParser().parseFromString(clipboard_html, "text/html").body;
              const aTag = dom.querySelector("a");
              if (!aTag) return;
              insertYoutubeVideo(this, aTag.href);
            },
            "url.linked": function (link) {
              insertYoutubeVideo(this, link.href);
            },
            "image.beforeUpload": (images) => {
              if (images[0].size > 30 * 1024 * 1024) {
                alert("용량은 30MB 이하로 첨부해주세요.");
              }
            },
            "video.beforeUpload": ($video) => {
              if ($video[0].size > 30 * 1024 * 1024) {
                alert("용량은 30MB 이하로 첨부해주세요.");
              }
            },
            "file.beforeUpload": ($files) => {
              if ($files[0].size > 30 * 1024 * 1024) {
                alert("용량은 30MB 이하로 첨부해주세요.");
              }
            },
          },
          emoticonsUseImage: false,
          heightMin: 600,
          key: "zEG4iE3B9D6A5F6C4G5aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3F3A1B8D6D4D4D3==",
          attribution: false,
          language: "ko",
          colorsText: [
            "#1abc9c",
            "#2ecc71",
            "#3498db",
            "#9b59b6",
            "#34495e",
            "#16a085",
            "#27ae60",
            "#2980b9",
            "#8e44ad",
            "#2c3e50",
            "#f1c40f",
            "#e67e22",
            "#e74c3c",
            "#ecf0f1",
            "#95a5a6",
            "#f39c12",
            "#d35400",
            "#c0392b",
            "#bdc3c7",
            "#7f8c8d",
            "#ffffff",
            "REMOVE",
          ],
          colorsBackground: [
            "#55efc4",
            "#81ecec",
            "#74b9ff",
            "#a29bfe",
            "#dfe6e9",
            "#00b894",
            "#00cec9",
            "#0984e3",
            "#6c5ce7",
            "#b2bec3",
            "#ffeaa7",
            "#fab1a0",
            "#ff7675",
            "#fd79a8",
            "#636e72",
            "#fdcb6e",
            "#e17055",
            "#d63031",
            "#e84393",
            "#2d3436",
            "#ffffff",
            "REMOVE",
          ],
          tableColors: [
            "#55efc4",
            "#81ecec",
            "#74b9ff",
            "#a29bfe",
            "#dfe6e9",
            "#00b894",
            "#00cec9",
            "#0984e3",
            "#6c5ce7",
            "#b2bec3",
            "#ffeaa7",
            "#fab1a0",
            "#ff7675",
            "#fd79a8",
            "#636e72",
            "#fdcb6e",
            "#e17055",
            "#d63031",
            "#e84393",
            "#2d3436",
            "#ffffff",
            "REMOVE",
          ],
          linkList: [
            {
              text: "moreDEN",
              href: "https://www.moreden.co.kr",
              target: "_blank",
              rel: "nofollow",
            },
          ],
          imageTextNear: false,
          imageDefaultAlign: "left",
          imageMaxSize: 1024 * 1024 * 30,
          videoMaxSize: 1024 * 1024 * 30,
          fileMaxSize: 1024 * 1024 * 30,
          imageDefaultWidth: 0,
          imageUploadToS3: presignedUrl,
          fileUploadToS3: presignedUrl,
          videoUploadToS3: presignedUrl,
          imageUploadMethod: "POST",
          imageUploadUrl: false,
          tableEditButtons: [
            "tableHeader",
            "tableRemove",
            "|",
            "tableRows",
            "tableColumns",
            "tableCells",
            "-",
            "tableCellBackground",
            "tableCellVerticalAlign",
            "tableCellHorizontalAlign",
            "tableCellStyle",
          ],
          imageEditButtons: [
            "imageAlign",
            "imageCaption",
            "imageLink",
            "linkOpen",
            "linkEdit",
            "linkRemove",
            "imageRemove",
          ],
          linkAlwaysBlank: true,
          videoEditButtons: ["videoRemove", "videoAlign"],
          videoAllowedTypes: ["mp4"],
          imageInsertButtons: ["imageBack", "|", "imageUpload", "imageByURL"],
          paragraphFormat: {
            N: "Normal",
            H1: "Heading 1",
            H2: "Heading 2",
            H3: "Heading 3",
            H4: "Heading 4",
          },
          toolbarSticky: true,
          imageResizeWithPercent: true,
          toolbarStickyOffset: 119,
          fontSizeSelection: true,
          fontSize: ["10", "12", "15", "18", "30", "60", "96"],
          fontSizeDefaultSelection: "15",
          lineHeights: {
            Default: "1.6",
            "0.5배": "0.5",
            "1배": "1.0",
            "2배": "2",
          },
          inlineStyles: {
            기본값: "border-bottom: inherit;",
            하이라이트: "border-bottom: solid #4a25aa 2px;",
          },
          toolbarButtons: [
            [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "fontSize",
              "lineHeight",
              "textColor",
              "backgroundColor",
              "insertLink",
            ],
            ["align", "formatOL", "formatUL", "paragraphFormat", "insertHR", "quote"],
            [
              "insertTable",
              "insertImages",
              "insertVideo",
              "insertFile",
              "emoticons",
              "inlineStyle",
            ],
          ],
        }}
        model={content}
        onModelChange={setContent}
      />
    </div>
  );
};

export default EditorAdmin;
