import { useState } from "react";

const TongtestAttachment = () => {
  const [part1, setPart1] = useState(1);
  const [part2, setPart2] = useState(1);

  return (
    <>
      <article style={{ marginTop: 100 }} className="section__tit__box">
        <div className="tit-box">
          <span className="tit-txt">통치자료</span>
        </div>
      </article>

      <div className="thanku_box">
        <div className="thanku">
          <b>
            닉네임 <span className="on">'늘감사합니다'</span> 님께서 소중한
            자료를 공유해주신 자료입니다.
            <br />
            ‘늘감사합니다’ 님은 닉네임{" "}
            <span className="on">‘테리파라타이드’</span>님으로도 유명하신{" "}
            <span className="on">갓테리</span>님입니다.
          </b>
          <br />
          <br />
          아래 정리된{" "}
          <b>
            모든 자료 정리 및 코멘트는, ‘테리파라타이드'님 께서 수많은 시간을
            투자하셔서 정리해주셨으며,
          </b>{" "}
          저희 모어덴에서는 단순히 플랫폼만 제공하기에,
          <br />
          모든 칭찬과 공은 ‘테리파라타이드‘님에게 있습니다.
          <br />
          <br />
          다른 통치 수험생들을 위해 봉사와 함께 자료 공유해주시고 있는 점에 대해
          대단히 감사드립니다. <br />
          많은 분들께서 ‘늘감사합니다(테리파라타이드)’ 닉네임처럼 항상 감사하고
          있습니다. 감사합니다.
        </div>
      </div>

      <div className="thanku_box3">
        <div className="thanku">
          <b>
            <span className="zipon">📞 수련고시위원회 전화번호 📞</span>
          </b>
          <br />
          <br />
          통치 전문의 시험 관련 문의는 아래 번호로 전화하시면 됩니다.
          <br />
          <span className="number">
            <a href="tel:02-2024-9155">02 - 2024 - 9155</a>
          </span>
        </div>
      </div>

      <div className="thanku_box2">
        <div className="thanku">
          <b>
            <span className="zipon">ZIP파일 압축푸는 방법</span>
          </b>
          <br />
          <br />
          1. 아래 링크를 클릭하시고 <b>'설치하기' 버튼을 클릭 후 </b>알집을
          다운로드 받습니다.
          <br />
          2. 윈도우 탐색기에서 압축파일을 찾아{" "}
          <b>마우스 오른쪽 버튼 클릭 {">"} ‘알집으로 압축풀기’ </b>
          선택합니다.
          <br />
          3. 압축풀기 창이 열리면 풀어진 파일이 저장될 폴더를 지정한 후 ‘확인’을
          클릭하면 압축해제가 완료됩니다.
          <br /> <br />
          <a
            className="on"
            href="https://www.altools.co.kr/download/alzip.aspx"
          >
            https://www.altools.co.kr/download/alzip.aspx
          </a>
        </div>
      </div>

      <article
        style={{ marginTop: "100px", paddingLeft: "560px" }}
        className="section__tit__box"
      >
        <div className="tit-box">
          <span className="tit-txt">1차 대비</span>
        </div>
      </article>

      <a
        className="down_box"
        href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84.zip"
        download
      >
        <div className="down">
          <span className="img">
            <img
              src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder.svg`}
              alt="다운로드"
            />
          </span>
          <span className="text">1차 대비 전체 다운로드 하기</span>
        </div>
      </a>

      <div className="first_but">
        <div
          className={part1 === 1 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(1)}
        >
          <div className="text">1. 기출문제</div>
        </div>

        <div
          className={part1 === 2 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(2)}
        >
          <div className="text">2. 모의고사</div>
        </div>

        <div
          className={part1 === 3 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(3)}
        >
          <div className="text">3. 간단정리 &amp; 암기법</div>
        </div>

        <div
          className={part1 === 4 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(4)}
        >
          <div className="text">4. 정리본</div>
        </div>
      </div>

      <div className="first_but">
        <div
          className={part1 === 5 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(5)}
        >
          <div className="text">5. 온라인 강의</div>
        </div>

        <div
          className={part1 === 6 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(6)}
        >
          <div className="text">6. 방사선 연조직 그림 등</div>
        </div>

        <div
          className={part1 === 7 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(7)}
        >
          <div className="text">7. 2차 대비 음성 파일</div>
        </div>

        <div
          className={part1 === 8 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart1(8)}
        >
          <div className="text">8. 설명회</div>
        </div>
      </div>

      <div className="down_box2">
        {part1 === 1 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/1.+%EA%B8%B0%EC%B6%9C%EB%AC%B8%EC%A0%9C.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">1. 기출문제 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">
                <b>1. 기출문제</b>
              </div>
              <div className="sub_tt">
                2019, 2020년 1차 기출문제
                <br />
                2020년 1차 시험 이의신청-1
                <br />
                2020년 2차 복원 <br />
                <br />위 기출문제들은 꼭 보세요 ^^
              </div>
            </div>
          </>
        )}

        {part1 === 2 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/2.+%EB%AA%A8%EC%9D%98%EA%B3%A0%EC%82%AC.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">2. 모의고사 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">2. 모의고사</div>
              <div className="sub_tt">
                <b>１) 가드닝 모의고사</b>
                <br />
                <br /> 이거 풀어보셨나요? 처음 풀었는데 90점 넘으셨다고요?
                <br /> 이건 자신감을 드리려고 만들었던 것 같습니다. 편하게
                푸시고 틀린 것은 확인해보시면 될 겁니다. ^^
                <br />
                <br />
                <b>２) 김형균 100제 모의고사</b>
                <br />
                <br /> 대부분 풀어보셨죠? 몇 점 나오세요? <br /> 처음에 60점 못
                넘으셨다고 좌절 마세요. 대부분 그렇습니다. 2번째 풀어도 80점대
                나오기 힘들어요. 3번째 풀어야 90점 넘는 게 보통이더라고요.{" "}
                <br /> 처음에 80점 이상이라면 그분은 수석을 목표로 공부하시는
                겁니다. ^^ 오답노트 만드셔서 꼼꼼히 보시길 바라겠습니다.
                <br />
                <br />
                <b>3) 2019 통치 네이버카페 객관예상문제 모음</b>
                <br />
                <br /> 문제가 상당히 많죠.(350문제 전후로 기억합니다. 부정확하니
                이해바랍니다 TT)
                <br /> 2019년도 처음 시험 치를 때 네이버카페에 통치준비방을
                개설했습니다. 카페 가입조건이 예상문제 한문제씩 제출하는
                거였는데 그 과정에서 모은 문제들을 모은 겁니다. <br />
                처음이라서 검증과정이 부족해서 애매한 답이 좀 많죠. 중복된
                문제도 많이 있습니다. 이런 점을 고려하셔서 보시면 될 것 같아요
                <br />
                <br />
                <b>4) 2021년 녹두방 모의고사</b>
                <br />
                <br /> 2020년 통치 1차 모의고사 유형으로 관우 님을 비롯한
                녹두방원 분들이 심혈을 기울여서 만든 모의고사입니다. 실제
                모의고사 환경(마스크, 글러브 착용, OMR 카드 작성, 120분 정확히
                지키기)에서 꼭 풀어보시면 좋을 것 같습니다. 모의고사
                만들어주시느라 고생하신 녹두방원 분들께 진심으로 감사드립니다.{" "}
              </div>
            </div>
          </>
        )}

        {part1 === 3 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/3.+%EA%B0%84%EB%8B%A8%EC%A0%95%EB%A6%AC+%26+%EC%95%94%EA%B8%B0%EB%B2%95.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">3. 간단정리 &amp; 암기법 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">3. 간단정리 &amp; 암기법</div>
              <div className="sub_tt">
                {" "}
                <b>1) 통치그림짤 by 피노키오</b>
                <br />
                <br /> 닉네임 피노키오 님이 만들어주신 각종 암기짤이 다수
                있습니다
                <br /> 재밌는 그림들로 구성되어 있으니 수시로 보시면 자연암기 될
                겁니다.
                <br />
                <br />
                <b>2) 2020 통치 암기법 by RANKL</b>
                <br />
                <br /> 작년 녹두방에서 랭클 님이 단톡방에서 회자된 내용 중
                중요한 것들 위주로 암기법을 만들어 공개하셨습니다. 그걸 모은
                겁니다. 암기하는데 도움 될 듯 싶네요.
                <br />
                <br />
                <b>3) 통치 관련 간단 정리 (닉네임 테리파라타이드)</b>
                <br />
                <br /> 작년에 공부하면서 각 강의 별로 중요한 부분을 간단정리
                형식으로 정리한 것입니다. 공부하다 이해가 안가면 이거 먼저 눈에
                발라두고 보시면 좀 편할 듯 싶네요. 파일 중에 0-1. 통치 카톡방
                정리본 정리.pdf 가 있습니다. 이게 간단정리 중 중요한 것들을
                모아서 첨삭한 거라 보시면 될 겁니다. 녹두방 원장님들이 첨삭을
                해주셨습니다.
              </div>
            </div>
          </>
        )}

        {part1 === 4 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/4.+%EC%A0%95%EB%A6%AC%EB%B3%B8.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">4. 정리본 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">4. 정리본</div>
              <div className="sub_tt">
                {" "}
                <b>1) 2019 건축학개론 1차 대비 note</b>
                <br />
                <br /> 작년에 많이들 봤던 자료입니다. 이름은 피크로드 1차 또는
                건축학개론 1차 두가지 이름으로 돌아다녔는데 같은 내용입니다.
                깔끔하게 중요 내용들 위주로 정리되었습니다. 1차범위 포함해서요.
                개념 정리 차원에서 보기 딱 좋습니다.
                <br />
                <br />
                <b>2) 2020 브레이브 2차대비 수정본 201123</b>
                <br />
                <br />
                작년 2차 시험 바로 전날에 공개된 2차대비용 자료입니다.
                정리수준이 후덜덜 합니다. 1차 시험대비(2차범위) 하기에 좋을 듯
                싶어 넣었습니다.
                <br />
                <br />
                <b>3) 학회정리본</b>
                <br />
                <br /> 작년 1차시험 보기 한 달 전쯤에 알게된 자료입니다.
                스페셜케어, 오랄리헤빌리테이션, 엔도페리오, 서저리, 임플란트
                보철 수술 5개분야 메인 분야 정리한 것입니다. 정리가 깔끔해서
                이걸로 공부하신 분들도 은근 많았던걸로 기억합니다^^
              </div>
            </div>
          </>
        )}

        {part1 === 5 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/5.+%EC%98%A8%EB%9D%BC%EC%9D%B8%EA%B0%95%EC%9D%98.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">5. 온라인 강의 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">5. 온라인 강의</div>
              <div className="sub_tt">
                <b>통치 온라인 문제 – 주제별 정리 파일입니다.</b>
                <br />
                <br /> 1차 시험 보기 전에 꼭 온라인강의 문제 처음부터 끝까지
                다시 풀어보세요 ^^
                <br />
                아마 처음에 풀었을 때 틀렸던 거 다시 틀리는 경우가 다반사입니다.
                틀린 것은 오답노트 정리하시고 문제가 이상 있으면 꼭 확인 하시길
                바랍니다.
              </div>
            </div>
          </>
        )}

        {part1 === 6 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/6.+%EA%B5%90%EA%B3%BC%EC%84%9C.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">6. 방사선 연조직 그림 등 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">6. 방사선 연조직 그림 등</div>
              <div className="sub_tt">
                방사선 연조직 응급 중요 그림 모음입니다.
                <br />
                중복되는 사진들이 많습니다. 양에 비해서 시간은 그리 많이 걸리지
                않으니 찬찬히 보세요 ^^
              </div>
            </div>
          </>
        )}

        {part1 === 7 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/7.+2%EC%B0%A8+%EB%8C%80%EB%B9%84+%EC%9D%8C%EC%84%B1%ED%8C%8C%EC%9D%BC.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">7. 2차 대비 음성 파일 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">7. 2차 대비 음성 파일</div>
              <div className="sub_tt">
                교과서응급약물정리, <br /> 구강재건 정리,
                <br /> 엔도 정리, <br /> 외과파트 소제목, <br /> 임플란트 수술
                및 보철 내과랑 그 외 정리, <br /> 접착파트 정리,
                <br /> 정복영 교수님 입니다. <br /> 작년 2차 시험 준비기간에
                공개되었었는데 1차시험 대비할 때부터 틈틈이 들어두시면 도움될 것
                같아 첨부하였습니다.
              </div>
            </div>
          </>
        )}
        {part1 === 8 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/1%EC%B0%A8+%EB%8C%80%EB%B9%84/8.+%EC%84%A4%EB%AA%85%ED%9A%8C.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">8. 설명회 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>

            <div className="content">
              <div className="sub">8. 설명회</div>
              <div className="sub_tt">
                2020, 2021년 통합치의학 설명회에서 나온 캡처본입니다. FAQ 라고
                생각하시면 되겠습니다.
                <br />
                이상입니다. 다들 파이팅하세요. 감사합니다.
                <br />
                닉네임 테리파라타이드 배상
              </div>
            </div>
          </>
        )}
      </div>

      <article
        style={{ marginTop: "100px", paddingLeft: "560px" }}
        className="section__tit__box"
      >
        <div className="tit-box">
          <span className="tit-txt">2차 대비</span>
        </div>
      </article>

      <div className="down_box">
        <a
          className="down"
          href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84.zip"
          download
        >
          <span className="img">
            <img
              src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder.svg`}
              alt="다운로드"
            />
          </span>
          <span className="text">2차 대비 전체 다운로드 하기</span>
        </a>
      </div>

      <div className="first_but">
        <div
          className={part2 === 1 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(1)}
        >
          <div className="text">1. 기출문제</div>
        </div>

        <div
          className={part2 === 2 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(2)}
        >
          <div className="text">2. 기초자료</div>
        </div>

        <div
          className={part2 === 3 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(3)}
        >
          <div className="text">3. 2차 예상문제</div>
        </div>

        <div
          className={part2 === 4 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(4)}
        >
          <div className="text">4. 2차 정리본</div>
        </div>
      </div>

      <div className="first_but">
        <div
          className={part2 === 5 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(5)}
        >
          <div className="text">5. 교과서 관련문제</div>
        </div>

        <div
          className={part2 === 6 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(6)}
        >
          <div className="text">6. 2020 파트별 정리</div>
        </div>

        <div
          className={part2 === 7 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(7)}
        >
          <div className="text">7. 방사선 연조직 그림 등</div>
        </div>

        <div
          className={part2 === 8 ? "first_but_box_on" : "first_but_box"}
          onClick={() => setPart2(8)}
        >
          <div className="text">8. 2020년 문제 모음</div>
        </div>
      </div>

      <div className="down_box2">
        {part2 === 1 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/1.+%EA%B8%B0%EC%B6%9C%EB%AC%B8%EC%A0%9C.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">1. 기출문제 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>

            <div className="content">
              <div className="sub">1. 기출문제</div>
              <div className="sub_tt">
                2018, 2019, 2020년 2차 기출문제 및 타과(구강외과,보존과)
                기출문제 첨부하였습니다.
                <br /> 복원의 정도는 좀 부족합니다. (특히 2019년도)
                <br /> 이러한 점이 있사오니 이해 부탁드립니다.
                <br />
                <br /> 타과자료 첨부한 이유는 타과에서 같은 문제가 나온 적이
                있다고 해서 같이 첨부하였습니다.
              </div>
            </div>
          </>
        )}
        {part2 === 2 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/2.+%EA%B8%B0%EC%B4%88%EC%9E%90%EB%A3%8C.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">2. 기초자료 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>

            <div className="content">
              <div className="sub">2. 기초자료</div>
              <div className="sub_tt">
                단어연습 및 2차대비 음성파일입니다.
                <br />
                <br />
                <b>1) 단어연습</b>
                <br /> <br /> 1차 시험이 끝나면 무조건 단어연습 파일 풀어보세요.
                <br /> 답보지 말고 직접 쓰셔야 본인의 실력을 파악할 수 있습니다.
                생각보다 점수 안 나와요. <br />
                <br />
                <b>2) 2차대비 음성파일</b>
                <br />
                <br /> 전에 공개해드린 자료입니다. 출퇴근할 때나 집중 안될 때
                수시로 들어주세요. 1차, 2차 시험 모두에 도움이 많이 될 겁니다^^
              </div>
            </div>
          </>
        )}
        {part2 === 3 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/3.+2%EC%B0%A8+%EC%98%88%EC%83%81%EB%AC%B8%EC%A0%9C.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">3. 2차 예상문제 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">3. 2차 예상문제</div>
              <div className="sub_tt">
                2019년도 예상문제 2개 2020년 예상문제 정리 2개입니다.
                <br />
                <br /> -&gt; 실제로는 문제는 같고요. 다른 분들이 답과 설명을
                정리하였습니다. <br /> 중요한 것을 모아서 정리한 것이고 완성도가
                높아서 꼭 보시길 바랍니다.
              </div>
            </div>
          </>
        )}
        {part2 === 4 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/4.+2%EC%B0%A8+%EC%A0%95%EB%A6%AC%EB%B3%B8.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">4. 2차 정리본 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">4. 2차 정리본</div>
              <div className="sub_tt">
                <b>1) 2019년</b>
                <br />
                <br /> 가드닝 주관식 -&gt; 과목별로 묶어서 문제 위주로 되어
                있습니다. 찬찬히 풀어보세요.
                <br />
                피크로드 2차 -&gt; 작년에 많이들 봤던 2차 대비용 정리본입니다.
                정리 깔끔하고 개념 정리용으로 괜찮을 듯 싶습니다.
                <br />
                <br />
                <b>2) 2020년</b>
                <br />
                <br /> 3가지 정리본을 올렸습니다. <br /> a. 브레이브 2차대비 :
                작년 2차시험 하루 전에 완성되어서 발표된 것입니다. 완성도가 가장
                높아서 가장 추천합니다. 1차 준비할 때 보셔도 좋아요.
                <br /> b. 피대서 : 사실 저는 이걸 작년에 몰랐습니다. 이제 와서
                봤는데 정리가 잘되어 있네요. <br /> c. Good Morning AGD : 작년
                녹두방에서 유명했던 자료입니다. 녹두방 초기에 Good Morning AGD
                님이 매일 문제를 올려주셨었는데 그걸 모아서 묶은것으로
                기억합니다.
                <br /> 정리본은 이 정도 추천하는데 이중 최소 한 개정도는
                봐두세요. 시간되면 더 보셔도 좋구요 ^^
                <br />
                <br />
                <b>3) 녹두방 3차 정리집</b>
                <br />
                <br /> 작년에 2차 시험이 어려울걸 대비해서 1차시험 한달 전까지
                녹두방에서 많은 분들의 노력의 결실로 만든 것입니다. 상당히
                세세한 부분까지 정리되어 있고 정리의 퀄리티도 높습니다. 2차 시험
                난이도가 높을것이라 생각이 되면 참고하세요.
              </div>
            </div>
          </>
        )}
        {part2 === 5 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/5.+%EA%B5%90%EA%B3%BC%EC%84%9C+%EC%A0%95%EB%A6%AC.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">5. 교과서 관련문제 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">5. 교과서 관련문제</div>
              <div className="sub_tt">
                교과서는 작년에 많이들 못 봤어요. 1차 시험 2달 전에 나와서요.{" "}
                <br /> 자료도 별로 없습니다. 3개의 파일 올렸으니 그거는 꼭
                보세요 ^^
              </div>
            </div>
          </>
        )}
        {part2 === 6 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/6.+2020%EB%85%84+%ED%8C%8C%ED%8A%B8%EB%B3%84+%EC%A0%95%EB%A6%AC.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">6. 2020년 파트별 정리 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">6. 2020년 파트별 정리</div>
              <div className="sub_tt">
                이 파트는 무조건 다 한번씩 읽어보세요. <br />
                작년에 녹두방에 계신 선생님들이 자진해서 파트별로 정리해서
                올려주신 겁니다. 주로 개념정리 및 이해를 위해 정리한 것들이에요.{" "}
                <br /> 방난심, 정복영 교수님 및 그 외 파트 중요 정리 또는 문제가
                있습니다. 물론 잘 알고 계시면 볼 필요 없고요 ^^
              </div>
            </div>
          </>
        )}
        {part2 === 7 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/7.+%EA%B5%90%EA%B3%BC%EC%84%9C+%EA%B7%B8%EB%A6%BC(%EB%B0%A9%EC%82%AC%EC%84%A0%2C+%EC%97%B0%EC%A1%B0%EC%A7%81).zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">7. 방사선 연조직 그림 등 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">7. 방사선 연조직 그림 등</div>
              <div className="sub_tt">
                방사선 연조직 등 파일은 이미 1차에서 많이 보셨을테니 다시 복습차
                보세요.
                <br /> 방사선 연조직 응급은 작년에 많이 회자가 되어서 정리해서
                올렸습니다.
              </div>
            </div>
          </>
        )}
        {part2 === 8 && (
          <>
            <a
              className="down2"
              href="https://moreden-img.s3.ap-northeast-2.amazonaws.com/file/%ED%86%B5%EC%B9%98%EC%9E%90%EB%A3%8C/2%EC%B0%A8+%EB%8C%80%EB%B9%84/8.+2020%EB%85%84+%EB%AC%B8%EC%A0%9C%EB%AA%A8%EC%9D%8C.zip"
              download
            >
              <span className="img">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/folder2.svg`}
                  alt="다운로드"
                />
              </span>
              <span className="text">8. 2020년 문제 모음 다운받기</span>
              <span className="img_right">
                <img
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/download.svg`}
                  alt="다운로드"
                />
              </span>
            </a>
            <div className="content">
              <div className="sub">8. 2020년 문제 모음</div>
              <div className="sub_tt">
                1) 증류수 님 문제 : 극최상 문제들입니다. 10점 맞았다고
                현타오실거 없어요. 그만큼 어려워요. 긴장감 불어넣기 좋을 것 같아
                넣어봤네요. <br /> <br /> 2) 안개 님 문제 : 공부 열심히 하신
                분들은 거의다 맞추는 것 같고요. 저처럼 부족하게 공부했던 분들은
                좀 많이 틀리고 그랬네요. 괜찮은 것 같으니 한번 보세요. <br />{" "}
                <br /> 3) 테그레톨 님 문제 모음집 : 작년 2차 시험 준비는
                테그레톨 님이 주도했죠 ^^ 고퀄리티 문제를 매일 수십문제씩
                공개했습니다. 과목별로 정리가 되어 있는데 공부 많이 하신
                분들이라면 풀어볼 만할 것 같아요. 간혹 좀 갸우뚱할만한 오류가
                있으니 이점은 양해 바랍니다. 근데 양이 어마어마하니 이점은 미리
                고려하세요 (700메가바이트가 넘습니다 TT)
                <br /> <br /> 이상입니다. 다들 파이팅하세요. <br /> 감사합니다.
                닉네임 테리파라타이드 배상
              </div>
            </div>
          </>
        )}
      </div>

      <article
        style={{ marginTop: "100px", paddingLeft: "560px" }}
        className="section__tit__box"
      >
        <div className="tit-box">
          <span className="tit-txt">'늘감사합니다'님의 글</span>
        </div>
      </article>

      <div className="first_but">
        <a className="first_but_box" href="/post/article/2255">
          <div className="text">1편</div>
        </a>

        <a className="first_but_box" href="/post/article/2785">
          <div className="text">2편</div>
        </a>

        <a className="first_but_box" href="/post/article/3027">
          <div className="text">3편</div>
        </a>

        <a className="first_but_box" href="/post/article/3061">
          <div className="text">4편</div>
        </a>
      </div>
    </>
  );
};

export default TongtestAttachment;
