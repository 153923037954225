const CommentMark = ({ type }) => {
  if (type === "best")
    return (
      <mark className="text-white font-semibold text-[13px] rounded-sm bg-gradient-to-r from-[#F47B7B] to-[#DB4747] py-[1px] px-[4px] mr-2 relative -top-1">
        <i className="ii ii-like text-white relative top-[-1px] mr-1" />
        BEST
      </mark>
    );
  if (type === "admin")
    return (
      <mark className="text-white font-semibold text-[13px] rounded-sm bg-gradient-to-r from-[#9A94F7] to-[#9A94F7] py-[1px] px-[4px] mr-2 relative -top-1">
        <i className="ii ii-medal-outline text-white relative top-[-1px] mr-1" />
        위원회PICK
      </mark>
    );
  if (type === "writer")
    return (
      <mark className="text-white font-semibold text-[13px] rounded-sm bg-gradient-to-r from-[#70DEE3] to-[#60C2C6] py-[1px] px-[4px] mr-2 relative -top-1">
        <i className="ii ii-trophy-outline text-white relative top-[-1px] mr-1" />
        질문자PICK
      </mark>
    );
};

export default CommentMark;
