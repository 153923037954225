const SyncPopup = ({ syncType }) => {
  return (
    <div className="sync-popup fade-out">
      <div className="txt">{syncType} 계정과 연결되었습니다.</div>
      <div className="txt">다음부터는 간편하게 {syncType} 로그인이 가능합니다.</div>
    </div>
  );
};

export default SyncPopup;
