import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api, writeDayYtoS } from "../../utils/util";
import SideMenu from "./SideMenu";
import Loading from "../Loading";
import BlockPopup from "./BlockPopup";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

const Block = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const [popup, setPopup] = useState({ open: false, uuid: null });
  const tabList = [
    { name: "닉네임 공개", value: "nickname" },
    { name: "익명", value: "anonymous" },
  ];
  const urlParam = new URLSearchParams(location.search);
  const tab = urlParam.get("tab") || tabList[0].value;

  const onTabClick = (value) => {
    const param = new URLSearchParams();
    param.append("page", 1);
    param.append("tab", value);
    navigate(`${window.location.pathname}?${param}`);
  };

  const { data: blockList, isLoading: blockListLoading } = useQuery(["mypageBlockList"], () =>
    api.get("/blocks/legacy/blockList").then((res) => res.data.block_user_list),
  );

  const { data: anonymousBlocks, isLoading: anonymousBlocksLoading } = useQuery(
    "mypageAnonymousBlocks",
    () => api.get("/blocks/anonymous-blocks").then((res) => res.data),
  );

  const releaseBlock = useMutation((data) => api.post(`/blocks/legacy/blockRelease`, data), {
    onSuccess: () => {
      alert("차단 해제되었습니다.");
      return queryClient.invalidateQueries("mypageBlockList");
    },
    onError: (err) => {
      err.response && alert(err.response.data.msg);
    },
  });

  const releaseAnonymousBlock = useMutation(
    (data) => api.delete(`/blocks/anonymous-blocks/${data}`),
    {
      onSuccess: () => {
        alert("차단 해제되었습니다.");
        return queryClient.invalidateQueries("mypageAnonymousBlocks");
      },
      onError: (err) => {
        err.response && alert(err.response.data.msg);
      },
    },
  );

  const mutateBlock = async () => {
    const data = {
      uuid: popup.uuid,
    };
    releaseBlock.mutate(data);
  };

  const mutateAnonymousBlock = async () => {
    releaseAnonymousBlock.mutate(popup.uuid);
  };

  if (blockListLoading || anonymousBlocksLoading || !blockList) return <Loading />;
  return (
    <div className="container">
      {popup.open && popup.open === "nickname" && (
        <BlockPopup setPopup={setPopup} confirm={() => mutateBlock()} />
      )}
      {popup.open && popup.open === "anonymous" && (
        <BlockPopup setPopup={setPopup} confirm={() => mutateAnonymousBlock()} />
      )}
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="block" />
            <div className="con__layout">
              <article className="section__tit__box">
                <div className="tit-box">
                  <span className="tit-txt">차단된 사용자</span>
                </div>
              </article>
              <article className="section__tit__box2 border-none" style={{ marginTop: "20.5px" }}>
                <div className="tit-box">
                  {tabList.map((item, idx) => {
                    return (
                      <button
                        className={tab === item.value ? "tab-name selected" : "tab-name"}
                        onClick={() => onTabClick(item.value)}
                        key={idx}
                      >
                        {item.name}
                      </button>
                    );
                  })}
                </div>
              </article>
              <div className="list__wrap">
                <article className="list__table2">
                  {tab !== "nickname" ? (
                    <AnonymousBlockListTable items={anonymousBlocks} setPopup={setPopup} />
                  ) : (
                    <BlockListTable items={blockList} setPopup={setPopup} />
                  )}
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Block;

const AnonymousBlockListTable = ({ items, setPopup }) => {
  return (
    <div className="list">
      <div className="list-head">
        <div className="w20p left">닉네임</div>
        <div className="w50p left">차단내용</div>
        <div className="w20p left">차단일시</div>
        <div className="w10p">차단해제</div>
      </div>
      {items.length &&
        items.map((item, idx) => {
          return (
            <div className="width-max" key={idx}>
              <div className="slim content no-hover">
                <div className="w20p left">(익명)</div>
                <div className="w50p left">{item.referenceText}</div>
                <div className="w20p left">{writeDayYtoS(item.blockedAt)}</div>
                <div
                  className="w10p"
                  onClick={() => setPopup({ open: "anonymous", uuid: item.id })}
                >
                  <div className="block-button">차단해제</div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const BlockListTable = ({ items, setPopup }) => {
  return (
    <div className="list">
      <div className="list-head">
        <div className="w20p left">닉네임</div>
        <div className="w50p left"></div>
        <div className="w20p left">차단일시</div>
        <div className="w10p">차단해제</div>
      </div>
      {items.length &&
        items.map((item, idx) => {
          return (
            <div className="width-max" key={idx}>
              <div className="slim content no-hover">
                <div className="w20p left">{item.id}</div>
                <div className="w50p left"></div>
                <div className="w20p left">{writeDayYtoS(item.dttm)}</div>
                <div
                  className="w10p"
                  onClick={() => setPopup({ open: "nickname", uuid: item.uuid })}
                >
                  <div className="block-button">차단해제</div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
