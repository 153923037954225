import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { EssayQuestion, MultipleQuestion } from "./Tongtest_question";
import { api, selectStyles } from "../../utils/util";
import { default as Select } from "react-select";

const TongtestNote = () => {
  const navigate = useNavigate;
  const sid = parseInt(useParams().sid);
  const [problems, setProblems] = useState([]);
  const [select, setSelect] = useState(null);
  const [problemSet, setProblemSet] = useState([]);

  useEffect(() => {
    api.get("/tongtest/set_list").then((res) => {
      setProblemSet(res.data.list);
    });
  }, []);

  useEffect(() => {
    api
      .get(`/tongtest/record/${sid}`)
      .then((res) => {
        setProblems(res.data.problems);
      })
      .catch((err) => {
        alert("시험 로딩에 실패하였습니다");
      });
  }, [sid]);

  return (
    <>
      <article style={{ marginTop: 100 }} className="section__tit__box">
        <div className="tit-box">
          <span className="tit-txt">오답노트</span>
        </div>
      </article>
      <div className="wrapper tongtest-note__select">
        <Select
          value={select}
          onChange={(option) => {
            setSelect(option);
            navigate(`/tongtest/note/${option.value}`, { replace: true });
          }}
          styles={selectStyles}
          options={problemSet.map((item) => {
            return { label: item.name, value: item.sid };
          })}
          isSearchable={false}
          placeholder="모의고사를 선택해주세요"
        />
        <section className="festival__hot__board" style={{ marginTop: "5px" }}>
          <div className="inner">
            <article className="festival__board tong__board">
              <div className="tsubject-box">
                <div className="number">오답노트</div>
              </div>
            </article>
          </div>
        </section>
        {problems.map((item) =>
          item.problem_type === "choice" ? (
            <MultipleQuestion question={{ ...item, showAns: true }} isNote={true} key={item.pid} />
          ) : (
            <EssayQuestion question={{ ...item, showAns: true }} isNote={true} key={item.pid} />
          ),
        )}
        <article className="bottom__btn__group">
          <Link to="/tongtest/exam" className="bottom__btn__box">
            처음으로
          </Link>
        </article>
      </div>
    </>
  );
};

export default TongtestNote;
