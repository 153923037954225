import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { Img } from "../../utils/util";
import { isMarketOnlyUser } from "utils/marketUtil";

const Blur = ({ children }) => {
  const token = useRecoilValue(tokenState);
  const isAuthorizedUser = token && token.user_type && !isMarketOnlyUser(token.user_type);

  if (isAuthorizedUser) return children;
  return (
    <div className="have-blur h-full">
      <div className="announcement">
        <img src={Img("", "img/ic_teeth_round.svg")} alt=""></img>
        인증된 회원만 이용하실 수 있습니다.
      </div>
      {children}
    </div>
  );
};

export default Blur;
