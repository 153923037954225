import React, { useRef, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { scrollTopList } from "../../utils/util";
import ReactPaginate from "react-paginate";
import RecruitListTable from "./recruitListTable";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";

function RecruitContent({ pageInfo, state }) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const bid = parseInt(useParams().bid);
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q") || "";
  const [searchKeyword, setSearchKeyword] = useState(q);

  const param = (() => {
    const p = new URLSearchParams();
    state && state.major && p.append("major", state.major.value);
    state && state.working_time && p.append("working_time", state.working_time.value);
    state && state.loc1 && p.append("loc1", state.loc1);
    state && state.loc2 && state.loc2.length > 0 && p.append("loc2", state.loc2.join(","));
    page && p.append("page", page);
    q && p.append("q", q);
    return p.toString();
  })();

  const managementScroll = useRef();

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    state && state.major && param.append("major", state.major.value);
    state && state.working_time && param.append("working_time", state.working_time.value);
    state && state.loc1 && param.append("loc1", state.loc1);
    state && state.loc2 && state.loc2.length > 0 && param.append("loc2", state.loc2.join(","));
    param.append("page", 1);
    searchKeyword && param.append("q", searchKeyword);
    navigate(`${location.pathname}?${param}`);
    scrollTopList(managementScroll);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    state && state.major && param.append("major", state.major.value);
    state && state.working_time && param.append("working_time", state.working_time.value);
    state && state.loc1 && param.append("loc1", state.loc1);
    state && state.loc2 && state.loc2.length > 0 && param.append("loc2", state.loc2.join(","));
    selected && param.append("page", selected);
    q && param.append("q", q);
    navigate(`${location.pathname}?${param}`);
    scrollTopList(managementScroll);
  };

  return (
    <>
      <article className="list__table2" ref={managementScroll}>
        <RecruitListTable articles={pageInfo.items} param={param} bid={bid} />
      </article>
      <article className="paging">
        {pageInfo.page && (
          <ul>
            <li>
              <ReactPaginate
                previousLabel="←"
                previousLinkClassName="before"
                nextLabel="→"
                nextLinkClassName="next"
                breakLabel="..."
                pageCount={pageInfo.pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                pageLinkClassName="num"
                activeClassName=""
                activeLinkClassName="current"
                onPageChange={onPageClick}
                forcePage={pageInfo.page - 1}
              />
            </li>
          </ul>
        )}
        {token && token.user_type === "doctor" && (
          <Link to={`/recruit/hygienist/write`} className="write-btn bottom">
            <i className="ic ic-write"></i>
            <span className="txt">글쓰기</span>
          </Link>
        )}
      </article>

      <article className="list__search__form" style={{ marginTop: "47px" }}>
        <form className="search__form__box">
          <input
            type="search"
            placeholder="검색어를 입력해주세요."
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <button
            className="search-btn"
            onClick={(e) => search(e)}
            style={{ border: "none", outline: "none", cursor: "pointer" }}
          ></button>
        </form>
      </article>
    </>
  );
}

export default RecruitContent;
