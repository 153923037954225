import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "prosemirror-view/style/prosemirror.css";
import "prosemirror-tables/style/tables.css";
import "../../style/addMoreden.scss";

import colorsMoreden from "../../style/colorsMoreden";

import { INTEGRATION_EDITOR_MENUBAR_CLASS } from "../../submodules/integration-editors/prosemirror/styles/classNames";
import axios from "axios";
import Compressor from "compressorjs";
import Popup from "../Popup";
import ProsemirrorEditorMenubar from "./ProsemirrorEditorMenubar";
import { api } from "../../utils/util";
import { EditorState } from "prosemirror-state";
import { useEditor } from "../../submodules/integration-editors/prosemirror/core/ReactEditor";
import {
  Strong,
  Del,
  Em,
  Heading,
  HorizontalRule,
  Underline,
  BlockQuote,
  Code,
  CodeBlock,
  Video,
  Indicator,
  RemoteMenu,
  File,
  Image,
  TextAlign,
  Link,
  TextColor,
  TextBackground,
  Iframe,
  IframeTooltip,
  FontSize,
  Poll,
  Emoji,
  HtmlInsert,
  CustomTemplate,
  TableExtensions,
  ListItem,
  BulletList,
  OrderedList,
} from "../../submodules/integration-editors/prosemirror/extensions";
import { OnTransactionParams } from "submodules/integration-editors/prosemirror/core/eventEmitter";
import {
  handlePMClick,
  handlePMKeyDown,
  handlePMMouseup,
  handlePMPaste,
} from "submodules/integration-editors/prosemirror/utils/prosemirror";
import { REMOTE_MENU_PLUGIN_KEY } from "submodules/integration-editors/prosemirror/extensions/remoteMenu";
import { MoredenHighlighted } from "submodules/integration-editors/prosemirror/extensions/moredenHighlighted";

/**
 * 호출 시점의 에디터 상태를 가져오는 함수를 반환합니다.
 */
export type GetEditorStateHandle = {
  getEditorState: () => EditorState | undefined;
};

interface Props {
  isAdmin: boolean;
  savedContentJson?: any;
  setThumbnail?: (fn: string) => void; // FIXME: props 로 전달 받는 경우가 없는 것 같습니다.
}

const editorExtensions = [
  Strong,
  Del,
  Em,
  Heading,
  HorizontalRule,
  Underline,
  BlockQuote,
  Code,
  CodeBlock,
  Video,
  Indicator,
  RemoteMenu,
  File,
  Image,
  TextAlign,
  Link,
  TextColor,
  TextBackground,
  Iframe,
  IframeTooltip,
  FontSize,
  Poll,
  Emoji,
  HtmlInsert,
  CustomTemplate,
  ...TableExtensions,
  ListItem,
  BulletList,
  OrderedList,
  MoredenHighlighted,
];

const ProsemirrorEditor = forwardRef<GetEditorStateHandle, Props>((props, editorStateGetterRef) => {
  const fileSizePolicy = useMemo(
    () => ({
      gif: 10 * 1024 * 1024, // 모든 유저: 10MB
      staticImage: 20 * 1024 * 1024, // 모든 유저: 20MB
      video: props.isAdmin ? 100 * 1024 * 1024 : 20 * 1024 * 1024, // 일반유저: 20MB, 운영자: 100MB
      file: props.isAdmin ? "infinite" : 100 * 1024 * 1024, // 일반유저: 100MB, 운영자: 무제한
    }),
    [props.isAdmin],
  );

  const editorElementRef = useRef<HTMLDivElement>(null);

  const [popup, setPopup] = useState(false);
  const [autoSaveToast, setAutoSaveToast] = useState(false);
  const [fontNodeName, setFontNodeName] = useState("");
  const [toggleBtnName, setToggleBtnName] = useState({
    dropDown: false,
    name: "",
  });

  const onTransaction = useCallback<(fn: OnTransactionParams) => void>(({ editor }) => {
    autoSave(editor.state);
  }, []);

  const editorInstance = useEditor({
    element: editorElementRef,
    extensions: editorExtensions,
    onTransaction,
    editorProps: {
      handleClick: handlePMClick,
      handleKeyDown: handlePMKeyDown,
      handlePaste: handlePMPaste,
      handleDOMEvents: { mouseup: handlePMMouseup },
      colorScheme: colorsMoreden,
    },
  });

  useImperativeHandle(editorStateGetterRef, () => ({
    getEditorState: () => {
      return editorInstance.view?.state;
    },
  }));

  useEffect(() => {
    if (!editorInstance) return;
    if (typeof props.savedContentJson === "object" && props.savedContentJson.type === "doc") {
      const state = editorInstance.createStateFromDoc(props.savedContentJson);
      editorInstance.view?.updateState(state);
    }
  }, [editorInstance]);

  useEffect(() => {
    if (autoSaveToast === false) return;

    const timerId = setTimeout(() => {
      setAutoSaveToast(false);
    }, 3000);

    return () => {
      clearTimeout(timerId);
      setAutoSaveToast(false);
    };
  }, [autoSaveToast]);

  useEffect(() => {
    const savedContent = localStorage.getItem("autosave:pm-article:");
    savedContent && setPopup(true);
  }, []);

  /**
   * 에디터의 remoteMenu 플러그인으로 명령어와 페이로드를 전달합니다.
   */
  const dispatchMetaData = useCallback(
    (command: any) => {
      if (!editorInstance) return;
      setToggleBtnName({ dropDown: false, name: "" });
      editorInstance.view?.dispatch(
        editorInstance.view?.state.tr.setMeta(REMOTE_MENU_PLUGIN_KEY, command),
      );
    },
    [editorInstance],
  );

  /**
   * 100자 이상 쓸 때마다 자동 저장합니다.
   */
  const autoSave = (state: EditorState) => {
    const diffSizeUnit = 100;
    const writingContentLength = state.doc.nodeSize;
    const cachedContentLength = localStorage.getItem("autosave:pm-article-length:") || "0";
    if (writingContentLength >= diffSizeUnit) {
      if (Math.abs(writingContentLength - +cachedContentLength) >= diffSizeUnit) {
        localStorage.setItem("autosave:pm-article-length:", String(writingContentLength));
        localStorage.setItem("autosave:pm-article:", JSON.stringify(state.doc.toJSON()));
        setAutoSaveToast(true);
      }
    }
  };

  const replaceContentWithSavedContent = () => {
    const savedContent = localStorage.getItem("autosave:pm-article:")!;
    const state = editorInstance.createStateFromDoc(JSON.parse(savedContent));
    editorInstance.view?.updateState(state);
    localStorage.removeItem("autosave:pm-article:");
    localStorage.removeItem("autosave:pm-article-length:"); // 팝업에서 취소 버튼 누를때 삭제하는게 맞을것 같은데..
    setPopup(false);
  };

  const onFontSizeOpen = useCallback(() => {
    const { $from } = editorInstance.view?.state.selection || {};
    const node = $from?.node();

    if (node) {
      const nodeName = node.type.name;
      return setFontNodeName(nodeName);
    }

    setFontNodeName("");
    // MARK: editorInstance.view.state.selection 의 변화에 의존하지 않아도 최신 selection 을 참조할 수 있습니다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorInstance]);

  const mediaTypeGuard = (files: File[], Name: string) => {
    const isContainingUnacceptedMediaType = Array.from(files).some((file: File) => {
      if (Name === "image") {
        return !file.type.startsWith("image/");
      }
      if (Name === "video") {
        return !(file.type === "video/mp4");
      }
      return false;
    });
    if (isContainingUnacceptedMediaType) {
      throw new Error("허용되지 않은 형식입니다.");
    }
  };

  const compressImage = (file: File | Blob): Promise<File | Blob> => {
    return new Promise((resolve, reject) => {
      return new Compressor(file, {
        maxWidth: 1920,
        maxHeight: 1920,
        success(result) {
          resolve(result);
        },
        error(err: Error) {
          reject(err);
        },
      });
    });
  };

  const checkUploadingMediaSize = useCallback(
    async (file: File | Blob, policies: typeof fileSizePolicy) => {
      const GIF_MAX_SIZE = policies.gif;
      const STATIC_IMAGE_MAX_SIZE = policies.staticImage;
      const VIDEO_MAX_SIZE = policies.video;
      const FILE_MAX_SIZE = policies.file;

      const isGIF = file.type === "image/gif";
      const isVideo = file.type.startsWith("video/");
      const isStaticImage = file.type.startsWith("image/") && !isGIF;
      const isFile = !file.type.startsWith("image/") && !file.type.startsWith("video/");

      // GIF 파일인 경우
      if (isGIF && typeof GIF_MAX_SIZE === "number") {
        if (file.size > GIF_MAX_SIZE) {
          throw new Error(`GIF 파일은 ${GIF_MAX_SIZE / 1024 / 1024}MB 이상 업로드할 수 없습니다.`);
        }
        return file;
      }
      // 정적 이미지인 경우 압축을 시도합니다.
      if (isStaticImage && typeof STATIC_IMAGE_MAX_SIZE === "number") {
        if (typeof STATIC_IMAGE_MAX_SIZE === "number") {
          if (file.size > STATIC_IMAGE_MAX_SIZE) {
            throw new Error(
              `이미지 파일은 ${STATIC_IMAGE_MAX_SIZE / 1024 / 1024}MB 이상 업로드할 수 없습니다.`,
            );
          }
        }
        return await compressImage(file).catch(() => {
          throw new Error("파일 압축을 실패했습니다. 다시 시도해주세요.");
        });
      }
      // 동영상인 경우
      if (isVideo && typeof VIDEO_MAX_SIZE === "number") {
        if (file.size > VIDEO_MAX_SIZE) {
          throw new Error(
            `동영상 파일은 ${VIDEO_MAX_SIZE / 1024 / 1024}MB 이상 업로드할 수 없습니다.`,
          );
        }
        return file;
      }
      // 기타 파일인 경우
      if (isFile && typeof FILE_MAX_SIZE === "number") {
        if (file.size > FILE_MAX_SIZE) {
          throw new Error(`파일은 ${FILE_MAX_SIZE / 1024 / 1024}MB 이상 업로드할 수 없습니다.`);
        }
        return file;
      }

      return file;
    },
    [],
  );

  const hookBeforeUpload = useCallback(
    async (files: File[], name: string) => {
      // 첨부된 파일들의 타입을 검사합니다.
      mediaTypeGuard(files, name);

      // 첨부된 파일들을 압축합니다.
      const compressRequests = Array.from(files).map((file) => {
        return checkUploadingMediaSize(file as any, fileSizePolicy);
      });
      const compressedFiles = await Promise.all(compressRequests);
      return compressedFiles;
    },
    [checkUploadingMediaSize, fileSizePolicy],
  );

  const ContentTypeKorean = (name: string) => {
    switch (name) {
      case "image":
        return "이미지";
      case "video":
        return "동영상";
      case "file":
        return "파일";
      default:
        return "";
    }
  };

  const startUploadProcess = useCallback(
    async (filesArray: File[], indicatorId: {}, name: string) => {
      let files = [];
      // 업로드 훅을 통과시킵니다.
      try {
        files = await hookBeforeUpload(filesArray, name);
      } catch (error: any) {
        console.error(error);
        dispatchMetaData({
          type: "upload-failure-message",
          message: error.message,
          id: indicatorId,
        });
      }

      // 훅을 통과하지 못한 경우 업로드를 중단합니다.
      if (!files.length) {
        dispatchMetaData({
          type: "upload-failure-message",
          message: "파일 압축을 실패했습니다. 다시 시도해주세요.",
          id: indicatorId,
        });
        return false;
      }

      // 압축된 미디어의 업로드를 시도합니다.
      try {
        const uploadedFileList = await Promise.all(
          Array.from(filesArray).map(async (file) => {
            const res = await api!.post("/util/presigned", { filename: file.name });
            const signedUrl = res.data;
            const url = new URL(signedUrl);
            url.search = "";
            await axios.put(signedUrl, file);
            return url.toString();
          }),
        );
        const decodeUploadedFileList = uploadedFileList.map((url) => {
          return decodeURIComponent(url);
        });

        if (name === "image") {
          props.setThumbnail && props.setThumbnail(uploadedFileList[0]);
        }
        if (name === "video") {
          const videos = uploadedFileList.map((url) => ({ url, poster: "" }));
          dispatchMetaData({ type: name, videos, id: indicatorId });
        } else if (name === "file") {
          dispatchMetaData({ type: name, urls: decodeUploadedFileList, id: indicatorId });
        } else {
          dispatchMetaData({ type: name, urls: uploadedFileList, id: indicatorId });
        }
      } catch (err) {
        console.log(err);
        dispatchMetaData({
          type: "upload-failure-message",
          message: `${ContentTypeKorean(name)} 업로드를 실패했습니다. 다시 시도해주세요.`,
          id: indicatorId,
        });
      }
    },
    [dispatchMetaData, hookBeforeUpload],
  );

  const uploadFiles = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, name: string) => {
      if (!event.target.files) return;
      const indicatorId = {}; // 미디어가 삽입될 위치에 인디케이터를 표시합니다. 객체의 주소를 인디케이터 식별자로 사용합니다.
      dispatchMetaData({ type: "before-upload", id: indicatorId });
      startUploadProcess(Array.from(event.target.files), indicatorId, name);
    },
    [dispatchMetaData, startUploadProcess],
  );

  /**
   * drag & drop 혹은 paste 이벤트를 통해 미디어를 첨부하는 경우를 대응합니다.
   */
  const handleAlternativeMediaAttach = (
    fileList: FileList,
    coords?: { left: number; top: number },
  ) => {
    const files = Array.from(fileList);
    const indicatorId = {};
    const acceptedImageTypes = "image/";
    const acceptedVideoTypes = "video/mp4";
    const isImages = files.every((file) => file.type.startsWith(acceptedImageTypes));
    const isVideos = files.every((file) => file.type === acceptedVideoTypes);
    const isFiles = files.every((file) => {
      if (file.type.startsWith(acceptedImageTypes)) return false;
      if (file.type === acceptedVideoTypes) return false;
      return true;
    });

    coords
      ? dispatchMetaData({
          type: "before-drag-upload",
          id: indicatorId,
          coords,
        })
      : dispatchMetaData({ type: "before-upload", id: indicatorId });

    if (isImages) {
      return startUploadProcess(files, indicatorId, "image");
    }
    if (isVideos) {
      return startUploadProcess(files, indicatorId, "video");
    }
    if (isFiles) {
      return startUploadProcess(files, indicatorId, "file");
    }
    return dispatchMetaData({
      type: "upload-failure-message",
      message: "한 종류의 미디어만 첨부해 주세요.",
      id: indicatorId,
    });
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const isFileDrop = event.dataTransfer && event.dataTransfer.files.length;
    if (isFileDrop) {
      const coords = { left: event.clientX, top: event.clientY };
      handleAlternativeMediaAttach(event.dataTransfer.files, coords);
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const isFilePaste = event.clipboardData && event.clipboardData.files.length;
    if (isFilePaste) {
      event.preventDefault();
      handleAlternativeMediaAttach(event.clipboardData.files);
    }
  };

  return (
    <div
      className="article-editor-pm"
      ref={editorElementRef}
      onDrop={handleDrop}
      onPaste={handlePaste}
    >
      {popup && (
        <Popup
          title="임시 저장"
          isProseMirror
          setConfirm={replaceContentWithSavedContent}
          setPopup={setPopup}
        >
          <div>
            작성 중이던 글이 있습니다.
            <br />
            이어서 계속 작성하시겠어요?
          </div>
        </Popup>
      )}
      {autoSaveToast && (
        <div className="fixed left-10 bottom-20 z-10 shadow-custom px-4 py-5 rounded-lg bg-white">
          글이 자동 저장되었습니다.
        </div>
      )}
      <div className={INTEGRATION_EDITOR_MENUBAR_CLASS + "-wrap"} style={{ top: "0" }}>
        <ProsemirrorEditorMenubar
          dispatchMetaData={dispatchMetaData}
          onFontSizeOpen={onFontSizeOpen}
          fontNodeType={fontNodeName}
          toggleBtnName={toggleBtnName}
          setToggleBtnName={setToggleBtnName}
          uploadFiles={uploadFiles}
          IsAdmin={props.isAdmin}
        />
      </div>
    </div>
  );
});

ProsemirrorEditor.displayName = "ProsemirrorEditor";

export default ProsemirrorEditor;
