import React from "react";
import ReactPaginate from "react-paginate";
import { default as Select } from "react-select";
import { cls, selectStyles } from "../../utils/util";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import BoardListTable from "./BoardListTable";

function BoardListUI({ pageInfo, board, param, onPageClick, scroll }) {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam = new URLSearchParams(location.state ? location.state : location.search);
  const sort = urlParam.get("sort") || undefined;
  const cate_name =
    pageInfo &&
    (pageInfo.board_cate
      ? pageInfo.board_cate.filter((value) => value.cate_cd === board)[0].cate_name
      : "");
  const article = parseInt(useParams().bid || 0);
  const form = location.pathname;
  const options = [
    { label: "최신순", value: undefined },
    { label: "추천순", value: "like" },
    { label: "조회순", value: "view" },
  ];

  return (
    <>
      <article className="list__table2 relative" ref={scroll}>
        <div className="mb-3 flex justify-between">
          <div className="flex gap-2 items-center">
            <button
              className={cls(
                !pageInfo.board_type
                  ? "bg-secondary-300 text-white"
                  : "border-secondary-300 border-[1px] border-solid",
                "text-[13px] rounded p-2 px-3 h-[37px]",
              )}
              onClick={() => {
                navigate(`${form}?${param}`);
              }}
            >
              전체글
            </button>
            <button
              className={cls(
                pageInfo.board_type === "pick"
                  ? "bg-secondary-300 text-white"
                  : "border-secondary-300 border-[1px] border-solid",
                "text-[13px] rounded p-2 px-3 h-[37px]",
              )}
              onClick={() => {
                navigate(`${form}?type=pick&${param}`);
              }}
            >
              {/* 눌렀을때 페이지 초기화 */}
              화제글
            </button>
          </div>
          <div className="flex gap-2">
            <Select
              className="w-[100px]"
              value={options.filter((option) => option.value === sort)[0]}
              onChange={(option) => {
                navigate(`${form}?${option.value && `sort=${option.value}&`}${param}`);
              }}
              styles={selectStyles}
              options={options}
              isSearchable={false}
              placeholder="정렬"
            />
            <Link
              to={`/post/write/${board}`}
              className="bg-black text-white p-2 px-3 rounded text-[13px] flex items-center gap-1 h-[37px]"
            >
              <i className="ii ii-pen-outline text-white text-lg"></i>
              글쓰기
            </Link>
          </div>
        </div>
        {pageInfo && (
          <BoardListTable
            articles={pageInfo.board}
            param={param}
            board={board}
            notices={pageInfo.notices}
            cate_name={cate_name}
            article={article}
          />
        )}
        <Link
          to={`/post/write/${board}`}
          className="bg-black text-white p-2 px-3 rounded text-[13px] flex items-center gap-1 h-[37px] w-fit absolute right-0 bottom-0 translate-y-[calc(100%+12px)]"
        >
          <i className="ii ii-pen-outline text-white text-lg"></i>
          글쓰기
        </Link>
      </article>
      <article className="paging">
        {pageInfo.page && (
          <ReactPaginate
            previousLabel="←"
            previousLinkClassName="before"
            nextLabel="→"
            nextLinkClassName="next"
            breakLabel="..."
            pageCount={pageInfo.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            pageLinkClassName="num"
            activeClassName=""
            activeLinkClassName="current"
            onPageChange={onPageClick}
            style={{ cursor: "pointer" }}
            forcePage={pageInfo.page - 1}
          />
        )}
      </article>
    </>
  );
}

export default BoardListUI;
