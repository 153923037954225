import { useMutation, useQuery, useQueryClient } from "react-query";
import { api, cls } from "utils/util";
import { tokenState } from "../../state";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const SubscribeButton = ({ uid }) => {
  const token = useRecoilValue(tokenState);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { data: isSubscribed, isLoading: isSubscribedLoading } = useQuery(
    ["isSubscribed", token.uid, uid],
    () => api.get(`/users/subscribe/${uid}`).then((res) => res.data.result),
    {
      enabled: !!token.uid,
      cacheTime: Infinity,
    },
  );

  const subscribeUser = useMutation((uid) => api.post(`/users/subscribe/${uid}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["isSubscribed", token.uid, uid]);
    },
    onSettled: () => setIsLoading(false),
  });

  const unsubscribeUser = useMutation((uid) => api.delete(`/users/unsubscribe/${uid}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["isSubscribed", token.uid, uid]);
    },
    onSettled: () => setIsLoading(false),
  });

  if (!uid || uid === token.uid || isSubscribedLoading) return null;

  return (
    <button
      disabled={isLoading}
      onClick={() => {
        setIsLoading(true);
        isSubscribed ? unsubscribeUser.mutate(uid) : subscribeUser.mutate(uid);
      }}
      className={cls(
        isSubscribed
          ? "border-[1px] border-solid border-secondary-300 bg-white hover:bg-secondary-100 text-black"
          : "bg-black hover:bg-[#000] text-white",
        "whitespace-nowrap flex items-center justify-center w-[55px] h-[27.5px] text-[13px] p-1 px-2 rounded  disabled:opacity-50 disabled:cursor-not-allowed",
      )}
    >
      {isSubscribed ? (
        <>구독중</>
      ) : (
        <span className="flex items-center">
          <i className="ii ii-plus ii-weight-600 text-white mr-0.5"></i>구독
        </span>
      )}
    </button>
  );
};

export default SubscribeButton;
