import React, { useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { api, dttm, htmlTextContent, Img, watermark } from "../../utils/util";
import Loading from "../../component/Loading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import BoardTrashPopup from "../../component/board/BoardTrashPopup";
import RecruitContent from "../../component/recruit/recruitContent";
import RecruitClosePopup from "../../component/recruit/recruitClosePopup";
import KakaoMap from "component/shared/KakaoMap";

function HygienistDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const bid = useParams().bid;
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const q = urlParam.get("q") || "";
  const params = { page, q };
  const [deletePopup, setDeletePopup] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const token = useRecoilValue(tokenState);

  const recruitScroll = useRef();

  const directCloseArticle = () => {
    api.post(`/management/hygienist/close/${bid}`).then((res) => {
      navigate(`/recruit/hygienist`, { replace: true });
    });
  };

  const modifyFunc = () => {
    navigate(`/recruit/hygienist/modify/${bid}`, {
      state: { ...content },
    });
  };

  const directDeleteArticle = () => {
    api.delete(`/management/hygienist/delete/${bid}`).then((res) => {
      navigate(`/recruit/hygienist`, { replace: true });
    });
  };

  const { data: content, isLoading: contentLoading } = useQuery(
    ["managementHygienistArticle", bid],
    () => api.get(`/management/hygienist/article/${bid}`).then((res) => res.data),
    {
      onError: (err) => {
        alert(err.response.data.msg);
        navigate(-1);
      },
    },
  );

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["managementHygienistList", params],
    () =>
      api
        .get("/management/hygienist/list", {
          params: params,
        })
        .then((res) => res.data),
    { onError: (err) => console.log(err) },
  );

  if (pageInfoLoading || contentLoading) return <Loading current="management" />;

  return (
    <div>
      <div className="wrapper" style={{ background: watermark(token), backgroundRepeat: "space" }}>
        <section className="magazine__detail" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">치과위생사</span>
                {content.article && content.ismine ? (
                  <>
                    <div className="menu-box modify">
                      <div className="report-box">
                        {!content.article.terminate_at && (
                          <button onClick={() => setClosePopup(true)} className="report-btn">
                            마감
                          </button>
                        )}
                      </div>
                      <div className="report-box">
                        <button onClick={modifyFunc} className="report-btn">
                          수정
                        </button>
                      </div>
                      <div className="report-box">
                        <button onClick={() => setDeletePopup(true)} className="report-btn">
                          삭제
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </article>
            {deletePopup && (
              <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
            )}
            {closePopup && (
              <RecruitClosePopup setClosePopup={setClosePopup} closeFunc={directCloseArticle} />
            )}
            {content.article && (
              <div className="detail__group">
                <div className="tit-box dt">
                  <div className="subject-box">
                    {dayjs(content.article.terminate_at) < dayjs() && "[마감] "}
                    {content.article.title}
                  </div>
                  <div className="info-box">
                    <span className="txt">{content.article.hospital_name}</span>
                    <span className="txt">{dttm(content.article.reg_dttm)}</span>
                  </div>
                </div>

                <div className="etc-box dt">
                  <div className="box">
                    <div className="row">
                      <div className="subject dt">업무구분</div>
                      <div className="con">
                        <span className="accent">
                          {content.article.task_list.replace(",", ", ")}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">경력조건</div>
                      <div className="con">
                        <span className="accent">{content.article.career_type}</span>
                        <span className="accent">{`${content.article.career_lower}~${content.article.career_upper}년`}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">급여조건</div>
                      <div className="con">
                        <span className="accent">{content.article.wage_type}</span>
                        {content.article.wage_type !== "면접시 협의" && (
                          <span className="accent">{`${content.article.wage_amount / 10000}만원 (${
                            content.article.wage_tax
                          })`}</span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">마감일</div>
                      <div className="con">
                        <span className="accent">
                          {dayjs(content.article.terminate_at, "YYYYMMDD").format("YYYY년 M월 D일")}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">위치</div>
                      <div className="con">
                        <span className="txt">{content.article.address} </span>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="row">
                      <div className="subject dt">제출서류</div>
                      <div className="con">
                        <span className="txt">
                          {content.article.requirement_docs.replace(",", ", ")}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">고용형태</div>
                      <div className="con">
                        <span className="txt">{content.article.type_list.replace(",", ", ")}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">병원명</div>
                      <div className="con">
                        <span className="txt">{content.article.hospital_name}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">담당자</div>
                      <div className="con">
                        <span className="txt">
                          {content.article.contact_email}
                          {content.article.contact_email.length === 0 ||
                          content.article.contact_phone.length === 0
                            ? ""
                            : " / "}
                          {content.article.contact_phone}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject dt">근무요일/시간</div>
                      <div className="con">
                        <span className="txt schedule">
                          {htmlTextContent(content.article.work_schedule)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="con-box">
                  <div className="txt-box">{htmlTextContent(content.article.content)}</div>
                  <div className="img-box">
                    {content.images &&
                      content.images.map((item, i) => {
                        return <img key={i} src={Img(item.link)} alt="이미지" />;
                      })}
                  </div>
                  <div className="location-box" style={{}}>
                    <b>근무지 위치</b>
                    <div className="info-box" style={{}}>
                      {content.article.address}
                    </div>
                    <KakaoMap lati={content.article.lati} long={content.article.long} />
                  </div>
                </div>
                <div className="menu-box-list">
                  {navigator.share ? (
                    <button
                      className="menu-box"
                      onClick={() =>
                        navigator.share({
                          title: content.article.title,
                          text: `[모어덴 - 치과위생사 구인공고 게시판]\n${content.article.title}`,
                          url: location.pathname,
                        })
                      }
                    >
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  ) : (
                    <CopyToClipboard
                      text={`https://www.moreden.co.kr${location.pathname}`}
                      onCopy={() => alert("링크를 복사하였습니다")}
                    >
                      <button className="menu-box">
                        <div className="share-btn-n"></div>
                        <span className="menu-txt">공유</span>
                      </button>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>

        <section
          className="magazine__list"
          style={{ marginBottom: "136.5px", marginTop: "50px" }}
          ref={recruitScroll}
        >
          {pageInfo && (
            <div className="inner">
              <RecruitContent pageInfo={pageInfo} current="recruit" pages={pageInfo.pages} />
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default HygienistDetail;
