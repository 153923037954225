/**
 * @typedef {{
 *  name: string,
 *  type?: 'mark' | 'node',
 *  defineSpec?: (fn: Extension[]) => import("prosemirror-model").MarkSpec | import("prosemirror-model").NodeSpec,
 *  addPlugins?: () => import('prosemirror-state').Plugin[],
 *  addKeyboardShortcuts?: () => any,
 *  addInputRules?: () => import('prosemirror-inputrules').InputRule[],
 *  addPasteRules?: () => any,
 *  addCommands?: () => any,
 *  addNodeView?: () => import('prosemirror-view').NodeView,
 * }} ExtensionConfig
 */

export class Extension {
  /**
   * @param {ExtensionConfig} config 
   */
  constructor(config = {}) {
    this.name = config.name
    this.type = config.type
    this.defineSpec = config.defineSpec
    this.addPlugins = config.addPlugins
    this.addKeyboardShortcuts = config.addKeyboardShortcuts
    this.addInputRules = config.addInputRules
    this.addPasteRules = config.addPasteRules
    this.addCommands = config.addCommands
    this.addNodeView = config.addNodeView
  }

  /**
   * 
   * @param {ExtensionConfig} config 
   */
  static Create(config = {
    name,
    type,
    defineSpec,
    addPlugins,
    addKeyboardShortcuts,
    addInputRules,
    addPasteRules,
    addCommands,
    addNodeView,
  }) {
    return new Extension(config)
  }
}
