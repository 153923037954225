import { api } from "utils/util";

export interface Badge {
  createdAt: string;
  deletedAt: string | null;
  id: number;
  imgUrl: string;
  link: string | null;
  name: string;
  sort: number;
  updatedAt: string;
  type: string;
  description: string;
}

export const fetchUserBadges = (uid: number) =>
  api?.get<Badge[]>(`/users/${uid}/badges`).then((res) => res.data);
