import { Link } from "react-router-dom";
import { Img, resizedImg } from "utils/util";

const RecommendContents = ({ contents }) => {
  return (
    <div className="bg-gray-50 rounded-md py-3 px-5 mb-4">
      <div className="text-xl tracking-tighter font-semibold whitespace-nowrap mb-3">
        모어덴 추천 콘텐츠 ⭐
      </div>
      {contents.slice(0, 4).map((item, idx) => (
        <Link
          to={`/contents/article/${item.bid}`}
          className="w-full relative flex justify-between items-center group mb-4"
          key={idx}
        >
          <div className="w-[40%] rounded-md aspect-contents overflow-hidden">
            <img
              className="w-full h-full group-hover:scale-105 transition-all duration-300"
              src={resizedImg(Img(item.thumbnail), 320)}
              alt=""
            />
          </div>
          <div className="w-[58%] m-0 flex flex-col justify-between">
            <div className="text-black font-semibold lg:truncate text-md group-hover:text-primary-800">
              {item.title}
            </div>
            <div className="flex mt-3">
              {item.tag
                .split(",")
                .slice(0, 3)
                .map((keyword) => {
                  return (
                    <div
                      className="truncate text-tiny bg-gray-100 !text-gray-600 rounded-sm p-1 mr-3 lg:mt-auto"
                      key={keyword}
                    >
                      {keyword}
                    </div>
                  );
                })}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default RecommendContents;
