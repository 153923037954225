import { wrappingInputRule } from "prosemirror-inputrules";
import { Extension } from "../core/extension";
import { wrapIn } from "prosemirror-commands";
import { Heading } from "./heading";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

export const BlockQuote = Extension.Create({
  name: 'blockquote',

  type: 'node',

  defineSpec(extensions) {
    const heading = extensions.find(extension => extension.name === Heading.name)
    const contentExpression = heading ? '(paragraph|heading)+' : 'paragraph+'

    return {
      content: contentExpression,
      group: 'block',
      defining: true,
      parseDOM: [{tag: 'blockquote'}],
      toDOM() {
        return [
          'blockquote',
          {class: MEDISTREAM_SCHEMA_STYLE.nodes.blockquote},
          0,
        ]
      },
    }
  },

  addCommands() {
    return {
      wrapInBlockquote: wrapIn(this.schema.nodes[this.name]),
    }
  },

  addInputRules() {
    return [wrappingInputRule(/^\s*>\s$/, this.schema.nodes[this.name])]
  }
})
