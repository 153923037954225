import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import AttachImages from "../../component/shared/AttachImages";
import Footer from "../../component/footer/Footer";
import Loading from "../../component/Loading";
import { api } from "../../utils/util";

function QnaWrite() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const modifyState = location?.state?.qna;

  const [cate_cd, setCateCd] = useState(modifyState?.cate_cd || 0);
  const [title, setTitle] = useState(modifyState?.title || "");
  const [content, setContent] = useState(modifyState?.content || "");
  const [images, setImages] = useState(state?.images?.map((item) => item.link) || []);
  const urlParam = new URLSearchParams(location.search);
  const tab = useParams().tab;
  const tabTitle = { doctor: "치과의사 Q&A", student: "치대생 Q&A" };

  const { data: topicItem, isLoading: topicListLoading } = useQuery(
    "qnaCateCd",
    () =>
      api
        .get("/qna/cate_cd")
        .then((res) => res?.data?.cate_list?.filter((item) => item?.cate_forum === state?.forum)),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        err?.response
          ? alert(err.response.data.msg)
          : alert("Q&A 토픽을 받아오는 데 실패하였습니다");
      },
    },
  );

  const qnaWrite = useMutation(({ cate_cd, data }) => api.post(`/qna/write/${cate_cd}`, data), {
    onSuccess: (res) => {
      alert(res.data.msg);
      navigate(`/qna/article/${res.data.bid}?${urlParam}`, { replace: true });
    },
    onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
  });

  const qnaModify = useMutation(({ bid, data }) => api.post(`/qna/modify/${bid}`, data), {
    onSuccess: (res) => {
      alert(res.data.msg);
      navigate(`/qna/article/${res.data.bid}?${urlParam}`, { replace: true });
    },
    onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
  });

  const submit = async (e) => {
    e.preventDefault();
    if (title.length < 5) {
      alert("제목을 5글자 이상 입력해주세요");
      return;
    }

    const data = {
      cate_cd,
      title,
      content,
      images: images.length > 0 ? images : [],
    };

    !modifyState
      ? qnaWrite.mutate({ cate_cd, data })
      : qnaModify.mutate({ data, bid: modifyState.bid });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (topicListLoading) return <Loading />;

  if (qnaWrite.isLoading || qnaModify.isLoading) return <Loading />;

  return (
    <>
      <div className="wrapper mt70">
        <section className="board__reg" style={{ marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">{tabTitle[tab]} 작성</span>
                {tab === "doctor" && (
                  <span className="info-txt">
                    치과의사 Q&amp;A는 의사 면허증을 인증한 회원만 작성할 수 있습니다
                  </span>
                )}
              </div>
            </article>

            <div className="reg__top__box">
              <div className="topic-box">
                <div className="sbox">
                  <select onChange={(e) => setCateCd(e.target.value)} value={cate_cd}>
                    <option hidden>게시판을 선택해주세요.</option>
                    {topicItem &&
                      topicItem
                        .filter((item) => item.cate_cd !== 0 && item.cate_forum === tab)
                        .map((item) => {
                          return (
                            <option value={item.cate_cd} key={item.cate_cd}>
                              {item.cate_name}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>

              <div className="tit-box">
                <input
                  className="full-width"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  type="text"
                  placeholder="5글자 이상의 제목을 입력해주세요."
                />
              </div>
            </div>

            <div className="reg__mid__box">
              <textarea
                onChange={(e) => setContent(e.target.value)}
                value={content}
                placeholder="이미지는 하단에 보여지는 순서로 배치됩니다. 댓글이 작성된 게시글은 삭제 및 수정이 불가합니다."
              ></textarea>
            </div>
            <div className="reg__bottom__box">
              <AttachImages useImages={[images, setImages]} />
            </div>

            <div className="reg__menu__box">
              <button
                onClick={() => navigate(-1)}
                className="menu-btn type1"
                style={{ border: "none", outline: "none" }}
              >
                취소
              </button>
              <button
                disabled={qnaWrite.isLoading || qnaModify.isLoading}
                onClick={submit}
                className="menu-btn type2"
                style={{ border: "none", outline: "none" }}
              >
                {modifyState ? "수정" : "등록"}
              </button>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default QnaWrite;
