import { setBlockType } from "prosemirror-commands";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

const ALIGN_PATTERN = /(left|right|center|justify)/;

/**
 *
 * @param {HTMLElement} dom
 * @returns {Object}
 */
const getAttrs = (dom) => {
  const {textAlign} = dom.style

  let align = dom.getAttribute('align') || textAlign || ''

  align = ALIGN_PATTERN.test(align) ? align : null

  return {align}
}

/**
 *
 * @param {Node} node
 * @returns {Array<any>}
 */
const toDOM = (node) => {
  const {align} = node.attrs
  const attrs = {
    class: MEDISTREAM_SCHEMA_STYLE.nodes.paragraph,
  }

  let style = ''

  if (align && align !== 'left') {
    style += `text-align: ${align};`
  }

  style && (attrs.style = style)

  return ['p', attrs, 0]
}

export const Paragraph = Extension.Create({
  name: 'paragraph',

  type: 'node',

  defineSpec() {
    return {
      attrs: {
        align: {default: null},
      },
      content: 'inline*',
      group: 'block',
      parseDOM: [{tag: 'p', getAttrs}],
      toDOM,
    }
  },

  addCommands() {
    return {
      setBlockToParagraph: (state, dispatch) => setBlockType(state.schema.nodes[this.name])(state, dispatch),
    }
  },

  addKeyboardShortcuts() {
    return {
      'Ctrl-Alt-H': this.editor.commands.setBlockToParagraph,
      'Ctrl-Alt-h': this.editor.commands.setBlockToParagraph,
    }
  }
})

export const getParagraphNodeAttrs = getAttrs
