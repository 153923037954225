import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { cls } from "utils/util";
import { useInView } from "react-intersection-observer";
import SearchItemRecruit from "component/search/item/SearchItemRecruit";
import { useSearchSegemnet } from "hooks/useSearchSegment";

const SearchResultRecruit = ({ searchResult, hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  // ---------- 검색 리스트 ----------
  const recruitList = useMemo(() => {
    if (!searchResult) return [];
    return searchResult.reduce((acc, cur) => {
      if (cur?.hits) {
        return [...acc, ...cur.hits];
      }
      return acc;
    }, []);
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div>
      {recruitList.length > 0 ? (
        recruitList.map((recruit, index) => (
          <Link
            className={cls(
              index === recruitList.length - 1 ? "" : "border-b border-solid border-b-gray-200",
              "block cursor-pointer",
            )}
            to={`/recruit/doctor/${recruit.id.split("_")[1]}`}
            onClick={() => handleSearchItemClick({ id: recruit.id, title: recruit.title })}
            key={recruit.id}
          >
            <SearchItemRecruit
              title={recruit.title}
              content={recruit.content}
              location1={recruit.location1}
              location2={recruit.location2}
              hospital_name={recruit.hospital_name}
              view={recruit.view}
              reg_dttm={recruit.reg_dttm}
              key={recruit.id}
            />
          </Link>
        ))
      ) : (
        <p>검색 결과가 없습니다.</p>
      )}
      {hasNextPage && !isFetchingNextPage && <div ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultRecruit;
