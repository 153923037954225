import React from "react";
import { Route, Routes } from "react-router-dom";
import ConsultingPage from "./ConsultingPage";
import ConsultingMediground from "./ConsultingMediground";
import ConsultingDetail from "./ConsultingDetail";
import ConsultingWrite from "./ConsultingWrite";
import ConsultingDentalMaster from "./ConsultingDentalMaster";
import ConsultingTd from "./ConsultingTd";
import ConsultingEum from "./ConsultingEum";
import ConsultingGoldDoctors from "./GoldDoctors";
import ConsultingMediRise from "./MediRise";
const Consulting = () => {
  return (
    <div className="container">
      <Routes>
        <Route path="/*" element={<ConsultingPage />} />
        <Route path="/goldDoctors" element={<ConsultingGoldDoctors />} />
        <Route path="/mediRise" element={<ConsultingMediRise />} />
        <Route path="/mediground" element={<ConsultingMediground />} />
        <Route path="/eum" element={<ConsultingEum />} />
        <Route path="/td" element={<ConsultingTd />} />
        <Route path="/dentalMaster" element={<ConsultingDentalMaster />} />
        <Route path="/article/:id" element={<ConsultingDetail />} />
        <Route path="/write/:cate" element={<ConsultingWrite />} />
        <Route path="/modify/:bid" element={<ConsultingWrite />} />
      </Routes>
    </div>
  );
};

export default Consulting;
