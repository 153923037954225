import { useState } from "react";
import { Link } from "react-router-dom";
import { cutCateLength, api, cls } from "../../utils/util";
import { useQuery } from "react-query";
import Blur from "../../page/user/Blur";
import CensoredData from "page/user/CensoredData";
import { useHomeSegment } from "hooks/useHomeSegment";

const IssueArticle = () => {
  const { sendTrackingEvent } = useHomeSegment();
  const [board, setBoard] = useState<"board" | "qna" | "forum">("board");
  const [term, setTerm] = useState<"daily" | "monthly" | "weekly">("daily");
  const boardArray: { label: "게시판" | "Q&A" | "임상포럼"; value: "board" | "qna" | "forum" }[] = [
    { label: "게시판", value: "board" },
    { label: "Q&A", value: "qna" },
    { label: "임상포럼", value: "forum" },
  ];
  const termArray: { label: "최근" | "주간" | "월간"; value: "daily" | "weekly" | "monthly" }[] = [
    { label: "최근", value: "daily" },
    { label: "주간", value: "weekly" },
    { label: "월간", value: "monthly" },
  ];

  const mapping: {
    [key: string]: string;
  } = {
    board: "post",
    qna: "qna",
    forum: "forum",
  };

  const { data: boardsSpotlightedState, isLoading: boardsSpotlightedStateLoading } = useQuery<
    BoardsSpotlighted | undefined,
    Error
  >("boardsSpotlighted", () => api?.get("boards/spotlighted").then((res) => res.data));

  interface BoardsSpotlighted {
    board: {
      daily: boardsSpotlightedItem[];
      monthly: boardsSpotlightedItem[];
      weekly: boardsSpotlightedItem[];
    };
    forum: {
      daily: boardsSpotlightedItem[];
      monthly: boardsSpotlightedItem[];
      weekly: boardsSpotlightedItem[];
    };
    qna: {
      daily: boardsSpotlightedItem[];
      monthly: boardsSpotlightedItem[];
      weekly: boardsSpotlightedItem[];
    };
  }

  interface boardsSpotlightedItem {
    bid: number;
    cate_name: string;
    comment: number;
    level: number;
    reg_dttm: string;
    title: string;
    unick: string;
    view: number;
  }

  return (
    <div className="w-full flex flex-col gap-3">
      <span className="flex justify-between items-center">
        <b className="text-[20px]">모어덴 HOT 게시글</b>
        <div className="grid grid-cols-3 text-[13px]">
          {termArray.map((item, idx) => {
            return (
              <div
                key={idx}
                onClick={() => setTerm(item.value)}
                className="cursor-pointer text-center last:after:content-['']  after:content-['|']"
              >
                <span
                  className={cls(
                    term === item.value ? "font-semibold" : "",
                    "hover:underline px-2",
                  )}
                >
                  {item.label}
                </span>
              </div>
            );
          })}
        </div>
      </span>
      <div className="border-solid border-[1px] border-gray-300 overflow-hidden grid grid-cols-3 w-full rounded-md bg-gray-100 text-gray-900">
        {boardArray.map((item, idx) => {
          return (
            <div
              onClick={() => setBoard(item.value)}
              key={idx}
              className={cls(
                board === item.value ? "bg-white text-primary-800" : "",
                "even:border-r-[1px] even:border-l-[1px] even:border-solid  even:border-gray-300 hover:bg-white py-2 text-center text-[14px] cursor-pointer",
              )}
            >
              {item.label}
            </div>
          );
        })}
      </div>
      <div>
        <Blur>
          <div
            onClick={() => sendTrackingEvent({ name: "HOT 게시글" })}
            className="hard-blur flex flex-col"
          >
            {!boardsSpotlightedStateLoading &&
              boardsSpotlightedState &&
              boardsSpotlightedState[board][term].slice(0, 5).map((item) => (
                <Link
                  to={`${mapping[board]}/article/${item.bid}`}
                  className="border-solid border-b-[1px] text-[14px] text-gray-900 hover:text-primary-800 border-b-gray-100 flex items-center h-[56px] cursor-pointer"
                  key={item.bid}
                >
                  <div className="!text-primary-800 bg-primary-100 rounded-sm text-xs w-[56px]  text-center py-1 mr-2">
                    {cutCateLength(item.cate_name)}
                  </div>
                  <div className="text-[16px] flex flex-col w-[312px]">
                    <div className="text-gray-900 font-medium ellipsis leading-5">
                      {" "}
                      <CensoredData>{item.title}</CensoredData>
                    </div>
                    <div className="flex text-gray-500 justify-start text-xs">
                      <div className="mr-2">
                        {/* <i className="ii ii-eye mr-1 text-gray-600" /> */}
                        <span className="mr-1">조회수</span>
                        {item.view}
                      </div>
                      <div className=" ">
                        {/* <i className="ii ii-message-line-outline mr-1"></i> */}
                        <span className="mr-1">댓글</span>
                        {item.comment}
                      </div>
                    </div>
                  </div>

                  {/* 
                  <div className="ellipsis  w-full mr-1 font-medium">{item.title}</div>
                  <div className="w-[110px] !text-gray-900">
                    <i className="ii ii-eye mr-1" />
                    {item.view}
                  </div>
                  <div className="w-[60px] !text-gray-900">
                    <i className="ii ii-message-line-outline mr-1"></i>
                    {item.comment}
                  </div> */}
                </Link>
              ))}
          </div>
        </Blur>
      </div>
    </div>
  );
};

export default IssueArticle;
