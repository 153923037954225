import { useState } from "react";
import { api } from "../../utils/util";
import { useLocation, useNavigate } from "react-router-dom";

import EditorAdmin from "../../component/editor_admin";
import { EDU_ROUTE } from "App";

const ClassWriteNotice = ({ cid }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const modify = location.state && location.state.content ? location.state.content : null;
  const [content, setContent] = useState(modify ? modify.content : "");
  const [title, setTitle] = useState(modify ? modify.title : "");

  const submit = async (e) => {
    e.preventDefault();

    if (title.length < 5) {
      alert("제목을 5글자 이상 입력해주세요");
      return;
    }

    const data = new FormData();
    data.append("title", title);
    content && data.append("content", content);
    content &&
      data.append(
        "text_content",
        new DOMParser().parseFromString(content, "text/html").body.textContent,
      );

    try {
      if (!modify) {
        const res = await api.post(`/class/course/${cid}/notice/write`, data);
        navigate(`/${EDU_ROUTE}/${cid}?tab=notice`, { replace: true });
        alert(res.data.msg);
      } else {
        const res = await api.post(`/class/course/notice/modify/${modify.nid}`, data);
        navigate(`/${EDU_ROUTE}/${cid}?tab=notice`, { replace: true });
        alert(res.data.msg);
      }
    } catch (e) {
      alert(e.response.data.msg);
    }
  };

  return (
    <div className="curation-write">
      <section className="board__reg notice__write">
        <div className="inner">
          <div className="reg__top__box">
            <div className="mb15"></div>
            <div className="tag-box mb70"></div>
            <div className="tit-box">
              <input
                className="width-max"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                type="text"
                placeholder="5글자 이상의 제목을 입력해주세요."
              />
            </div>
          </div>
          <div className="short-inner curation-box">
            <div className="reg__mid__box curation-editor">
              <EditorAdmin content={content} setContent={setContent} />
            </div>

            <div className="reg__menu__box">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/contents", { replace: true });
                }}
                className="menu-btn type1"
                style={{ border: "none", outline: "none" }}
              >
                취소
              </button>
              <button
                onClick={submit}
                className="menu-btn type2"
                style={{ border: "none", outline: "none" }}
              >
                {modify ? "수정" : "저장"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ClassWriteNotice;
