import { Route, Routes } from "react-router-dom";
import Klass from "./Klass";
import KlassSearch from "./KlassSearch";
import KlassLecture from "./KlassLecture";
import ChannelTalk from "component/ChannelTalk";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";

const KlassRoute: React.FC = () => {
  return (
    <section className="relative min-h-[100vh]">
      <Header current="class" />
      <article className="pb-64">
        <Routes>
          <Route path="/*" element={<Klass />} />
          <Route path="/search" element={<KlassSearch />} />
          <Route path="/course/:id" element={<KlassLecture />} />
        </Routes>
      </article>
      <Footer />
      <ChannelTalk />
    </section>
  );
};

export default KlassRoute;
