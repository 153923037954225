import { Extension } from "../core/extension";
import { CellSelection } from '@medistream/prosemirror-tables'
import { Heading } from "./heading";
import { Paragraph } from "./paragraph";

export const alignTextLeft = alignText('left')
export const alignTextCenter = alignText('center')
export const alignTextRight = alignText('right')

/**
 *
 * @param {string} direction
 * @returns {import('prosemirror-state').Command}
 */
function alignText(direction) {
  return (state, dispatch, view) => {
    let tr = state.tr
    const {selection, doc} = tr

    if (!selection || !doc) return false

    const {from, to} = selection
    const {nodes} = state.schema

    const heading = nodes[Heading.name]
    const paragraph = nodes[Paragraph.name]
    const tasks = []
    const alignment = direction
    const allowedNodeTypes = new Set([heading, paragraph])

    if (selection instanceof CellSelection) {
      selection.forEachCell((cell, pos) => {
        cell.content.forEach((node, offset) => {
          if (allowedNodeTypes.has(node.type) && node.attrs.align !== alignment) {
            tasks.push({
              node,
              pos: pos + offset + 1,
              nodeType: node.type,
            })
          }
        })
      })

      if (!tasks.length) return false

      tasks.forEach(job => {
        const {node, pos, nodeType} = job
        let {attrs} = node
        if (alignment) {
          attrs = {...attrs, align: alignment}
        } else {
          attrs = {...attrs, align: null}
        }
  
        tr.setNodeAttribute(pos, 'align', alignment)
      })
      
      dispatch(tr)
      return true
    }


    doc.nodesBetween(from, to, (node, pos, parentNode) => {
      const nodeType = node.type
      const align = node.attrs.align || null
      if (align !== alignment && allowedNodeTypes.has(nodeType)) {
        tasks.push({
          node,
          pos,
          nodeType,
        })
      }
      return true
    })

    if (!tasks.length) return false

    tasks.forEach(job => {
      const {node, pos, nodeType} = job
      let {attrs} = node
      if (alignment) {
        attrs = {...attrs, align: alignment}
      } else {
        attrs = {...attrs, align: null}
      }

      tr = tr.setNodeMarkup(pos, nodeType, attrs, node.marks)
    })

    dispatch(tr)
    return true
  }
}

export const TextAlign = Extension.Create({
  name: 'text_align',

  addCommands() {
    return {
      alignTextLeft,
      alignTextCenter,
      alignTextRight,
    }
  }
})
