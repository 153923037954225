import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../utils/util";
import AttachImages from "../../../component/shared/AttachImages";
import { useMutation } from "react-query";

const ConsultingWrite = () => {
  const { state: modify } = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState(modify ? modify.board.title : "");
  const [content, setContent] = useState(modify ? modify.board.content : "");
  const [images, setImages] = useState(modify ? modify.images.map((item) => item.link) : []);
  const cate = parseInt(useParams().cate || 0);

  const writeContent = useMutation((data) => api.post(`/board/write/${cate}`, data), {
    onSuccess: (res) => {
      alert(res.data.msg);
      navigate(`/management/consulting/article/${res.data.bid}`, { replace: true });
    },
  });
  const modifyContent = useMutation((data) => api.post(`/board/modify/${modify.board.bid}`, data), {
    onSuccess: (res) => {
      navigate(`/management/consulting/article/${res.data.bid}`, { replace: true });
    },
  });

  const submit = async () => {
    if (title.length < 5) {
      alert("5글자 이상의 제목을 입력해주세요.");
      return;
    }
    const data = {
      cate_cd: modify ? modify.board.cate_cd : cate,
      title: title,
      content: content,
      images: images.length > 0 ? images : [],
    };
    // TODO Image 삽입

    try {
      !modify ? writeContent.mutate(data) : modifyContent.mutate(data);
    } catch (err) {
      alert(err.response?.data?.msg);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="">
        <div className="wrapper">
          <section className="board__reg" style={{ marginTop: "54px", marginBottom: "54px" }}>
            <div className="inner">
              <article className="section__tit__box">
                <div className="box-flex">
                  <div className="tit-box">
                    <span className="tit-txt">문의하기</span>
                  </div>
                </div>
              </article>

              <div className="reg__top__box">
                <div className="tit-box">
                  <input
                    className="full-width"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    type="text"
                    placeholder="5글자 이상의 제목을 입력해주세요."
                  />
                </div>
              </div>

              <div className="reg__mid__box">
                <textarea
                  onChange={(e) => setContent(e.target.value)}
                  value={content}
                  placeholder="여기를 눌러 글을 작성할 수 있습니다."
                ></textarea>
              </div>
              <div className="reg__bottom__box">
                <AttachImages useImages={[images, setImages]} />
              </div>

              <div className="reg__menu__box">
                <button
                  onClick={(e) => {
                    navigate(`/management/consulting`, { replace: true });
                  }}
                  className="menu-btn type1"
                >
                  취소
                </button>
                <button onClick={submit} className="menu-btn type2">
                  {modify ? "수정" : "등록"}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ConsultingWrite;
