import CopyToClipboard from "react-copy-to-clipboard";

const ShareButton = ({ title, text, small }) => {
  if (small)
    return (
      <>
        {navigator.share ? (
          <button
            className="flex w-9 h-9 justify-center items-center text-black rounded-full text-sm hover:bg-gray-100"
            onClick={() =>
              navigator.share({
                title: title,
                text: text,
                url: window.location.href,
              })
            }
          >
            <i className="ii ii-export text-lg"></i>
          </button>
        ) : (
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => alert("링크를 복사하였습니다")}
          >
            <button className="flex w-9 h-9 justify-center items-center text-black rounded-full text-sm hover:bg-gray-100">
              <i className="ii ii-export text-lg"></i>
            </button>
          </CopyToClipboard>
        )}
      </>
    );

  return (
    <>
      {navigator.share ? (
        <button
          className="flex border-[1px] border-solid border-gray-300 text-black rounded-sm text-sm px-4 py-1 h-fit hover:bg-gray-100"
          onClick={() =>
            navigator.share({
              title: title,
              text: text,
              url: window.location.href,
            })
          }
        >
          <i className="ii ii-export mr-2"></i>
          공유
        </button>
      ) : (
        <CopyToClipboard text={window.location.href} onCopy={() => alert("링크를 복사하였습니다")}>
          <button className="flex border-[1px] border-solid border-gray-300 text-black rounded-sm text-sm px-4 py-1 h-fit hover:bg-gray-100">
            <i className="ii ii-export mr-2"></i>
            공유
          </button>
        </CopyToClipboard>
      )}
    </>
  );
};

export default ShareButton;
