import { Link } from "react-router-dom";

const ConsultingPage = () => {
  const consultingList = [
    {
      title: "메디그라운드",
      background: "/img/card_mediground.svg",
      to: "/management/consulting/mediground",
      des: "메디그라운드는 치과의사가 만든 종합 컨설팅 회사입니다. 원장님은 진료에 집중하세요. 나머진 저희가 하겠습니다.",
    },
    //광고종료 2024.03.06
    // {
    //   title: "덴탈마스터컨설팅",
    //   background: '/img/card_dentalmaster.svg',
    //   to: '/management/consulting/dentalmaster',
    //   des:'덴탈마스터컨설팅은 치과개원준비 및 치과내부 시스템과 교육을 진행하는 15년의 노하우가 있는 회사입니다.'
    // }
    // ,
    // {
    //   title: "티디컴퍼니 치과전문마케팅",
    //   background: '/img/card_td.svg',
    //   to: '/management/consulting/td',
    //   des: '티디컴퍼니는 어려운 치과 개원 시장에서 작은 힘이나마 원장님의 치과에 도움을 줄 수 있도록 진실되게 일하고 있습니다.'
    // },
    {
      title: "이앤디코퍼레이션",
      background: "/img/card_eum.svg",
      to: "/management/consulting/eum",
      des: "이앤디코퍼레이션은 20년 이상의 노하우를 가진 전문가들이 병원 개원과 경영관리를 해결해드립니다.",
    },
  ];

  return (
    <div className="">
      <div>
        <div className="wrapper expo__wrapper">
          <div className="inner">
            <div className="grid grid-cols-4 gap-4 mt-12">
              {consultingList.map(({ title, background, to, des }, i) => (
                <Link
                  key={`${to}-${i}`}
                  className=" 
                shadow-lg
              relative overflow-hidden w-full 
              rounded-md
              h-[341px]"
                  to={to}
                >
                  <img src={background} className="w-full h-full" alt="" />
                  <div
                    className="absolute top-[186px] w-full h-[158px]"
                    style={{ background: "linear-gradient(to top, #003986, transparent)" }}
                  >
                    <div className="p-[15px] mt-3">
                      <span className="text-base text-white ">{des}</span>
                      <div className="mt-3 font-bold text-lg text-white">
                        <span>{title}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}

              <Link className="shadow-lg " to="/management/consulting/goldDoctors">
                <img
                  className="w-full h-full"
                  src="https://moreden-img.s3.amazonaws.com/img/20231208/2B3163C8-D1BC-4E74-9159-FE7D2E673B75/%EC%83%81%EC%98%81%EA%B4%80%20%EC%8D%B8%EB%84%A4%EC%9D%BC%20%28%EB%8B%A4%EC%9D%B4%EC%95%84%EB%8D%B4%ED%8A%B8%29%20%282%29.png"
                  alt=""
                />
              </Link>
              <Link className="shadow-lg" to="/management/consulting/mediRise">
                <img
                  className="w-full h-full"
                  src="https://moreden-img.s3.amazonaws.com/img/20231220/767944A6-239C-4AC0-B99B-E97A29C9266A/%EC%83%81%EC%98%81%EA%B4%80%20%EC%8D%B8%EB%84%A4%EC%9D%BC%20%28PC%29.png"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultingPage;
