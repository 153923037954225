export const highlightedText = (title, q) => {
  if (!q || !title.includes(q)) return title;
  const escapedQ = escapeRegExp(q); // q를 이스케이프 처리
  const parts = title.split(new RegExp(`(${escapedQ})`, "gi"));
  return (
    <>
      {parts.map((item, idx) =>
        item.toLowerCase() === q.toLowerCase() ? (
          <span className="bg-primary-100 text-primary-800" key={idx}>
            {item}
          </span>
        ) : (
          item
        ),
      )}
    </>
  );
};

export const createHighlightedTextWrapper = (q) => {
  const highlightedTextWrapper = (title) => {
    if (!q || !title.includes(q)) return title;
    const escapedQ = escapeRegExp(q); // q를 이스케이프 처리
    const parts = title.split(new RegExp(`(${escapedQ})`, "gi"));
    return (
      <>
        {parts.map((item, idx) =>
          item.toLowerCase() === q.toLowerCase() ? (
            <span className="bg-primary-100 text-primary-800" key={idx}>
              {item}
            </span>
          ) : (
            item
          ),
        )}
      </>
    );
  };
  return highlightedTextWrapper;
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
