import Footer from "component/footer/Footer";
import Header from "component/header/Header";
import { StaticImg, resizedImg } from "utils/util";
import { Link, useNavigate } from "react-router-dom";

const UnloginChicruiting = () => {
  const navigate = useNavigate();
  return (
    <section className="relative min-h-[100vh] ">
      <Header current="chicruiting" />
      <article className="pt-10 pb-64 max-w-[1200px] mx-auto ">
        <div className="flex flex-col gap-6 items-center mb-[50px]">
          <ChicuitingLogo />
          <div className="lg:text-[40px] text-[28px] text-center text-secondary-900 font-bold">
            3만명 치과위생사 인력풀에서
            <br />
            확실한 인재를 채용해 보세요
          </div>
          <div className="text-secondary text-center">
            최대 치과위생사 플랫폼 치즈톡과 연동되어
            <br />
            치과 스탭을 가장 빠르게 채용할 수 있습니다.
          </div>
          {/* 버튼 동작 넣기 */}
          <div
            onClick={() => navigate("/login")}
            style={{ boxShadow: "0px 4px 15px 0px rgba(31, 96, 238, 0.40)" }}
            className="transition cursor-pointer ease-in duration-300 py-4 px-6 text-lg font-semibold rounded-full bg-[#3872F0] hover:bg-[#0044A7] text-white"
          >
            통합회원 전환하고 무료로 공고 등록하기
            <i className="ii ii-arrow-right ii-weight-600 text-white ml-2 text-xl"></i>
          </div>
        </div>
        <img src={resizedImg(StaticImg("cheecruit/main1.png"), 2000)} />
        <div className="px-5 lg:px-0 my-[100px] lg:my-[80px]">
          <div className="lg:text-[40px] text-[28px] mb-[48px] lg:mb-[80px] text-center text-secondary-900 font-bold">
            치크루팅에 공고를 올리면,
            <br />
            실시간으로
            <br className="lg:hidden" />{" "}
            <span className="text-[#F47B93]">치과위생사 3만명 인력풀</span>에게
            <br className="lg:hidden" /> 노출이 됩니다.
          </div>
          <div className="flex lg:flex-row flex-col gap-[52px] justify-between">
            <div className="flex flex-col lg:w-[268px]">
              <div className="bg-[#F1F3F8] w-[80px] h-[80px] rounded-full mb-7 lg:mb-10 flex justify-center items-center">
                <img src={StaticImg("cheecruit/icon1.svg")} />
              </div>
              <div className="text-[#293654] font-bold text-xl mb-3 lg:mb-5">
                치즈톡 메인 상단 노출
              </div>
              <div className="text-[#666875] text-[15px] lg:text-[16px] break-keep">
                국내 최대 치과위생사 커뮤니티 치즈톡에 공고가 노출되어, 적극 구직자 뿐만 아니라 잠재
                구직자에게도 자연스럽게 노출됩니다.
              </div>
            </div>
            <div className="flex flex-col lg:w-[268px]">
              <div className="bg-[#F1F3F8] w-[80px] h-[80px] rounded-full mb-7 lg:mb-10 flex justify-center items-center">
                <img src={StaticImg("cheecruit/icon2.svg")} />
              </div>
              <div className="text-[#293654] font-bold text-xl mb-3 lg:mb-5">
                적극적인 매칭 서비스
              </div>
              <div className="text-[#666875] text-[15px] lg:text-[16px] break-keep">
                공고만 올려두면 조건에 맞는 인재에게 면접 요청서가 발송됩니다.
              </div>
            </div>
            <div className="flex flex-col lg:w-[268px]">
              <div className="bg-[#F1F3F8] w-[80px] h-[80px] rounded-full mb-7 lg:mb-10 flex justify-center items-center">
                <img src={StaticImg("cheecruit/icon3.svg")} />
              </div>
              <div className="text-[#293654] font-bold text-xl mb-3 lg:mb-5">
                모바일 앱/웹 서비스 제공
              </div>
              <div className="text-[#666875] text-[15px] lg:text-[16px] break-keep">
                지원자의 이력서 확인, 채용공고 등록/수정 등 모바일 앱, 웹으로 언제 어디서나 편리하게
                빠른 구인이 가능합니다.
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden mb-[52px] flex text-center flex-col gap-6 my-auto text-[#2E2F3F]">
          <div className="text-[28px] font-bold">구인 광고비 0원!</div>
          <div className="text-[16px] text-[##666875] font-semibold">
            새롭게 런칭한 치크루팅의 구인 서비스는
            <br /> 무료로 제공됩니다!
          </div>
          <div className="text-[#4E516A] text-[12px]">
            모어덴의 기존 치크루팅(헤드헌팅) 유료 서비스는 동일하게
            <br />
            모어덴에서 이용이 가능합니다. 2024년 상반기 내 헤드헌팅 서비스 또한
            <br />
            새로운 치크루팅 서비스로 이관될 예정입니다.
          </div>
        </div>
        <div
          className="lg:rounded-3xl grid lg:grid-cols-2 gap-[100px] lg:px-10 py-8"
          style={{
            background: "linear-gradient(274deg, #DBE8FF 2.11%, #ECF0FE 98.66%, #EBF3FE 98.66%)",
          }}
        >
          <div className="lg:flex hidden flex-col gap-6 my-auto text-[#2E2F3F]">
            <div className="text-[32px] font-bold">구인 광고비 0원!</div>
            <div className="text-[18px]">
              새롭게 런칭한 치크루팅의 구인 서비스는 무료로 제공됩니다!
            </div>
            <div className="text-[#4E516A] text-[14px]">
              모어덴의 기존 치크루팅(헤드헌팅) 유료 서비스는 동일하게
              <br />
              모어덴에서 이용이 가능합니다. 2024년 상반기 내 헤드헌팅 서비스 또한
              <br />
              새로운 치크루팅 서비스로 이관될 예정입니다.
            </div>
          </div>
          <img src={StaticImg("cheecruit/main3.png")} />
        </div>
        <Link
          to="/recruit/chicruiting"
          className="transition cursor-pointer ease-in duration-300 mx-5 lg:mx-0 mt-8  rounded-3xl py-4 lg:py-5 px-5 lg:px-10 border-solid border-[1px] border-[#EDEAFF] hover:!bg-[#F2F0FE] items-center lg:items-start flex flex-col gap-2"
          style={{ background: "rgba(245, 244, 255, 0.70)" }}
        >
          <div className="!text-[#25242C] text-[16px] lg:text-[18px] font-bold flex items-center">
            기존 헤드헌팅 서비스 이용하기
            <i className="lg:hidden text-secondary-600 ii ii-chevron-right ii-weight-600 ml-2"></i>
          </div>
          <div className="!text-[#25242C] text-[15px] lg:text-[16px] font-medium">
            3개월 미근속 혹은 매칭 안될 경우 비용 0원
          </div>
          <div className="!text-[#84809A] text-[12px] lg:text-[14px]">
            채용 성사시(채용 후 3개월 근속)에만 70만원 이용금액 부과
          </div>
        </Link>
        <div className="flex flex-col gap-6 items-center my-[80px]">
          <div className="lg:text-[40px] text-[28px] text-center text-secondary-900 font-bold">
            새로운 치크루팅은 모든
            <br className="lg:hidden" /> 치과 종사자 채용이
            <br className="lg:hidden" /> 가능합니다!
          </div>
          <div className="text-secondary text-[16px] lg:text-[20px] text-center">
            페이닥터부터, 치과위생사, 기타 치과 스텝 직군 모두
            <br className="lg:hidden" /> 채용이 가능합니다.
          </div>
          <div
            onClick={() => navigate("/login")}
            style={{ boxShadow: "0px 4px 15px 0px rgba(31, 96, 238, 0.40)" }}
            className="transition cursor-pointer ease-in duration-300 py-4 px-6 text-lg font-semibold rounded-full bg-[#3872F0] hover:bg-[#0044A7] text-white"
          >
            지금 채용시작하기
            <i className="ii ii-arrow-right ii-weight-600 text-white ml-2 text-xl"></i>
          </div>
        </div>
      </article>
      <div className="hidden lg:block">
        <Footer />
      </div>
    </section>
  );
};

export default UnloginChicruiting;

const ChicuitingLogo = () => {
  return (
    <svg
      width="121"
      height="33"
      viewBox="0 0 121 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.946 18.0651C118.029 18.0651 117.581 17.7237 117.581 16.9131V7.44106C117.581 6.65173 118.05 6.28906 118.946 6.28906C119.864 6.28906 120.312 6.65173 120.312 7.44106V16.9131C120.312 17.7237 119.885 18.0651 118.946 18.0651ZM115 16.2091C115 17.0411 114.68 17.4251 113.912 17.4251H103.757C102.904 17.4251 102.541 17.0197 102.541 16.2304V8.18773C102.541 7.3344 102.904 6.9504 103.757 6.9504H113.293C114.061 6.9504 114.381 7.3344 114.381 8.1664C114.381 8.9984 114.061 9.3824 113.293 9.3824H105.229V10.9397H112.952C113.72 10.9397 114.04 11.3237 114.04 12.1557C114.04 12.9451 113.72 13.3291 112.952 13.3291H105.229V14.9931H113.912C114.68 14.9931 115 15.3771 115 16.2091ZM108.344 18.5984H116.322C119.074 18.5984 120.76 19.9637 120.76 22.4597C120.76 24.9771 119.032 26.3424 116.28 26.3424H108.344C105.57 26.3424 103.885 24.9771 103.885 22.4597C103.885 19.9637 105.57 18.5984 108.344 18.5984ZM108.216 23.9317H116.429C117.602 23.9317 118.114 23.2917 118.114 22.4597C118.114 21.6277 117.602 21.0091 116.429 21.0091H108.216C107.021 21.0091 106.53 21.6491 106.53 22.4597C106.53 23.2704 107.021 23.9317 108.216 23.9317Z"
        fill="#2C2C34"
      />
      <path
        d="M98.0186 17.2762H83.0639C82.1252 17.2762 81.7412 16.8922 81.7412 15.9535V12.2415C81.7412 11.3242 82.1252 10.9402 83.0639 10.9402H96.0986V9.38284H82.8506C82.0612 9.38284 81.7412 9.02017 81.7412 8.2095C81.7412 7.35617 82.0612 6.97217 82.8506 6.97217H97.3572C98.2746 6.97217 98.7226 7.3775 98.7226 8.31617V12.0708C98.7226 12.9242 98.3386 13.3082 97.4852 13.3082H84.3012V14.8655H98.0186C98.7866 14.8655 99.1279 15.2282 99.1279 16.0388C99.1279 16.8922 98.7866 17.2762 98.0186 17.2762ZM81.6132 18.9828H98.8506C99.6399 18.9828 99.9599 19.3882 99.9599 20.2415C99.9599 21.0948 99.6399 21.4788 98.8506 21.4788H91.5972V25.2335C91.5972 26.0442 91.1492 26.4068 90.2319 26.4068C89.3146 26.4068 88.8666 26.0442 88.8666 25.2335V21.4788H81.6132C80.8452 21.4788 80.5039 21.0948 80.5039 20.2415C80.5039 19.3882 80.8452 18.9828 81.6132 18.9828Z"
        fill="#2C2C34"
      />
      <path
        d="M74.4659 9.4255H61.4099C60.6419 9.4255 60.3219 9.08417 60.3219 8.2095C60.3219 7.35617 60.6419 6.97217 61.4099 6.97217H75.7033C76.8979 6.97217 77.2819 7.4415 77.2819 8.57217L77.0473 19.6655C77.0473 20.3908 76.5566 20.6895 75.6179 20.6682C74.7219 20.6468 74.2953 20.1562 74.2953 19.4095L74.3379 16.2948H61.4526C60.6846 16.2948 60.3646 15.9108 60.3646 15.0362C60.3646 14.1828 60.6846 13.7775 61.4526 13.7775H74.4019L74.4659 9.4255ZM60.2793 23.1642H77.5166C78.3059 23.1642 78.6259 23.5695 78.6259 24.4228C78.6259 25.2762 78.3059 25.6602 77.5166 25.6602H60.2793C59.5113 25.6602 59.1699 25.2762 59.1699 24.4228C59.1699 23.5695 59.5113 23.1642 60.2793 23.1642Z"
        fill="#2C2C34"
      />
      <path
        d="M45.7947 11.0253V18.4706L50.4241 23.5906C50.9147 24.1026 50.8294 24.6786 50.1681 25.2759C49.4001 25.9586 48.9734 25.8519 48.4614 25.2759L44.4507 20.7746L40.4187 25.2759C39.9067 25.8519 39.4801 25.9586 38.7121 25.2759C38.0507 24.6786 37.9654 24.1026 38.4561 23.5906L43.1067 18.4493V11.0253H39.6081C38.8187 11.0253 38.5414 10.6413 38.5414 9.78794C38.5414 8.95594 38.8187 8.57194 39.6081 8.57194H43.1067V7.12127C43.1067 6.31061 43.4907 5.90527 44.4507 5.90527C45.3894 5.90527 45.7947 6.31061 45.7947 7.12127V8.57194H49.2721C50.0614 8.57194 50.3387 8.95594 50.3387 9.78794C50.3387 10.6413 50.0614 11.0253 49.2721 11.0253H45.7947ZM54.7334 6.28927C55.6507 6.28927 56.0987 6.69461 56.0987 7.48394V25.0199C56.0987 25.8306 55.6507 26.2146 54.7334 26.2146C53.8161 26.2146 53.3681 25.8306 53.3681 25.0199V7.48394C53.3681 6.69461 53.8161 6.28927 54.7334 6.28927Z"
        fill="#2C2C34"
      />
      <rect x="0.232422" y="0.15625" width="32" height="32" rx="16" fill="#3872F0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2875 15.9174C12.2875 13.3324 14.3831 11.2368 16.9682 11.2368C18.1051 11.2368 19.1473 11.6421 19.9582 12.3162C20.3802 12.7292 20.9579 12.9838 21.5951 12.9838C22.8876 12.9838 23.9354 11.936 23.9354 10.6435C23.9354 9.85581 23.5462 9.15902 22.9497 8.73487C21.3275 7.38006 19.247 6.55615 16.9682 6.55615C11.7981 6.55615 7.6069 10.7473 7.6069 15.9174C7.6069 17.4085 7.95551 18.8181 8.57567 20.0693L7.17254 24.2787C7.02534 24.7203 7.44548 25.1405 7.8871 24.9932L11.7649 23.7006C13.2529 24.6974 15.0427 25.2787 16.9682 25.2787C19.2375 25.2787 21.3164 24.461 22.9366 23.1162C23.5405 22.693 23.9354 21.9918 23.9354 21.1983C23.9354 19.9058 22.8876 18.858 21.5951 18.858C20.9788 18.858 20.4181 19.0962 20.0001 19.4856L19.999 19.4844C19.1823 20.179 18.1242 20.5981 16.9682 20.5981C14.3831 20.5981 12.2875 18.5025 12.2875 15.9174Z"
        fill="white"
      />
    </svg>
  );
};
