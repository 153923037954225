import { useQuery, useInfiniteQuery } from "react-query";
import { getProductInfo, getProducts } from "./api";

export const useProductInfo = (productId, queryOptions) =>
  useQuery({
    queryKey: ["/market/product", productId],
    queryFn: () => getProductInfo(productId),
    refetchOnWindowFocus: false,
    enabled: !!productId,
    ...queryOptions,
  });

/**
 * @param {*} urlParams
 * - ids: productIdsString(string)
 * - offset
 * - limit
 * - curationIds
 * - productGroupIds
 * - tags
 * - ninCategoryId
 * - ninCategoryIds
 * - sort
 * - q (Meilisearch): q가 있을 경우만 Meilisearch 사용
 * - filter (Meilisearch)
 */
export const useProducts = (urlParams, queryOptions) => {
  const paramsIdsString = urlParams?.ids ? [...urlParams.ids].sort().join(",") : undefined;
  const urlParamsWithIdsString = {
    ...urlParams,
    ids: paramsIdsString,
  };

  // MARK: 검색제외 부카테고리가 설정된 상품은 결과에 포함되지 않음
  return useQuery({
    queryKey: ["/market/products", paramsIdsString],
    queryFn: () => getProducts(urlParamsWithIdsString),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
    ...queryOptions,
  });
};

export const useProductsInfinite = (urlParams, queryOptions) => {
  const {
    startPage = 1,
    limit = 40,
    sort = ["three_months:desc"],
    tag = [],
    curationIds = [],
    productGroupIds = [],
    q,
  } = urlParams;
  const queryParams = {
    limit,
    sort,
    tag,
    curationIds: urlParams?.curationIds,
    productGroupIds: urlParams?.productGroupIds,
    q,
  };

  const curationIdsString = [...curationIds].sort().join(",");
  const productGroupIdsString = [...productGroupIds].sort().join(",");

  return useInfiniteQuery({
    queryKey: [
      "market/products",
      curationIdsString,
      productGroupIdsString,
      sort.join(","),
      [...tag].sort().join(","),
      q,
    ],
    queryFn: ({ pageParam = startPage }) =>
      getProducts({ offset: (pageParam - 1) * limit, ...queryParams }),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.query) {
        // MARK: queryParams에 q를 넣고 meiliSearch를 사용한 응답을 받는 경우
        const hasMorePage = lastPage.offset + lastPage.limit < lastPage.estimatedTotalHits;
        if (hasMorePage) {
          const nextPage = allPages.length + startPage;
          return nextPage;
        }
        return undefined;
      }
      if (!lastPage?.query) {
        // MARK: 검색어 사용하지 않고 cezerin을 사용한 응답을 받는 경우
        if (lastPage.has_more) {
          const nextPage = allPages.length + startPage;
          return nextPage;
        }
        return undefined;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 1,
    cacheTime: 1000 * 60 * 5, // default가 이미 5분
    ...queryOptions,
  });
};
