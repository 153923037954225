import { useRef, useState } from "react";
import { api } from "../../utils/util";

const AddressSelect = ({ state, setState }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const initialState = state;

  const search = async () => {
    await api
      .get("/util/address", {
        params: {
          q: searchKeyword,
        },
      })
      .then((res) => {
        setState({
          ...state,
          keyword: searchKeyword,
          load: true,
          addressInfo: res.data,
          selectedItem: undefined,
        });
      })
      .catch((error) => {
        console.log(error.data);
        if (error) alert("우편번호가 존재하지 않습니다.");
      });
  };

  const exitPopup = () => {
    setState({ ...initialState, view: false });
  };

  const handleClickAdress = () => {
    if (state.selectedItem && state.selectedItem.detail === undefined) {
      alert("상세주소를 입력해주세요");
      return;
    }
    setState({ ...state, view: false });
  };
  return (
    <article className="popup popup-addr" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="head">
          <div className="popup-tit">
            <i className="ic ic-logo"></i>
            <span className="txt">주소검색</span>
          </div>

          <button className="close-btn" onClick={exitPopup}></button>
        </div>
        <div className="body">
          <article className="pop__addr">
            <div className="choice__group">
              <button className="choice-btn active">도로명 주소</button>
              {false && <button className="choice-btn">지번 주소</button>}
            </div>

            <div className="input__group">
              <div className="info-txt">
                찾으시려는 <span className="accent">도로명주소+건물번호/건물명</span> 을
                입력해주세요.
                <br />
                예) 도로명 : 불정로 432번길 <br />
                * 단, 도로명 혹은 동(읍/면/리)만 검색하시는 경우 정확한 검색결과가 나오지 않을 수
                있습니다.
                <br />
              </div>

              <form
                className="input-box"
                onSubmit={(e) => {
                  search();
                  e.preventDefault();
                }}
              >
                <input type="text" onChange={(e) => setSearchKeyword(e.target.value)} />
                <button className="search-btn">검색</button>
              </form>
            </div>

            {state.load && (
              <div className="result__group" style={{ borderBottom: "none" }}>
                <div className="result__data__group">
                  {/* {state.load &&
                  <div className="result-txt">
                    <span className="accent">‘용현로 20’</span> 검색 결과입니다.
                  </div>
                } */}
                  <div className="result-txt">
                    <span className="accent">‘{state.keyword}’</span> 검색 결과입니다.
                  </div>

                  <ul className="result-list">
                    {state.addressInfo.items.map((item) => {
                      return (
                        <li key={item.address}>
                          <button
                            className={item === state.selectedItem ? "on" : ""}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedItem: { ...item, detail: undefined },
                              });
                            }}
                          >
                            <div className="row">
                              <div className="subject">도로명</div>
                              <div className="con">{item.address}</div>
                            </div>
                            <div className="row">
                              <div className="subject">지번</div>
                              <div className="con">{item.addrjibun}</div>
                            </div>
                            <div className="post-num">{item.postcd}</div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </article>
        </div>
        <div className="sticky bg-white flex flex-col border-solid border-t-[1px] border-gray-200">
          {state.selectedItem && (
            <div className="address result-form">
              <div className="form-txt">
                <div className="txt accent">
                  실제 배송은 선택하신 도로명주소로 진행되며, 상세주소는 도로명주소에 맞게
                  입력해주세요.
                </div>
              </div>

              <div className="form-box">
                <div className="row">
                  <div className="subject">기본주소</div>
                  <div className="con">{state.selectedItem.address}</div>
                </div>
                <div className="row">
                  <div className="subject">상세주소</div>
                  <div className="con">
                    <input
                      placeholder="도로명 주소를 입력해주세요"
                      value={state && state.selectedItem.detail}
                      onChange={(e) =>
                        setState({
                          ...state,
                          selectedItem: {
                            ...state.selectedItem,
                            detail: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-5 py-3 justify-center">
            <button
              className="bg-black text-white text-xs py-1 px-3 rounded hover:bg-[#000]"
              onClick={() => {
                handleClickAdress();
              }}
            >
              주소입력
            </button>
            <button
              className="bg-gray-300 text-white text-xs py-1 px-5 rounded hover:bg-gray-500"
              onClick={exitPopup}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AddressSelect;
