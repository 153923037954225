import React from "react";
import { Link } from "react-router-dom";
import { Img, outResource } from "../../utils/util";

function Footer() {
  return (
    <footer className="hidden lg:block w-100vw">
      <div className="footer__wrap_2">
        <div className="top">
          <div className="left">
            <img src={Img("", "img/footer_logo.svg")} alt="" />
            <div className="txt">치과의사만의 커뮤니티, 그 이상</div>
          </div>
          <div className="right">
            <div className="box">
              <div>(주)데니어</div>
              <div>
                <b>대표이사</b> 송언의
              </div>
            </div>
            <div className="box address">
              <div className="address_wrapper">
                <b className="title">주소</b>
                <div className="value">
                  전북특별자치도 전주시 덕진구 기린대로 886 2층 201호(여의동2가)
                </div>
              </div>
              <div>
                <b>전화번호</b> 02-2281-2045
              </div>
            </div>
            <div className="box">
              <div>
                <b>사업자등록번호</b> 349-86-02042
              </div>
              <div>
                <b>통신판매업 신고번호</b> 2022-전주덕진-0434
              </div>
            </div>
            <div className="box">
              <div>
                <b>직업정보제공사업신고</b> J1611020230003
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="footer_copyright">
            Copyright ⓒDENEER Corp. All Rights Reserved.
            <span>문의 cs@deneer.co.kr</span>
          </div>
          <div className="right">
            <div className="footer__menu">
              <div
                className="lnline-block cursor-pointer"
                onClick={() =>
                  outResource("https://www.ftc.go.kr/bizCommPop.do?wrkr_no=3498602042")
                }
              >
                사업정보
              </div>
              <Link to="/footer/service">이용약관</Link>
              <Link to="/footer/privacy">개인정보처리방침</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
