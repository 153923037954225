import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  location1,
  location2,
  majorOptions,
  timeOptions,
} from "../../constants";

function FilterBox() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const major = urlParams.get("major") || "";
  const workingTime = urlParams.get("working_time") || "";
  const loc1 = urlParams.get("loc1") || "";
  const loc2 = urlParams.get("loc2") || "";
  const wage = urlParams.get("wage") / 10000 || "";
  const [wageText, setWageText] = useState(wage);
  const [info, setInfo] = useState({
    major: major ? majorOptions.find((option) => option.value === major) : "",
    workingTime: workingTime
      ? timeOptions.find((option) => option.value === workingTime)
      : "",
    loc1: loc1 ? loc1 : "",
    loc2: loc2 ? loc2.split(",") : [],
    wage: wage ? wage : "",
  });
  const [toggle, setToggle] = useState({
    major: false,
    time: false,
    loc1: false,
    wage: true,
  });

  const doneButton = () => {
    // 선택된 조건 검색
    info.major?.value
      ? urlParams.set("major", info.major.value)
      : urlParams.delete("major");
    info.wage
      ? urlParams.set("wage", info.wage * 10000)
      : urlParams.delete("wage");
    info.workingTime?.value
      ? urlParams.set("working_time", info.workingTime.value)
      : urlParams.delete("working_time");
    info.loc1 ? urlParams.set("loc1", info.loc1) : urlParams.delete("loc1");
    !!info.loc2?.length
      ? urlParams.set("loc2", info.loc2.join(","))
      : urlParams.delete("loc2");
    urlParams.set("page", 1);
    navigate(`${location.pathname}?${urlParams}`);
    window.scrollTo(0, 0);
  };

  const resetButton = () => {
    setInfo({
      major: "",
      workingTime: "",
      loc1: "",
      loc2: [],
      wage: "",
    });

    window.scrollTo(0, 0);
  };

  return (
    <article
      className="selector-tabs"
      style={{ marginTop: "52px", marginBottom: "30px" }}
    >
      <div className="inner">
        <div className="selector-tabs-wrap">
          <div className="top-tabs">
            <button
              data-tabs="tabs3"
              onClick={() =>
                setToggle({
                  major: false,
                  time: false,
                  loc1: true,
                  wage: false,
                })
              }
              className={toggle.loc1 ? "top-tabs-btn on" : "top-tabs-btn"}
            >
              <span className="txt">지역</span>
            </button>
            <button
              data-tabs="tabs1"
              onClick={() =>
                setToggle({
                  major: true,
                  time: false,
                  loc1: false,
                  wage: false,
                })
              }
              className={toggle.major ? "top-tabs-btn on" : "top-tabs-btn"}
            >
              <span className="txt">전공</span>
            </button>
            <button
              data-tabs="tabs2"
              onClick={() =>
                setToggle({
                  major: false,
                  time: true,
                  loc1: false,
                  wage: false,
                })
              }
              className={toggle.time ? "top-tabs-btn on" : "top-tabs-btn"}
            >
              <span className="txt">근무형태</span>
            </button>
            <button
              data-tabs="tabs4"
              onClick={() =>
                setToggle({
                  major: false,
                  time: false,
                  loc1: false,
                  wage: true,
                })
              }
              className={toggle.wage ? "top-tabs-btn on" : "top-tabs-btn"}
            >
              <span className="txt">급여</span>
            </button>
          </div>

          <div className="tabs__group">
            {toggle.major && (
              <div className="tabs__box" data-tabs="tabs1">
                <div className="item__list">
                  {majorOptions.map((item) => {
                    return (
                      // a태그에서 버튼태그로 바꾸니 회색이 되어서 스타일에 하얀색 줌
                      <button
                        key={item.value}
                        onClick={() => setInfo({ ...info, major: item })}
                        className={
                          info.major === item ? "item-btn on" : "item-btn"
                        }
                      >
                        {item.label}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}

            {toggle.time && (
              <div className="tabs__box" data-tabs="tabs1">
                <div className="item__list">
                  {timeOptions.map((item) => {
                    return (
                      <button
                        key={item.value}
                        onClick={() => setInfo({ ...info, workingTime: item })}
                        className={
                          info.workingTime === item ? "item-btn on" : "item-btn"
                        }
                      >
                        {item.label}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}

            {toggle.loc1 && (
              <div className="tabs__box" data-tabs="tabs1">
                <div className="item__list">
                  {location1.map((item, i) => {
                    return (
                      <button
                        key={i}
                        onClick={() => {
                          setInfo({ ...info, loc1: item, loc2: [] });
                          // setLoc2([]);
                        }}
                        className={
                          info.loc1 === item ? "item-btn on" : "item-btn"
                        }
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {toggle.loc1 && info.loc1 && (
            <div className="tabs__group">
              <div className="tabs__box" data-tabs="tabs1">
                <div className="item__list">
                  {location2[info.loc1].map((item, i) => {
                    return (
                      <button
                        key={i}
                        // onClick={() => setLoc2([...loc2, item])}
                        onClick={() =>
                          setInfo({ ...info, loc2: [...info.loc2, item] })
                        }
                        className={
                          info.loc2.find((value) => value === item) === item
                            ? "item-btn on"
                            : "item-btn"
                        }
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {toggle.wage && (
            <div className="tabs__box" data-tabs="tabs1">
              <div className="wage__box">
                <span>월급</span>
                <input
                  type="number"
                  value={wageText}
                  onChange={(e) => setWageText(e.target.value)}
                  placeholder="0"
                />
                <span>만원 이상</span>
                <button onClick={() => setInfo({ ...info, wage: wageText })}>
                  추가
                </button>
              </div>
            </div>
          )}

          {(info.major || info.loc1 || info.workingTime || info.wage) && (
            <div className="tabs__result">
              <div className="result-list">
                {info.major && (
                  <div className="result-item">
                    <span className="txt">{info.major.label}</span>
                    <button
                      className="remove-btn"
                      onClick={() => setInfo({ ...info, major: null })}
                    ></button>
                  </div>
                )}
                {info.workingTime && (
                  <div className="result-item">
                    <span className="txt">{info.workingTime.label}</span>
                    <button
                      className="remove-btn"
                      onClick={() => setInfo({ ...info, workingTime: null })}
                    ></button>
                  </div>
                )}
                {info.wage && (
                  <div key={info.wage} className="result-item">
                    <span className="txt">월급 {info.wage}만원 이상</span>
                    <button
                      className="remove-btn"
                      onClick={() => {
                        setInfo({ ...info, wage: null });
                      }}
                    ></button>
                  </div>
                )}
                {info.loc1 && (
                  <div key={info.loc1} className="result-item">
                    <span className="txt">{info.loc1}</span>
                    <button
                      className="remove-btn"
                      onClick={() => {
                        setInfo({ ...info, loc1: null, loc2: [] });
                      }}
                    ></button>
                  </div>
                )}
                {info.loc2 &&
                  info.loc2.length > 0 &&
                  info.loc2.map((item, i) => {
                    return (
                      <div key={i} className="result-item">
                        <span className="txt">{item}</span>
                        <button
                          className="remove-btn"
                          onClick={() =>
                            // setLoc2(info.loc2.filter((value) => value !== item))
                            setInfo({
                              ...info,
                              loc2: info.loc2.filter((value) => value !== item),
                            })
                          }
                        ></button>
                      </div>
                    );
                  })}
              </div>

              <button className="reset-search-btn" onClick={resetButton}>
                <img
                  src={`${
                    import.meta.env.VITE_APP_PUBLIC_URL
                  }img/ico-refresh-01-white.svg`}
                  alt=""
                />
                초기화
              </button>
              <button className="result-search-btn" onClick={doneButton}>
                선택된 조건 검색
              </button>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}

export default FilterBox;
