import { PuffLoader } from "react-spinners";

const Loading = ({ small }) => {
  if (small) {
    <PuffLoader color="#4a25aa" />;
  }
  return (
    <div className="loading_box">
      <div className="loading">
        <PuffLoader color="#4a25aa" />
      </div>
    </div>
  );
};

export default Loading;
