import { Link } from "react-router-dom";
import { AdsCampaign } from "../../types/advertisement";
import { resizedImg } from "../../utils/util";

interface StaticBannerProps {
  ads: AdsCampaign;
  bannerWidth?: string | null;
  bannerHeight?: string | null;
  name: string;
}

function StaticBanner(props: StaticBannerProps) {
  const { ads, name, bannerHeight, bannerWidth } = props;

  const {
    inventoryWidth: width,
    inventoryHeight: height,
    segment,
    link,
    campaignName,
    advertisementFile: { url },
  } = ads;

  const bannerClicked = () => {
    if (!segment) return;
    window.analytics.track("Ads Clicked", segment);
  };

  const tmpLink = (() => {
    if (link.indexOf('http') === 0) {
      return link;
    }
    if (link === "/") {
      return "https://market.moreden.co.kr/";
    }
    if (link.includes("/product/")) {
      return `https://market.moreden.co.kr${link}`;
    }
    return link;
  })(); //FIXME: 추후 서브도메인 구분 가능할 경우 해당 로직 삭제

  return (
    <div>
      {/* 홈 프로모션 배너의 경우 제목을 노출해야한다 */}
      {name === "pc_home_top_promotion" && (
        <span className="flex justify-between items-center pb-6 bg-white">
          <b className="lg:text-[20px] text-[18px]">{campaignName}</b>
        </span>
      )}
      <Link className="inline-block" to={tmpLink} onClick={bannerClicked}>
        <img
          className={"rounded-md"}
          src={resizedImg(url, width * 2)}
          style={{
            objectFit: "cover",
            width: bannerWidth === "full" ? "auto" : `${width}px`,
            height: bannerHeight === "full" ? "auto" : `${height}px`,
            objectPosition: "center top",
          }}
        />
      </Link>
    </div>
  );
}

export default StaticBanner;
