import { Img } from "../utils/util";
import dayjs from "dayjs";
import RollingBanner from "./advertisment/rolling";
import { useAdsCampaign, useAdsInventoryList } from "../api/banner/hooks";

function Modal() {
  const name = "pc_home_popup_mini";

  const { data: adsInventoryList } = useAdsInventoryList({
    pageName: "home",
  });

  const { data: adsCampaignList, isLoading } = useAdsCampaign({
    name,
    adsInventoryList,
    pageName: "home",
  });

  const setCookie = (name, value, expireHours) => {
    document.cookie = `${name}=${value}; path=/; expires=${dayjs()
      .add(expireHours, "h")
      .toDate()
      .toUTCString()};`;
  };

  const closeModal = (expireHours) => {
    setCookie("surveycookie", "done", expireHours);
    document.getElementById("modal").style.display = "none";
  };

  if (isLoading || !adsCampaignList || adsCampaignList?.length === 0) return <></>;

  return (
    <div id="modal">
      <div className="modal-overlay" onClick={() => closeModal(4)}></div>
      <div className="modal">
        <div className="w-[318px] h-[243px]">
          <RollingBanner
            identifier={name}
            data={adsCampaignList}
            customControlPanel={
              <div
                className={`swiper-pagination text-xs text-white bg-black opacity-80 px-3 py-1 !w-[50px] !h-[25px] !top-0 !left-[100%] !translate-x-[-100%] ${name}-fraction`}
              />
            }
          />
        </div>
        <div className="bottom_box">
          <span className="cursor" onClick={() => closeModal(72)}>
            3일간 보지 않기
          </span>
          <span className="close cursor" onClick={() => closeModal(4)}>
            닫기
            <img src={Img("", "img/ico-x.svg")} alt="X" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Modal;
