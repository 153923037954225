import { CLASS_ROUTE } from "App";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const CoursesSearchForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q") || "";
  const [searchKeyword, setSearchKeyword] = useState<string>(q);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    setSearchKeyword(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchKeyword === "") {
      navigate(`/${CLASS_ROUTE}`);
      return;
    }
    navigate(`/${CLASS_ROUTE}/search?q=${searchKeyword}`);
  };

  return (
    <form className="relative" onSubmit={handleSubmit}>
      <i className="ii ii-search z-[10] absolute top-[10px] left-[12px] h-[20px] leading-[20px] text-[20px] text-gray-800"></i>
      <input
        className="w-full bg-gray-50 focus:bg-white focus:drop-shadow-md h-[40px] px-[40px] rounded-md text-[15px] "
        type="text"
        value={searchKeyword}
        placeholder="검색어를 입력해주세요."
        onChange={handleChange}
        onBlur={(e) => {
          if (e.target.value) return;
          navigate(`/${CLASS_ROUTE}`);
        }}
      />
      {searchKeyword && (
        <button
          className="absolute top-0 right-0 w-[40px] h-[40px]"
          type="button"
          onClick={() => {
            setSearchKeyword("");
            navigate(`/${CLASS_ROUTE}`);
          }}
        >
          <i className="ii ii-remove text-[20px] text-gray-800"></i>
        </button>
      )}
      <button className="hidden" type="submit">
        검색
      </button>
    </form>
  );
};

export default CoursesSearchForm;
