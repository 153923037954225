import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { tokenState } from "../../state";
import { useRecoilValue } from "recoil";
import { api, watermarkOnImg } from "../../utils/util";
import { useLocation, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ZoomImg from "../../component/ZoomImg";
import ProseMirrorEditorView from "component/prosemirror/ProseMirrorEditorView";

function ForumDetailContent({ content, likeClicked, scrapClicked, setReportPopup }) {
  const token = useRecoilValue(tokenState);
  const location = useLocation();
  const bid = parseInt({ ...useParams() }.bid || 0);

  const addImgWatermark = content?.forum?.content.replaceAll(
    /<img [^>]*src="[^"]*"[^>]*>/gm,
    `<div class="img-box">$&<div class="watermark" style="background: ${watermarkOnImg(
      token,
    ).replaceAll('"', "\\'")}; background-repeat: space"></div></div>`,
  );

  const addImgWatermarkPM = content?.forum?.content.replaceAll(
    /<img [^>]*src="[^"]*"[^>]*>/gm,
    `<span style="position: relative; display:block;">$&<span class="watermark" style="background: ${watermarkOnImg(
      token,
    ).replaceAll('"', "\\'")}; background-repeat: space"></span></span>`,
  );

  const getLikeInfo = (cid) => {
    const info = content.likes && content.likes.find((like_info) => like_info.cid === cid);
    return info && info.liked;
  };

  const selectArticle = () => {
    if (token.user_id !== "admin") return;

    api
      .post(`/forum/selected/${bid}`)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  return (
    <>
      <div className="txt-box">
        {content.forum.report_count >= 10 && (
          <div className="reported">
            신고가 10회 이상 누적되어 블라인드 처리 고려 중인 글입니다.
          </div>
        )}
        {content.forum.reported && "다수의 신고로 블라인드 처리된 게시글입니다."}
        {content.forum?.contentJson ? (
          <ZoomImg>
            <ProseMirrorEditorView contentString={addImgWatermarkPM} />
          </ZoomImg>
        ) : (
          <ZoomImg>
            <div
              id="froala_view"
              onContextMenu={(e) => {
                e.target.matches("img") && e.preventDefault();
              }}
            >
              <FroalaEditorView model={addImgWatermark} />
            </div>
          </ZoomImg>
        )}
      </div>
      <div className="menu-box-list like">
        <button
          className={getLikeInfo(null) ? "like-box on" : "like-box"}
          onClick={() => likeClicked(null, getLikeInfo(null))}
        >
          <div className={getLikeInfo(null) ? "like-btn-n on" : "like-btn-n"}></div>
          <span className="menu-txt">추천</span>
          <span className="count_n">{content.forum.like}</span>
        </button>
      </div>
      <div className="menu-box-list">
        {token.user_id === "admin" && (
          <button
            className={content.forum.admin_selected_at ? "menu-box on" : "menu-box"}
            onClick={selectArticle}
          >
            <div
              className={content.forum.admin_selected_at ? "scrap-btn-n on" : "scrap-btn-n"}
            ></div>
            <span className="menu-txt">선정</span>
          </button>
        )}
        {!content.ismine && (
          <>
            <button className={content.scraped ? "menu-box on" : "menu-box"} onClick={scrapClicked}>
              <div className={content.scraped ? "scrap-btn-n on" : "scrap-btn-n"}></div>
              <span className="menu-txt">스크랩</span>
            </button>
            <button
              className="menu-box"
              onClick={() => setReportPopup({ category: "forum", toggle: true })}
            >
              <div className="police-btn-n on"></div>
              <span className="menu-txt">신고</span>
            </button>
          </>
        )}
        {navigator.share ? (
          <button
            className="menu-box"
            onClick={() =>
              navigator.share({
                title: content.forum.title,
                text: `[모어덴 - ${content.cate_name} 임상포럼]\n${content.forum.title}`,
                url: location.pathname,
              })
            }
          >
            <div className="share-btn-n"></div>
            <span className="menu-txt">공유</span>
          </button>
        ) : (
          <CopyToClipboard
            text={`https://www.moreden.co.kr${location.pathname}`}
            onCopy={() => alert("링크를 복사하였습니다")}
          >
            <button className="menu-box">
              <div className="share-btn-n"></div>
              <span className="menu-txt">공유</span>
            </button>
          </CopyToClipboard>
        )}
      </div>
    </>
  );
}

export default ForumDetailContent;
