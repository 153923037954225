import { useEffect } from "react";

const KakaoMap = ({ lati, long }) => {
  let mapContainer = document.getElementById("kakao-map-box");

  useEffect(() => {
    if (!document.body.contains(document.getElementById("kakao-map-script"))) {
      const script = document.createElement("script");
      script.async = true;
      script.id = "kakao-map-script";
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?appkey=b01f3e9b5a6b7407188f65a1c8180879&autoload=false";
      document.body.append(script);
    }
  }, []);

  useEffect(() => {
    if (mapContainer && window.kakao && window.kakao.maps) {
      window.kakao.maps.load(() => {
        let options = {
          center: new window.kakao.maps.LatLng(lati, long),
          level: 7,
        };
        let map = new window.kakao.maps.Map(mapContainer, options);
        let marker = new window.kakao.maps.Marker({
          position: new window.kakao.maps.LatLng(lati, long),
        });
        marker.setMap(map);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContainer, lati, long]);

  return <div id="kakao-map-box" style={{ width: "800px", height: "500px" }}></div>;
};

export default KakaoMap;
