import CourseCard from "component/klass/CourseCard";
import { cls, publicApi } from "../../utils/util";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { CLASS_ROUTE } from "App";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { useState } from "react";
import { useHomeSegment } from "hooks/useHomeSegment";

interface curation {
  courseId: string;
  title: string;
  thumbnailImage: {
    isPublic: boolean;
    link: string;
    name: string;
  };
  lecturerNames: string[];
  products: {
    regularPrice: number;
    salePrice: number;
  }[];
  badges: string[];
  groupedBadges: {
    level?: string[];
    price?: string[];
    toothNumbers?: string[];
    topics?: string[];
    isClosed?: boolean;
  };
}

const ClassCuration = () => {
  const { sendTrackingEvent } = useHomeSegment();
  const { data: curations, isLoading: curationsLoading } = useQuery("KlassCurations", () =>
    publicApi?.get("/class/public/curations").then((res) => {
      return res.data.curations;
    }),
  );
  const [swiperId] = useState<string>(Math.random().toString(36).substring(2, 7));
  const [targetCuration, setTargetCuration] = useState<number>(0);

  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <h2 className="font-bold text-lg">모어덴 클래스</h2>
        <Link
          to={`/${CLASS_ROUTE}`}
          className="cursor-pointer text-[13px] text-gray-600 hover:underline z-10"
        >
          +더보기
        </Link>
      </div>
      <div className="scroll_none overflow-x-scroll flex flex-nowrap mt-5 mb-2.5">
        {!curationsLoading &&
          curations &&
          curations.slice(0, 3).map((item: curation, idx: number) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  setTargetCuration(idx);
                }}
                className={cls(
                  idx === targetCuration ? "bg-gray-900 text-white" : "bg-white",
                  "cursor-pointer shadow-md inline-block py-1 px-3 rounded-md mr-4 mb-2 whitespace-nowrap z-10",
                )}
              >
                {item.title}
              </div>
            );
          })}
      </div>
      <div
        onClick={() => sendTrackingEvent({ name: "클래스" })}
        id={`swiper-${swiperId}`}
        className="relative"
      >
        {!curationsLoading && curations && curations[targetCuration] && (
          <>
            <Swiper
              modules={[Autoplay, Navigation]}
              loop={true}
              slidesPerView={2}
              spaceBetween={20}
              className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events pb-3"
              parallax={true}
              // slideToClickedSlide={true}
              navigation={{
                nextEl: `#swiper-${swiperId} .class-swiper-next-btn`,
              }}
            >
              {curations[targetCuration].courses?.map((item: any, idx: number) => {
                const course =
                  item.courses?.find((course: any) => course.courseId === item.defaultCourseId) ||
                  item;
                return (
                  <SwiperSlide key={idx}>
                    <Link className="block w-full" to={`/${CLASS_ROUTE}/course/${course.courseId}`}>
                      <CourseCard
                        thumbnail={course.thumbnailImage.link}
                        lecturers={course.lecturerNames}
                        title={course.title}
                        regularPrice={course.products[0]?.regularPrice}
                        salePrice={course.products[0]?.salePrice}
                        topic={course.groupedBadges.topics}
                        level={course.groupedBadges.level?.join(",")}
                        isSwiper
                        isFree={course.isFree}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <button className="class-swiper-next-btn absolute w-9 h-9 rounded-full shadow-custom z-20 -right-2 top-[72px] bg-white">
              <i className="ii-chevron-right"></i>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ClassCuration;
