import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../../component/Loading";
import { BASE_URL } from "../../utils/util";

function FindId(props) {
  const [sms, setSms] = useState(false);
  const [phone, setPhone] = useState("");
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [number, setNumber] = useState(null);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (time > 0) {
      const timeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      setTimer(timeout);
    }
  }, [time]);

  const sendNumber = () => {
    const data = {
      phone: phone,
    };
    setLoading(true);

    if (phone.length < 10) return;
    if (timer) {
      clearTimeout(timer);
    }
    axios
      .post(`${BASE_URL}/user/findId`, data)
      .then((res) => {
        setSms(true);
        setTime(180);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.response.data.msg);
        setLoading(false);
      });
  };

  const checkNumber = () => {
    const data = {
      number: number,
    };
    axios
      .post(`${BASE_URL}/user/findIdVerify`, data)
      .then((res) => {
        setId(res.data.id);
        setName(res.data.name);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  return (
    <div className="container full">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`} alt="로고" />
          </div>

          {loading && <Loading />}
          <div className="member__con">
            <div className="member__tit">
              <div className="tit-txt">아이디 찾기</div>
            </div>

            <div className="join-form">
              <div className="row">
                <div className="tbox full">
                  <input
                    type="tel"
                    placeholder="휴대폰 번호를 입력하세요"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <button
                  className={"send-btn btn big full" + (phone.length >= 10 ? " on" : "")}
                  onClick={sendNumber}
                >
                  인증번호 전송
                </button>
              </div>

              {sms && (
                <div className="row">
                  <div className="tbox with-btn with-timer">
                    <input
                      placeholder="인증번호를 입력하세요."
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <span className="timer">
                      {Math.floor(time / 60)}:{String(time % 60).padStart(2, "0")}
                    </span>
                    <button className="tbox-btn" onClick={checkNumber}>
                      확인
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="join-form" style={{ marginTop: "216px" }}>
              {id && (
                <div className="result-txt">
                  {name}님의 아이디는 <b>[{id}]</b>입니다.
                </div>
              )}
              <div className="login-btn-box">
                <Link to="/login" className={"login-btn" + (id ? " on" : "")}>
                  로그인
                </Link>
              </div>
              <div className="row">
                <Link to="/findpw" className="complete-btn">
                  비밀번호 찾기
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FindId;
