import { Link } from "react-router-dom";
import { Img, resizedImg, cls } from "../../utils/util";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ShareButton from "component/shared/ShareButton";

const ContentsListTable = ({ articles, param }) => {
  const tag = param && param.get("tag");
  const q = param && param.get("q");

  const highlightedText = (title, q) => {
    if (!q || !title.includes(q)) return title;
    const parts = title.split(new RegExp(`(${q})`, "gi"));
    return (
      <>
        {parts.map((item, idx) =>
          item.toLowerCase() === q.toLowerCase() ? (
            <span className="bg-[#fffade] text-primary-800" key={idx}>
              {item}
            </span>
          ) : (
            item
          ),
        )}
      </>
    );
  };

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
      {articles &&
        articles.map((item) => {
          return (
            <div className={cls(item.published_at ? "" : "bg-gray-100 rounded-md")} key={item.bid}>
              <Link
                className="w-full group inline-block"
                to={`/contents/article/${item.bid}?${param}`}
              >
                <div className="lg:h-full rounded-md aspect-contents overflow-hidden bg-gray-100">
                  {item.thumbnail && (
                    <img
                      className="w-full h-full group-hover:scale-105 transition-all duration-300"
                      src={resizedImg(Img(item.thumbnail), 600)}
                      alt=""
                    />
                  )}
                </div>
                <div className="text-xl my-2 group-hover:text-primary-800 font-semibold truncate">
                  {highlightedText(item.title, q)}
                </div>
              </Link>
              <div className="flex justify-between items-center mt-3">
                <div className="flex">
                  {item.tag
                    .split(",")
                    .slice(0, 3)
                    .map((keyword) => {
                      return (
                        <Link
                          to={`/contents/search?tag=${keyword}`}
                          className="inline-block truncate lg:text-tiny bg-gray-100 !text-gray-600 rounded-sm p-1 px-2 mr-3 lg:mt-auto cursor-pointer hover:bg-primary-100 hover:!text-primary-800"
                          key={keyword}
                        >
                          {keyword}
                        </Link>
                      );
                    })}
                </div>
                <ShareButton
                  title={item.title}
                  text={`[모어덴 - 콘텐츠]\n${item.title}`}
                  small={true}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ContentsListTable;
