import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import AttachImages from "../../../component/shared/AttachImages";
import { api } from "../../../utils/util";
import RegionFilter from "../RegionFilter";

function UsedMarketWritePage(props) {
  const navigate = useNavigate();
  const { state: modify } = useLocation();
  const { bid: article_num } = useParams();

  const [title, setTitle] = useState(modify ? modify.article.title : "");
  const [content, setContent] = useState(modify ? modify.article.content : "");
  const [price, setPrice] = useState(modify ? modify.article.price : null);
  const [loc, setLoc] = useState({
    view: false,
    loc1: modify ? modify.article.location1 : null,
    loc2: modify ? [modify.article.location2] : [],
  });
  const [images, setImages] = useState(modify ? modify.images.map((item) => item.link) : []);

  const submit = async () => {
    if (price == null) {
      alert("금액을 입력해주세요");
      return;
    }
    if (!loc.loc1) {
      alert("지역을 선택해주세요");
      return;
    }
    if (title.length < 5) {
      alert("제목은 5글자 이상 입력해야 합니다");
      return;
    }
    if (content.length < 3) {
      alert("내용은 3글자 이상 입력해야 합니다");
      return;
    }
    const data = {
      title: title,
      content: content,
      price: price,
      location1: loc.loc1,
      location2: loc.loc2.join(","),
      images: images.length > 0 ? images : [],
    };
    try {
      if (!modify) {
        await api
          .post(`/management/market/write`, data)
          .then((res) => {
            navigate(`/management/market/${res.data.bid}`, { replace: true });
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      } else {
        await api
          .post(`/management/market/modify/${article_num}`, data)
          .then((res) => {
            navigate(`/management/market/${res.data.bid}`, { replace: true });
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    } catch (err) {
      alert(err.response.data.msg);
    }
  };

  return (
    <div className="">
      <div className="wrapper">
        <section className="board__reg" style={{ marginTop: "54px", marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">중고장터</span>
              </div>
            </article>

            <div className="reg__top__box">
              <div className="row-list">
                <div className="tit-box">
                  <div className="tbox full">
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="title"
                      type="text"
                      placeholder="제목을 입력해주세요."
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "21px" }}>
                  <div className="location-box">
                    <div className="item">
                      <button
                        onClick={() => {
                          loc.view
                            ? setLoc({ ...loc, view: false })
                            : setLoc({ ...loc, view: true });
                        }}
                        className="select-btn"
                      >
                        {loc.loc1 ? loc.loc1 + " - " + loc.loc2[0] : "거래 위치를 선택해주세요."}
                      </button>
                      {loc.view && <RegionFilter useLoc={[loc, setLoc]} single />}
                    </div>
                    <div className="item" style={{ marginLeft: "20px" }}>
                      <div className="tbox">
                        <input
                          type="number"
                          value={price ? price : ""}
                          onChange={(e) => setPrice(e.target.value)}
                          style={{ color: "#666" }}
                          placeholder="물건 가격을 입력해주세요"
                        />
                      </div>
                      <span className="unit">원</span>
                    </div>
                    {/* <span className="unit-txt">{priceText(article.price)}</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="reg__mid__box">
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                style={{ height: "548px" }}
                placeholder="여기를 눌러 글을 작성할 수 있습니다."
              ></textarea>
            </div>
            <div className="reg__bottom__box">
              <AttachImages useImages={[images, setImages]} />
            </div>
            <div className="reg__menu__box">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/recruit/doctor`, { replace: true });
                }}
                className="menu-btn type1"
              >
                취소
              </button>
              <button onClick={submit} className="menu-btn type2">
                등록
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UsedMarketWritePage;
