import { Plugin, PluginKey } from 'prosemirror-state'
import { Extension } from '../core/extension'
import { IDB } from '../utils/indexedDB';

export const AUTOSAVE_PLUGIN_KEY = new PluginKey('autosave')

class autoSavePluginView {
  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   * @param {AutoSaveConfig} pluginConfig
   * @param {import('../core/editor').IntegrationEditor} editor
   */
  constructor(view, pluginConfig, editor) {
    this.view = view
    this.config = pluginConfig
    this.editor = editor
  }

  /**
   * 
   * @param {import('prosemirror-view').EditorView} _ 
   * @param {import('prosemirror-state').EditorState} editorState 
   */
  async update(_, editorState) {
    /**
     * @type {AutoSaveState}
     */
    const pluginState = AUTOSAVE_PLUGIN_KEY.getState(editorState)
    
    if (!pluginState || !pluginState.idb.db) return
    
    if (pluginState.trCount === 10) {
      try {
        const cursor = await pluginState.idb.getItemCursor(pluginState.createdAt)
        
        cursor 
          ? cursor.update({ ...cursor.value, content: editorState.doc.toJSON() })
          : pluginState.idb.add({ content: editorState.doc.toJSON(), createdAt: pluginState.createdAt, identifier: pluginState.config.userId })

        this.editor.emit('autosave')
      } catch (error) {
        console.info(error)
      }
    }
  }
}

/**
 * @typedef {{
 *  domain: 'medistream' | 'moreden' | 'cheestalk'
 *  userId: string
 *  createdAt: number
 * }} AutoSaveConfig
 * 
 * @typedef {{
 *  trCount: number
 *  createdAt: number
 *  idb: IDB
 *  config: AutoSaveConfig
 * }} AutoSaveState
 *
 * @param {AutoSaveConfig} config
 * @param {import('../core/editor').IntegrationEditor} editor
 */
const autoSave = (config, editor) => new Plugin({
  key: AUTOSAVE_PLUGIN_KEY,
  state: {
    init(_, editorState) {
      const idb = new IDB({
        name: config.domain,
        onReady: () => {
          idb.add({ content: editorState.doc.toJSON(), createdAt: config.createdAt, identifier: config.userId })
        }
      })

      return { trCount: 0, createdAt: config.createdAt, idb, config }
    },
    /**
     * 
     * @param {import('prosemirror-state').Transaction} tr
     * @param {AutoSaveState} pluginState 
     * @param {import('prosemirror-state').EditorState} editorState 
     */
    apply(tr, pluginState, editorState) {
      if (pluginState.trCount === 10) {
        pluginState.trCount = 0
        return pluginState
      }
      // 내용이 변경된 경우에만 trCount를 증가시킵니다.
      if (tr.doc.eq(editorState.doc)) {
        return pluginState
      }

      pluginState.trCount += 1

      return pluginState
    }
  },
  view(editorView) {
    return new autoSavePluginView(editorView, config, editor)
  }
})

/**
 * @param {AutoSaveConfig} config
 */
export const AutoSave = (config) => Extension.Create({
  name: 'auto_save',

  addPlugins() {
    if (!config) throw new Error('도메인과 식별자가 설정되지 않았습니다.')
    if (!config.userId) throw new Error('식별자가 설정되지 않았습니다.')
    if (!config.domain) throw new Error('도메인이 설정되지 않았습니다.')
    if (!config.createdAt) throw new Error('createdAt 값이 설정되지 않았습니다.')
    if (!['medistream', 'modeden', 'cheestalk'].includes(config.domain)) throw new Error('도메인이 올바르지 않습니다.')

    return [autoSave(config, this.editor)]
  }
})
