import { NodeSelection } from 'prosemirror-state'
import { findParentNodeOfTypeClosestToPos } from 'prosemirror-utils'
import { Schema } from 'prosemirror-model'
import { MEDISTREAM_SCHEMA_STYLE } from '../styles/classNames'
import { CELL_TOOLTIP_PLUGIN_KEY } from '../extensions/table'
import { removeCustomTemplate } from '../extensions/customTemplate'

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {Number} pos
 */
export const selectTableNode = (view, pos) => {
  const tableNode = findParentNodeOfTypeClosestToPos(
    view.state.doc.resolve(pos),
    view.state.schema.nodes.table,
  )
  if (!tableNode) return false
  view.dispatch(
    view.state.tr.setSelection(
      NodeSelection.create(view.state.doc, tableNode.pos),
    ),
  )
  return true
}

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 */
export const showCellTooltipButton = (view) => {
  const tr = view.state.tr.setMeta(CELL_TOOLTIP_PLUGIN_KEY, 'open')
  view.dispatch(tr)
  return true
}

/**
 * 
 * @param {import('../core/extension').Extension[]} extensions 
 * @returns {import('prosemirror-model').Schema}
 */
export const createSchemaFromExtensions = (extensions) => {
  const marks = extensions
      .filter(extension => extension.type === 'mark')
      .reduce((acc, extension) => {
        acc[extension.name] = extension.defineSpec(extensions)
        return acc
      }, {})
    
  const nodes = extensions
    .filter(extension => extension.type === 'node')
    .reduce((acc, extension) => {
      acc[extension.name] = extension.defineSpec(extensions)
      return acc
    }, {})
  
  return new Schema({ marks, nodes })
}

/**
 * Prosemirror DOM Event Handlers
 */

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {Number} pos
 * @param {MouseEvent} event
 * @returns {Boolean}
 */
export const handlePMClick = (view, pos, event) => {
  if (event.target && event.target.parentElement.classList.contains(MEDISTREAM_SCHEMA_STYLE.nodes.tableCell)) {
    return showCellTooltipButton(view)
  }
  if (event.target && event.target.classList.contains(MEDISTREAM_SCHEMA_STYLE.etc.tableWrapper)) {
    return selectTableNode(view, pos)
  }
}

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {KeyboardEvent} event
 * @returns {Boolean}
 */
export const handlePMKeyDown = (view, event) => {
  if (event.key === 'Backspace') {
    return removeCustomTemplate(view.state, view.dispatch)
  }
  return false
}

/**
 * 
 * @param {import('prosemirror-view').EditorView} view 
 * @param {ClipboardEvent} event 
 */
export const handlePMPaste = (view, event) => {
  const clipboardHtml = event.clipboardData.getData('text/html')
  const parser = new DOMParser()
  const doc = parser.parseFromString(clipboardHtml, 'text/html')
  const tables = doc.querySelectorAll('table')

  const isFilePaste = event.clipboardData && event.clipboardData.files.length && tables.length < 1 && tables.length < 1
  if (isFilePaste) {
    return true
  }
}

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {MouseEvent} event
 * @returns {Boolean}
 */
export const handlePMMouseup = (view, event) => {
  if (event.target && event.target.parentElement.classList.contains(MEDISTREAM_SCHEMA_STYLE.nodes.tableCell)) {
    return showCellTooltipButton(view)
  }
};

export const _equalNodeType = (nodeType, node) => {
  return (
    (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
    node.type === nodeType
  );
};

export const _isNodeSelection = (selection) => {
  return selection instanceof NodeSelection;
};

export const _findSelectedNodeOfType = (nodeType) => (selection) => {
  if (_isNodeSelection(selection)) {
    const { node, $from } = selection;
    if (_equalNodeType(nodeType, node)) {
      return { node, pos: $from.pos, depth: $from.depth };
    }
  }
};