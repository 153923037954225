import { _api, getStorage, parseJSON, publicApi, publicApiOption } from "utils/util";

export const getCustomerInfo = async () => {
  try {
    const { data } = await publicApi.get(`/market/customers`);
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getProductCurations = async (urlParams) => {
  const { enabled = true } = urlParams;
  try {
    const {
      status,
      data: { success, data },
    } = await publicApi({
      method: "get",
      url: "/market/product-curations",
      params: {
        enabled,
      },
    });
    if (status >= 200 && status < 300 && success) {
      return data;
    }
    throw new Error("getProductCurations error");
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * @param {*} urlParams
 * - enabled: boolean
 * - type: string
 */
export const getProductGroups = async (urlParams) => {
  try {
    const { status, data } = await publicApi({
      method: "get",
      url: "/market/product-groups",
      params: urlParams,
    });
    if (status >= 200 && status < 300) {
      return data.data;
    }
    throw new Error("getProductGroups error");
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * @param {*} urlParams
 * - groupId: string
 * - enabled: boolean
 * - offset: number
 * - limit: number
 * - sort: string
 */

export const getProductGroupProducts = async (urlParams) => {
  const { groupId, ...restParams } = urlParams;
  try {
    const { status, data } = await publicApi({
      method: "get",
      url: `/market/product-groups/${groupId}/products`,
      params: restParams,
    });
    if (status >= 200 && status < 300) {
      return data.data;
    }
    throw new Error("getProductGroupProducts error");
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * @param {*} urlParams
 * - enabled: boolean
 * - type: string
 */
export const getHomeProductGroups = async (urlParams) => {
  try {
    const customPublicApi = _api({
      ...publicApiOption,
      headers: {
        ...publicApiOption.headers,
        Authorization: getStorage().getItem("token")
          ? `Bearer ${parseJSON(getStorage().getItem("token")).access_token}`
          : "",
      },
    });
    const { status, data } = await customPublicApi({
      method: "get",
      url: "/market/home-product-groups",
      params: urlParams,
    });
    if (status >= 200 && status < 300) {
      return data.data;
    }
    throw new Error("getHomeProductGroups error");
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * @param {*} urlParams
 * - groupId: string
 * - enabled: boolean
 * - offset: number
 * - limit: number
 * - sort: string
 */

export const getHomeProductGroupProducts = async (urlParams) => {
  const { groupId, ...restParams } = urlParams;
  try {
    const customPublicApi = _api({
      ...publicApiOption,
      headers: {
        ...publicApiOption.headers,
        Authorization: getStorage().getItem("token")
          ? `Bearer ${parseJSON(getStorage().getItem("token")).access_token}`
          : "",
      },
    });
    const { status, data } = await customPublicApi({
      method: "get",
      url: `/market/home-product-groups/${groupId}/products`,
      params: restParams,
    });
    if (status >= 200 && status < 300) {
      return data.data;
    }
    throw new Error("getHomeProductGroupProducts error");
  } catch (e) {
    console.error(e);
    throw e;
  }
};
