import CommentItem from "./CommentItem";
import { cls } from "../../utils/util";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Comment = ({
  item, //댓글정보
  isBestComment, //베스트댓글
  getLikeInfo, //좋아요기능
  likeClicked, //좋아요기능
  setDeleteUUID, //삭제기능
  setNewReply, //수정, 대댓글기능
  reportComment, //신고기능
  commentBoxRef, //댓글창 스크롤
  highlightedCid, //댓글창 스크롤
}) => {
  const isReply = item.cid ? true : false;
  const modifyComment = () => {
    setNewReply({ ...item, image: item.img_link });
  };

  return (
    <div
      className={cls(isReply ? "bg-gray-200/40" : "")}
      ref={highlightedCid === item.uuid ? commentBoxRef : null}
    >
      <CommentItem
        item={item}
        setNewReply={setNewReply}
        getLikeInfo={getLikeInfo}
        likeClicked={likeClicked}
        isBestComment={isBestComment}
      >
        {item.ismine ? (
          <div className="ml-auto text-gray-600">
            <button className="mr-5" onClick={() => modifyComment(item)}>
              수정
            </button>
            <button onClick={() => setDeleteUUID(item.uuid)}>삭제</button>
          </div>
        ) : (
          <button className="ml-auto text-gray-600 " onClick={() => reportComment(item)}>
            <i className="ii ii-alarm-light-outline mr-1 relative top-[-2px]" />
            신고
          </button>
        )}
      </CommentItem>
    </div>
  );
};

export default Comment;
