import React, { useEffect, useState } from "react";
import { api, noticeDay } from "../../utils/util";
import ReactPaginate from "react-paginate";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";

function NoticeContent({ props }) {
  const navigate = useNavigate();
  const [notice, setNotice] = useState({ load: false });

  const openNotice = (bid, e) => {
    navigate(`/mypage/notice/${bid}`);
    window.scrollTo(0, 0);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    api
      .get(`/mypage/noticeList`, {
        params: {
          page: selected,
          q: notice.query,
        },
      })
      .then((res) => {
        setNotice({ ...res.data, load: true });
        window.scrollTo(0, 0);
      });
  };

  useEffect(() => {
    if (notice.load) return;
    api.get(`/mypage/noticeList`).then((res) => setNotice({ ...res.data, load: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!notice.load) return <Loading />;
  return (
    <>
      <article className="list__table type-1" style={{ marginTop: "35px" }}>
        {notice &&
          notice.notice.map((item) => {
            return (
              <div key={item.bid} onClick={(e) => openNotice(item.bid, e)} className="cursor">
                <div className="notice">
                  <span className="normal-txt">{item.title}</span>
                  <span className="sub-txt">{noticeDay(item.reg_dttm)}</span>
                </div>
              </div>
            );
          })}
      </article>
      <article className="paging" style={{ paddingTop: "20px" }}>
        <ReactPaginate
          previousLabel="←"
          previousLinkClassName="before"
          nextLabel="→"
          nextLinkClassName="next"
          breakLabel="..."
          pageCount={notice.pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageLinkClassName="num"
          activeClassName=""
          activeLinkClassName="current"
          onPageChange={onPageClick}
        />
      </article>
    </>
  );
}

export default NoticeContent;
