import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ManageContent from "../../../component/management/ManageContent";
import { api, dttm, htmlTextContent, Img, priceText } from "../../../utils/util";
import Loading from "../../../component/Loading";
import BoardReportPopup from "../../../component/board/BoardReportPopup";
import BoardTrashPopup from "../../../component/board/BoardTrashPopup";
import { CopyToClipboard } from "react-copy-to-clipboard";

function UsedMarketDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const bid = useParams().bid;
  const [content, setContent] = useState();
  const [pageInfo, setPageInfo] = useState();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const q = urlParam.get("q") || "";
  const [reportPopup, setReportPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [soldPopup, setSoldPopup] = useState(false);

  const modifyFunc = () => {
    if (!!content.article.sold_at) {
      alert("판매 완료되어 수정이 불가능합니다.");
      return;
    }
    navigate(`/management/market/modify/${bid}`, {
      state: { ...content },
    });
  };

  const directDeleteArticle = () => {
    api
      .delete(`/management/market/delete/${bid}`)
      .then(() => {
        navigate(`/management/market`, { replace: true });
      })
      .catch((err) => alert(err.response.data.msg));
  };

  const scrapClicked = async () => {
    const data = {
      scraped: !content.scraped,
    };
    const res = await api.post(`/management/market/scrap/${bid}`, data).catch((err) => {
      alert(err.response.data.msg);
    });

    res &&
      setContent({
        ...content,
        scraped: res.data.scrap,
      });
  };

  useEffect(() => {
    api
      .get(`/management/market/article/${bid}`)
      .then((res) => setContent(res.data))
      .catch((err) => {
        alert(err.response.data.msg);
        navigate(-1);
      });
  }, [navigate, bid]);

  useEffect(() => {
    // TODO paging 에 따라 로딩
    const data = new URLSearchParams();
    data.append("page", page);
    data.append("q", q);
    api
      .get(`/management/market/list`, {
        params: data,
      })
      .then((res) => {
        setPageInfo(res.data);
      });
  }, [page, q]);

  if (!content) return <Loading current="management" />;

  return (
    <div>
      <div className="wrapper">
        <section className="magazine__detail" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">중고장터</span>
                {content.article && content.ismine ? (
                  <>
                    <div className="menu-box">
                      <div className="report-box">
                        <button onClick={modifyFunc} className="report-btn">
                          수정
                        </button>
                      </div>
                      <div className="report-box">
                        <button onClick={() => setDeletePopup(true)} className="report-btn">
                          삭제
                        </button>
                      </div>
                      <span
                        className="ml-[13px] border-[1px] border-solid border-gray-300  cursor-pointer p-3 rounded-sm"
                        onClick={() => setSoldPopup(true)}
                      >
                        <img
                          className="inline-block"
                          src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/buy_done.svg`}
                          alt="판매완료"
                        />
                        <span className="text-[13px] text-gray-600 ml-1 relative top-1">
                          판매 완료
                        </span>
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="menu-box"></div>
                )}
              </div>
            </article>
            {deletePopup && (
              <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
            )}
            {soldPopup && <SoldPopup setPopup={setSoldPopup} bid={bid} />}

            <div className="detail__group">
              <div className="tit-box">
                <div className="subject-box">{content.article.title}</div>
                <div className="info-box">
                  <span className="txt">{content.article.unick}</span>
                  <span className="txt">{dttm(content.article.reg_dttm)}</span>
                </div>
              </div>
              <div className="etc-box">
                <div className="row">
                  <div className="subject">지역:</div>
                  <div className="con">
                    {content.article.location1} {content.article.location2}
                  </div>
                </div>
                <div className="row">
                  <div className="subject">가격:</div>
                  <div className="con">{priceText(content.article.price)}</div>
                </div>
                {/* <div className="row">
                  <div className="subject">전화번호:</div>
                  <div className="con">{content.article.call_number}</div>
                </div> */}
              </div>

              <div className="con-box">
                <div className="txt-box" style={{}}>
                  {htmlTextContent(content.article.content)}
                  <br />
                  <br />
                  <br />
                  <div className="img-box">
                    {content.images &&
                      content.images.map((item) => {
                        return <img src={Img(item.link)} alt="이미지" />;
                      })}
                  </div>
                </div>
              </div>
              <div className="menu-box-list">
                {content.article && !content.ismine && (
                  <>
                    <button
                      className={content.scraped ? "menu-box on" : "menu-box"}
                      onClick={scrapClicked}
                    >
                      <div className={content.scraped ? "scrap-btn-n on" : "scrap-btn-n"}></div>
                      <span className="menu-txt">스크랩</span>
                    </button>
                    <button className="menu-box" onClick={() => setReportPopup(true)}>
                      <div className="police-btn-n on"></div>
                      <span className="menu-txt">신고</span>
                    </button>
                  </>
                )}
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: content.article.title,
                        text: `[모어덴 - 중고장터 게시판]\n${content.article.title}`,
                        url: location.pathname,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="magazine__list mt-[60px]" style={{ marginBottom: "35px" }}>
          <div className="inner">
            {pageInfo && (
              <ManageContent pageInfo={pageInfo} current="market" pages={pageInfo.pages} />
            )}
          </div>
        </section>
      </div>
      {reportPopup && (
        <BoardReportPopup category="management_market" setReportPopup={setReportPopup} bid={bid} />
      )}
    </div>
  );
}

const SoldPopup = ({ setPopup, bid }) => {
  return (
    <>
      <div className="popup-overlay" onClick={() => setPopup(false)}></div>
      <article className="popup popup-addr" style={{ display: "block" }}>
        <div className="popup-wrap">
          <div className="head">
            <div className="popup-tit">
              <i className="ic ic-logo"></i>
              <span className="txt">판매 상태 변경</span>
            </div>

            <span onClick={() => setPopup(false)} className="close-btn"></span>
          </div>
          <div className="body">
            <article className="pop__addr">
              <div className="result__group">
                <div className="no-data">
                  판매 상태를 판매 중에서
                  <br />
                  판매 완료로 변경하시겠습니까?
                </div>
              </div>
            </article>

            <div className="popup__menu">
              <button onClick={() => setPopup(false)} className="pop-menu-btn">
                취소
              </button>
              <button
                onClick={() => {
                  setPopup(false);
                  api
                    .post(`/management/market/sold/${bid}`)
                    .then(() => alert("판매 완료 처리되었습니다."))
                    .catch((err) => alert(err.response.data.msg));
                }}
                className="pop-menu-btn type1"
              >
                완료
              </button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default UsedMarketDetailPage;
