import { publicApi } from "utils/util";

export const getGroupPurchaseList = async () => {
  try {
    const { status, data } = await publicApi({
      method: "get",
      url: "/market/products/team-products",
    });
    if (status !== 200) {
      throw new Error("getGroupPurchaseList error");
    }
    return data.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getGroupPurchaseItem = async (urlParams) => {
  const { groupPurchaseId } = urlParams;
  try {
    const { status, data } = await publicApi({
      method: "get",
      url: `/market/products/team-products/${groupPurchaseId}`,
    });
    if (status !== 200) {
      throw new Error("getGroupPurchase error");
    }
    return data.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getGroupPurchaseMetadata = async ({ productId }) => {
  try {
    const { status, data } = await publicApi({
      method: "get",
      url: `/market/products/${productId}/metadata`,
    });
    if (status !== 200) {
      throw new Error("getGroupPurchase metadata error");
    }
    return data.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
