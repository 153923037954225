import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Img, StaticImg, outResource } from "../../../utils/util";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ConsultingDentalMaster = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <div className="">
        <div className="inner" style={{ marginTop: 64 }}>
          <div className="video-info">
            <div className="video-info__right">
              <span>치과 인력관리 및 치과시스템은 이제 필수입니다.</span>
            </div>
          </div>
          <div className="video-detail">
            <a
              className="theater-publisher"
              href="https://blog.naver.com/dhlee1228"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Img("", "img/logo_dentalmaster.svg")} alt="게시자" />
              <span>덴탈마스터컨설팅</span>
            </a>
            <div className="detail__group">
              <div className="menu-box-list theater__menu-box-list">
                <a
                  href="https://blog.naver.com/dhlee1228"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="menu-box home__btn">
                    <div className="home"></div>
                    <span className="menu-txt">홈페이지</span>
                  </button>
                </a>
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: "[모어덴 - 덴탈마스터 컨설팅]",
                        // text: adsProductState[param].title,
                        url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
          <div className="top-article" style={{ margin: "45px 0" }}>
            <div className="btn-flex">
              <button
                className="btn-inner-flex"
                onClick={() => outResource("https://pf.kakao.com/_zPRET")}
              >
                <div className="icon-phone"></div>
                <div className="btn-txt">카톡 상담하기</div>
              </button>
              <button className="btn-inner-flex">
                <div className="icon-pen"></div>
                <div
                  className="btn-txt"
                  onClick={() =>
                    outResource(
                      "https://map.naver.com/v5/entry/place/194780226?c=14144794.7576994,4508205.1518471,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt",
                    )
                  }
                >
                  미팅 예약하기
                </div>
              </button>
            </div>
          </div>
          <div className="bottom-article">
            <img src={StaticImg("consulting/2211110-dental-master.jpeg")} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingDentalMaster;
