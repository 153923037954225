import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useRcaModal } from "recoil/common";
import { cls } from "utils/util";

const HookModal = () => {
  const { isOpenedModal, closeModal, modalContent } = useRcaModal();

  const { component: ContentComponent, componentProps, modalProps } = modalContent;

  const {
    closeOnOverlayClick = true,
    overlayClassName: propOverlayClassName = undefined,
    contentClassName: propContentClassName = undefined,
  } = modalProps;

  const handleClickOverlay = () => {
    if (!closeOnOverlayClick) return;
    closeModal();
  };

  if (!isOpenedModal || !ContentComponent) return null;

  return ReactDOM.createPortal(
    <div className="absolute h-full overflow-y-hidden top-0 bottom-0 z-[1001]">
      <div
        className={cls(
          "hook-modal__overlay",
          propOverlayClassName
            ? propOverlayClassName
            : "fixed left-0 right-0 top-0 bottom-0 z-[990] bg-[rgba(0,0,0,0.4)]",
        )}
        onClick={() => handleClickOverlay()}
      />
      <div className="fixed z-[1001] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <div
          className={cls(
            "hook-modal__content",
            propContentClassName
              ? propContentClassName
              : "relative shadow-custom rounded-lg bg-white min-w-[100px]",
          )}
        >
          <ContentComponent {...componentProps} />
        </div>
      </div>
    </div>,
    document.body,
  );
};
export default HookModal;
