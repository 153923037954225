import { cls } from "../../utils/util";

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement>;
type Option = {
  label: string;
  value: string | number;
};
interface Select extends SelectProps {
  value: string | number | readonly string[] | undefined;
  onChange: React.ChangeEventHandler<HTMLSelectElement>; // 필수
  option: Option[]; // 필수
}

export default function StyledSelect({ className, option, ...props }: Select) {
  return (
    <select
      {...props}
      className={cls(
        className ? className : "w-full h-full",
        "styled-select pl-2 pr-7 focus:outline-none focus:border-gray-900 border-[1px] border-solid bg-white hover:bg-secondary-50 focus:shadow-sm border-gray-300 rounded-md placeholder-gray-300 cursor-pointer",
      )}
    >
      {option.map((item, idx) => (
        <option value={item.value} key={idx} disabled={item.value === ""}>
          {item.label}
        </option>
      ))}
    </select>
  );
}
