import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import Loading from "../../component/Loading";

const SyncAccount = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const typeToKorean = { kakao: "카카오", apple: "애플" };

  const onSync = async () => {
    const { accessToken, idToken, syncableUser: { sso_id = "" } = {} } = state;
    const url = `${import.meta.env.VITE_APP_PUBLIC_API_URL}/community-auth/user/connect`;
    const data = { idToken, targetUserId: sso_id };
    const options = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const result = await axios
      .post(url, data, options)
      .then((res) => res.data.success)
      .catch((err) => {
        console.error(err);
        return false;
      });

    if (result) {
      navigate("/login", { state: { syncType: typeToKorean[state.loginType] }, replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  if (!state) return <Loading />;
  return (
    <div className="container full">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`} alt="로고" />
          </div>
          <div className="member__con">
            <section>
              <div className="sync_notice">
                <div className="txt">회원님의 방문을 환영합니다🥳</div>
                <div className="txt">
                  닉네임 <span className="nick">{state.syncableUser.nickname}</span>으로 가입되어
                  있습니다.
                </div>
                <div className="q">{typeToKorean[state.loginType]} 계정과 연동하시겠습니까?</div>

                <button className="sync-btn" onClick={onSync}>
                  연동
                </button>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SyncAccount;
