import axios from "axios";
import { useState } from "react";
import { api } from "../../utils/util";
import Loading from "../Loading";

const AttachImage = ({ changeImage, text = "" }) => {
  const [loading, setLoading] = useState(false);

  const attachImage = async (e) => {
    if (
      !e ||
      !e.target ||
      !e.target.files ||
      !e.target.files.length ||
      !e.target.files[0].type.match(/image\//)
    )
      return;
    try {
      setLoading(true);
      const file = e.target.files[0];
      const res = await api.post("/util/presigned", { filename: file.name });
      const signedUrl = res.data;
      const url = new URL(signedUrl);
      url.search = "";
      await axios.put(signedUrl, file);
      changeImage(url.toString());
    } catch (err) {
      console.log(err);
      alert("이미지 업로드에 실패했습니다.");
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <label className="cursor-pointer w-[50px] h-[50px] rounded-full hover:bg-gray-100 flex justify-center items-center">
        <i className="ii ii-camera-vintage-outline text-[20px] text-gray-600" />
        <input
          type="file"
          onChange={attachImage}
          accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
          style={{ display: "none" }}
        />
        {text && (
          <span className="text-[12px] pl-2 text-gray-600 font-normal tracking-[-0.05em]">
            {text}
          </span>
        )}
      </label>
    </>
  );
};

export default AttachImage;
