export const majorOptions = [
  { value: "A", label: "전공무관" },
  { value: "B", label: "구강악안면외과" },
  { value: "C", label: "치과보철과" },
  { value: "D", label: "치과교정과" },
  { value: "E", label: "소아치과" },
  { value: "F", label: "치주과" },
  { value: "G", label: "치과보존과" },
  { value: "H", label: "구강내과" },
  { value: "I", label: "영상치의학과" },
  { value: "J", label: "구강병리과" },
  { value: "K", label: "예방치과" },
  { value: "L", label: "통합치의학과" },
];

export const DHMajorOptions = [
  { value: "A", label: "교정과" },
  { value: "B", label: "보철과" },
  { value: "C", label: "임플란트" },
  { value: "D", label: "소아" },
  { value: "E", label: "진료실" },
  { value: "F", label: "상담" },
  { value: "G", label: "경영지원" },
  { value: "H", label: "데스크" },
  { value: "I", label: "기타" },
];

export const DHTypeOptions = [
  { value: "A", label: "정규직" },
  { value: "B", label: "계약직" },
  { value: "C", label: "아르바이트" },
];

export const DHRequirementOptions = [
  { value: "A", label: "이력서" },
  { value: "B", label: "자기소개서" },
  { value: "C", label: "면허/자격증 사본(합격 시)" },
  { value: "D", label: "주민등록등본(합격 시)" },
];

export const careerTypeOptions = [
  { value: "experience", label: "경력" },
  { value: "fresh", label: "신입" },
];

export const timeOptions = [
  { value: "full_time", label: "풀타임 근무" },
  { value: "part_time", label: "파트타임 근무" },
  { value: "whatever", label: "협의 가능" },
];

export const salaryOptions = [
  { value: "annualy", label: "연봉" },
  { value: "weekly", label: "주급" },
  { value: "daily", label: "일급" },
  { value: "hourly", label: "시급" },
  { value: "monthly", label: "월급" },
  { value: "", label: "협의" },
];

export const DHSalaryOptions = [
  { value: "conference", label: "면접시 협의" },
  { value: "annualy", label: "연봉" },
  { value: "hourly", label: "시급" },
  { value: "monthly", label: "월급" },
];

export const location1 = [
  "서울",
  "경기",
  "인천",
  "강원",
  "경남",
  "경북",
  "광주",
  "대구",
  "대전",
  "부산",
  "세종특별자치시",
  "울산",
  "전남",
  "전북",
  "제주",
  "충남",
  "충북",
];

export const location2 = {
  강원: [
    "강릉시",
    "고성군",
    "동해시",
    "삼척시",
    "속초시",
    "양구군",
    "양양군",
    "영월군",
    "원주시",
    "인제군",
    "정선군",
    "철원군",
    "춘천시",
    "태백시",
    "평창군",
    "홍천군",
    "화천군",
    "횡성군",
  ],
  경기: [
    "가평군",
    "고양시 덕양구",
    "고양시 일산동구",
    "고양시 일산서구",
    "과천시",
    "광명시",
    "광주시",
    "구리시",
    "군포시",
    "김포시",
    "남양주시",
    "동두천시",
    "부천시",
    "성남시 분당구",
    "성남시 수정구",
    "성남시 중원구",
    "수원시 권선구",
    "수원시 영통구",
    "수원시 장안구",
    "수원시 팔달구",
    "시흥시",
    "안산시 단원구",
    "안산시 상록구",
    "안성시",
    "안양시 동안구",
    "안양시 만안구",
    "양주시",
    "양평군",
    "여주시",
    "연천군",
    "오산시",
    "용인시 기흥구",
    "용인시 수지구",
    "용인시 처인구",
    "의왕시",
    "의정부시",
    "이천시",
    "파주시",
    "평택시",
    "포천시",
    "하남시",
    "화성시",
  ],
  경남: [
    "거제시",
    "거창군",
    "고성군",
    "김해시",
    "남해군",
    "밀양시",
    "사천시",
    "산청군",
    "양산시",
    "의령군",
    "진주시",
    "창녕군",
    "창원시 마산합포구",
    "창원시 마산회원구",
    "창원시 성산구",
    "창원시 의창구",
    "창원시 진해구",
    "통영시",
    "하동군",
    "함안군",
    "함양군",
    "합천군",
  ],
  경북: [
    "경산시",
    "경주시",
    "고령군",
    "구미시",
    "군위군",
    "김천시",
    "문경시",
    "봉화군",
    "상주시",
    "성주군",
    "안동시",
    "영덕군",
    "영양군",
    "영주시",
    "영천시",
    "예천군",
    "울릉군",
    "울진군",
    "의성군",
    "청도군",
    "청송군",
    "칠곡군",
    "포항시 남구",
    "포항시 북구",
  ],
  광주: ["광산구", "남구", "동구", "북구", "서구"],
  대구: ["남구", "달서구", "달성군", "동구", "북구", "서구", "수성구", "중구"],
  대전: ["대덕구", "동구", "서구", "유성구", "중구"],
  부산: [
    "강서구",
    "금정구",
    "기장군",
    "남구",
    "동구",
    "동래구",
    "부산진구",
    "북구",
    "사상구",
    "사하구",
    "서구",
    "수영구",
    "연제구",
    "영도구",
    "중구",
    "해운대구",
  ],
  서울: [
    "강남구",
    "강동구",
    "강북구",
    "강서구",
    "관악구",
    "광진구",
    "구로구",
    "금천구",
    "노원구",
    "도봉구",
    "동대문구",
    "동작구",
    "마포구",
    "서대문구",
    "서초구",
    "성동구",
    "성북구",
    "송파구",
    "양천구",
    "영등포구",
    "용산구",
    "은평구",
    "종로구",
    "중구",
    "중랑구",
  ],
  세종특별자치시: [],
  울산: ["남구", "동구", "북구", "울주군", "중구"],
  인천: [
    "강화군",
    "계양구",
    "남동구",
    "동구",
    "미추홀구",
    "부평구",
    "서구",
    "연수구",
    "옹진군",
    "중구",
  ],
  전남: [
    "강진군",
    "고흥군",
    "곡성군",
    "광양시",
    "구례군",
    "나주시",
    "담양군",
    "목포시",
    "무안군",
    "보성군",
    "순천시",
    "신안군",
    "여수시",
    "영광군",
    "영암군",
    "완도군",
    "장성군",
    "장흥군",
    "진도군",
    "함평군",
    "해남군",
    "화순군",
  ],
  전북: [
    "고창군",
    "군산시",
    "김제시",
    "남원시",
    "무주군",
    "부안군",
    "순창군",
    "완주군",
    "익산시",
    "임실군",
    "장수군",
    "전주시 덕진구",
    "전주시 완산구",
    "정읍시",
    "진안군",
  ],
  제주: ["서귀포시", "제주시"],
  충남: [
    "계룡시",
    "공주시",
    "금산군",
    "논산시",
    "당진시",
    "보령시",
    "부여군",
    "서산시",
    "서천군",
    "아산시",
    "예산군",
    "천안시 동남구",
    "천안시 서북구",
    "청양군",
    "태안군",
    "홍성군",
  ],
  충북: [
    "괴산군",
    "단양군",
    "보은군",
    "영동군",
    "옥천군",
    "음성군",
    "제천시",
    "증평군",
    "진천군",
    "청주시 상당구",
    "청주시 서원구",
    "청주시 청원구",
    "청주시 흥덕구",
    "충주시",
  ],
};

export const location1_detail = [
  "서울특별시",
  "경기도",
  "인천광역시",
  "강원도",
  "경상남도",
  "경상북도",
  "광주광역시",
  "대구광역시",
  "대전광역시",
  "부산광역시",
  "세종특별자치시",
  "울산광역시",
  "전라남도",
  "전라북도",
  "제주특별자치도",
  "충청남도",
  "충청북도",
];

export const location2_detail = {
  강원도: [
    "강릉시",
    "고성군",
    "동해시",
    "삼척시",
    "속초시",
    "양구군",
    "양양군",
    "영월군",
    "원주시",
    "인제군",
    "정선군",
    "철원군",
    "춘천시",
    "태백시",
    "평창군",
    "홍천군",
    "화천군",
    "횡성군",
  ],
  경기도: [
    "가평군",
    "고양시 덕양구",
    "고양시 일산동구",
    "고양시 일산서구",
    "과천시",
    "광명시",
    "광주시",
    "구리시",
    "군포시",
    "김포시",
    "남양주시",
    "동두천시",
    "부천시",
    "성남시 분당구",
    "성남시 수정구",
    "성남시 중원구",
    "수원시 권선구",
    "수원시 영통구",
    "수원시 장안구",
    "수원시 팔달구",
    "시흥시",
    "안산시 단원구",
    "안산시 상록구",
    "안성시",
    "안양시 동안구",
    "안양시 만안구",
    "양주시",
    "양평군",
    "여주시",
    "연천군",
    "오산시",
    "용인시 기흥구",
    "용인시 수지구",
    "용인시 처인구",
    "의왕시",
    "의정부시",
    "이천시",
    "파주시",
    "평택시",
    "포천시",
    "하남시",
    "화성시",
  ],
  경상남도: [
    "거제시",
    "거창군",
    "고성군",
    "김해시",
    "남해군",
    "밀양시",
    "사천시",
    "산청군",
    "양산시",
    "의령군",
    "진주시",
    "창녕군",
    "창원시 마산합포구",
    "창원시 마산회원구",
    "창원시 성산구",
    "창원시 의창구",
    "창원시 진해구",
    "통영시",
    "하동군",
    "함안군",
    "함양군",
    "합천군",
  ],
  경상북도: [
    "경산시",
    "경주시",
    "고령군",
    "구미시",
    "군위군",
    "김천시",
    "문경시",
    "봉화군",
    "상주시",
    "성주군",
    "안동시",
    "영덕군",
    "영양군",
    "영주시",
    "영천시",
    "예천군",
    "울릉군",
    "울진군",
    "의성군",
    "청도군",
    "청송군",
    "칠곡군",
    "포항시 남구",
    "포항시 북구",
  ],
  광주광역시: ["광산구", "남구", "동구", "북구", "서구"],
  대구광역시: ["남구", "달서구", "달성군", "동구", "북구", "서구", "수성구", "중구"],
  대전광역시: ["대덕구", "동구", "서구", "유성구", "중구"],
  부산광역시: [
    "강서구",
    "금정구",
    "기장군",
    "남구",
    "동구",
    "동래구",
    "부산진구",
    "북구",
    "사상구",
    "사하구",
    "서구",
    "수영구",
    "연제구",
    "영도구",
    "중구",
    "해운대구",
  ],
  서울특별시: [
    "강남구",
    "강동구",
    "강북구",
    "강서구",
    "관악구",
    "광진구",
    "구로구",
    "금천구",
    "노원구",
    "도봉구",
    "동대문구",
    "동작구",
    "마포구",
    "서대문구",
    "서초구",
    "성동구",
    "성북구",
    "송파구",
    "양천구",
    "영등포구",
    "용산구",
    "은평구",
    "종로구",
    "중구",
    "중랑구",
  ],
  세종특별자치시: [],
  울산광역시: ["남구", "동구", "북구", "울주군", "중구"],
  인천광역시: [
    "강화군",
    "계양구",
    "남동구",
    "동구",
    "미추홀구",
    "부평구",
    "서구",
    "연수구",
    "옹진군",
    "중구",
  ],
  전라남도: [
    "강진군",
    "고흥군",
    "곡성군",
    "광양시",
    "구례군",
    "나주시",
    "담양군",
    "목포시",
    "무안군",
    "보성군",
    "순천시",
    "신안군",
    "여수시",
    "영광군",
    "영암군",
    "완도군",
    "장성군",
    "장흥군",
    "진도군",
    "함평군",
    "해남군",
    "화순군",
  ],
  전라북도: [
    "고창군",
    "군산시",
    "김제시",
    "남원시",
    "무주군",
    "부안군",
    "순창군",
    "완주군",
    "익산시",
    "임실군",
    "장수군",
    "전주시 덕진구",
    "전주시 완산구",
    "정읍시",
    "진안군",
  ],
  제주특별자치도: ["서귀포시", "제주시"],
  충청남도: [
    "계룡시",
    "공주시",
    "금산군",
    "논산시",
    "당진시",
    "보령시",
    "부여군",
    "서산시",
    "서천군",
    "아산시",
    "예산군",
    "천안시 동남구",
    "천안시 서북구",
    "청양군",
    "태안군",
    "홍성군",
  ],
  충청북도: [
    "괴산군",
    "단양군",
    "보은군",
    "영동군",
    "옥천군",
    "음성군",
    "제천시",
    "증평군",
    "진천군",
    "청주시 상당구",
    "청주시 서원구",
    "청주시 청원구",
    "청주시 흥덕구",
    "충주시",
  ],
};

export const seminar_topic = [
  { value: "임플란트/상악동거상술", label: "임플란트/상악동거상술" },
  { value: "엔도/보존", label: "엔도/보존" },
  { value: "교정", label: "교정" },
  { value: "치과경영", label: "치과경영" },
  { value: "치주", label: "치주" },
  { value: "심미/보철", label: "심미/보철" },
  { value: "사랑니/외과", label: "사랑니/외과" },
  { value: "턱관절/내과", label: "턱관절/내과" },
  { value: "소아치과", label: "소아치과" },
  { value: "교합", label: "교합" },
  { value: "기타", label: "기타" },
];

export const korean_num = ["첫", "둘", "셋", "넷", "다섯", "여섯", "일곱", "여덟", "아홉", "열"];

export const pointOptions = [
  { value: 0, label: "없음" },
  { value: 1, label: "1점" },
  { value: 2, label: "2점" },
  { value: 3, label: "3점" },
  { value: 4, label: "4점" },
  { value: 5, label: "5점" },
  { value: 6, label: "6점" },
  { value: 7, label: "7점" },
  { value: 8, label: "8점" },
];

export const chicruiting_page = [
  "expectedRoles",
  "expectedWorkingTimes",
  "expectedCareer",
  "expectedSalery",
  "expectedWorkingDateType",
  "expectedWorkingTimeMemo",
  "providedWelfares",
  "memberCount",
  "description",
  "contactManager",
];

export const chicruiting_requests = [
  [
    { value: "office", label: "진료실" },
    { value: "consulting", label: "상담" },
    { value: "front_desk", label: "데스크" },
    { value: "insurance", label: "보험청구" },
    { value: "disinfection_room", label: "소독실" },
    { value: "operating_room", label: "수술실" },
    { value: "material_management", label: "재료 관리" },
    { value: "dental_products", label: "기공물 관리" },
    { value: "dental_care", label: "진료팀장" },
    { value: "consultant_lead", label: "상담실장" },
    { value: "general_manager", label: "총괄실장" },
    { value: "orthodontics", label: "교정과" },
  ],
  [
    { value: "full_time", label: "정규직" },
    { value: "contract_workers", label: "계약직" },
    { value: "part_time", label: "파트타임" },
  ],
  [
    { value: "newbie", label: "신입" },
    { value: "senior", label: "경력" },
    { value: "whatever", label: "무관" },
  ],
  [
    { value: "salaryWithoutTax", label: "세후 실수령 (월)" },
    { value: "salaryMemo", label: "직접입력" },
  ],
  [
    { value: "one_day_on_week", label: "주 1일" },
    { value: "two_days_on_week", label: "주 2일" },
    { value: "three_days_on_week", label: "주 3일" },
    { value: "four_days_on_week", label: "주 4일" },
    { value: "four_days_and_half_on_week", label: "주 4.5 일" },
    { value: "five_days_on_week", label: "주 5일" },
    { value: "five_days_on_week_with_interval_saturday", label: "주 5일 (토요일 격주 휴무)" },
    { value: "six_days_on_week", label: "주 6일" },
    { value: "dynamic_scheduling", label: "탄력적 근무" },
    { value: "negotiable", label: "협의가능" },
  ],
  [],
  [
    { value: "dormitory", label: "기숙사 지원" },
    { value: "tomorrow_filling_deduction", label: "내일채움공제" },
    { value: "insentive", label: "인센티브" },
    { value: "paid_holiday", label: "법정 연차" },
    { value: "support_seminar", label: "세미나 지원" },
    { value: "support_snack", label: "간식 지원" },
    { value: "support_lunch", label: "중식 제공" },
    { value: "support_dinner", label: "석식 제공 (야간 진료시)" },
  ],
  [],
  [],
  [
    { value: "name", label: "담당자명" },
    { value: "role", label: "직책" },
    {
      value: "phone",
      label: "연락처",
      detail: "연락이 가능한 휴대폰 번호를 적어주셔야 채용 진행이 가능합니다",
    },
    { value: "email", label: "이메일주소" },
  ],
];

export const hospitalOptions = [
  { value: "dental_clinic", label: "치과의원" },
  { value: "dental_hospital", label: "치과병원" },
  { value: "general_hospital", label: "종합병원" },
  { value: "university_hospital", label: "대학병원" },
  { value: "public_institutions", label: "공공기관" },
];

// 치식인
export const qnaVip = {
  "22년": [
    11700, 10270, 7555, 2555, 9266, 1787, 10398, 12715, 1228, 5419, 5341, 2023, 2957, 14673, 8627,
    4760, 5698, 2073,
  ],
  "23년": [
    2555, 10398, 1228, 9683, 8627, 1787, 14010, 21399, 2957, 1330, 11700, 3027, 9161, 9228, 20786,
    21405, 22827, 22810, 2897, 4803,
  ],
};

// 명의
export const forumVip = {
  "22년": [
    11700, 10270, 7555, 2087, 12337, 8980, 18010, 5828, 1139, 19921, 10313, 3161, 7489, 10663, 5590,
    1101, 4863, 5882,
  ],
  "23년": [
    2087, 11700, 4988, 19213, 21353, 18924, 20786, 10175, 5590, 1139, 22396, 12635, 21038, 7555,
    22810, 12830, 6692, 7058, 3263, 10270, 9836, 1101, 7354, 7871, 21012,
  ],
};

export const boardVip = {
  "23년": [
    4803, 10181, 22649, 8773, 2259, 5590, 5512, 5296, 6681, 21824, 4092, 12575, 970, 2203, 1656,
    19177, 8627, 858, 13573, 734,
  ],
};

export const cardCodes = [
  "361", // BC카드
  "364", // 광주카드
  "365", // 삼성카드
  "366", // 신한카드
  "367", // 현대카드
  "368", // 롯데카드
  "369", // 수협카드
  "370", // 씨티카드
  "371", // NH카드
  "372", // 전북카드
  "373", // 제주카드
  "374", // 하나SK카드
  "381", // KB국민카드
  "041", // 우리카드
  "071", // 우체국카드
  "VIS", // 해외비자카드
  "MAS", // 해외마스터카드
  "DIN", // 해외다이너스카드
  "AMX", // 해외아멕스카드
  "JCB", // 해외JCB카드
  "UNI", // 중국은련카드
  "DIS", // 해외디스커버카드
];

type cardColorsType = {
  [key: string]: {
    [key: string]: string;
  };
};

export const cardColors: cardColorsType = {
  361: {
    // 361 BC카드
    bgColor: "#FA3246",
    textColor: "#fff",
  },
  364: {
    // 364 광주카드
    bgColor: "#0C73CF",
    textColor: "#fff",
  },
  365: {
    // 365 삼성카드
    bgColor: "#1EA6E0",
    textColor: "#fff",
  },
  366: {
    // 366 신한카드
    bgColor: "#2B3FAB",
    textColor: "#fff",
  },
  367: {
    // 367 현대카드
    bgColor: "#222222",
    textColor: "#fff",
  },
  368: {
    // 368 롯데카드
    bgColor: "#E80012",
    textColor: "#fff",
  },
  369: {
    // 369 수협카드
    bgColor: "#066baf",
    textColor: "#fff",
  },
  370: {
    // 370 씨티카드
    bgColor: "#006CB3",
    textColor: "#fff",
  },
  371: {
    // NH카드
    bgColor: "#005A9E",
    textColor: "#fff",
  },
  372: {
    // 전북카드
    bgColor: "#0C73CF",
    textColor: "#fff",
  },
  373: {
    // 제주카드
    bgColor: "#2B3FAB",
    textColor: "#fff",
  },
  374: {
    // 하나SK카드
    bgColor: "#e61939",
    textColor: "#fff",
  },
  381: {
    // KB국민카드
    bgColor: "#FFAC00",
    textColor: "#000",
  },
  "002": {
    // KDB산업은행카드
    bgColor: "#066baf",
    textColor: "#000",
  },
  "041": {
    // 우리카드
    bgColor: "#08699a",
    textColor: "#fff",
  },
  "045": {
    // 새마을금고카드
    bgColor: "#1EA6E0",
    textColor: "#fff",
  },
  "048": {
    // 신협카드
    bgColor: "#08699a",
    textColor: "#fff",
  },
  "071": {
    // 우체국카드
    bgColor: "#e61939",
    textColor: "#fff",
  },
  "089": {
    // 케이뱅크카드
    bgColor: "#F75172",
    textColor: "#fff",
  },
  "090": {
    // 카카오뱅크카드
    bgColor: "#FFE04E",
    textColor: "#000",
  },
  VIS: {
    // 해외비자카드
    bgColor: "#0057a4",
    textColor: "#fff",
  },
  MAS: {
    // 해외마스터카드
    bgColor: "#e30c16",
    textColor: "#fff",
  },
  DIN: {
    // 해외다이너스카드
    bgColor: "#066baf",
    textColor: "#fff",
  },
  AMX: {
    // 해외아멕스카드
    bgColor: "#006fcf",
    textColor: "#fff",
  },
  JCB: {
    // 해외JCB카드
    bgColor: "#066baf",
    textColor: "#fff",
  },
  UNI: {
    // 중국은련카드
    bgColor: "#066baf",
    textColor: "#fff",
  },
  DIS: {
    // 해외디스커버카드
    bgColor: "#ff782f",
    textColor: "#fff",
  },
};

export const VBANK_LIST = [
  { value: "", label: "은행선택" },
  { value: "004", label: "KB국민은행" },
  { value: "088", label: "신한은행" },
  { value: "081", label: "하나은행" },
  { value: "020", label: "우리은행" },
  { value: "003", label: "기업은행" },
  { value: "011", label: "농협" },
  { value: "023", label: "SC제일은행" },
  { value: "032", label: "부산은행" },
  { value: "031", label: "대구은행" },
  { value: "071", label: "우체국" },
  { value: "089", label: "케이뱅크" },
];

export const BANK_NAME_LIST = [
  "기업은행",
  "NH농협은행",
  "국민은행",
  "신한은행",
  "씨티은행",
  "SC제일은행",
  "우리은행",
  "KEB하나은행",
  "대구은행",
  "부산은행",
  "경남은행",
  "전북은행",
  "제주은행",
  "수협은행",
  "신협중앙회",
  "새마을금고",
  "카카오뱅크",
  "케이뱅크",
  "우체국",
];

export const CEZERIN = {
  PAYMENT_METHOD_MEDIPAY_ID: "5d58dfb04e77525ec5ca11dd",
  PAYMENT_METHOD_CREDITCARD_ID: "5d23e6014b25b80d9fb1f3e6",
  PAYMENT_METHOD_VBANK_ID: "5d23e5eb4b25b80d9fb1f3e5",
  PAYMENT_METHOD_SEND_ID: "5d23e5dc4b25b80d9fb1f3e4",
  PAYMENT_METHOD_FIXED_VBANK_ID: "5d58dfb04e77525ec5ca11de",
  PAYMENT_METHOD_NAVERPAY_ID: "6437e5312a7937253f86b55e",
  PAYMENT_METHOD_PAYCO_ID: "6437e5312a7937253f86b54e",
  PAYMENT_METHOD_KAKAOPAY_ID: "6437e5312a7937253f86b58e",
  ORDER_STATUS_WISHLIST_ID: "5d1d485b4b25b80d9fb1f372",
  ORDER_STATUS_INVOICE_CART_ID: "611f5c51f7d29b27e94fd9a1",
  ORDER_STATUS_CART_ID: "5d1d4f134b25b80d9fb1f376",
  ORDER_STATUS_CHECKOUT_ID: "614198cde87f260b75adfea7",
  ORDER_STATUS_BUY_ID: "5e269a5d4267e105dfff0be0",
  ORDER_STATUS_ADVICE_ID: "5db015d758a95605b9ac5e34",
  ORDER_STATUS_UNPAID_ID: "5d1d61954b25b80d9fb1f379",
  ORDER_STATUS_PAID_ID: "5d48ead24e77525ec5ca1079",
  ORDER_STATUS_SHIP_PREPARE_ID: "5d48eb4a4e77525ec5ca107a",
  ORDER_STATUS_SHIP_PROGRESS_ID: "5d48eb4e4e77525ec5ca107b",
  ORDER_STATUS_DELIVERED_ID: "5d48eb534e77525ec5ca107c",
  ORDER_STATUS_HOLD_ID: "5d4f9a604e77525ec5ca10a5",
  ORDER_STATUS_CANCELLED_ID: "5d53f7cb4e77525ec5ca116d",
  ORDER_STATUS_CLOSED_ID: "5d53fa4a4e77525ec5ca1171",
  ORDER_STATUS_RETURN_REQUEST_COMPLETE_ID: "63588e67f0f7e100231c6a1c",
  ORDER_STATUS_RETURN_PROGRESS_ID: "63588e9ef0f7e100231c6a1e",
  ORDER_STATUS_RETURN_SHIP_CHARGE_STAND_BY_ID: "63588eb6f0f7e100231c6a20",
  ORDER_STATUS_RETURN_COMPLETE_ID: "63588ea5f0f7e100231c6a1f",
  ORDER_STATUS_ERROR_ID: "5d58df1e4e77525ec5ca11dc",
  ORDER_STATUS_DEVELOPER_PROCESS_ID: "5f522c2aa0b91001bf48f2a1",
  ORDER_STATUS_TEAM_IN_PROGRESS_ID: "651cebee8db7af81523237f2",
  ORDER_STATUS_TEAM_COMPLETE_ID: "651d0020e074ad6d1db9e6ed",
  CATEGORY_BOOK_ID: "5cf8bbba0098b2225c5dfaa3",
  CATEGORY_LECTURE_ID: "5ea40b671a3ca36b967849ad",
  CATEGORY_OFFLINE_LECTURE_ID: "602f886d527223001a20995b",
  CATEGORY_LIVE_LECTURE_ID: "63bba3c57901380023b54447",
  CATEGORY_MEDICINE_ID: "5cf8bbba0098b2225c5dfaa2",
  CATEGORY_MEDICINAL_HERBS_ID: "5fb32f7a5120316334323fd8",
  CATEGORY_EXPENDABLE_ID: "5e91ce6fbfe42609447852d1",
  CATEGORY_BEST_ID: "5ebfd0c3e510ee503167a7cc",
  CATEGORY_COUPON_ID: "5ee19600e510ee503167cb3d",
  CATEGORY_PROMOTION_ID: "6184c585bc6341002347469f",
  CATEGORY_HOME_ID: "5e5d8beabfe4260944782c14",
  CATEGORY_CODED_PRESCRIPTION_ID: "62fc4baf0b5b9e002b2e0277",
  CATEGORY_MEDICASH_CHARGE_ID: "638ff7cbd105df0022020ea6",
  CATEGORY_MEDITV_ID: "62bc13f608e04900234e38d0",
  EXPOSURE_CATEGORY_ACUPUNCTURE_ID: "63087825a855ef615d5660fb", // for router
  SUB_CATEGORY_ACUPUNCTURE_ID: "63116f29974f2b0022f86b35", // for fetch coupons
  FUNCTIONAL_CATEGORY_LIQUEUR_ID: "600f852b527223001a206b02",
  FUNCTIONAL_CATEGORY_SHIPPING_PRICES_ID: "5fa3d1395120316334321d9d",
  FUNCTIONAL_CATEGORY_SHIPPINGFREE_THRESHOLDS_ID: "5fcf14231c6ef3001a114caf",
  FUNCTIONAL_CATEGORY_TAXFREE_ID: "5f9a5f1b51203163343206bd",
  FUNCTIONAL_CATEGORY_MEDIPAYBENEFIT_DISABLE_ID: "5f920ad0512031633431f561",
  FUNCTIONAL_CATEGORY_ONETIMEPAY_ID: "5eec20a4e510ee503167cff4",
  FUNCTIONAL_CATEGORY_SUBSCRIPTION_BILLING_ID: "60766d350ccc56001bc7b422",
  FUNCTIONAL_CATEGORY_AVAILABLE_ONLY_COMPANY: "60b84ce20ccc56001bc7fac5",
  FUNCTIONAL_CATEGORY_NOSHIPPINGDATA_ID: "5f87ed35512031633431e506", // 배송 물품 없음
  FUNCTIONAL_CATEGORY_CULTURE_DEDUCTION_ID: "643e686517aab9cb0fe4f3d1", // 문화비소득공제 가능
  FUNCTIONAL_CATEGORY_REQUESTDEAL_ID: "622f04b0f967570023fad813",
  FUNCTIONAL_CATEGORY_EXCLUSIVE_ID: "6200d5050088a50032f5fc52",
  FUNCTIONAL_CATEGORY_DEACTIVATION_ESTIMATED_DELIVERY_ID: "62ecbae5a670760022a3dbf5",
  FUNCTIONAL_CATEGORY_EXPOSE_PREPARE_MEDICINE_BUTTON_ID: "62f5be6a0b5b9e002b2de45b",
  FUNCTIONAL_CATEGORY_REPORT_MEDICAL_SUPPLY_ID: "60b84ce20ccc56001bc7fac5",
  FUNCTIONAL_CATEGORY_BUSINESS_ONLY_ID: "62f5bed50b5b9e002b2de45f",
  FUNCTIONAL_CATEGORY_BUYER_ADDRESS_ONLY_ID: "62f5beb70b5b9e002b2de45e",
  FUNCTIONAL_CATEGORY_LIMIT_ACCESS_ID: "634659e36c1d36001a839882",
  FUNCTIONAL_CATEGORY_COUPON_UNAVAILABLE_ID: "6201c84f0088a50032f5ff4c",
  FUNCTIONAL_CATEGORY_DISABLE_CASH: '"64f958ed60bce20031308c2f"',
  FUNCTIONAL_CATEGORY_DISABLE_MEDIPAY: '"64f9591560bce20031308c30"',
  FUNCTIONAL_CATEGORY_DISABLE_NAVERPAY: '"64f9593060bce20031308c31"',
  FUNCTIONAL_CATEGORY_DISABLE_PAYCO: '"64f9594c60bce20031308c32"',
  FUNCTIONAL_CATEGORY_DISABLE_KAKAOPAY: '"64f9596460bce20031308c35"',
  FUNCTIONAL_CATEGORY_DISABLE_CREDITCARD: '"64f9597960bce20031308c37"',
  FUNCTIONAL_CATEGORY_DISABLE_RESERVATION_SHIPPING_ID: '"65364f39cc75020022b837b2"',
  FUNCTIONAL_CATEGORY_TEAM_PRODUCT_TEAM_PURCHASE: '"6526426fa354630022f228c3"',
  FUNCTIONAL_CATEGORY_TEAM_PRODUCT_SINGLE_PURCHASE: '"6526426fa354630022f228c3"',
  FUNCTIONAL_CATEGORY_REFRIGERATED_PRODUCT_ID: '"6572a4e87759134febc3a7b5"',
  IMAGE_BASE_URL: "https://warehouse.medistream.co.kr/images/products",
  CURATION_SAVE_SHIPPING_CHARGE_ID: "63369a7d224d0a002350fba4",
  MEDICASH_PRODUCT_GROUP_ID: "6392d1fdd105df0022021f2e",
};

export const DELIVERY_METHODS = {
  DELIVERY_TRACKING_EXCLUDED: "deliveryTrackingExcluded",
  PACKAGE_DELIVERY: "packageDelivery",
  SETUP_DELIVERY: "setupDelivery",
  CUSTOM_ORDER_DELIVERY: "customOrderDelivery",
  ONLINE_CLASS_DELIVERY_WITH_BOOK: "onlineClassDeliveryWithBook",
  ONLINE_CLASS_DELIVERY_WITHOUT_BOOK: "onlineClassDeliveryWithoutBook",
  OFFLINE_CLASS_DELIVERY_WITHOUT_BOOK: "offlineClassDeliveryWithoutBook",
  INTANGIBLE_SERVICE_DELIVERY: "intangibleServiceDelivery",
  SYSTEM_SERVICE_DELIVERY: "systemServiceDelivery",
};

export const NO_SHIPPING_DELIVERY_METHODS = [
  DELIVERY_METHODS.ONLINE_CLASS_DELIVERY_WITHOUT_BOOK,
  DELIVERY_METHODS.OFFLINE_CLASS_DELIVERY_WITHOUT_BOOK,
  DELIVERY_METHODS.DELIVERY_TRACKING_EXCLUDED,
  DELIVERY_METHODS.INTANGIBLE_SERVICE_DELIVERY,
  DELIVERY_METHODS.SYSTEM_SERVICE_DELIVERY,
];

export const TOPICS = [
  {
    name: "IMPL",
    label: "임플란트",
    img: "img/ico-topic-implant.svg",
  },
  {
    name: "GBR",
    label: "GBR/상악동거상술",
    img: "img/ico-topic-gbr.svg",
  },
  {
    name: "ENDO",
    label: "엔도",
    img: "img/ico-topic-endodontics.svg",
  },
  {
    name: "PROS",
    label: "보철",
    img: "img/ico-topic-prosthetic.svg",
  },
  {
    name: "MGMT",
    label: "개원/경영",
    img: "img/ico-topic-management.svg",
  },
  {
    name: "EXT",
    label: "발치",
    img: "img/ico-topic-extraction.svg",
  },
  {
    name: "ESTHE",
    label: "심미",
    img: "img/ico-topic-beauty.svg",
  },
  {
    name: "OMFS",
    label: "구강외과",
    img: "img/ico-topic-omfs.svg",
  },
  {
    name: "RESTO",
    label: "보존",
    img: "img/ico-topic-conservative.svg",
  },
  {
    name: "IMPL/PROS",
    label: "임플란트보철/인상채득",
    img: "",
  },
  {
    name: "SUTURE",
    label: "봉합/절개",
    img: "",
  },
  {
    name: "OCCLUSAL",
    label: "교합",
    img: "",
  },
  {
    name: "PRIO",
    label: "치주",
    img: "",
  },
  {
    name: "ORTHO",
    label: "교정",
    img: "",
  },
  {
    name: "TMD/MED",
    label: "TMD/구강내과",
    img: "",
  },
  {
    name: "CD/RPD",
    label: "CD/RPD",
    img: "",
  },
  {
    name: "PEDI",
    label: "소아치과",
    img: "",
  },
  {
    name: "CLAIM",
    label: "보험청구/처방",
    img: "",
  },
  {
    name: "RADIO",
    label: "영상치의학",
    img: "",
  },
  {
    name: "DIGITAL",
    label: "디지털",
    img: "",
  },
  {
    name: "HANDS-ON",
    label: "핸즈온",
    img: "",
  },
  {
    name: "TRAUMA",
    label: "외상",
    img: "",
  },
  {
    name: "PUBLIC-HEALTH-DOCTOR",
    label: "공보의",
    img: "",
  },
  {
    name: "ETC",
    label: "기타",
    img: "",
  },
];

export const TOOTH_NUMBERS = [
  // 상악 우측 (10번대)
  {
    zone: "upper-right",
    id: 11,
    img: "img/ic_tooth_number_11.svg",
  },
  {
    zone: "upper-right",
    id: 12,
    img: "img/ic_tooth_number_12.svg",
  },
  {
    zone: "upper-right",
    id: 13,
    img: "img/ic_tooth_number_13.svg",
  },
  {
    zone: "upper-right",
    id: 14,
    img: "img/ic_tooth_number_14.svg",
  },
  {
    zone: "upper-right",
    id: 15,
    img: "img/ic_tooth_number_15.svg",
  },
  {
    zone: "upper-right",
    id: 16,
    img: "img/ic_tooth_number_16.svg",
  },
  {
    zone: "upper-right",
    id: 17,
    img: "img/ic_tooth_number_17.svg",
  },
  {
    zone: "upper-right",
    id: 18,
    img: "img/ic_tooth_number_18.svg",
  },
  // 상악 좌측 (20번대)
  {
    zone: "upper-left",
    id: 21,
    img: "img/ic_tooth_number_21.svg",
  },
  {
    zone: "upper-left",
    id: 22,
    img: "img/ic_tooth_number_22.svg",
  },
  {
    zone: "upper-left",
    id: 23,
    img: "img/ic_tooth_number_23.svg",
  },
  {
    zone: "upper-left",
    id: 24,
    img: "img/ic_tooth_number_24.svg",
  },
  {
    zone: "upper-left",
    id: 25,
    img: "img/ic_tooth_number_25.svg",
  },
  {
    zone: "upper-left",
    id: 26,
    img: "img/ic_tooth_number_26.svg",
  },
  {
    zone: "upper-left",
    id: 27,
    img: "img/ic_tooth_number_27.svg",
  },
  {
    zone: "upper-left",
    id: 28,
    img: "img/ic_tooth_number_28.svg",
  },
  // 하악 좌측 (30번대)
  {
    zone: "lower-left",
    id: 31,
    img: "img/ic_tooth_number_31.svg",
  },
  {
    zone: "lower-left",
    id: 32,
    img: "img/ic_tooth_number_32.svg",
  },
  {
    zone: "lower-left",
    id: 33,
    img: "img/ic_tooth_number_33.svg",
  },
  {
    zone: "lower-left",
    id: 34,
    img: "img/ic_tooth_number_34.svg",
  },
  {
    zone: "lower-left",
    id: 35,
    img: "img/ic_tooth_number_35.svg",
  },
  {
    zone: "lower-left",
    id: 36,
    img: "img/ic_tooth_number_36.svg",
  },
  {
    zone: "lower-left",
    id: 37,
    img: "img/ic_tooth_number_37.svg",
  },
  {
    zone: "lower-left",
    id: 38,
    img: "img/ic_tooth_number_38.svg",
  },
  // 하악 우측 (40번대)
  {
    zone: "lower-right",
    id: 41,
    img: "img/ic_tooth_number_41.svg",
  },
  {
    zone: "lower-right",
    id: 42,
    img: "img/ic_tooth_number_42.svg",
  },
  {
    zone: "lower-right",
    id: 43,
    img: "img/ic_tooth_number_43.svg",
  },
  {
    zone: "lower-right",
    id: 44,
    img: "img/ic_tooth_number_44.svg",
  },
  {
    zone: "lower-right",
    id: 45,
    img: "img/ic_tooth_number_45.svg",
  },
  {
    zone: "lower-right",
    id: 46,
    img: "img/ic_tooth_number_46.svg",
  },
  {
    zone: "lower-right",
    id: 47,
    img: "img/ic_tooth_number_47.svg",
  },
  {
    zone: "lower-right",
    id: 48,
    img: "img/ic_tooth_number_48.svg",
  },
];

export const LECTURE_STATUS = {
  before: "수강 전",
  completed: "수강 완료",
  inProgress: "수강 중",
};

export const SHIPPING_MESSAGE_LIST = [
  "배송 전 연락바랍니다.",
  "택배함에 넣어주세요.",
  "문 앞에 놓아주세요.",
  "부재 시 경비실에 맡겨주세요.",
  "부재 시 전화 또는 문자 주세요.",
  "직접입력 (배송일 지정은 불가합니다)",
];

export const HIDE_PRODUCT_ATTRIBUTES = [
  // MARK: 상품 정보에서 일반 사용자에게 노출하지 않을 attribute 목록
  "supplier_name", // 공급자 상호명
  "supplier_code", // 공급자 사업자번호
  "supply_code", // 공급자의 물품 코드
  "order_request_email", // 발주서 수신 이메일
  "order_request_phone", // 발주서 수신 phone
  "payment_card_disable", // 결제수단 제한 (카드)
  "payment_cash_disable", // 결제수단 제한 (무통장)
  "extra_requirement_comment", // 추가 요청사항 필드 설명
  "count_unit_format", // 상품 선택단위 포맷
  "advisor_name", // 상담 담당자 이름
  "advisor_phone", // 상담 담당자 연락처
  "action_button_text", // 구매하기 버튼 텍스트 임의 부여
  "related_article", // 연관 아티클 (링크 리다이렉트)
  "related_lecture", // 연관 강의글 (링크 리다이렉트)
];
export const SEARCH_INDICES = [
  {
    name: "all",
    title: "전체",
  },
  {
    name: "market",
    title: "마켓",
  },
  {
    name: "classes",
    title: "클래스",
  },
  {
    name: "content",
    title: "콘텐츠",
  },
  {
    name: "post",
    title: "게시판",
  },
  {
    name: "qna",
    title: "Q&A",
    sub: "qna_comment",
  },
  {
    name: "forum",
    title: "임상포럼",
    sub: "forum_comment",
  },
  {
    name: "recruit",
    title: "구인구직",
  },
  {
    name: "seminar",
    title: "세미나",
  },
];

export const MARKET_PRODUCT_SORTING_LIST = [
  {
    label: "인기순",
    value: "three_months:desc",
  },
  {
    label: "최신순",
    value: "date_created:desc",
  },
  {
    label: "과거순",
    value: "date_created:asc",
  },
  {
    label: "높은 가격순",
    value: "sale_price:desc",
  },
  {
    label: "낮은 가격순",
    value: "sale_price:asc",
  },
  {
    label: "이름순",
    value: "meta_title:asc",
  },
];
