import { wrappingInputRule } from "prosemirror-inputrules";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { wrapInList } from "prosemirror-schema-list";

/**
 * Given a list node type, returns an input rule that turns a number
 * followed by a dot at the start of a textblock into an ordered list.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @returns
 */
const orderedListRule = (nodeType) => {
  return wrappingInputRule(
    /^(1)\.\s$/,
    nodeType,
    match => ({order: +match[1]}),
    (match, node) => node.childCount + node.attrs.order == +match[1],
  )
}

export const OrderedList = Extension.Create({
  name: 'ordered_list',

  type: 'node',

  defineSpec() {
    return {
      group: 'block',
      content: 'list_item+',
      attrs: {order: {default: 1}},
      toDOM: node => ['ol', {class: MEDISTREAM_SCHEMA_STYLE.nodes.ol}, 0],
      parseDOM: [
        {
          tag: 'ol',
          /**
           * @param {HTMLElement} dom
          */
         getAttrs: dom => ({
           order: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1,
          }),
        },
      ],
    }
  },
    
  addCommands() {
    return {
      wrapInOrderedList: wrapInList(this.schema.nodes[this.name])
    }
  },

  addInputRules() {
    return [orderedListRule(this.schema.nodes[this.name])]
  }
})
