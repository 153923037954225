import { TextSelection } from "prosemirror-state";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { BlockQuote } from "./blockquote";
import { Paragraph } from "./paragraph";

/**
 * 다음 블록에 Horizontal Rule 노드를 삽입합니다.
 *
 * @type {import('prosemirror-state').Command}
 */
export function addHorizontalRuleNextLine(state, dispatch, view) {
  if (!state.selection.empty) return false

  const hrNode = state.schema.nodes[HorizontalRule.name].create()
  const paragraphNode = state.schema.nodes[Paragraph.name].create()
  const selectionHead = state.selection.$head
  const isInsideBlockquote =
    selectionHead.node(selectionHead.depth - 1).type.name === BlockQuote.name

  // 인용문 안에 있을 경우 인용문 밖에 가로줄을 삽입하고, 그 밑에 본문 블록을 삽입합니다.
  if (isInsideBlockquote) {
    const insertAt = selectionHead.after(selectionHead.depth - 1)
    const tr = state.tr
      .insert(insertAt, hrNode)
      .insert(insertAt + 1, paragraphNode)
    const tr2 = tr.setSelection(TextSelection.create(tr.doc, insertAt + 2))

    dispatch(tr2)
    return true
  }

  // 다음 블럭에 가로줄, 그 다음 블럭에 본문 블록을 삽입합니다.
  const endPosOfCurNode = selectionHead.end(selectionHead.depth)
  const tr = state.tr
    .insert(endPosOfCurNode + 1, hrNode)
    .insert(endPosOfCurNode + 2, paragraphNode)
  const tr2 = tr.setSelection(TextSelection.create(tr.doc, endPosOfCurNode + 3))

  dispatch(tr2)
  return true
}

class HorizontalRuleNodeView {
  /**
   * @type {import('prosemirror-view').NodeViewConstructor}
   */
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos

    const hr = document.createElement('hr')
    hr.style.clear = 'both'
    hr.style.border = 'solid #dedede'
    hr.style.borderWidth = '0 0 1px'
    this.node.attrs.style && hr.setAttribute('style', this.node.attrs.style)

    this.dom = document.createElement('div')
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.etc.hrWrapper)
    this.dom.style.width = '100%'
    this.dom.append(hr)
  }
}

export const horizontalRuleNodeView = (node, view, getPos) =>
  new HorizontalRuleNodeView(node, view, getPos)

export const HorizontalRule = Extension.Create({
  name: 'horizontal_rule',

  type: 'node',

  defineSpec() {
    return {
      group: 'block',
      parseDOM: [{tag: 'hr'}],
      toDOM() {
        return ['hr', {class: MEDISTREAM_SCHEMA_STYLE.nodes.horizontal_rule}]
      },
    }
  },

  addCommands() {
    return {
      addHorizontalRuleNextLine,
    }
  },

  addNodeView() {
    return horizontalRuleNodeView
  }
})
