import React from "react";

const LoanPopup = ({ setPopup, txt }) => {
  return (
    <>
      <div className="popup-overlay" onClick={() => setPopup(false)}>
        {" "}
      </div>
      <div>
        <div className="loan-popup-wrap">
          <button className="loan-popup-close" onClick={() => setPopup(false)} />
          <div className="txt-wrap">
            <div className="txt text-center">{txt}</div>
            <button className="btn" onClick={() => setPopup(false)}>
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanPopup;
