import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { Extension } from "../core/extension";
import { toggleMark } from "prosemirror-commands";

export const MoredenHighlighted = Extension.Create({
  name: 'moreden_highlighted',

  type: 'mark',

  defineSpec() {
    return {
      toDOM() {
        return [
          "bdi",
          { class: MEDISTREAM_SCHEMA_STYLE.marks.bdi },
          0,
        ];
      },
      parseDOM: [{ tag: "bdi" }],
    }
  },

  addCommands() {
    return {
      toggleMoredenHighlighted: (state, dispatch) => toggleMark(state.schema.marks[this.name])(state, dispatch),
    }
  }
})
