import { useEffect, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { useRecoilValue } from "recoil";

import { CLASS_ROUTE } from "App";
import Loading from "component/Loading";
import LectureHeader from "component/klass/LectureHeader";
import LectureContent from "component/klass/LectureContent";
import SidePanel from "component/klass/SidePanel";
import { publicApi, scrollTopList } from "utils/util";
import { AllCourses } from "types/klass";
import { tokenState } from "state";

const KlassLecture: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);

  const curriculumRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const scrollToCurriculum = () => {
    if (!curriculumRef.current) throw new Error("curriculumRef is not defined");

    scrollTopList(curriculumRef, "smooth");
  };

  const { data: lectureList, isLoading: lectureListLoading } = useQuery<
    AllCourses | undefined,
    Error
  >(
    ["classCourses", id, token],
    () =>
      publicApi
        ?.get(
          token && (token.user_type === "doctor" || token.user_type === "student")
            ? `/class/courses/${id}`
            : `/class/public/courses/${id}`,
        )
        .then((res) => res.data),
    {
      onError: (err: any) => {
        //todo:any type fix
        if (err.response.status === 401) {
          alert("구매할 수 없는 회원입니다. 관리자에게 문의해주세요.");
        } else if (err.response.status === 404) {
          alert("존재하지 않는 강의입니다.");
          navigate(-1);
        }
      },
    },
  );

  // MARK: 자식 컴포넌트에 전달함.
  // 현재 코스가 패키지에 속해 있고 대표 코스가 아닌 경우에 대표 코스 id를 반환
  const defaultCourseId = useMemo(
    () =>
      (lectureList?.collection?.courses?.some((course) => course.courseId === id) &&
        lectureList?.collection?.defaultCourseId !== id &&
        lectureList?.collection?.defaultCourseId) ||
      undefined,
    [id, lectureList?.collection?.courses, lectureList?.collection?.defaultCourseId],
  );

  // MARK: id(강의 id)가 없을 때의 분기 처리가 되어있지 않았으므로 추가함. KlassRoute라는 컴포넌트에서 id가 없을때의 이벤트를 관리하고있긴하나, 명시적인 타입 처리를 위해 컴포넌트에서도 분기처리를 해주는게 좋을것같음.
  if (!id) {
    navigate(CLASS_ROUTE);

    return <></>;
  }

  if (!lectureList || lectureListLoading) return <Loading small />;

  return (
    <div className="relative max-w-[1200px] m-auto pt-10">
      <div className="mb-5 px-4 lg:px-0">
        <div className="text-gray-600 mb-2 text-sm lg:text-base font-semibold">
          {lectureList.course.groupedBadges.level?.join("")} |{" "}
          {lectureList.course.groupedBadges.topics?.slice(0, 4).join(", ")}
        </div>
        <h3 className="text-gray-900 text-lg lg:text-[25px] font-semibold break-all overflow-hidden text-ellipsis line-clamp-2 lg:line-clamp-1">
          {lectureList.course.title}
        </h3>
      </div>
      <div className="lg:flex lg:justify-between">
        <div className="lg:max-w-[694px]">
          <LectureHeader
            course={lectureList.course}
            collection={lectureList.collection}
            studentCourses={lectureList.studentCourses}
          />
          <LectureContent
            ref={curriculumRef}
            courseId={id}
            lectureList={lectureList}
            scrollToCurriculum={scrollToCurriculum}
          />
        </div>
        <div className="lg:w-[450px]">
          <div className="w-full sticky top-[104px]">
            <SidePanel
              course={lectureList.course}
              progress={lectureList.progress}
              defaultCourseId={defaultCourseId}
              scrollToCurriculum={scrollToCurriculum}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KlassLecture;
