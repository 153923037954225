import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../utils/util";

const BlockAlert = ({ bid, setBlockAlert, isAnonymous, blockReference }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const onClickBlock = () => {
    const data = {
      category:
        location.pathname.split("/")[1] === "post" ? "board" : location.pathname.split("/")[1],
      id: bid,
    };
    api
      .post(`/blocks/legacy?type=nickname`, data)
      .then((res) => {
        alert("해당 유저가 차단되었습니다");
        navigate(-1);
        setBlockAlert(false);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };
  const onClickAnonymousBlock = () => {
    const data = {
      category:
        location.pathname.split("/")[1] === "post" ? "board" : location.pathname.split("/")[1],
      boardId: bid,
      blockReference: blockReference,
    };

    api
      .post(`/blocks/legacy?type=anonymous`, data)
      .then((res) => {
        alert("해당 유저가 차단되었습니다");
        navigate(-1);
        setBlockAlert(false);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };
  return (
    <>
      <div className="popup-overlay" onClick={() => setBlockAlert(false)}></div>
      <article className="popup popup-addr" style={{ display: "block" }}>
        <div className="popup-wrap">
          <div className="head">
            <div className="popup-tit">
              <i className="ic ic-logo"></i>
              <span className="txt">작성자 차단</span>
            </div>

            <span onClick={() => setBlockAlert(false)} className="close-btn"></span>
          </div>
          <div className="body">
            <article className="pop__addr">
              <div className="result__group">
                <div className="no-data">
                  해당 작성자의 글을 차단할 수 있습니다.
                  <br />
                  작성자를 차단하시겠습니까?
                </div>
              </div>
            </article>

            <div className="popup__menu">
              <button onClick={() => setBlockAlert(false)} className="pop-menu-btn">
                취소
              </button>
              <button
                onClick={isAnonymous ? onClickAnonymousBlock : onClickBlock}
                className="pop-menu-btn type1"
              >
                차단
              </button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default BlockAlert;
