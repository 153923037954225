import { useSearchParams } from "react-router-dom";
import { resizedImg, Img, seminarDttm } from "utils/util";
import { highlightedText } from "utils/searchUtil";

const SearchItemSeminar = ({
  tag,
  title,
  thumbnail,
  location1,
  location2,
  start_date,
  end_date,
  point,
}) => {
  const [searchParams] = useSearchParams();
  const params = {
    indexUid: searchParams.get("indexUid") || "",
    q: searchParams.get("q") || "",
  };
  const q = params.q;
  return (
    <div className="py-4">
      <div className="overflow-hidden">
        {thumbnail && (
          <figure className="float-right ml-4">
            <img
              className="w-[100px] h-[100px] object-cover"
              src={resizedImg(Img(thumbnail), 200)}
              alt=""
            />
          </figure>
        )}
        <span className="inline-block mb-3 px-2 py-1 rounded-full text-[12px] bg-gray-100">
          {tag}
        </span>
        <h4 className="mb-2 text-[16px] font-bold">{highlightedText(title, q)}</h4>
        <div className="flex items-center text-secondary-500 mb-1">
          <i className="ii ii-location-outline text-base text-secondary-500"></i>
          <div className="text-[13px]">
            {location1} {location2}
          </div>
        </div>
        <div className="flex items-center text-secondary-500">
          <i className="ii ii-calendar-outline text-base text-secondary-500"></i>
          <div className="text-[13px]">
            {seminarDttm(start_date)} ~ {seminarDttm(end_date)} | 보수교육점수 {point}점
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchItemSeminar;
