import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { cls } from "utils/util";
import StyledRadioBtn from "component/shared/StyledRadioBtn";

const UID_TITLE_INFO = {
  all: "전체",
  market: "마켓",
  classes: "클래스",
  content: "콘텐츠",
  post: "게시판",
  qna: "Q&A",
  forum: "임상포럼",
  recruit: "구인구직",
  seminar: "세미나",
};

const SearchController = ({
  onSubmitKeyword,
  displayedUidList,
  selectedUid,
  onSelectedUid,
  resultsTotalCount,
  selectedSearchTarget,
  onSelectedSearchTarget,
  isShowSearchTargetRadio,
  topicDropdownOptions,
  selectedTopicList,
  onSelectedTopicList,
}) => {
  const [searchParams] = useSearchParams();
  const params = {
    indexUid: searchParams.get("indexUid") || "",
    q: searchParams.get("q") || "",
    topics: JSON.parse(searchParams.get("topics") || "[]"),
  };

  return (
    <div className="flex flex-col items-center">
      <SearchBar onSubmitKeyword={onSubmitKeyword} params={params} />
      <IndexUidBar
        displayedUidList={displayedUidList}
        selectedUid={selectedUid}
        onSelectedUid={onSelectedUid}
      />
      {params?.indexUid && (
        <>
          <TopicBar
            topicDropdownOptions={topicDropdownOptions}
            selectedTopicList={selectedTopicList}
            onSelectedTopicList={onSelectedTopicList}
          />
          <div className="flex items-center justify-between w-full mt-[20px]">
            <EstimatedHitsBar
              resultsTotalCount={resultsTotalCount}
              selectedUid={selectedUid}
              params={params}
            />
            {isShowSearchTargetRadio && (
              <SearchTargetBar
                selectedSearchTarget={selectedSearchTarget}
                onSelectedSearchTarget={onSelectedSearchTarget}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default SearchController;

const SearchBar = ({ onSubmitKeyword, params }) => {
  const [searchKeyword, setSearchKeyword] = useState(params.q || "");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmitKeyword(searchKeyword);
    }
  };

  // ---------- 삭제 버튼 클릭시 동작로직 ----------
  const [clickedRemoved, setClickedRemoved] = useState(false);
  const handleClickRemove = () => {
    setSearchKeyword("");
    setClickedRemoved(true);
  };
  useEffect(() => {
    if (clickedRemoved && searchKeyword === "") {
      onSubmitKeyword(searchKeyword);
      setClickedRemoved(false);
    }
  }, [clickedRemoved, searchKeyword, onSubmitKeyword]);

  // ---------- 최상단 header 검색과 검색어 동기화를 위한 로직 ----------
  useEffect(() => {
    if (params?.q !== searchKeyword) {
      setSearchKeyword(params?.q || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className="relative w-[420px] mb-[20px]">
      <i className="ii ii-search absolute left-[1px] px-2.5 h-full flex items-center leading-[24px] text-[24px] text-gray-800"></i>
      <input
        className={cls(
          "w-full h-[40px] px-[40px] border border-solid border-secondary-200 rounded-md bg-gray-50 text-[15px]",
          "focus:ring-1 focus:ring-primary-800",
        )}
        type="text"
        placeholder={"검색어를 입력해주세요"}
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        onKeyDown={handleKeyPress}
      />
      {searchKeyword && (
        <i
          className="ii ii-remove-circle h-full leading-[20px] text-[20px] text-gray-800 absolute top-0 right-[1px] cursor-pointer px-2.5 flex items-center"
          onClick={handleClickRemove}
        ></i>
      )}
    </div>
  );
};

const IndexUidBar = ({ displayedUidList, selectedUid, onSelectedUid }) => {
  const handleClick = (uid) => {
    onSelectedUid(uid);
  };
  return (
    <div className="w-full inline-block h-[50px] mb-[16px]">
      <div className="w-full overflow-auto scroll_none">
        <ul className="w-full flex gap-5 text-[18px] whitespace-nowrap">
          {displayedUidList.map((uid) => {
            return (
              <li className="inline-block" key={uid.name}>
                <span
                  onClick={() => handleClick(uid.name)}
                  className={cls(
                    "block relative py-3 font-semibold text-[16px] hover:cursor-pointer",
                    uid.name === selectedUid ||
                      uid?.sub === selectedUid ||
                      (uid.name === "" && selectedUid === "all")
                      ? "text-primary-800 border-solid border-b-2 border-primary-800"
                      : "text-secondary-500 border-b-2 border-transparent",
                  )}
                >
                  {uid.title}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const TopicBar = ({ topicDropdownOptions, selectedTopicList, onSelectedTopicList }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  // ---------- 드롭다운 외부 클릭시 닫힘 ----------
  const dropdownRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  if (!topicDropdownOptions?.length) return null;
  return (
    <div ref={dropdownRef} className="w-full flex justify-between items-center">
      <div className="relative border border-solid border-secondary-200 rounded-md">
        <button
          onClick={() => setShowDropdown(!showDropdown)}
          className="flex items-center justify-between w-64 p-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        >
          <span className="truncate">토픽 검색</span>
          <i className="ii ii-chevron-down ii-weight-600"></i>
        </button>
        {showDropdown && (
          <div className="absolute z-10 mt-1 w-64 bg-white border border-gray-300 rounded-md shadow-lg">
            <div className="flex flex-col">
              {topicDropdownOptions.map((option, index) => {
                return (
                  <div
                    key={option.value}
                    onClick={() => onSelectedTopicList(option.label)}
                    className={cls(
                      selectedTopicList.includes(option.label) ? "bg-secondary-100" : "",
                      index === topicDropdownOptions.length - 1
                        ? ""
                        : "border-b border-solid border-b-gray-200",
                      "p-2 cursor-pointer hover:bg-secondary-50 text-[12px]",
                    )}
                  >
                    {selectedTopicList.includes(option.label) && (
                      <i className="ii ii-check ii-weight-600 mr-2 text-[12px]"></i>
                    )}
                    {option.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="max-w-[400px] flex flex-wrap items-center text-[12px] gap-x-1.5 gap-y-2">
        {selectedTopicList.map((topic, index) => (
          <div
            className="max-w-fit flex items-center rounded-full bg-gray-100 justify-start whitespace-nowrap px-2 py-1 cursor-pointer"
            key={index}
            onClick={() => onSelectedTopicList(topic)}
          >
            <span>{topic}</span>
            <i className="ii ii-remove text-[12px] w-[12px] h-[12px]"></i>
          </div>
        ))}
      </div>
    </div>
  );
};

const EstimatedHitsBar = ({ resultsTotalCount, selectedUid, params }) => {
  const limitDisplayCount = selectedUid === "all" ? 1000000 : 1000;
  const isSelectedTopics = params?.topics?.length > 0;

  return (
    <div className="flex items-center justify-between">
      <h3 className="align-middle text-secondary-800 text-[16px] lg:text-[18px] font-semibold">
        {UID_TITLE_INFO?.[selectedUid] || ""} 검색결과{" "}
        {!isSelectedTopics && (
          <span className="text-secondary-500">
            {resultsTotalCount > limitDisplayCount ? limitDisplayCount : resultsTotalCount}
            {resultsTotalCount > 999 && `개 이상`}
          </span>
        )}
      </h3>
    </div>
  );
};

const SearchTargetBar = ({ selectedSearchTarget, onSelectedSearchTarget }) => {
  const [selectedRadio, setSelectedRadio] = useState(
    selectedSearchTarget === "comments" ? "comments" : "titleAndContent",
  );
  const handleRadio = (radioId) => {
    setSelectedRadio(radioId);
    if (radioId === "comments") {
      onSelectedSearchTarget("comments");
    } else {
      onSelectedSearchTarget("");
    }
  };

  return (
    <div className="flex gap-3">
      <StyledRadioBtn
        value={selectedRadio}
        checked={selectedRadio === "titleAndContent"}
        onChange={() => handleRadio("titleAndContent")}
      >
        <span>제목 + 내용</span>
      </StyledRadioBtn>
      <StyledRadioBtn
        value={selectedRadio}
        checked={selectedRadio === "comments"}
        onChange={() => handleRadio("comments")}
      >
        <span>댓글</span>
      </StyledRadioBtn>
    </div>
  );
};
