import { useEffect } from "react";
import { Link } from "react-router-dom";

const LoanPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <div>
        <div className="wrapper expo__wrapper">
          <div className="inner">
            <div className="firm_card">
              <Link className="each_card hana" to="/management/loan/hana">
                <div className="info">
                  <div className="des">
                    <span>
                      하나은행은 국내 최대 글로벌 네트워크를 바탕으로 디지털 시대를 선도하며 신뢰와
                      전문성을 바탕으로 하는 은행입니다.
                    </span>
                    <div className="title">
                      <span>(주)하나은행</span>
                    </div>
                  </div>
                </div>
              </Link>
              {/* <Link className="each_card kn" to="/management/loan/kn">
                <div className="info">
                  <div className="des">
                    <span>
                      글로벌 스탠다드 금융그룹으로 도약”으로 나아가는 경남은행이
                      여러분들의 소중한 행복을 키워드리겠습니다.
                    </span>
                    <div className="title">
                      <span>(주)경남은행</span>
                    </div>
                  </div>
                </div>
              </Link> */}
              <Link className="each_card ibk" to="/management/loan/ibk">
                <div className="info">
                  <div className="des">
                    <span>
                      “우리 모두의 은행 IBK” 대한민국 국민 모두가 거래할 수 있는 은행입니다. 기업이
                      살아야 일자리가 늘어납니다.
                    </span>
                    <div className="title">
                      <span>(주)기업은행</span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoanPage;
