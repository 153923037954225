import { cls } from "utils/util";

const ScrapButton = ({ scraped, scrapClicked, scrapable }) => {
  if (!scrapable) return <></>;

  return (
    <button
      className={cls(
        scraped
          ? "border-primary-800 text-primary-800 bg-primary-100"
          : "border-gray-300 text-black",
        "flex border-[1px] border-solid rounded-sm text-sm px-3 py-1 h-fit lg:hover:bg-gray-100",
      )}
      onClick={scrapClicked}
    >
      <i
        className={cls(
          scraped ? "ii ii-bookmark text-primary-800" : "ii ii-bookmark-outline text-black",
          "mr-1",
        )}
      ></i>
      스크랩
    </button>
  );
};

export default ScrapButton;
