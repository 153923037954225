import DetectUrl from "../contents";
import { cls, getMention, removeMention } from "../../utils/util";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CommentView = ({ content }) => {
  return (
    <div>
      {getMention(content) && (
        <span className="font-semibold text-primary-800 mr-1">{getMention(content)[1]}</span>
      )}
      {htmlTextComment(removeMention(content))}
    </div>
  );
};

export default CommentView;

const htmlTextComment = (text) => {
  return text
    ? text.split("\n").map((text, i) => (
        <div className={cls(i == 0 ? "inline" : "", "w-full")} key={`${text}+${i}`}>
          &#8203;<DetectUrl>{text}</DetectUrl>
        </div>
      ))
    : "";
};
