import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { tokenState } from "../../state";
import AttachImages from "../../component/shared/AttachImages";
import Footer from "../../component/footer/Footer";
import { api } from "../../utils/util";
import { useRecoilValue } from "recoil";
import Loading from "component/Loading";

function BoardWrite() {
  const token = useRecoilValue(tokenState);
  const { state: modify } = useLocation();
  const { board: cate } = useParams();
  const [category, setCategory] = useState({
    cd: modify ? modify.board.cate_cd : cate,
  });
  const navigate = useNavigate();
  const [title, setTitle] = useState(modify ? modify.board.title : "");
  const [content, setContent] = useState(modify ? modify.board.content : "");
  const [images, setImages] = useState(modify ? modify.images.map((item) => item.link) : []);
  const [anonymous, setAnonymous] = useState(
    modify ? modify.board.anonymous : token.user_type === "student" ? true : false,
  );
  const [anonymousDisabled, setAnonymousDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: menuItem } = useQuery(
    "boardCateCd",
    () => api.get("/board/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        err.response && alert(err.response.data.msg);
      },
    },
  );

  useEffect(() => {
    if (!menuItem) return;

    if (
      menuItem.filter((item) => parseInt(category.cd) === parseInt(item.cate_cd))[0].cate_name ===
      "후기"
    ) {
      setAnonymousDisabled(true);
      setAnonymous(false);
    } else {
      setAnonymousDisabled(false);
    }

    // eslint-disable-next-line no-use-before-define
  }, [category.cd, menuItem]);

  const submit = async () => {
    if (category.cd === 0 || category.cd.length > 10) {
      alert("게시판을 선택해주세요.");
      return;
    }

    if (title.length < 5) {
      alert("5글자 이상의 제목을 입력해주세요.");
      return;
    }

    const data = {
      cate_cd: category.cd,
      title: title,
      content: content,
      anonymous: anonymous,
      images: images.length > 0 ? images : [],
    };

    try {
      setLoading(true);
      if (!modify) {
        const res = await api.post(`/board/write/${category.cd}`, data);
        navigate(`/post/article/${res.data.bid}`, { replace: true });
      } else {
        const res = await api.post(`/board/modify/${modify.board.bid}`, data);
        navigate(`/post/article/${res.data.bid}`, { replace: true });
      }
    } catch (err) {
      alert(err.response.data.msg);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="wrapper">
        <section className="board__reg" style={{ marginTop: "54px", marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">게시글 작성</span>
              </div>
            </article>
            <div className="reg__top__box">
              <div className="topic-box flex center">
                <div className="sbox">
                  <select value={category.cd} onChange={(e) => setCategory({ cd: e.target.value })}>
                    <option hidden>게시판을 선택해주세요</option>
                    {menuItem &&
                      menuItem
                        .filter((item) => item.cate_cd !== 0 && item.cate_cd !== 1)
                        .map((item) => {
                          return (
                            <option value={item.cate_cd} key={item.cate_cd}>
                              {item.cate_name}
                            </option>
                          );
                        })}
                  </select>
                </div>
                {menuItem && token.user_type !== "student" && (
                  <div className="cbox" style={{ marginLeft: 20 }}>
                    <label>
                      <input
                        disabled={anonymousDisabled} //익명이 불가능하면 비활성화
                        className="disabled:text-gray-400"
                        type="checkbox"
                        checked={anonymous}
                        onChange={(e) => {
                          setAnonymous(!anonymous);
                        }}
                      />
                      <i></i>
                      <span className="txt">닉네임 비공개</span>
                    </label>
                  </div>
                )}
              </div>

              <div className="tit-box">
                <input
                  className="full-width"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  type="text"
                  placeholder="5글자 이상의 제목을 입력해주세요."
                />
              </div>
            </div>

            <div className="reg__mid__box">
              <textarea
                onChange={(e) => setContent(e.target.value)}
                value={content}
                placeholder="여기를 눌러 글을 작성할 수 있습니다."
              ></textarea>
            </div>
            <div className="reg__bottom__box">
              <AttachImages useImages={[images, setImages]} />
            </div>

            <div className="reg__menu__box">
              <button
                onClick={(e) => {
                  navigate(`/post?cate=${category.cd}`, { replace: true });
                }}
                className="menu-btn type1"
              >
                취소
              </button>
              <button disabled={loading} onClick={submit} className="menu-btn type2">
                {modify ? "수정" : "등록"}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default BoardWrite;
