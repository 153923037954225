import { api } from "../../utils/util";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const NoticesBox = () => {
  const { data: noticesMainState, isLoading: noticesMainStateLoading } = useQuery<
    NoticesMainItem[] | undefined
  >("noticesMain", () => api?.get("notices/main").then((res) => res.data));

  return (
    <div className="w-full h-[78px] flex flex-col bg-gray-50 rounded-sm text-[15px] px-3 hover:bg-gray-100 overflow-hidden">
      <Link
        to="/mypage/notice"
        className="font-semibold border-solid border-b-[1px] border-gray-300 pt-2 pb-1"
      >
        <i className="ii ii-flag-outline ii-weight-600 mr-1 relative top-[-2px]" />
        운영자 공지사항
      </Link>
      <div className="mt-2">
        <Swiper
          modules={[Autoplay]}
          className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
          slidesPerView={1}
          slideToClickedSlide={true}
          autoplay={true}
        >
          {!noticesMainStateLoading &&
            noticesMainState &&
            noticesMainState.slice(0, 4).map((item) => (
              <SwiperSlide key={item.bid} className="swiper-slide">
                <Link
                  to={`/mypage/notice/${item.bid}`}
                  className="text-gray-600 w-[240px] truncate py-2 cursor-pointer"
                >
                  {item.title}
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default NoticesBox;

interface NoticesMainItem {
  bid: number;
  reg_dttm: any;
  title: string;
}
