import { cartSVG, couponSVG, productSVG } from "../assets/icons";
import { Extension } from "../core/extension";
import {TextSelection} from 'prosemirror-state'
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { Link } from "./link";
import { Paragraph } from "./paragraph";
import { BlockQuote } from "./blockquote";

/**
 * @type {import('prosemirror-state').Command}
 */
const addCustomTemplate = (state, dispatch, view) => {
  if (!state.selection.empty) return false

  const customTemplateNode = state.schema.nodes[CustomTemplate.name].create()
  const selectionHead = state.selection.$head
  const isInsideBlockquote =
    selectionHead.node(selectionHead.depth - 1).type.name === BlockQuote.name
  const isInEmptyParagraphNode =
    selectionHead.node(selectionHead.depth).content.size === 0

  // 인용문 안에 있을 경우 인용문 밖에 커스텀 템플릿 노드를 삽입합니다.
  if (isInsideBlockquote) {
    const insertAt = selectionHead.after(selectionHead.depth - 1)
    const tr = state.tr.insert(insertAt, customTemplateNode)
    const tr2 = tr.setSelection(TextSelection.create(tr.doc, insertAt + 1))

    dispatch(tr2)
    return true
  }

  // 커서가 빈 Paragraph 블록에 있을 경우 커스텀 템플릿 노드로 교체합니다.
  if (isInEmptyParagraphNode) {
    const tr = state.tr.setNodeMarkup(
      selectionHead.before(selectionHead.depth),
      state.schema.nodes[CustomTemplate.name],
    )

    dispatch(tr)
    return true
  }

  // 다음 블럭에 커스텀 템플릿 노드를 삽입합니다.
  const posAfterCurNode = selectionHead.end(selectionHead.depth)
  const tr = state.tr.insert(posAfterCurNode, customTemplateNode)
  const tr2 = tr.setSelection(TextSelection.create(tr.doc, posAfterCurNode + 2))

  dispatch(tr2)
  return true
}

/**
 * @type {import('prosemirror-state').Command}
 */
export const removeCustomTemplate = (state, dispatch, view) => {
  if (!state.selection.empty) return false
  const selectionHead = state.selection.$head
  const isInsideCustomTemplate =
    selectionHead.node(selectionHead.depth).type.name === CustomTemplate.name

  // 빈 커스텀 템플릿 노드에서 백스페이스를 누를 경우 본문 노드로 교체합니다.
  if (
    isInsideCustomTemplate &&
    selectionHead.node(selectionHead.depth).content.size === 0
  ) {
    const tr = state.tr.setNodeMarkup(
      selectionHead.before(selectionHead.depth),
      state.schema.nodes[Paragraph.name],
    )

    dispatch(tr)
    return true
  }

  return false
}

/**
 * 카카오톡으로 1:1 문의하기 버튼을 삽압하는 커맨드입니다.
 * 운영팀으로부터 거의 사용하지 않는다는 의견을 전달 받았습니다.
 * 
 * @type {import('prosemirror-state').Command}
 */
const addDirectContactButton = (state, dispatch, view) => {
  const selectionHead = state.selection.$head
  const paragraphNode = state.schema.nodes[Paragraph.name].create(
    null,
    state.schema.text('1:1 문의하기', [
      state.schema.marks[Link.name].create({
        href: 'https://medistream.channel.io',
        target: '_blank',
        style:
          'display:inline-block;padding:10px 20px;border-radius:2px;background-color:#ffe66b;color:#3f1c1d;line-height:16px;font-size:14px;font-weight:bold;text-decoration:none;',
      }),
    ]),
  )

  dispatch(state.tr.insert(selectionHead.pos, paragraphNode))
  return true
}

class CustomTemplateNodeView {
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos

    const id = node.attrs.id
    const dataTarget = node.attrs['data-target']
    const dataOption = node.attrs['data-option']
    const dataImage = node.attrs['data-image']
    const dataComment = node.attrs['data-comment']

    this.dom = document.createElement('div')
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.nodes.custom)

    const iconWrapper = document.createElement('div')
    iconWrapper.className = MEDISTREAM_SCHEMA_STYLE.etc.iconWrapper

    const typeDiv = document.createElement('div')
    typeDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.typeDiv

    const titleDiv = document.createElement('div')
    titleDiv.innerHTML = dataTarget
    titleDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.titleDiv

    id && this.dom.setAttribute('id', id)
    dataTarget && this.dom.setAttribute('data-target', dataTarget)
    dataOption && this.dom.setAttribute('data-option', dataOption)
    dataImage && this.dom.setAttribute('data-image', dataImage)
    dataComment && this.dom.setAttribute('data-comment', dataComment)

    switch (id) {
      case 'addToCart':
        iconWrapper.innerHTML = cartSVG
        typeDiv.innerHTML = '장바구니 위젯'
        break
      case 'productPreview':
        iconWrapper.innerHTML = productSVG
        typeDiv.innerHTML = '상품 위젯'
        break
      case 'downloadCoupon':
        iconWrapper.innerHTML = couponSVG
        typeDiv.innerHTML = '쿠폰 위젯'
        break
      case 'marketingAgreement':
        typeDiv.innerHTML = '마케팅 수신동의 프로모션 위젯'
        break
      case 'teamProduct':
        typeDiv.innerHTML = '팀구매 위젯'
        break
      default:
        typeDiv.innerHTML = '잘못된 형식입니다'
    }

    this.dom.appendChild(iconWrapper)
    this.dom.appendChild(typeDiv)
    this.dom.appendChild(titleDiv)
  }
}

const customTemplateNodeView = (node, view, getPos) =>
  new CustomTemplateNodeView(node, view, getPos)

/**
 * 마켓 운영에 필요한 <template> 태그를 삽입하는 경우를 대응합니다.
 * 
 * <template id="addToCart" data-target=""></template> 형태로 DOM 에 표현됩니다.
 * 
 * <template> 태그는 DOM 에서 보이지 않습니다.
 * 따라서, 에디터에서는 NodeView 를 통해 박스 형태로 표현합니다.
 * 발행된 글 에서는 v-runtime-template 라이브러리를 통해 준비된 Vue 컴포넌트로 교체 후 렌더링합니다. (파싱 과정은 medistream-frontend 저장소의 ProseMirrorView.vue 참고)
 * 
 * <template> 태그를 사용하게된 이유: 
 *  (구)froala 에디터에서 쿠폰 위젯 등을 본문에 추가할 때 사용하던 태그입니다.
 *  기존 운영진의 사용 방식을 존중하기 위해 유지하기로 결정했습니다.
 */
export const CustomTemplate = Extension.Create({
  name: 'custom_template',

  type: 'node',

  defineSpec() {
    return {
      attrs: {
        id: {default: ''},
        'data-target': {default: ''},
        'data-option': {default: ''},
        'data-image': {default: ''},
        'data-comment': {default: ''},
      },
      group: 'block',
      marks: '',
      selectable: true,
      draggable: true,
      toDOM(node) {
        return [
          'template',
          {
            class: MEDISTREAM_SCHEMA_STYLE.nodes.custom,
            id: node.attrs.id,
            'data-target': node.attrs['data-target'] || null,
            'data-option': node.attrs['data-option'] || null,
            'data-image': node.attrs['data-image'] || null,
            'data-comment': node.attrs['data-comment'] || null,
          }
        ]
      },
      parseDOM: [
        {
          tag: 'template',
          getAttrs: (dom) => ({
            id: dom.getAttribute('id'),
            ['data-target']: dom.getAttribute('data-target') || null,
            ['data-option']: dom.getAttribute('data-option') || null,
            ['data-image']: dom.getAttribute('data-image') || null,
            ['data-comment']: dom.getAttribute('data-comment') || null,
          })
        }
      ]
    }
  },
    
  addCommands() {
    return {
      addCustomTemplate,
      removeCustomTemplate,
      addDirectContactButton,
    }
  },

  addNodeView() {
    return customTemplateNodeView
  }
})
