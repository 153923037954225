import { api, publicApi } from "utils/util";

export const getProductInfo = async (productId) => {
  try {
    const {
      status,
      data: { data, success },
    } = await publicApi({
      method: "get",
      url: `/market/products/${productId}`,
    });
    if (status >= 200 && status < 300 && success) {
      return data;
    }
    throw new Error("getProductInfo error");
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * @param {*} urlParams
 * - ids: productIdsString(string)
 * - offset
 * - limit
 * - curationIds
 * - productGroupIds
 * - tags
 * - ninCategoryId
 * - ninCategoryIds
 * - sort
 * - q (Meilisearch): q가 있을 경우만 Meilisearch 사용
 * - filter (Meilisearch)
 */
export const getProducts = async (urlParams) => {
  try {
    const {
      status,
      data: { success, data },
    } = await publicApi({
      method: "get",
      url: `market/products`,
      params: urlParams,
    });
    if (status >= 200 && status < 300 && success) {
      // MARK: 검색제외 부카테고리가 설정된 상품은 결과에 포함되지 않음
      return data;
    }
    throw new Error("getProducts error");
  } catch (error) {
    console.error(error);
    throw error;
  }
};
