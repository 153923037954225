import { atom } from "recoil";
import { getStorage } from "./utils/util";

export const tokenState = atom<any>({
  key: "tokenState",
  default: (() => {
    const token = getStorage().getItem("token");
    return token ? JSON.parse(token) : null;
  })(),
});

export const alarmState = atom<any>({
  key: "alarmState",
  default: { noti: [], load: false, popup: false },
});

export const headerColorState = atom<string | null>({
  key: "headerColorState",
  default: null,
});

export const searchInfoState = atom<{
  searchKey: string;
  searchIndexes: string[];
}>({
  key: "searchInfoState",
  default: { searchKey: getStorage().getItem("searchKey") || "", searchIndexes: [] },
});
