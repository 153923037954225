import { useSearchParams } from "react-router-dom";

export const useSearchSegemnet = () => {
  const [searchParams] = useSearchParams();
  const segment = window.analytics;

  const handleSearchItemClick = (clickInfo) => {
    const currentIndexUid = searchParams.get("indexUid");
    const currentKeyWord = searchParams.get("q");

    segment?.track("Search Item Click", {
      searchKeyword: currentKeyWord,
      indexUid: currentIndexUid,
      clickInfo,
    });
  };

  const trackSearchResult = (pathname, prevPath) => {
    const prevPathName = prevPath.path;
    const prevSearchKeyword = prevPath.searchKeyword;

    const currentKeyWord = searchParams.get("q");
    const currentIndexUid = searchParams.get("indexUid");

    if (prevPathName !== "/search") {
      return;
    }

    if (pathname !== prevPathName) {
      const clickedUrlPattern = [
        "/class/article",
        "/post/article",
        "/qna/article",
        "/contents/article",
        "/forum/article",
        "/management/seminar",
        "/recruit/doctor",
      ];

      const isClicked = clickedUrlPattern.some((v) => {
        return pathname.includes(v);
      });

      if (isClicked) {
        return;
      }

      segment?.track("Dont Click Search Result", {
        searchKeyword: prevPath.searchKeyword,
        userClickLoction: pathname,
        indexUid: currentIndexUid,
      });
    }

    if (pathname === prevPathName && prevSearchKeyword !== currentKeyWord) {
      segment?.track("Search after Search", {
        beforeSearchKeyword: prevSearchKeyword,
        afterSearchKeyword: currentKeyWord,
        indexUid: currentIndexUid,
      });
    }
  };

  return { handleSearchItemClick, trackSearchResult, searchParams };
};
