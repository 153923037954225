import { EmojiButton } from "@joeattardi/emoji-button";
import { useEffect, useState } from "react";

const ProseMirrorMenuEmojiButton = ({ Name, Description, menubarClassName, dispatchMetaData }) => {
  const [picker, setPicker] = useState();
  
  useEffect(() => {
    const emojiInstance = new EmojiButton({
      position: "bottom-end",
      zIndex: 999,
      showVariants: false,
    });

    setPicker(emojiInstance);
  }, [])
  
  useEffect(() => {
    if (picker === undefined) return
    
    const onEmojiSelect = (selection) => {
      dispatchMetaData({ type: "addEmoji", emoji: selection.emoji });
    };

    picker.on("emoji", onEmojiSelect);

    return () => {
      picker.off("emoji", onEmojiSelect);
      picker.destroyPicker()
    }
  }, [picker])

  const toggleEmojiPicker = () => {
    picker.togglePicker(document.querySelector(".trigger"));
  };

  if (picker === undefined) {
    return null;
  }

  return (
    <button
      className={"trigger " + menubarClassName + "__icon" + Name}
      data-command-name={Name}
      data-tooltip={Description}
      onClick={toggleEmojiPicker}
      data-icon-type="toggle"
      style={{ fontSize: "20px", padding: "0 8px" }}
    >
      🙂
    </button>
  );
};
export default ProseMirrorMenuEmojiButton;
