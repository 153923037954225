import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import AttachImages from "../../../component/shared/AttachImages";
import { api, priceText } from "../../../utils/util";
import AddressSelect from "../AddressSelect";

function EstateWritePage(props) {
  const navigate = useNavigate();
  const { state: modify } = useLocation();
  const { bid: article_num } = useParams();

  const estateType = {
    transmission: 1,
    rent: 2,
  };
  const [title, setTitle] = useState(modify ? modify.article.title : null);
  const [content, setContent] = useState(modify ? modify.article.content : null);
  const [type, setType] = useState(modify ? estateType[modify.article.type] : 1);
  const [price, setPrice] = useState({
    price1: modify ? modify.article.price1 : null,
    price2: modify ? modify.article.price2 : null,
  });
  const [address, setAddress] = useState({
    selectedItem: {
      address: modify ? modify.article.address : null,
      detail: modify ? modify.article.detail_address : null,
    },
  });
  const [callNumber, setCallNumber] = useState(modify ? modify.article.call_number : null);
  const [images, setImages] = useState(modify ? modify.images.map((item) => item.link) : []);

  const handleTel = (tel) => {
    if (!tel) return "";
    const t = tel.replace(/-/g, "");
    if (t.length > 10) return t.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    if (t.length > 9) return t.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    if (t.length > 6) return t.replace(/(\d{3})(\d{3})(\d)/, "$1-$2-$3");
    if (t.length > 3) return t.replace(/(\d{3})(\d)/, "$1-$2");
    return t;
  };

  const submit = async () => {
    if (type === null) {
      alert("양도/임대를 선택해주세요");
      return;
    }
    if (price.price1 === null) {
      alert("보증금을 입력해주세요");
      return;
    }
    if (price.price2 === null) {
      alert("양도금을 입력해주세요");
      return;
    }
    if (!address.selectedItem || !address.selectedItem.address) {
      alert("주소를 선택헤주세요");
      return;
    }
    if (title.length < 5) {
      alert("제목은 5글자 이상 입력하여야 합니다");
      return;
    }
    if (content.length < 3) {
      alert("내용은 3글자 이상 입력하여야 합니다");
      return;
    }

    const data = {
      title: title,
      content: content,
      type: type,
      price1: price.price1,
      price2: price.price2,
      address: address.selectedItem.address,
      detail_address: address.selectedItem.detail,
      call_number: callNumber ? callNumber.replace(/-/g, "") : null,
      images: images.length > 0 ? images : [],
    };

    try {
      if (!modify) {
        await api
          .post("/management/estate/write", data)
          .then((res) => {
            navigate(`/management/estate/${res.data.bid}`, { replace: true });
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      } else {
        await api
          .post(`/management/estate/modify/${article_num}`, data)
          .then((res) => {
            navigate(`/management/estate/${res.data.bid}`, { replace: true });
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    } catch (err) {
      alert(err.response.data.msg);
    }
  };

  return (
    <div className={address.view ? "container overlay" : "container"}>
      <div className="wrapper">
        <section className="board__reg" style={{ marginTop: "54px", marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">병원 양도/임대</span>
              </div>
            </article>

            <div className="reg__top__box">
              <div className="row-list">
                <div className="row">
                  <div className="sbox" style={{ width: "300px" }}>
                    <select
                      onChange={(e) => {
                        setType(parseInt(e.target.value));
                      }}
                      value={type}
                    >
                      <option value={1}>양도</option>
                      <option value={2}>임대</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">병원주소를 입력해주세요.</div>

                    <div className="addr-row-box">
                      <input
                        placeholder="우편번호"
                        readOnly={true}
                        value={address.selectedItem && address.selectedItem.postcd}
                      />
                      <button
                        className="post-btn"
                        onClick={() =>
                          setAddress({
                            ...address,
                            view: true,
                            load: false,
                            selectedItem: null,
                          })
                        }
                      >
                        우편번호
                      </button>
                    </div>

                    <div className="addr-row-box">
                      <input
                        placeholder=""
                        readOnly={true}
                        value={address.selectedItem && address.selectedItem.address}
                        style={{ width: "401px" }}
                      />
                      <input
                        style={{ width: "300px", marginLeft: "20px" }}
                        placeholder=""
                        readOnly={true}
                        value={address.selectedItem && address.selectedItem.detail}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="sum-box">
                    <div className="item">
                      <div className="tbox">
                        <input
                          style={{ color: "#666" }}
                          placeholder="보증금을 입력해주세요"
                          value={price.price1}
                          onChange={(e) => setPrice({ ...price, price1: parseInt(e.target.value) })}
                          type="number"
                        />
                      </div>
                      <span className="unit">원</span>
                    </div>
                    <div className="item" style={{ marginLeft: "52px" }}>
                      <div className="tbox">
                        <input
                          style={{ color: "#666" }}
                          placeholder={
                            type === 1 ? "권리금을 입력해주세요" : "임대료를 입력해주세요"
                          }
                          value={price.price2}
                          onChange={(e) => setPrice({ ...price, price2: parseInt(e.target.value) })}
                          type="number"
                        />
                      </div>
                      <span className="unit">원</span>
                    </div>
                    {type === 1 && (
                      <div className="item" style={{ marginLeft: "20px" }}>
                        <div className="sum-txt">
                          총 양도금 {priceText(price.price1 + price.price2)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">문의 받으실 번호를 입력해주세요.</div>

                    <div className="addr-row-box">
                      <input
                        style={{ width: "300px" }}
                        placeholder="010-1234-1234"
                        type="tel"
                        value={handleTel(callNumber)}
                        onChange={(e) => setCallNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tbox full">
              <input
                className="title"
                placeholder="제목을 입력해주세요."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="reg__mid__box">
              <textarea
                placeholder="여기를 눌러 글을 작성할 수 있습니다."
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
            <div className="reg__bottom__box">
              <AttachImages useImages={[images, setImages]} />
            </div>

            <div className="reg__menu__box">
              <button
                className="menu-btn type1"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/management/estate`, { replace: true });
                }}
              >
                취소
              </button>
              <button onClick={submit} className="menu-btn type2">
                등록
              </button>
            </div>
          </div>
        </section>
      </div>
      {address.view ? <AddressSelect state={address} setState={setAddress} /> : <></>}
    </div>
  );
}

export default EstateWritePage;
