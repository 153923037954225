import { useEffect, useState } from "react";
import BoardReportPopup from "../../component/board/BoardReportPopup";
import BoardTrashPopup from "../../component/board/BoardTrashPopup";
import DetailBoardTitle from "../../component/board/detail/DetailBoardTitle";
import Footer from "../../component/footer/Footer";
import Loading from "../../component/Loading";
import { writeDayYtoS, api, watermark, StaticImg } from "../../utils/util";
import QnaDetailContent from "../../component/qna/QnaDetailContent";
import QnaRanking from "../../component/qna/QnaRanking";
import BlockAlert from "../../component/BlockAlert";
import QnaArticleBox from "../../component/qna/QnaArticleBox";
import GroupBuyingRecommend from "../groupBuying/GroupBuyingRecommend";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import QnaListUI from "../../component/qna/QnaListUI";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import Banner from "../../component/advertisment/banner";
import Nickname from "../../component/user/nickname";
import QnaCommentList from "component/comment/QnaCommentList";

function QnaDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const bid = parseInt(useParams().bid || 0);
  const urlParam = new URLSearchParams(location.search);
  const cate_cd = urlParam.has("topic") ? parseInt(urlParam.get("topic")) : 0;
  const page = urlParam.has("page") ? parseInt(urlParam.get("page")) : 1;
  const sort = urlParam.has("sort") ? urlParam.get("sort") : "";
  const q = urlParam.get("q") || "";
  const [reportPopup, setReportPopup] = useState({ toggle: false });
  const [blockAlert, setBlockAlert] = useState(false);
  const token = useRecoilValue(tokenState);
  const params = { page, q, sort_type: sort };

  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => window.scrollTo(0, 0), [bid]);

  const { data: content, isLoading: contentLoading } = useQuery(
    ["qnaArticle", bid],
    () => api.get(`/qna/article/${bid}`).then((res) => res.data),
    {
      onError: (err) => {
        err?.response?.data?.msg && alert(err.response.data.msg);
        navigate(-1);
      },
    },
  );

  const tab = content?.cate_forum;

  const { data: allPageInfo, isLoading: allPageInfoLoading } = useQuery(
    ["qnaList", tab, cate_cd, sort, params],
    () =>
      api
        .get(`/qna/list/${tab}/${cate_cd}`, {
          params,
        })
        .then((res) => res.data),
    { enabled: !!tab },
  );

  const { data: recommendProduct } = useQuery(["market0ListRecommend", content?.cate_name], () =>
    api
      .get("/market0/list/recommend", {
        params: { category: content?.cate_name },
      })
      .then((res) => res.data),
  );

  const { data: pickInfo, isLoading: pickInfoLoading } = useQuery(
    ["qnaListPick", tab],
    () => api.get(`/qna/list_pick/${tab}`).then((res) => res.data),
    { enabled: !!tab },
  );

  const articleLike = useMutation(({ bid, data }) => api.post(`/qna/like/${bid}`, data), {
    onSuccess: () => queryClient.invalidateQueries("qnaArticle"),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const articleScrap = useMutation(({ bid, data }) => api.post(`/qna/scrap/${bid}`, data), {
    onSuccess: () => queryClient.invalidateQueries("qnaArticle"),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const modifyFunc = () => {
    navigate(`/qna/modify/${tab}/${content.qna.bid}`, {
      state: content,
    });
  };

  const reportComment = (item) =>
    setReportPopup({
      category: "qna_comment",
      uuid: item.uuid,
      toggle: true,
    });

  const directDeleteArticle = () => {
    api
      .delete(`/qna/delete/${content.qna.bid}`)
      .then((res) => {
        res?.data?.msg && alert(res.data.msg);
        navigate(`/qna/${tab}?${urlParam}`);
      })
      .catch((e) => {
        e?.response?.data?.msg && alert(e.response.data.msg);
        setDeletePopup(false);
      });
  };

  const scrapClicked = async () => {
    const data = {
      scraped: !content.scraped,
    };
    articleScrap.mutate({ bid, data });
  };

  const likeClicked = async (comment, curLiked) => {
    if (comment ? comment.ismine : content.ismine) {
      alert("내가 작성한 글은 추천할 수 없습니다.");
      return;
    }
    const cid = comment ? comment.uuid : null;
    const data = {
      cid: cid ?? null,
      liked: !curLiked,
    };
    articleLike.mutate({ bid, data });
  };

  if (contentLoading || !content) return <Loading />;
  return (
    <>
      <div className="wrapper mt70">
        <section className="magazine__detail">
          <div className="inner">
            <DetailBoardTitle
              menu="qna"
              content={content}
              modifyFunc={modifyFunc}
              setDeletePopup={setDeletePopup}
            />
            {deletePopup && (
              <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
            )}
            <div
              className="detail__group"
              style={{
                background: watermark(token),
                backgroundRepeat: "space",
              }}
            >
              <div className="tit-box">
                <div className="subject-box">
                  {content.qna.report_count >= 10 && <i className="reported" />}
                  {content.qna.reported
                    ? "다수의 신고로 블라인드 처리된 게시글입니다."
                    : content.qna.title}
                </div>
                <div className="info-box flex justify-between items-center">
                  <div>
                    <span className="txt">
                      <div className="flex">
                        <Nickname
                          isDetail
                          uid={content.qna.userUid}
                          unick={content.qna.unick}
                          level={content.qna.level}
                        />
                      </div>
                    </span>
                    <span className="txt">{writeDayYtoS(content.qna.reg_dttm)}</span>
                  </div>
                  {/* {!content.ismine && (
                    <span onClick={() => setBlockAlert(true)} className="block">
                      <img
                        src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/ico-prohibition.svg`}
                        alt="차단"
                      />
                      <span className="txt">작성자 차단</span>
                    </span>
                  )} */}
                </div>
              </div>
              <div className="con-box divided">
                <div className="left-content">
                  <QnaDetailContent
                    content={content}
                    likeClicked={likeClicked}
                    scrapClicked={scrapClicked}
                    setReportPopup={setReportPopup}
                  />
                  <Banner page="QnaDetail" name="pc_QnaDetail_middle" />
                  <QnaCommentList
                    current="qna"
                    content={content}
                    article_num={bid}
                    reportComment={reportComment}
                  />
                </div>
                <div className="right-content">
                  {content.cate_name === "대출" ? (
                    <div className="groupbuying-recommend recommend-list">
                      <div className="title__box">
                        <div>
                          <b>함께 보면 좋아요👀</b>
                        </div>
                      </div>
                      <Link to={"/management/loan/hana"}>
                        <div className="qna-right-image">
                          <img src={StaticImg("banks/hana3.png")} alt="" />
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <GroupBuyingRecommend />
                  )}

                  {!pickInfoLoading && pickInfo && (
                    <>
                      <QnaArticleBox articles={pickInfo.pick_qna} title="화제의 Q&amp;A" />
                      <QnaArticleBox articles={pickInfo.hot_qna} title="실시간 HOT Q&amp;A" />
                      <QnaRanking pageInfo={pickInfo} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {allPageInfoLoading || !allPageInfo ? (
          <Loading />
        ) : (
          <section className="magazine__list mt-[60px]" style={{ marginBottom: "136.5px" }}>
            <div className="inner">
              {!contentLoading && (
                <QnaListUI current="qna_detail" pageInfo={allPageInfo} detailTab={tab} />
              )}
            </div>
          </section>
        )}
      </div>

      <Footer />
      {blockAlert && (
        <BlockAlert
          setBlockAlert={setBlockAlert}
          bid={content.qna.bid}
          isAnonymous={true}
          blockReference={content.qna.title}
        />
      )}
      {reportPopup.toggle && (
        <BoardReportPopup
          category={reportPopup.category}
          setReportPopup={setReportPopup}
          bid={reportPopup.uuid ? reportPopup.uuid : bid}
        />
      )}
    </>
  );
}

export default QnaDetail;
