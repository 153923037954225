import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { default as Select } from "react-select";
import AttachImages from "../../component/shared/AttachImages";
import {
  DHMajorOptions,
  DHTypeOptions,
  DHRequirementOptions,
  DHSalaryOptions,
  careerTypeOptions,
} from "../../constants";
import { api, selectDTStyles } from "../../utils/util";
import AddressSelect from "../management/AddressSelect";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import Loading from "component/Loading";

function HygienistWrite(props) {
  const navigate = useNavigate();
  const { state: modify } = useLocation();
  const { bid: article_num } = useParams();
  const [title, setTitle] = useState(modify ? modify.article.title : "");
  const [hospitalName, setHospitalName] = useState(modify ? modify.article.hospital_name : "");
  const [content, setContent] = useState(modify ? modify.article.content : "");
  const [address, setAddress] = useState({
    selectedItem: {
      address: modify ? modify.article.address : "",
      detail: modify ? modify.article.detail_address : "",
    },
  });
  const [email, setEmail] = useState(modify ? modify.article.contact_email : "");
  const [phone, setPhone] = useState(modify ? modify.article.contact_phone : "");
  const [taskList, setTaskList] = useState(modify ? modify.article.task_list.split(",") : []);
  const [typeList, setTypeList] = useState(modify ? modify.article.type_list.split(",") : []);
  const [requirementDocs, setRequirementDocs] = useState(
    modify ? modify.article.requirement_docs.split(",") : [],
  );
  const [workSchedule, setWorkSchedule] = useState(modify ? modify.article.work_schedule : "");
  const [careerType, setCareerType] = useState(
    modify
      ? careerTypeOptions.find((item) => item.label === modify.article.career_type) ||
          careerTypeOptions.find((item) => !item.label)
      : careerTypeOptions[0],
  );
  const [careerLower, setCareerLower] = useState(modify ? modify.article.career_lower : 0);
  const [careerUpper, setCareerUpper] = useState(modify ? modify.article.career_upper : 0);
  const [wageType, setWageType] = useState(
    modify
      ? DHSalaryOptions.find((item) => item.label === modify.article.wage_type) ||
          DHSalaryOptions.find((item) => !item.label)
      : DHSalaryOptions[0],
  );
  const [wageAmount, setWageAmount] = useState(
    modify && modify.article.wage_amount
      ? modify.article.wage_amount && Number(modify.article.wage_amount) / 10000
      : 0,
  );
  const [wageInTax, setWageInTax] = useState(modify ? modify.article.wage_tax : "세전");
  const [images, setImages] = useState(modify ? modify.images.map((item) => item.link) : []);
  const [terminateAt, setTerminateAt] = useState(
    modify
      ? modify.article.terminate_at
        ? dayjs(modify.article.terminate_at).format("YYYY-MM-DD")
        : ""
      : "",
  );
  const salaryOptionsSelect = DHSalaryOptions.filter((item) => item.value);

  useEffect(() => window.scrollTo(0, 0), []);

  const DHWrite = useMutation(({ data }) => api.post("/management/hygienist/write", data), {
    onSuccess: (res) => {
      alert(res.data.msg);
      navigate(`/recruit/hygienist/${res.data.bid}`, { replace: true });
    },
    onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
  });

  const DHModify = useMutation(
    ({ data }) => api.post(`/management/hygienist/modify/${article_num}`, data),
    {
      onSuccess: (res) => {
        alert(res.data.msg);
        navigate(`/recruit/hygienist/${article_num}`, { replace: true });
      },
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const submit = async () => {
    if (title.length < 5) {
      alert("제목을 5글자 이상 입력해야 합니다");
      return;
    }

    if (!hospitalName) {
      alert("병원명을 입력해주세요");
      return;
    }

    if (!address.selectedItem || !address.selectedItem.address) {
      alert("주소를 입력해주세요");
      return;
    }

    if (!email && !phone) {
      alert("담당자 정보를 1개 이상 입력해주세요");
      return;
    }

    if (!taskList.length) {
      alert("업무구분을 선택해주세요");
      return;
    }

    if (!typeList.length) {
      alert("고용형태를 입력해주세요");
      return;
    }

    if (wageType.label !== "면접시 협의" && !wageAmount) {
      alert("희망연봉을 입력해주세요");
      return;
    }

    if (!workSchedule.length) {
      alert("근무요일/시간을 입력해주세요");
      return;
    }

    if (!requirementDocs.length) {
      alert("제출서류를 입력해주세요");
      return;
    }

    if (content.length < 3) {
      alert("내용을 3글자 이상 입력해야 합니다");
      return;
    }

    if (!terminateAt) {
      alert("마감일을 입력해주세요");
      return;
    }

    const data = {
      title,
      content,
      hospital_name: hospitalName,
      address: address.selectedItem.address,
      detail_address: address.selectedItem.detail,
      email,
      phone,
      task_list: taskList.toString(),
      type_list: typeList.toString(),
      career_type: careerType.label,
      career_lower: careerLower,
      career_upper: careerUpper,
      wage_type: wageType.value ? wageType.label : null,
      wage_tax: wageType.value ? wageInTax : null,
      wage_amount: wageType.value ? `${Number(wageAmount) * 10000}` : null,
      work_schedule: workSchedule,
      requirement_docs: requirementDocs.toString(),
      terminate_at: dayjs(terminateAt).format(),
      images: images.length > 0 ? images : [],
    };

    modify ? DHModify.mutate({ data }) : DHWrite.mutate({ data, bid: article_num });
  };

  if (DHWrite.isLoading || DHModify.isLoading) return <Loading />;

  return (
    <div className={address.view ? "container overlay" : "container"}>
      <div className="wrapper">
        <section className="board__reg" style={{ marginTop: "54px", marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">치과위생사 구인공고 작성하기</span>
              </div>
            </article>

            <div className="reg__top__box">
              <div className="row-list">
                <div className="row"></div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">제목</div>
                    <div className="addr-row-box">
                      <input
                        style={{ width: "900px" }}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="제목을 입력해주세요"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">병원명</div>
                    <div className="addr-row-box">
                      <input
                        style={{ width: "900px" }}
                        value={hospitalName}
                        onChange={(e) => setHospitalName(e.target.value)}
                        placeholder="병원명을 입력해주세요"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">병원주소</div>
                    <div className="flex column">
                      <div className="addr-row-box">
                        <input
                          style={{ width: "300px" }}
                          placeholder="우편번호"
                          value={address.selectedItem && address.selectedItem.postcd}
                          readOnly={true}
                        />
                        <button
                          className="post-btn"
                          onClick={() =>
                            setAddress({
                              ...address,
                              view: true,
                              load: false,
                              selectedItem: undefined,
                            })
                          }
                        >
                          우편번호
                        </button>
                      </div>
                      <div className="addr-row-box">
                        <input
                          style={{ width: "300px" }}
                          placeholder=""
                          value={address.selectedItem && address.selectedItem.address}
                          readOnly={true}
                        />
                        <input
                          style={{ width: "300px", marginLeft: "13px" }}
                          placeholder=""
                          value={address.selectedItem && address.selectedItem.detail}
                          onChange={(e) => {
                            setAddress({
                              selectedItem: {
                                address: address.selectedItem.address,
                                detail: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">담당자 정보</div>
                    <div className="flex column">
                      <div className="addr-row-box">
                        <input
                          style={{ width: "300px" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="이메일을 입력해주세요"
                        />
                      </div>
                      <div className="addr-row-box">
                        <input
                          style={{ width: "300px" }}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="연락처를 입력해주세요"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">업무구분</div>
                    <div className="addr-row-box">
                      {DHMajorOptions.map((item) => {
                        return (
                          <button
                            onClick={() => {
                              taskList.includes(item.label)
                                ? setTaskList(taskList.filter((e) => e !== item.label))
                                : setTaskList(taskList.concat(item.label));
                            }}
                            className={taskList.includes(item.label) ? "clicked" : ""}
                          >
                            {item.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">고용형태</div>
                    <div className="addr-row-box">
                      {DHTypeOptions.map((item) => {
                        return (
                          <button
                            onClick={() => {
                              typeList.includes(item.label)
                                ? setTypeList(typeList.filter((e) => e !== item.label))
                                : setTypeList(typeList.concat(item.label));
                            }}
                            className={typeList.includes(item.label) ? "clicked" : ""}
                          >
                            {item.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">경력 조건</div>
                    <div className="addr-row-box salary-info">
                      <Select
                        value={careerType}
                        onChange={(option) => setCareerType(option)}
                        styles={selectDTStyles}
                        options={careerTypeOptions}
                        isSearchable={false}
                      />
                      <input
                        className="ml10"
                        style={{ width: "80px", textAlign: "left" }}
                        value={careerLower}
                        onChange={(e) => {
                          const value = e.target.value.replaceAll(",", "");
                          if (value.length > 2) return;
                          if (value && !value.match(/^\d+$/)) return;
                          setCareerLower(value);
                        }}
                        placeholder=""
                      />
                      <div className="ml10">년</div>
                      <div className="line" />
                      <input
                        className="ml10"
                        style={{ width: "80px", textAlign: "left" }}
                        value={careerUpper}
                        onChange={(e) => {
                          const value = e.target.value.replaceAll(",", "");
                          if (value.length > 2) return;
                          if (value && !value.match(/^\d+$/)) return;
                          setCareerUpper(value);
                        }}
                        placeholder=""
                      />
                      <div className="ml10">년</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">급여 조건</div>
                    <div className="addr-row-box salary-info">
                      <Select
                        value={wageType}
                        onChange={(option) => setWageType(option)}
                        styles={selectDTStyles}
                        options={salaryOptionsSelect}
                        isSearchable={false}
                      />
                      <input
                        className="ml10"
                        value={!wageType.value ? "" : Number(wageAmount).toLocaleString("ko-KR")}
                        onChange={(e) => {
                          const value = e.target.value.replaceAll(",", "");
                          if (value.length > 5) return;
                          if (value && !value.match(/^\d+$/)) return;
                          setWageAmount(value);
                        }}
                        placeholder=""
                        disabled={wageType.value === "conference"}
                      />
                      <div className="ml10">만원</div>
                      <div className="rbox ml30">
                        <label>
                          <input
                            type="radio"
                            name="tax"
                            value="세전"
                            checked={wageInTax === "세전"}
                            onChange={(e) => setWageInTax(e.target.value)}
                            disabled={wageType.value === "conference"}
                          />
                          <i></i>
                          <span className="txt">세전</span>
                        </label>
                      </div>
                      <div className="rbox ml30">
                        <label>
                          <input
                            type="radio"
                            name="tax"
                            value="세후"
                            checked={wageInTax === "세후"}
                            onChange={(e) => setWageInTax(e.target.value)}
                            disabled={wageType.value === "conference"}
                          />
                          <i></i>
                          <span className="txt">세후</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">근무요일/시간</div>
                    <div className="addr-row-box">
                      <div className="textarea-box">
                        <TextareaAutosize
                          onChange={(e) => setWorkSchedule(e.target.value)}
                          value={workSchedule}
                          placeholder="근무요일과 근무시간을 입력해주세요"
                          minRows={3}
                          style={{ fontSize: "15px" }}
                        ></TextareaAutosize>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">제출서류</div>
                    <div className="addr-row-box">
                      {DHRequirementOptions.map((item) => {
                        return (
                          <button
                            onClick={() => {
                              requirementDocs.includes(item.label)
                                ? setRequirementDocs(
                                    requirementDocs.filter((e) => e !== item.label),
                                  )
                                : setRequirementDocs(requirementDocs.concat(item.label));
                            }}
                            className={requirementDocs.includes(item.label) ? "clicked" : ""}
                          >
                            {item.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box flex">
                    <div className="addr-txt">마감일</div>
                    <div className="datetime__input">
                      <input
                        type="date"
                        onChange={(e) => setTerminateAt(e.target.value)}
                        value={terminateAt}
                        min={dayjs().format("YYYY-MM-DD")}
                        max={dayjs().add(30, "day").format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reg__mid__box">
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="내용을 입력해주세요."
              ></textarea>
            </div>
            <div className="reg__bottom__box">
              <AttachImages useImages={[images, setImages]} />
            </div>

            <div className="reg__menu__box">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/recruit/hygienist`, { replace: true });
                }}
                className="menu-btn type1"
              >
                취소
              </button>
              <button onClick={submit} className="menu-btn type2">
                등록
              </button>
            </div>
            <div className="hygienist__accent-txt">
              본 공고는 치과위생사 커뮤니티 치즈톡에서 무료로 홍보됩니다.
            </div>
          </div>
        </section>
      </div>
      {address.view ? <AddressSelect state={address} setState={setAddress} /> : <></>}
    </div>
  );
}

export default HygienistWrite;
