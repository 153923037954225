import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { api, cls, removeMention } from "../../utils/util";
import { useQuery } from "react-query";
import Blur from "../../page/user/Blur";
import CensoredData from "../../page/user/CensoredData";
import { useHomeSegment } from "hooks/useHomeSegment";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function LiveArticle() {
  const { sendTrackingEvent } = useHomeSegment();
  const { data: allCommentsLatestState, isLoading: allCommentsLatestStateLoading } = useQuery(
    "allCommentsLatest",
    () => api.get("all/comments/latest").then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const { data: boardsLatestState, isLoading: boardsLatestStateLoading } = useQuery(
    "boardsLatest",
    () => api.get("boards/latest").then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const [timeNow, setTimeNow] = useState(0);
  const [isComment, setIsComment] = useState(false);
  const cateMapping = {
    Forum: { label: "임상포럼", color: { background: "#16C6F2" } },
    Qna: { label: "Q&A", color: { background: "#00BAB8" } },
    Board: { label: "게시판", color: { background: "#333" } },
  };

  const getUrl = (item, isComment) => {
    const baseUrlPoint =
      Object.keys(item)[0] === "Board" ? "post" : Object.keys(item)[0].toLowerCase();

    return `/${baseUrlPoint}/article/${item[Object.keys(item)[0]].bid}${
      isComment ? `?cid=${item[Object.keys(item)[1]].uuid}` : ""
    }`;
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeNow(dayjs().format("M월 D일 H시 m분"));
    }, 1000);
    return () => clearInterval(countdown);
  }, [timeNow]);

  return (
    <div className="w-full flex flex-col gap-3">
      <span className="flex justify-between items-center">
        <b className="text-[20px]">실시간 글</b>
        <div className="text-[14px] text-gray-600">🕰 {timeNow}</div>
      </span>
      <div className="border-solid border-[1px] border-gray-300 overflow-hidden grid grid-cols-2 w-full rounded-md bg-gray-100 text-gray-900">
        <button
          onClick={() => setIsComment(false)}
          className={cls(
            !isComment && "bg-white text-primary-800",
            "border-solid border-r-[1px] border-gray-300  hover:bg-white py-2 text-center text-[14px] cursor-pointer",
          )}
        >
          작성글
        </button>
        <button
          onClick={() => setIsComment(true)}
          className={cls(
            isComment && "bg-white text-primary-800",
            "hover:bg-white py-2 text-center text-[14px] cursor-pointer",
          )}
        >
          댓글
        </button>
      </div>
      <div onClick={() => sendTrackingEvent({ name: "실시간 글" })}>
        <Blur>
          <div className="hard-blur flex flex-col">
            {!boardsLatestStateLoading &&
              !allCommentsLatestStateLoading &&
              allCommentsLatestState &&
              boardsLatestState &&
              (isComment
                ? allCommentsLatestState.slice(0, 5).map((comment, idx) => (
                    <Link
                      key={idx}
                      to={getUrl(comment, true)}
                      className="border-solid border-b-[1px] text-gray-900 hover:text-primary-800 border-b-gray-100 flex justify-between items-center h-[56px] cursor-pointer"
                    >
                      <div
                        className="!text-white rounded-sm text-xs w-[56px] text-center py-1 mr-2"
                        style={cateMapping[Object.keys(comment)[0]].color}
                      >
                        {cateMapping[Object.keys(comment)[0]].label}
                      </div>

                      <div className="truncate w-full mr-3 font-medium">
                        <CensoredData>
                          {removeMention(comment[Object.keys(comment)[1]].content || "")}
                        </CensoredData>
                      </div>
                    </Link>
                  ))
                : boardsLatestState.slice(0, 5).map((article, idx) => (
                    <Link
                      key={idx}
                      to={getUrl(article, false)}
                      className="border-solid border-b-[1px] text-gray-900 hover:text-primary-800 border-b-gray-100 flex justify-between items-center h-[56px] cursor-pointer"
                    >
                      <div
                        className="!text-white rounded-sm text-xs w-[72px] text-center py-1 mr-2"
                        style={cateMapping[Object.keys(article)[0]].color}
                      >
                        {cateMapping[Object.keys(article)[0]].label}
                      </div>
                      <div className="w-full truncate mr-3 font-medium">
                        <CensoredData>{article[Object.keys(article)[0]].title} </CensoredData>
                      </div>
                    </Link>
                  )))}
          </div>
        </Blur>
      </div>
    </div>
  );
}

export default LiveArticle;
