const SearchForm = ({
  value,
  setValue,
  search,
  width = "auto",
  placeholder = "검색어를 입력해주세요",
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      search(e);
    }
  };

  return (
    <form className="relative" style={{ width: width }}>
      <input
        className="w-full bg-gray-100 focus:bg-white focus:shadow-md text-[14px] h-full py-2 lg:py-3 pl-4 pr-8 lg:pr-10 rounded-full"
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <button className="absolute right-3 lg:top-[5px] lg:right-5" onClick={(e) => search(e)}>
        <i className="ii ii-search ii-weight-600 text-[20px]" />
      </button>
    </form>
  );
};

export default SearchForm;
