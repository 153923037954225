import axios from "axios";
import { BASE_URL } from "../../utils/util";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const HeaderFlag = ({ value }) => {
  const { data: classLive, isLoading: classLiveLoading } = useQuery(
    "메인_보수교육_LIVE",
    () =>
      axios.get(`${BASE_URL}/home/KVStore/메인_보수교육_LIVE`).then((res) => res.data.store?.value),
    { cacheTime: Infinity, staleTime: 15 * 60 * 1000 },
  );

  const { data: marketTag, isLoading: marketTagLoading } = useQuery(
    "메인_groupbuying",
    () => axios.get(`${BASE_URL}/home/KVStore/groupbuying`).then((res) => res.data.store?.value),
    { cacheTime: Infinity, staleTime: 15 * 60 * 1000 },
  );

  const isDate = (value) => {
    const date = new Date(value);
    return date instanceof Date && !isNaN(date);
  };

  if (value === "class" && !classLiveLoading && classLive && isDate(classLive))
    return <Timer dateString={classLive} />;

  if (value === "market" && !marketTagLoading && marketTag && isDate(marketTag))
    return <Timer dateString={marketTag} />;

  if (value === "market" && !marketTagLoading && marketTag && !isDate(marketTag))
    return (
      <>
        <span className="bg-[#FF228C] z-10 whitespace-nowrap absolute text-white px-2 py-[3px] text-[8px] rounded-sm left-[50%] translate-x-[-50%] top-[-10px]">
          {marketTag}
        </span>
        <div className="absolute w-0 h-0 border-[6px] border-solid border-transparent border-t-[#FF228C] top-2 left-[50%] translate-x-[-50%]"></div>
      </>
    );

  if (value === "chicruiting")
    return (
      <>
        <span className="bg-[#F06E6F] z-10 whitespace-nowrap absolute text-white px-2 py-[0px] text-[10px] font-semibold rounded-full right-[10%] top-[0px]">
          치즈톡 채용
        </span>
        <svg
          className="absolute top-[8px] left-[32px]"
          width="9"
          height="8"
          viewBox="0 0 9 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector 4"
            d="M0.177182 6.30678L3.34194 0.424478C3.53208 0.0710823 4.00883 0.000336998 4.29339 0.283293L8.10241 4.07083C8.42481 4.39141 8.28739 4.94013 7.85195 5.07092L0.878171 7.1657C0.366225 7.31947 -0.0760812 6.77752 0.177182 6.30678Z"
            fill="#F06E6F"
          />
        </svg>
      </>
    );

  if (value === "class" && !classLiveLoading && classLive && !isDate(classLive))
    return (
      <>
        <span className="bg-[#FF228C] z-10 whitespace-nowrap absolute text-white px-2 py-[3px] text-[8px] rounded-sm left-[37%] translate-x-[-50%] top-[-10px]">
          {classLive}
        </span>
        <div className="absolute w-0 h-0 border-[6px] border-solid border-transparent border-t-[#FF228C] top-2 left-[37%] translate-x-[-50%]"></div>
      </>
    );
};

export default HeaderFlag;

function Timer({ dateString }) {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const targetDate = dayjs(dateString).startOf("day").add(0, "hour");
      const now = dayjs();
      const diff = targetDate.diff(now);
      if (diff <= 0) {
        // 날짜가 이미 지났으면 타이머를 멈춥니다.
        clearInterval(intervalId);
        setTimeRemaining(null);
        return;
      }
      const duration = dayjs.duration(diff);
      setTimeRemaining({
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [dateString]);

  if (!timeRemaining) {
    return <></>;
  }

  return (
    <>
      <span className="bg-[#FF228C] z-10 whitespace-nowrap absolute text-white px-2 py-[2px] text-[8px] rounded-sm left-[50%] translate-x-[-50%] top-[-10px]">
        {timeRemaining.days > 0 && <span>{timeRemaining.days}일&nbsp;</span>}
        {timeRemaining.hours.toString().padStart(2, "0")}:
        {timeRemaining.minutes.toString().padStart(2, "0")}:
        {timeRemaining.seconds.toString().padStart(2, "0")} 남음
      </span>
      <div className="absolute w-0 h-0 border-[8px] border-solid border-transparent border-t-[#FF228C] top-1 left-[50%] translate-x-[-50%]"></div>
    </>
  );
}
