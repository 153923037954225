import React from "react";
import { Link } from "react-router-dom";
import { cutCateLength } from "../../utils/util";

function QnaArticleBox({ articles, title }) {
  return (
    <div className="layout__box__2">
      <article className="section__tit__box border-type2">
        <div className="tit-box width-max">
          <span className="tit-txt">{title}</span>
        </div>
      </article>
      <article className="list__table">
        {articles &&
          articles.map((article, index) => (
            <Link to={`/qna/article/${article.bid}?page=1`} key={index} className="cursor">
              <div className="txt-box pick-box">
                <div className={index < 3 ? "label-box_first" : "label-box"}>
                  {cutCateLength(article.cate_name)}
                </div>
                <div className="txt">{article.title}</div>
                <div className="comment">[{article.comment}]</div>
              </div>
            </Link>
          ))}
      </article>
    </div>
  );
}

export default QnaArticleBox;
