import { useNavigate } from "react-router-dom";
import { api, Img } from "../../../utils/util";
import Footer from "../../../component/footer/Footer";
import { useLocation } from "react-router";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import Banner from "../../../component/advertisment/banner";

const SeminarWeekly = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const param = { page };

  const { data: seminarList, isLoading: seminarListLoading } = useQuery(
    ["managementSeminarWeeklyList", param],
    () => api.get("/management/seminar/weekly/list", { params: param }).then((res) => res.data),
  );

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    navigate(`/management/seminar_weekly?${param}`);
  };

  return (
    <div>
      <div className="wrapper">
        <div className="inner">
          <Banner page="seminar" name="pc_Seminar_top" />
        </div>
        <section className="" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">주간 세미나</span>
              </div>
            </article>
          </div>
        </section>

        <section className="magazine__list mt-[60px]" style={{ marginBottom: "35px" }}>
          <div className="inner" style={{ marginTop: "-50px" }}>
            <article className="seminar_sche">
              <table>
                <colgroup>
                  <col style={{ width: "200px" }} />
                  <col style={{ width: "auto" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className="left">이미지</th>
                    <th className="left">제목</th>
                  </tr>
                </thead>
                <tbody>
                  {!seminarListLoading &&
                    seminarList.weekly_list.map((item, idx) => {
                      return (
                        <tr
                          className="cursor"
                          key={idx}
                          onClick={() => navigate(`/management/seminar_weekly/${item.wid}`)}
                        >
                          <td className="">
                            <div className="img-box">
                              <img src={Img(item.link)} alt="이미지" />
                            </div>
                          </td>
                          <td className="left">
                            <span className="accent">{item.title}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <article className="paging">
                {!seminarListLoading && (
                  <ReactPaginate
                    previousLabel="←"
                    previousLinkClassName="before"
                    nextLabel="→"
                    nextLinkClassName="next"
                    breakLabel="..."
                    pageCount={seminarList.pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    pageLinkClassName="num"
                    activeClassName=""
                    activeLinkClassName="current"
                    onPageChange={onPageClick}
                    style={{ cursor: "pointer" }}
                    forcePage={seminarList.page - 1}
                  />
                )}
              </article>
            </article>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default SeminarWeekly;
