import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { Img, StaticImg } from "../../utils/util";
import { useEffect, useState } from "react";
import ChicruitPopup from "component/recruit/chicruitPopup";
import { useNavigate } from "react-router";
import Popup from "../../component/Popup";
import ChannelTalk from "component/ChannelTalk";

function ChiCruiting() {
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [reject, setReject] = useState(false);

  const onClickPopup = () => {
    if (!token || token.user_type !== "doctor") {
      setReject(true);
      return;
    }
    setPopup(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="flex column">
        <div className="chicruit-banner">
          <img className="chicruit1" src={StaticImg("cheecruit/chicruiting15.png")} alt="" />
        </div>
        <div className="chicruiting">
          <div className="flexCenter flex center">
            <div className="blue-box">
              <button
                className="chicruiting_form"
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                onClick={onClickPopup}
              >
                <div className="flex center">
                  <div>요청서 작성하기</div>
                  <i className="ii ii-arrow-right" style={{ color: "#fff" }}></i>
                  <img src={Img("", "img/recruit_chicruiting_button.svg")} alt="" />
                </div>
                <div className="txt">원하는 조건 작성하고 맞춤 인재 이력서 받기!</div>
                <div className="click">지금 신청하러가기(Click)</div>
                <img className="click_img" src={StaticImg("cheecruit/click.svg")} alt="" />
              </button>
            </div>
            <button className="chicruiting_form BDS" onClick={onClickPopup}>
              <img
                style={{ top: 40, right: 30 }}
                src={Img("", "img/recruit_BDS_button.svg")}
                alt=""
              ></img>
              <div className="flex center">
                <div style={{ color: "#414E71" }}>BDS멤버 전용 요청서 작성하기</div>
                <i className="ii ii-arrow-right" style={{ color: "#414E71" }}></i>
              </div>
              <div className="txt">메가젠 Blue Diamond Society 멤버 전용입니다.</div>
            </button>
          </div>
        </div>
        <img className="chicruit1" src={StaticImg("cheecruit/chicruiting9.png")} alt="" />
        <img className="pb-10" src={StaticImg("cheecruit/chicruiting8.png")} alt="" />
        <img src={StaticImg("cheecruit/chicruiting3.png")} alt="" />
        <img
          className="chicruit2 cursor"
          src={StaticImg("cheecruit/chicruiting4.png")}
          onClick={() => onClickPopup()}
          alt=""
        />
      </div>
      {popup && <ChicruitPopup setPopup={setPopup} />}
      {reject && (
        <Popup
          setPopup={setReject}
          setConfirm={() => {
            navigate("/mypage");
          }}
          confirmBtn="인증하러가기"
        >
          <div>본 서비스는 인증된 의사회원만 이용 가능합니다.</div>
        </Popup>
      )}
      <ChannelTalk />
    </div>
  );
}

export default ChiCruiting;
