import SideMenu from "component/mypage/SideMenu";
import { useQuery } from "react-query";
import { api, StaticImg } from "utils/util";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import SubscribeButton from "component/user/subscribeButton";
import Nickname from "../../component/user/nickname";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const MySubscription = () => {
  const navigate = useNavigate();
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const take = 10;
  const token = useRecoilValue(tokenState);
  const { data: usersSubscribed, isLoading: usersSubscribedLoading } = useQuery(
    ["userSubscribed", token.uid, page],
    () => api.get(`/user/subscribed?page=${page}&take=${take}`).then((res) => res.data.result),
    {
      enabled: !!token.uid,
      cacheTime: Infinity,
    },
  );

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);

    navigate({ pathname: location.pathname, search: param.toString() });
  };

  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="mysubscription" />
            <div className="con__layout">
              <section className="bookmark mb-4">
                <article className="section__tit__box">
                  <div className="tit-box">
                    <span className="tit-txt">구독 관리</span>
                  </div>
                </article>
              </section>
              <div className="flex flex-col gap-3">
                {!usersSubscribedLoading && usersSubscribed && (
                  <>
                    {usersSubscribed.data.length > 0 ? (
                      usersSubscribed.data.map((user) => {
                        return (
                          <div
                            className="border-[1px] border-secondary-300 text-lg p-5 flex justify-between rounded"
                            key={user.uid}
                          >
                            <div className="w-fit text-base">
                              <Nickname
                                isDetail
                                uid={user.uid}
                                unick={user.nickname}
                                level={user.level}
                                isOwner={user.isOwner}
                              />
                            </div>
                            <SubscribeButton uid={user.uid} />
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex flex-col items-center justify-center mt-[100px] mb-[140px]">
                        <div className="flex flex-col items-center gap-[15px]">
                          <img src={StaticImg("klass/order/complete_icon.svg")} alt="" />
                          <h3 className="leading-[24px] text-[20px] text-secondary-900 font-semibold">
                            구독중인 대상이 없습니다.
                          </h3>
                        </div>
                      </div>
                    )}
                    {usersSubscribed.pages > 1 && (
                      <article className="paging">
                        <ReactPaginate
                          previousLabel="←"
                          previousLinkClassName="before"
                          nextLabel="→"
                          nextLinkClassName="next"
                          breakLabel="..."
                          currentPage={usersSubscribed.page}
                          pageCount={usersSubscribed.pages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={4}
                          pageLinkClassName="num"
                          activeClassName=""
                          activeLinkClassName="current"
                          onPageChange={onPageClick}
                          style={{ cursor: "pointer" }}
                          forcePage={page - 1}
                        />
                      </article>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MySubscription;
