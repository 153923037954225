import ReactPaginate from "react-paginate";
import Loading from "../../component/Loading";
import SideMenu from "../../component/mypage/SideMenu";
import { api, htmlTextContent, noticeDay } from "../../utils/util";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useQuery } from "react-query";
import ChicruitingListTable from "../../component/chicruiting/chicruitingListTable";
import { useState } from "react";
import { chicruiting_requests } from "../../constants";

function MyChicruiting() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const [popup, setPopup] = useState(false);
  const { data: mypageCheecruitsState, isLoading: mypageCheecruitsLoading } = useQuery(
    ["mypageCheecruits", page],
    () => api.get("/mypage/cheecruits", { params: { page: page } }).then((res) => res.data),
    {
      //   onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    navigate(`/mypage/mychicruiting?${param}`);
    window.scrollTo(0, 0);
  };

  if (mypageCheecruitsLoading) return <Loading />;

  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="mychicruiting" />
            <div className="con__layout">
              <section className="bookmark" style={{ marginBottom: "113.5px" }}>
                <article className="section__tit__box">
                  <div className="tit-box">
                    <span className="tit-txt" style={{ marginBottom: 30 }}>
                      치크루팅 요청내역
                    </span>
                  </div>
                </article>
                {mypageCheecruitsState && !!mypageCheecruitsState.recruits.length ? (
                  <div className="list__wrap">
                    <article className="list__table2">
                      <ChicruitingListTable
                        recruits={mypageCheecruitsState.recruits}
                        setPopup={setPopup}
                      />
                    </article>
                  </div>
                ) : (
                  <article className="no-data">
                    <img
                      src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_no_history_data.svg`}
                      alt="이미지"
                    />
                    <div className="txt">요청서를 작성한 내역이 없습니다.</div>
                    <Link to="/recruit/chicruiting">
                      <div className="chicruiting">치크루팅 보러가기</div>
                    </Link>
                  </article>
                )}
                {mypageCheecruitsState && mypageCheecruitsState.pages > 0 && (
                  <article className="mypage paging" style={{ paddingTop: "70px" }}>
                    <ReactPaginate
                      previousLabel="←"
                      previousLinkClassName="before"
                      nextLabel="→"
                      nextLinkClassName="next"
                      breakLabel="..."
                      pageCount={mypageCheecruitsState.pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={4}
                      pageLinkClassName="num"
                      activeClassName=""
                      activeLinkClassName="current"
                      onPageChange={onPageClick}
                      style={{ cursor: "pointer" }}
                    />
                  </article>
                )}
              </section>
            </div>
          </div>
        </section>
        {popup && <MyChicruitingPopup item={popup} setPopup={setPopup} />}
      </div>
    </div>
  );
}

export default MyChicruiting;

export const MyChicruitingPopup = ({ item, setPopup }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="popup-overlay dark" onClick={() => setPopup(false)}>
        <article className="popup chicruiting" style={{ display: "block" }}>
          <div className="date">요청일 {noticeDay(item.createdAt)}</div>
          <div className="name">치크루팅 요청</div>
          <div className="flex column">
            {/* <div className="row">
              <div className="mychicruiting_tit">주소</div>
              <div></div>
            </div> */}
            <div className="row">
              <div className="mychicruiting_tit">구성원수</div>
              <div>{item.memberCount}명</div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">담당업무</div>
              <div className="content">
                {item.expectedRoles?.map((item) => {
                  return (
                    <span>
                      {chicruiting_requests[0].filter((target) => target.value === item)[0].label}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">고용형태</div>
              <div className="content">
                {item.expectedWorkingTimes?.map((item) => {
                  return (
                    <span>
                      {chicruiting_requests[1].filter((target) => target.value === item)[0].label}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">희망경력</div>
              <div className="content">
                <span>
                  {
                    chicruiting_requests[2].filter(
                      (target) => target.value === item.expectedCareer.type,
                    )[0].label
                  }
                  {item.expectedCareer.type === "senior" &&
                    ` ${item.expectedCareer.forYears.min} ~ ${item.expectedCareer.forYears.max}년차`}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">급여조건</div>
              <div className="content">
                {item.expectedSalery.salaryWithoutTax &&
                  `세후 ${item.expectedSalery.salaryWithoutTax}만원`}
                {item.expectedSalery.salaryMemo && item.expectedSalery.salaryMemo}
              </div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">복리후생</div>
              <div className="content">
                {item.providedWelfares?.map((item) => {
                  return (
                    <span>
                      {chicruiting_requests[6].filter((target) => target.value === item)[0].label}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">근무일수</div>
              <div className="content">
                {item.expectedWorkingDateType?.map((item) => {
                  return (
                    <span>
                      {chicruiting_requests[4].filter((target) => target.value === item)[0].label}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">근무시간</div>
              <div className="content">{htmlTextContent(item.expectedWorkingTimeMemo)}</div>
            </div>
            <div className="row">
              <div className="mychicruiting_tit">담당자정보</div>
              <div className="content">
                {htmlTextContent(`${item.contactManager.email}\n${item.contactManager.phone}`)}
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <button className="white-button" onClick={() => setPopup(false)}>
              닫기
            </button>
            <button
              className="purple-button"
              onClick={() => navigate("/recruit/chicruiting/register")}
            >
              새로 요청하기
            </button>
          </div>
        </article>
      </div>
    </>
  );
};
