const ProseMirrorMenuPalette = ({
  Open,
  Context,
  Colors,
  dispatchMetaData,
  menubarClassName,
  cls,
}) => {
  const handleClick = (e) => {
    const color = e.target.dataset.color;
    if (Context === "text") {
      return dispatchMetaData({ type: "setTextColor", color });
    }
    if (Context === "textHighlight") {
      return dispatchMetaData({ type: "setTextBackgroundColor", color });
    }
    if (Context === "table") {
      return dispatchMetaData({ type: "setCellAttrs", color });
    }
  };

  return (
    <div
      className={cls(
        menubarClassName + "__color-palette",
        Open ? "!grid" : "none",
        Context === "table" ? "top-[30px] right-[99%]" : "",
      )}
    >
      {Colors.map((color, index) => {
        return (
          <figure
            onClick={handleClick}
            key={index}
            className={menubarClassName + "__color-palette__color"}
            title={color[0]}
            data-color={color[1]}
            style={{ backgroundColor: color[1] }}
          />
        );
      })}
    </div>
  );
};

export default ProseMirrorMenuPalette;
