import React, { useState } from "react";

const Tooltip = ({ text, children, showTooltip }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered && showTooltip && (
        <span className="absolute bg-secondary-900 text-white font-PretendardVariable text-[12px] px-2 py-1 rounded-md -top-7 left-1/2 transform -translate-x-1/2 z-10 whitespace-nowrap">
          {text}
        </span>
      )}
    </div>
  );
};

export default Tooltip;
