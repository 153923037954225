import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/footer/Footer";

function BusinessInfo() {
  return (
    <div className="container full">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <Link to="/">
              <img
                src={`${
                  import.meta.env.VITE_APP_PUBLIC_URL
                }img/img_login_logo.svg`}
                alt="로고"
              />
            </Link>
          </div>

          <div className="member__con">
            <div className="member__tit">
              <div className="tit-txt">사업자정보</div>
            </div>

            <div className="agree-form">
              <div className="agree-list">
                <div className="agree-box">
                  <div className="agree-service-box">
                    <b>주식회사 데니어</b>
                    <br />
                    <br />
                    대표: 송언의
                    <br />
                    <br />
                    사업자등록번호: 349-86-02042
                    <br />
                    <br />
                    주소: 전주시 덕진구 기린대로 886 미래혁신센터 201호
                    <br />
                    <br />
                    이메일: cs@deneer.co.kr
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default BusinessInfo;
