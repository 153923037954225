import FroalaEditor from "react-froala-wysiwyg";
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/languages/ko.js";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/file.min.css";
import "froala-editor/css/plugins/video.min.css";
import "froala-editor/css/plugins/draggable.min.css";
import axios from "axios";
import { api } from "../utils/util";
import { useEffect, useState } from "react";
import Loading from "./Loading";

const insertYoutubeVideo = (editor, url) => {
  if (!url.toLowerCase().includes("youtu")) return;
  axios.get(`https://youtube.com/oembed?format=json&url=${url}`).then((res) => {
    editor.video.insert(res.data.html);
    editor.html.insert("<br/>", true);
  });
};

const TextEditor = ({ useContent, setThumbnail }) => {
  const [content, setContent] = useContent;
  const [presignedUrl, setPresignedUrl] = useState(null);

  useEffect(() => {
    api?.post("/util/presigned/froala").then((res) => {
      setPresignedUrl(res.data);
    });
  }, []);

  if (!presignedUrl) return <Loading />;
  return (
    <div id="froala_editor">
      <FroalaEditor
        tag="textarea"
        config={{
          events: {
            contentChanged: function () {
              this.el.querySelector("img") && setThumbnail(this.el.querySelector("img").src);
            },
            "paste.beforeCleanup": function (clipboard_html) {
              const dom = new DOMParser().parseFromString(clipboard_html, "text/html").body;
              const aTag = dom.querySelector("a");
              if (!aTag) return;
              insertYoutubeVideo(this, aTag.href);
            },
            "url.linked": function (link) {
              insertYoutubeVideo(this, link.href);
            },
            "image.beforeUpload": (images) => {
              if (images[0].size > 30 * 1024 * 1024) {
                alert("용량은 30MB 이하로 첨부해주세요.");
              }
            },
            "video.beforeUpload": ($video) => {
              if ($video[0].size > 30 * 1024 * 1024) {
                alert("용량은 30MB 이하로 첨부해주세요.");
              }
            },
            "file.beforeUpload": ($files) => {
              if ($files[0].size > 30 * 1024 * 1024) {
                alert("용량은 30MB 이하로 첨부해주세요.");
              }
            },
          },
          emoticonsUseImage: false,
          heightMin: 600,
          key: "zEG4iE3B9D6A5F6C4G5aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3F3A1B8D6D4D4D3==",
          attribution: false,
          language: "ko",
          colorsText: [
            "#1abc9c",
            "#2ecc71",
            "#3498db",
            "#9b59b6",
            "#34495e",
            "#16a085",
            "#27ae60",
            "#2980b9",
            "#8e44ad",
            "#2c3e50",
            "#f1c40f",
            "#e67e22",
            "#e74c3c",
            "#ecf0f1",
            "#95a5a6",
            "#f39c12",
            "#d35400",
            "#c0392b",
            "#bdc3c7",
            "#7f8c8d",
            "REMOVE",
          ],
          colorsBackground: [
            "#55efc4",
            "#81ecec",
            "#74b9ff",
            "#a29bfe",
            "#dfe6e9",
            "#00b894",
            "#00cec9",
            "#0984e3",
            "#6c5ce7",
            "#b2bec3",
            "#ffeaa7",
            "#fab1a0",
            "#ff7675",
            "#fd79a8",
            "#636e72",
            "#fdcb6e",
            "#e17055",
            "#d63031",
            "#e84393",
            "#2d3436",
            "REMOVE",
          ],
          tableColors: [
            "#55efc4",
            "#81ecec",
            "#74b9ff",
            "#a29bfe",
            "#dfe6e9",
            "#00b894",
            "#00cec9",
            "#0984e3",
            "#6c5ce7",
            "#b2bec3",
            "#ffeaa7",
            "#fab1a0",
            "#ff7675",
            "#fd79a8",
            "#636e72",
            "#fdcb6e",
            "#e17055",
            "#d63031",
            "#e84393",
            "#2d3436",
            "REMOVE",
          ],
          linkList: [
            {
              text: "moreDEN",
              href: "https://www.moreden.co.kr",
              target: "_blank",
              rel: "nofollow",
            },
          ],
          imageMaxSize: 1024 * 1024 * 30,
          videoMaxSize: 1024 * 1024 * 30,
          fileMaxSize: 1024 * 1024 * 30,
          imageDefaultWidth: 1200,
          imageUploadToS3: presignedUrl,
          fileUploadToS3: presignedUrl,
          videoUploadToS3: presignedUrl,
          imageUploadMethod: "POST",
          imageUploadUrl: false,
          tableEditButtons: [
            "tableHeader",
            "tableRemove",
            "|",
            "tableRows",
            "tableColumns",
            "tableCells",
            "-",
            "tableCellBackground",
            "tableCellVerticalAlign",
            "tableCellHorizontalAlign",
            "tableCellStyle",
          ],
          imageEditButtons: [
            "imageAlign",
            "imageCaption",
            "imageLink",
            "linkOpen",
            "linkEdit",
            "linkRemove",
            "imageRemove",
          ],
          linkAlwaysBlank: true,
          videoEditButtons: ["videoRemove", "videoAlign"],
          videoAllowedTypes: ["mp4"],
          imageInsertButtons: ["imageBack", "|", "imageUpload", "imageByURL"],
          paragraphFormat: {
            N: "Normal",
            H1: "Heading 1",
            H2: "Heading 2",
            H3: "Heading 3",
            H4: "Heading 4",
          },
          toolbarSticky: true,
          imageTextNear: false,
          imageResizeWithPercent: true,
          toolbarStickyOffset: 119,
          toolbarButtons: [
            [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "textColor",
              "backgroundColor",
              "insertLink",
            ],
            ["align", "formatOL", "formatUL", "paragraphFormat", "insertHR", "quote"],
            ["insertTable", "insertImage", "insertVideo", "insertFile", "emoticons"],
          ],
        }}
        model={content}
        onModelChange={setContent}
      />
    </div>
  );
};

export default TextEditor;
