declare global {
  interface Window {
    analytics: any;
  }
}

export enum BANNER_DISPLAY_TYPE {
  ROLLING = "rolling",
  STATIC = "static",
}

export type AdsCampaign = {
  campaignId: number;
  campaignType: string;
  campaignName: string;
  inventoryId: number;
  inventoryName: string;
  inventoryDeviceType: string;
  inventoryWidth: number;
  inventoryHeight: number;
  displayMethod: string | null;
  advertisementId: number;
  advertisementFile: {
    url: string;
    alternativeText?: string | null;
    caption?: string | null;
    ext?: string;
  };
  link: string;
  segment: {
    promotion_id: number;
    name: string;
    position: string;
    creative: string;
  };
};

export type AdsInventory = {
  inventoryId: string;
  pageId: number;
  pageName: string;
  inventoryName: string;
  deviceType: string;
  displayMethod: string | null;
};
