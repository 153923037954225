import React, { useState } from "react";
import { api } from "../../utils/util";

function BoardReportPopup({ category, setReportPopup, bid }) {
  const [checkBox, setCheckBox] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    check6: false,
  });
  const [reasonInput, setReasonInput] = useState("");
  const checkContent = {
    check1: "의료법 위반,",
    check2: "치과의사 품위 저해/욕설/인신 공격,",
    check3: "유출/사칭/사기,",
    check4: "상업적 광고 및 도배,",
    check5: "특정인 비방,",
    check6: "기타:",
  };
  const isChecked =
    checkBox.check1 ||
    checkBox.check2 ||
    checkBox.check3 ||
    checkBox.check4 ||
    checkBox.check5 ||
    checkBox.check6;

  const reportFunc = () => {
    let reason = "";
    if (!isChecked) {
      alert("신고 사유를 선택해주세요.");
      return;
    }
    if (checkBox.check6 && reasonInput.length < 5) {
      alert("신고 사유를 5글자 이상 입력해주세요.");
      return;
    }
    for (let key in checkBox) {
      checkBox[key] && (reason += checkContent[key]);
    }
    checkBox.check6 && (reason += reasonInput);

    const data = {
      explanation: reason,
      id: bid,
      category: category,
    };

    api
      .post(`/user/report`, data)
      .then((res) => {
        alert(res.data.msg);
        setReportPopup(false);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  return (
    <article className="popup popup-leave" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="head">
          <div className="popup-tit">
            <i className="ic ic-logo"></i>
            <span className="txt">신고하기</span>
          </div>
        </div>
        <div className="body">
          <article className="pop__leave">
            <div className="leave__info__box">
              <div className="tit-box mb-3">신고하시기 전,</div>
              <div className="con-box">
                <div className="item-box">
                  신고는 반대의견을 나타내는 기능이 아닙니다.<br></br>신고 사유에 맞지 않는 신고를
                  했을 경우, 해당 신고는 처리되지 않습니다.
                </div>
              </div>
            </div>

            <div className="leave__check__box">
              <div className="tit-box mb-3">신고하시는 이유가 무엇인가요?</div>
              <div className="chk-list-box">
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={checkBox.check1}
                        onChange={(e) => setCheckBox({ ...checkBox, check1: e.target.checked })}
                      />
                      <i></i>
                      <span className="txt">의료법 위반</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={checkBox.check2}
                        onChange={(e) => setCheckBox({ ...checkBox, check2: e.target.checked })}
                      />
                      <i></i>
                      <span className="txt">치과의사 품위 저해/욕설/인신 공격</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={checkBox.check3}
                        onChange={(e) => setCheckBox({ ...checkBox, check3: e.target.checked })}
                      />
                      <i></i>
                      <span className="txt">유출/사칭/사기</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={checkBox.check4}
                        onChange={(e) => setCheckBox({ ...checkBox, check4: e.target.checked })}
                      />
                      <i></i>
                      <span className="txt">상업적 광고 및 도배</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={checkBox.check5}
                        onChange={(e) => setCheckBox({ ...checkBox, check5: e.target.checked })}
                      />
                      <i></i>
                      <span className="txt">특정인 비방</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={checkBox.check6}
                        onChange={(e) => setCheckBox({ ...checkBox, check6: e.target.checked })}
                      />
                      <i></i>
                      <span className="txt">기타</span>
                    </label>
                  </div>
                  <div className="text-box">
                    <textarea
                      placeholder="신고사유를 자세하게 입력해주세요."
                      value={reasonInput || ""}
                      onChange={(e) => {
                        setReasonInput(e.target.value);
                        !checkBox.check6 &&
                          setCheckBox({
                            ...checkBox,
                            check6: true,
                          });
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div className="bg-white p-5 mt-0 flex justify-center gap-2 border-t">
          <button
            onClick={() => setReportPopup(false)}
            className="w-[80px] h-[34px] bg-[#ccc] text-white rounded-md text-sm hover:bg-[#c2c2c2] cursor-pointer"
          >
            닫기
          </button>
          <button
            onClick={reportFunc}
            className="w-[80px] h-[34px] bg-[#333] text-white rounded-md text-sm hover:bg-[#000] cursor-pointer"
          >
            신고하기
          </button>
        </div>
      </div>
    </article>
  );
}

export default BoardReportPopup;
