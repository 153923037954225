import { Link } from "react-router-dom";
import { api } from "../../utils/util";
import Blur from "../../page/user/Blur";
import { useQuery } from "react-query";
import ContentLoader from "react-content-loader";
import CensoredData from "page/user/CensoredData";
import { useHomeSegment } from "hooks/useHomeSegment";

const HotQna = ({ user }: { user: string | null }) => {
  const { sendTrackingEvent } = useHomeSegment();
  const { data: qnaHotState, isLoading: qnaHotStateLoading } = useQuery("qnaHot", () =>
    api?.get("qna/hot").then((res) => res.data),
  );

  return (
    <div className="w-full flex flex-col justify-between">
      <span className="flex justify-between items-center pb-3">
        <b className="text-[20px]">실시간 HOT Q&amp;A</b>
        <Link
          to={`/qna/${user === "student" ? "student" : "doctor"}`}
          className="cursor-pointer text-[13px] text-gray-600 hover:underline"
        >
          + 더보기
        </Link>
      </span>
      <div className="">
        <Blur>
          {qnaHotStateLoading ? (
            <HotQnaBoxLoader />
          ) : (
            qnaHotState && (
              <div
                onClick={() => sendTrackingEvent({ name: "Q&A" })}
                className="hard-blur h-full grid grid-cols-3 gap-6"
              >
                {qnaHotState[0] && <HotQnaBox item={qnaHotState[0]} key={0} />}
                {qnaHotState[1] && <HotQnaBox item={qnaHotState[1]} key={1} />}
                {qnaHotState[2] && <HotQnaBox item={qnaHotState[2]} key={2} />}
              </div>
            )
          )}
        </Blur>
      </div>
    </div>
  );
};

export default HotQna;

const HotQnaBox = ({ item }: { item: qnaHotItem }) => {
  return (
    <Link
      to={`/qna/article/${item.bid}?page=1`}
      className="w-full px-[15px] py-[10px] cursor-pointer bg-white hover:bg-gray-50 shadow-list rounded-lg"
    >
      <div className="flex items-center text-[13px] text-gray-600">
        <span className="text-primary-800 text-[15px] mr-2">{item.cate_name}</span>
        <i className="ii ii-eye mr-1" />
        {item.view}
      </div>
      <div className="font-semibold flex items-center h-[35px]">
        <div className="text-primary-800 text-[25px]">Q&nbsp;</div>
        <div className="text-[17px] ellipsis">
          <CensoredData>{item.title}</CensoredData>
        </div>
      </div>
      <div className="text-gray-600 text-[15px] ellipsis">
        <CensoredData>{item.content}</CensoredData>
      </div>
    </Link>
  );
};

const HotQnaBoxLoader = () => (
  <ContentLoader
    speed={1}
    width={420}
    height={218}
    viewBox="0 0 420 218"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="10" ry="10" width="420" height="100" />
    <rect x="0" y="118" rx="10" ry="10" width="420" height="100" />
  </ContentLoader>
);

interface qnaHotItem {
  bid: number;
  cate_name: string;
  content: string;
  reg_dttm: any;
  title: string;
  unick: string;
  view: number;
}
