import CommentItem from "./CommentItem";

const BestComment = ({ item, getLikeInfo, likeClicked, setNewReply, setHighlightedCid }) => {
  return (
    <div className="cursor-pointer bg-[#ffe7e7]/40" onClick={() => setHighlightedCid(item.uuid)}>
      <CommentItem
        item={item}
        setNewReply={setNewReply}
        getLikeInfo={getLikeInfo}
        likeClicked={likeClicked}
        isBestComment={true}
      ></CommentItem>
    </div>
  );
};

export default BestComment;
