import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { publicApi, StaticImg } from "../../utils/util";
import Loading from "component/Loading";
import TopicsNavbar from "component/klass/TopicsNavbar";
import CourseCard from "component/klass/CourseCard";
import CoursesSearchForm from "component/klass/CoursesSearchForm";
import CoursesFilterForm from "component/klass/CoursesFilterForm";
import { CourseList } from "types/klass";
import { CLASS_ROUTE } from "App";
import ClassBanner from "component/advertisment/banner";
const limit = 200;

const KlassSearch = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const topics = searchParams.get("topics");
  const free = searchParams.get("free");
  const level = searchParams.get("level");
  const lecturers = searchParams.get("lecturers");
  const toothNumbers = searchParams.get("toothNumbers");
  const sort = searchParams.get("sort") || "threeMonthlySales:desc";

  const { data: courses, isLoading: coursesLoading } = useQuery<CourseList[] | undefined, Error>(
    ["KlassSearch", { q, topics, free, level, lecturers, toothNumbers, sort, limit }],
    () => {
      return publicApi
        ?.get("/class/public/courses/search", {
          params: {
            q,
            topics,
            free,
            level,
            lecturers,
            toothNumbers,
            sort,
            limit,
          },
        })
        .then((res) => {
          return res.data.results.hits;
        });
    },
  );

  useEffect(() => {
    const q = searchParams.get("q");
    const topics = searchParams.get("topics");
    const free = searchParams.get("free");
    const level = searchParams.get("level");
    const lecturers = searchParams.get("lecturers");
    const toothNumbers = searchParams.get("toothNumbers");
    const sort = searchParams.get("sort") || "threeMonthlySales:desc";
    queryClient.invalidateQueries([
      "KlassSearch",
      { q, topics, free, level, lecturers, toothNumbers, sort, limit },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (!courses || coursesLoading) return <Loading small />;

  return (
    <div className="">
      <div className="max-w-[1200px] mx-auto">
        <div className="mb-1.5 lg:mb-6">
          <div className="hidden lg:block">
            <ClassBanner name="pc_KlassSearch_top" page="KlassSearch" />
          </div>
        </div>
        <TopicsNavbar />
      </div>
      <div className="max-w-[1200px] mx-[16px] lg:mx-auto">
        <div className="relative flex my-5">
          <div className="absolute lg:relative bottom-[3px] lg:bottom-auto flex-0 lg:flex-1">
            <h3 className="pt-2 text-secondary-800 text-[16px] lg:text-[18px] font-semibold">
              검색결과 {courses.length}건
            </h3>
          </div>
          <div className="flex-1 lg:flex-0 lg:max-w-[330px]">
            <CoursesSearchForm />
          </div>
          <div className="flex-0 ml-3 text-right">
            <CoursesFilterForm />
          </div>
        </div>
        {!coursesLoading && courses.length === 0 && (
          <div className="mt-[54px] flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center space-y-4">
              <img src={StaticImg("klass/order/complete_icon.svg")} alt="" />
              <h3 className="text-xl text-gray-900 font-semibold">검색결과가 없습니다.</h3>
              <div className="text-[#A0A0A0] text-base font-semibold text-center">
                다른 검색어를 입력해주세요.
                <br />더 나은 검색결과를 보여드릴 수 있도록 노력하겠습니다!
              </div>
            </div>
          </div>
        )}
        {!coursesLoading && courses.length > 0 && (
          <div className="mx-[-10px] grid grid-cols-2 lg:grid-cols-4">
            {courses.map((item) => {
              return (
                <Link to={`/${CLASS_ROUTE}/course/${item.id}`} key={item.id}>
                  <CourseCard
                    thumbnail={item.thumbnailImageUrl}
                    lecturers={item.lecturers}
                    title={item.title}
                    regularPrice={item?.product?.regularPrice}
                    salePrice={item?.product?.salePrice}
                    topic={item?.groupedBadges?.topics}
                    level={item?.groupedBadges?.level?.join(",")}
                    isFree={item.isFree}
                  />
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default KlassSearch;
