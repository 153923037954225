import axios from "axios";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { api, BASE_URL } from "../../utils/util";

const ChangePwd = ({ setPopup }) => {
  const [password, setPassword] = useState("");
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [useable, setUseable] = useState(false);
  const token = useRecoilValue(tokenState);
  const checkPassword = () => {
    const data = {
      id: token.user_id,
      password: password,
    };

    axios
      .post(`${BASE_URL}/user/login`, data)
      .then((res) => {
        alert("비밀번호 변경이 가능합니다");
        setUseable(true);
      })
      .catch((err) => {
        alert("사용중인 비밀번호가 아닙니다.");
      });
  };

  const setMaxLen = (value, max = 30) => {
    if (value.length > max) {
      alert(`${max}글자 이하만 가능합니다.`);
      return false;
    }
    return true;
  };

  const setNewPwd = () => {
    if (pw1.length < 4) {
      alert("비밀번호는 4글자 이상 입력해주세요.");
      return;
    }
    if (pw1 !== pw2) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    const params = {
      new_pw: pw1,
    };

    api
      .post("/user/modifyPassword", params)
      .then((res) => {
        alert(res.data.msg);
        setPopup(false);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  return (
    <>
      <div className="popup-overlay dark"></div>
      <article className="popup popup-addr" style={{ display: "block" }}>
        <div className="popup-wrap">
          <div className="head">
            <div className="popup-tit">
              <i className="ic ic-logo"></i>
              <span className="txt">비밀번호 변경</span>
            </div>

            <span onClick={() => setPopup(false)} className="close-btn"></span>
          </div>
          <div className="body">
            <article className="pop__addr">
              <div className="change-pwd__txt">
                <div>사용중인 비밀번호를 입력해주세요.</div>
              </div>
            </article>

            <div className="change-pwd__box">
              <input
                type="password"
                placeholder="비밀번호를 입력하세요."
                value={password}
                onChange={(e) => {
                  setMaxLen(e.target.value) && setPassword(e.target.value);
                }}
              />
              <div className="pwd-check">
                <button className="pwd-check__btn" onClick={checkPassword}>
                  확인
                </button>
              </div>
            </div>
            {useable && (
              <>
                <article className="pop__addr">
                  <div className="change-pwd__txt">
                    <span>새 비밀번호를 입력해주세요.</span>
                    {pw1.length < 4 ? (
                      <span className="info-txt">비밀번호는 4글자 이상 입력해주세요.</span>
                    ) : pw1 === pw2 ? (
                      <span className="info-txt ok">비밀번호가 일치합니다.</span>
                    ) : (
                      <span className="info-txt">비밀번호가 일치하지 않습니다.</span>
                    )}
                  </div>
                </article>
                <div className="change-pwd__box">
                  <input
                    type="password"
                    placeholder="새 비밀번호"
                    value={pw1}
                    onChange={(e) => {
                      setPw1(e.target.value);
                    }}
                  />
                </div>
                <article className="pop__addr">
                  <div className="change-pwd__txt">
                    <div>새 비밀번호를 한번 더 입력해주세요.</div>
                  </div>
                </article>
                <div className="change-pwd__box">
                  <input
                    type="password"
                    placeholder="새 비밀번호 확인"
                    value={pw2}
                    onChange={(e) => {
                      setPw2(e.target.value);
                    }}
                  />
                </div>
              </>
            )}

            <div className="popup__menu">
              <button onClick={() => setPopup(false)} className="pop-menu-btn">
                닫기
              </button>
              {useable && (
                <button
                  onClick={setNewPwd}
                  className="pop-menu-btn type1"
                  disabled={pw1.length < 4 || pw1 !== pw2}
                >
                  확인
                </button>
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default ChangePwd;
