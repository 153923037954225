import { useState, useEffect } from "react";
import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import { getStorage } from "utils/util";
import { getProducts } from "api/market/product/api";
import { getSingleSearch, getMultiSearch, getSearchKey, getSearchTopicList } from "./api";

const useSearchKey = () => {
  const searchKeyExpireAt = parseInt(getStorage().getItem("searchKeyExpireAt") || "0");
  const [isSearchKeyValid, setIsSearchKeyValid] = useState(
    !!getStorage().getItem("searchKey") &&
      !!getStorage().getItem("searchIndexes") &&
      !!getStorage().getItem("searchKeyExpireAt") &&
      searchKeyExpireAt > new Date().getTime(),
  );
  const { data: keyData, isSuccess: isSuccessKeyData } = useQuery({
    queryKey: ["getSearchKey"],
    queryFn: getSearchKey,
    enabled: !isSearchKeyValid,
    staleTime: 4 * 60 * 1000,
  });

  useEffect(() => {
    if (isSuccessKeyData) {
      const expireAt = new Date().getTime() + 4 * 60 * 1000;
      getStorage().setItem("searchKey", keyData?.searchKey || "");
      getStorage().setItem("searchIndexes", JSON.stringify(keyData?.indexes || []));
      getStorage().setItem("searchKeyExpireAt", expireAt.toString());
      setIsSearchKeyValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessKeyData]);

  const clearSearchKey = () => {
    getStorage().removeItem("searchKey");
    getStorage().removeItem("searchIndexes");
    getStorage().removeItem("searchKeyExpireAt");
  };

  // MARK: 403 토큰 만료 응답을 받을 경우, searchKey를 갱신합니다.
  const refreshSearchKey = async () => {
    clearSearchKey();
    const { searchKey, indexes } = await getSearchKey();
    const expireAt = new Date().getTime() + 4 * 60 * 1000;
    getStorage().setItem("searchKey", searchKey || "");
    getStorage().setItem("searchIndexes", JSON.stringify(indexes || []));
    getStorage().setItem("searchKeyExpireAt", expireAt.toString());
    setIsSearchKeyValid(true);
  };

  return { isSearchKeyValid, refreshSearchKey };
};

export const useSingleSearchInfinite = (
  {
    indexUid,
    searchKeyword,
    offset = 0,
    limit = 30,
    sort,
    additionalBody = {},
    uniqKey = undefined,
  } = {},
  queryOptions = {},
) => {
  // ---------- searchKey 갱신 관련 로직 ----------
  const { isSearchKeyValid, refreshSearchKey } = useSearchKey();

  // ---------- 검색 결과 로직 ----------
  const startPage = Math.floor(offset / limit) + 1;
  return useInfiniteQuery({
    // MARK: additionalBody를 사용하는 경우, additionalBody마다 별도의 uniqKey를 설정해주세요
    queryKey: ["/search", indexUid, searchKeyword, offset, limit, sort, uniqKey],
    queryFn: ({ pageParam = startPage }) => {
      if (!isSearchKeyValid) return;

      if (indexUid === "market") {
        // MARK: 마켓의 경우, 재고확인 및 포인트 적립 등의 추가적인 정보를 가져오기 위해 MarketBff API를 사용합니다.
        return getProducts({
          q: searchKeyword,
          offset: (pageParam - 1) * limit,
          limit,
          sort: [sort],
        });
      }

      return getSingleSearch(
        {
          indexUid,
          reqBody: {
            ...QUERIES_SINGLE_SEARCH[indexUid],
            q: '"' + searchKeyword + '"',
            offset: (pageParam - 1) * limit,
            limit,
            ...additionalBody,
          },
        },
        refreshSearchKey,
      );
    },
    getNextPageParam: (lastPage, allPages) => {
      const { estimatedTotalHits = 0, limit, offset } = lastPage;
      const hasMorePage = estimatedTotalHits > offset + limit;
      if (hasMorePage) {
        const nextPage = allPages.length + startPage;
        return nextPage;
      }
      return undefined;
    },
    select: (data) => data?.pages || [],
    retry: (failureCount, error) =>
      error?.response?.data?.code === "invalid_api_key" && failureCount < 3,
    enabled: isSearchKeyValid && !!indexUid && !!searchKeyword,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    ...queryOptions,
  });
};

export const useMultiSearchInfinite = (
  { indexUid, searchKeyword, offset = 0, limit = 30, customQueries = null } = {},
  queryOptions = {},
) => {
  // ---------- searchKey 갱신 관련 로직 ----------
  const { isSearchKeyValid, refreshSearchKey } = useSearchKey();

  // ---------- 검색 결과 로직 ----------
  const startPage = Math.floor(offset / limit) + 1;
  return useInfiniteQuery({
    queryKey: ["/multi-search", indexUid, searchKeyword, offset, limit],
    queryFn: ({ pageParam = startPage }) => {
      if (!isSearchKeyValid) return;

      // MARK: 로컬 스토리지에 저장된 indexes를 사용하기에 보안 우려성이 있지만,
      // 이세환님께 확인 받은 바로는 서버에서 2차로 유저 권한 체크해서 응답을 주기에 로컬 스토리지를 사용합니다.
      const searchIndexes = JSON.parse(getStorage().getItem("searchIndexes"));
      const queries = customQueries ? customQueries : QUERIES_MULTI_SEARCH[indexUid].queries;
      const enhancedQueries = queries
        // MARK: searchIndexes에 포함된 indexUid만 검색합니다.
        .filter((query) => searchIndexes.includes(query?.indexUid))
        .map((query) => ({
          ...query,
          q: '"' + searchKeyword + '"',
          offset: (pageParam - 1) * limit,
          limit,
        }));

      return getMultiSearch({ reqBody: { queries: enhancedQueries } }, refreshSearchKey);
    },
    getNextPageParam: (lastPage, allPages) => {
      const { results = [] } = lastPage;
      const hasMorePage = results.some(
        (result) => result.estimatedTotalHits > result.offset + result.limit,
      );
      if (hasMorePage) {
        const nextPage = allPages.length + startPage;
        return nextPage;
      }
      return undefined;
    },
    select: (data) => data?.pages || [],
    retry: (failureCount, error) =>
      error?.response?.data?.code === "invalid_api_key" && failureCount < 3,
    enabled: isSearchKeyValid && !!indexUid && !!searchKeyword,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    ...queryOptions,
  });
};

export const useSearchTopicList = ({ topicType }, queryOptions) => {
  // MARK: seminar의 경우 api 요청시 'forum'를 사용합니다.
  const convertedTopicType = topicType === "seminar" ? "qna" : topicType;

  return useQuery({
    queryKey: ["searchTopicList", topicType],
    queryFn: () => getSearchTopicList(convertedTopicType),
    enabled: !!topicType,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (topicList) => {
      // MARK: 중복 제거
      const map = new Map();
      for (const topic of topicList) {
        if (!map.has(topic.cate_name)) {
          map.set(topic.cate_name, topic);
        }
      }
      return Array.from(map.values());
    },
    ...queryOptions,
  });
};

export const useClearSearchCache = () => {
  const queryClient = useQueryClient();
  const clearSingleSearchCache = () => {
    queryClient.removeQueries(["/search"], { exact: false });
  };
  const clearMultiSearchCache = () => {
    queryClient.removeQueries(["/multi-search"], { exact: false });
  };
  const clearSearchCache = () => {
    clearSingleSearchCache();
    clearMultiSearchCache();
  };
  return { clearSingleSearchCache, clearMultiSearchCache, clearSearchCache };
};

// -------------------- 카테고리별 쿼리 템플릿입니다. --------------------
// MARK: 새로운 카테고리 생성 시 아래에 추가해주세요.

const QUERIES_SINGLE_SEARCH = {
  qna: {
    attributesToCrop: ["content"],
    cropLength: 100,
  },
  qna_comment: {
    attributesToCrop: ["content"],
    cropLength: 100,
  },
  forum: {
    filter: ["board_name = 임상포럼"],
    attributesToCrop: ["content"],
    cropLength: 100,
  },
  forum_comment: {
    attributesToCrop: ["content"],
    cropLength: 100,
  },
  content: {
    filter: ["board_name = 컨텐츠"],
    attributesToCrop: ["content"],
    cropLength: 100,
  },
  classes: {
    filter: ["isHidden = false", "isDeleted = false"],
    attributesToCrop: ["content"],
    cropLength: 100,
  },
  seminar: {
    attributesToCrop: ["content"],
    cropLength: 100,
  },
  recruit: {
    attributesToCrop: ["content"],
    cropLength: 100,
  },
};

const QUERIES_MULTI_SEARCH = {
  all: {
    queries: [
      {
        indexUid: "qna",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "content",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
        filter: ["board_name = 임상포럼"],
      },
      {
        indexUid: "content",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
        filter: ["board_name = 컨텐츠"],
      },
      {
        indexUid: "classes",
        q: "",
        filter: ["isHidden = false", "isDeleted = false"],
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "seminar",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "recruit",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "dentist",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "dentist_m",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "dentist_f",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "student",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "student_m",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "student_f",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
    ],
  },
  post: {
    queries: [
      {
        indexUid: "dentist",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "dentist_m",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "dentist_f",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "student",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "student_m",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
      {
        indexUid: "student_f",
        q: "",
        attributesToCrop: ["content"],
        cropLength: 100,
      },
    ],
  },
};
