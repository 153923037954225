import React from "react";
import ContentLoader from "react-content-loader";

const MypageTableLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1000}
    height={200}
    viewBox="0 0 1000 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="312" height="144" />
    <rect x="0" y="152" rx="5" ry="5" width="300" height="15" />
    <rect x="0" y="178" rx="0" ry="0" width="80" height="27" />
    <rect x="90" y="177" rx="0" ry="0" width="80" height="27" />

    <rect x="333" y="0" rx="5" ry="5" width="312" height="144" />
    <rect x="333" y="152" rx="5" ry="5" width="300" height="15" />
    <rect x="333" y="178" rx="0" ry="0" width="80" height="27" />
    <rect x="423" y="177" rx="0" ry="0" width="80" height="27" />

    <rect x="666" y="0" rx="5" ry="5" width="312" height="144" />
    <rect x="666" y="152" rx="5" ry="5" width="300" height="15" />
    <rect x="666" y="178" rx="0" ry="0" width="80" height="27" />
    <rect x="756" y="177" rx="0" ry="0" width="80" height="27" />
  </ContentLoader>
);

export default MypageTableLoader;
