export const useHomeSegment = () => {
  const segment = window.analytics;

  const sendTrackingEvent = ({ name }) => {
    segment?.track("Home Clicked", {
      name,
    });
  };

  return { sendTrackingEvent };
};
