import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { isMarketOnlyUser } from "utils/marketUtil";
import { api } from "utils/util";

export const useUserInfo = () => {
  const token = useRecoilValue(tokenState);
  return useQuery(
    ["/mypage/info", token?.uid],
    () => api?.get("/mypage/info").then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: 15 * 60 * 1000,
      enabled: !!(token && token.user_type && !isMarketOnlyUser(token.user_type)),
    },
  );
};

export const useUserGroupCode = () => {
  const { data: myInfo, isLoading: myInfoLoading } = useUserInfo();

  const userGroupCode = getUserGroupCode(myInfo);

  return { data: userGroupCode, isLoading: myInfoLoading };
};

const getUserGroupCode = (myInfo) => {
  const userGroupCodeList = [0, 0, 0];

  if (myInfo) {
    if (myInfo.gender === "M") {
      userGroupCodeList[0] = 1;
    } else if (myInfo.gender === "F") {
      userGroupCodeList[0] = 2;
    }

    if (myInfo.user_type === "doctor") {
      userGroupCodeList[1] = 1;
    } else if (myInfo.user_type === "student") {
      userGroupCodeList[1] = 2;
    }

    if (myInfo.is_owner) {
      userGroupCodeList[2] = 1;
    } else if (!myInfo.is_owner) {
      userGroupCodeList[2] = 2;
    }
  }

  return userGroupCodeList.join("");
};
