import { useQuery } from "react-query";
import { Route, Routes, Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import Loading from "../../component/Loading";
import { tokenState } from "../../state";
import { api } from "../../utils/util";
import QnaDetail from "./QnaDetail";
import QnaPage from "./QnaPage";
import QnaWrite from "./QnaWrite";

function Qna() {
  const token = useRecoilValue(tokenState);
  const user = token && token.user_type;

  const { isLoading: topicListLoading } = useQuery(
    "qnaCateCd",
    () => api.get("/qna/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        err?.response
          ? alert(err.response.data.msg)
          : alert("Q&A 토픽을 받아오는 데 실패하였습니다");
      },
    },
  );

  if (topicListLoading) return <Loading />;
  return (
    <section className="relative min-h-[100vh]">
      <Header current="qna" />
      <article className="container pb-64">
        <Routes>
          <Route path="/article/:bid/*" element={<QnaDetail />} />
          <Route path="/write/:tab/:cate_cd/*" element={<QnaWrite />} />
          <Route path="/modify/:tab/:bid/*" element={<QnaWrite />} />
          <Route path="/:tab/*" element={<QnaPage />} />
          <Route
            path="/*"
            element={<Navigate replace to={`/qna/${user === "student" ? "student" : "doctor"}`} />}
          />
        </Routes>
      </article>
      <Footer />
    </section>
  );
}

export default Qna;
