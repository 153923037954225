import { useState, useEffect } from "react";
import ClassQuiz from "./ClassQuiz";

import { api, noticeDay } from "../../utils/util";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { useNavigate } from "react-router-dom";
import { EDU_ROUTE } from "App";

const ClassCurriculumList = ({ cid, item, registration }) => {
  const navigate = useNavigate();
  const [lectureList, setLectureList] = useState({ load: false });
  const [quizPopup, setQuizPopup] = useState(false);
  const closePopup = (isDone) => {
    setQuizPopup(false);
    if (isDone) {
      api.get(`class/course/${cid}/lecture/list`).then((res) => {
        setLectureList({ ...lectureList, progresses: res.data.progresses });
      });
    }
  };

  const getQid = (lid) => {
    const quiz = lectureList.quizzes.find((item) => item.lid === lid);
    return quiz && quiz.qid;
  };

  const getOngoingLecture = () => {
    return (
      lectureList.progresses && lectureList.progresses.find((item) => !item.lecture_completed_at)
    );
  };

  const getProgressItem = (lid) => {
    return lectureList.progresses && lectureList.progresses.find((item) => item.lid === lid);
  };

  const getLectureItem = (lid) => {
    return lectureList.lectures && lectureList.lectures.find((item) => item.lid === lid);
  };

  const getVideoProgress = (lecture) => {
    const progressItem = getProgressItem(lecture.lid);

    if (!progressItem) return 0;
    return Math.trunc((progressItem.elapsed_seconds * 100) / lecture.total_contents_time_sec);
  };

  const isPreqCompleted = (lecture) => {
    const preqList = lecture.prerequisite;
    if (!preqList) return true;
    return !preqList.some((preqlid) => isCompleted(preqlid) === false);
  };

  const isCompleted = (lid) => {
    const progressItem = getProgressItem(lid);
    if (!progressItem) return false;

    const lectureItem = getLectureItem(lid);
    return getVideoProgress(lectureItem) >= 100 && !!progressItem.lecture_completed_at;
  };

  const isQuizCompleted = (lecture) => {
    const progressItem = getProgressItem(lecture.lid);
    return progressItem && progressItem.quiz_completed_at;
  };

  const isUnlock = (lecture) => {
    // const ongoingLecture = getOngoingLecture();
    // if (ongoingLecture) {
    //   return ongoingLecture.lid === lecture.lid ? true : false;
    // }

    return isPreqCompleted(lecture);
  };

  const userToken = useRecoilValue(tokenState);
  const user_info =
    lectureList.load && userToken
      ? {
          name: userToken.user_name,
          dentist_id: registration.doc_id,
          numOfLectuerDone: lectureList.progresses
            ? lectureList.progresses.filter((item) => !!item.lecture_completed_at).length
            : 0,
          numOfLecture: item.education
            ? item.education.required_unit === "시간"
              ? 1
              : item.education.required_unit_amount
            : lectureList.lectures.length,
        }
      : {
          name: "",
          dentist_id: "",
          numOfLectuerDone: 0,
          numOfLecture: lectureList.load ? lectureList.lectures.length : 1,
        };

  const lectureSecondsToStr = (sec) => {
    const hour = Math.trunc(sec / 3600) % 60;
    const minute = Math.trunc(sec / 60) % 60;
    const seconds = sec % 60;

    if (hour >= 1) {
      return `${hour}시간 ${minute}분`;
    }

    return `${minute}분 ${seconds}초`;
  };

  useEffect(() => {
    api.get(`class/course/${cid}/lecture/list`).then((res) => {
      setLectureList({ ...res.data, load: true });
    });
  }, [setLectureList, cid]);

  return (
    <>
      {lectureList.load && (
        <div className="class__box">
          <div className="name">{user_info.name}님</div>

          <div className="course_progress">
            <div className="txt">
              <label>
                수강률: {user_info.numOfLectuerDone}/{user_info.numOfLecture}강 (
                {user_info.numOfLecture === 0
                  ? 100
                  : Math.ceil((user_info.numOfLectuerDone / user_info.numOfLecture) * 100)}
                %)
              </label>
              <label
                className={
                  user_info.numOfLectuerDone >= user_info.numOfLecture
                    ? "progress done"
                    : "progress"
                }
              >
                {user_info.numOfLectuerDone >= user_info.numOfLecture ? "이수완료" : "수강미완료"}
              </label>
            </div>
            <progress
              id="file"
              max={100}
              value={
                user_info.numOfLecture === 0
                  ? 100
                  : (user_info.numOfLectuerDone / user_info.numOfLecture) * 100
              }
            />
          </div>
          {/* {item && item.education ? (
            item.education.credit === 0 ? (
              <div className="notice">
                📌 이수점수가 없는 학술대회로 보수교육 점수가 인정되지 않습니다.
              </div>
            ) : (
              <div className="notice">
                📌 본 보수교육은 {item.education.required_unit_amount}
                {item.education.required_unit} 모두 수강하셔야 이수 완료됩니다.
              </div>
            )
          ) : (
            <></>
          )} */}
        </div>
      )}
      <article className="list__table2">
        <div className="class list">
          <div className="class list-head">
            <div className="w70">번호</div>
            <div className="w100">동영상 강의</div>
            <div className="w280 left">커리큘럼</div>
            <div className="w70">첨부파일</div>
            <div className="w150 left">연자(소속)</div>
            <div className="w150 left">교육일정</div>
            <div className="w100">강의시간</div>
            <div className="w70">QUIZ</div>
            <div className="w70">수강률</div>
            <div className="w70">수강완료</div>
            <div className="w70">잠김</div>
          </div>
          {lectureList.load &&
            lectureList.lectures
              .sort((a, b) => a.order - b.order)
              .map((item, idx) => {
                const progress = {
                  videoProgress: getVideoProgress(item),
                  videoCompleted: getVideoProgress(item) >= 100,
                  quizCompleted: isQuizCompleted(item),
                  completed: isCompleted(item.lid),
                  hasQuiz: !!getQid(item.lid),
                  unlock: isUnlock(item),
                  ongoing: getOngoingLecture() && getOngoingLecture().lid === item.lid,
                };

                return (
                  <div key={idx}>
                    <div className={progress.unlock ? "class content available" : "class content"}>
                      <div className="w70">{item.order}</div>
                      {/* 동영상 => 선수강요건이 모두 수강완료일 때 활성화 */}
                      <div className="w100">
                        <button
                          className={progress.unlock ? "play available" : "play"}
                          disabled={!progress.unlock}
                          onClick={() => {
                            navigate(`/${EDU_ROUTE}/lecture/${item.lid}`, {
                              state: { registration: registration },
                            });
                          }}
                        />
                      </div>
                      <div className={"w280 left"}>
                        <button
                          className={progress.unlock ? "desc available" : "desc"}
                          disabled={!progress.unlock}
                          onClick={() => {
                            navigate(`/${EDU_ROUTE}/lecture/${item.lid}`, {
                              state: { registration: registration },
                            });
                          }}
                        >
                          {item.sub_title}
                        </button>
                      </div>
                      <div className="w70">
                        {item.attachment && (
                          <a href={item.attachment} target="_blank" rel="noopener noreferrer">
                            <button className="download" />
                          </a>
                        )}
                      </div>
                      <div className="w150 left">{item.lecturer}</div>
                      <div className="w150 left">
                        {noticeDay(item.release_at)} ~ {noticeDay(item.conceal_at)}
                      </div>
                      <div className="w100">
                        {lectureSecondsToStr(item.total_contents_time_sec)}
                      </div>
                      {/* 퀴즈 => 수강률 100%일 시 활성화*/}
                      <div className="w70">
                        {progress.hasQuiz && (
                          <button
                            className={
                              progress.videoCompleted && !progress.quizCompleted
                                ? "quiz on"
                                : "quiz"
                            }
                            disabled={!progress.videoCompleted || progress.quizCompleted}
                            onClick={() => setQuizPopup(item.lid)}
                          />
                        )}
                      </div>
                      {/* 수강률 => 영상 시청 정도 반영*/}
                      <div className={item.unlock && !progress.completed ? "w70 red" : "w70"}>
                        <b>{progress.videoCompleted ? "100" : progress.videoProgress}%</b>
                      </div>
                      {/* 수강완료 => lecture_completed_at 표시에서 */}
                      <div className={progress.completed ? "w70 green" : "w70 red"}>
                        <b>{progress.completed ? "이수완료" : "미완료"}</b>
                      </div>
                      {/* 잠김 => 선수강요건이 모두 수강완료일 때 비활성화*/}
                      <div className="w70">{!progress.unlock && <button className="lock" />}</div>
                    </div>
                    {/* 안내 => 잠금이 풀린 강의가 이수완료되지않을 때 활성화 */}
                    {/* {progress.ongoing && (
                      <div className="content alarm">
                        {progress.hasQuiz ? (
                          <span>
                            {item.order}강을 모두 수강하고 <b>QUIZ</b>까지 푸셔야 다음 강의를 들으실
                            수 있습니다.
                          </span>
                        ) : (
                          <span>
                            {item.order}강을 모두 수강하셔야 다른 강의를 들으실 수 있습니다.
                          </span>
                        )}
                      </div>
                    )} */}
                  </div>
                );
              })}
        </div>
      </article>
      {quizPopup && <ClassQuiz closePopup={closePopup} lid={quizPopup} qid={getQid(quizPopup)} />}
    </>
  );
};

export default ClassCurriculumList;
