import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { findIndicator, indicatorPlugin } from "./indicator";

/**
 * 로딩 인디케이터가 표시된 위치에 업로드된 비디오를 삽입합니다.
 * @type {() => import('prosemirror-state').Command}
 */
export const insertVideo = (videos, id) => (state, dispatch, view) => {
  const tr = state.tr
  const pos = findIndicator(view.state, id)
  const videoNodes = videos.map(({url, poster}) =>
    view.state.schema.nodes.paragraph.create(
      null,
      view.state.schema.nodes.video.create({
        src: url,
        poster
      }),
    )
  )

  tr.insert(pos, videoNodes).setMeta(indicatorPlugin, {
    remove: {id},
  })

  dispatch(tr)
  return true
}

export const Video = Extension.Create({
  name: 'video',

  type: 'node',

  defineSpec() {
    return {
      attrs: {
        src: {},
        poster: {default: null},
      },
      inline: true,
      group: 'inline',
      draggable: true,
      marks: '',
      parseDOM: [
        {
          tag: 'video',
          getAttrs(dom) {
            const source = dom.querySelector('source')
            if (!source) return false
            return {
              src: source.getAttribute('src'),
              poster: dom.getAttribute('poster'),
            }
          },
        },
      ],
      toDOM(node) {
        const {src, poster} = node.attrs
        return [
          'video',
          {
            controls: '',
            draggable: 'false',
            poster,
            class: MEDISTREAM_SCHEMA_STYLE.nodes.video,
          },
          ['source', {src}],
        ]
      },
    }
  },

  addCommands() {
    return {
      insertVideo,
    }
  }
})
