import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { default as Select } from "react-select";
import AttachImages from "../../component/shared/AttachImages";
import { majorOptions, timeOptions } from "../../constants";
import { api, selectStyles } from "../../utils/util";
import AddressSelect from "../management/AddressSelect";
import { salaryOptions } from "../../constants";
import Loading from "component/Loading";

function DoctorWrite(props) {
  const navigate = useNavigate();
  const { state: modify } = useLocation();
  const { bid: article_num } = useParams();
  const [title, setTitle] = useState(modify ? modify.article.title : "");
  const [content, setContent] = useState(modify ? modify.article.content : "");
  const [majors, setMajors] = useState([]);
  const [workingTime, setWorkingTime] = useState(
    modify ? timeOptions.find((item) => item.value === modify.article.working_time) : "",
  );
  const [wageType, setWageType] = useState(
    modify
      ? salaryOptions.find((item) => item.value === modify.article.wage_type) ||
          salaryOptions.find((item) => !item.value)
      : salaryOptions[0],
  );
  const [wageValue, setWageValue] = useState(
    modify && modify.article.wage_type
      ? (modify.article.gross_wage && `${Number(modify.article.gross_wage) / 10000}`) ||
          (modify.article.net_wage && `${Number(modify.article.net_wage) / 10000}`)
      : "",
  );
  const [wageInTax, setWageInTax] = useState(
    modify && modify.article.gross_wage ? "gross_wage" : "net_wage",
  );
  const [images, setImages] = useState(modify ? modify.images.map((item) => item.link) : []);
  const [address, setAddress] = useState({
    selectedItem: {
      address: modify ? modify.article.address : "",
      detail: modify ? modify.article.detail_address : "",
    },
  });
  const [callNumber, setCallNumber] = useState(modify ? modify.article.call_number : "");
  const [hospitalName, setHospitalName] = useState(modify ? modify.article.hospital_name : "");
  const salaryOptionsSelect = salaryOptions.filter((item) => item.value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!modify) return;
    const pre_major = modify.article.major.split("");
    const preMajorOption = [];
    pre_major.forEach((major) =>
      preMajorOption.push(majorOptions.find((item) => item.value === major)),
    );
    setMajors(preMajorOption);
  }, [modify]);

  useEffect(() => window.scrollTo(0, 0), []);

  const submit = async () => {
    if (!majors.length) {
      alert("전공을 선택해주세요");
      return;
    }
    if (!workingTime) {
      alert("근무형태를 선택해주세요");
      return;
    }
    if (!address.selectedItem || !address.selectedItem.address) {
      alert("주소를 입력해주세요");
      return;
    }
    if (!hospitalName) {
      alert("병원명을 입력해주세요");
      return;
    }
    if (!callNumber) {
      alert("전화번호를 입력해주세요");
      return;
    }
    if (title.length < 5) {
      alert("제목을 5글자 이상 입력해야 합니다");
      return;
    }
    if (content.length < 3) {
      alert("내용을 3글자 이상 입력해야 합니다");
      return;
    }

    const data = {
      title,
      content,
      major: majors
        .sort((a, b) => (a.value < b.value ? -1 : a.value === b.value ? 0 : 1))
        .reduce((prev, item) => prev + item.value, ""),
      working_time: workingTime.value,
      wage_type: wageType.value ? wageType.value : null,
      [wageInTax]: wageType.value ? `${Number(wageValue) * 10000}` : null,
      hospital_name: hospitalName,
      address: address.selectedItem.address,
      detail_address: address.selectedItem.detail,
      call_number: callNumber,
      images: images.length > 0 ? images : [],
    };

    try {
      setLoading(true);
      if (!modify) {
        await api
          .post("/management/recruit/write", data)
          .then((res) => {
            navigate(`/recruit/doctor/${res.data.bid}`, { replace: true });
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      } else {
        await api
          .post(`/management/recruit/modify/${article_num}`, data)
          .then((res) => {
            navigate(`/recruit/doctor/${res.data.bid}`, { replace: true });
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    } catch (err) {
      alert(err.response.data.msg);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className={address.view ? "container overlay" : "container"}>
      <div className="wrapper">
        <section className="board__reg" style={{ marginTop: "54px", marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">구인공고</span>
              </div>
            </article>

            <div className="reg__top__box">
              <div className="row-list">
                <div className="row">
                  <div className="sbox" style={{ width: "300px", border: "none" }}>
                    <Select
                      value={majors}
                      onChange={(options) => {
                        setMajors(options);
                      }}
                      styles={selectStyles}
                      isMulti={true}
                      options={[
                        {
                          ...majorOptions[0],
                          label: majorOptions[0].label + " (+일반의)",
                        },
                        ...majorOptions.slice(1),
                      ]}
                      isSearchable={false}
                      placeholder="전공을 선택해주세요"
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                    />
                  </div>
                  <div className="sbox-job" style={{ width: "300px", border: "none" }}>
                    <Select
                      value={workingTime}
                      onChange={(option) => {
                        setWorkingTime(option);
                      }}
                      styles={selectStyles}
                      options={timeOptions}
                      isSearchable={false}
                      placeholder="근무형태를 선택해주세요"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">병원주소를 입력해주세요.</div>

                    <div className="addr-row-box">
                      <input
                        placeholder="우편번호"
                        value={address.selectedItem && address.selectedItem.postcd}
                        readOnly={true}
                      />
                      <button
                        className="post-btn"
                        onClick={() =>
                          setAddress({
                            ...address,
                            view: true,
                            load: false,
                            selectedItem: undefined,
                          })
                        }
                      >
                        우편번호
                      </button>
                    </div>

                    <div className="addr-row-box">
                      <input
                        style={{ width: "401px" }}
                        placeholder=""
                        value={address.selectedItem && address.selectedItem.address}
                        readOnly={true}
                      />
                      <input
                        style={{ width: "300px", marginLeft: "20px" }}
                        placeholder=""
                        value={address.selectedItem && address.selectedItem.detail}
                        onChange={(e) => {
                          setAddress({
                            selectedItem: {
                              ...address.selectedItem,
                              detail: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">병원이름을 입력해주세요.</div>
                    <div className="addr-row-box">
                      <input
                        style={{ width: "300px" }}
                        value={hospitalName}
                        onChange={(e) => setHospitalName(e.target.value)}
                        placeholder="병원이름을 입력해주세요"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">전화번호를 입력해주세요.</div>
                    <div className="addr-row-box">
                      <input
                        style={{ width: "300px" }}
                        value={callNumber}
                        onChange={(e) => setCallNumber(e.target.value)}
                        placeholder="전화번호를 입력해주세요"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">급여를 입력해주세요.</div>
                    <div className="addr-row-box salary-info">
                      <Select
                        value={wageType}
                        onChange={(option) => setWageType(option)}
                        styles={selectStyles}
                        options={salaryOptionsSelect}
                        isSearchable={false}
                      />
                      <input
                        className="ml10"
                        value={!wageType.value ? "" : Number(wageValue).toLocaleString("ko-KR")}
                        onChange={(e) => {
                          const value = e.target.value.replaceAll(",", "");
                          if (value.length > 5) return;
                          if (value && !value.match(/^\d+$/)) return;
                          setWageValue(value);
                        }}
                        placeholder=""
                        disabled={!wageType.value}
                      />
                      <div className="ml10">만원</div>
                      <div className="rbox ml30">
                        <label>
                          <input
                            type="radio"
                            name="tax"
                            value="gross_wage"
                            checked={wageInTax === "gross_wage"}
                            onChange={(e) => setWageInTax(e.target.value)}
                            disabled={!wageType.value}
                          />
                          <i></i>
                          <span className="txt">세전</span>
                        </label>
                      </div>
                      <div className="rbox ml30">
                        <label>
                          <input
                            type="radio"
                            name="tax"
                            value="net_wage"
                            checked={wageInTax === "net_wage"}
                            onChange={(e) => setWageInTax(e.target.value)}
                            disabled={!wageType.value}
                          />
                          <i></i>
                          <span className="txt">세후</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tit-box">
              <div className="tbox full">
                <input
                  className="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="제목을 입력해주세요."
                />
              </div>
            </div>
            <div className="reg__mid__box">
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="여기를 눌러 글을 작성할 수 있습니다."
              ></textarea>
            </div>
            <div className="reg__bottom__box">
              <AttachImages useImages={[images, setImages]} />
            </div>

            <div className="reg__menu__box">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/management/recruit`, { replace: true });
                }}
                className="menu-btn type1"
              >
                취소
              </button>
              <button disabled={loading} onClick={submit} className="menu-btn type2">
                등록
              </button>
            </div>
          </div>
        </section>
      </div>
      {address.view ? <AddressSelect state={address} setState={setAddress} /> : <></>}
    </div>
  );
}

export default DoctorWrite;
