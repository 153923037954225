import SideMenu from "../../component/mypage/SideMenu";
import { useEffect, useState } from "react";
import { api, Img, dttm, notiIcon } from "../../utils/util";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loading from "../../component/Loading";

const Alarm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [alarm, setAlarm] = useState({ noti: [], load: false });
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const unread =
    alarm &&
    alarm.load &&
    alarm.noti &&
    alarm.noti.filter((item) => item.noti_status === "received").length;

  const onAlarmClick = (nid) => {
    api.post(`/user/readNotification/${nid}`);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    navigate(`/mypage/alarm?${param}`);
  };

  useEffect(() => {
    api
      .get("/user/notification", {
        params: {
          page: page,
          per_page: 10,
        },
      })
      .then((res) => {
        api.post(`/user/readNotification`);
        setAlarm({ ...res.data, load: true });
        window.scrollTo(0, 0);
      });
  }, [page]);

  if (!alarm.load) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="alarm" />
            <div className="con__layout">
              <section className="my__home" style={{ marginBottom: 76.5 }}>
                <article className="section__tit__box">
                  <div className="tit-box">
                    <span className="tit-txt">알림</span>
                  </div>
                </article>
                <article className="section__tit__box2 border-type1" style={{ marginTop: 20.5 }}>
                  <div className="tit-box">
                    <span className="tit-txt">
                      <div>
                        읽지 않은 알림 <span className="purple">{unread > 9 ? "9+" : unread}</span>
                        개
                      </div>
                    </span>
                  </div>
                </article>
                {alarm.load && alarm.noti.length > 0 ? (
                  <article className="alarm__list">
                    <div className="list__wrap">
                      <ul>
                        {alarm.noti.map((item, idx) => {
                          return (
                            <Link
                              to={item.link}
                              onClick={() => onAlarmClick(item.nid)}
                              className="width-max"
                              key={idx}
                            >
                              <li className={item.noti_status === "received" ? "received" : ""}>
                                <div className="alarm_box items-center">
                                  <div className="w-6 h-6 rounded-full bg-secondary-200 flex justify-center items-center text-secondary-700 text-base">
                                    <i className={notiIcon(item.noti_type)}></i>
                                  </div>
                                  <span className="text">
                                    <span className="des">
                                      {JSON.parse(item.content).map((run, idx) => (
                                        <span key={idx} className={run.s}>
                                          {run.t}
                                        </span>
                                      ))}
                                    </span>
                                    <span className="time_check">{dttm(item.reg_dttm)}</span>
                                  </span>
                                </div>
                              </li>
                            </Link>
                          );
                        })}
                      </ul>
                    </div>
                    <article className="paging" style={{ paddingTop: "20px" }}>
                      <ReactPaginate
                        previousLabel="←"
                        previousLinkClassName="before"
                        nextLabel="→"
                        nextLinkClassName="next"
                        breakLabel="..."
                        pageCount={alarm.pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={4}
                        pageLinkClassName="num"
                        activeClassName=""
                        activeLinkClassName="current"
                        onPageChange={onPageClick}
                        forcePage={alarm.page - 1}
                      />
                    </article>
                  </article>
                ) : (
                  <div className="list__wrap">
                    <article className="no-alarm">
                      <img src={Img("", "img/no_alarm.svg")} alt="이미지" />
                      <div className="txt">알림이 없습니다.</div>
                    </article>
                  </div>
                )}
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Alarm;
