import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import Login from "./Login";
import Verify from "./Verify";
import CommunityAccessDenied from "component/market/CommunityAccessDenied";
import { isMarketOnlyUser } from "utils/marketUtil";

const RequestSignup = () => {
  const token = useRecoilValue(tokenState);

  // 승인받지 않은 커뮤니티 계정
  if (token && token.uid && !token.user_type) {
    return <Verify />;
  }
  // 마켓 전용 계정
  if (token && isMarketOnlyUser(token.user_type)) {
    return <CommunityAccessDenied />;
  }
  // 비로그인 계정
  return <Login />;
};

export default RequestSignup;
