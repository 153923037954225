import { wrappingInputRule } from "prosemirror-inputrules";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { Extension } from "../core/extension";
import { wrapInList } from "prosemirror-schema-list";

/**
 * Given a list node type, returns an input rule that turns a bullet
 * (dash, plush, or asterisk) at the start of a textblock into a
 * bullet list.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @returns
 */
const bulletListRule = (nodeType) => {
  return wrappingInputRule(/^\s*([-+*])\s$/, nodeType)
}

export const BulletList = Extension.Create({
  name: 'bullet_list',

  type: 'node',

  defineSpec() {
    return {
      group: 'block',
      content: 'list_item+',
      toDOM() {
        return ['ul', {class: MEDISTREAM_SCHEMA_STYLE.nodes.ul}, 0]
      },
      parseDOM: [{tag: 'ul'}],
    }
  },

  addCommands() {
    return {
      wrapInBulletList: wrapInList(this.schema.nodes[this.name]),
    }
  },

  addInputRules() {
    return [bulletListRule(this.schema.nodes[this.name])]
  }
})
