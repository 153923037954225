// atoms.ts
import { atom } from "recoil";
import { DrawerContentState, ModalContentState } from "types/common";

// ---------- swipeable Drawer 관련 로직 ----------
export const swipeableDrawerContent = atom<DrawerContentState>({
  key: "drawerContent",
  default: { component: null, componentProps: {}, drawerProps: {} },
});
export const isOpenedSwipeableDrawer = atom<boolean>({
  key: "isOpenedSwipeableDrawer",
  default: false,
});
export const isHiddenSwipeableDrawer = atom<boolean>({
  key: "isHiddenSwipeableDrawer",
  default: false,
});

// ---------- useModal 관련 로직 ----------
export const useModalContent = atom<ModalContentState>({
  key: "modalContent",
  default: { component: null, componentProps: {}, modalProps: {} },
});
export const isOpenedUseModal = atom<boolean>({
  key: "isOpenedUseModal",
  default: false,
});
