import { React } from "react";

function DetailBoardTitle({ menu, content, modifyFunc, setDeletePopup }) {
  const hasQnaComments = menu === "qna" && content.comments.length > 0;
  return (
    <article className="section__tit__box">
      <div className="tit-box">
        {menu === "qna" ? (
          <span className="tit-txt">{content.cate_name} 토픽</span>
        ) : (
          <span className="tit-txt">{content.cate_name} 게시판</span>
        )}

        {content.ismine && (
          <>
            <div className="menu-box">
              <div className="flex items-center justify-end">
                <div className="report-box">
                  <button
                    onClick={modifyFunc}
                    className="report-btn disabled:opacity-50 disabled:hover:!bg-[#f5f5f5]"
                    disabled={hasQnaComments}
                  >
                    수정
                  </button>
                </div>
                <div className="report-box">
                  <button
                    onClick={() => setDeletePopup(true)}
                    className="report-btn disabled:opacity-50 disabled:hover:!bg-[#f5f5f5]"
                    disabled={hasQnaComments}
                  >
                    삭제
                  </button>
                </div>
              </div>
              {hasQnaComments && (
                <div className="w-fit text-xs font-normal text-gray-400 mt-1">
                  *댓글이 작성된 게시글은 삭제 및 수정이 불가합니다.
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </article>
  );
}

export default DetailBoardTitle;
