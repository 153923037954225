import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import { Route, Routes } from "react-router-dom";
import SeminarPage from "./SeminarPage";
import SeminarDetailPage from "./SeminarDetailPage";
import SeminarWritePage from "./SeminarWritePage";
import SeminarWeeklyPage from "./SeminarWeeklyPage";
import SeminarWeekly from "./SeminarWeekly";

const Seminar = () => {
  return (
    <section className="container relative min-h-[100vh]">
      <Header current="seminar" />
      <article className="pb-64">
        <Routes>
          <Route path="/*" element={<SeminarPage />} />
          <Route path="/write/*" element={<SeminarWritePage />} />
          <Route path="/modify/:bid/*" element={<SeminarWritePage />} />
          <Route path="/:bid/*" element={<SeminarDetailPage />} />
        </Routes>
      </article>
      <Footer />
    </section>
  );
};

export default Seminar;

export const SeminarWeeklyRoute = () => {
  return (
    <section className="relative min-h-[100vh]">
      <Header current="seminar" />
      <article className="pb-64">
        <Routes>
          <Route path="/*" element={<SeminarWeekly />} />
          <Route path="/:article/*" element={<SeminarWeeklyPage />} />
        </Routes>
      </article>
      <Footer />
    </section>
  );
};
