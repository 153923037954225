import KlassPopup from "component/klass/KlassPopup";
import LectureVideo from "component/klass/LectureVideo";
import Loading from "component/Loading";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { Chapter, Course, File, Progress } from "types/klass";
import { cls, getEarlierDate, publicApi, totalTime } from "../../utils/util";
import CoursePopup from "./CoursePopup";

export type VodPlay = {
  popup: boolean;
  bookmark: number;
  lectureId: string;
};

type CurriculumProps = {
  progress?: Progress;
  course: Course;
  defaultCourseId?: string;
};

const Curriculum: React.FC<CurriculumProps> = ({ progress, course, defaultCourseId }) => {
  const token = useRecoilValue(tokenState);
  const [openBookmark, setOpenBookmark] = useState<string | undefined>("0");
  const [openLecture, setOpenLecture] = useState<string | undefined>("0");
  const [authPopup, setAuthPopup] = useState<string>("");
  const [vodPopup, setVodPopup] = useState<VodPlay>({ popup: false, bookmark: 0, lectureId: "" });
  const [coursePopup, setCouresPopup] = useState<string>("");
  const { id } = useParams();
  // const id = "01GR3J25T8VFC5K0SATGX8XJ5G";
  const isLogin = token && token.user_type;
  const courseFile = course?.file;
  const isForSale = dayjs(course?.sellStartAt) <= dayjs() && dayjs() < dayjs(course?.sellEndAt); // 판매중인지 확인
  const isFinished = dayjs().isAfter(dayjs(course.learnEndAt)); // 교육종료된 강의인지 확인
  const isExpired = progress && dayjs().isAfter(dayjs(progress?.expireAt)); // 수강종료된 강의인지 확인

  const clickPlayButton = (lecture: any, bookmark = 0) => {
    if (isFinished) {
      alert("종료된 강의입니다");
      return;
    }
    if (dayjs() < dayjs(course?.learnStartAt)) {
      alert(`${dayjs(course?.learnStartAt).format("MM월 DD일")}부터 수강 가능합니다`);
      return;
    }
    if (token?.user_status === "pending") {
      alert("인증 완료(치과의사 및 치과대학생)후 주문가능합니다. 인증완료 후 구매해 주세요");
      return;
    }
    if (progress && !isExpired) {
      setVodPopup({
        popup: true,
        bookmark: bookmark,
        lectureId: lecture.lectureId,
      });
      return;
    }
    if (!isForSale) {
      alert("판매 종료된 강의입니다.");
      return;
    }
    if (course?.isFree) {
      setCouresPopup(lecture.lectureId);
      return;
    }
    setAuthPopup(lecture.lectureId);
  };

  const { data: chapterList, isLoading: chapterListLoading } = useQuery<Chapter | undefined, Error>(
    ["classCoursesChapters", id],
    () =>
      publicApi
        ?.get(
          token && (token.user_type === "doctor" || token.user_type === "student")
            ? `/class/courses/${id}/chapters`
            : `/class/public/courses/${id}/chapters`,
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setOpenLecture(() => data?.chapters[0].chapterId);
        setOpenBookmark(() =>
          data?.chapters[0]?.lectures[0]?.bookmarks &&
          data?.chapters[0]?.lectures[0]?.bookmarks?.length > 0
            ? data?.chapters[0]?.lectures[0]?.lectureId
            : "",
        );
      },
    },
  );

  const { data: file, isLoading: fileLoading } = useQuery<File | undefined, Error>(
    ["classCoursesFile", id],
    () => publicApi?.get(`/class/courses/${id}/file`).then((res) => res.data.file),
    {
      enabled: !!(courseFile && isLogin),
      refetchOnWindowFocus: false,
    },
  );

  const downloadFile = () => {
    if (fileLoading) {
      alert("파일을 가져오는 중입니다.");
      return;
    }
    if (!file) {
      alert("수강하지 않은 강의입니다.");
      return;
    }
    const a = document.createElement("a");
    a.href = file.link;
    a.download = file.name;
    a.click();
    a.remove();
  };

  const lectureCount = () => {
    let count = 0;
    chapterList?.chapters.map((item) => (count += item.lectures.length));
    return count;
  };

  if (chapterListLoading || !chapterList) return <Loading small />;

  return (
    <>
      <div className="mt-[29px] ">
        <div className="flex items-center space-x-2 mb-[15px] px-4 lg:px-0">
          <div className="text-gray-900 text-[22px] font-bold">커리큘럼</div>
          <div className="text-base text-gray-600 font-medium">
            <span className="text-primary-800 font-semibold mr-2">총 {lectureCount()}강</span>총{" "}
            {totalTime(chapterList.totalVideoLength)}
          </div>
        </div>

        {courseFile && (
          <div className="flex justify-between bg-primary-50 p-[16px] rounded-md mb-[20px] mx-4 lg:mx-0 items-center">
            <div className="flex items-start">
              <i className="ii ii-book-outline ii-weight-600 text-[20px] text-primary-600 mr-[6px] flex-shrink-0 leading-[20px]"></i>
              <span className="text-[15px] text-primary-600 font-semibold mr-[10px] flex-shrink-0 self-start leading-[20px]">
                강의 자료
              </span>
              <span
                className="block mr-3 break-all leading-[20px] hover:underline cursor-pointer"
                onClick={downloadFile}
              >
                {courseFile.name}
              </span>
            </div>
            <button
              className="flex text-[13px] font-bold text-white bg-secondary-900 hover:bg-secondary-800 rounded-md w-[74px] h-8 flex-shrink-0 justify-center items-center"
              onClick={downloadFile}
            >
              <span>다운로드</span>
              <i className="ii ii-download-outline text-white ml-1"></i>
            </button>
          </div>
        )}

        <div className="space-y-5">
          {chapterList.chapters?.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <div
                  className="px-4 lg:px-0"
                  key={item.chapterId}
                  onClick={() =>
                    setOpenLecture((prev) => (prev === item.chapterId ? "0" : item.chapterId))
                  }
                >
                  <div className="bg-secondary-100 cursor-pointer flex items-center rounded-md px-3 py-3 hover:bg-secondary-200">
                    <div className="text-sm font-medium text-gray-900 mr-2.5">
                      Chapter.{idx + 1 >= 10 ? idx + 1 : "0" + (idx + 1)}
                    </div>
                    <div className="flex items-center">
                      <h4 className="text-gray-900 font-semibold text-lg">{item.title}</h4>
                      <div className="text-gray-600 text-sm mx-2 font-medium shrink-0">
                        총 {totalTime(item.totalVideoLength)}
                      </div>
                    </div>
                    <i
                      className={cls(
                        openLecture === item.chapterId ? "ii-chevron-up" : "ii-chevron-down",
                        "mr-0 ml-auto",
                      )}
                    ></i>
                  </div>
                </div>

                {openLecture === item.chapterId && (
                  <div>
                    <div className="border-t-2 border-solid border-primary-800">
                      <div className="hidden lg:flex bg-secondary-100 text-gray-900 text-base font-medium px-3.5 h-[56px] items-center justify-evenly">
                        <div className="w-[366px]">강의 내용</div>
                        <div className="w-[128px] flex justify-center">주요부분</div>
                        <div className="w-[86px] flex justify-center">강의 시간</div>
                        <div className="w-[86px] flex justify-center">수강하기</div>
                      </div>
                      {item.lectures.map((lecture) => {
                        return (
                          <div className="pt-4" key={lecture.lectureId}>
                            {progress &&
                              vodPopup.popup &&
                              vodPopup.lectureId === lecture.lectureId && (
                                <LectureVideo
                                  courseId={id as string}
                                  chapterId={item.chapterId}
                                  lectureId={vodPopup.lectureId}
                                  setPopup={setVodPopup}
                                  bookmark={vodPopup.bookmark}
                                  progressPercent={progress?.progressPct}
                                />
                              )}
                            {!progress && authPopup === lecture.lectureId && (
                              <KlassPopup
                                setPopup={setAuthPopup}
                                course={course}
                                defaultCourseId={defaultCourseId}
                              />
                            )}

                            {coursePopup === lecture.lectureId && (
                              <CoursePopup title={course?.title} setPopup={setCouresPopup} />
                            )}

                            <div className="lg:flex">
                              <div className="px-4 lg:w-[380px]">
                                <h5 className="text-gray-900 font-medium text-base">
                                  {lecture.title}
                                </h5>
                              </div>
                              <div className="flex lg:w-[300px] items-center justify-between mt-5 lg:mt-0 pb-4 lg:pb-0">
                                {lecture.bookmarks && lecture.bookmarks.length > 0 ? (
                                  <button
                                    className={cls(
                                      openBookmark === lecture.lectureId
                                        ? "text-primary-800 bg-primary-50 border-[#DED2FF] hover:bg-primary-100"
                                        : "text-gray-900 border-gray-300 hover:bg-secondary-50",
                                      "mx-4 border-solid lg:w-[128px] border-[1px] rounded-md text-xs font-semibold flex items-center justify-center w-[95px] h-[30px] space-x-1",
                                    )}
                                    onClick={() =>
                                      setOpenBookmark((prev) =>
                                        prev === lecture.lectureId ? "0" : lecture.lectureId,
                                      )
                                    }
                                  >
                                    <div>주요부분 {lecture.bookmarks.length}개</div>
                                    <i
                                      className={cls(
                                        openBookmark === lecture.lectureId
                                          ? "ii-chevron-up"
                                          : "ii-chevron-down",
                                      )}
                                    ></i>
                                  </button>
                                ) : (
                                  <div className="w-[128px]"></div>
                                )}

                                <div className="flex items-center space-x-4 lg:space-x-0 lg:w-[172px]">
                                  <div className="text-gray-900 text-sm font-medium lg:w-[86px] lg:flex lg:justify-center">
                                    {totalTime(lecture.totalVideoLength)}
                                  </div>
                                  <div
                                    className="cursor-pointer w-[34px] lg:w-[86px] h-[34px] border-[1px] border-solid border-gray-300 lg:border-none rounded-full flex items-center justify-center"
                                    onClick={() => clickPlayButton(lecture)}
                                  >
                                    <div
                                      className={cls(
                                        progress &&
                                          dayjs(
                                            getEarlierDate(progress.expireAt, course.learnEndAt),
                                          ).diff(dayjs(new Date())) > 0
                                          ? "text-primary-800 bg-primary-50 font-medium hover:bg-primary-100"
                                          : "text-gray-300 bg-secondary-100",
                                        "relative text-[13px] rounded-[4px] h-[33px] flex items-center justify-center w-[72px]",
                                      )}
                                    >
                                      수강하기
                                      <i
                                        className={cls(
                                          progress &&
                                            dayjs(
                                              getEarlierDate(progress.expireAt, course.learnEndAt),
                                            ).diff(dayjs(new Date())) > 0
                                            ? "text-primary-800"
                                            : "text-gray-300",
                                          "ii-play ml-1 lg:ml-0 text-[13px]",
                                        )}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative mt-6">
                              {openBookmark === lecture.lectureId && lecture.bookmarks ? (
                                <div className="bg-primary-50 box-border w-full flex pl-10 pr-5 py-4 lg:rounded-md">
                                  <div className="w-[3px] left-5 h-calc-full-32 flex bg-primary-500 rounded-full absolute"></div>
                                  <div className="flex flex-col mt-4 gap-y-5 w-full items-start">
                                    {lecture.bookmarks.map((bookmark) => {
                                      return (
                                        <div className="relative w-full">
                                          <div className="w-4 h-4 bg-primary-800 rounded-full absolute -left-[26px] top-1 border-4 border-solid border-primary-500" />
                                          <div
                                            className="text-sm hover:underline text-gray-900 font-medium cursor-pointer"
                                            onClick={() => clickPlayButton(lecture, bookmark.time)}
                                          >
                                            <div className="truncate">
                                              {totalTime(bookmark.time)}부터
                                            </div>
                                            <div className="line-clamp-2">
                                              {bookmark.description}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <div className="w-full h-[2px] bg-gray-200 lg:mt-4" />
                              )}
                            </div>
                          </div>
                        );
                      })}

                      <div></div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Curriculum;
