import { Link } from "react-router-dom";
import { cls } from "utils/util";

const ContentsRanking = ({ latest_article }) => {
  return (
    <div className="hot_curation bg-gray-50 rounded-md py-3 px-5">
      <div className="text-xl tracking-tighter font-semibold whitespace-nowrap mb-3">
        인기 콘텐츠 🔥
      </div>
      <div className="">
        {latest_article &&
          latest_article.slice(0, 6).map((article, index) => (
            <Link
              to={`/contents/article/${article.bid}`}
              className="flex items-center py-1 group"
              key={index}
            >
              <span
                className={cls(
                  index < 3 ? "!text-primary-800" : "!text-black",
                  "text-2xl font-bold mr-2 w-4",
                )}
              >
                {index + 1}
              </span>
              <span className="text-base font-medium truncate group-hover:text-primary-800">
                {article.title}
              </span>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default ContentsRanking;
