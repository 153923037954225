import { toggleMark } from "prosemirror-commands";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

export const Em = Extension.Create({
  name: 'em',

  type: 'mark',

  defineSpec() {
    return {
      toDOM() {
        return ['em', {class: MEDISTREAM_SCHEMA_STYLE.marks.em}, 0]
      },
      parseDOM: [
        {tag: 'i'},
        {tag: 'em'},
        {style: 'font-style=italic'},
        {style: 'font-style=normal', clearMark: m => m.type.name == 'em'},
      ],
    }
  },

  addCommands() {
    return {
      toggleEm: (state, dispatch) => toggleMark(state.schema.marks[this.name])(state, dispatch),
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-I': this.editor.commands.toggleEm,
      'Mod-i': this.editor.commands.toggleEm,
    }
  }
})
