import { Routes, Route } from "react-router";
import ContentsPage from "./ContentsPage";
import ContentsWrite from "./ContentsWrite";
import ContentsDetail from "./ContentsDetail";
import ContentsSearchPage from "./ContentsSearchPage";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";

const Contents = () => {
  return (
    <section className="relative min-h-[100vh]">
      <Header current="contents" />
      <article className="py-10 pb-64">
        <Routes>
          <Route path="article/:bid/*" element={<ContentsDetail />} />
          <Route path="write/*" element={<ContentsWrite />} />
          <Route path="modify/:bid/*" element={<ContentsWrite />} />
          <Route path="search/*" element={<ContentsSearchPage />} />
          <Route path="*" element={<ContentsPage />} />
        </Routes>
      </article>
      <Footer />
    </section>
  );
};

export default Contents;
