import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import Loading from "../../component/Loading";
import { api } from "../../utils/util";
import ForumDetail from "./ForumDetail";
import ForumPage from "./ForumPage";
import ForumWrite from "./ForumWrite";

const Forum = () => {
  const { isLoading: topicListLoading } = useQuery(
    "forumCateCd",
    () => api.get("/forum/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        err.response
          ? alert(err.response.data.msg)
          : alert("임상포럼 카테고리를 받아오는 데 실패하였습니다");
      },
    },
  );

  if (topicListLoading) return <Loading />;
  return (
    <section className="relative min-h-[100vh]">
      <Header current="forum" />
      <div className="container pt-10 pb-64">
        <Routes>
          <Route path="/article/:bid/*" element={<ForumDetail />} />
          <Route path="/write/:cate_cd/*" element={<ForumWrite />} />
          <Route path="/modify/:bid/*" element={<ForumWrite />} />
          <Route path="/*" element={<ForumPage />} />
        </Routes>
      </div>
      <Footer />
    </section>
  );
};

export default Forum;
