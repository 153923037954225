const LikeButton = ({ likeCount, liked, likeClicked }) => {
  return (
    <div className="menu-box-list like">
      <button
        className={liked ? "like-box on" : "like-box"}
        onClick={() => likeClicked(null, liked)}
      >
        <div className={liked ? "like-btn-n on" : "like-btn-n"}></div>
        <span className="menu-txt">추천</span>
        <span className="count_n">{likeCount}</span>
      </button>
    </div>
  );
};

export default LikeButton;
