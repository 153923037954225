import React, { useRef, useState } from "react";
import { default as Select } from "react-select";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { api, scrollTopList, selectStyles } from "../../utils/util";
import Loading from "../Loading";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import ReactPaginate from "react-paginate";
import QnaListTable from "./QnaListTable";

const QnaListUI = ({ current, pageInfo, detailTab }) => {
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();
  const location = useLocation();
  const bid = parseInt(useParams().bid || 0);
  const tab = useParams().tab || detailTab;
  const urlParam = new URLSearchParams(location.search);
  const sort = urlParam.get("sort") || "";
  const q = urlParam.get("q") || "";
  const page = urlParam.has("page") ? parseInt(urlParam.get("page")) : 1;
  const cate_cd = parseInt(urlParam.get("topic")) || 0;
  const [searchKeyword, setSearchKeyword] = useState(q);
  const sortOptions = [
    { label: "최신순", value: "" },
    { label: "추천순", value: "like" },
    { label: "조회순", value: "view" },
  ];
  const qnaScroll = useRef();

  const { data: topicList, isLoading: topicListLoading } = useQuery(
    "qnaCateCd",
    () => api.get("/qna/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        err?.response
          ? alert(err.response.data.msg)
          : alert("Q&A 토픽을 받아오는 데 실패하였습니다");
      },
    },
  );

  const [topic, setTopic] = useState(
    !cate_cd
      ? null
      : topicList
          ?.map((item) => ({ label: item.cate_name, value: item.cate_cd }))
          ?.find((item) => item.value === cate_cd),
  );

  const search = (e) => {
    e.preventDefault();
    urlParam.set("page", 1);
    urlParam.set("topic", cate_cd);
    urlParam.set("q", searchKeyword);
    current === "qna_page"
      ? navigate(`/qna/${tab}?${urlParam}`)
      : navigate(`/qna/article/${bid}?${urlParam}`);
    current === "qna_detail" ? scrollTopList(qnaScroll) : window.scrollTo(0, 0);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    urlParam.set("page", selected);
    current === "qna_page"
      ? navigate(`/qna/${tab}?${urlParam}`)
      : navigate(`/qna/article/${bid}?${urlParam}`);
    scrollTopList(qnaScroll);
  };

  return (
    <>
      <article className="list__table2" ref={qnaScroll}>
        <div className="table__menu flex justify-between">
          <div className="flex">
            <button className="table-menu-btn type1">전체글</button>
            <div className="w-[250px] h-[40px] ml-3 text-[15px] text-start">
              <Select
                value={topic}
                onChange={(option) => {
                  setTopic(option);
                  urlParam.set("topic", option.value);
                  urlParam.set("page", 1);
                  sort && urlParam.set("sort", sort);
                  navigate(`/qna/${tab}?${urlParam}`);
                }}
                styles={selectStyles}
                options={topicList
                  ?.filter((item) => item.cate_forum === tab)
                  // topic 정렬 (cate_certi가 true일 때 우선 정렬, 그리고 cate_cd 오름차순)
                  ?.sort((a, b) =>
                    a.cate_certi !== b.cate_certi
                      ? b.cate_certi - a.cate_certi
                      : a.cate_cd - b.cate_cd,
                  )
                  ?.map((item) => ({ label: item.cate_name, value: item.cate_cd }))}
                isSearchable={false}
                placeholder="토픽 선택"
              />
            </div>
          </div>
          <div className="flex gap-3">
            <Select
              className="w-[100px] text-[15px]"
              value={sortOptions.filter((option) => option.value === sort)[0]}
              onChange={(option) => {
                urlParam.set("page", 1);
                urlParam.set("topic", cate_cd);
                urlParam.set("sort", option.value);
                navigate(`/qna/${tab}?${urlParam}`);
              }}
              styles={selectStyles}
              options={sortOptions}
              isSearchable={false}
              placeholder="정렬"
            />
            <Link
              to={`/qna/write/${tab}/${cate_cd}`}
              className="bg-black text-white p-2 px-3 rounded text-[13px] flex items-center gap-1 h-[37px]"
            >
              <i className="ii ii-pen-outline text-white text-lg"></i>
              글쓰기
            </Link>
          </div>
        </div>
        {topicListLoading ? (
          <Loading />
        ) : (
          <>
            <QnaListTable articles={pageInfo.qna} bid={bid} />
            <article className="paging">
              <ReactPaginate
                previousLabel="←"
                previousLinkClassName="before"
                nextLabel="→"
                nextLinkClassName="next"
                breakLabel="..."
                pageCount={pageInfo.pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                pageLinkClassName="num"
                activeClassName=""
                activeLinkClassName="current"
                onPageChange={onPageClick}
                forcePage={page - 1}
              />
              {token.user_type === tab && (
                <Link to={`/qna/write/${tab}/${cate_cd}`} className="write-btn bottom">
                  <i className="ic ic-write"></i>
                  <span className="txt">글쓰기</span>
                </Link>
              )}
            </article>
          </>
        )}
      </article>
      <article className="list__search__form" style={{ marginTop: "47px" }}>
        <form className="search__form__box">
          <input
            type="search"
            placeholder="검색어를 입력해주세요."
            onChange={(e) => setSearchKeyword(e.target.value)}
            value={searchKeyword}
          />
          <button className="search-btn center" onClick={(e) => search(e)}></button>
        </form>
      </article>
    </>
  );
};

export default QnaListUI;
