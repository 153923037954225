const TextFormats = [
  ["제목", "heading", "1.47em"],
  ["본문", "paragraph", "reset-style"],
];

const ProsemirroMenuTextFormat = ({
  Open,
  Context,
  menubarClassName,
  cls,
  dispatchMetaData,
}) => {
  const handleClick = (e) => {
    const textFormat = e.target.dataset.textformat;

    if (Context === "textFormat") {
      dispatchMetaData({ type: "setTextFormat", textFormat });
    }
  };
  return (
    <div>
      <ul
        className={cls(
          menubarClassName + "__textFormat-box",
          Open ? "!grid" : "hidden"
        )}
      >
        {TextFormats.map((textFormat, index) => (
          <li
            key={index}
            className={menubarClassName + "__textFormat-box__textFormat"}
            style={{ fontSize: textFormat[2] }}
            onClick={handleClick}
            title={textFormat[0]}
            data-textformat={textFormat[1]}
          >
            {textFormat[0]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProsemirroMenuTextFormat;
