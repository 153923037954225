import { useState, useEffect } from "react";
import { api } from "../../utils/util";
import AddressSelect from "../../page/management/AddressSelect";
import FileUpload from "./FileUpload";
import { hospitalOptions } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Loading from "../Loading";

const ChicruitPopup = ({ setPopup }) => {
  const navigate = useNavigate();
  const { data: mypageHospitalsState, isLoading: mypageHospitalsLoading } = useQuery(
    "mypageHospitals",
    () => api.get("/mypage/hospitals").then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  useEffect(() => {
    if (mypageHospitalsState) return navigate("/recruit/chicruiting/register");
  }, [mypageHospitalsState, navigate]);

  if (mypageHospitalsLoading) return <Loading />;

  return (
    <>
      <HospitalInfoPopup isChicruitingRegister setPopup={setPopup} />
    </>
  );
};

export default ChicruitPopup;

export const HospitalInfoPopup = ({ isChicruitingRegister, setPopup, setDonePopup }) => {
  const [name, setName] = useState("");
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState("");
  const [files, setFiles] = useState([]);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState({
    selectedItem: {
      address: "",
      detail: "",
    },
  });
  const [owner, setOwner] = useState("");
  const [type, setType] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [healthInsuranceIdentificationNumber, setHealthInsuranceIdentificationNumber] =
    useState("");
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  var isEmail = (text) =>
    text.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i);
  const isBusinessNumber = (text) => text.match(/^\d{10}$/);
  const isHealthNumber = (text) => text.match(/^\d{8}$/);
  const queryClient = useQueryClient();

  const submit = async () => {
    if (!name.length) {
      setErrorMsg("병원명을 입력해주세요");
      return;
    }

    if (!type.length) {
      setErrorMsg("의료기관 종류를 선택해주세요");
      return;
    }

    if (!isBusinessNumber(businessRegistrationNumber)) {
      setErrorMsg("올바른 사업자 등록번호를 입력해주세요. (숫자만 입력, 10자리)");
      return;
    }

    if (!files.length) {
      setErrorMsg("사업자등록증을 첨부해주세요");
      return;
    }

    if (!address.selectedItem.address) {
      setErrorMsg("주소를 검색해주세요");
      return;
    }

    if (!address.selectedItem.detail) {
      setErrorMsg("상세주소를 입력해주세요");
      return;
    }

    if (!owner.length) {
      setErrorMsg("대표자명을 입력해주세요");
      return;
    }

    if (!isHealthNumber(healthInsuranceIdentificationNumber)) {
      setErrorMsg("올바른 요양기관번호를 입력해주세요. (숫자만 입력, 8자리)");
      return;
    }

    if (!isEmail(email)) {
      setErrorMsg("올바른 이메일 주소를 입력해주세요");
      return;
    }

    const data = {
      name: name,
      type: type,
      email: email,
      businessRegistrationNumber: businessRegistrationNumber,
      address: address.selectedItem.address,
      detailAddress: address.selectedItem.detail,
      owner: owner,
      healthInsuranceIdentificationNumber: healthInsuranceIdentificationNumber,
      businessRegistrationCertificateImagePath: files[0],
    };

    try {
      setIsFetching(true);
      await api
        .post("/hospitals", data)
        .then((res) => {
          setDonePopup && setDonePopup(true);
          setPopup(false);
          queryClient.invalidateQueries("mypageHospitals");
          isChicruitingRegister && navigate("/recruit/chicruiting/register");
        })
        .catch((err) => {
          setErrorMsg(err.response.data.msg);
        });
    } catch (err) {
      alert(err.response.data.msg);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <div className="popup-overlay dark" onClick={() => setPopup(false)}></div>
      <article className="popup chicruiting" style={{ display: "block" }}>
        <div className="tit">개원의 사업자정보 입력하기</div>
        <div className="sub-tit">
          병원정보 악용 방지 및 귀원의 비용 처리를 위한
          <br />
          세금계산서 증빙 발행을 위해 병원 등록을 해주셔야 합니다. 🙌
        </div>
        <div className="info" style={{ marginTop: 0 }}>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="상호(병원명)를 입력해주세요."
            required
          ></input>
          <select onChange={(e) => setType(e.target.value)} required>
            <option value="" disabled selected>
              의료기관 종류를 선택해주세요.
            </option>
            {hospitalOptions.map((item) => {
              return (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
          <input
            type="text"
            value={businessRegistrationNumber}
            onChange={(e) => setBusinessRegistrationNumber(e.target.value)}
            placeholder="사업자 등록번호를 입력해주세요. (숫자만 입력, 10자리)"
            maxLength={10}
          ></input>
          <section className="file_box">
            <div className="bottom">
              <FileUpload useFiles={[files, setFiles]} />
            </div>
          </section>
          <div className="flex center">
            <input
              placeholder="주소를 검색해주세요."
              value={address.selectedItem && address.selectedItem.address}
              style={{ width: 380 }}
              readOnly
            ></input>
            <button
              className="post-btn"
              onClick={() =>
                setAddress({
                  ...address,
                  view: true,
                  load: false,
                  selectedItem: undefined,
                })
              }
            >
              검색
            </button>
          </div>
          <input
            value={address.selectedItem && address.selectedItem.detail}
            onChange={(e) =>
              setAddress({
                ...address,
                selectedItem: {
                  ...address.selectedItem,
                  detail: e.target.value,
                },
              })
            }
            placeholder="상세주소를 입력해주세요."
          ></input>
          <input
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
            placeholder="대표자명을 입력해주세요."
          ></input>
          <input
            value={healthInsuranceIdentificationNumber}
            onChange={(e) => setHealthInsuranceIdentificationNumber(e.target.value)}
            placeholder="요양기관번호를 입력해주세요. (숫자만 입력, 8자리)"
          ></input>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="추후 세금계산서를 발행받을 수 있는 이메일 주소를 입력해주세요."
          ></input>
        </div>
        {errorMsg && <div className="err-msg">{errorMsg}</div>}
        <button className="done" onClick={submit} disabled={isFetching}>
          {isChicruitingRegister ? "맞춤 인재 조건 입력하러 가기" : "완료"}
        </button>
        {address.view ? <AddressSelect state={address} setState={setAddress} /> : <></>}
      </article>
    </>
  );
};
