import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Login = () => {
  const { state } = useLocation();
  const param = new URLSearchParams(location && location.search);
  const pathname = param.get("pathname"); // MARK: 로그인 페이지에서 파라미터로 redirect 시키는 경우에 사용

  useEffect(() => {
    const redirectUrl = new URL(`${window.location.origin}/auth`);
    if (window.location.pathname !== "/login") {
      const path = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      redirectUrl.searchParams.append("pathname", path);
    }
    if (window.location.pathname === "/login" && pathname) {
      redirectUrl.searchParams.append("pathname", pathname);
    }
    if (state && state.syncType) {
      redirectUrl.searchParams.append("syncType", state.syncType);
    }

    const url = new URL(`${import.meta.env.VITE_APP_AUTH_HOST}/login`);
    url.searchParams.append("client_id", import.meta.env.VITE_APP_AUTH_CLIENT_ID);
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", redirectUrl.href);

    window.location.replace(url.href);
  }, [pathname, state]);

  return <></>;
};

export default Login;
