import { api, Img } from "../../utils/util";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { Dispatch, SetStateAction } from "react";
import Badges from "component/user/badges";

const AuthUserBox = ({ setLogoutPopup }: { setLogoutPopup: Dispatch<SetStateAction<boolean>> }) => {
  const { data: userInfoState, isLoading: userInfoStateLoading } = useQuery("userInfo", () =>
    api?.get("user/info").then((res) => res.data),
  );

  if (userInfoStateLoading || !userInfoState) {
    return (
      <div className="flexCenter center" style={{ alignItems: "center", height: "100%" }}>
        <PuffLoader color="#4a25aa" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-between h-full pt-5 text-[15px]">
      <div className="flex items-center">
        <div className="relative">
          <img className="w-[74px] h-[74px]" src={Img("", `img/profile_rank.svg`)} alt="" />
          {userInfoState.userLevel && (
            <div className="text-white w-[20px] h-[20px] text-center absolute top-[16px] right-[13px] text-[11px]">
              {userInfoState.userLevel}
            </div>
          )}
        </div>
        <div className="flex flex-col ml-4">
          <div className="flex mb-2">
            <div className="ellipsis max-w-[100px] text-[17px] font-bold">
              {userInfoState.nickname && userInfoState.nickname}
            </div>
            <span className="text-primary-800 bg-primary-100 px-2 rounded-sm mx-2 text-[16px] font-semibold">
              {userInfoState.userLevel && `Lv.${userInfoState.userLevel}`}
            </span>
            <Badges isOwner={userInfoState.isOwner} uid={userInfoState.uid} />
          </div>
          <div className="text-[15px]">
            등급 포인트 <b>{userInfoState.totalPoint}점</b>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 grid grid-cols-2 w-full text-[15px]">
        <button
          className="text-center font-medium hover:bg-gray-100 py-2"
          onClick={() => setLogoutPopup(true)}
        >
          로그아웃
        </button>
        <Link
          className="mypage text-center hover:bg-gray-100 font-medium text-primary-800 py-2"
          to="/mypage"
        >
          <span className="color-on">마이페이지</span>
        </Link>
      </div>
    </div>
  );
};

export default AuthUserBox;
