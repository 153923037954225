import React, { useState, useRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { api, commentDttm, htmlTextContent, Img } from "../../utils/util";
import CommentTrashPopup from "../board/CommentTrashPopup";
import TextareaAutosize from "react-textarea-autosize";
import ClassCommentTextArea from "./ClassCommentTextArea";
import { useLocation } from "react-router-dom";
import { scrollTopList } from "../../utils/util";
import Nickname from "../user/nickname";

function ClassComment({ current, content, article_num, setContent }) {
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const [newComment, setNewComment] = useState({
    uuid: null,
    cid: null,
    content: null,

    color: { toggle: false, index: null },
  });
  const [newReply, setNewReply] = useState({
    uuid: null,
    cid: null,
    content: null,

    color: { toggle: false, index: null },
  });

  const [deleteUUID, setDeleteUUID] = useState(false);

  const modifyComment = (item) => {
    setNewReply({
      ...item,
      image: item.img_link,
      color: { toggle: true, index: item.uuid },
    });
  };

  const deleteComment = async (uuid) => {
    setDeleteUUID(false);
    await api.delete(`/${current}/comment/delete/${uuid}`);
    api.get(`/${current}/article/${article_num}`).then((res) => {
      setContent({
        load: true,
        ...res.data,
      });
    });
  };

  const submit = async (comment, setComment) => {
    if (!comment.content && !comment.image) {
      alert("댓글내용이 존재하지 않습니다");
      return;
    }
    const data = new FormData();
    data.append("cid", comment.cid ?? null);
    data.append("content", comment.content ? comment.content : "");

    if (!comment.uuid) {
      await api.post(`/class/${current}/write/comment/${article_num}`, data);
    } else {
      await api.post(`/class/${current}/comment/modify/${comment.uuid}`, data);
    }
    setComment({
      uuid: null,
      cid: null,
      content: null,
      color: { toggle: false, index: null },
    });
    // TODO SetState
    api.get(`class/${current}/${article_num}`).then((res) => {
      setContent({
        load: true,
        ...res.data,
      });
    });
  };

  const param = new URLSearchParams(location?.search);
  const highlightedCid = parseInt(param.get("cid"));
  const commentBoxRef = useRef(null);

  useEffect(() => {
    commentBoxRef.current && scrollTopList(commentBoxRef);
  }, [highlightedCid]);

  return (
    <article className="comment__form">
      <div className="comment__reg__info">
        <span className="info">
          댓글
          <span className="purple">{content.comments && content.comments.length}</span>
        </span>
      </div>
      <div className="comment__group">
        <div className="list__wrap">
          <div className="comment__list">
            {/* <!-- 댓글 --> */}
            {content.comments &&
              content.comments
                .filter((item) => !item.cid)
                .sort((a, b) => {
                  if (a.admin_selected_at && a.writer_selected_at) return -1;
                  if (b.admin_selected_at && b.writer_selected_at) return 1;
                  if (a.admin_selected_at) return -1;
                  if (b.admin_selected_at) return 1;
                  if (a.writer_selected_at) return -1;
                  if (b.writer_selected_at) return 1;
                  return a.uuid - b.uuid;
                })
                .map((item) => {
                  return (
                    <div key={item.uuid} className="comment-item">
                      {newReply.uuid && newReply.uuid === item.uuid ? (
                        <article className="comment__form">
                          <div className="comment__reg__form modify">
                            <div className="reg-input-box">
                              <TextareaAutosize
                                onChange={(e) =>
                                  setNewReply({
                                    ...newReply,
                                    content: e.target.value,
                                  })
                                }
                                value={newReply.content || ""}
                                minRows={3}
                              ></TextareaAutosize>
                              <div className="reg-menu">
                                <div className="right-menu-box">
                                  <div className="reg-btn-box">
                                    <button
                                      onClick={() => submit(newReply, setNewReply)}
                                      className="reg-btn"
                                    >
                                      {newReply.uuid ? "수정" : "등록"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      ) : (
                        <div
                          style={
                            item && item.uuid === highlightedCid
                              ? {
                                  boxShadow: "0px 0px 1px #00000006, 0px 1px 3px #00000024",
                                }
                              : { boxShadow: "none" }
                          }
                        >
                          <div
                            className="comment-box"
                            style={
                              newComment.color &&
                              newComment.color.index === item.uuid &&
                              newComment.color.toggle
                                ? { backgroundColor: "#F5F4FF" }
                                : item.admin_selected_at
                                ? { backgroundColor: "#f5f4ff" }
                                : item.writer_selected_at
                                ? { backgroundColor: "#f4ffff" }
                                : {}
                            }
                            ref={item.uuid === highlightedCid ? commentBoxRef : null}
                          >
                            <div className="info-box">
                              {!item.deleted && (
                                <span className={item.iswriter ? "txt accent" : "txt"}>
                                  <Nickname uid={item.uid} unick={item.unick} level={item.level} />
                                </span>
                              )}
                            </div>

                            <div className="container__content-btn">
                              <div className="container__content">
                                {item.admin_selected_at && (
                                  <img
                                    src={`${
                                      import.meta.env.VITE_APP_PUBLIC_URL
                                    }img/qna_admin_pick_comment.png`}
                                    alt="관리자PICK"
                                  />
                                )}
                                {item.writer_selected_at && (
                                  <img
                                    src={`${
                                      import.meta.env.VITE_APP_PUBLIC_URL
                                    }img/qna_writer_pick_comment.png`}
                                    alt="질문자PICK"
                                  />
                                )}
                                <div className="txt-box">
                                  {item.reported
                                    ? "다수의 신고로 인해 블라인드 처리되었습니다"
                                    : htmlTextContent(item.content)}
                                </div>
                                <span className="txt">{commentDttm(item.reg_dttm)}</span>
                              </div>
                              {item.img_link && !item.reported && (
                                <div className="img-box">
                                  <img src={Img(item.img_link)} alt="이미지" />
                                </div>
                              )}
                              {!item.deleted && (
                                <div className="menu-box">
                                  <button
                                    onClick={() =>
                                      setNewReply({
                                        cid: item.uuid,
                                        content: null,
                                        image: null,
                                        color: {
                                          toggle: true,
                                          index: item.uuid,
                                        },
                                      })
                                    }
                                    className="comment-btn"
                                  >
                                    <i className="ii ii-message-line-outline"></i>
                                    <div className="comment">대댓글</div>
                                  </button>
                                  {(token.uid === item.uid || item.ismine) && (
                                    <div>
                                      <button
                                        className="report-btn"
                                        onClick={() => modifyComment(item)}
                                      >
                                        수정
                                      </button>
                                      <button
                                        className="report-btn"
                                        onClick={() => setDeleteUUID(item.uuid)}
                                      >
                                        삭제
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* 대댓글 버튼을 누르면, 해당 댓글 바로 하단에 textarea 생김 */}
                      {!newReply.uuid && newReply.cid && newReply.cid === item.uuid && (
                        <article className="comment__form">
                          <div className="comment__reg__form reply">
                            <div className="reg-input-box">
                              <span className="reply__user">@{item.unick}</span>
                              <TextareaAutosize
                                onChange={(e) =>
                                  setNewReply({
                                    ...newReply,
                                    content: e.target.value,
                                  })
                                }
                                value={newReply.content || ""}
                                minRows={3}
                              ></TextareaAutosize>
                              <div className="reg-menu">
                                <div className="right-menu-box">
                                  <div className="reg-btn-box">
                                    <button
                                      onClick={() => submit(newReply, setNewReply)}
                                      className="reg-btn"
                                    >
                                      {newReply.uuid ? "수정" : "등록"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      )}

                      {/* <!-- 대댓글 --> */}
                      {content.comments
                        .filter((reply) => reply.cid === item.uuid)
                        .sort((a, b) => {
                          return a.reg_dttm - b.reg_dttm;
                        })
                        .map((reply, i) => {
                          return (
                            <div
                              key={reply.uuid}
                              className="comment-reply-list"
                              style={
                                reply && reply.uuid === highlightedCid
                                  ? {
                                      boxShadow: "0px 0px 1px #00000006, 0px 1px 3px #00000024",
                                    }
                                  : { boxShadow: "none" }
                              }
                              ref={reply.uuid === highlightedCid ? commentBoxRef : null}
                            >
                              {newReply.uuid && newReply.uuid === reply.uuid ? (
                                <article className="comment__form">
                                  <div className="comment__reg__form reply">
                                    <div className="reg-input-box">
                                      <span className="reply__user">@{item.unick}</span>
                                      <TextareaAutosize
                                        onChange={(e) =>
                                          setNewReply({
                                            ...newReply,
                                            content: e.target.value,
                                          })
                                        }
                                        value={newReply.content || ""}
                                        minRows={3}
                                      ></TextareaAutosize>
                                      <div className="reg-menu">
                                        <div className="right-menu-box">
                                          <div className="reg-btn-box">
                                            <button
                                              onClick={() => submit(newReply, setNewReply)}
                                              className="reg-btn"
                                            >
                                              {newReply.uuid ? "수정" : "등록"}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              ) : (
                                <div
                                  className="comment-box comment-reply-box"
                                  style={
                                    newComment.color &&
                                    newComment.color.index === reply.uuid &&
                                    newComment.color.toggle
                                      ? { backgroundColor: "#F5F4FF" }
                                      : {}
                                  }
                                >
                                  <div className="info-box">
                                    {!reply.deleted && (
                                      <span className={reply.iswriter ? "txt accent" : "txt"}>
                                        <Nickname
                                          uid={reply.uid}
                                          unick={reply.unick}
                                          level={reply.level}
                                        />
                                      </span>
                                    )}
                                  </div>

                                  <div className="container__content-btn">
                                    <div className="container__content">
                                      <div className="txt-box">
                                        {reply.reported
                                          ? "다수의 신고로 인해 블라인드 처리되었습니다"
                                          : htmlTextContent(reply.content)}
                                      </div>
                                      <span className="txt">{commentDttm(reply.reg_dttm)}</span>
                                    </div>
                                    {reply.img_link && !reply.reported && (
                                      <div className="img-box">
                                        <img src={Img(reply.img_link)} alt="이미지" />
                                      </div>
                                    )}
                                    {!reply.deleted && (
                                      <div className="menu-box">
                                        {(token.uid === reply.uid || reply.ismine) && (
                                          <div>
                                            <button
                                              className="report-btn"
                                              onClick={() => modifyComment(reply)}
                                            >
                                              수정
                                            </button>
                                            <button
                                              className="report-btn"
                                              onClick={() => setDeleteUUID(reply.uuid)}
                                            >
                                              삭제
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
          </div>
          <div className="comment__reg__form">
            <ClassCommentTextArea
              newComment={newComment}
              setNewComment={setNewComment}
              submit={submit}
            />
          </div>
        </div>
      </div>

      {deleteUUID && (
        <CommentTrashPopup
          setPopup={setDeleteUUID}
          deleteFunc={() => {
            deleteComment(deleteUUID);
          }}
        />
      )}
    </article>
  );
}

export default ClassComment;
