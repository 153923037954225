import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { api } from "../../../utils/util";

function BoardArticleBox({ articles, title }) {
  const { data: boardList } = useQuery(
    "boardCateCd",
    () => api.get("/board/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
    {
      onError: (err) => {
        err.response && alert(err.response.data.msg);
      },
    },
  );

  const getCateName = (cate_cd) => {
    const r = boardList.find((cate) => cate.cate_cd === cate_cd);
    return r ? r.cate_name : "";
  };

  return (
    <div>
      <div className="text-[20px] tracking-tighter font-semibold animate-fadedown whitespace-nowrap">
        {title}
      </div>
      <div className="mt-1 font-medium">
        {articles &&
          !!articles.length &&
          articles.slice(0, 6).map((item, idx) => (
            <Link
              to={`/post/article/${item.bid}`}
              key={idx}
              className="flex items-center text-[15px] h-[45px] border-solid border-b-[1px] border-gray-100 hover:text-primary-800"
            >
              {boardList && !!boardList.length && (
                <div className="whitespace-nowrap py-1 px-4 mr-2 text-[13px] bg-primary-100 rounded-sm tracking-tighter !text-primary-800 h-[fit-content]">
                  {getCateName(item.cate_cd)}
                </div>
              )}
              <span className="truncate mr-1">{item.title}</span>[{item.comment}]
            </Link>
          ))}
      </div>
    </div>
  );
}

export default BoardArticleBox;
