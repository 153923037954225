import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router";
import { api } from "../../utils/util";
import SideMenu from "./SideMenu";

const PointHistory = () => {
  const type = useParams().type;
  const [point, setPoint] = useState(null);
  const title = { board: "회원 등급", qna: "Q&A", forum: "임상포럼" };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    api
      .get(`/mypage/pointList/${type}`, {
        params: {
          page: selected,
        },
      })
      .then((res) => {
        setPoint({ ...res.data, load: true });
        window.scrollTo(0, 0);
      });
  };

  useEffect(() => {
    api
      .get(`/mypage/pointList/${type}`, {
        params: {
          page: 1,
        },
      })
      .then((res) => {
        setPoint({ ...res.data, load: true });
        window.scrollTo(0, 0);
      });
  }, [type]);

  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="mypage" />
            {point && (
              <div className="con__layout">
                <article className="section__tit__box">
                  <div className="tit-box">
                    <span className="tit-txt">{title[type]} 포인트 내역</span>
                  </div>
                </article>
                <ul className="grade__list">
                  {point.items.map((item, idx) => (
                    <li className={item.point < 0 ? "minus" : ""}>
                      <div className="date">
                        {item.created_at.slice(0, 4)}.{item.created_at.slice(4, 6)}.
                        {item.created_at.slice(6, 8)}
                      </div>
                      <div className="txt-box">
                        <span>{item.desc}</span>
                        <span className="point">{item.point}P</span>
                      </div>
                    </li>
                  ))}
                </ul>
                <article className="paging" style={{ paddingTop: "20px" }}>
                  <ReactPaginate
                    previousLabel="←"
                    previousLinkClassName="before"
                    nextLabel="→"
                    nextLinkClassName="next"
                    breakLabel="..."
                    pageCount={point.pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    pageLinkClassName="num"
                    activeClassName=""
                    activeLinkClassName="current"
                    onPageChange={onPageClick}
                  />
                </article>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default PointHistory;
