import { forwardRef, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import { useRecoilValue } from "recoil";
import { SwiperSlide } from "swiper/react";

import { CLASS_ROUTE } from "App";
import SwiperKlass from "component/klass/SwiperKlass";
import CourseCard from "component/klass/CourseCard";
import ProseMirrorEditorView from "component/prosemirror/ProseMirrorEditorView";

import KlassBookSwiper from "component/klass/KlassBookSwiper";
import Curriculum from "component/klass/Curriculum";
import { publicApi, cls, scrollTopList } from "utils/util";
import { isOfflineClass } from "utils/marketUtil";
import EditorView from "shared/EditorView";
import { tokenState } from "state";

import { AllCourses, CourseList } from "types/klass";

const KLASS_DETAIL_TAB = {
  intro: "강의소개",
  curriculum: "커리큘럼",
  faq: "FAQ 및 공지",
} as const;

const KLASS_DETAIL_TAB_KEYS = Object.keys(KLASS_DETAIL_TAB) as KlassDetailTab[];

const INIT_KLASS_DETAIL_TAB_KEY = KLASS_DETAIL_TAB_KEYS[0];

export type KlassDetailTab = keyof typeof KLASS_DETAIL_TAB;

type LectureContentProps = {
  courseId: string;
  lectureList: AllCourses;
  defaultCourseId?: string;
} & LectureContentActions;

interface LectureContentActions {
  scrollToCurriculum: () => void;
}

const LectureContent = forwardRef<HTMLDivElement, LectureContentProps>(
  ({ courseId, lectureList, defaultCourseId, scrollToCurriculum }, forwardedRef) => {
    const token = useRecoilValue(tokenState);

    const [tab, setTab] = useState<KlassDetailTab>(INIT_KLASS_DETAIL_TAB_KEY);
    const [moreIntro, setMoreIntro] = useState(false);

    const introRef = useRef<HTMLDivElement | null>(null);
    const faqRef = useRef<HTMLDivElement | null>(null);

    // MARK: react-query dependency에 사용함.
    const lecturers = useMemo(
      () => lectureList.course.lecturerNames?.join(","),
      [lectureList.course.lecturerNames],
    );

    // MARK: react-query dependency에 사용함.
    const level = useMemo(
      () => lectureList.course.groupedBadges?.level?.join(""),
      [lectureList.course.groupedBadges?.level],
    );

    // MARK: 자식컴포넌트에 전달함.
    const lectureProductId = useMemo(
      () => lectureList.course?.products?.[0]?.productId,
      [lectureList.course?.products],
    );

    const { data: lecturerSearchList } = useQuery(
      ["classCoursesSearchLecturers", lecturers],
      () =>
        publicApi
          ?.get(`/class/public/courses/search?lecturers=${lecturers}`)
          .then((res) => res.data),
      {
        enabled: !!lecturers,
      },
    );

    const { data: levelSearchList } = useQuery(
      ["classCoursesSearchLevel", level],
      () => publicApi?.get(`/class/public/courses/search?level=${level}`).then((res) => res.data),
      {
        enabled: !!level,
      },
    );

    const onTabClick = (tab: KlassDetailTab) => {
      setTab(tab);

      switch (tab) {
        case "intro":
          scrollTopList(introRef, "smooth");
          break;
        case "curriculum":
          scrollToCurriculum();
          break;
        case "faq":
          scrollTopList(faqRef, "smooth");
          break;
        default:
          break;
      }
    };

    return (
      <>
        <div className="w-full h-[52px] max-w-[1200px] m-auto border-b-[1px] border-secondary-900 flex items-center justify-around text-lg text-[#333] sticky top-[53px] bg-secondary-100 z-30">
          {KLASS_DETAIL_TAB_KEYS.filter(
            (klassDetailTabKey) =>
              !(isOfflineClass(lectureList.course) && klassDetailTabKey === "curriculum"),
          ).map((klassDetailTabKey, klassDetailTabKeyIndex) => {
            return (
              <div
                key={klassDetailTabKeyIndex}
                className={cls(
                  tab === klassDetailTabKey ? "text-white bg-secondary-900" : "",
                  isOfflineClass(lectureList.course) ? "w-[50%]" : "w-1/3",
                  "cursor-pointer border-solid flex items-center justify-center h-full",
                )}
                onClick={() => onTabClick(klassDetailTabKey)}
              >
                {KLASS_DETAIL_TAB[klassDetailTabKey]}
              </div>
            );
          })}
        </div>
        <div className="max-w-[1200px] px-4 m-auto lg:px-0 flex justify-between">
          <div className="max-w-[694px]">
            <div className="mt-[45px]">
              <h3 className="text-[#333] font-bold text-[22px]  mb-[15px]" ref={introRef}>
                강의소개
              </h3>

              <div
                className={cls(
                  moreIntro ? "" : "min-h-[100px] max-h-[750px] overflow-hidden",
                  "w-full",
                )}
              >
                {lectureList.course?.page?.editorType === "proseMirror" ? (
                  <ProseMirrorEditorView contentString={lectureList.course?.page?.contentString} />
                ) : (
                  <EditorView content={lectureList.course?.page.content} />
                )}
              </div>
              {!moreIntro && (
                <div className="mt-4 ">
                  <button
                    className="w-full h-10 rounded-md border-[1px] border-solid border-[#ccc] text-[#333] font-semibold text-sm flex items-center justify-center hover:bg-secondary-50"
                    onClick={() => setMoreIntro(true)}
                  >
                    강의소개 전체보기<i className="ii-chevron-down ml-2"></i>
                  </button>
                </div>
              )}
            </div>
            <div ref={forwardedRef}>
              {!isOfflineClass(lectureList?.course) && (
                <Curriculum
                  progress={lectureList.progress}
                  course={lectureList.course}
                  defaultCourseId={defaultCourseId}
                />
              )}
            </div>
            <div>
              {token && token.uid && token.user_type && lectureProductId && (
                <KlassBookSwiper lectureProductId={lectureProductId} />
              )}
              {lecturerSearchList?.results?.hits?.filter((i: CourseList) => i.courseId !== courseId)
                ?.length > 0 && (
                <div className="mt-[50px] pl-[15px] lg:pl-0">
                  <h3 className="text-[#333] font-bold text-[22px] mb-[5px]">
                    강연자의 또 다른 강의
                  </h3>
                  <SwiperKlass
                    options={{
                      breakpoints: {
                        0: {
                          slidesPerView: 2.3,
                          spaceBetween: 4,
                        },
                      },
                    }}
                    hasNavigation={false}
                  >
                    {lecturerSearchList?.results?.hits
                      ?.filter((i: CourseList) => i.courseId !== courseId)
                      ?.map((item: CourseList) => {
                        return (
                          <SwiperSlide key={item.id}>
                            <Link to={`/${CLASS_ROUTE}/course/${item.courseId}`} className="block">
                              <CourseCard
                                thumbnail={item.thumbnailImageUrl}
                                lecturers={item.lecturers}
                                title={item.title}
                                topic={item?.groupedBadges?.topics}
                                level={item?.groupedBadges?.level?.join(",")}
                                regularPrice={item?.product?.regularPrice}
                                salePrice={item?.product?.salePrice}
                                isSwiper
                                isFree={item?.isFree}
                              />
                            </Link>
                          </SwiperSlide>
                        );
                      })}
                  </SwiperKlass>
                </div>
              )}
              {levelSearchList?.results?.hits?.filter((i: CourseList) => i.courseId !== courseId)
                ?.length > 0 && (
                <div className="mt-[50px] pl-[15px] lg:pl-0">
                  <h3 className="text-[#333] font-bold text-[22px] mb-[5px]">
                    동일한 레벨의 다른 강의
                  </h3>

                  <SwiperKlass
                    options={{
                      breakpoints: {
                        0: {
                          slidesPerView: 2.3,
                          spaceBetween: 4,
                        },
                      },
                    }}
                    hasNavigation={false}
                  >
                    {levelSearchList?.results?.hits
                      ?.filter((i: CourseList) => i.courseId !== courseId)
                      ?.map((item: CourseList) => (
                        <SwiperSlide key={item.id}>
                          <Link
                            to={`/${CLASS_ROUTE}/course/${item.courseId}`}
                            className="block p-[1px]"
                          >
                            <CourseCard
                              thumbnail={item.thumbnailImageUrl}
                              lecturers={item.lecturers}
                              title={item.title}
                              topic={item?.groupedBadges?.topics}
                              level={item?.groupedBadges?.level?.join(",")}
                              regularPrice={item?.product?.regularPrice}
                              salePrice={item?.product?.salePrice}
                              isSwiper
                              isFree={item?.isFree}
                            />
                          </Link>
                        </SwiperSlide>
                      ))}
                  </SwiperKlass>
                </div>
              )}
            </div>

            <div className="mt-[50px]">
              <h3 className="text-[#333] font-bold text-[22px] mb-[15px]" ref={faqRef}>
                FAQ 및 공지
              </h3>
              {lectureList.course?.announcement.page?.editorType === "proseMirror" ? (
                <ProseMirrorEditorView
                  contentString={lectureList.course?.announcement.page?.contentString}
                />
              ) : (
                <EditorView content={lectureList.course?.announcement.page?.content} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default LectureContent;
