import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import { tokenState } from "../../state";
import BoardDetail from "./BoardDetail";
import BoardPage from "./BoardPage";
import BoardWrite from "./BoardWrite";

function Board() {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token.user_type === "partner") {
      alert("접근이 불가능한 페이지입니다.");
      navigate("/", { replace: true });
      return;
    }
  }, [token.user_type, navigate]);

  return (
    <section className="relative min-h-[100vh]">
      <Header current="board" />
      <article className="container pb-64">
        <Routes>
          <Route path="/article/:bid/*" element={<BoardDetail />} />
          <Route path="/write/:board/*" element={<BoardWrite />} />
          <Route path="/modify/:bid/*" element={<BoardWrite />} />
          <Route path="/*" element={<BoardPage />} />
        </Routes>
      </article>
      <Footer />
    </section>
  );
}

export default Board;
