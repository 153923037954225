import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { preventKorean, preventSpaceBar, BASE_URL } from "../../utils/util";
import Loading from "../../component/Loading";

function FindPwd(props) {
  const navigate = useNavigate();
  const [sms, setSms] = useState(false);
  const [phone, setPhone] = useState("");
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [number, setNumber] = useState(null);
  const [id, setId] = useState("");
  const [useable, setUseable] = useState(false);
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (time > 0) {
      const timeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      setTimer(timeout);
    }
  }, [time]);

  const sendNumber = () => {
    const data = {
      phone: phone,
      id: id,
    };

    setLoading(true);

    if (phone.length < 10) return;
    if (timer) {
      clearTimeout(timer);
    }

    axios
      .post(`${BASE_URL}/user/findPw`, data)
      .then((res) => {
        setSms(true);
        setTime(180);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.response.data.msg);
        setLoading(false);
      });
  };

  const checkNumber = () => {
    const data = {
      number: number,
    };
    axios
      .post(`${BASE_URL}/user/findPwVerify`, data)
      .then((res) => {
        setUseable(true);
        setAccessToken(res.data.access_token);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  const setNewPwd = () => {
    if (pw1.length < 4) {
      alert("비밀번호는 4글자 이상 입력해주세요.");
      return;
    }
    if (pw1 !== pw2) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    const data = {
      new_pw: pw1,
    };

    axios
      .post(`${BASE_URL}/user/modifyPassword`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        alert(res.data.msg);
        navigate("/login");
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  return (
    <div className="container full">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`} alt="로고" />
          </div>

          <div className="member__con">
            <div className="member__tit">
              <div className="tit-txt">비밀번호 찾기</div>
            </div>

            <div className="join-form">
              <div className="row">
                <div className="tbox full">
                  <input
                    type="text"
                    placeholder="아이디를 입력하세요."
                    onChange={(e) =>
                      preventSpaceBar(e.target.value) && setId(e.target.value.toLowerCase())
                    }
                    value={id}
                  />
                </div>
              </div>
              <div className="row">
                <div className="tbox full">
                  <input
                    type="tel"
                    placeholder="휴대폰 번호를 입력하세요."
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <button
                  className={"send-btn" + (phone.length >= 10 ? " on" : "")}
                  onClick={sendNumber}
                >
                  인증번호 전송
                </button>
              </div>
              {loading && <Loading />}
              {sms && (
                <div className="row">
                  <div className="tbox with-btn with-timer">
                    <input
                      placeholder="인증번호를 입력하세요."
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <span className="timer">
                      {Math.floor(time / 60)}:{String(time % 60).padStart(2, "0")}
                    </span>
                    <button onClick={checkNumber} className="tbox-btn">
                      확인
                    </button>
                  </div>
                </div>
              )}
            </div>

            {useable && (
              <div className="join-form" style={{ marginTop: "216px" }}>
                <div className="row">
                  <div className="tbox full f_main">
                    <input
                      type="password"
                      placeholder="새 비밀번호를 입력하세요. (4자리 이상)"
                      onChange={(e) =>
                        preventSpaceBar(e.target.value) &&
                        preventKorean(e.target.value) &&
                        setPw1(e.target.value)
                      }
                      value={pw1}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="tbox full f_main">
                    <input
                      type="password"
                      placeholder="새 비밀번호를 한번 더 입력하세요."
                      onChange={(e) =>
                        preventSpaceBar(e.target.value) &&
                        preventKorean(e.target.value) &&
                        setPw2(e.target.value)
                      }
                      value={pw2}
                    />
                  </div>
                </div>

                <div className="row">
                  <button className="complete-btn" onClick={setNewPwd}>
                    완료
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default FindPwd;
