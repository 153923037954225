import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { cls } from "utils/util";
import { useInView } from "react-intersection-observer";
import SearchItemArticle from "component/search/item/SearchItemArticle";

const SearchResultPost = ({ searchResult, hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  // ---------- 검색 리스트 ----------
  const postList = useMemo(() => {
    if (!searchResult) return [];
    return (
      searchResult?.reduce((acc, cur) => {
        const hits = cur.results?.flatMap((result) => result.hits) || [];
        return acc.concat(hits);
      }, []) || []
    );
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div>
      {postList.length > 0 ? (
        postList.map((post, index) => (
          <Link
            className={cls(
              index === postList.length - 1 ? "" : "border-b border-solid border-b-gray-200",
              "block cursor-pointer",
            )}
            to={`/post/article/${post.id.split("_")[1]}`}
            key={post.id}
          >
            <SearchItemArticle
              tag={post.topic}
              title={post.title}
              board_name={post.board_name}
              description={post.content}
              thumbnail={post.thumbnail}
              nickname={post.nickname}
              user_level={post.user_level}
              view={post.view}
              like={post.like}
              comment_count={post.comment_count}
              reg_dttm={post.reg_dttm}
            />
          </Link>
        ))
      ) : (
        <p>검색 결과가 없습니다.</p>
      )}
      {hasNextPage && !isFetchingNextPage && <div className="mt-[20px]" ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultPost;
