import { useQuery } from "react-query";
import { fetchUserBadges } from "./api";

export const useUserBadgesQuery = (uid: number) => {
  const hasUid = uid && uid > 0 ? true : false;

  return useQuery(["userBadges", uid], () => fetchUserBadges(uid), {
    staleTime: 15 * 60 * 1000,
    enabled: hasUid,
  });
};
