import React from "react";
import { Link } from "react-router-dom";
import { dttm, Img } from "../../../utils/util";
import { majorOptions } from "../../../constants";

function HotContent(props) {
  return (
    <section className="job_select" style={{ marginTop: "54px" }}>
      <div className="inner">
        {props.current === "recruit" ? (
          <article className="section__tit__box">
            <div className="tit-box">
              <span className="tit-txt">구인공고 HOT 게시글</span>
            </div>
          </article>
        ) : (
          <></>
        )}

        <div className="list__wrap">
          <ul>
            {/* 아래를 map으로 */}
            {props.hot_items &&
              props.hot_items.map((item) => {
                return (
                  <li key={item.bid}>
                    <article className="item__template3">
                      <Link to={`/recruit/doctor/${item.bid}`} className="box-wrap inline-block">
                        <div className="mid-box">
                          <div className="info-box">
                            <div className="location">
                              <img src={Img("", "img/ico-pin.svg")} alt="" />
                              {item.location1} {item.location2}
                            </div>
                            <div className="subject-box">{item.title}</div>
                            {props.current === "recruit" ? (
                              <div className="sub-box">
                                <div className="txt">
                                  {item.hospital_name &&
                                    item.hospital_name.slice(0, 6) +
                                      (item.hospital_name.slice(6) ? "⋯" : "")}
                                </div>
                                <div className="txt">
                                  {item.major
                                    ?.split("")
                                    .slice(0, 1)
                                    ?.map(
                                      (cd) => majorOptions.find((opt) => opt.value === cd)?.label,
                                    )
                                    ?.join("") +
                                    (item.major?.slice(1).length > 0
                                      ? ` 외 ${item.major?.slice(1).length}개과`
                                      : "")}
                                </div>
                                <span className="txt">
                                  {item.working_time === "whatever"
                                    ? "풀타임 근무"
                                    : "파트타임 근무"}
                                </span>
                              </div>
                            ) : (
                              <div className="sub-box">
                                <span className="txt">{item.unick}</span>
                                <span className="txt">{dttm(item.reg_dttm)}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="bottom-box">
                          <div className="count-box">
                            <span className="view">{item.view}</span>
                            {typeof item.comment === "number" && (
                              <span className="comment">{item.comment}</span>
                            )}
                          </div>
                          <div className="scrap-box">
                            <span className={item.scraped ? "scrap-mark on" : "scrap-mark"}></span>
                          </div>
                        </div>
                      </Link>
                    </article>
                  </li>
                );
              })}
          </ul>
          {props.current === "recruit" && (
            <article style={{ marginTop: "60px" }} className="paging">
              <Link to={`/recruit/doctor/write`} className="write-btn inline-block">
                <i className="ic ic-write"></i>
                <span className="txt">글쓰기</span>
              </Link>
            </article>
          )}
        </div>
      </div>
    </section>
  );
}

export default HotContent;
