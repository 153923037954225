import React from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ZoomImg from "../../../component/ZoomImg";
import { Img, api, htmlTextContent } from "../../../utils/util";
import Loading from "../../../component/Loading";
import LoanComment from "../../../component/management/LoanComment";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../../state";

const ConsultingDetail = () => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);
  const article = parseInt(useParams().id || 0);

  const { data: content, isLoading: contentLoading } = useQuery(
    ["boardArticle", article],
    () => api.get(`/board/article/${article}`).then((res) => res.data),
    {
      onError: (err) => {
        alert(err.response.data.msg);
        navigate(-1);
      },
    },
  );

  const deleteContent = () => {
    api.delete(`/board/delete/${content.board.bid}`).then((res) => {
      alert(res.data.msg);
      navigate(`/management/consulting`, { replace: true });
    });
  };

  if (contentLoading || !content) return <Loading />;

  return (
    <div className="">
      <div style={{ padding: "0 16px" }}>
        <section className="seoulDA__article">
          <div className="inner">
            <section className="info">
              <div className="top">{content.board.title}</div>
              <div className="bottom">
                <div className="left">
                  {token.user_nickname === content.board.unick ? content.board.unick : "익명"}
                  <span className="date">
                    {dayjs(content.board.reg_dttm).format("YYYY-MM-DD HH:mm")}
                  </span>
                </div>
                {content.ismine && (
                  <div className="right">
                    <button
                      onClick={() =>
                        navigate(`/management/consulting/modify/${article}`, {
                          state: { ...content },
                        })
                      }
                    >
                      수정
                    </button>
                    <button onClick={deleteContent}>삭제</button>
                  </div>
                )}
              </div>
            </section>

            <section className="content">
              <div className="txt-box">
                {htmlTextContent(content.board.content)}
                <br />
                <br />
                <br />
                <ZoomImg>
                  {content.images.map((item) => {
                    return (
                      <div key={item.link} className="img-box">
                        <img src={Img(item.link)} alt="이미지" />
                        <br />
                      </div>
                    );
                  })}
                </ZoomImg>
              </div>
            </section>
            <LoanComment article={content} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ConsultingDetail;
