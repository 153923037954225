import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { cls } from "utils/util";
import { useInView } from "react-intersection-observer";
import SearchItemArticle from "component/search/item/SearchItemArticle";
import { useSearchSegemnet } from "hooks/useSearchSegment";

const SearchResultForum = ({ searchResult, hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  // ---------- 검색 결과 총 개수 ----------
  const resultsTotalCount = useMemo(() => {
    return searchResult?.[0]?.estimatedTotalHits || 0;
  }, [searchResult]);

  // ---------- 검색 리스트 ----------
  const forumList = useMemo(() => {
    if (!searchResult) return [];
    return searchResult.reduce((acc, cur) => {
      if (cur?.hits) {
        return [...acc, ...cur.hits];
      }
      return acc;
    }, []);
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div>
      {forumList.length > 0 ? (
        forumList.map((forum, index) => (
          <Link
            className={cls(
              index === forumList.length - 1 ? "" : "border-b border-solid border-b-gray-200",
              "block cursor-pointer",
            )}
            to={`/forum/article/${
              forum.id.includes("forum_comment") ? forum.bid : forum.id.split("_")[2]
            }`}
            onClick={() => handleSearchItemClick({ id: forum.id, title: forum.title })}
            key={forum.id}
          >
            <SearchItemArticle
              tag={forum.topic}
              title={forum.title}
              description={forum.content}
              thumbnail={forum.thumbnail}
              nickname={forum.nickname}
              user_level={forum.user_level}
              view={forum.view}
              like={forum.like}
              comment_count={forum.comment_count}
              reg_dttm={forum.reg_dttm}
            />
          </Link>
        ))
      ) : (
        <p>검색 결과가 없습니다.</p>
      )}
      {hasNextPage && !isFetchingNextPage && <div ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultForum;
