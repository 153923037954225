import { useEffect, useState } from "react";
import { api, noticeDay } from "../../utils/util";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ClassComment from "./ClassComment";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { EDU_ROUTE } from "App";

const ClassNotice = ({ cid }) => {
  const navigate = useNavigate();
  const [noticeList, setNoticeList] = useState({ load: false });
  const [noticeDetail, setNoticeDetail] = useState({ load: false });
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const modifyFunc = () => {
    navigate(`/${EDU_ROUTE}/${cid}?tab=notice_write`, {
      state: { content: noticeDetail.notice },
    });
  };

  const urlParam = new URLSearchParams(location.search);
  const nid =
    parseInt(urlParam.get("nid")) ||
    (noticeList.load && (noticeList.notices.length > 0 ? noticeList.notices[0].nid : null));

  useEffect(() => {
    api.get(`/class/course/${cid}/notice`).then((res) => {
      setNoticeList({ ...res.data, load: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cid]);

  useEffect(() => {
    if (!nid) return;
    api.get(`/class/notice/${nid}`).then((res) => {
      setNoticeDetail({ ...res.data, load: true });
    });
  }, [cid, nid]);

  return (
    <div className="class_info__box">
      <div className="left-content">
        {nid && token && token.user_id === "admin" && (
          <span className="modify" onClick={modifyFunc}>
            수정하기
          </span>
        )}
        {noticeDetail.load && (
          <>
            <div className="class__box notice">
              <span className="notice-tit">{noticeDetail.notice.title}</span>
              <p className="notice-line">{noticeDay(noticeDetail.notice.reg_dttm)}</p>
              <div id="froala_view">
                <FroalaEditorView model={noticeDetail.notice.content} />
              </div>
            </div>
            <ClassComment
              current="notice"
              content={noticeDetail}
              article_num={nid}
              setContent={setNoticeDetail}
            />
          </>
        )}
      </div>
      <div className="right-content">
        <div className="notice-list-box">
          {noticeList.load &&
            noticeList.notices.map((item, idx) => {
              return (
                <Link to={`/${EDU_ROUTE}/${cid}?tab=notice&nid=${item.nid}`} key={idx} replace>
                  <div
                    className={
                      item.nid === nid
                        ? "notice-item current inline-block"
                        : "notice-item inline-block"
                    }
                    key={idx}
                  >
                    <div className="notice-title">{item.title}</div>
                    <div className="notice-detail">{noticeDay(item.reg_dttm)}</div>
                  </div>
                </Link>
              );
            })}
        </div>
        {token && token.user_id === "admin" && (
          <article className="paging">
            <Link to={`/${EDU_ROUTE}/${cid}?tab=notice_write`} className="write-btn inline-block">
              <i className="ic ic-write"></i>
              <span className="txt">글쓰기</span>
            </Link>
          </article>
        )}
      </div>
    </div>
  );
};

export default ClassNotice;
