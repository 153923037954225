import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { api } from "../../utils/util";
import BoardListUI from "../board/BoardListUI";
import Loading from "../Loading";

function TongtestContent({ current }) {
  const location = useLocation();
  const navigate = useNavigate();
  const board = 25;
  const article = parseInt(useParams().bid || 0);
  const [searchKeyword, setSearchKeyword] = useState();
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q");
  const cate = urlParam.get("cate");
  const type = urlParam.get("type");
  const params = { page, q };
  const pickParams = { type, page, q };

  const param = (() => {
    const p = new URLSearchParams();
    page && p.append("page", page);
    board && p.append("cate", board);
    searchKeyword && p.append("q", searchKeyword);
    return p.toString();
  })();

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    const p = new URLSearchParams(location.search);
    const type = p.get("type");
    type && param.append("type", type);
    param.append("page", 1);
    cate && param.append("cate", cate);
    searchKeyword && param.append("q", searchKeyword);
    navigate(
      current === "board_page" ? `/tongtest/board?${param}` : `/post/article/${article}?${param}`,
    );
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    const p = new URLSearchParams(location.search);
    const type = p.get("type");
    type && param.append("type", type);
    param.append("page", selected);
    cate && param.append("cate", cate);
    q && param.append("q", q);
    navigate(
      current === "board_page" ? `/tongtest/board?${param}` : `/post/article/${article}?${param}`,
    );
    window.scrollTo(0, 0);
  };

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["boardListTongTest", params, pickParams],
    () =>
      api
        .get(`/board/list/25`, { params: type === "pick" ? pickParams : params })
        .then((res) => res.data),
  );

  // const openArticle = (bid, e) => {
  //   e.preventDefault();
  //   const param = new URLSearchParams();
  //   pageInfo.load && pageInfo.page && param.append("page", pageInfo.page);
  //   board && param.append("cate", board);
  //   searchKeyword && param.append("q", searchKeyword);
  //   navigate(`/post/article/${bid}?${param}`);
  //   window.scrollTo(0, 0);
  // }

  if (pageInfoLoading) return <Loading />;

  return (
    <>
      {pageInfo && (
        <BoardListUI pageInfo={pageInfo} board={board} param={param} onPageClick={onPageClick} />
      )}

      <article className="list__search__form" style={{ marginTop: "47px" }}>
        <form className="search__form__box">
          <input
            type="search"
            placeholder="검색어를 입력해주세요."
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <button
            className="search-btn"
            onClick={(e) => search(e)}
            style={{ border: "none", outline: "none", cursor: "pointer" }}
          ></button>
        </form>
      </article>
    </>
  );
}

export default TongtestContent;
