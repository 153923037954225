import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import ProductUnit from "component/market/product/ProductUnit";
import MarketSortingBar from "component/market/MarketSortingBar";
import { cls } from "utils/util";

const SearchResultMarket = ({
  searchResult,
  selectedSort,
  onSelectSort,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}) => {
  // ---------- 검색 리스트 ----------
  const marketList = useMemo(() => {
    if (!searchResult) return [];
    return (
      searchResult?.reduce((acc, cur) => {
        const hits = cur?.hits || [];
        return acc.concat(hits);
      }, []) || []
    );
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div>
      {marketList.length > 0 ? (
        <>
          <div className="flex justify-end items-center w-full mb-[20px]">
            <MarketSortingBar initialSelectedSort={selectedSort} onSelectSort={onSelectSort} />
          </div>
          <div
            className={cls(
              "w-full grid grid-cols-2 gap-x-[12px] gap-y-[24px]",
              "lg:grid-cols-3 lg:gap-x-[20px] lg:gap-y-[20px]",
            )}
          >
            {marketList.map((product) => (
              <ProductUnit
                key={product.id}
                id={product.id}
                name={product?.meta_title || product?.product?.meta_title || product?.name || ""}
                regularPrice={product.regular_price}
                salePrice={product.sale_price}
                discountPercent={Math.round(
                  ((product.regular_price - product.sale_price) / product.regular_price) * 100,
                )}
                thumbnail={product?.images?.[0]?.url || product?.image}
                categoryIds={product?.category_ids || []}
                productCode={product?.product_code}
                brandName={product?.brand?.englishName}
                isSoldOut={product?.is_sold_out}
                isLoadingUnit={false}
                isSearchResult
              />
            ))}
          </div>
        </>
      ) : (
        <p>검색 결과가 없습니다.</p>
      )}
      {hasNextPage && !isFetchingNextPage && <div className="mt-[20px]" ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultMarket;
