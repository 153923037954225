import { Link, useLocation } from "react-router-dom";
import { writeDayMMDD } from "../../utils/util";

const DoctorListTable = ({ articles, bid }) => {
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  return (
    <div className="list">
      <div className="list-head">
        <div className="w15p left">지역</div>
        <div className="w15p left">병원</div>
        <div className="w55p left">제목</div>
        <div className="w10p">작성일</div>
        <div className="w5p">조회수</div>
      </div>
      {articles &&
        articles.map((item, i) => {
          return (
            <Link className="width-max" key={item.bid} to={`/recruit/doctor/${item.bid}?${param}`}>
              <div className={item.bid === bid ? "slim content current" : "slim content"}>
                <div className="w15p left">
                  <span className="accent">
                    <b>{item.location1}</b> {" " + item.location2}
                  </span>
                </div>
                <div className="w15p left">
                  <span className="left-hospital">{item.hospital_name}</span>
                </div>
                <div className="w55p left">{item.title}</div>
                <div className="w10p">
                  <span>{writeDayMMDD(item.reg_dttm)}</span>
                </div>
                <div className="w5p">
                  <span>{item.view}</span>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default DoctorListTable;
