import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Loading from "../../component/Loading";
import { tokenState } from "../../state";
import { api, Img, BASE_URL, tokenUpdateInApiOption } from "../../utils/util";
import ChannelTalk from "../../component/ChannelTalk";

function Recommender() {
  const navigate = useNavigate();
  const setToken = useSetRecoilState(tokenState);
  const [code, setCode] = useState("");
  const [nick, setNick] = useState("");
  const [email, setEmail] = useState({ value: "", msg: "" });
  const [nickChecked, setNickChecked] = useState({ checked: false, msg: "" });
  const [emailChecked, setEmailChecked] = useState({ checked: false, msg: "" });
  const [kakaoInvite, setKakaoInvite] = useState({ checked: false, msg: "" });

  const { state } = useLocation();
  const rcode = localStorage.getItem("rcode");

  const emailRegexp = () => {
    const regexp =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return regexp.test(email.value) ? false : true;
  };

  const onKakaoSync = () => {
    const data = {
      token: state.kakaoToken,
    };
    api.post("/user/connectKakaoID", data).catch((err) => {
      alert(err.response.data.msg);
    });
  };

  const onAppleSync = () => {
    const data = {
      token: state.appleToken,
    };
    api.post("/user/connectAppleID", data).catch((err) => {
      alert(err.response.data.msg);
    });
  };

  const setMaxLen = (value) => {
    if (value.length > 30) {
      setNickChecked({ checked: false, msg: "30자 이하만 가능합니다." });
      return false;
    }
    return true;
  };

  const join = () => {
    if (!nickChecked.checked) {
      setNickChecked({ checked: false, msg: "닉네임 중복확인이 필요합니다" });
      return;
    }

    if (!emailChecked.checked) {
      setEmailChecked({ checked: false, msg: "이메일 중복확인이 필요합니다" });
      return;
    }

    if (!email.value) {
      setEmail((cur) => ({ ...cur, msg: "이메일을 입력해주세요" }));
      return;
    }

    if (emailRegexp()) {
      setEmail((cur) => ({ ...cur, msg: "이메일 형식이 잘못되었습니다" }));
      return;
    }

    if (!kakaoInvite.checked) {
      setKakaoInvite({ checked: false, msg: "단톡방 초대 확인이 필요합니다" });
      return;
    }

    const signup = {
      ...state,
      recommender: rcode || code,
      nickname: nick,
      email: email.value,
    };

    axios
      .post(`${BASE_URL}/user/signup`, signup)
      .then(() => {
        navigate("/login", { replace: true });
      })
      .catch((e) => {
        setKakaoInvite({ ...kakaoInvite, msg: e.response.data.msg });
      });
  };

  const checkNickname = () => {
    const markable_nick = nick.replace(/\s/g, "");
    const data = {
      nickname: markable_nick,
    };
    axios.post(`${BASE_URL}/user/checkDuplicateNickname`, data).then((res) => {
      if (res.data.duplicate === false) {
        setNickChecked({ checked: true, msg: "사용가능한 별명입니다" });
      } else {
        setNickChecked({ checked: false, msg: "중복된 별명입니다" });
      }
    });
    return { status: "ok" };
  };

  const checkEmail = () => {
    axios
      .post(`${BASE_URL}/user/checkDuplicateEmail`, {
        email: email.value,
      })
      .then((res) => {
        if (res.data.duplicate === false) {
          setEmailChecked({ checked: true, msg: "사용가능한 이메일입니다" });
        } else {
          setEmailChecked({ checked: false, msg: "중복된 이메일입니다" });
        }
      })
      .catch((err) => {
        setEmailChecked({ checked: false, msg: err.response.data.msg });
      });
    return { status: "ok" };
  };

  useEffect(() => {
    if (!state || !state.id) {
      navigate("/signup", { replace: true });
    }
    if (!state || !state.phone) {
      navigate("/phoneVerify", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state) return <Loading />;

  return (
    <div className="container full">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`} alt="로고" />
          </div>

          <div className="member__con">
            <div className="member__tit">
              <div className="tit-txt">모어덴 회원가입 (2/2)</div>
            </div>

            <div className="join-form">
              <div className="row">
                <div className="tbox with-btn with-btn-full">
                  <input
                    placeholder="닉네임을 입력해주세요."
                    value={nick}
                    onChange={(e) => {
                      setNickChecked({ checked: false, msg: "" });
                      setMaxLen(e.target.value) && setNick(e.target.value);
                    }}
                  />
                  <button
                    className="tbox-btn"
                    style={{
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                    }}
                    onClick={checkNickname}
                  >
                    중복 확인
                  </button>
                </div>
              </div>
              {nickChecked.msg && (
                <div className={nickChecked.checked ? "info-txt ok" : "info-txt"}>
                  {nickChecked.msg}
                </div>
              )}
              <div className="row">
                <div className="tbox with-btn with-btn-full">
                  <input
                    placeholder="이메일을 입력해주세요."
                    type="email"
                    name="email"
                    // value={email}
                    onChange={(e) => {
                      setEmailChecked({ checked: false, msg: "" });
                      setEmail({ value: e.target.value });
                    }}
                  />
                  <button
                    className="tbox-btn"
                    style={{
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                    }}
                    onClick={checkEmail}
                  >
                    중복 확인
                  </button>
                </div>
              </div>
              {emailChecked.msg && (
                <div className={emailChecked.checked ? "info-txt ok" : "info-txt"}>
                  {emailChecked.msg}
                </div>
              )}
              {email.msg && <div className="info-txt">{email.msg}</div>}
              {!rcode && (
                <div className="row">
                  <div className="tbox with-btn with-btn-full">
                    <input
                      placeholder="추천인 코드를 입력해주세요. (선택)"
                      style={{ textTransform: "uppercase" }}
                      onChange={(e) => setCode(e.target.value)}
                      maxLength="10"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="user-info__container">
              <div className="user-info__box flex">
                <div className="user-name">
                  <input value={state.name} disabled />
                </div>
                <div className="user-gender">
                  <select defaultValue={state.genderCd === "F" ? "여자" : "남자"} disabled>
                    <option value="여자">여자</option>
                    <option value="남자">남자</option>
                  </select>
                </div>
              </div>
              <div className="user-info__box">
                <div className="title">생년월일</div>
                <div className="user-birthday flex">
                  <input value={dayjs(state.birthday).format("YYYY")} disabled />
                  <select defaultValue={dayjs(state.birthday).format("MM")} disabled>
                    <option value={dayjs(state.birthday).format("MM")}>
                      {dayjs(state.birthday).format("MM")}
                    </option>
                  </select>
                  <input value={dayjs(state.birthday).format("DD")} disabled />
                </div>
              </div>
              <div className="user-info__box">
                <div className="user-phone">
                  <div className="title">휴대전화</div>
                  <input value={state.phone} disabled />
                </div>
              </div>
            </div>

            <section className="kakao-invite__container join-form">
              <div className="title flex items-center">
                <span>콘텐츠 알림 단톡방 초대</span>
                <img className="inline-block" src={Img("", "img/kakao-invite.svg")} alt="invite" />
              </div>
              <div className="content">
                <span>
                  향후 콘텐츠 알림 단톡방으로 초대해드리겠습니다.
                  <br />
                  단톡방 초대안내를 위해 <b>1:1 카카오톡</b>으로 연락드릴 예정입니다. 감사합니다.
                </span>
              </div>
              <div
                className="check-btn"
                onClick={() => setKakaoInvite({ checked: !kakaoInvite.checked, msg: "" })}
              >
                <img
                  src={Img("", `img/kakao-${kakaoInvite.checked ? "check" : "uncheck"}.svg`)}
                  alt="check"
                />
                <span>네, 확인하였습니다.</span>
              </div>
              {kakaoInvite.msg && <div className="info-txt">{kakaoInvite.msg}</div>}
            </section>

            <div className="join-btn-box">
              <button
                className="join-btn-final"
                onClick={join}
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                완료
              </button>
            </div>
          </div>
        </div>
      </section>
      <ChannelTalk />
    </div>
  );
}

export default Recommender;
