import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Package from "./Package";

import { cls, resizedImg } from "utils/util";
import { isOfflineClass } from "utils/marketUtil";
import { Collection, Course } from "types/klass";

type LectureHeaderProps = {
  course: Course;
  collection?: Collection;
  studentCourses?: string[];
};

const LectureHeader: React.FC<LectureHeaderProps> = ({ course, collection, studentCourses }) => {
  const [openPackage, setOpenPackage] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const autoplay = params.get("autoplay");
  // const play = sessionStorage.getItem("play"); //마이페이지에서 자동재생을 위한 코드, 추후 사용할 수 있어서 주석처리
  const { id } = useParams();

  //마이페이지에서 자동재생을 위한 코드, 추후 사용할 수 있어서 주석처리
  // useEffect(() => {
  //   if (play === "true") {
  //     setVodPopup({ popup: true, bookmark: 0 });
  //     sessionStorage.removeItem("play");
  //   }
  // }, [play]);

  // leanEndAt, expireAt, registrationValidDays 비교하는 함수, 추후 사용할 수 있어서 주석처리
  // const getEarlierDate = (learnEndAt: Date, expireAt?: Date) => {
  //   if (!learnEndAt && expireAt) return new Date(expireAt);
  //   if (!expireAt) return new Date(learnEndAt);

  //   const learnEndDate = new Date(learnEndAt);
  //   const expireDate = new Date(expireAt);
  //   const earlierDate = learnEndDate < expireDate ? learnEndDate : expireDate;

  //   return new Date(earlierDate);
  // };

  // const timeRemaining = (datetime: Date) => {
  //   if (!datetime) return "";

  //   const nowAt = dayjs(new Date());
  //   const expireAt = dayjs(datetime);
  //   if (expireAt.diff(nowAt, "day") > 0) {
  //     return course.registrationValidDays > expireAt.diff(nowAt, "day")
  //       ? expireAt.diff(nowAt, "day") + "일"
  //       : course.registrationValidDays + "일";
  //   } else {
  //     return course.registrationValidDays > expireAt.diff(nowAt, "hours")
  //       ? expireAt.diff(nowAt, "hours") + "시간"
  //       : course.registrationValidDays + "시간";
  //   }
  // };

  return (
    <div>
      {/* 헤더 */}
      {/* <PageHeader tit="클래스" back={() => navigate(-1)} noHome children /> */}
      <div className="lg:flex lg:items-start lg:justify-between overflow-y-hidden">
        <div className="lg:max-w-[694px]">
          <div className="w-full lg:w-[694px] lg:h-[390px] lg:rounded-md overflow-hidden">
            {course?.previews[0]?.mediaKey ? (
              <div className="relative h-0 pb-[60.5%] lg:w-full lg:h-full lg:pb-0">
                <iframe
                  id="kollus-player-sample"
                  style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}
                  src={
                    "https://v.kr.kollus.com/" +
                    course.previews[0].mediaKey +
                    `?player_version=html5${autoplay ? "&autoplay" : ""}` // autoplay=구매 전 팝업에서 미리보기 시 자동재생
                  }
                  title="lecture"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center w-full lg:w-[694px] lg:h-[390px] lg:rounded-md">
                  <img
                    src={resizedImg(course.coverImage.link, "800")}
                    alt=""
                    className="w-full h-full object-cover lg:rounded-md"
                  />
                </div>
              </>
            )}
          </div>
          {!isOfflineClass(course) && (
            <div className="w-full">
              <div className="bg-primary-50 border-[1px] border-solid border-[#C3ACFF] rounded-md mt-5 px-3 py-4">
                <h5 className="text-gray-900 text-lg font-semibold mb-2">
                  강의 구매 전 필독사항 👀
                </h5>
                <ul className="mt-1 space-y-1 text-[15px] font-medium">
                  <li className="relative pl-3 text-gray-600 leading-5">
                    <span className="absolute top-[0] left-[0]">•</span>
                    환불 규정은 소비자보호에 관한 법률을 따릅니다.
                  </li>
                  <li className="relative pl-3 text-gray-600 leading-5">
                    <span className="absolute top-[0] left-[0]">•</span>
                    모든 콘텐츠와 강좌는 저작권 보호를 위해 불법다운로드, 캡쳐, 편집, 재배포 등을
                    금지합니다.
                  </li>
                  <li className="relative pl-3 text-gray-600 leading-5">
                    <span className="absolute top-[0] left-[0]">•</span>
                    하나의 계정당 최대 4개의 기기에서 수강 가능하며, 기기인증 초기화는 고객센터로
                    문의 바랍니다.
                  </li>
                </ul>
              </div>
            </div>
          )}
          {collection && collection.defaultCourseId === id && (
            <div className="relative">
              <div className="mt-12 mb-5 flex items-center space-x-2">
                <h3 className="text-gray-900 text-[22px] font-bold">
                  이 패키지에 포함된 <span className="text-teal-600">강의</span>
                </h3>
                <span className="text-gray-600 text-sm font-medium hidden lg:block">
                  필요한 강의만 수강해보세요😊
                </span>
              </div>
              <div>
                <div className="px-4 lg:px-0 max-w-[1200px] m-auto">
                  <div
                    className={cls(
                      openPackage
                        ? ""
                        : collection?.courses && collection.courses.length > 4
                        ? "h-[200px] overflow-hidden"
                        : "",
                      "space-y-3",
                    )}
                  >
                    {collection?.courses
                      ?.filter((course) => course.courseId !== collection.defaultCourseId)
                      ?.map((item, itemIndex) => {
                        return (
                          <Package
                            key={itemIndex}
                            studentCourses={studentCourses}
                            item={item}
                            isPackage
                          />
                        );
                      })}
                  </div>
                  {collection?.courses && collection.courses.length > 4 && (
                    <button
                      className="border-[1px] mt-5 border-solid border-gray-300 rounded-md w-full h-10 text-sm font-semibold text-gray-900 flex items-center justify-center space-x-2"
                      onClick={() => setOpenPackage(!openPackage)}
                    >
                      <span className="text-[#1B8773] mr-2">
                        총 {collection?.courses?.length - 1}개
                      </span>
                      강의 전체보기
                      <i
                        className={cls(
                          openPackage ? "ii-chevron-up" : "ii-chevron-down",
                          "text-gray-900",
                        )}
                      ></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          {collection && collection.defaultCourseId !== id && (
            <div className="relative">
              <div className="mt-12 mb-5 flex items-center space-x-2">
                <h3 className="text-gray-900 text-[22px] font-bold">
                  이 강의가 포함된 <span className="text-teal-600">패키지</span>
                </h3>
              </div>
              <div>
                <div className="px-4 lg:px-0 max-w-[1200px] m-auto">
                  <div className="overflow-hidden space-y-3">
                    {collection?.courses
                      ?.filter((course) => course.courseId === collection.defaultCourseId)
                      ?.map((item) => {
                        return (
                          <Package
                            studentCourses={studentCourses}
                            item={item}
                            key={item.courseId}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={cls(
          collection ? "bg-gradient-to-t" : "",
          "from-[#F9F7FF] to-[#ffffff] pb-[30px]",
        )}
      ></div>
    </div>
  );
};

export default LectureHeader;
