import { textblockTypeInputRule } from "prosemirror-inputrules";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { exitCode } from "prosemirror-commands";

/**
 * Given a code block node type, returns an input rule that turns a
 * textblock starting with three backticks into a code block.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @returns
 */
const codeBlockRule = (nodeType) => {
  return textblockTypeInputRule(/^```$/, nodeType)
}

export const CodeBlock = Extension.Create({
  name: 'code_block',

  type: 'node',

  defineSpec() {
    return {
      content: 'text*',
      marks: '',
      group: 'block',
      code: true,
      defining: true,
      parseDOM: [{tag: 'pre', preserveWhitespace: 'full'}],
      toDOM() {
        return ['pre', {class: MEDISTREAM_SCHEMA_STYLE.nodes.pre}, 0]
      },
    }
  },
    
    addCommands() {
      return {
        exitCode,
      }
    },
    
    addKeyboardShortcuts() {
    return {
      'Shift-Enter': this.editor.commands.exitCode,
    }
  },

  addInputRules() {
    return [
      codeBlockRule(this.schema.nodes[this.name]),
    ]
  }
})

