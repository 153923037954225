import { useInfiniteQuery, useQuery } from "react-query";
import {
  getCustomerInfo,
  getHomeProductGroupProducts,
  getHomeProductGroups,
  getProductCurations,
  getProductGroupProducts,
  getProductGroups,
} from "./api";

export const useCustomerInfo = () =>
  useQuery({
    queryKey: "/market/customers",
    queryFn: getCustomerInfo,
    cacheTime: 15 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
  });

export const useProductCurations = ({ enabled = true } = {}, queryOptions) => {
  return useQuery({
    queryKey: `/market/product-curations`,
    queryFn: () => getProductCurations({ enabled }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 15 * 60 * 1000,
    cacheTime: 15 * 60 * 1000,
    ...queryOptions,
  });
};

export const useProductGroups = (urlParams = {}, queryOptions) => {
  const { exposeTargets, enabled, type, ...rest } = urlParams;
  return useQuery({
    queryKey: ["/market/product-groups", type, enabled],
    queryFn: () => getProductGroups({ exposeTargets, enabled, type, ...rest }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useProductGroupProducts = (urlParams = {}, queryOptions) => {
  const { groupId, enabled, sort, limit } = urlParams;

  return useQuery({
    queryKey: [`/market/product-groups/${groupId}/products`, enabled, sort, limit],
    queryFn: () => getProductGroupProducts(urlParams, queryOptions),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useHomeProductGroups = (urlParams = {}, queryOptions) => {
  const { exposeTargets, enabled, type, ...rest } = urlParams;
  return useQuery({
    queryKey: ["/market/home-product-groups", type, enabled],
    queryFn: () => getHomeProductGroups({ exposeTargets, enabled, type, ...rest }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useHomeProductGroupProducts = (urlParams = {}, queryOptions) => {
  const { groupId, enabled, sort, limit } = urlParams;

  return useQuery({
    queryKey: [`/market/home-product-groups/${groupId}/products`, enabled, sort, limit],
    queryFn: () => getHomeProductGroupProducts(urlParams, queryOptions),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useProductGroupProductsInfinite = (urlParams, queryOptions) => {
  const { startPage = 1, enabled = true, limit = 40, sort = "three_months:desc" } = urlParams;
  const queryParams = {
    enabled,
    limit,
    sort,
  };
  return useInfiniteQuery({
    queryKey: [`/market/product-groups/${urlParams.groupId}/products`, enabled, sort],
    queryFn: ({ pageParam = startPage }) =>
      getProductGroupProducts({ ...urlParams, offset: (pageParam - 1) * limit, ...queryParams }),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.has_more) {
        const nextPage = allPages.length + startPage;
        return nextPage;
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 1,
    cacheTime: 1000 * 60 * 5, // default가 이미 5분
    ...queryOptions,
  });
};

export const useSortedCategories = ({ enabled = true } = {}, queryOptions) => {
  return useProductCurations(
    { enabled },
    {
      select: (productCurations) => {
        const enableCategories = productCurations?.map((category) => {
          if (category.sub_curations) {
            const enableSubCurations = category.sub_curations.filter(({ enabled }) => enabled);
            return { ...category, sub_curations: enableSubCurations };
          }
          return category;
        });
        return enableCategories?.slice().sort((a, b) => {
          return a.position - b.position;
        });
      },
      ...queryOptions,
    },
  );
};

export const useFlattenedCategories = ({ enabled = true } = {}, queryOptions) => {
  return useProductCurations(
    { enabled },
    {
      select: (productCurations) => {
        const flattenCurations = (curations) => {
          return curations.reduce((acc, cur) => {
            const newCur = { ...cur };
            if (newCur.sub_curations) {
              const enabledSubCurations = newCur.sub_curations
                .filter(({ enabled }) => enabled)
                .flatMap((subCur) => flattenCurations([subCur]));
              newCur.sub_curations = enabledSubCurations;
              acc.push(newCur);
              acc.push(...enabledSubCurations);
            } else {
              acc.push(newCur);
            }
            return acc;
          }, []);
        };

        return flattenCurations(productCurations || []);
      },
      ...queryOptions,
    },
  );
};
