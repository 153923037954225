import { useEffect, useRef, useState } from 'react';
import { IntegrationEditor } from './editor';

export class ReactEditor extends IntegrationEditor {
  /**
   * @param {import("./editor").IntegrationEditorConfig} options
   */
  constructor(options = {}) {
    super(options);
  }
}

const useForceUpdate = () => {
  const [, setValue] = useState(0);

  return () => setValue((value) => value + 1);
};

/**
 *
 * @param {import("./editor").IntegrationEditorConfig & { onTransaction?: (fn: import("./eventEmitter").OnTransactionParams) => void }} options
 * @param {import('react').DependencyList} deps
 * @returns {ReactEditor}
 */
export const useEditor = (options = {}, deps = []) => {
  /**
   * @type {[ReactEditor | null, import('react').Dispatch<React.SetStateAction<ReactEditor>>]} state
   */
  const [editor, setEditor] = useState(null);

  const forceUpdate = useForceUpdate();

  const { onTransaction } = options;

  // // This effect will handle updating the editor instance
  // // when the event handlers change.
  useEffect(() => {
    if (!editor) {
      return;
    }

    if (onTransaction) {
      editor.on('transaction', onTransaction);
    }
  }, [onTransaction, editor]);

  useEffect(() => {
    if (!options.element || !options.element.current) return
    
    let isMounted = true;

    options.element = options.element.current;

    const instance = new ReactEditor(options);

    setEditor(instance);

    instance.on('transaction', () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (isMounted) {
            forceUpdate();
          }
        });
      });
    });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    return () => {
      editor?.destroy();
    };
  }, [editor]);

  return editor;
};
