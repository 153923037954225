import { Route, Routes } from "react-router-dom";
import Footer from "../footer/Footer";
import Expo from "./Expo";
import ExpoDetail from "./ExpoDetail";
import Header from "../header/Header";

const ExpoRoute = () => {
  return (
    <section className="relative min-h-[100vh]">
      <Header current="expo" />
      <article className="container pt-10 pb-64">
        <Routes>
          <Route path="/:id/*" element={<ExpoDetail />} />
          <Route path="/*" element={<Expo />} />
        </Routes>
      </article>
      <Footer />
    </section>
  );
};

export default ExpoRoute;
