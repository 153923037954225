import { useMutation } from "react-query";
import { api } from "utils/util";
import { useQueryClient } from "react-query";

const BlockButton = ({ isBlocked, uid }) => {
  const queryClient = useQueryClient();
  const onClickBlock = useMutation((uid) => api.post(`/blocks/${uid}`), {
    onSuccess: () => {
      alert("해당 유저가 차단되었습니다.");
      return queryClient.invalidateQueries("users");
    },
    onError: (err) => {
      err.response && alert(err.response.data.msg);
    },
  });

  const releaseBlock = useMutation((uid) => api.delete(`/blocks/${uid}`), {
    onSuccess: () => {
      alert("차단 해제되었습니다.");
      return queryClient.invalidateQueries("users");
    },
    onError: (err) => {
      err.response && alert(err.response.data.msg);
    },
  });

  return (
    <BlockButtonUI
      isBlocked={isBlocked}
      onClick={() => {
        isBlocked ? releaseBlock.mutate(uid) : onClickBlock.mutate(uid);
      }}
    />
  );
};

export default BlockButton;

export const BlockButtonUI = ({ isBlocked, onClick }) => {
  return (
    <button
      className="h-[27.5px] flex items-center justify-center text-[13px] p-1 px-2 rounded border-[1px] border-solid border-secondary-300 hover:bg-secondary-100 bg-white disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={onClick}
    >
      <span className="flex items-center">
        {isBlocked ? (
          <>차단해제</>
        ) : (
          <span className="flex items-center">
            <i className="ii ii-ban mr-1"></i>차단
          </span>
        )}
      </span>
    </button>
  );
};
