import dayjs from "dayjs";
import TongtestExam from "./Tongtest_exam";
import TongtestNote from "./Tongtest_note";
import TongtestQuestion from "./Tongtest_question";
import { Route, Routes } from "react-router";
import Footer from "../../component/footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import TongtestCard from "./Tongtest_card";
import TongtestAbout from "./TongtestAbout";
import TongtestSchedule from "./TongtestSchedule";
import TongtestBoard from "./TongtestBoard";
import TongtestAttachment from "./Tongtest_attachment";
import { StaticImg } from "../../utils/util";
import Header from "component/header/Header";

const TongTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const menu_list = [
    {
      title: "통합치의학 모의고사",
      img: "home_tong_test.svg",
      link: "/tongtest",
    },
    {
      title: "통치자료",
      img: "tong_mate.svg",
      link: "/tongtest/attachment",
    },
    {
      title: "오답노트",
      img: "tong_era.svg",
      link: "/tongtest/note/1",
    },
    {
      title: "통치안내",
      img: "tong_time.svg",
      link: "/tongtest/about",
    },
    {
      title: "시험 일정",
      img: "tong_sche.svg",
      link: "/tongtest/schedule",
    },
    {
      title: "게시판/공지",
      img: "tong_talk.svg",
      link: "/tongtest/board",
    },
    {
      title: "암기법",
      img: "tong_puzzle.svg",
      link: "/tongtest/card",
    },
  ];
  const tongDate = dayjs("2021-07-18T00:00:00+09:00");
  const tongDateOver = dayjs() > tongDate.add(12, "hour");
  const token = useRecoilValue(tokenState);

  return (
    <section className="relative min-h-[100vh]">
      <Header current="class" />
      <div className="container pb-64">
        <div className="wrapper tongtest">
          <section className="magazine__home">
            <div className="inner">
              <article className="section__tit__box">
                <div className="tit-box">
                  <span className="tit-txt">통치</span>
                </div>
              </article>
              <div className="list__wrap">
                {dayjs() <= tongDate && (
                  <div className="tong__wrap">
                    <div className="event__box">
                      <div className="img-box">
                        <img
                          src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/tong_pencil.svg`}
                          alt="이미지"
                        />
                      </div>
                      <div className="info-box">
                        <span className="mid_box">7/18일 통치시험까지</span>
                        <span className="con_box">
                          D-DAY
                          <span className="accent"> {tongDate.diff(dayjs(), "days")}</span>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <ul>
                  <li>
                    <article className="item__tong__template1">
                      <a href="#none" className="box-wrap">
                        <div className="img-box">
                          <img src={StaticImg("tongtest/tongtest_main.png")} alt="이미지" />
                        </div>
                        <div className="info-box">
                          <div className="subject-box">ABOUT</div>
                          <div className="con-box">
                            통합치의학전문의는 치과영역의 종합적인 치료계획 수립 및 정확한 진료를
                            제공하기 위해 대한치과의사협회에서 2006년부터 치과대학병원에 교육과정을
                            개설하고
                            <br />
                            2019년 처음으로 시행된 통합치의학전문의 시험을 통해 자격을 취득한
                            전문의를 말합니다.
                            <br />
                            <br />
                            2022년도 통합치의학전문의 자격시험의 일정, 시행 계획, 예상 출제 등의
                            정보를 모어덴과 함께 쉽고 빠르게 알아보세요.
                          </div>
                        </div>
                      </a>
                    </article>
                  </li>
                </ul>
              </div>
              <section className="tongtest_new">
                <div className="gray_menu_list">
                  {menu_list
                    .filter((item) => !tongDateOver || item.title !== "통치자료")
                    .map((item, idx) => (
                      <Link
                        to={item.link}
                        className={
                          item.title === "통합치의학 모의고사"
                            ? "gray_menu tongtest_exam"
                            : location.pathname.indexOf(item.link) !== -1
                            ? " gray_menu on"
                            : " gray_menu"
                        }
                        onClick={(e) => {
                          window.scrollTo(0, 850);
                          if (item.title === "오답노트" && !token) {
                            alert("회원가입 후 이용해주세요");
                            e.preventDefault();
                            return;
                          }
                          navigate(item.link, { replace: true });
                        }}
                        key={idx}
                      >
                        <div className="subject">
                          <span>{item.title}</span>
                        </div>
                        <div className="sub-img">
                          <img src={StaticImg(`tongtest/${item.img}`)} alt="이미지" />
                        </div>
                      </Link>
                    ))}
                </div>
              </section>
              <Routes>
                <Route path="/board" element={<TongtestBoard />} />
                <Route path="/schedule" element={<TongtestSchedule />} />
                <Route path="/about" element={<TongtestAbout />} />
                <Route path="/card/*" element={<TongtestCard />} />
                <Route path="/note/:sid/*" element={<TongtestNote />} />
                <Route path="/question/:sid/*" element={<TongtestQuestion />} />
                {!tongDateOver && <Route path="/attachment/*" element={<TongtestAttachment />} />}
              </Routes>
              <>
                {location.pathname === "/tongtest" && (
                  <>
                    <article style={{ marginTop: 100 }} className="section__tit__box">
                      <div className="tit-box">
                        <span className="tit-txt">통합치의학 모의고사</span>
                      </div>
                    </article>

                    <div className="ready tong__box__container">
                      <article className="section__tong__box">
                        <img
                          className="ttest_logo"
                          src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/ttest_logo.svg`}
                          alt="이미지"
                        />
                        <div className="tong__box__text">모어덴 통합치의학 모의고사</div>
                      </article>
                      <Routes>
                        <Route path="/*" element={<TongtestExam />} />
                      </Routes>
                    </div>
                  </>
                )}
              </>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default TongTest;
