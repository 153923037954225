import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/util";

function WithdrawPopupBox({ setPopup }) {
  const navigate = useNavigate();
  const [r1, setR1] = useState(false);
  const [r2, setR2] = useState(false);
  const [r3, setR3] = useState(false);
  const [r4, setR4] = useState(false);
  const [r5, setR5] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [reason, setReason] = useState("");

  const withdraw = async () => {
    if (!agree1 || !agree2) {
      alert("서비스 탈퇴에 동의해주세요");
      return;
    }

    const data = {
      r1,
      r2,
      r3,
      r4,
      r5,
      reason,
    };
    api.post("/user/withdraw", data).then(() => {
      navigate("/logout", { replace: true });
      alert("탈퇴처리가 완료되었습니다");
    });
  };

  return (
    <article className="popup popup-leave" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="head">
          <div className="popup-tit">
            <i className="ic ic-logo"></i>
            <span className="txt">탈퇴하기</span>
          </div>
          <button className="close-btn" onClick={() => setPopup(false)} />
        </div>
        <div className="alert">탈퇴하시면, 모어덴의 서비스를 더이상 사용하실 수 없습니다.</div>
        <div className="body">
          <article className="pop__leave">
            <div className="tit-box">1. 탈퇴사유</div>
            <div className="leave__check__box">
              <div className="chk-list-box">
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input value={r1} onChange={(e) => setR1(e.target.checked)} type="checkbox" />
                      <i></i>
                      <span className="txt">필요한 정보를 다 얻어서 떠나고 싶어요.</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input value={r2} onChange={(e) => setR2(e.target.checked)} type="checkbox" />
                      <i></i>
                      <span className="txt">얻고 싶은 정보가 부족해요.</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input value={r3} onChange={(e) => setR3(e.target.checked)} type="checkbox" />
                      <i></i>
                      <span className="txt">이미 다른 계정이 있어요.</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input value={r4} onChange={(e) => setR4(e.target.checked)} type="checkbox" />
                      <i></i>
                      <span className="txt">탈퇴 후 재가입 하고 싶어요.</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cbox">
                    <label>
                      <input value={r5} onChange={(e) => setR5(e.target.checked)} type="checkbox" />
                      <i></i>
                      <span className="txt">기타 사유</span>
                    </label>
                  </div>
                  <div className="text-box">
                    <textarea
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      placeholder="기타 사유를 입력해주세요."
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="tit-box">2. 탈퇴 동의</div>
              <div className="agree-box">
                <div className="cbox">
                  <label>
                    <input
                      type="checkbox"
                      id="agree"
                      value={agree1}
                      onChange={(e) => setAgree1(e.target.checked)}
                    />
                    <i></i>
                    <span className="txt">
                      개인정보처리방침에 따라{" "}
                      <b style={{ color: "#FF7878" }}>일정기간동안 재가입이 제한됨</b>을
                      확인하였습니다.
                    </span>
                  </label>
                </div>
              </div>
              <div className="agree-box">
                <div className="cbox">
                  <label>
                    <input
                      type="checkbox"
                      id="agree"
                      value={agree2}
                      onChange={(e) => setAgree2(e.target.checked)}
                    />
                    <i></i>
                    <span className="txt">모어덴 서비스 탈퇴에 동의합니다.</span>
                  </label>
                </div>
              </div>
            </div>
          </article>

          <div className="popup__menu">
            <button onClick={() => setPopup(false)} className="pop-menu-btn">
              닫기
            </button>
            <button onClick={withdraw} className="pop-menu-btn type1">
              탈퇴하기
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

export default WithdrawPopupBox;
