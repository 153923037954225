import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { api, Img } from "../../../utils/util";
import Loading from "../../Loading";
import MagazineList from "./MagazineList";

function BoardMagazine() {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q");
  const params = { page, q };

  const { data: magazineInfo, isLoading: magazineInfoLoading } = useQuery(
    ["boardList1", params],
    () => api.get("/board/list/1", { params }).then((res) => res.data),
  );

  if (magazineInfoLoading) return <Loading />;
  return (
    <>
      {magazineInfo && magazineInfo.recommend_items && (
        <section className="magazine__home" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">모어덴 추천 매거진</span>
              </div>
            </article>
            <div className="list__wrap">
              <ul>
                {magazineInfo.recommend_items.map((item) => {
                  return (
                    <li key={item.bid}>
                      <article className="item__template1">
                        <Link
                          to={`/post/article/${item.bid}?cate=1`}
                          className="box-wrap inline-block"
                        >
                          <div className="img-box">
                            <img src={Img(item.thumbnail)} alt="이미지" />
                          </div>
                          <div className="info-box">
                            <span className="label-box">{item.label}</span>
                            <div className="subject-box mt10">{item.title}</div>
                            <div className="con-box">{item.content}</div>
                          </div>
                        </Link>
                      </article>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>
      )}

      <section className="magazine__list" style={{ marginBottom: "136.5px" }}>
        <div className="inner">
          <MagazineList current="magazine_page" current_cate={1} />
        </div>
      </section>
    </>
  );
}

export default BoardMagazine;
