import 'prosemirror-view/style/prosemirror.css'

import { EditorView } from "prosemirror-view"
import { EventEmitter } from "./eventEmitter"
import { ExtensionManager } from "./extensionManager"
import { EditorState } from "prosemirror-state"
import { CommandManager } from './commandManager'
import { INTEGRATION_EDITOR_CLASS } from '../styles/classNames'

/**
 * 할일 목록
 * 
 * -1. Node/Mark Spec 에 못생긴 코드 정리하기. (+영어 주석 번역해놓기)
 * 2. 서브모듈 내부에만 적용되는 eslint 설정하기. (workspace 속의 workspace 느낌으로)
 * -3. 모든 타입 import JSDoc 내부로 옮기기.
 * 4. 에디터 사용하는 개발자들 슬랙 그룹 생성해서 에디터 업데이트 공지하는 플로우 만들기.
 * -5. Plugin 꼭 editor 참조 하도록 만들어야 하나?
 * 6. remoteMenu 명령어 형식 통일하기.
 * 7. 클래스에 JSDOC 꼼꼼히 달기.
 * -8. spec 함수로 바꾸기.
 * -9. exitCode 키맵 추가하기.
 * -10. 맥 키맵 분기.
 * 11. extension 에 configure 메서드 추가해서 설정 오버라이드 지원하기.
 * 
 * @typedef {{ 
 *  extensions: import('./extension').Extension[],
 *  element: HTMLElement | import('react').RefObject,
 *  editorProps?: import('prosemirror-view').EditorProps & SomeProp,
 *  placeholder?: string,
 * }} IntegrationEditorConfig
 * 
 * 
 * @typedef {{ [someProp: string]: any }} SomeProp 외부의 데이터를 에디터에 주입할 때 사용하는 경로입니다.
 * 
 */

export class IntegrationEditor extends EventEmitter {
  /**
   * @param {IntegrationEditorConfig} options 
   */
  constructor(options = {
    extensions: [],
    element: null,
    editorProps: {},
    placeholder: '',
  }) {
    super()
    this.options = options
    this._createExtensionManager()
    this._createCommandManager()
    this._createView()
  }

  get state() {
    return this.view.state
  }

  get commands() {
    return this.commandManager.commands
  }

  dispatchTransaction(transaction) {
    const state = this.view.state.apply(transaction)

    this.view.updateState(state)

    this.emit('transaction', {
      editor: this,
      transaction,
    })
  }

  getTextContent() {
    return this.state.doc.textContent
  }

  /**
   * 저장된 글을 불러올때 사용합니다.
   * @param {import('prosemirror-model').Node} doc 
   */
  createStateFromDoc(doc) {
    return EditorState.create({
      doc: this.extensionManager.schema.nodeFromJSON(doc),
      schema: this.extensionManager.schema,
      plugins: this.extensionManager.plugins,
    })
  }

  destroy() {
    if (this.view) {
      this.view.destroy()
    }

    this.removeAllListeners()
  }

  resetState() {
    this.view.updateState(EditorState.create({
      schema: this.extensionManager.schema,
      plugins: this.extensionManager.plugins,
    }))
  }
  
  /**
   * @private
   */
  _createExtensionManager() {
    this.extensionManager = new ExtensionManager(this.options.extensions, this)
  }

  /**
   * @private
   */
  _createCommandManager() {
    this.commandManager = new CommandManager(this)
  }

  /**
   * @private
   */
  _createView() {
    this.view = new EditorView(this.options.element, {
      ...this.options.editorProps,
      dispatchTransaction: this.dispatchTransaction.bind(this),
      attributes: {
        class: INTEGRATION_EDITOR_CLASS,
        placeholder: this.options.placeholder || '',
      },
      state: EditorState.create({
        schema: this.extensionManager.schema,
        plugins: this.extensionManager.plugins,
      }),
    })

    this._createNodeViews()
  }

  /**
   * @private
   */
  _createNodeViews() {
    this.view.setProps({
      nodeViews: this.extensionManager.nodeViews,
    })
  }
}
