import { searchApi, api } from "utils/util";

export const getSingleSearch = async ({ indexUid, reqBody }, retryCallback) => {
  try {
    const { status, data } = await searchApi?.post(`/indexes/${indexUid}/search`, {
      ...reqBody,
    });

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error("getSingleSearch error");
  } catch (error) {
    console.error(error);
    if (error?.response?.data?.code === "invalid_api_key" && retryCallback) {
      await retryCallback();
      const { status, data } = await searchApi?.post(`/indexes/${indexUid}/search`, {
        ...reqBody,
      });
      if (status >= 200 && status < 300) {
        return data;
      }
      throw new Error("getSingleSearch error");
    }
    throw error;
  }
};

export const getMultiSearch = async ({ reqBody }, retryCallback) => {
  try {
    const { status, data: results = [] } = await searchApi?.post(`/multi-search`, {
      ...reqBody,
    });

    if (status >= 200 && status < 300) {
      return results;
    }

    throw new Error("getMultiSearch error");
  } catch (error) {
    console.error(error);
    if (error?.response?.data?.code === "invalid_api_key" && retryCallback) {
      await retryCallback();
      const { status, data: results = [] } = await searchApi?.post(`/multi-search`, {
        ...reqBody,
      });
      if (status >= 200 && status < 300) {
        return results;
      }
      throw new Error("getMultiSearch error");
    }
    throw error;
  }
};

export const getSearchKey = async () => {
  try {
    const {
      status,
      data: { searchKey = "", indexes = [] },
    } = await api?.get(`/user/searchKey`);

    if (status >= 200 && status < 300) {
      return { searchKey, indexes };
    }

    throw new Error("getSearchKey error");
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// topicType: "forum" | "qna" | "seminar"
export const getSearchTopicList = async (topicType) => {
  if (!topicType) return;
  try {
    const res = await api?.get(`/${topicType}/cate_cd`);
    return res?.data?.cate_list || [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};
