import React from "react";
import ContentLoader from "react-content-loader";

const LectureLoading = (props) => (
  <ContentLoader width={800} height={500} viewBox="0 0 800 500" {...props}>
    <rect x="0" y="0" rx="2" ry="2" width="800px" height="500px" />
  </ContentLoader>
);

export default LectureLoading;
