import { useNavigate } from "react-router";
import ContentsKeyword from "../../component/contents/ContentsKeyword";
import ContentsList from "../../component/contents/ContentsList";
import { useState, useEffect } from "react";
import { api } from "../../utils/util";
import Loading from "../../component/Loading";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import TodayContents from "component/contents/TodayContents";
import HotContents from "component/contents/HotContents";
import SearchForm from "component/shared/SearchForm";

function ContentsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam = new URLSearchParams(location.search);
  const [searchKeyword, setSearchKeyword] = useState("");
  const q = urlParam.get("q") || "";
  const tag = urlParam.get("tag") || "";
  const page = parseInt(urlParam.get("page")) || 1;
  const params = { page, per_page: 12, q, tag };
  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(["contentsList", params], () =>
    api.get("/contents/list", { params }).then((res) => res.data),
  );

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    selected && param.append("page", selected);
    navigate(`/contents?${param}`);
  };

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    param.append("page", 1);
    searchKeyword && param.append("q", searchKeyword);
    navigate(`/contents/search?${param}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setSearchKeyword(q);
  }, [page, q, tag]);

  if (pageInfoLoading) return <Loading />;

  return (
    <article>
      <section className="lg:max-w-[1200px] mx-auto">
        <div className="grid grod-cols-1 lg:grid-cols-2 gap-10 mb-10">
          <TodayContents recentContents={pageInfo.recent_contents} />
          <HotContents hotContents={pageInfo.hot_contents} />
        </div>
      </section>
      {/* 키워드로 콘텐츠 찾기 */}
      <ContentsKeyword />
      <section className="lg:max-w-[1200px] mx-auto">
        <div className="lg:p-0 px-4 text-xl tracking-tighter font-semibold whitespace-nowrap mb-5">
          전체 콘텐츠
        </div>
        <ContentsList pageInfo={pageInfo} onPageClick={onPageClick} />
        <div className="w-full flex justify-center pt-10 px-10 lg:px-0">
          <SearchForm
            value={searchKeyword}
            setValue={setSearchKeyword}
            search={search}
            width="400px"
          />
        </div>
      </section>
    </article>
  );
}

export default ContentsPage;
