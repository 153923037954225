import React, { useRef, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { scrollTopList } from "../../utils/util";
import ReactPaginate from "react-paginate";
import ManageListTable from "./ManageListTable";

function ManageContent({ pageInfo, current }) {
  const navigate = useNavigate();
  const location = useLocation();
  const bid = parseInt(useParams().bid);
  const urlParams = new URLSearchParams(location.search);
  const q = urlParams.get("q") || "";
  const [searchKeyword, setSearchKeyword] = useState(q);

  const managementScroll = useRef();

  const search = (e) => {
    e.preventDefault();
    searchKeyword ? urlParams.set("q", searchKeyword) : urlParams.delete("q");
    urlParams.set("page", 1);
    navigate(`${location.pathname}?${urlParams}`);
    scrollTopList(managementScroll);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    urlParams.set("page", selected);
    navigate(`${location.pathname}?${urlParams}`);
    scrollTopList(managementScroll);
  };

  return (
    <>
      <article className="list__table2" ref={managementScroll}>
        <ManageListTable current={current} articles={pageInfo.board} bid={bid} />
      </article>
      <article className="paging">
        {pageInfo.page && (
          <ul>
            <li>
              <ReactPaginate
                previousLabel="←"
                previousLinkClassName="before"
                nextLabel="→"
                nextLinkClassName="next"
                breakLabel="..."
                pageCount={pageInfo.pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                pageLinkClassName="num"
                activeClassName=""
                activeLinkClassName="current"
                onPageChange={onPageClick}
                forcePage={pageInfo.page - 1}
              />
            </li>
          </ul>
        )}
        <Link to={`/management/${current}/write`} className="write-btn bottom">
          <i className="ic ic-write"></i>
          <span className="txt">글쓰기</span>
        </Link>
      </article>

      <article className="list__search__form" style={{ marginTop: "47px" }}>
        <form className="search__form__box">
          <input
            type="search"
            placeholder="검색어를 입력해주세요."
            onChange={(e) => setSearchKeyword(e.target.value)}
            value={searchKeyword}
          />
          <button
            className="search-btn"
            onClick={(e) => search(e)}
            style={{ border: "none", outline: "none", cursor: "pointer" }}
          ></button>
        </form>
      </article>
    </>
  );
}

export default ManageContent;
