import { useProductInfo } from "api/market/product/hooks";
import Timer from "component/market/groupPurchase/Timer";
import { useHomeSegment } from "hooks/useHomeSegment";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import { money } from "utils/marketUtil";
import { StaticImg, cls, resizedImg, marketUrl } from "utils/util";

export default function GroupPurchaseBox({ groupPurchase }) {
  const { sendTrackingEvent } = useHomeSegment();
  const { teamProduct } = groupPurchase?.at(0) || {};

  const { data: productInfo, isLoading: productInfoLoading } = useProductInfo(
    teamProduct?.productId,
    {
      enabled: !!teamProduct?.productId,
    },
  );

  const isSoldOut = productInfo?.is_sold_out;

  const isNotOpened = !productInfoLoading && !productInfo;
  const groupPurchaseText = (text, notOpenedText = "오픈 예정") => {
    if (productInfoLoading) return "";
    if (isSoldOut) return "품절";
    if (isNotOpened) return notOpenedText;
    return text;
  };

  if (productInfoLoading)
    return (
      <ContentLoader
        speed={1}
        width={384}
        height={269}
        viewBox="0 0 384 269"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width="384" height="269" />
      </ContentLoader>
    );

  return (
    <div
      onClick={() => sendTrackingEvent({ name: "공동구매" })}
      className={cls(isSoldOut ? "cursor-not-allowed" : "")}
    >
      <Link
        to={marketUrl(
          `/market/product/${teamProduct?.productId}?groupPurchaseId=${teamProduct?.teamProductId}`,
        )}
        className={cls(
          isSoldOut ? "opacity-70 pointer-events-none" : "",
          "relative block rounded-md shadow-md w-full h-[269px] group justify-self-end",
        )}
      >
        <div className="absolute z-20 -top-1 left-2 flex justify-center w-[96px] h-[38px]">
          <img
            className="absolute top-0 left-0 w-full h-full"
            src={resizedImg(StaticImg("market/groupPurchase_discount_MO.svg"))}
            alt=""
          />
          <p className="z-10 mt-1 text-white">
            <b>{teamProduct?.description1}</b>
            <span className="font-semibold text-[12px]">{teamProduct?.description2}</span>
          </p>
        </div>
        <div className="relative w-full h-[134px] rounded-t-md overflow-hidden">
          {isSoldOut && (
            <span className="absolute -top-0 left-0 z-10 w-full h-full backdrop-blur-sm font-semibold text-white text-2xl grid place-items-center bg-[rgba(0,0,0,0.4)] pointer-events-none rounded-l-md">
              일시품절
            </span>
          )}
          <img
            className="w-full h-full object-cover rounded-t-md group-hover:scale-105 duration-300"
            src={resizedImg(teamProduct?.productImage, 768)}
            alt=""
          />
        </div>
        <div className="px-3.5 py-3">
          <p className="mb-1 text-ellipsis line-clamp-1">
            {groupPurchaseText(teamProduct?.exposureName, "상품 오픈 예정입니다")}
          </p>
          <div className="flex items-center mb-2 flex-wrap justify-between">
            <p className="flex items-center gap-[5px]">
              <b className="py-[3px] px-[5px] font-semibold text-xs text-white bg-gradient-to-r from-[#FF3370] to-[#FF2E16] rounded-md">
                {teamProduct?.description3 || "공동구매가"}
              </b>
              <b className="text-[#FF2F17] flex items-center leading-[24px] text-sm mr-1.5 flex-shrink-0">
                {groupPurchaseText(teamProduct?.description4 || teamProduct.description1, "비공개")}
                <i className="ii ii-arrow-down ii-weight-600 text-base text-[#FF2F17]"></i>
              </b>
            </p>
            <b className="text-xl flex-shrink-0">
              {groupPurchaseText(`${money(teamProduct?.teamPrice)}원`, "비공개")}
            </b>
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-12 px-3.5 bg-primary-700 flex items-center rounded-b-md justify-between">
          <p className="text-white font-medium">🔥공동구매</p>
          {!isSoldOut && Date.now() < teamProduct?.teamProductEndAt && (
            <Timer
              targetDate={teamProduct.teamProductEndAt}
              timerUnitStyle={{ width: 28, height: 28, fontSize: 14 }}
            />
          )}
        </div>
      </Link>
    </div>
  );
}
