import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../../component/Loading";
import ManageContent from "../../../component/management/ManageContent";
import { api } from "../../../utils/util";

function UsedMarketPage() {
  const location = useLocation();
  const [pageInfo, setPageInfo] = useState({});
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const q = urlParam.get("q") || "";

  useEffect(() => {
    const data = new URLSearchParams();
    data.append("page", page);
    data.append("q", q);
    api
      .get(`/management/market/list`, {
        params: data,
      })
      .then((res) => {
        setPageInfo({ ...res.data });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, q]);

  if (!pageInfo) return <Loading current="management" />;
  return (
    <div>
      <div className="wrapper">
        <section className="" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article style={{ marginTop: "60px" }} className="paging">
              <Link
                to={`/management/market/write`}
                className="write-btn"
                style={{ marginTop: "15px" }}
              >
                <i className="ic ic-write"></i>
                <span className="txt">글쓰기</span>
              </Link>
            </article>
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">중고장터</span>
              </div>
            </article>
            <div className="explain">치과 장비/책 등을 사고 파는 곳입니다.</div>
          </div>
        </section>

        <section className="magazine__list mt-[60px]" style={{ marginBottom: "35px" }}>
          <div className="inner" style={{ marginTop: "-50px" }}>
            {pageInfo && (
              <ManageContent pageInfo={pageInfo} current="market" pages={pageInfo.pages} />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default UsedMarketPage;
