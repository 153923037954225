import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { cls } from "utils/util";
import { useInView } from "react-intersection-observer";
import SearchItemSeminar from "../item/SearchItemSeminar";
import { useSearchSegemnet } from "hooks/useSearchSegment";

const SearchResultSeminar = ({ searchResult, hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  // ---------- 검색 리스트 ----------
  const seminarList = useMemo(() => {
    if (!searchResult) return [];
    return searchResult.reduce((acc, cur) => {
      if (cur?.hits) {
        return [...acc, ...cur.hits];
      }
      return acc;
    }, []);
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div>
      {seminarList.length > 0 ? (
        seminarList.map((seminar, index) => (
          <Link
            className={cls(
              index === seminarList.length - 1 ? "" : "border-b border-solid border-b-gray-200",
              "block cursor-pointer",
            )}
            onClick={() => handleSearchItemClick({ id: seminar.id, title: seminar.title })}
            to={`/management/seminar/${seminar.id.split("_")[1]}`}
            key={seminar.id}
          >
            <SearchItemSeminar
              tag={seminar.topic}
              title={seminar.title}
              location1={seminar.location1}
              location2={seminar.location2}
              end_date={seminar.end_date}
              start_date={seminar.start_date}
              thumbnail={seminar.thumbnail}
              point={seminar.point}
              key={seminar.id}
            />
          </Link>
        ))
      ) : (
        <p>검색 결과가 없습니다.</p>
      )}
      {hasNextPage && !isFetchingNextPage && <div className="mt-[20px]" ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultSeminar;
