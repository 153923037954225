import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Loading from "../../../component/Loading";
import ManageContent from "../../../component/management/ManageContent";
import { tokenState } from "../../../state";
import { api, Img, seminarDttm } from "../../../utils/util";
import Banner from "../../../component/advertisment/banner";

function SeminarPage() {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const q = urlParam.get("q") || "";
  const params = { page, q };

  const token = useRecoilValue(tokenState);
  const isLogin = token && token.user_type;

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["managementSeminarList", params],
    () =>
      api
        .get("/management/seminar/list", {
          params,
        })
        .then((res) => res.data),
  );

  if (pageInfoLoading || !pageInfo) return <Loading />;
  return (
    <div>
      <div className="wrapper">
        <div className="inner">
          <Banner page="seminar" name="pc_Seminar_top" />
        </div>
        <section className="seminar_pick" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">모어덴 PICK 세미나</span>
              </div>
            </article>

            <div className="list__wrap">
              <ul className="flex flex-wrap justify-between">
                {pageInfo.recommend &&
                  pageInfo.recommend.slice(0, 8).map((item, idx) => {
                    return (
                      <li className="mb-5" key={idx}>
                        <Link to={`/management/seminar/${item.bid}`} className="box-wrap">
                          <article className="seminar_pick_box">
                            <div className="img_box">
                              <img src={Img(item.thumbnail)} alt="" />
                            </div>
                            <div className="info-box">
                              <div className="top">
                                <div className="label-box">{item.topic}</div>
                                <div className="subject-box">{item.title}</div>
                              </div>
                              <div className="bottom">
                                <div className="location">
                                  <img
                                    className="lo"
                                    src={Img("", "img/ico-pin.svg")}
                                    alt="location"
                                  />
                                  <div className="content">{item.location1}</div>
                                </div>
                                <div className="date_score">
                                  <img
                                    src={Img("", "img/ico-calendar-event.svg")}
                                    alt="date_score"
                                  />
                                  <div className="content">
                                    <div className="date">
                                      {seminarDttm(item.start_date)}~ {seminarDttm(item.end_date)} |
                                      보수교육점수 <span className="black">{item.point}점</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </article>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
              <article style={{ marginTop: "60px" }} className="paging">
                {isLogin && (
                  <Link to={`/management/seminar/write`} className="write-btn">
                    <i className="ic ic-write"></i>
                    <span className="txt">글쓰기</span>
                  </Link>
                )}
              </article>
            </div>
          </div>
        </section>
        <section className="magazine__list" style={{ marginTop: "-14px", marginBottom: "136.5px" }}>
          <div className="inner">
            <div className="explain2_seminar">세미나 글 작성은 회원가입 후 가능합니다.</div>
            <ManageContent pageInfo={pageInfo} current="seminar" pages={pageInfo.pages} />
          </div>
        </section>
      </div>
    </div>
  );
}

export default SeminarPage;
