import { Schema } from "prosemirror-model"
import { keymap } from "prosemirror-keymap"
import { history } from "prosemirror-history"
import { gapCursor } from "prosemirror-gapcursor"
import { dropCursor } from "prosemirror-dropcursor"
import { baseKeymap, chainCommands } from "prosemirror-commands"
import { inputRules as buildInputRules } from 'prosemirror-inputrules'

import { CoreShortkeys } from "../extensions/coreShortkeys"
import { Doc, HardBreak, Paragraph, Text } from "../extensions"
import { createSchemaFromExtensions } from "../utils/prosemirror"

/**
 * Extension 을 schema, plugin, input rule 등으로 분리합니다.
 */
export class ExtensionManager {
  /**
   * @param {import('./extension').Extension[]} extensions
   * @param {import('./editor').IntegrationEditor} editor 
   */
  constructor(extensions = [], editor) {
    this.addedExtensions = extensions
    this.extensions = ExtensionManager.Resolve.call(this)
    this.editor = editor
    this.schema = this._createSchema()
  }

  static Resolve() {
    const coreExtensions = [Doc, Text, Paragraph, HardBreak, CoreShortkeys]

    return coreExtensions.concat(this.addedExtensions)
  }

  get plugins() {
    const corePlugins = [history(), gapCursor(), dropCursor(), keymap(baseKeymap)]
    const inputRules = []
    const shortKeys = []
    
    const allPlugins = this.extensions.map(extension => {
      const plugins = []
      const thisValue = {
        editor: this.editor,
        schema: this.schema,
        name: extension.name,
      }

      /**
       * 플러그인을 추가합니다.
       */
      if (extension.addPlugins) {
        plugins.push(...extension.addPlugins.call(thisValue))
      }

      /**
       * 단축키를 추가합니다.
       */
      if (extension.addKeyboardShortcuts) {
        shortKeys.push(...Object.entries(extension.addKeyboardShortcuts.call(thisValue)))
      }

      /**
       * 순회하는 김에 InputRule 을 추가합니다.
       */
      if (extension.addInputRules) {
        inputRules.push(...extension.addInputRules.call(thisValue))
      }

      return plugins
    })

    // 중복되는 키는 chainCommands 로 처리합니다.
    const chainedShortkeys =  shortKeys.reduce((acc, [key, command]) => {
      if (acc[key]) {
        acc[key].push(command)
        return acc
      }

      acc[key] = [command]
      return acc
    }, {})

    Object.keys(chainedShortkeys).forEach(key => {
      chainedShortkeys[key] = chainCommands(...chainedShortkeys[key])
    })

    return [
      keymap(chainedShortkeys),
      ...corePlugins.concat(allPlugins.flat()),
      buildInputRules({ rules: inputRules }),
    ]
  }

  /**
   * @returns {{ [command: string]: import('prosemirror-state').Command }}
   */
  get commands() {
    return this.extensions
    .filter(extension => extension.addCommands)
    .reduce((commands, extension) => {
        const thisValue = {
          editor: this.editor,
          schema: this.schema,
          name: extension.name,
        }

        return { ...commands, ...extension.addCommands.call(thisValue) }
      }, {})
  }

  get nodeViews() {
    const nodeViewEntries = this.extensions
      .filter(extension => extension.addNodeView)
      .map(extension => {
        const thisValue = {
          editor: this.editor,
          schema: this.schema,
          name: extension.name,
        }

        return [extension.name, extension.addNodeView.call(thisValue)]
      })
      
    return Object.fromEntries(nodeViewEntries)
  }

  /**
   * @private
   */
  _createSchema() {
    return createSchemaFromExtensions(this.extensions)
  }
}
