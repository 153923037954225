import Nickname from "component/user/nickname";
import { Img, commentDttm, cls } from "../../utils/util";
import CommentMark from "./CommentMark";
import ZoomImg from "component/ZoomImg";
import CommentView from "./CommentView";

// 댓글 내용, 작성자 정보, 작성 시간 등을 포함한 HTML 요소들을 담당하는 컴포넌트
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CommentItem = ({ item, isBestComment, setNewReply, getLikeInfo, likeClicked, children }) => {
  const isReply = item.cid ? true : false;
  const replyComment = () =>
    setNewReply({
      cid: isReply ? item.cid : item.uuid, //대댓글의 cid는 해당 댓글의 uuid
      content: null,
      image: null,
      target: item.uuid,
      targetNick: item.unick,
    });
  const isDeleted = item.deleted || item.deleted_at;
  return (
    <div
      className={cls(
        "relative flex flex-col border-solid border-b-[1px] border-gray-200 w-full p-3",
        isReply ? "pl-6" : "pl-3",
      )}
    >
      <div>
        {isBestComment && <CommentMark type="best" />}
        {item.writer_selected_at && <CommentMark type="writer" />}
        {item.admin_selected_at && <CommentMark type="admin" />}
      </div>
      <div className="relative text-left pr-3 w-fit">
        {!isDeleted && (
          <span
            className={cls(
              item.iswriter ? "text-primary-800" : "text-gray-600",
              "text-[15px] font-medium relative tracking-tighter",
            )}
          >
            <Nickname
              uid={item.uid}
              unick={item.unick}
              level={item.level}
              isOwner={item.is_owner}
              isDetail
            />
          </span>
        )}
      </div>
      <div className="flex flex-col justify-between w-[inherit]">
        <div className="flex justify-between pt-3">
          <div className="comment-content w-full break-all text-[15px] leading-[1.3] tracking-tight">
            {item.reported ? (
              "다수의 신고로 인해 블라인드 처리되었습니다"
            ) : (
              <CommentView content={item.content} />
            )}
          </div>
          <div className="absolute right-3 top-3 text-right text-[13px] whitespace-nowrap text-gray-300">
            {commentDttm(item.reg_dttm)}
          </div>
        </div>
        <ZoomImg>
          {item.img_link && !item.reported && (
            <div className="max-w-[80%] img-box mt-5">
              <img src={Img(item.img_link)} alt="이미지" />
            </div>
          )}
        </ZoomImg>
        {!isDeleted && (
          <div className="flex items-center text-[13px] pt-7">
            <button
              onClick={replyComment}
              className="lg:bg-white lg:border-[1px] lg:border-solid lg:border-gray-300 rounded-sm lg:px-2 tracking-tighter h-[23px] mr-3"
            >
              <i className="ii ii-message-line-outline mr-1 relative top-[-1px]" />
              대댓글
            </button>
            <button
              className={cls(
                getLikeInfo(item.uuid) ? "text-primary-800" : "text-black",
                "lg:bg-white lg:border-[1px] lg:border-solid lg:border-gray-300 rounded-sm lg:px-2 tracking-tighter h-[23px] mr-3",
              )}
              onClick={(e) => {
                e.stopPropagation();
                likeClicked(item, getLikeInfo(item.uuid));
              }}
            >
              <i
                className={cls(
                  getLikeInfo(item.uuid)
                    ? "ii ii-like text-primary-800"
                    : "ii ii-like-outline text-gray-600",
                  "mr-1 relative top-[-1px]",
                )}
              />
              추천 {item.like}
            </button>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentItem;
