import { useState, useEffect } from "react";
import { api, htmlTextContent, selectStyles } from "../../utils/util";
import { default as Select } from "react-select";

const SimpleCard = ({ card }) => {
  const { problem, answer } = card;
  const [clickAns, setClickAns] = useState(false);

  useEffect(() => setClickAns(false), [card]);

  return (
    <section style={{ marginTop: 20 }} className="festival__hot__board">
      <div className="inner">
        <article className="festival__board tong__board">
          <div className="ttsubject-box">{htmlTextContent(problem)}</div>

          <div className="answer_check__container">
            <div className="answer__container">
              <div className="answer_check" onClick={() => setClickAns(!clickAns)}>
                {clickAns ? "정답 가리기" : "정답 확인"}
              </div>
              {clickAns && <div className="answer">{htmlTextContent(answer)}</div>}
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

const TongtestCard = () => {
  const [select, setSelect] = useState(null);
  const [cardSet, setCardSet] = useState(null);
  const [cards, setCards] = useState(null);

  useEffect(() => {
    api.get("/tongtest/card_list").then((res) => {
      setCardSet(res.data.card_set);
    });
  }, []);

  useEffect(() => {
    select &&
      api.get(`/tongtest/card_set/${select.value}`).then((res) => {
        setCards(res.data.cards);
      });
  }, [select]);

  return (
    <>
      <article style={{ marginTop: 100 }} className="section__tit__box">
        <div className="tit-box">
          <span className="tit-txt">통치자료</span>
        </div>
      </article>
      <div className="wrapper">
        <section className="form__layout1 section-first-t">
          <div className="inner">
            <div className="row">
              <div className="subject-t">열람할 암기법을 선택해주십시오.</div>
              {cardSet && (
                <div className="con">
                  <Select
                    value={select}
                    onChange={(option) => {
                      setSelect(option);
                    }}
                    styles={selectStyles}
                    options={cardSet.map((item) => {
                      return { label: item.name, value: item.sid };
                    })}
                    isSearchable={false}
                    placeholder="암기법을 선택해주세요"
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        {cards && (
          <div className="ready tong__box__container">
            <article className="section__tong__box">
              <img
                className="ttest_logo"
                src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/ttest_logo.svg`}
                alt="이미지"
              />
              <div className="tong__box__text">모어덴 통합치의학 암기법</div>
              <div className="wrapper">
                {cards.map((card, idx) => (
                  <SimpleCard card={card} key={idx} />
                ))}
              </div>
            </article>
          </div>
        )}
      </div>
    </>
  );
};

export default TongtestCard;
