import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import BoardReportPopup from "../../component/board/BoardReportPopup";
import BoardTrashPopup from "../../component/board/BoardTrashPopup";
import DetailBoardTitle from "../../component/board/detail/DetailBoardTitle";
import Loading from "../../component/Loading";
import { api, watermark, Img, writeDayYtoS } from "../../utils/util";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import ForumDetailContent from "./ForumDetailContent";
import BlockAlert from "../../component/BlockAlert";
import ForumRanking from "../../component/forum/ForumRanking";
import ForumArticleBox from "../../component/forum/ForumArticleBox";
import GroupBuyingRecommend from "../groupBuying/GroupBuyingRecommend";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Banner from "../../component/advertisment/banner";
import ForumListUI from "../../component/forum/ForumListUI";
import Nickname from "../../component/user/nickname";
import CommentList from "../../component/comment/CommentList";
import SubscribeButton from "component/user/subscribeButton";

function ForumDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const state = location.state;
  const bid = parseInt({ ...useParams() }.bid || 0);
  const [reportPopup, setReportPopup] = useState({ toggle: false });
  const [blockAlert, setBlockAlert] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const token = useRecoilValue(tokenState);
  if (bid === 0) {
    navigate(-1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [bid]);

  const { data: content, isLoading: contentLoading } = useQuery(
    ["forumArticle", bid],
    () => api.get(`/forum/article/${bid}`).then((res) => res.data),
    {
      onError: (err) => {
        err.response?.data?.msg && alert(err.response.data.msg);
        navigate(-1);
      },
    },
  );

  const { data: pickInfo } = useQuery("forumListPick", () =>
    api.get("/forum/list_pick").then((res) => res.data),
  );

  const modifyFunc = () => {
    if (content.forum?.contentJson) {
      navigate(`/forum/modify/${content.forum.bid}`, {
        state: { ...state, ...content },
      });
      return;
    } else {
      navigate(`/forum/modify/${content.forum.bid}?editor=froala`, {
        state: { ...state, ...content },
      });

      return;
    }
  };

  const reportComment = (item) =>
    setReportPopup({
      category: "forum_comment",
      uuid: item.uuid,
      toggle: true,
    });

  const directDeleteArticle = () => {
    api.delete(`/forum/delete/${content.forum.bid}`).then(() => navigate(`/forum`));
  };

  const articleLike = useMutation(({ bid, data }) => api.post(`/forum/like/${bid}`, data), {
    onSuccess: () => queryClient.invalidateQueries("forumArticle"),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const articleScrap = useMutation(({ bid, data }) => api.post(`/forum/scrap/${bid}`, data), {
    onSuccess: () => queryClient.invalidateQueries("forumArticle"),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const scrapClicked = async () => {
    const data = {
      scraped: Boolean(!content.scraped),
    };
    articleScrap.mutate({ bid, data });
  };

  const likeClicked = async (comment, curLiked) => {
    if (comment ? comment.ismine : content.ismine) {
      alert("내가 작성한 글은 추천할 수 없습니다.");
      return;
    }
    const cid = comment ? comment.uuid : null;
    const data = {
      cid: cid ?? null,
      liked: !curLiked,
    };
    articleLike.mutate({ bid, data });
  };

  if (contentLoading || !content) return <Loading />;
  return (
    <>
      <div className="wrapper">
        <section className="magazine__detail">
          {content && (
            <div className="inner">
              <DetailBoardTitle
                menu="forum"
                content={content}
                modifyFunc={modifyFunc}
                setDeletePopup={setDeletePopup}
              />
              {deletePopup && (
                <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
              )}
              <div
                className="detail__group"
                style={{
                  background: watermark(token),
                  backgroundRepeat: "space",
                }}
              >
                <div className="tit-box">
                  <div className="subject-box">
                    {content.forum.report_count >= 10 && <i className="reported" />}
                    {content.forum.admin_selected_at && (
                      <img src={Img("", `img/forum_pick_detail.png`)} className="picked" alt="" />
                    )}
                    {content.forum.reported
                      ? "다수의 신고로 블라인드 처리된 게시글입니다."
                      : content.forum.title}
                  </div>
                  <div className="flex justify-between items-center my-3 whitespace-nowrap">
                    <div className="flex items-center text-sm text-secondary-600 gap-2">
                      <Nickname
                        isDetail
                        uid={content.forum.writerUid}
                        unick={content.forum.unick}
                        level={content.forum.level}
                        isOwner={content.forum.is_owner}
                      />
                      <div>{writeDayYtoS(content.forum.reg_dttm)}</div>
                    </div>
                    <div className="flex gap-2">
                      <SubscribeButton uid={content.forum.writerUid} />
                    </div>
                  </div>
                </div>
                <div className="con-box divided">
                  <div className="left-content">
                    <ForumDetailContent
                      content={content}
                      likeClicked={likeClicked}
                      scrapClicked={scrapClicked}
                      setReportPopup={setReportPopup}
                    />
                    <Banner page="ForumDetail" name="pc_ForumDetail_middle" />
                    <CommentList
                      current="forum"
                      content={content}
                      bid={bid}
                      reportComment={reportComment}
                    />
                  </div>
                  <div className="right-content">
                    <GroupBuyingRecommend />
                    {pickInfo?.pick_forum && pickInfo?.hot_forum && (
                      <>
                        <ForumArticleBox articles={pickInfo.pick_forum} title="화제의 임상포럼" />
                        <ForumArticleBox
                          articles={pickInfo.hot_forum}
                          title="실시간 HOT 임상포럼"
                        />
                        <ForumRanking pageInfo={pickInfo} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <section className="magazine__list mt-[60px]" style={{ marginBottom: "136.5px" }}>
          <div className="inner">{content && <ForumListUI current="forum_detail" />}</div>
        </section>
      </div>
      {blockAlert && (
        <BlockAlert setBlockAlert={setBlockAlert} bid={content.forum.bid} isAnonymous={false} />
      )}
      {reportPopup.toggle && (
        <BoardReportPopup
          category={reportPopup.category}
          setReportPopup={setReportPopup}
          bid={reportPopup.uuid ? reportPopup.uuid : bid}
        />
      )}
    </>
  );
}

export default ForumDetail;
