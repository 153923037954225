import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { useRecoilValue } from "recoil";
import dayjs from "dayjs";

import KlassPurchaseButton from "./KlassPurchaseButton";

import { cls, diffFromDate, discountedRate, publicApi, totalTime } from "utils/util";
import { isOfflineClass } from "utils/marketUtil";
import { Course, Progress } from "types/klass";
import { tokenState } from "state";

type SidePanelProps = {
  course: Course;
  progress?: Progress;
  defaultCourseId?: string;
} & SidePanelActions;

interface SidePanelActions {
  scrollToCurriculum: () => void;
}

const SidePanel: React.FC<SidePanelProps> = ({
  course,
  progress,
  defaultCourseId,
  scrollToCurriculum,
}) => {
  const { id } = useParams();
  const token = useRecoilValue(tokenState);
  const queryClient = useQueryClient();

  const [remainingDate, setRemainingDate] = useState<any>(
    diffFromDate(progress?.expireAt, dayjs()),
  );
  const [extendPopup, setExtendPopup] = useState<boolean>(false);

  const courseHasFile = course?.file;

  const showExtendBtn =
    progress && // 수강중인 경우
    course?.isExtendable && // 툴즈 연장 버튼 노출 여부 설정
    !progress?.extendedTime && // 연장한 적이 없는 경우
    dayjs().isBefore(dayjs(progress.expireAt)) && // 수강종료일이 지나지 않은 경우
    dayjs(progress.expireAt).isBefore(dayjs(course?.learnEndAt)) && // 수강종료일이 교육종료일보다 크지 않은 경우
    dayjs(progress.expireAt).subtract(7, "days").isBefore(dayjs()); // 수강종료일이 7일 이하로 남은 경우

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingDate((cur: any) => cur.subtract(1, "second"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const lectureCount = () => {
    let count = 0;
    course.chapters.map((item) => (count += item.lectures.length));
    return count;
  };

  const closeExtendPopup = () => {
    setExtendPopup(false);
    queryClient.invalidateQueries(["classCourses", id, token]);
  };

  const lecturePeriod = () => {
    if (!course?.learnStartAt || !course?.learnEndAt) {
      return "";
    }
    if (dayjs(course.learnStartAt).year() === dayjs(course.learnEndAt).year()) {
      return (
        <>
          {dayjs(course.learnStartAt).format("YYYY년 MM월 DD일 (dd) HH:mm")}
          <br />~ {dayjs(course.learnEndAt).format("MM월 DD일 (dd) HH:mm")}
        </>
      );
    }
    return (
      <>
        {dayjs(course.learnStartAt).format("YYYY년 MM월 DD일 (dd) HH:mm")}
        <br />~ {dayjs(course.learnEndAt).format("YYYY년 MM월 DD일 (dd) HH:mm")}
      </>
    );
  };

  return (
    <div className="lg:w-[450px]">
      {extendPopup && (
        <div className="absolute w-full h-full">
          <div onClick={closeExtendPopup} className="popup-overlay dark"></div>
          <ExtendPopup
            closePopup={closeExtendPopup}
            expireAt={progress?.expireAt}
            learnEndAt={course?.learnEndAt}
          />
        </div>
      )}
      <div className="lg:mr-[1px] border-[1px] p-1 border-secondary-400 rounded-[6px]">
        <div className="px-4 space-y-5 lg:space-y-6 bg-[#FAFAFA] pt-4 lg:pt-6 pb-4 mb-6 lg:mb-0 lg:bg-white lg:pb-0">
          {course.lecturerNames?.length > 0 && (
            <div className="flex items-center lg:justify-between">
              <div className="w-1/3 text-gray-500 text-[15px] font-semibold">연자</div>
              <div className="text-gray-900 text-[15px] font-semibold w-2/3 lg:text-right">
                {course.lecturerNames.join(", ")}
              </div>
            </div>
          )}
          {!isOfflineClass(course) ? (
            <>
              <div className="flex items-center lg:justify-between">
                <div className="w-1/3 text-gray-500 text-[15px] font-semibold">총 강의 수</div>
                <div className="max-w-2/3 text-gray-900 text-[15px] font-semibold">
                  총 {lectureCount()}강
                </div>
              </div>
              <div className="flex items-center lg:justify-between">
                <div className="w-1/3 text-gray-500 text-[15px] font-semibold">총 강의시간</div>
                <div className="max-w-2/3 text-gray-900 text-[15px] font-semibold">
                  {totalTime(course.totalVideoLength)}
                </div>
              </div>
              <div className="flex items-center lg:justify-between">
                <div className="w-1/3 text-gray-500 text-[15px] font-semibold">수강기간</div>
                <div className="max-w-2/3 text-gray-900 text-[15px] font-semibold">
                  {course?.registrationValidDays}일
                </div>
              </div>
            </>
          ) : (
            <div className="flex items-center lg:justify-between">
              <div className="text-gray-500 w-[70px] shrink-0 text-[15px] font-semibold">
                강의 날짜
              </div>
              <div className="text-gray-900 text-[15px] font-semibold">{lecturePeriod()}</div>
            </div>
          )}
        </div>
        <div className="hidden lg:block px-4 my-5">
          <div className="w-full h-[2px] bg-gray-200" />
        </div>
        <div className="px-4 pb-3">
          <div className="flex items-center justify-between">
            <div className="text-secondary-900 text-[15px] font-semibold">정상가</div>
            {course.products[0]?.regularPrice && (
              <div className="text-secondary-600 text-[15px] font-medium">
                정가 {course.products[0]?.regularPrice.toLocaleString()}원
              </div>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="text-secondary-900 text-[15px] font-semibold">
              {!course.isFree &&
              discountedRate(course.products[0]?.regularPrice, course.products[0]?.salePrice) !== 0
                ? "할인가"
                : "판매가"}
            </div>
            {course.products.length > 0 && (
              <div className="text-primary-800 text-[26px] font-semibold">
                {discountedRate(course.products[0]?.regularPrice, course.products[0]?.salePrice) !==
                  0 &&
                  !course.isFree && (
                    <span className="text-alert mr-2">
                      {discountedRate(
                        course.products[0]?.regularPrice,
                        course.products[0]?.salePrice,
                      )}
                      %
                    </span>
                  )}
                {course.isFree ? "무료" : course.products[0]?.salePrice.toLocaleString() + "원"}
              </div>
            )}
            {course.products.length <= 0 && course.isFree && (
              <div className="text-primary-800 text-[26px] font-semibold">무료</div>
            )}
          </div>
        </div>
      </div>

      {courseHasFile && (
        <div
          className="flex justify-between bg-primary-100 p-[10px] lg:py-[8px] rounded-md mt-[20px] mx-4 lg:mx-0 cursor-pointer"
          onClick={() => scrollToCurriculum()}
        >
          <div className="flex items-center">
            <i className="ii ii-book-outline ii-weight-600 text-[20px] text-primary-600 mr-[6px]"></i>
            <span className="text-[15px]">강의 자료가 파일로 제공됩니다</span>
          </div>
          <button className="text-[13px] font-bold">보기</button>
        </div>
      )}

      <div
        className={cls(
          courseHasFile ? "lg:mt-[20px]" : "lg:mt-4",
          "px-4 lg:px-0 h-[64px] text-[25px] mt-5",
        )}
      >
        <KlassPurchaseButton
          course={course}
          progress={progress}
          defaultCourseId={defaultCourseId}
        />
      </div>
      {showExtendBtn && (
        <div>
          <div className="flex justify-between items-center px-[20px] py-[16px] bg-secondary-100 rounded-none lg:rounded-md mt-[16px]">
            <div className="text-[14px] font-semibold flex items-center">
              <i className="ii ii-info-circle-outline ii-weight-600 text-[16px] mr-1"></i>수강 만료{" "}
              <span className="text-primary-700 ml-1">
                {remainingDate.days() * 24 +
                  remainingDate.hours() * 1 +
                  ":" +
                  remainingDate.format("mm:ss")}{" "}
                남음
              </span>
            </div>
            <div
              className="text-[12px] font-bold cursor-pointer underline"
              onClick={() => setExtendPopup(true)}
            >
              수강권 연장하기
            </div>
          </div>
          <div className="hidden lg:flex text-[13px] font-normal text-secondary-500 mt-[9px] justify-center">
            *수강권 연장은 강의당 1회 사용 가능합니다. / 수강권 연장은 교육기간 내에서만 적용됩니다.
          </div>
          <div className="lg:hidden text-[13px] font-normal text-secondary-500 mt-[9px] flex flex-col justify-center px-[12px] mb-6">
            <div>*수강권 연장은 강의당 1회 사용 가능합니다.</div>
            <div>*수강권 연장은 교육기간 내에서만 적용됩니다.</div>
          </div>
        </div>
      )}
    </div>
  );
};

const ExtendPopup = ({
  closePopup,
  expireAt,
  learnEndAt,
}: {
  closePopup: () => void;
  expireAt: Date | undefined;
  learnEndAt: Date | undefined;
}) => {
  const { id } = useParams();
  const [isExtended, setIsExtended] = useState<boolean>(false);
  const extendDate = () => {
    // 수강종료일+7이 교육종료일을 넘어가는 경우에는 교육종료일까지 연장
    if (dayjs(expireAt).add(7, "days").isAfter(dayjs(learnEndAt))) {
      return dayjs(learnEndAt);
    }
    return dayjs(expireAt).add(7, "days");
  };

  const extend = async () => {
    try {
      await publicApi?.put(`/class/students/self/courses/${id}/progress`, {
        extendedTime: diffFromDate(extendDate(), dayjs(expireAt)).as("milliseconds"),
      });
      setIsExtended(true);
    } catch (e) {
      alert((e as any)?.response?.data?.message || "수강권 연장에 실패하였습니다.");
      closePopup();
    }
  };
  return (
    <article className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[510] w-5/6 lg:w-[514px] shadow-custom popup-addr rounded-md">
      {isExtended ? (
        <div className="rounded-lg bg-white px-6 py-8">
          <h1 className="text-[22px] lg:text-[25px] font-semibold mb-5">수강권이 연장되었습니다</h1>
          <div>수강 종료일은 {extendDate().format("YYYY년 M월 D일 H시 m분")}입니다.</div>
          <button
            onClick={closePopup}
            className="bg-primary-800 hover:bg-primary-900 w-full text-white rounded-md py-3 lg:py-0 lg:h-12 text-[20px] mt-[41px]"
          >
            확인
          </button>
        </div>
      ) : (
        <div className="rounded-lg bg-white px-6 py-8">
          <h1 className="text-[22px] lg:text-[25px] font-semibold mb-5">
            수강권을 연장하시겠습니까?
          </h1>
          <div>
            수강 연장은 강의당 1회만 가능합니다.
            <br />
            연장 후, 수강 종료일은 {extendDate().format("YYYY년 M월 D일 H시 m분")}입니다.
          </div>
          <div className="flex w-full mt-[41px] space-x-[7px] h-12 text-[20px]">
            <button
              onClick={closePopup}
              className="bg-white hover:bg-gray-50 text-secondary-800 border-[1px] border-solid border-secondary-200 w-1/2 rounded-md py-3 flex self-center justify-center"
            >
              취소
            </button>
            <button
              onClick={extend}
              className="bg-primary-800 hover:bg-primary-900 text-white w-1/2 rounded-md py-3 flex self-center justify-center"
            >
              확인
            </button>
          </div>
        </div>
      )}
    </article>
  );
};

export default SidePanel;
