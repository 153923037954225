import { Route, Routes } from "react-router-dom";
import LabPage from "./LabPage";
import LabTeefit from "./LabTeefit";
import LabDetail from "./LabDetail";

const Lab = () => {
  return (
    <Routes>
      <Route path="/*" element={<LabPage />} />
      <Route path="/teefit" element={<LabTeefit />} />
      <Route path="/:id" element={<LabDetail />} />
    </Routes>
  );
};

export default Lab;
