import SideMenu from "../../component/mypage/SideMenu";
import Loading from "../../component/Loading";
import { api, Img } from "../../utils/util";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { hospitalOptions } from "../../constants";
import { HospitalInfoPopup } from "component/recruit/chicruitPopup";
import { useState } from "react";
import { cls } from "utils/util";

function MyHospital() {
  const { data: mypageHospitalsState, isLoading: mypageHospitalsLoading } = useQuery(
    "mypageHospitals",
    () => api.get("/mypage/hospitals").then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  if (mypageHospitalsLoading) return <Loading />;

  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="myhospital" />
            <div className="con__layout">
              <section className="my__home" style={{ marginBottom: "76.5px" }}>
                <article className="section__tit__box" style={{ marginBottom: "23px" }}>
                  <div className="tit-box">
                    <span className="tit-txt">개원의 사업자 등록 정보</span>
                  </div>
                </article>
                {!!mypageHospitalsState && mypageHospitalsState.status === "registered" && (
                  <StatusMessage
                    status="registered"
                    iconClassName="ii ii-clock text-[17px] text-gray-600"
                    title="사업자 정보 확인중"
                    description="개원의 인증을 위해 사업자 정보를 확인중입니다. 정보가 올바를 경우, 곧 승인될 예정이니 잠시만 기다려주세요."
                  />
                )}
                {!!mypageHospitalsState && mypageHospitalsState.status === "rejected" && (
                  <StatusMessage
                    status="rejected"
                    iconClassName="ii ii-warning-circle text-[17px] text-red-600"
                    title="개원의 인증 실패"
                    description="등록된 회원정보와 사업자 정보가 일치하지 않아 개원의 인증에 실패했습니다. 자세한 내용은 고객센터를 통해 문의 부탁드립니다."
                  />
                )}
                {!!mypageHospitalsState &&
                  (mypageHospitalsState.status === "approved" ||
                    mypageHospitalsState.status === "temp_approved") && (
                    <StatusMessage
                      status="approved"
                      iconSrc="/img/owner_badge.svg"
                      title="개원의 사업자등록정보 등록 승인"
                      description="개원의 인증이 완료되었습니다. 오직 개원의만 이용할 수 있는 모어덴의 혁신적인 구인 서비스를 경험해보세요!"
                      link={{ to: "/recruit/chicruiting", text: "치크루팅 바로가기" }}
                    />
                  )}
                {mypageHospitalsState ? (
                  <HospitalInfoTable mypageHospitalsState={mypageHospitalsState} />
                ) : (
                  <NoHospitalInfo />
                )}
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MyHospital;

export const NoHospitalInfo = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);

  return (
    <div className="no_hospital_info">
      {popup && <HospitalInfoPopup setPopup={setPopup} />}
      <img
        src={Img("", "img/mypage_businessInfo.svg")}
        style={{ marginRight: 18 }}
        alt="사업자 등록증"
      ></img>
      <div className="flex column">
        <div>
          1. 하단 버튼을 눌러 병원 정보를 입력하시면 치크루팅(구인구직) 등 다양한 신규 서비스를
          이용하실 수 있습니다. <br />
          2. 인증을 위해 사업자등록증 첨부가 필요합니다. 식별이 불가능한 사업자등록증을 첨부하시는
          경우, 거절되실 수 있습니다. <br />
          3. 승인까지 영업일 기준 최대 3일이 소요될 수 있습니다.
        </div>
        <button
          className="direct"
          onClick={() => {
            setPopup(true);
          }}
        >
          지금 등록하러 가기
        </button>
      </div>
    </div>
  );
};

export const HospitalInfoTable = ({ mypageHospitalsState }) => {
  return (
    <article className="set__table" style={{ borderTop: "1px solid #ccc" }}>
      <table>
        <tbody>
          <tr>
            <th className="accent">병원명</th>
            <td>{mypageHospitalsState.name}</td>
          </tr>
          <tr>
            <th className="accent">요양기관종류</th>
            <td>
              {
                hospitalOptions.filter((item) => item.value === mypageHospitalsState?.type)[0]
                  ?.label
              }
            </td>
          </tr>
          <tr>
            <th className="accent">사업자등록번호</th>
            <td>{mypageHospitalsState.businessRegistrationNumber} </td>
          </tr>
          <tr>
            <th className="accent">주소</th>
            <td>
              {mypageHospitalsState.address} {mypageHospitalsState.detail_address}
            </td>
          </tr>
          <tr>
            <th className="accent">대표자명</th>
            <td>{mypageHospitalsState.owner}</td>
          </tr>
          <tr>
            <th className="accent">요양기관번호</th>
            <td>{mypageHospitalsState.healthInsuranceIdentificationNumber}</td>
          </tr>
          <tr>
            <th className="accent">이메일주소</th>
            <td>{mypageHospitalsState.email}</td>
          </tr>
        </tbody>
      </table>
    </article>
  );
};

const StatusMessage = ({ status, iconSrc, iconClassName, title, description, link }) => {
  const descriptionWithLineBreaks =
    status === "approved" ? description.replace(". ", ".<br />") : description;

  return (
    <div
      className={cls(
        "flex items-start self-stretch rounded-md mb-4",
        status === "approved" ? "gap-6 p-4 bg-primary-50" : "gap-2 p-3 bg-gray-100",
        status === "rejected" ? "bg-red-50" : "bg-gray-100",
      )}
    >
      {iconSrc && <img className="mt-[3px] h-[80px]" src={iconSrc} alt="" />}
      {iconClassName && <i className={iconClassName}></i>}
      <div className="font-PretendardVariable text-base">
        <div className="font-semibold text-[14px] mb-2">{title}</div>
        <div
          className="text-[13px] mb-2"
          dangerouslySetInnerHTML={{ __html: descriptionWithLineBreaks }}
        />
        {link && (
          <Link className="font-semibold text-[14px] text-primary-800" to={link.to}>
            {link.text}
            <i className="ii ii-chevron-right text-primary-800 ii-weight-600 -mt-1 ml-1"></i>
          </Link>
        )}
      </div>
    </div>
  );
};
