import React, { useState, useRef, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { cls } from "utils/util";
import { useRcaSwipeableDrawer } from "recoil/common";
import "./SwipeableDrawer.scss";

const SwipeableDrawer = () => {
  const { isOpenedDrawer, isHiddenDrawer, drawerContent, closeDrawer } = useRcaSwipeableDrawer();

  const initCoordinate = useRef(0);
  const dynamicYCoordinateRef = useRef(0);
  const [dynamicYCoordinate, setDynamicYCoordinate] = useState(0);

  const { component: ContentComponent, componentProps, drawerProps } = drawerContent;
  const {
    closeOnOverlayClick = true,
    propHeight = 180,
    closingThreshold = 0.4,
    isSwipeable = true,
    showTouchHandle = true,
  } = drawerProps;

  const handleOverlayClick = () => {
    if (!closeOnOverlayClick) return;
    closeDrawer();
  };

  const drawerHeight = useMemo(() => {
    return propHeight + 56;
  });

  const touchableAreaRef = useRef(null);

  useEffect(() => {
    if (!isOpenedDrawer) return;
    const touchableArea = touchableAreaRef.current;
    if (touchableArea) {
      touchableArea.addEventListener("touchstart", registerInitCoordinate, { passive: true });
      touchableArea.addEventListener("touchmove", dragDrawer, { passive: true });
      touchableArea.addEventListener("touchend", resetInitCoordinate, { passive: true });

      return () => {
        touchableArea.removeEventListener("touchstart", registerInitCoordinate);
        touchableArea.removeEventListener("touchmove", dragDrawer);
        touchableArea.removeEventListener("touchend", resetInitCoordinate);
      };
    }
  }, [isOpenedDrawer]);

  const registerInitCoordinate = (e) => {
    const { clientY } = e.touches[0];
    initCoordinate.current = clientY;
  };

  const dragDrawer = (e) => {
    if (!isSwipeable || !showTouchHandle) return;
    const { clientY } = e.touches[0];
    const deltaY = clientY - initCoordinate.current;
    if (deltaY < -20) return; // 위로 드래그 20px까지만 가능하도록

    dynamicYCoordinateRef.current = deltaY;
    setDynamicYCoordinate(deltaY);
  };

  const resetInitCoordinate = () => {
    const finalDynamicYCoordinate = dynamicYCoordinateRef.current;

    if (Math.abs(finalDynamicYCoordinate) >= drawerHeight * closingThreshold) {
      closeDrawer();
    }
    initCoordinate.current = 0;
    setDynamicYCoordinate(0);
  };

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--drawer-height", `-${drawerHeight}px`);

    // 스크롤 제어 로직
    root.style.overflow = isOpenedDrawer ? "hidden" : "";

    return () => {
      // 컴포넌트가 언마운트될 때 스크롤 해제
      root.style.overflow = "";
    };
  }, [isOpenedDrawer, drawerHeight]);

  if (!isOpenedDrawer || !drawerContent?.component) return null;

  return ReactDOM.createPortal(
    <div className="swipeable-drawer">
      <div className="drawer__overlay" onClick={handleOverlayClick}></div>
      <div
        className={cls("drawer__content", showTouchHandle && "touch-handle")}
        style={{ transform: `translateY(${Math.max(dynamicYCoordinate, -drawerHeight)}px)` }}
      >
        <div ref={touchableAreaRef} id="touchable-area"></div>
        <ContentComponent {...componentProps} />
      </div>
    </div>,
    document.body,
  );
};

export default SwipeableDrawer;
