import React, { useEffect, useState } from "react";
import SideMenu from "../../component/mypage/SideMenu";
import { api } from "../../utils/util";
import Loading from "../../component/Loading";
import NoticeContent from "../../component/mypage/NoticeContent";

function Notice(props) {
  const [notice, setNotice] = useState({ load: false });

  useEffect(() => {
    if (notice.load) return;
    api.get(`/mypage/noticeList`).then((res) => setNotice({ ...res.data, load: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!notice.load) return <Loading />;
  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="notice" />
            <div className="con__layout">
              <section className="" style={{ marginBottom: "113.5px" }}>
                <div className="inner">
                  <article className="section__tit__box">
                    <div className="tit-box">
                      <span className="tit-txt">공지사항</span>
                    </div>
                  </article>
                </div>
                <NoticeContent props={props} />
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Notice;
