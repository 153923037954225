const IEAlert = ({ continueIE }) => {
  return (
    <div className="container full">
      <section className="no_ex">
        <div className="whitebox">
          <div className="use_other">다른 브라우저를 사용해주세요.</div>

          <div className="download">
            <div
              className="down_box"
              onClick={() =>
                window.open("https://www.google.co.kr/chrome/", "_blank")
              }
            >
              <div className="down_img">
                <img
                  src={`${
                    import.meta.env.VITE_APP_PUBLIC_URL
                  }img/no_ex_chrome.png`}
                  alt="크롬"
                />
              </div>
              <div className="down_text">
                크롬 <b>다운로드</b>
              </div>
            </div>

            <div
              className="down_box"
              onClick={() =>
                window.open("https://www.microsoft.com/ko-kr/edge", "_blank")
              }
            >
              <div className="down_img">
                <img
                  src={`${
                    import.meta.env.VITE_APP_PUBLIC_URL
                  }img/no_ex_edge.png`}
                  alt="엣지"
                />
              </div>
              <div className="down_text">
                EDGE <b>다운로드</b>
              </div>
            </div>

            <div
              className="down_box"
              onClick={() => window.open("https://whale.naver.com/", "_blank")}
            >
              <div className="down_img">
                <img
                  src={`${
                    import.meta.env.VITE_APP_PUBLIC_URL
                  }img/no_ex_naver.png`}
                  alt="네이버웨일"
                />
              </div>
              <div className="down_text">
                네이버웨일 <b>다운로드</b>
              </div>
            </div>

            <div
              className="down_box"
              onClick={() =>
                window.open("https://www.mozilla.org/ko/firefox/new/", "_blank")
              }
            >
              <div className="down_img">
                <img
                  src={`${
                    import.meta.env.VITE_APP_PUBLIC_URL
                  }img/no_ex_fire.png`}
                  alt="파이어폭스"
                />
              </div>
              <div className="down_text">
                파이어폭스 <b>다운로드</b>
              </div>
            </div>
          </div>
        </div>

        <div className="no_explorer">
          <div className="ex_img">
            <img
              src={`${
                import.meta.env.VITE_APP_PUBLIC_URL
              }img/internet-explorer-logo.svg`}
              alt="익스플로러"
            />
          </div>
          <div className="context">
            모어덴은 현재 사용하시는 인터넷 익스플로러 브라우저를
            <br />
            완벽하게 지원하지 않습니다.
          </div>
          <div
            className="ex_go"
            onClick={() => {
              continueIE(true);
              sessionStorage.setItem("ie_closed", "true");
            }}
          >
            기능 제약이나 오류 가능성을 감안하고, 인터넷 익스플로러로
            접속합니다.
          </div>
        </div>

        <div className="no_ex_logo">
          <img
            src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/no_ex_logo.png`}
            alt="모어덴로고"
          />
        </div>
      </section>
    </div>
  );
};

export default IEAlert;
