import { useNavigate } from "react-router-dom";

const Popup = (props) => {
  // TODO : setConfirm 말고, confirmAction 같은 이름이...
  const {
    children,
    setPopup,
    title,
    setConfirm,
    confirmBtn = "확인",
    goBack,
    isProseMirror,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="popup-overlay" onClick={() => setPopup(false)}></div>
      <article className="popup popup-addr" style={{ display: "block" }}>
        <div className="popup-wrap">
          <div className="head">
            <div className="popup-tit">
              <i className="ic ic-logo"></i>
              <span className="txt">{title}</span>
            </div>
            <span onClick={() => setPopup(false)} className="close-btn"></span>
          </div>
          <div className="body">
            <article className="pop__addr">
              <div className="result__group">
                <div className="no-data">{children}</div>
              </div>
            </article>

            <div className="popup__menu">
              <button onClick={() => setPopup(false)} className="pop-menu-btn">
                취소
              </button>
              <button
                onClick={() => {
                  setConfirm();
                  if (!isProseMirror) {
                    goBack && navigate(-1);
                  }
                }}
                className="pop-menu-btn type1"
              >
                {confirmBtn}
              </button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Popup;

export const AlertPopup = (props) => {
  const { children, setConfirm, confirmBtn = "확인", goBack } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="popup-overlay" onClick={() => setConfirm()}></div>
      <article className="popup popup-addr" style={{ display: "block" }}>
        <div className="popup-wrap">
          <div className="body" style={{ borderRadius: "10px", padding: "50px 0" }}>
            <article className="pop__addr">
              <div className="result__group">
                <div className="no-data">{children}</div>
              </div>
            </article>
            <div className="popup__menu" style={{ border: "none", margin: 0, padding: 0 }}>
              <button
                onClick={() => {
                  setConfirm();
                  goBack && navigate(-1);
                }}
                className="pop-menu-btn type1"
              >
                {confirmBtn}
              </button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};
