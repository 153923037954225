import { Plugin, PluginKey } from "prosemirror-state";
import { Extension } from "../core/extension";
import { Paragraph } from "./paragraph";
import { Iframe } from "./iframe";
import { setIframePlatform } from "../utils/regex";

const EXTENSION_NAME = 'iframe_tooltip'

export const IFRAME_TOOLTIP_PLUGIN_KEY = new PluginKey(EXTENSION_NAME)

/**
 * iframe 링크 입력창을 표시합니다.
 *
 * @type {import('prosemirror-state').Command}
 */
const displayIframeTooltip = (state, dispatch, view) => {
  if (dispatch) {
    const tr = state.tr.setMeta(IFRAME_TOOLTIP_PLUGIN_KEY, 'open')
    dispatch(tr)
    return true
  }
}

class iframeTooltipPluginView {
  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   */
  constructor(view) {
    this.view = view
    this.CSS = 'iframe-link-input-dialog'

    this.dialog = document.createElement('div')
    this.dialog.classList.add(this.CSS)
    this.dialog.style.display = 'none'

    this.form = document.createElement('form')
    this.form.classList.add(`${this.CSS}__form`)
    this.form.addEventListener('submit', this._onSubmit.bind(this))

    this.input = document.createElement('input')
    this.input.classList.add(`${this.CSS}__input`)
    this.input.type = 'text'
    this.input.placeholder = 'https://www.example.com'

    this.button = document.createElement('button')
    this.button.classList.add(`${this.CSS}__button`)
    this.button.type = 'submit'
    this.button.textContent = '삽입'

    this.form.appendChild(this.input)
    this.form.appendChild(this.button)
    this.dialog.appendChild(this.form)
    view.dom.parentNode.appendChild(this.dialog)

    this.__hideDialog = this._hideDialog.bind(this)

    document.addEventListener('mousedown', this.__hideDialog)
  }

  /**
   *
   * @param {SubmitEvent} event
   */
  _onSubmit(event) {
    event.preventDefault()

    if (this.input.value === '') return
    if (
      !this.input.value.startsWith('http') ||
      !this.input.value.startsWith('https')
    ) {
      const errorMessage = this.view.state.schema.nodes[Paragraph.name].create(
        null,
        this.view.state.schema.text('올바른 URL을 입력해주세요.'),
      )
      this.view.dispatch(this.view.state.tr.replaceSelectionWith(errorMessage))
      return
    }

    const iframeNode = this.view.state.schema.nodes[Iframe.name].create({
      src: this.input.value,
      'data-platform': setIframePlatform(this.input.value),
    })
    this.view.dispatch(this.view.state.tr.replaceSelectionWith(iframeNode))
  }

  /**
   *
   * @param {MouseEvent} event
   */
  _hideDialog(event) {
    event.stopPropagation()
    if (event.target.closest(`.${this.CSS}`)) return
    this.dialog.style.display = 'none'
  }

  /**
   * @param {import('prosemirror-view').EditorView} view
   */
  update(view) {
    if (iframeTooltipPlugin.getState(view.state) === 'open') {
      this.dialog.style.display = 'block'
      this.input.value = ''
      this.input.focus()
      return
    }
    this.dialog.style.display = 'none'
  }

  destroy() {
    this.dialog.remove()
    document.removeEventListener('mousedown', this.__hideDialog)
  }
}

export const iframeTooltipPlugin = new Plugin({
  key: IFRAME_TOOLTIP_PLUGIN_KEY,
  state: {
    init() {
      return undefined // or 'open'
    },
    apply(tr) {
      const meta = tr.getMeta(this)
      if (meta) {
        return meta
      }
    },
  },
  view(editorView) {
    return new iframeTooltipPluginView(editorView)
  },
})

export const IframeTooltip = Extension.Create({
  name: EXTENSION_NAME,

  addPlugins() {
    return [iframeTooltipPlugin]
  },

  addCommands() {
    return {
      displayIframeTooltip,
    }
  }
})
