import { useLocation } from "react-router-dom";
import { api } from "../../utils/util";
import ForumRanking from "../../component/forum/ForumRanking";
import ForumArticleBox from "../../component/forum/ForumArticleBox";
import { useQuery } from "react-query";
import ForumListUI from "../../component/forum/ForumListUI";
// import Banner from "../../component/Banner";
import Banner from "component/advertisment/banner";

function ForumPage() {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);

  const { data: pickInfo, isLoading: pickInfoLoading } = useQuery("forumListPick", () =>
    api.get("/forum/list_pick").then((res) => res.data),
  );

  return (
    <div className="wrapper">
      <section className="layout__half">
        <div className="inner flex justify-between">
          {!urlParam.has("topic") && !pickInfoLoading && (
            <>
              <ForumArticleBox articles={pickInfo.pick_forum} title="화제의 임상포럼" />
              <ForumArticleBox articles={pickInfo.hot_forum} title="실시간 HOT 임상포럼" />
              <ForumRanking pageInfo={pickInfo} />
            </>
          )}
        </div>
        <div className="inner" style={{ marginBottom: "54px" }}>
          <Banner name="pc_Forum_middle" page="Forum" height="full" />
        </div>
        <section className="magazine__list" style={{ marginBottom: "136.5px" }}>
          <div className="inner">
            <ForumListUI current="forum_page" />
          </div>
        </section>
      </section>
    </div>
  );
}

export default ForumPage;
