import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../component/Loading";
import LoanPopup from "../../../component/management/LoanPopup";
import { api, scrollTopList, writeDayYtoS, StaticImg, resizedImg } from "../../../utils/util";
import ReactPaginate from "react-paginate";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../../state";
const LoanHana = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const [popup, setPopup] = useState(false);
  const param = new URLSearchParams(location.search);
  const page = param.get("page") || 1;
  const scrollRef = useRef(null);

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["boardList34", page],
    () => api.get(`/board/list/34`, { params: { page: page } }).then((res) => res.data),
    {},
  );

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    navigate(`/management/loan?${param}`);
  };

  const handleClick = () => {
    if (token.user_type !== "doctor") {
      window.alert("의사회원만 작성 가능합니다");
    }
    scrollRef.current && scrollTopList(scrollRef);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (pageInfoLoading) return <Loading />;

  return (
    <section>
      {popup && <LoanPopup setPopup={setPopup} txt="010-2983-2703 (한정구 지점장)" />}
      <div>
        <div className="inner">
          <div className="top-article">
            <div className="loan-btn-wrap">
              <div className="txt">
                치과의사 대출한도
                <br />
                <span>최대 5억원</span>
              </div>
              <div className="flex justify-center">
                <button
                  className="flex shadow-md rounded-md w-[140px] h-[32px] items-center"
                  onClick={() => setPopup(true)}
                >
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      marginLeft: "8px",
                      backgroundImage: 'url("/img/phone.svg")',
                      backgroundSize: "cover",
                      color: "#333",
                    }}
                  ></div>
                  <div className="ml-3 font-medium">전화 상담하기</div>
                </button>
                {/* <button className="btn-inner-flex">
                  <div className="icon-pen"></div>
                  <div className="btn-txt" onClick={handleClick}>
                    문의글 남기기
                  </div>
                </button> */}
              </div>
            </div>
            <div className="pig-image"></div>
          </div>
          <div className="bottom-article">
            <img
              src={resizedImg(
                "https://images.moreden.co.kr/img/5_240412_DM_1_0ac7d651c6.png",
                1500,
              )}
              alt=""
            />
            <img
              src={resizedImg(
                "https://images.moreden.co.kr/img/5_240412_DM_2_42ce0329cf.png",
                1500,
              )}
              alt=""
            />
          </div>

          {/*           
          <div className="loan-page-title-txt">문의하기</div>

          <article className="loan-paging">
            {token.user_type === "doctor" && (
              <Link
                to={`/management/loan/write/${pageInfo.cate_cd}`}
                className="write-btn"
                style={{ bottom: "30px" }}
              >
                <i className="ic ic-write"></i>
                <span className="txt">글쓰기</span>
              </Link>
            )}
          </article>

          <article className="list__table2" ref={scrollRef}>
            <div className="list">
              <div className="list-head">
                <div className="w10p left">구분</div>
                <div className="w50p left">제목</div>
                <div className="w10p left">작성자</div>
                <div className="w15p">작성일시</div>
                <div className="w5p">조회</div>
              </div>

              {pageInfo.board?.map((item, i) => {
                return (
                  <Link
                    className="width-max"
                    key={item.bid}
                    to={`/management/loan/article/${item.bid}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="slim content">
                      <div className="w10p left">
                        <span className="left-hospital">문의</span>
                      </div>
                      <div className="w50p left">
                        {item.title} [{item.comment}]
                      </div>
                      <div className="w10p left">
                        <span>{token.user_nickname === item.unick ? item.unick : "익명"}</span>
                      </div>
                      <div className="w15p">
                        <span>{writeDayYtoS(item.reg_dttm)}</span>
                      </div>
                      <div className="w5p">
                        <span>{item.view}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </article>

          {pageInfo.page && (
            <article className="paging">
              <ReactPaginate
                previousLabel="←"
                previousLinkClassName="before"
                nextLabel="→"
                nextLinkClassName="next"
                breakLabel="..."
                pageCount={pageInfo.pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                pageLinkClassName="num"
                activeClassName=""
                activeLinkClassName="current"
                onPageChange={onPageClick}
                forcePage={pageInfo.page - 1}
              />
            </article>
          )} */}
        </div>
      </div>
    </section>
  );
};

export default LoanHana;
