import { Routes, Route } from "react-router-dom";
import ClassPage from "./ClassPage";
import ClassDetail from "./ClassDetail";
import ClassLecture from "./ClassLecture";
import ClassWrite from "./ClassWrite";
import ChannelTalk from "../../component/ChannelTalk";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";

const Class = () => {
  const token = useRecoilValue(tokenState);

  return (
    <section className="relative min-h-[100vh]">
      <Header current="class" />
      <article className="pt-10 pb-64">
        {token && token.uid ? (
          <Routes>
            <Route path="/modify/:id/*" element={<ClassWrite />} />
            <Route path="/write/*" element={<ClassWrite />} />
            <Route path="/lecture/:lid/*" element={<ClassLecture />} />
            <Route path="/:cid/*" element={<ClassDetail />} />
            <Route path="/*" element={<ClassPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/:cid/*" element={<ClassDetail />} />
            <Route path="/*" element={<ClassPage />} />
          </Routes>
        )}
      </article>
      <div className="hidden lg:block">
        <Footer />
      </div>
      <ChannelTalk />
    </section>
  );
};

export default Class;
