const BlockPopup = ({ setPopup, confirm }) => {
  return (
    <>
      <div className="popup-overlay" onClick={() => setPopup({ open: false })}></div>
      <article className="block-popup-box">
        <div className="popup-false-btn" onClick={() => setPopup({ open: false })}></div>
        <div className="txt">선택한 사용자를 차단 해제하시겠습니까?</div>
        <div className="btn-wrap">
          <button
            className="confirm"
            onClick={() => {
              confirm();
              setPopup({ open: false });
            }}
          >
            확인
          </button>
          <button className="cancel" onClick={() => setPopup({ open: false })}>
            취소
          </button>
        </div>
      </article>
    </>
  );
};

export default BlockPopup;
