import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { tokenState } from "../../state";
import { api, tokenUpdateInApiOption } from "../../utils/util";
import Loading from "../../component/Loading";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

const SendAppleToken = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [, setToken] = useRecoilState(tokenState);
  const queryClient = useQueryClient();

  const doLogin = (data) => {
    localStorage.clear();
    localStorage.setItem("token", JSON.stringify(data));
    queryClient.clear();
    tokenUpdateInApiOption(data.access_token);
    return data;
  };

  useEffect(() => {
    // Todo: 애플 토큰 보내고 verifyStatus 받아오기
    (async () => {
      const data = {
        code: state.authorization.code,
        id_token: state.authorization.id_token,
      };
      const verifyRes = await api.post("/user/verifyAppleToken", data);
      const verifyStatus = verifyRes.data.status;
      const verifyToken = verifyRes.data.token;

      if (verifyStatus === "login") {
        setToken({ ...doLogin(verifyRes.data) });
        if (!verifyRes.data.user_type) {
          navigate("/verifyPending", { replace: true });
          return;
        }
        navigate(state.pathname);
      }

      if (verifyStatus === "sync") {
        setToken({ ...doLogin(verifyRes.data) });
        navigate("/syncAccount", {
          state: {
            loginType: "apple",
            appleToken: state.authorization.id_token,
            nickname: verifyRes.data.user_nickname,
          },
        });
      }

      if (verifyStatus === "failed") {
        navigate("/phoneVerify", {
          state: { appleToken: state.authorization.id_token, loginType: "apple" },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return <Loading />;
};

export default SendAppleToken;
