import CopyToClipboard from "react-copy-to-clipboard";
import { Img, api, StaticImg, cls, selectStyles } from "../../../utils/util";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { default as Select } from "react-select";
// import { Loading } from "component/Loading";
import LoanPopup from "../../../component/management/LoanPopup";
import { useState } from "react";
import dayjs from "dayjs";

const LabDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam = new URLSearchParams(location.search);
  const param = parseInt(urlParam.get("tab")) || 0;
  const id = useParams().id;
  const [popup, setPopup] = useState(false);

  const { data: adsMain, isLoading: adsMainLoading } = useQuery(
    `adsMain${id}`,
    () =>
      api
        .get(`/advertisements?advertisement=dentallab&name=${id}&component=main`)
        .then((res) => res.data[0]),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const { data: adsProduct, isLoading: adsProductLoading } = useQuery(
    `adsProduct${id}`,
    () =>
      api
        .get(`/advertisements?advertisement=dentallab&name=${id}&component=product`)
        .then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const popupData = {
    bellybutton: {
      txt: "010-5886-5842 (대표번호)",
    },
  };

  if (adsProductLoading | adsMainLoading) return <></>;

  return (
    <div className="w-[1200px] mx-auto">
      <article className="flex justify-between mb-5">
        <div className="mt-10 text-[20px] tracking-tighter font-semibold animate-fadedown">
          {adsMain.title}
        </div>
        {adsProduct.length > 1 && (
          <div className="w-[500px]">
            <Select
              onChange={(option) => {
                navigate(`/expo/${id}?tab=${option.value}`, { replace: true });
              }}
              styles={selectStyles}
              options={adsProduct.map((item, idx) => {
                return { label: item.title, value: idx };
              })}
              value={{ label: adsProduct[param].title, value: param }}
              isSearchable={false}
              defaultValue={{ label: adsProduct[0].title, value: 0 }}
            />
          </div>
        )}
      </article>
      {adsProduct[param]?.link && (
        <>
          <div className="flex h-[400px]">
            <iframe
              className="w-full"
              src={adsProduct[param] && `https://www.youtube.com/embed/${adsProduct[param].link}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="flex justify-between items-center mt-5">
            <b className="text-[26px]">{adsProduct[param]?.title}</b>
            <div className="text-[18px] text-gray-600">
              {dayjs(adsProduct[param]?.createdDate).format("YYYY-MM-DD")}
            </div>
          </div>
        </>
      )}
      {popup && <LoanPopup setPopup={setPopup} txt={popupData[id].txt} />}

      <div className="flex justify-between items-center border-b-[1px] border-solid border-gray-600 mt-5 pb-5 mb-5">
        <div className="flex items-center">
          <a
            className="flex items-center font-semibold text-[15px] mr-5"
            href={adsMain.content.homepage_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-[70px] mr-3" src={adsMain.content.sponsor_logo} alt="로고" />
            {adsMain.content.sponsor_name}
          </a>
          <button
            className="p-2 rounded-md shadow-list text-center text-[16px] h-[40px]"
            onClick={() => setPopup(true)}
          >
            <i class="ii ii-phone-call-outline relative top-[-1px]"></i>전화 상담하기
          </button>
        </div>
        <div className="flex h-[35px]">
          <a
            className="py-1 px-2 mr-3 border-[1px] border-solid border-gray-300 rounded-sm text-[13px] flex items-center"
            href={adsMain.content.homepage_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="ii ii-home-outline mr-1" />
            홈페이지
          </a>
          {navigator.share ? (
            <button
              className="py-1 px-2 border-[1px] border-solid border-gray-300 rounded-sm text-[13px] flex items-center"
              onClick={() =>
                navigator.share({
                  title: `[모어덴 - ${adsMain.content.sponsor_name} 상영관]`,
                  text: adsProduct[param].title,
                  url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                })
              }
            >
              <i class="ii ii-export mr-1" /> 공유
            </button>
          ) : (
            <CopyToClipboard
              text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
              onCopy={() => alert("링크를 복사하였습니다")}
            >
              <button className="py-1 px-2 border-[1px] border-solid border-gray-300 rounded-sm text-[13px] flex items-center">
                <i class="ii ii-export mr-1" /> 공유
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>
      <div className="megagen-cf">
        {id === "megagen" && (
          <>
            <img
              className="w-[400px] mx-auto my-10 big_logo"
              src={StaticImg("logos/companies/logo_megagen3.png")}
              alt=""
            />
            <div className="embed__video-wrap">
              <iframe
                src={"https://www.youtube.com/embed/tYj4z9hegCk"}
                title="YouTube video player"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </>
        )}
        <div className="flex flex-wrap">
          {adsProduct[param]?.content?.map((item) => {
            return (
              <a
                className={cls(id === "purgo" ? "max-w-[600px]" : "")}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Img(item.pcImage)} alt="" />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LabDetail;
