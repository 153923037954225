import "./moreden.variables.scss";

/**
 * 글자색에 적용될 CSS 변수 목록
 */

export const textColors = [
  ["text-color-black", "var(--black, #000000)"],
  ["text-color-blue-gray-700", "var(--blue-gray-700, #515257)"],
  ["text-color-blue-gray-100", "var(--blue-gray-100, #F1F3F8)"],
  ["text-color-deep-purple-900", "var(--deep-purple-900, #3A2D73)"],
  ["text-color-deep-purple-800", "var(--deep-purple-800, #4A25AA)"],
  ["text-color-deep-purple-300", "var(--deep-purple-100, #DED1FF)"],
  ["text-color-blue-900", "var(--blue-900, #0B52BD)"],
  ["text-color-blue-700", "var(--blue-700, #1066E9)"],
  ["text-color-blue-100", "var(--blue-100, #D1E6FF)"],
  ["text-color-teal-900", "var(--teal-900, #08976E)"],
  ["text-color-teal-700", "var(--teal-700, #14AD8A)"],
  ["text-color-teal-100", "var(--teal-100, #BBEBE1)"],
  ["text-color-light-green-900", "var(--light-green-900, #008E0E)"],
  ["text-color-light-green-700", "var(--light-green-700, #00C714)"],
  ["text-color-light-green-100", "var(--light-green-100, #CAFFD1)"],
  ["text-color-yellow-900", "var(--yellow-900, #FE9800)"],
  ["text-color-yellow-700", "var(--yellow-700, #FFC700)"],
  ["text-color-yellow-100", "var(--yellow-100, #FFF1A6)"],
  ["text-color-pink-900", "var(--pink-900, #FF187A)"],
  ["text-color-pink-700", "var(--pink-700, #FF60A3)"],
  ["text-color-pink-100", "var(--pink-100, #FFDEEC)"],
  ["text-color-red-900", "var(--red-900, #AB2617)"],
  ["text-color-red-700", "var(--red-700, #C93221)"],
  ["text-color-red-100", "var(--red-100, #FABAB3)"],
];

/**
 * 하이라이트에 적용될 CSS 변수 목록
 */
export const textHighlightColors = [
  ["highlight-color-blue-gray", "var(--blue-gray-50, #FBFCFF)"],
  ["highlight-color-deep-purple", "var(--deep-purple-50, #F5F4FF)"],
  ["highlight-color-blue", "var(--blue-50, #E9F3FF)"],
  ["highlight-color-teal", "var(--teal-50, #E4F7F3)"],
  ["highlight-color-light-green", "var(--light-green-50, #E1FFE5)"],
  ["highlight-color-yellow", "var(--yellow-50, #FFF6C7)"],
  ["highlight-color-pink", "var(--pink-50, #FFEAF3)"],
  ["highlight-color-red", "var(--red-50, #FCE8E6)"],
];

/**
 * 테이블 셀 배경색에 적용될 CSS 변수 목록
 */
export const tableCellBackgroundColors = [
  ["cell-color-blue-gray-400", "var(--blue-gray-400, #BFC2C3)"],
  ["cell-color-blue-gray-200", "var(--blue-gray-200, #E1E4EC)"],
  ["cell-color-blue-gray-50", "var(--blue-gray-50, #FBFCFF)"],
  ["cell-color-deep-purple-400", "var(--deep-purple-400, #CAB6FF)"],
  ["cell-color-deep-purple-200", "var(--deep-purple-200, #E6DBFF)"],
  ["cell-color-deep-purple-50", "var(--deep-purple-50, #F5F4FF)"],
  ["cell-color-blue-400", "var(--blue-400, #5CA6FF)"],
  ["cell-color-blue-200", "var(--blue-200, #A8CFFF)"],
  ["cell-color-blue-50", "var(--blue-50, #E9F3FF)"],
  ["cell-color-teal-400", "var(--teal-400, #3DC6AB)"],
  ["cell-color-teal-200", "var(--teal-200, #8DDECE)"],
  ["cell-color-teal-50", "var(--teal-50, #E4F7F3)"],
  ["cell-color-light-green-400", "var(--light-green-400, #45FF58)"],
  ["cell-color-light-green-200", "var(--light-green-200, #A1FFAB)"],
  ["cell-color-light-green-50", "var(--light-green-50, #E1FFE5)"],
  ["cell-color-yellow-400", "var(--yellow-400, #FFE458)"],
  ["cell-color-yellow-200", "var(--yellow-200, #FFED8F)"],
  ["cell-color-yellow-50", "var(--yellow-50, #FFF6C7)"],
  ["cell-color-pink-400", "var(--pink-400, #FFA0C8)"],
  ["cell-color-pink-200", "var(--pink-200, #FFCEE3)"],
  ["cell-color-pink-50", "var(--pink-50, #FFEAF3)"],
  ["cell-color-red-400", "var(--red-400, #EB5847)"],
  ["cell-color-red-200", "var(--red-200, #F5948A)"],
  ["cell-color-red-50", "var(--red-50, #FCE8E6)"],
];

export default {
  textColors,
  textHighlightColors,
  tableCellBackgroundColors,
};
