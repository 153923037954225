import { Link } from "react-router-dom";
import { api, cls } from "../../utils/util";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { korean_num } from "../../constants";
import { useHomeSegment } from "hooks/useHomeSegment";

const RecommendSeminar = () => {
  const { sendTrackingEvent } = useHomeSegment();
  const weekOfMonth = () => {
    dayjs.extend(weekOfYear);
    const firstMonth = dayjs(`${dayjs().year()}-${dayjs().month() + 1}-1`).week();
    const today = dayjs().week();
    const nextMonth = dayjs(`${dayjs().year()}-${dayjs().month() + 2}-1`).week();
    // 다음달 1일과 현재의 week of year이 같으면 다음달 첫재주로
    if (today === nextMonth) {
      return {
        month: dayjs().month() + 2 > 12 ? "1" : dayjs().month() + 2,
        week: "첫",
      };
    }
    return {
      month: dayjs().month() + 1,
      week: korean_num[today - firstMonth],
    };
  };

  const matchLocationEng = (location: string) => {
    if (location === "오프라인") return "OFF";
    if (location === "온라인") return "ON";
    return "ON/OFF";
  };

  const { data: seminarSpotlightedState, isLoading: seminarSpotlightedStateLoading } = useQuery<
    seminarSpotlightedItem[] | undefined,
    Error
  >("managementSeminarSpotlighted", () =>
    api?.get("management/seminar/spotlighted").then((res) => res.data),
  );

  interface seminarSpotlightedItem {
    end_date: string;
    host: string;
    location: string;
    point: number;
    reg_dttm: string;
    start_date: string;
    title: string;
  }

  return (
    <div onClick={() => sendTrackingEvent({ name: "주간 세미나" })}>
      <Link
        className="w-full h-full shadow-list rounded-md overflow-hidden"
        to="/management/seminar_weekly"
      >
        <div className="h-[40px] p-[13px] bg-gradient-to-r from-gray-600 to-primary-800 flex justify-between items-center text-white">
          <b className="text-[20px]">주간 세미나</b>
          <div className="text-[13px]">
            {weekOfMonth().month}월 {weekOfMonth().week}째 주
            <i className="ii ii-clock-outline ii-weight-600 text-white text-[20px] ml-1 relative top-[-1px]"></i>
          </div>
        </div>
        <div className="flex flex-col h-[333px] justify-between p-4 hover:bg-gray-50">
          {!seminarSpotlightedStateLoading &&
            seminarSpotlightedState &&
            seminarSpotlightedState.slice(0, 5).map((item: any, idx: number) => (
              <div className="w-full flex items-center gap-4" key={idx}>
                <div
                  className={cls(
                    "min-w-[50px] h-[23px] text-center text-[13px] rounded-full border-[1px] border-solid border-primary-800",
                    item.location === "온라인"
                      ? "bg-primary-800 !text-white"
                      : "bg-white !text-primary-800",
                  )}
                >
                  {matchLocationEng(item.location)}
                </div>
                <div className="text-[14px] flex flex-col justify-between">
                  <b className="text-gray-900">
                    {`${item.point}점`} / [{item.host}]
                  </b>
                  <div className="ellipsis w-[280px] text-gray-600">{item.title}</div>
                </div>
              </div>
            ))}
        </div>
      </Link>
    </div>
  );
};

export default RecommendSeminar;
