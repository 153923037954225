import { useQuery } from "react-query";
import { getGroupPurchaseItem, getGroupPurchaseList, getGroupPurchaseMetadata } from "./api";

export const useGroupPurchaseList = (queryOptions) =>
  useQuery({
    queryKey: `/market/products/team-products`,
    queryFn: () => getGroupPurchaseList(),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

export const useGroupPurchaseItem = ({ groupPurchaseId = "" } = {}, queryOptions) =>
  useQuery({
    queryKey: `/market/products/team-products/${groupPurchaseId}`,
    queryFn: () => getGroupPurchaseItem({ groupPurchaseId }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

export const useGroupPurchaseMetadata = ({ productId }, queryOptions) =>
  useQuery({
    queryKey: `/market/products/${productId}/metadata`,
    queryFn: () => getGroupPurchaseMetadata({ productId }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
