import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import ChannelService from "../ChannelService";
import { tokenState } from "../state";
import CryptoJS from "crypto-js";

const ChannelTalk = () => {
  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token && token.uid && token.user_type) {
      const hash = CryptoJS.HmacSHA256(
        token.user_id,
        CryptoJS.enc.Hex.parse(
          "6e7f73b95ce7d8a9a3ecd74d012c548257aa627659db3e62583b3ca9f704750b"
        )
      ).toString(CryptoJS.enc.Hex);

      ChannelService.boot({
        pluginKey: "14123e3e-8cd8-4698-98a9-3af097115ea5",
        memberId: hash,
        profile: {
          name: token.user_nickname, //fill with user name
          userType: token.user_type,
          customerId: token.customerId,
        },
      });
    }
    if (!token || !token.uid || !token.user_type) {
      ChannelService.boot({
        pluginKey: "14123e3e-8cd8-4698-98a9-3af097115ea5",
        profile: {
          userType: "비회원",
        },
      });
    }
    return () => ChannelService.shutdown();
  }, [token]);

  return <></>;
};

export default ChannelTalk;
