export const INTEGRATION_EDITOR_CLASS = 'integration-editor'

export const INTEGRATION_EDITOR_MENUBAR_CLASS = 'integration-editor__menubar'

export const MEDISTREAM_SCHEMA_STYLE = {
  default: INTEGRATION_EDITOR_CLASS,
  nodes: {
    heading: INTEGRATION_EDITOR_CLASS + '__heading',
    paragraph: INTEGRATION_EDITOR_CLASS + '__p',
    horizontal_rule: INTEGRATION_EDITOR_CLASS + '__hr',
    image: INTEGRATION_EDITOR_CLASS + '__img',
    li: INTEGRATION_EDITOR_CLASS + '__li',
    ul: INTEGRATION_EDITOR_CLASS + '__ul',
    ol: INTEGRATION_EDITOR_CLASS + '__ol',
    blockquote: INTEGRATION_EDITOR_CLASS + '__blockquote',
    pre: INTEGRATION_EDITOR_CLASS + '__pre',
    br: INTEGRATION_EDITOR_CLASS + '__br',
    code_block: INTEGRATION_EDITOR_CLASS + '__code',
    video: INTEGRATION_EDITOR_CLASS + '__video',
    iframe: INTEGRATION_EDITOR_CLASS + '__iframe',
    table: INTEGRATION_EDITOR_CLASS + '__table',
    tableCell: INTEGRATION_EDITOR_CLASS + '__table-cell',
    custom: INTEGRATION_EDITOR_CLASS + '__custom',
  },
  marks: {
    link: INTEGRATION_EDITOR_CLASS + '__a',
    em: INTEGRATION_EDITOR_CLASS + '__em',
    strong: INTEGRATION_EDITOR_CLASS + '__strong',
    code: INTEGRATION_EDITOR_CLASS + '__code',
    del: INTEGRATION_EDITOR_CLASS + '__del',
    u: INTEGRATION_EDITOR_CLASS + '__u',
    text_color: INTEGRATION_EDITOR_CLASS + '__text-color',
    background_color: INTEGRATION_EDITOR_CLASS + '__background-color',
    font_size: INTEGRATION_EDITOR_CLASS + "__font_size",
    bdi: INTEGRATION_EDITOR_CLASS + "__bdi",
  },
  etc: {
    iframeWrapper: INTEGRATION_EDITOR_CLASS + '__iframeWrapper',
    iframeResizer: INTEGRATION_EDITOR_CLASS + '__iframe-resizer',
    tableWrapper: INTEGRATION_EDITOR_CLASS + '__tableWrapper',
    hrWrapper: INTEGRATION_EDITOR_CLASS + '__hrWrapper',
    imageResizeHandleWrapper: INTEGRATION_EDITOR_CLASS + '__image-resize-handle-wrapper',
    imageResizeHandle: INTEGRATION_EDITOR_CLASS + '__image-resize-handle',
    imageMenuWrapper: INTEGRATION_EDITOR_CLASS + '__image-menu-wrapper',
    imageMenuButton: INTEGRATION_EDITOR_CLASS + '__image-menu-button',
    imageAlignWrapper: INTEGRATION_EDITOR_CLASS + '__image-align-wrapper',
    imageAlignButton: INTEGRATION_EDITOR_CLASS + '__image-align-button',
    cellMenu: INTEGRATION_EDITOR_CLASS + '__cellmenu',
    selectable: 'selectable-guide',
    linkInputDialog: 'link-input-dialog',
    iframeLinkInputDialog: 'iframe-link-input-dialog',
    iconWrapper: 'icon-wrapper',
    typeDiv: 'type-div',
    titleDiv: 'title-div',
  }
}
