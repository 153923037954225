import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { listDttm, Img, StaticImg, api } from "../../utils/util";
import { useQuery } from "react-query";

const QnaListTable = ({ articles, bid }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const urlParams = new URLSearchParams(location.search);
  const { tab } = params;

  const { data: qnaNotice, isLoading: qnaNoticeLoading } = useQuery(["qnaNotice", tab], () =>
    api.get(`/qna/list/${tab}?informType=notice`).then((res) => res.data.qna),
  );

  return (
    <div className="list">
      <div className="list-head">
        <div className="w10p">이미지</div>
        <div className="w15p left">토픽</div>
        <div className="w45p left">제목</div>
        <div className="w15p left">글쓴이</div>
        <div className="w5p">작성일</div>
        <div className="w5p">조회</div>
        <div className="w5p">추천</div>
      </div>
      {!location.pathname.startsWith("/mypage") &&
        !qnaNoticeLoading &&
        qnaNotice.map((notice) => (
          <div
            className="notice"
            key={notice.bid}
            onClick={() => navigate(`/qna/article/${notice.bid}`)}
          >
            <div className="image">
              <div className="img-box">
                <img src={Img("", "img/notice_q.svg")} alt="이미지" />
              </div>
            </div>
            <div className="topic left">공지사항</div>
            <div className="title left">
              <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/ico-flag-01.svg`} alt="이미지" />
              {notice.title}
            </div>
            <div className="writer left">
              <img
                src={Img("", `img/rank_badge/rank_badge_admin.svg`)}
                className="user_badge"
                alt=""
              />
              모어덴
            </div>
          </div>
        ))}
      {articles &&
        articles.map((item) => (
          <Link className="width-max" key={item.bid} to={`/qna/article/${item.bid}?${urlParams}`}>
            <div
              className={
                item.bid === bid
                  ? "content current"
                  : item.report_count >= 10
                  ? "content reported"
                  : "content"
              }
            >
              <div className="w10p">
                <div className="img-box">
                  <img
                    src={
                      item.thumbnail
                        ? Img(item.thumbnail)
                        : StaticImg(`category/qna/cate_${item.cate_cd}.jpg`)
                    }
                    alt="이미지"
                  />
                </div>
              </div>
              <div className="w15p left">
                <span>{item.cate_name}</span>
              </div>
              <div className="w45p left">
                {item.reported ? (
                  "다수의 신고로 블라인드 처리된 게시글입니다."
                ) : (
                  <>
                    {item.report_count >= 10 && <i className="ic ic-reported" />}
                    {item.title.length > 40 ? item.title.slice(0, 40) + "⋯" : item.title + " "}[
                    {item.comment}]{item.has_img && <i className="ic ic-albums"></i>}
                  </>
                )}
              </div>
              <div className="w15p left">
                <span>{item.unick}</span>
              </div>
              <div className="w5p">
                <span>{listDttm(item.reg_dttm)}</span>
              </div>
              <div className="w5p">
                <span>{item.view}</span>
              </div>
              <div className="w5p">
                <span className="accent">{item.like}</span>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default QnaListTable;
