import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { api } from "../../utils/util";

const Expo = () => {
  const { data: adsTheater, isLoading: adsTheaterLoading } = useQuery(
    `adsTheater`,
    () => api.get("/advertisements/sponsor?advertisement=theater").then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  return (
    <div>
      <div className="wrapper expo__wrapper">
        <div className="w-[1200px] mx-auto flex flex-wrap gap-10">
          {!adsTheaterLoading &&
            adsTheater?.map((item) => {
              return (
                <Link
                  key={item.name}
                  to={`/expo/${item.name}`}
                  className="w-[270px] h-[320px] hover:transform hover:translate-y-[-20px] hover:duration-200 shadow-lg"
                >
                  <img className="w-full h-full" src={item.thumbnail} alt=""></img>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Expo;
