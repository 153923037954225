import ReactPaginate from "react-paginate";
import ContentsListTable from "./ContentsListTable";
import { useLocation } from "react-router-dom";

const ContentsList = ({ pageInfo, onPageClick }) => {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const q = urlParam.get("q") || "";
  const tag = urlParam.get("tag") || "";
  const page = parseInt(urlParam.get("page")) || 1;
  const param = new URLSearchParams();
  param.append("page", page);
  tag && param.append("tag", tag);
  q && param.append("q", q);
  return (
    <div className="lg:p-0 px-4">
      <ContentsListTable articles={pageInfo.contents} param={param} />
      {pageInfo.page && (
        <article className="paging">
          <ReactPaginate
            previousLabel="←"
            previousLinkClassName="before"
            nextLabel="→"
            nextLinkClassName="next"
            breakLabel="..."
            pageCount={pageInfo.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            pageLinkClassName="num"
            activeClassName=""
            activeLinkClassName="current"
            onPageChange={onPageClick}
            forcePage={pageInfo.page - 1}
          />
        </article>
      )}
    </div>
  );
};

export default ContentsList;
