import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import TextareaAutosize from "react-textarea-autosize";
import { api, Img } from "../../utils/util";
import CommentTextArea from "../board/detail/CommentTextArea";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import CommentTrashPopup from "../board/CommentTrashPopup";
import { useParams } from "react-router-dom";
import AttachImage from "component/shared/AttachImage";

const LoanComment = ({ article }) => {
  const bid = useParams().id;
  const token = useRecoilValue(tokenState);
  const queryClient = useQueryClient();
  const [newComment, setNewComment] = useState({
    uuid: null,
    cid: null,
    content: null,
    image: null,
    color: { toggle: false, index: null },
  });
  const [newReply, setNewReply] = useState({
    uuid: null,
    cid: null,
    content: null,
    image: null,
    color: { toggle: false, index: null },
  });
  const [deletePopup, setDeletePopup] = useState(false);

  const modifyComment = (item) => {
    setNewReply({ ...item, image: item.image, color: { toggle: true, index: item.uuid } });
  };

  const commentWrite = useMutation((data) => api.post(`/board/write/comment/${bid}`, data), {
    onSuccess: () => queryClient.invalidateQueries("boardArticle"),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const commentModify = useMutation(
    ({ data, uuid }) => api.post(`/board/comment/modify/${uuid}`, data),
    {
      onSuccess: () => queryClient.invalidateQueries("boardArticle"),
      onError: (e) => alert(e?.response?.data?.msg),
    },
  );

  const commentDelete = useMutation((uuid) => api.delete(`/board/comment/delete/${uuid}`), {
    onSuccess: () => {
      setDeletePopup(false);
      queryClient.invalidateQueries("boardArticle");
    },
    onError: (err) => {
      alert(err?.response?.data?.msg);
    },
  });

  const submit = async (comment, setComment) => {
    if (!comment.content && !comment.image) {
      alert("댓글내용이 존재하지 않습니다");
      return;
    }

    const data = {
      cid: comment.cid ?? null,
      content: comment.content ? comment.content : "",
      image: comment.image ?? null,
    };
    comment.uuid
      ? await commentModify.mutate({ data, uuid: comment.uuid })
      : await commentWrite.mutate(data);

    setComment({
      uuid: null,
      cid: null,
      content: null,
      image: null,
      color: { toggle: false, index: null },
    });
  };

  return (
    <article className="seoulDA__comment comment__form">
      <div className="comment__info">
        댓글
        <span className="seoulDA-blue">{article?.comment}</span>
      </div>
      {article?.comments
        .filter((comment) => !comment.cid)
        .map((comment, idx) => (
          <React.Fragment key={idx}>
            {newReply.uuid && newReply.uuid === comment.uuid ? (
              <article className="comment__form">
                <div className="comment__reg__form modify">
                  <CommentTextArea
                    newComment={newReply}
                    setNewComment={setNewReply}
                    changeImage={(file) =>
                      setNewReply({
                        ...newReply,
                        image: file,
                      })
                    }
                    submit={submit}
                  />
                </div>
              </article>
            ) : (
              <div className="comment-box">
                <div className="info-box">
                  <span className="nickname">{comment.unick}</span>
                  <span className="date">{dayjs(comment.reg_dttm).format("YYYY-MM-DD HH:mm")}</span>
                </div>
                <div className="content-box">{comment.content}</div>
                {comment.img_link && !comment.reported && (
                  <div className="img-box">
                    <img src={Img(comment.img_link)} alt="이미지" />
                  </div>
                )}
                {comment.content !== "삭제된 댓글입니다" ? (
                  <div className="menu-box">
                    <div className="left">
                      <button
                        className="comment-btn"
                        onClick={() =>
                          setNewReply({
                            cid: comment.uuid,
                            content: null,
                            image: null,
                            color: { toggle: true, index: comment.uuid },
                          })
                        }
                      >
                        <i className="ii ii-message-line-outline"></i>
                        <div className="comment">대댓글</div>
                      </button>
                    </div>
                    {token && token.user_nickname === comment.unick && (
                      <div className="seoulDA-report-wrap">
                        <button
                          className="seoulDA-report-btn"
                          onClick={() => modifyComment(comment)}
                        >
                          수정
                        </button>
                        <button
                          className="seoulDA-report-btn"
                          onClick={() => setDeletePopup(comment.uuid)}
                        >
                          삭제
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            )}
            {!newReply.uuid && newReply.cid && newReply.cid === comment.uuid && (
              <article className="comment__form">
                <div className="comment__reg__form reply">
                  <CommentTextArea
                    newComment={newReply}
                    setNewComment={setNewReply}
                    changeImage={(file) =>
                      setNewReply({
                        ...newReply,
                        image: file,
                      })
                    }
                    submit={submit}
                    replyUnick={comment.unick}
                  />
                </div>
              </article>
            )}
            {article.comments
              .filter((reply) => reply.cid === comment.uuid)
              .map((reply, idx) => {
                return (
                  <>
                    {newReply.uuid && newReply.uuid === reply.uuid ? (
                      <article className="comment__form">
                        <div className="comment__reg__form reply">
                          <div className="reg-input-box">
                            <span className="reply__user">@{reply.unick}</span>
                            <TextareaAutosize
                              onChange={(e) =>
                                setNewReply({ ...newReply, content: e.target.value })
                              }
                              value={newReply.content || ""}
                              minRows={3}
                            ></TextareaAutosize>
                            <div className="reg-menu">
                              <div className="left-menu-box">
                                <AttachImage
                                  changeImage={(file) =>
                                    setNewReply({
                                      ...newReply,
                                      image: file,
                                    })
                                  }
                                />
                              </div>
                              <div className="right-menu-box">
                                <div className="reg-btn-box">
                                  <button
                                    onClick={() => submit(newReply, setNewReply)}
                                    className="reg-btn"
                                  >
                                    {newReply.uuid ? "수정" : "등록"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {newReply.image && (
                            <div className="preview-list">
                              <div className="preview-box">
                                <img src={Img(newReply.image)} alt="이미지" />
                                <button
                                  className="remove-btn"
                                  onClick={() => {
                                    setNewReply({ ...newReply, image: null });
                                  }}
                                ></button>
                              </div>
                            </div>
                          )}
                        </div>
                      </article>
                    ) : (
                      <div className="comment-box reply-box" key={idx}>
                        <div className="info-box">
                          <span className="nickname">{reply.unick}</span>
                          <span className="date">
                            {dayjs(reply.reg_dttm).format("YYYY-MM-DD HH:mm")}
                          </span>
                        </div>
                        <div className="content-box">{reply.content}</div>
                        {reply.img_link && !reply.reported && (
                          <div className="img-box">
                            <img src={Img(reply.img_link)} alt="이미지" />
                          </div>
                        )}
                        <div className="menu-box">
                          <div className="left"></div>
                          {token && token.user_nickname === reply.unick && (
                            <div className="seoulDA-report-wrap">
                              <button
                                className="seoulDA-report-btn"
                                onClick={() => modifyComment(reply)}
                              >
                                수정
                              </button>
                              <button
                                className="seoulDA-report-btn"
                                onClick={() => setDeletePopup(reply.uuid)}
                              >
                                삭제
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </React.Fragment>
        ))}
      <div className="comment__reg__form">
        <CommentTextArea
          newComment={newComment}
          setNewComment={setNewComment}
          changeImage={(file) =>
            setNewComment({
              ...newComment,
              image: file,
            })
          }
          submit={submit}
        />
      </div>
      {deletePopup && (
        <CommentTrashPopup
          setPopup={setDeletePopup}
          deleteFunc={() => commentDelete.mutate(deletePopup)}
        />
      )}
    </article>
  );
};

export default LoanComment;
