import React from "react";

function CommentTrashPopup({ setPopup, deleteFunc }) {
  return (
    <article className="popup popup-addr" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="head">
          <div className="popup-tit">
            <i className="ic ic-logo"></i>
            <span className="txt">댓글 삭제</span>
          </div>

          {/* <a href="javascript:popupClose('addr')" className="close-btn"></a> */}
        </div>
        <div className="body">
          <article className="pop__addr">
            <div className="result__group">
              <div className="no-data">
                삭제된 댓글은 복구할 수 없습니다.<br></br>
                댓글을 삭제하시겠습니까?
              </div>
            </div>
          </article>

          <div className="popup__menu">
            <button onClick={(e) => setPopup(false)} className="pop-menu-btn">
              취소
            </button>
            <button onClick={(e) => deleteFunc()} className="pop-menu-btn type1">
              삭제
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

export default CommentTrashPopup;
