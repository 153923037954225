import { Component } from "react";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    if (import.meta.env.PROD) {
      Sentry.captureException(error, { extra: info });
      return <Navigate to="/" replace={true} />;
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
