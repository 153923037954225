import { toggleMark } from "prosemirror-commands";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

export const Del = Extension.Create({
  name: 'del',

  type: 'mark',
  
  defineSpec() {
    return {
      toDOM: () => ['del', {class: MEDISTREAM_SCHEMA_STYLE.marks.del}, 0],
      parseDOM: [{tag: 'del'}, {style: 'text-decoration=line-through'}],
    }
  },

  addCommands() {
    return {
      toggleDel: (state, dispatch) => toggleMark(state.schema.marks[this.name])(state, dispatch),
    }
  },

  addKeyboardShortcuts() {
    return {
      'Ctrl-Alt-S': this.editor.commands.toggleDel,
      'Ctrl-Alt-s': this.editor.commands.toggleDel,
    }
  }
})
