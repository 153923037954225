import { Link } from "react-router-dom";
import LevelIcon from "./LevelIcon";
import { CLASS_ROUTE } from "App";

const CoursesQuickSearchForm = () => {
  return (
    <>
      <Link
        className="inline-block h-[32px] leading-[32px] mr-2 px-4 rounded-full bg-teal-50 hover:bg-white shadow-custom"
        to={`/${CLASS_ROUTE}/search?free=true`}
      >
        <i className="ii ii-currency-krw-outline text-teal-600 align-top"></i>
        <span className="ml-1.5 font-semibold !text-teal-600">무료</span>
      </Link>
      <Link
        className="inline-block h-[32px] leading-[32px] mr-2 px-4 rounded-full bg-blue-50 hover:bg-white shadow-custom"
        to={`/${CLASS_ROUTE}/search?level=입문`}
      >
        <LevelIcon level="입문" background="bg-secondary-800" />
        <span className="ml-1.5 font-semibold !text-blue-600">입문</span>
      </Link>
      <Link
        className="inline-block h-[32px] leading-[32px] mr-2 px-4 rounded-full bg-blue-50 hover:bg-white shadow-custom"
        to={`/${CLASS_ROUTE}/search?level=초급`}
      >
        <LevelIcon level="초급" background="bg-secondary-800" />
        <span className="ml-1.5 font-semibold !text-blue-600">초급</span>
      </Link>
      <Link
        className="inline-block h-[32px] leading-[32px] mr-2 px-4 rounded-full bg-blue-50 hover:bg-white shadow-custom"
        to={`/${CLASS_ROUTE}/search?level=중급`}
      >
        <LevelIcon level="중급" background="bg-secondary-800" />
        <span className="ml-1.5 font-semibold !text-blue-600">중급</span>
      </Link>
      <Link
        className="inline-block h-[32px] leading-[32px] mr-2 px-4 rounded-full bg-blue-50 hover:bg-white shadow-custom"
        to={`/${CLASS_ROUTE}/search?level=고급`}
      >
        <LevelIcon level="고급" background="bg-secondary-800" />
        <span className="ml-1.5 font-semibold !text-blue-600">고급</span>
      </Link>
    </>
  );
};

export default CoursesQuickSearchForm;
