import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { cls } from "utils/util";
import { useInView } from "react-intersection-observer";
import SearchItemArticle from "component/search/item/SearchItemArticle";
import { useSearchSegemnet } from "hooks/useSearchSegment";

const SearchResultQna = ({ searchResult, hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  // ---------- 검색 리스트 ----------
  const qnaList = useMemo(() => {
    if (!searchResult) return [];
    return searchResult.reduce((acc, cur) => {
      if (cur?.hits) {
        return [...acc, ...cur.hits];
      }
      return acc;
    }, []);
  }, [searchResult]);

  // ---------- 무한 스크롤 관련 로직 ----------
  const { ref: triggerRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div>
      {qnaList.length > 0 ? (
        qnaList.map((qna, index) => (
          <Link
            className={cls(
              index === qnaList.length - 1 ? "" : "border-b border-solid border-b-gray-200",
              "block cursor-pointer",
            )}
            to={`/qna/article/${qna.id.includes("qna_comment") ? qna.bid : qna.id.split("_")[1]}`}
            onClick={() => handleSearchItemClick({ id: qna.id, title: qna.title })}
            key={qna.id}
          >
            <SearchItemArticle
              tag={qna.topic}
              title={qna.title}
              description={qna.content}
              thumbnail={qna.thumbnail}
              nickname={qna.nickname}
              user_level={qna.user_level}
              view={qna.view}
              like={qna.like}
              comment_count={qna.comment_count}
              reg_dttm={qna.reg_dttm}
            />
          </Link>
        ))
      ) : (
        <p>검색 결과가 없습니다.</p>
      )}
      {hasNextPage && !isFetchingNextPage && <div className="mt-[20px]" ref={triggerRef}></div>}
    </div>
  );
};
export default SearchResultQna;
