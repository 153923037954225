import { useState } from "react";
import { cls } from "utils/util";
import { MARKET_PRODUCT_SORTING_LIST } from "constants";

const MarketSortingBar = ({ initialSelectedSort, onSelectSort, customSortingList }) => {
  const sortingList = customSortingList || MARKET_PRODUCT_SORTING_LIST;

  const [selectedSortingOption, setSelectedSortingOption] = useState(
    sortingList?.length > 0
      ? sortingList.find((sort) => sort.value === initialSelectedSort)?.value ||
          sortingList[0].value
      : undefined,
  );

  const handleClickButton = (sort) => {
    setSelectedSortingOption(sort.value);
    if (onSelectSort) {
      onSelectSort(sort);
    }
  };

  const [hoveredSort, setHoveredSort] = useState(null);

  return (
    <div className="flex items-center gap-[14px] h-[21px]">
      {sortingList.map((sort) => {
        const isSelected = selectedSortingOption === sort.value;
        return (
          <button
            key={sort.label}
            type="button"
            className="flex items-center gap-[4px] group"
            onClick={() => handleClickButton(sort)}
            onMouseEnter={() => setHoveredSort(sort.value)}
            onMouseLeave={() => setHoveredSort(null)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="7"
              viewBox="0 0 6 7"
              fill="none"
            >
              <circle
                cx="3"
                cy="3.5"
                r="3"
                fill={isSelected ? "#6F4DC8" : hoveredSort === sort.value ? "#94969D" : "#BFC2C3"}
              />
            </svg>
            <strong
              className={cls(
                "text-[14px]",
                isSelected
                  ? "text-secondary-900 font-bold"
                  : "text-secondary-500 font-normal group-hover:text-secondary-600",
              )}
            >
              {sort.label}
            </strong>
          </button>
        );
      })}
    </div>
  );
};

export default MarketSortingBar;
