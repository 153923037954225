import { CellSelection } from "@medistream/prosemirror-tables";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { Heading } from "./heading";
import { Paragraph } from "./paragraph";

/**
 * @type {(fn: string) => import('prosemirror-state').Command}
 */
export const setFontSize = (fontSize) => (state, dispatch) => {
  const tr = state.tr;
  const selection = tr.selection;
  const { from, to } = state.selection;

  if (selection instanceof CellSelection) {
    const heading = state.schema.nodes[Heading.name];
    const paragraph = state.schema.nodes[Paragraph.name];
    const tasks = [];
    const allowedNodeTypes = new Set([heading, paragraph]);

    selection.forEachCell((cell, pos) => {
      cell.content.forEach((node, offset) => {
        const resolvedNode = state.doc.resolve(pos + offset + 1).nodeAfter;
        if (allowedNodeTypes.has(node.type)) {
          tasks.push({
            pos: pos + offset + 1,
            posEnd: pos + offset + 1 + resolvedNode.nodeSize,
          });
        }
      });
    });

    if (!tasks.length) return false;

    tasks.forEach(job => {
      const {pos, posEnd} = job

      fontSize === 'reset-style'
        ? tr.removeMark(pos, posEnd, state.schema.marks[FontSize.name])
        : tr.addMark(pos, posEnd, state.schema.marks[FontSize.name].create({style: `font_size: ${fontSize};`}))
    })

    dispatch(tr);
    return true;
  }

  if (fontSize === 'reset-style') {
    tr.removeMark(from, to, state.schema.marks[FontSize.name])
    
    return dispatch(tr)
  }

  tr.addMark(from, to, state.schema.marks[FontSize.name].create({ style: `font-size: ${fontSize};` }));
  
  dispatch(tr);
  return true;
};

export const FontSize = Extension.Create({
  name: 'font_size',

  type: 'mark',

  defineSpec() {
    return {
      attrs: {
        style: {
          default: null,
        },
      },
      inclusive: false,
      toDOM: (node) => [
        "span",
        { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.font_size },
        0,
      ],
      parseDOM: [
        {
          tag: "span",
          style: "font-size",
          getAttrs: dom => {
            const fontSize = dom.style?.fontSize;
            const classList = dom.getAttribute('class');
            
            if (classList?.includes(MEDISTREAM_SCHEMA_STYLE.marks.font_size) && fontSize) {
              return { style: `font-size: ${fontSize};` };
            }
            
            return false; 
          },
        },
      ],
    }
  },
    
  addCommands() {
    return {
      setFontSize,
    }
  }
})
