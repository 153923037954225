import { useNavigate } from "react-router-dom";
import ClassAuth from "./ClassAuth";
import dayjs from "dayjs";

const ClassCheckAuth = ({ registration, children, cid }) => {
  const navigate = useNavigate();

  if (!registration) {
    window.alert("수강신청 후에 이용하실 수 있습니다.");
    navigate(window.location.pathname, { replace: true });
    return <></>;
  }

  if (registration && dayjs(registration.start_dttm) > dayjs()) {
    window.alert(
      dayjs(registration.start_dttm).format(
        `M월 D일 A h시 ${
          dayjs(registration.start_dttm).minute() > 0 ? "m분 " : ""
        }부터 수강하실 수 있습니다.`,
      ),
    );
    navigate(window.location.pathname, { replace: true });
    return <></>;
  }

  if (!registration.doc_id) {
    return <ClassAuth cid={cid} />;
  }

  return <>{children}</>;
};

export default ClassCheckAuth;
