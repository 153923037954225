import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Loading from "../../component/Loading";
import { headerColorState } from "../../state";
import { api, Img } from "../../utils/util";
import ContentsKeyword from "../../component/contents/ContentsKeyword";
import ContentsList from "../../component/contents/ContentsList";
import ContentsRanking from "component/contents/ContentsRanking";
import SearchForm from "component/shared/SearchForm";
import RecommendContents from "component/contents/RecommendContents";
import { ContentsArticle, SmallContentsList, ContentsPage } from "types/contents";
import ContentsContent from "component/contents/ContentsContent";
import BoardReportPopup from "component/board/BoardReportPopup";

const ContentsDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bid = parseInt({ ...useParams() }.bid || "0");
  const urlParam = new URLSearchParams(location.search);
  const q = urlParam.get("q") || "";
  const tag = urlParam.get("tag") || "";
  const [headerColor, setHeaderColor] = useRecoilState(headerColorState);
  const [searchKeyword, setSearchKeyword] = useState(q);
  const [reportPopup, setReportPopup] = useState<any>({ toggle: false });
  const page = parseInt(urlParam.get("page") || "1");
  const contentsListRef = useRef<HTMLElement | null>(null);
  const params = { page, per_page: 12, q, tag };

  useEffect(() => window.scrollTo(0, 0), [bid]);

  const { data: smallList } = useQuery<SmallContentsList | undefined, Error>(
    "contentsSmallList",
    () => api?.get("/contents/smallList").then((res) => res.data),
  );

  const { data: content, isLoading: articleLoading } = useQuery<ContentsArticle | undefined, Error>(
    ["contentsArticle", bid],

    () => api?.get(`/contents/article/${bid}`).then((res) => res.data),
    {
      onSuccess: (data) => {
        data?.article.header_color && setHeaderColor(data.article.header_color);
      },
    },
  );

  const { data: allContents, isLoading: allContentsLoading } = useQuery<
    ContentsPage | undefined,
    Error
  >(["contentsList", params], () => api?.get("/contents/list", { params }).then((res) => res.data));

  useEffect(() => {
    return () => setHeaderColor(null);
  }, [setHeaderColor]);

  const publishContents = async () => {
    if (!content) return;

    const data = {
      title: content.article.title,
      content: content.article.content,
      textContent: new DOMParser().parseFromString(content.article.content, "text/html").body
        .textContent,
      header_color: content.article.header_color,
      display_auth: content.article.display_auth,
      tag: content.article.tag,
      thumbnail: content.article.thumbnail ? [content.article.thumbnail] : [],
      header_pc: content.article.header_pc ? [content.article.header_pc] : [],
      header_mo: content.article.header_mo ? [content.article.header_mo] : [],
    };

    api
      ?.post(`/contents/publish/${bid}`, data)
      .then((res) => {
        navigate(`/contents/article/${bid}`, { replace: true });
        alert(res.data.msg);
      })
      .catch((e) => alert(e.response.data.msg));
  };

  const search = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const param = new URLSearchParams();
    param.append("page", "1");
    searchKeyword && param.append("q", searchKeyword);
    navigate(`/contents/article/${bid}?${param}`);
    if (contentsListRef.current) {
      window.scrollTo({ top: contentsListRef.current.offsetTop - 60 });
    }
  };

  const modify = () => {
    if (!content) return;
    if (content.article.contentJson) {
      navigate(`/contents/modify/${content.article.bid}`, {
        state: { ...content },
      });
      return;
    } else {
      navigate(`/contents/modify/${content.article.bid}?editor=froala`, {
        state: { ...content },
      });
      return;
    }
  };

  const onPageClick = (data: any) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    selected && param.append("page", selected);
    navigate(`/contents/article/${bid}?${param}`);
    if (contentsListRef.current) {
      window.scrollTo({ top: contentsListRef.current.offsetTop - 60 });
    }
  };

  const deleteContents = () => {
    if (!content) return;

    api?.delete(`/contents/delete/${content.article.bid}`).then((res) => {
      alert(res.data.msg);
      navigate(`/contents`);
    });
  };

  if (articleLoading || !content) return <Loading small={false} />;

  return (
    <div>
      <div className="curation-detail">
        <div
          className={`h-[360px] lg:h-[400px] bg-center bg-no-repeat bg-cover mt-[-195px] bg-${
            headerColor === "black" ? "white" : "black"
          }`}
          style={
            content.article.header_pc
              ? {
                  backgroundImage: `url(${Img(
                    encodeURI(content.article.header_pc)
                      .replaceAll("%25", "%")
                      .replace(/\(/g, "%28")
                      .replace(/\)/g, "%29"),
                  )}`,
                }
              : {}
          }
        ></div>
        <div className="lg:max-w-[1200px] mx-auto">
          {/* 콘텐츠 헤더 */}
          <div className="p-4 lg:p-0 relative">
            <div className={`mt-[-120px] font-bold text-lg lg:text-3xl text-${headerColor}`}>
              {content.article.title}
            </div>
            <div className={`mt-4 lg:mt-6 text-tiny lg:text-base text-${headerColor}`}>
              <span className="mr-2">
                by {content.article.unick}&nbsp;&nbsp;
                {content.article.published_at &&
                  dayjs(content.article.published_at).format("YYYY/MM/DD")}
              </span>
              조회수 {content.article.view}
            </div>
            {content.ismine && (
              <div className="hidden lg:flex absolute gap-3 right-0 bottom-10">
                {!content.article.published_at && (
                  <button
                    className="bg-white hover:bg-gray-100 px-3 py-1 rounded-sm shadow-card border-solid border-gray-300 border-[1px]"
                    onClick={publishContents}
                  >
                    발행
                  </button>
                )}
                <button
                  className="bg-white hover:bg-gray-100 px-3 py-1 rounded-sm shadow-card border-solid border-gray-300 border-[1px]"
                  onClick={modify}
                >
                  수정
                </button>
                <button
                  className="bg-white hover:bg-gray-100 px-3 py-1 rounded-sm shadow-card border-solid border-gray-300 border-[1px]"
                  onClick={deleteContents}
                >
                  삭제
                </button>
              </div>
            )}
          </div>
          {/* 콘텐츠 내용 */}
          <div className="my-20 flex justify-between">
            <ContentsContent content={content} setReportPopup={setReportPopup} />
            {smallList && (
              <div className="w-[33%] h-[900px] lg:block lg:top-[65px] lg:sticky hidden">
                <RecommendContents contents={smallList.recommend_contents} />
                <ContentsRanking latest_article={smallList.popular_contents} />
              </div>
            )}
          </div>
        </div>
        <ContentsKeyword />
        <div className="lg:max-w-[1200px] mx-auto">
          <section ref={contentsListRef}>
            {q || tag ? (
              <div className="search-result lg:p-0 px-4">
                <span className="highlight">{q || tag}</span> 검색 결과
              </div>
            ) : (
              <div className="title lg:p-0 px-4">전체 콘텐츠</div>
            )}
            {!allContentsLoading && allContents && (
              <ContentsList pageInfo={allContents} onPageClick={onPageClick} />
            )}
            <div className="w-full flex justify-center pt-10 px-10 lg:px-0">
              <SearchForm
                value={searchKeyword}
                setValue={setSearchKeyword}
                search={search}
                width="400px"
              />
            </div>
          </section>
        </div>
      </div>
      {reportPopup.toggle && (
        <BoardReportPopup
          category={reportPopup.category}
          setReportPopup={setReportPopup}
          bid={reportPopup.uuid ? reportPopup.uuid : bid}
        />
      )}
    </div>
  );
};

export default ContentsDetail;
