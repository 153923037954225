import dayjs from "dayjs";
import CopyToClipboard from "react-copy-to-clipboard";
import { default as Select } from "react-select";
import { Img, selectStyles, outResource, api, StaticImg, cls } from "../../../utils/util";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Loading from "../../../component/Loading";
import { useState, useEffect } from "react";
import LoanPopup from "component/management/LoanPopup";

const ConsultingMediground = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const param = parseInt(urlParam.get("tab")) || 0;
  const id = "mediground";
  const [popup, setPopup] = useState(false);

  const { data: adsMain, isLoading: adsMainLoading } = useQuery(
    `adsMain${id}`,
    () =>
      api
        .get(`/advertisements?advertisement=consulting&name=${id}&component=main`)
        .then((res) => res.data[0]),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const { data: adsProduct, isLoading: adsProductLoading } = useQuery(
    `adsProduct${id}`,
    () =>
      api
        .get(`/advertisements?advertisement=consulting&name=${id}&component=product`)
        .then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  const { data: adsLink, isLoading: adsLinkLoading } = useQuery(
    `adsLink${id}`,
    () =>
      api
        .get(`/advertisements?advertisement=consulting&name=${id}&component=link`)
        .then((res) => res.data),
    {
      onError: (e) => e?.response?.data?.msg && alert(e.response.data.msg),
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (adsProductLoading | adsLinkLoading | adsMainLoading) return <Loading />;

  return (
    <div className="w-[1200px] mx-auto">
      {popup && <LoanPopup setPopup={setPopup} txt="010-2994-2337 (박병찬 과장)" />}
      <article className="flex justify-between my-5">
        <div className="text-[20px] tracking-tighter font-semibold animate-fadedown">
          {adsMain.title}
        </div>
        {adsProduct.length > 1 && (
          <div className="w-[500px]">
            <Select
              onChange={(option) => {
                navigate(`/expo/${id}?tab=${option.value}`, { replace: true });
              }}
              styles={selectStyles}
              options={adsProduct.map((item, idx) => {
                return { label: item.title, value: idx };
              })}
              value={{ label: adsProduct[param].title, value: param }}
              isSearchable={false}
              defaultValue={{ label: adsProduct[0].title, value: 0 }}
            />
          </div>
        )}
      </article>
      {adsProduct[param]?.link && (
        <>
          <div className="flex h-[400px]">
            <iframe
              className="w-full"
              src={adsProduct[param] && `https://www.youtube.com/embed/${adsProduct[param].link}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {!!adsLink.length && (
              <div className="w-[600px] ml-[20px] p-4 rounded-md bg-gradient-to-b from-[#383838] to-[#7a7a7a]">
                <div className="flex items-between justify-between mb-5">
                  <img
                    className="max-w-[120px] max-h-[35px]"
                    src={adsMain.content.chart_logo}
                    alt=""
                  />
                  <div className="text-white font-semibold">
                    <mark
                      className="text-white px-1 rounded-sm mr-1"
                      style={{ background: `${adsMain.content.chart_color}` }}
                    >
                      NEW
                    </mark>
                    CONTENTS
                  </div>
                </div>
                <div className="h-[310px] flex flex-col justify-between">
                  {adsLink.slice(0, 3).map((item, idx) => (
                    <div
                      className="bg-white text-[15px] p-2 rounded-sm cursor-pointer group"
                      onClick={() => outResource(`${item.link}`)}
                      key={idx}
                    >
                      <div className="font-semibold">
                        <mark
                          className="text-white px-1 rounded-sm mr-1"
                          style={{ background: `${adsMain.content.chart_color}` }}
                        >
                          {idx + 1}
                        </mark>
                        {item.title}
                      </div>
                      <div className="text-gray-600 group-hover:underline">
                        <b className="font-medium text-black">주제:&nbsp;</b>
                        {item.content.subtitle}
                      </div>
                      <div className="text-gray-600 group-hover:underline">
                        <b className="font-medium text-black">연자:&nbsp;</b>
                        {item.lecturer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center mt-5">
            <b className="text-[26px]">{adsProduct[param]?.title}</b>
            <div className="text-[18px] text-gray-600">
              {dayjs(adsProduct[param]?.createdDate).format("YYYY-MM-DD")}
            </div>
          </div>
        </>
      )}

      <div className="flex justify-between items-center border-b-[1px] border-solid border-gray-600 mt-5 pb-5 mb-5">
        <a
          className="flex items-center font-semibold text-[15px]"
          href={adsMain.content.homepage_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="w-[70px] mr-3" src={adsMain.content.sponsor_logo} alt="로고" />
          {adsMain.content.sponsor_name}
        </a>
        <div className="flex h-[35px]">
          <a
            className="py-1 px-2 mr-3 border-[1px] border-solid border-gray-300 rounded-sm text-[13px] flex items-center"
            href={adsMain.content.homepage_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="ii ii-home-outline mr-1" />
            홈페이지
          </a>
          {navigator.share ? (
            <button
              className="py-1 px-2 border-[1px] border-solid border-gray-300 rounded-sm text-[13px] flex items-center"
              onClick={() =>
                navigator.share({
                  title: `[모어덴 - ${adsMain.content.sponsor_name} 상영관]`,
                  text: adsProduct[param].title,
                  url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                })
              }
            >
              <i class="ii ii-export mr-1" /> 공유
            </button>
          ) : (
            <CopyToClipboard
              text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
              onCopy={() => alert("링크를 복사하였습니다")}
            >
              <button className="py-1 px-2 border-[1px] border-solid border-gray-300 rounded-sm text-[13px] flex items-center">
                <i class="ii ii-export mr-1" /> 공유
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>
      <div className="megagen-cf">
        <div className="top-article" style={{ margin: "45px 0" }}>
          <div className="flex gap-5">
            <button
              className="shadow-custom flex gap-3 rounded-md p-2"
              onClick={() => setPopup(true)}
            >
              <i className="ii ii-phone-call-outline"></i>
              <div className="text-[16px] font-semibold">전화 상담하기</div>
            </button>
            <button className="shadow-custom flex gap-3 rounded-md p-2">
              <i class="ii ii-pen-outline ii-weight-600"></i>
              <div
                className="text-[16px] font-semibold"
                onClick={() => outResource("https://naver.me/5fj9abA7")}
              >
                미팅 요청하기
              </div>
            </button>
          </div>
        </div>
        <div className="flex flex-wrap mx-auto">
          {adsProduct[param]?.content?.map((item) => {
            return (
              <a
                className={cls(id === "purgo" ? "max-w-[600px]" : "")}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Img(item.pcImage)} alt="" />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConsultingMediground;
