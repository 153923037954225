import { Link } from "react-router-dom";
import { resizedImg, Img, api } from "../../utils/util";
import { useQuery } from "react-query";
import ContentLoader from "react-content-loader";
import { useHomeSegment } from "hooks/useHomeSegment";

const RecommendContents = () => {
  const { sendTrackingEvent } = useHomeSegment();
  const { data: contents, isLoading: contentsLoading } = useQuery<
    ContentsSmallList | undefined,
    Error
  >("contentsSmallList", () => api?.get("contents/smallList").then((res) => res.data));

  return (
    <div className="w-full curation-detail-recommend flex flex-col">
      <span className="flex justify-between items-center pb-6">
        <b className="text-[20px]">추천 콘텐츠</b>
        <Link to="/contents" className="cursor-pointer text-[13px] text-gray-600 hover:underline">
          +더보기
        </Link>
      </span>
      <div
        onClick={() => sendTrackingEvent({ name: "추천 콘텐츠" })}
        className="h-[215px] flex flex-col justify-between"
      >
        {contentsLoading ? (
          <RecommendContentsLoader />
        ) : (
          contents?.recommend_contents.slice(0, 3).map((item, idx: number) => (
            <Link to={`/contents/article/${item.bid}`} className="h-[30%] flex group" key={idx}>
              <div className="h-full rounded-md aspect-contents overflow-hidden bg-gray-100">
                {item.thumbnail && (
                  <img
                    className="w-full h-full group-hover:scale-105 transition-all duration-300"
                    src={resizedImg(Img(item.thumbnail), 200)}
                    alt="thumbnail"
                  />
                )}
              </div>
              <div className="flex flex-col justify-between p-2">
                <div className="truncate w-[250px] font-medium group-hover:text-primary-800">
                  {item.title}
                </div>
                <div className="flex">
                  {item.tag
                    .split(",")
                    .slice(0, 3)
                    .map((tag: string, idx: number) => (
                      <div className="bg-gray-100 rounded-sm mr-2 px-2 text-[12px]" key={idx}>
                        <span>{tag}</span>
                      </div>
                    ))}
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default RecommendContents;

const RecommendContentsLoader = () => (
  <ContentLoader
    speed={1}
    width={420}
    height={215}
    viewBox="0 0 420 215"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="5" ry="5" width="142" height="65.5" />
    <rect x="0" y="75.5" rx="5" ry="5" width="142" height="65.5" />
    <rect x="0" y="152" rx="5" ry="5" width="142" height="65.5" />
    <rect x="155" y="15" rx="5" ry="5" width="220" height="14" />
    <rect x="155" y="90.5" rx="5" ry="5" width="220" height="14" />
    <rect x="155" y="167" rx="5" ry="5" width="220" height="14" />
    <rect x="155" y="20" rx="5" ry="5" width="220" height="14" />
  </ContentLoader>
);

interface ContentsSmallList {
  popular_contents: ContentsItem[];
  recommend_contents: ContentsItem[];
}

interface ContentsItem {
  bid: number;
  comment: number;
  content: string;
  contents_score_count: number;
  contents_score_sum: number;
  deleted_at: string;
  display_auth: string;
  header_color: string;
  header_mo: string;
  header_pc: string;
  like: number;
  modified_at: string;
  my_score: any;
  published_at: string;
  recommend_no: any;
  reg_dttm: string;
  reported_at: string;
  revision: number;
  scraped: boolean;
  subtitle_link: null;
  tag: string;
  text_content: string;
  thumbnail: string;
  title: string;
  video_link: string;
  view: number;
}
