const ProseMirrorMenuFileButton = ({
  Name,
  menubarClassName,
  Description,
  Icon,
  cls,
  tooltip,
  uploadFiles,
}) => {
  const accept = () => {
    switch (Name) {
      case "image":
        return "image/*";
      case "video":
        return "video/mp4";
      case "file":
        return "*";
      default:
        return "*";
    }
  };

  return (
    <div className="group">
      <label
        htmlFor={Name}
        className={cls(
          menubarClassName +
            "__icon " +
            Name +
            (!!Description ? " show-tooltip" : ""),
          "hidden"
        )}
        data-tooltip={Description}
      >
        <input
          type="file"
          multiple
          onChange={(e) => uploadFiles(e, Name)}
          id={Name}
          accept={accept()}
          className="hidden"
        />
        <div dangerouslySetInnerHTML={{ __html: Icon }} className="h-6"></div>
      </label>
      <span className="tooltip-txt">{tooltip ? tooltip : Description}</span>
    </div>
  );
};

export default ProseMirrorMenuFileButton;
