import SwipeableDrawer from "component/shared/SwipeableDrawer";
import HookModal from "component/shared/HookModal";
import { useRcaModal, useRcaSwipeableDrawer } from "recoil/common";

const PortalRoot = () => {
  const { isOpenedDrawer } = useRcaSwipeableDrawer();
  const { isOpenedModal } = useRcaModal();

  return (
    <>
      {isOpenedDrawer && <SwipeableDrawer />}
      {isOpenedModal && <HookModal />}
    </>
  );
};
export default PortalRoot;
