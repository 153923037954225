import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../component/footer/Footer";
import { api } from "../../utils/util";
import QnaRanking from "../../component/qna/QnaRanking";
import QnaArticleBox from "../../component/qna/QnaArticleBox";
import { useQuery } from "react-query";
import QnaListUI from "../../component/qna/QnaListUI";
import Loading from "../../component/Loading";
// import Banner from "../../component/Banner";
import Banner from "component/advertisment/banner";

function QnaPage() {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const q = urlParam.get("q") || "";
  const cate_cd = urlParam.has("topic") ? parseInt(urlParam.get("topic")) : 0;
  const page = urlParam.has("page") ? parseInt(urlParam.get("page")) : 1;
  const tab = useParams().tab;
  const sort = urlParam.get("sort");
  const params = { page, q, sort_type: sort };

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["qnaList", tab, cate_cd, sort, params],
    () =>
      api
        .get(cate_cd ? `/qna/list/${tab}/${cate_cd}` : `/qna/list/${tab}`, {
          params,
        })
        .then((res) => res.data),
    { onError: (err) => console.log(err) },
  );

  const { data: pickInfo, isLoading: pickInfoLoading } = useQuery(["qnaListPick", tab], () =>
    api.get(`/qna/list_pick/${tab}`).then((res) => res.data),
  );

  if (pageInfoLoading) return <Loading />;
  return (
    <>
      <div className="wrapper mt40">
        <section className="layout__half">
          {cate_cd === 0 && !pickInfoLoading && (
            <div className="inner flex justify-between">
              <QnaArticleBox articles={pickInfo.pick_qna} title="화제의 Q&amp;A" />
              <QnaArticleBox articles={pickInfo.hot_qna} title="실시간 HOT Q&amp;A" />
              <QnaRanking pageInfo={pickInfo} />
            </div>
          )}
          <div className="inner" style={{ marginBottom: "54px" }}>
            <Banner name="pc_QnaPage_middle" page="QnaPage" height="full" />
          </div>
          <section className="magazine__list" style={{ marginBottom: "136.5px" }}>
            <div className="inner">
              <QnaListUI current="qna_page" pageInfo={pageInfo} />
            </div>
          </section>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default QnaPage;
