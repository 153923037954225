import { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import BoardContent from "../../component/board/BoardContent";
import BlockAlert from "../../component/BlockAlert";
import BoardReportPopup from "../../component/board/BoardReportPopup";
import BoardTrashPopup from "../../component/board/BoardTrashPopup";
import DetailBoardTitle from "../../component/board/detail/DetailBoardTitle";
import MagazineList from "../../component/board/magazine/MagazineList";
import Footer from "../../component/footer/Footer";
import Loading from "../../component/Loading";
import { api, watermark, writeDayYtoS } from "../../utils/util";
import BoardDetailContent from "../../component/board/BoardDetailContent";
import BoardArticleBox from "../../component/board/all/BoardLiveArticle";
import BoardLiveComment from "../../component/board/all/BoardLiveComment";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CommentList from "../../component/comment/CommentList";
// import Banner from "../../component/Banner";
import Banner from "component/advertisment/banner";
import Nickname from "component/user/nickname";
import { PuffLoader } from "react-spinners";
import SubscribeButton from "component/user/subscribeButton";
import BlockButton, { BlockButtonUI } from "component/user/blockButton";

function BoardDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const article = parseInt(useParams().bid || 0);
  const [deletePopup, setDeletePopup] = useState(false);
  const urlParam = new URLSearchParams(location.search); // TODO location 오류 이유 체크
  const board = parseInt(urlParam.get("cate")) || 0;
  const [reportPopup, setReportPopup] = useState({ toggle: false });
  const [blockAlert, setBlockAlert] = useState(false);
  const token = useRecoilValue(tokenState);
  const queryClient = useQueryClient();

  if (article === 0) {
    navigate(-1);
  }
  const boardScroll = useRef();

  useEffect(() => window.scrollTo(0, 0), [article]);

  const { data: content, isLoading: contentLoading } = useQuery(
    ["boardArticle", article],
    () => api.get(`/board/article/${article}`).then((res) => res.data),
    {
      onError: (err) => {
        alert(err.response.data.msg);
        navigate(-1);
      },
    },
  );

  const { data: pickInfo, isLoading: pickInfoLoading } = useQuery("boadListPick", () =>
    api.get("/board/list_pick").then((res) => res.data),
  );

  const directDeleteArticle = () => {
    api.delete(`/board/delete/${content.board.bid}`).then(() => {
      navigate(`/post?cate=${board}`, { replace: true });
    });
  };

  const modifyFunc = () => {
    navigate(`/post/modify/${content.board.bid}`, { state: content });
  };

  const reportComment = (item) =>
    setReportPopup({
      category: "board_comment",
      uuid: item.uuid,
      toggle: true,
    });

  const scrapChanged = useMutation((data) => api.post(`/board/scrap/${article}`, data), {
    onSuccess: () => queryClient.invalidateQueries("boardArticle"),
    onError: (err) => {
      alert(err.response.data.msg);
    },
  });

  const scrapClicked = () => {
    const data = {
      scraped: !content.scraped,
    };

    scrapChanged.mutate(data);
  };

  const likeChanged = useMutation((data) => api.post(`/board/like/${article}`, data), {
    onSuccess: () => queryClient.invalidateQueries("boardArticle"),
  });

  const likeClicked = (comment, curLiked) => {
    if (comment ? comment.ismine : content.ismine) {
      alert("내가 작성한 글은 추천할 수 없습니다.");
      return;
    }
    const cid = comment ? comment.uuid : null;
    const data = {
      cid: cid ?? null,
      liked: !curLiked,
    };

    likeChanged.mutate(data);
  };

  if (contentLoading || !content) return <Loading current="board" current_board={board} />;
  return (
    <>
      <div className="wrapper mt70">
        <section className="magazine__detail">
          <div className="inner">
            <DetailBoardTitle
              content={content}
              modifyFunc={modifyFunc}
              setDeletePopup={setDeletePopup}
            />
            {deletePopup && (
              <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
            )}
            <div
              className="detail__group"
              ref={boardScroll}
              style={{
                background: watermark(token),
                backgroundRepeat: "space",
              }}
            >
              <div className="tit-box">
                <div className="subject-box">
                  {content.board.report_count >= 10 && <i className="reported" />}
                  {content.board.reported
                    ? "다수의 신고로 블라인드 처리된 게시글입니다."
                    : content.board.title}
                </div>
                <div className="flex justify-between items-center my-3 whitespace-nowrap">
                  <div className="flex items-center text-sm text-secondary-600 gap-2">
                    <Nickname
                      isDetail
                      uid={content.board.userUid}
                      unick={content.board.unick}
                      level={content.board.level}
                      isOwner={content.board.is_owner}
                    />
                    <div>{writeDayYtoS(content.board.reg_dttm)}</div>
                  </div>
                  <div className="flex gap-2">
                    {content.board.userUid && <SubscribeButton uid={content.board.userUid} />}
                    {!content.ismine && <BlockButtonUI onClick={() => setBlockAlert(true)} />}
                  </div>
                </div>
              </div>
              <div className="con-box divided">
                <div className="w-screen lg:w-[750px]">
                  <BoardDetailContent
                    content={content}
                    likeClicked={likeClicked}
                    scrapClicked={scrapClicked}
                    setReportPopup={setReportPopup}
                  />
                  <Banner name="pc_BoardDetail_middle" page="BoardDetail" />
                  {content.comments && (
                    <CommentList
                      current="board"
                      content={content}
                      bid={article}
                      reportComment={reportComment}
                    />
                  )}
                </div>
                <div className="hidden lg:flex lg:flex-col w-[400px]">
                  {pickInfo && !pickInfoLoading ? (
                    <>
                      <div className="bg-gray-50 p-5 mb-5 rounded-md">
                        <BoardArticleBox articles={pickInfo.pick_items} title="모어덴 화제의 글" />
                      </div>
                      <div className="bg-gray-50 p-5 mb-5 rounded-md">
                        <BoardArticleBox articles={pickInfo.hot_items} title="실시간 HOT 게시글" />
                      </div>
                      <div className="bg-gray-50 p-5 rounded-md">
                        <BoardLiveComment recent_comments={pickInfo.recent_comments} />
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center justify-center">
                      <PuffLoader color="#4a25aa" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="magazine__list mt-[60px]"
          style={{ marginBottom: "136.5px" }}
          ref={boardScroll}
        >
          <div className="inner">
            {board === 1 ? (
              <MagazineList current="board_detail" current_cate={1} />
            ) : (
              <BoardContent current="board_detail" />
            )}
          </div>
        </section>
      </div>
      {blockAlert && (
        <BlockAlert
          setBlockAlert={setBlockAlert}
          bid={content.board.bid}
          isAnonymous={content.board.anonymous}
          blockReference={content.board.title}
        />
      )}
      {reportPopup.toggle && (
        <BoardReportPopup
          category={reportPopup.category}
          setReportPopup={setReportPopup}
          bid={reportPopup.uuid ? reportPopup.uuid : article}
        />
      )}
    </>
  );
}

export default BoardDetail;
