import { useState, useEffect } from "react";
import { api, htmlTextContent } from "../../utils/util";

const MultipleQuestion = ({ question, problemNum, setProblemNum }) => {
  const [myAnswer, setMyAnswer] = useState(null);
  const document = question[problemNum] && question[problemNum].document;
  const [wrongAlarm, setWrongAlarm] = useState(false);
  const { answer, choice, problem } = JSON.parse(document.replace(/\n/g, ""));

  useEffect(() => {
    setMyAnswer(null);
    setWrongAlarm(false);
  }, [problemNum]);

  const checkAnswer = () => {
    if (!document) return;
    if (answer[0] === myAnswer + 1) {
      setProblemNum(problemNum + 1);
    }

    if (answer[0] !== myAnswer + 1) {
      setWrongAlarm(true);
    }
  };

  return (
    document && (
      <>
        <div className="q">{problem}</div>
        <div className="des-box class">
          {choice.map((label, idx) => (
            <div key={idx}>
              <div className="rbox class">
                <label>
                  <input
                    type="checkbox"
                    className={idx === myAnswer ? "checked" : ""}
                    onChange={() => setMyAnswer(idx)}
                  />
                  <i className=""></i>
                  <span className="txt">{htmlTextContent(`${idx + 1}. ${label}`)}</span>
                </label>
              </div>
            </div>
          ))}
        </div>
        <button className="nxt" onClick={checkAnswer}>
          다음
        </button>
        {wrongAlarm ? (
          <div className="wrong alert">정답이 올바르지 않습니다 😥</div>
        ) : (
          <div className="alert">QUIZ를 풀어야 이수가 완료됩니다 😃</div>
        )}
      </>
    )
  );
};

const ClassQuiz = ({ closePopup, lid, qid, title }) => {
  const [problems, setProblems] = useState([]);
  const [problemNum, setProblemNum] = useState(0);

  useEffect(() => {
    setProblemNum(0);
    api
      .get(`/class/quiz/${qid}`)
      .then((res) => {
        setProblems(res.data.problems);
      })
      .catch((err) => {
        alert("퀴즈 로딩에 실패하였습니다");
      });
  }, [qid]);

  useEffect(() => {
    if (problems.length && problems.length <= problemNum) {
      api
        .post(`/class/quiz/complete/${lid}`)
        .catch(
          alert(
            "퀴즈 완료 처리에 문제가 생겼습니다. 영업일 기준 1일 이내에 퀴즈 완료 처리될 예정입니다.",
          ),
        );
    }
  }, [problems, problemNum, lid]);

  if (!problems.length) {
    return <></>;
  }

  return (
    <div>
      <div className="class-quiz-box">
        {problemNum < problems.length ? (
          <>
            <div className="tit">
              <b>QUIZ</b>
              <br />
              {title}
              <i className="close" onClick={() => closePopup(false)} />
            </div>
            <MultipleQuestion
              question={problems}
              problemNum={problemNum}
              setProblemNum={setProblemNum}
            />
          </>
        ) : (
          <div className="quiz_done">
            <i className="close" onClick={() => closePopup(true)} />
            <div style={{ fontSize: 60, marginBottom: 30 }}>👏</div>
            <div>수고하셨습니다!</div>
            <div>
              <b>이수완료</b> 처리되었습니다.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassQuiz;
export { MultipleQuestion };
