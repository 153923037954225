import React from "react";
import { Link } from "react-router-dom";
import { removeMention } from "../../../utils/util";

function BoardLiveComment({ recent_comments }) {
  return (
    <div>
      <div className="text-[20px] tracking-tighter font-semibold animate-fadedown whitespace-nowrap">
        실시간 댓글
      </div>
      <div className="flex flex-col mt-2 font-medium text-[15px] bg-gray-50 p-2">
        {recent_comments &&
          !!recent_comments.length &&
          recent_comments.slice(0, 4).map((comment, idx) => (
            <Link
              to={`/post/article/${comment.Board.bid}?cid=${
                comment.BoardComment.uuid ? comment.BoardComment.uuid : comment.BoardComment.cid
              }`}
              key={idx}
              className="inline-block mt-2"
            >
              <div className="flex items-center">
                <div className="text-white bg-primary-800 tracking-tighter text-[12px] rounded-sm h-[fit-content] p-1 mr-2">
                  new
                </div>
                <span className="truncate max-w-[320px] hover:text-primary-800">
                  {removeMention(comment.BoardComment.content || "")}
                </span>
              </div>
              <div className="flex items-center mt-1 ml-3">
                <img
                  className="relative -top-1 mr-2"
                  src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/dash_line.svg`}
                  alt="실시간 댓글"
                />
                <div className="text-gray-500 mr-2 truncate max-w-[300px]">
                  {comment.Board.title}
                </div>
                <span className="text-primary-800">[{comment.Board.comment}]</span>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default BoardLiveComment;
