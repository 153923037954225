import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";

const CensoredData = ({ children }) => {
  const token = useRecoilValue(tokenState);
  const isAuthorizedUser = token && token.user_type;

  if (isAuthorizedUser) return children;
  return <>인증된 회원만 이용하실 수 있습니다.</>;
};

export default CensoredData;
