const ProseMirrorMenuToggleButton = ({ Name, Description, Icon, menubarClassName, tooltip }) => {
  return (
    <div className="group">
      <button
        className={menubarClassName + "__icon " + Name}
        data-command-name={Name}
        data-tooltip={Description}
        data-icon-type="toggle"
        dangerouslySetInnerHTML={{ __html: Icon }}
      ></button>
      <span className="tooltip-txt">{tooltip ? tooltip : Description}</span>
    </div>
  );
};

export default ProseMirrorMenuToggleButton;
