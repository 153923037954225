import { ReactNode, useState } from "react";
import { Swiper } from "swiper/react";
import { Navigation, SwiperOptions } from "swiper";

const SwiperKlass = ({
  children,
  options = {},
  hasNavigation = true,
}: {
  children: ReactNode;
  options?: SwiperOptions;
  hasNavigation?: boolean;
}) => {
  const [swiperId] = useState<string>(Math.random().toString(36).substring(2, 7));

  return (
    <div id={`swiper-${swiperId}`} className="relative">
      <Swiper
        modules={[Navigation]}
        breakpoints={{
          0: {
            slidesPerView: 1.1,
            spaceBetween: 15,
          },
          375: {
            slidesPerView: 1.3,
            spaceBetween: 15,
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          540: {
            slidesPerView: 1.8,
            spaceBetween: 15,
          },
          760: {
            slidesPerView: 2.1,
            spaceBetween: 15,
          },
          820: {
            slidesPerView: 2.3,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        }}
        className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events lg:-ml-2.5"
        style={{ padding: "1px" }}
        parallax={true}
        navigation={{
          nextEl: `#swiper-${swiperId} .swiper-next-btn`,
          prevEl: `#swiper-${swiperId} .swiper-prev-btn`,
        }}
        {...options}
      >
        {children}
      </Swiper>
      {hasNavigation && (
        <div className="hidden lg:block">
          <button className="swiper-next-btn">
            <i className="ii-chevron-right"></i>
          </button>
          <button className="swiper-prev-btn">
            <i className="ii-chevron-left"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default SwiperKlass;
