import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from "react-query";
import {
  api,
  publicApi,
  StaticImg,
  Img,
  scrollTopList,
  seminarDttm,
  uidDecode,
  resizedImg,
} from "../../utils/util";
import Badge from "../../component/user/badge";
import Loading from "../../component/Loading";
import BoardListTable from "../../component/board/BoardListTable";
import ForumListTable from "../../component/forum/ForumListTable";
import ReactPaginate from "react-paginate";
import { useRef } from "react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import LevelIcon from "component/klass/LevelIcon";
import { CLASS_ROUTE } from "App";
import SubscribeButton from "component/user/subscribeButton";
import BlockButton from "component/user/blockButton";
import Badges, { BadgeView } from "component/user/badges";
import { useUserBadgesQuery } from "api/user/hooks";

const ProfilePage = () => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);
  const uid = uidDecode(useParams().uid);
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const tabList = [
    { name: "임상포럼", value: "forum" },
    { name: "게시판", value: "boards" },
  ];
  const LecturerUser = [
    { uid: "21012", name: "이용권" },
    { uid: "3709", name: "오한솔" },
    { uid: "7555", name: "정종현" },
    { uid: "5590", name: "김기도" },
    { uid: "5882", name: "이승철" },
    { uid: "2087", name: "박종환" },
    { uid: "6692", name: "박성용" },
    { uid: "22810", name: "송용승" },
    { uid: "19214", name: "성이수" },
  ];
  const LecturerInfo = LecturerUser.find((user) => user.uid === uid);
  const tab = urlParam.get("tab") || tabList[0].value;
  const page = parseInt(urlParam.get("page")) || 1;
  const boxRef = useRef(null);

  const { data: badges, isLoading: isBadgesLoading } = useUserBadgesQuery(uid);
  const { data: profile, isLoading: profileLoading } = useQuery(
    ["users", uid],
    () => api.get(`/users/${uid}`).then((res) => res.data),
    {
      staleTime: 15 * 60 * 1000,
      onError: (err) => {
        alert(err.response.data.msg);
      },
    },
  );

  const onTabClick = (value) => {
    const param = new URLSearchParams();
    param.append("page", 1);
    param.append("tab", value);
    navigate(`${window.location.pathname}?${param}`, { replace: true });
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    param.append("tab", tab);
    navigate(`${window.location.pathname}?${param}`, { replace: true });
    window.scrollTo(0, 0);
  };

  const { data: courses, isLoading: coursesLoading } = useQuery(
    ["KlassSearch", LecturerInfo],
    () => {
      return publicApi
        ?.get("/class/public/courses/search", {
          params: { lecturers: LecturerInfo.name },
        })
        .then((res) => {
          return res.data.results.hits;
        });
    },
    { enabled: !!LecturerInfo },
  );

  const { data: userSeminars, isLoading: userSeminarsLoading } = useQuery(["seminars", uid], () =>
    api
      .get(`/management/seminar/list?userUid=${uid}`, {
        params: { page: 1, per_page: 2 },
      })
      .then((res) => res.data),
  );

  const { data: userPostings, isLoading: userPostingsLoading } = useQuery(
    ["postings", uid, tab, page],
    () =>
      api
        .get(`/${tab}?userUid=${uid}&categoryCode=0`, {
          params: { page: page, per_page: 10 },
        })
        .then((res) => res.data),
  );

  useEffect(() => {
    if (page > 1 && boxRef.current) scrollTopList(boxRef);
  }, [page]);

  if (
    profileLoading ||
    !profile ||
    userPostingsLoading ||
    !userPostings ||
    userSeminarsLoading ||
    !userSeminars ||
    isBadgesLoading ||
    !badges
  )
    return <Loading />;

  const forumBadges = badges?.filter((badge) => badge.type === "forum");
  const qnaBadges = badges?.filter((badge) => badge.type === "qna");
  const boardBadges = badges?.filter((badge) => badge.type === "article");

  return (
    <section className="relative min-h-[100vh]">
      <Header />
      <article className="inner w-[1200px] pb-64 mx-auto wrapper">
        <div className="my-10 profile-page-box p-8 rounded-lg flex justify-between">
          <div>
            <div className="flex gap-2 items-center">
              <div className="text-2xl font-semibold max-w-[400px] truncate mr-3">
                {profile.nickname}
              </div>
              <Badge
                item_level={profile.level}
                style={{
                  position: "relative",
                  transform: "translate(0%, 0%)",
                  fontSize: "25px",
                }}
              />
              <Badges isOwner={profile.is_owner} uid={profile.uid} />
            </div>
            <div className="mb-1.5">
              <span className="text-secondary-600">구독자</span> {profile.subscription_cnt}
            </div>
            <div className="flex items-center divide-x divide-secondary-400">
              <div className="flex items-center gap-1 px-2 pl-0">
                {profile.rankings.qna < 20 && (
                  <img
                    className="rank_img"
                    src={StaticImg(`rank/rank_${profile.rankings.qna}.svg`)}
                    alt=""
                  ></img>
                )}
                <span className="text-gray-600">Q&A</span> {profile.rankings.qna}위
              </div>
              <div className="flex items-center gap-1 px-2">
                {profile.rankings.forum < 20 && (
                  <img
                    className="rank_img"
                    src={StaticImg(`rank/rank_${profile.rankings.forum}.svg`)}
                    alt=""
                  ></img>
                )}
                <span className="text-gray-600">임상포럼</span> {profile.rankings.forum}위
              </div>
              {forumBadges?.length > 0 && (
                <div className="flex items-center gap-1 px-2">
                  <BadgeView badge={forumBadges[0]} />
                  <span>
                    {forumBadges.map((badge) => badge.description).join(", ")} 임상포럼 명의
                  </span>
                </div>
              )}
              {qnaBadges?.length > 0 && (
                <div className="flex items-center gap-1 px-2">
                  <BadgeView badge={qnaBadges[0]} />
                  <span>{qnaBadges.map((badge) => badge.description).join(", ")} Q&A 치식인</span>
                </div>
              )}
              {boardBadges?.length > 0 && (
                <div className="flex items-center gap-1 px-2">
                  <BadgeView badge={boardBadges[0]} />
                  <span>
                    {boardBadges.map((badge) => badge.description).join(", ")} 게시판 추천좌
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <SubscribeButton uid={uid} />
            {token.uid !== parseInt(uid) && <BlockButton isBlocked={profile.blocked} uid={uid} />}
          </div>
        </div>
        <div className="flex justify-between">
          {userSeminars.board && userSeminars.board.length > 0 && (
            <section className="seminar_pick">
              <article className="section__tit__box">
                <div className="tit-box">
                  <span className="tit-txt">최근 등록한 세미나</span>
                </div>
              </article>
              <div className="list__wrap">
                <ul className="flex gap-5">
                  {userSeminars.board.slice(0, 2).map((item, idx) => {
                    return (
                      <li key={idx}>
                        <Link to={`/management/seminar/${item.bid}`} className="box-wrap">
                          <article className="seminar_pick_box">
                            <div className="img_box">
                              <img src={Img(item.thumbnail)} alt="" />
                            </div>
                            <div className="info-box">
                              <div className="top">
                                <div className="label-box">{item.topic}</div>
                                <div className="subject-box">{item.title}</div>
                              </div>
                              <div className="bottom">
                                <div className="location">
                                  <img
                                    className="lo"
                                    src={Img("", "img/ico-pin.svg")}
                                    alt="location"
                                  />
                                  <div className="content">{item.location1}</div>
                                </div>
                                <div className="date_score">
                                  <img
                                    src={Img("", "img/ico-calendar-event.svg")}
                                    alt="date_score"
                                  />
                                  <div className="content">
                                    <div className="date">
                                      {seminarDttm(item.start_date)}~ {seminarDttm(item.end_date)} |
                                      보수교육점수 <span className="black">{item.point}점</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </article>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          )}
          {!!LecturerInfo && (
            <section className="seminar_pick mb-10">
              <article className="section__tit__box flex justify-between">
                <div className="tit-box">
                  <span className="tit-txt">클래스에 등록된 강의 👨‍🏫</span>
                </div>
                <Link
                  to={`/class/search?lecturers=${LecturerInfo.name}`}
                  className="bg-secondary-900 text-white rounded-md py-2 px-3"
                >
                  <span className="text-mint">{LecturerInfo.name}</span>님의 강의 모두보기
                </Link>
              </article>
              {!coursesLoading && courses.length > 0 && (
                <div className="flex flex-col gap-5 w-[580px] mt-3">
                  {courses.slice(0, 2).map((item) => {
                    return (
                      <Link to={`/${CLASS_ROUTE}/course/${item.id}`} key={item.id}>
                        <CourseCard
                          thumbnail={item.thumbnailImageUrl}
                          title={item.title}
                          topic={item?.groupedBadges?.topics}
                          level={item?.groupedBadges?.level?.join(",")}
                        />
                      </Link>
                    );
                  })}
                </div>
              )}
            </section>
          )}
        </div>
        <section>
          <article className="section__tit__box" ref={boxRef}>
            <div className="tit-box">
              <span className="tit-txt">작성한 글</span>
            </div>
            <div className="flex mt20 profile-page">
              {tabList.map((item, idx) => {
                return (
                  <button
                    className={tab === item.value ? "active" : ""}
                    onClick={() => onTabClick(item.value)}
                    key={idx}
                  >
                    {item.name}
                  </button>
                );
              })}
            </div>
            <div className="list__wrap mt20">
              <article className="list__table2">
                {tab === "forum" && <ForumListTable articles={userPostings.forum} />}
                {tab === "boards" && <BoardListTable articles={userPostings.board} board={0} />}
              </article>
            </div>
            {userPostings.pages > 0 && (
              <article className="mypage paging" style={{ paddingTop: "70px" }}>
                <ReactPaginate
                  previousLabel="←"
                  previousLinkClassName="before"
                  nextLabel="→"
                  nextLinkClassName="next"
                  breakLabel="..."
                  pageCount={userPostings.pages}
                  forcePage={userPostings.page - 1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  pageLinkClassName="num"
                  activeClassName=""
                  activeLinkClassName="current"
                  onPageChange={onPageClick}
                  style={{ cursor: "pointer" }}
                />
              </article>
            )}
          </article>
        </section>
      </article>
      <Footer />
    </section>
  );
};

export default ProfilePage;

const CourseCard = ({ thumbnail, title, topic, level }) => {
  return (
    <section className="flex cursor-pointer shadow-card group p-[13px] rounded-lg">
      <div className="flex justify-center w-[200px] overflow-hidden rounded-md mr-3">
        <img
          src={resizedImg(thumbnail, "560")}
          alt=""
          className="rounded-md scale-100 ease-linear duration-300 lg:group-hover:scale-105"
        />
      </div>
      <div className="">
        <div className="mt-2 mb-2 text-base !text-gray-900 font-medium break-all overflow-hidden text-ellipsis line-clamp-2 h-11 group-hover:!text-primary-800">
          {title}
        </div>
        <div className="overflow-hidden h-[25px] border-b-[1px] border-solid border-gray-300">
          {topic?.map((item, idx) => (
            <div
              key={idx}
              className="inline-block bg-[#FAFAFA] mr-2 mb-1 px-1 rounded-sm !text-secondary-600 text-xs font-medium whitespace-nowrap"
            >
              {item}
            </div>
          ))}
        </div>
        <div className="relative pt-[6px] lg:pt-0">
          <div className="flex items-end justify-between h-[43px] lg:h-full lg:mt-1">
            <div className="flex items-center space-x-1">
              <div className="!text-secondary-800 text-xs font-medium leading-loose">
                {level ?? ""}
              </div>
              <LevelIcon level={level} background="bg-secondary-800" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
