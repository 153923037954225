import React, { useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { default as Select } from "react-select";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { api, scrollTopList, selectStyles } from "../../utils/util";
import ForumListTable from "./ForumListTable";
import { useQuery } from "react-query";
import Loading from "../Loading";

function ForumListUI({ current }) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const bid = parseInt({ ...useParams() }.bid || 0);
  const urlParam = new URLSearchParams(location.search);
  const sort = urlParam.get("sort") || "";
  const page = urlParam.has("page") ? parseInt(urlParam.get("page")) : 1;
  const q = urlParam.get("q") || "";
  const cate_cd = urlParam.has("topic") ? parseInt(urlParam.get("topic")) : 0;
  const params = { page, q, sort_type: sort };
  const [topic, setTopic] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(q);
  const sortOptions = [
    { label: "최신순", value: "" },
    { label: "추천순", value: "like" },
    { label: "조회순", value: "view" },
  ];

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["forumList", cate_cd, sort, params],
    () =>
      api
        .get(cate_cd ? `/forum/list/${cate_cd}` : `/forum/list`, {
          params,
        })
        .then((res) => res.data),
    { onError: (err) => console.log(err) },
  );

  const { data: topics } = useQuery(
    "forumCateCd",
    () => api.get("/forum/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        err.response
          ? alert(err.response.data.msg)
          : alert("임상포럼 카테고리를 받아오는 데 실패하였습니다");
      },
    },
  );

  const topicList = topics.slice();
  const param = (() => {
    const p = new URLSearchParams();
    page && p.append("page", page);
    cate_cd && p.append("topic", cate_cd);
    q && p.append("q", q);
    sort && p.append("sort", sort);
    return p.toString();
  })();

  const forumScroll = useRef();

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    cate_cd && param.append("topic", cate_cd);
    selected && param.append("page", selected);
    q && param.append("q", q);
    sort && param.append("sort", sort);
    navigate(current === "forum_page" ? `/forum?${param}` : `/forum/article/${bid}?${param}`);
    scrollTopList(forumScroll);
  };

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    cate_cd && param.append("topic", cate_cd);
    param.append("page", 1);
    param.append("sort", sort);
    searchKeyword && param.append("q", searchKeyword);
    navigate(current === "forum_page" ? `/forum?${param}` : `/forum/article/${bid}?${param}`);
    current === "forum_detail" ? scrollTopList(forumScroll) : window.scrollTo(0, 0);
  };

  if (pageInfoLoading) return <Loading />;
  return (
    <>
      <article className="list__table2" ref={forumScroll}>
        <div className="table__menu flex justify-between">
          <div className="flex">
            <button className="table-menu-btn type1">전체글</button>
            <div className="w-[250px] h-[40px] ml-3 text-[15px] text-start">
              <Select
                value={topic}
                onChange={(option) => {
                  setTopic(option);
                  urlParam.set("page", 1);
                  urlParam.set("topic", option.value);
                  urlParam.set("sort", sort);
                  navigate(`/forum?${urlParam}`);
                }}
                styles={selectStyles}
                options={topicList
                  .sort((a, b) => a.cate_cd - b.cate_cd)
                  .map((item) => {
                    return { label: item.cate_name, value: item.cate_cd };
                  })}
                isSearchable={false}
                placeholder="토픽 선택"
              />
            </div>
          </div>
          <div className="flex gap-3">
            <Select
              className="w-[100px] text-[15px]"
              value={sortOptions.filter((option) => option.value === sort)[0]}
              onChange={(option) => {
                urlParam.set("page", 1);
                urlParam.set("topic", cate_cd);
                urlParam.set("sort", option.value);
                navigate(`/forum?${urlParam}`);
              }}
              styles={selectStyles}
              options={sortOptions}
              isSearchable={false}
              placeholder="정렬"
            />
            {token.user_type === "doctor" && (
              <article className="paging" style={{ margin: "0" }}>
                <Link
                  to={{
                    pathname: `/forum/write/${cate_cd}`,
                  }}
                  className="write-btn"
                  style={{ position: "static" }}
                >
                  <i className="ic ic-write"></i>
                  <span className="txt">글쓰기</span>
                </Link>
              </article>
            )}
          </div>
        </div>
        <ForumListTable articles={pageInfo.forum} param={param} bid={bid} />
      </article>

      {pageInfo.page && (
        <article className="paging">
          <ReactPaginate
            previousLabel="←"
            previousLinkClassName="before"
            nextLabel="→"
            nextLinkClassName="next"
            breakLabel="..."
            pageCount={pageInfo.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            pageLinkClassName="num"
            activeClassName=""
            activeLinkClassName="current"
            onPageChange={onPageClick}
            forcePage={pageInfo.page - 1}
          />

          {token.user_type === "doctor" && (
            <Link
              to={{
                pathname: `/forum/write/${cate_cd}`,
              }}
              className="write-btn bottom"
            >
              <i className="ic ic-write"></i>
              <span className="txt">글쓰기</span>
            </Link>
          )}
        </article>
      )}
      <article className="list__search__form" style={{ marginTop: "47px" }}>
        <form className="search__form__box">
          <input
            type="search"
            placeholder="검색어를 입력해주세요."
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <button className="search-btn" onClick={(e) => search(e)}></button>
        </form>
      </article>
    </>
  );
}

export default ForumListUI;
