import { getAdsInventoryList, getAdsCampaign } from "./api";
import { useQuery, useQueries } from "react-query";
import { useUserGroupCode } from "api/mypage/hooks";

export const useAdsInventoryList = ({ pageName }, queryOptions) => {
  return useQuery({
    queryKey: `/banner/inventory/${pageName}`,
    queryFn: () => getAdsInventoryList(pageName),
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: 15 * 60 * 1000,
    ...queryOptions,
  });
};

export const useAdsCampaign = ({ name, adsInventoryList, pageName }, queryOptions) => {
  const { data: userGroupCode, isLoading: userGroupCodeLoading } = useUserGroupCode();

  return useQuery({
    queryKey: `/banner/campaign/${pageName}/${name}`,
    queryFn: () => getAdsCampaign(name, adsInventoryList, userGroupCode),
    enabled: !!name && !!adsInventoryList && !userGroupCodeLoading,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: 15 * 60 * 1000,
    ...queryOptions,
  });
};

export const useAdsCampaignList = ({ nameList, adsInventoryList, pageName }, queryOptions) => {
  const { data: userGroupCode, isLoading: userGroupCodeLoading } = useUserGroupCode();

  return useQueries(
    nameList.map((name) => ({
      queryKey: `/banner/campaign/${pageName}/${name}`,
      queryFn: () => getAdsCampaign(name, adsInventoryList, userGroupCode),
      enabled: !!nameList?.length && !!adsInventoryList && !userGroupCodeLoading,
      refetchOnWindowFocus: false,
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
      ...queryOptions,
    })),
  );
};
