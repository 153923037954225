import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { publicApi } from "../../utils/util";
import { SwiperSlide } from "swiper/react";
import SwiperKlass from "component/klass/SwiperKlass";
import Loading from "component/Loading";
import TopicsMenu from "component/klass/TopicsMenu";
import CourseCard from "component/klass/CourseCard";
import CoursesQuickSearchForm from "component/klass/CoursesQuickSearchForm";
import CoursesSearchForm from "component/klass/CoursesSearchForm";
import { Curation } from "types/klass";
import { CLASS_ROUTE } from "App";

import ClassBanner from "component/advertisment/banner";
const Klass = () => {
  // FIXME: ReactQuery 아직 학습이 필요합니다. Curation 모델 정의 해야합니다.
  const { data: curations, isLoading: curationsLoading } = useQuery<Curation[] | undefined, Error>(
    "KlassCurations",
    () =>
      publicApi?.get("/class/public/curations").then((res) => {
        return res.data.curations;
      }),
  );

  if (!curations || curationsLoading) return <Loading small />;

  return (
    <div className="relative">
      <div className="max-w-[1200px] mx-auto">
        <div className="hidden lg:block">
          <ClassBanner name="pc_Klass_top" page="Klass" />
        </div>
        <TopicsMenu />
        <hr className="hidden lg:block h-px bg-gray-200 border-0" />
      </div>
      <div className="max-w-[1200px] mx-[16px] lg:mx-auto">
        <div className="mt-8 mb-5 lg:mb-14">
          <div className="flex items-center">
            <div className="flex-1 mr-2.5">
              <Link
                className="inline-block h-[40px] lg:h-[44px] leading-[40px] lg:leading-[44px] px-3 rounded-md bg-secondary-800 text-[14px] lg:text-[16px] text-white whitespace-nowrap hover:bg-secondary-900"
                to={`/mypage/${CLASS_ROUTE}`}
              >
                <i className="ii ii-user-screen-outline mr-1.5 text-[16px] lg:text-[24px] text-white align-top"></i>
                내 강의실
              </Link>
            </div>
            <div className="hidden lg:block flex-0 pr-2">
              <CoursesQuickSearchForm />
            </div>
            <div className="flex-0">
              <CoursesSearchForm />
            </div>
          </div>
          <div className="lg:hidden overflow-auto mt-4 mx-[-16px] pb-3 px-[16px] whitespace-nowrap">
            <CoursesQuickSearchForm />
          </div>
        </div>
        {curations[0] && (
          <div className="my-10 lg:my-14">
            <h3 className="mb-2 text-gray-900 text-[20px] font-semibold">{curations[0].title}</h3>
            <SwiperKlass>
              {curations[0].courses?.map((item) => {
                const course =
                  item.courses?.find((course) => course.courseId === item.defaultCourseId) || item;
                return (
                  <SwiperSlide key={course.courseId}>
                    <Link className="block" to={`/${CLASS_ROUTE}/course/${course.courseId}`}>
                      <CourseCard
                        thumbnail={course.thumbnailImage.link}
                        lecturers={course.lecturerNames}
                        title={course.title}
                        regularPrice={course.products[0]?.regularPrice}
                        salePrice={course.products[0]?.salePrice}
                        topic={course.groupedBadges.topics}
                        level={course.groupedBadges.level?.join(",")}
                        isSwiper
                        isFree={course.isFree}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </SwiperKlass>
          </div>
        )}
        <>
          <div className="md:grid my-10 lg:my-14 grid grid-flow-row md:grid-flow-col md:grid-cols-3 gap-5 md:gap-5 lg:gap-11">
            <ClassBanner name="pc_Klass_middle_left" page="Klass" width="full" />
            <ClassBanner name="pc_Klass_middle_center" page="Klass" width="full" />
            <ClassBanner name="pc_Klass_middle_right" page="Klass" width="full" />
          </div>
        </>
        {curations[1] && (
          <div className="my-10 lg:my-14">
            <h3 className="mb-2 text-gray-900 text-[20px] font-semibold">{curations[1].title}</h3>
            <SwiperKlass>
              {curations[1].courses?.map((item) => {
                const course =
                  item.courses?.find((course) => course.courseId === item.defaultCourseId) || item;
                return (
                  <SwiperSlide key={course.courseId}>
                    <Link className="block" to={`/${CLASS_ROUTE}/course/${course.courseId}`}>
                      <CourseCard
                        thumbnail={course.thumbnailImage.link}
                        lecturers={course.lecturerNames}
                        title={course.title}
                        regularPrice={course.products[0]?.regularPrice}
                        salePrice={course.products[0]?.salePrice}
                        topic={course.groupedBadges.topics}
                        level={course.groupedBadges.level?.join(",")}
                        isSwiper
                        isFree={course.isFree}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </SwiperKlass>
          </div>
        )}
        <>
          <div className="hidden lg:block my-10 lg:my-14">
            <ClassBanner name="pc_Klass_middle_second" page="Klass" />
          </div>
        </>
        {curations
          .filter((curation, index) => curation && index > 1)
          .map((curation) => {
            return (
              <div className="my-10 lg:my-14" key={curation.curationId}>
                <h3 className="mb-2 text-gray-900 text-[20px] font-semibold">{curation.title}</h3>
                <div className="hidden lg:block">
                  <SwiperKlass>
                    {curation.courses?.map((item) => {
                      const course =
                        item.courses?.find((course) => course.courseId === item.defaultCourseId) ||
                        item;
                      return (
                        <SwiperSlide key={course.courseId}>
                          <Link className="block" to={`/${CLASS_ROUTE}/course/${course.courseId}`}>
                            <CourseCard
                              thumbnail={course.thumbnailImage.link}
                              lecturers={course.lecturerNames}
                              title={course.title}
                              regularPrice={course.products[0]?.regularPrice}
                              salePrice={course.products[0]?.salePrice}
                              topic={course.groupedBadges.topics}
                              level={course.groupedBadges.level?.join(",")}
                              isSwiper
                              isFree={course.isFree}
                            />
                          </Link>
                        </SwiperSlide>
                      );
                    })}
                  </SwiperKlass>
                </div>
                <div className="lg:hidden">
                  <div className="lg:mx-[-10px] grid grid-cols-2 lg:grid-cols-4 gap-3">
                    {curation.courses?.map((item) => {
                      const course =
                        item.courses?.find((course) => course.courseId === item.defaultCourseId) ||
                        item;
                      return (
                        <Link
                          className="block"
                          to={`/${CLASS_ROUTE}/course/${course.courseId}`}
                          key={course.courseId}
                        >
                          <CourseCard
                            thumbnail={course.thumbnailImage.link}
                            lecturers={course.lecturerNames}
                            title={course.title}
                            regularPrice={course.products[0]?.regularPrice}
                            salePrice={course.products[0]?.salePrice}
                            topic={course.groupedBadges.topics}
                            level={course.groupedBadges.level?.join(",")}
                            isFree={course.isFree}
                          />
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Klass;
