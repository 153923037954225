import { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import TextEditor from "../../component/text_editor";
import { api } from "../../utils/util";
import ProsemirrorEditor from "../../component/prosemirror/ProsemirrorEditor";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import Loading from "component/Loading";
import { DOMSerializer } from "prosemirror-model";

function ForumWrite() {
  const { state } = useLocation();
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();
  const [cate_cd, setCateCd] = useState(state?.forum?.cate_cd ?? 0);
  const titleRef = useRef(null);
  const [content, setContent] = useState(state?.forum?.content ?? "");
  const savedContentJson = state?.forum?.contentJson ?? "";
  const param = new URLSearchParams(useLocation().search);
  const editor = param.get("editor");
  const editorStateGetterRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [thumbnails, setThumbnails] = useState(null);

  // const [images, setImages] = useState(state.images ? state.images.map((item) => item.link) : []);

  const { data: topic } = useQuery(
    "forumCateCd",
    () => api.get("/forum/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        err.response
          ? alert(err.response.data.msg)
          : alert("임상포럼 카테고리를 받아오는 데 실패하였습니다");
      },
    },
  );

  const topicItem = [...topic].sort((a, b) => a.cate_cd - b.cate_cd);

  const submit = async (e) => {
    e.preventDefault();
    const title = titleRef.current?.value;
    const pmEditorState = editorStateGetterRef.current?.getEditorState();

    if (title.length < 5) {
      alert("제목을 5글자 이상 입력해주세요");
      return;
    }

    if (!content && !pmEditorState) return;

    const pmHTMLContent =
      pmEditorState?.schema &&
      DOMSerializer.fromSchema(pmEditorState.schema).serializeFragment(
        pmEditorState.doc.content,
        { document },
        document.createElement("div"),
      );
    const pmTextContent = pmHTMLContent?.textContent || "";
    const pmContentJson = pmEditorState?.doc.toJSON();
    const thumbnail =
      editor === "froala"
        ? thumbnails
        : (pmHTMLContent?.innerHTML || "")
            .split("<img")[1]
            ?.split("src=")[1]
            ?.split('"')[1]
            ?.replace(/&amp;/g, "&");

    const data = pmEditorState
      ? {
          cate_cd,
          title,
          content: pmHTMLContent?.innerHTML || "",
          textContent: pmTextContent,
          contentJson: pmContentJson,
          images: thumbnail ? [thumbnail] : [],
        }
      : {
          cate_cd,
          title,
          content: content,
          textContent: new DOMParser().parseFromString(content, "text/html").body.textContent,
          images: thumbnail ? [thumbnail] : [],
        };

    try {
      setLoading(true);
      if (!state?.forum) {
        const res = await api.post(`/forum/write/${cate_cd}`, data);
        localStorage.removeItem("autosave:pm-article:");
        localStorage.removeItem("autosave:pm-article-length:");

        navigate(`/forum/article/${res.data.bid}`, {
          // state: { forum: state?.forum },
          replace: true,
        });
      } else {
        const res = await api.post(`/forum/modify/${state.forum.bid}`, data);
        localStorage.removeItem("autosave:pm-article:");
        localStorage.removeItem("autosave:pm-article-length:");
        navigate(`/forum/article/${res.data.bid}`, {
          state: { forum: state?.forum },
          replace: true,
        });
      }
    } catch (e) {
      alert(e?.response?.data?.msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <div className="wrapper">
        <section className="board__reg" style={{ marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">임상포럼 작성</span>
              </div>
            </article>
            <div className="reg__top__box">
              <div className="topic-box">
                <div className="sbox">
                  <select
                    onChange={(e) => {
                      setCateCd(e.target.value);
                    }}
                    value={cate_cd}
                  >
                    <option hidden>게시판을 선택해주세요.</option>
                    {topicItem &&
                      topicItem
                        .filter((item) => item.cate_cd !== 0)
                        .map((item) => {
                          return (
                            <option value={item.cate_cd} key={item.cate_cd}>
                              {item.cate_name}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>

              <div className="tit-box">
                <input
                  className="width-max"
                  ref={titleRef}
                  defaultValue={state?.forum?.title}
                  type="text"
                  placeholder="5글자 이상의 제목을 입력해주세요."
                />
              </div>
            </div>

            <div className="reg__mid__box">
              {editor === "froala" ? (
                <TextEditor setThumbnails={setThumbnails} useContent={[content, setContent]} />
              ) : (
                <ProsemirrorEditor
                  savedContentJson={savedContentJson}
                  isAdmin={token?.user_id === "admin"}
                  ref={editorStateGetterRef}
                />
              )}
            </div>
            <div className="reg__menu__box">
              <button
                onClick={() => navigate(-1)}
                className="menu-btn type1"
                style={{ border: "none", outline: "none" }}
              >
                취소
              </button>
              <button
                disabled={loading}
                onClick={submit}
                className="menu-btn type2"
                style={{ border: "none", outline: "none" }}
              >
                {!!state?.forum ? "수정" : "등록"}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForumWrite;
