import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { default as Select } from "react-select";
import AttachImages from "../../../component/shared/AttachImages";
import { api, selectStyles } from "../../../utils/util";
import { seminar_topic, pointOptions } from "../../../constants";
import dayjs from "dayjs";
import RegionFilter from "../RegionFilter";
import { useMutation } from "react-query";

function SeminarWritePage(props) {
  const navigate = useNavigate();
  const { state: modify } = useLocation();
  const { bid: article_num } = useParams();

  const [title, setTitle] = useState(modify ? modify.article.title : "");
  const [content, setContent] = useState(modify ? modify.article.content : "");
  const [info, setInfo] = useState({
    start_date: modify ? modify.article.start_date : null,
    end_date: modify ? modify.article.end_date : null,
    location: modify ? modify.article.location : null,
    host: modify ? modify.article.host : null,
    topic: modify ? seminar_topic.find((item) => item.value === modify.article.topic) : null,
    point: modify ? pointOptions.find((item) => item.value === modify.article.point) : null,
    time_desc: modify ? modify.article.time_desc : null,
    reg_fee: modify ? modify.article.reg_fee : null,
    website: modify ? modify.article.website : null,
    contact: modify ? modify.article.contact : null,
  });
  const [loc, setLoc] = useState({
    view: false,
    loc1: modify ? modify.article.location1 : null,
    loc2: modify ? [modify.article.location2] : [],
  });
  const [images, setImages] = useState(modify ? modify.images.map((item) => item.link) : []);
  const locationOption = [
    { value: "온/오프라인", label: "온/오프라인" },
    { value: "온라인", label: "온라인" },
    { value: "오프라인", label: "오프라인" },
  ];

  const writeArticle = useMutation((data) => api.post(`/management/seminar/write`, data), {
    onSuccess: (res) => {
      navigate(`/management/seminar/${res.data.bid}`, { replace: true });
    },
    onError: (err) => alert(err?.response?.data?.msg),
  });

  const modifyArticle = useMutation(
    (data) => api.post(`/management/seminar/modify/${article_num}`, data),
    {
      onSuccess: (res) => {
        navigate(`/management/seminar/${res.data.bid}`, { replace: true });
      },
      onError: (err) => alert(err?.response?.data?.msg),
    },
  );

  const submit = async () => {
    if (!info.topic) {
      alert("토픽을 선택해주세요");
      return;
    }
    if (!info.point) {
      alert("보수교육 정보를 입력해주세요");
      return;
    }
    if (!info.start_date) {
      alert("시작 날짜를 입력해주세요");
      return;
    }
    if (!info.end_date) {
      alert("마치는 날짜를 입력해주세요");
      return;
    }
    if (!info.time_desc) {
      alert("시간을 입력해주세요");
      return;
    }
    if (info.reg_fee == null) {
      alert("등록비를 입력해주세요");
      return;
    }
    if (!info.location) {
      alert("강의 장소를 선택해주세요");
      return;
    }
    if (!loc.loc1 || !loc.loc2) {
      alert("주소를 입력해주세요");
      return;
    }
    if (!info.host) {
      alert("주최를 입력해주세요");
      return;
    }
    if (title.length < 5) {
      alert("제목은 5자 이상 입력해주세요");
      return;
    }

    const data = {
      title: title,
      content: content ?? "",
      location: info.location.value ? info.location.value : info.location,
      location1: loc.loc1,
      location2: loc.loc2 ? loc.loc2 : null,
      start_date: info.start_date ? dayjs(info.start_date).format("YYYY-MM-DD") : null,
      end_date: info.end_date ? dayjs(info.end_date).format("YYYY-MM-DD") : null,
      host: info.host,
      topic: info.topic.value,
      point: info.point.value,
      time_desc: info.time_desc,
      reg_fee: info.reg_fee,
      website: info.website ? info.website : null,
      contact: info.contact ? info.contact : null,
      images: images.length > 0 ? images : [],
    };

    if (modify) modifyArticle.mutate(data);
    else writeArticle.mutate(data);
  };

  return (
    <div>
      <div className="wrapper">
        <section className="board__reg" style={{ marginTop: "54px", marginBottom: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">세미나</span>
              </div>
            </article>

            <div className="reg__top__box">
              <div className="row-list">
                <div className="row">
                  <div className="sbox" style={{ width: "300px", border: "none" }}>
                    <Select
                      value={info.topic}
                      onChange={(option) => setInfo({ ...info, topic: option })}
                      styles={selectStyles}
                      options={seminar_topic}
                      isSearchable={false}
                      placeholder="토픽을 선택해주세요"
                    />
                  </div>
                  <div className="sbox-job" style={{ width: "300px", border: "none" }}>
                    <Select
                      value={info.point}
                      onChange={(option) => setInfo({ ...info, point: option })}
                      styles={selectStyles}
                      options={pointOptions}
                      isSearchable={false}
                      placeholder="보수교육 점수를 선택해주세요"
                    />
                  </div>
                </div>

                <div className="row" style={{ marginTop: "21px" }}>
                  <div className="row">
                    <div className="addr-box">
                      <div className="addr-info-txt">기간을 입력해주세요.</div>
                      <div className="addr-row-box2">
                        <input
                          style={{ width: "300px" }}
                          placeholder={`${dayjs().format("YYYY-MM-DD")}`}
                          value={dayjs(info.start_date).format("YYYY-MM-DD")}
                          type="date"
                          onChange={(e) => setInfo({ ...info, start_date: e.target.value })}
                        />
                      </div>
                      <span style={{ margin: "0px 2px" }}>~</span>
                      <div className="addr-row-box2">
                        <input
                          style={{ width: "300px" }}
                          placeholder={`${dayjs().format("YYYY-MM-DD")}`}
                          value={dayjs(info.end_date).format("YYYY-MM-DD")}
                          type="date"
                          onChange={(e) => setInfo({ ...info, end_date: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="addr-box">
                      <div className="addr-info-txt">
                        시간을 입력해주세요. (255자 이하로 입력해주세요)
                      </div>
                      <div className="addr-row-box">
                        <input
                          type="text"
                          style={{ width: "300px" }}
                          placeholder="매주 수요일 13:30~14:00"
                          value={info.time_desc}
                          onChange={(e) => setInfo({ ...info, time_desc: e.target.value })}
                          maxLength={255}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">등록비를 입력해주세요.</div>
                    <div className="addr-row-box">
                      <input
                        style={{ width: "300px" }}
                        placeholder="0원"
                        value={info.reg_fee}
                        onChange={(e) => setInfo({ ...info, reg_fee: e.target.value })}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">온/오프라인을 선택해주세요.</div>

                    <div className="sbox" style={{ width: "300px", border: "none" }}>
                      <Select
                        value={locationOption.find((item) => item.value === info.location)}
                        onChange={(option) => setInfo({ ...info, location: option })}
                        styles={selectStyles}
                        options={locationOption}
                        isSearchable={false}
                        placeholder="강의 장소를 선택해주세요"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">세미나 장소를 입력해주세요.</div>
                  </div>
                  <div className="location-box" style={{ marginTop: "-30px" }}>
                    <div className="item">
                      <button
                        onClick={() => {
                          loc.view
                            ? setLoc({ ...loc, view: false })
                            : setLoc({ ...loc, view: true });
                        }}
                        className="select-btn"
                      >
                        {loc.loc1 ? loc.loc1 + " - " + loc.loc2[0] : "세미나 지역을 선택해주세요"}
                      </button>
                      {loc.view && <RegionFilter useLoc={[loc, setLoc]} single withOnline />}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">주최를 입력해주세요.</div>

                    <div className="addr-row-box">
                      <input
                        style={{ width: "300px" }}
                        placeholder="모어덴"
                        value={info.host}
                        onChange={(e) => setInfo({ ...info, host: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">홈페이지 주소를 입력해주세요.</div>

                    <div className="addr-row-box">
                      <input
                        style={{ width: "300px" }}
                        placeholder="https://www.moreden.co.kr"
                        value={info.website}
                        onChange={(e) => setInfo({ ...info, website: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="addr-box">
                    <div className="addr-info-txt">문의 받으실 번호를 입력해주세요.</div>
                    <div className="addr-row-box">
                      <input
                        style={{ width: "300px" }}
                        placeholder="모어덴 / 02-2281-3124 / seminar@moreden.co.kr"
                        value={info.contact}
                        onChange={(e) => setInfo({ ...info, contact: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tit-box">
              <div className="tbox full">
                <input
                  className="title"
                  type="text"
                  placeholder="제목을 입력해주세요."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="reg__mid__box">
              <textarea
                placeholder="여기를 눌러 글을 작성할 수 있습니다."
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>

            <div className="reg__bottom__box">
              <AttachImages useImages={[images, setImages]} />
            </div>
            <div className="reg__menu__box">
              <button
                className="menu-btn type1"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/management/seminar`, { replace: true });
                }}
              >
                취소
              </button>
              <button onClick={submit} className="menu-btn type2">
                등록
              </button>
            </div>
          </div>
        </section>
      </div>
      {/* {loc.view &&
        <AddressSelect state={loc} setState={setLoc} />
      } */}
    </div>
  );
}

export default SeminarWritePage;
