import React from "react";
import "./EditorView.css";

export interface Content {
  blocks: {
    data: {
      level?: number; // header
      text?: string; // header, paragraph, quote
      alignment?: string;
      caption?: string; //image, quote
      file?: {
        //image
        url: string;
      };
      stretched?: boolean;
      withBackground?: boolean;
      withBorder?: boolean;
      backgroundColors?: [][];
      colSpans?: any; //table
      columnWidths?: [][]; //table
      content?: [][]; //table
      headers?: [][]; //table
      rowSpans?: any; //table
      style?: string; //list
      items?: any[]; //list
      message?: string; //alert
      type?: string; //alert
      html?: string; //raw html
    };
    id: string;
    type: string;
  }[];
}

const EditorView = ({ content }: { content: Content | string }) => {
  if (typeof content === "string") return null;
  return (
    <>
      <article className="editorjs-view">
        {content?.blocks?.map((block) => {
          return (
            <React.Fragment key={block.id}>
              {/* header */}
              {block.type === "header" && block.data.text && (
                <>
                  {block.data.level === 1 && (
                    <h1 dangerouslySetInnerHTML={{ __html: block.data.text }}></h1>
                  )}

                  {block.data.level === 2 && (
                    <h1 dangerouslySetInnerHTML={{ __html: block.data.text }}></h1>
                  )}

                  {block.data.level === 3 && (
                    <h1 dangerouslySetInnerHTML={{ __html: block.data.text }}></h1>
                  )}

                  {block.data.level === 4 && (
                    <h1 dangerouslySetInnerHTML={{ __html: block.data.text }}></h1>
                  )}

                  {block.data.level === 5 && (
                    <h1 dangerouslySetInnerHTML={{ __html: block.data.text }}></h1>
                  )}

                  {block.data.level === 6 && (
                    <h1 dangerouslySetInnerHTML={{ __html: block.data.text }}></h1>
                  )}
                </>
              )}
              {/* paragraph */}
              {block.type === "paragraph" && block.data.text && (
                <p
                  dangerouslySetInnerHTML={{ __html: block.data.text }}
                  className={block.data.alignment}
                ></p>
              )}
              {/* list */}
              {block.type === "list" && (
                <>
                  {block.data?.style === "ordered" && (
                    <ol>
                      {block.data?.items?.map((item, idx) => {
                        return (
                          <li key={idx} dangerouslySetInnerHTML={{ __html: item.content }}></li>
                        );
                      })}
                    </ol>
                  )}
                  {block.data?.style === "unordered" && (
                    <ul>
                      {block.data?.items?.map((item, idx) => {
                        return (
                          <li key={idx} dangerouslySetInnerHTML={{ __html: item.content }}></li>
                        );
                      })}
                    </ul>
                  )}
                </>
              )}
              {/* image */}
              {block.type === "image" && (
                <figure>
                  <img src={block.data.file?.url} alt="" />
                  {block.data?.caption && (
                    <figcaption
                      dangerouslySetInnerHTML={{ __html: block.data.caption }}
                    ></figcaption>
                  )}
                </figure>
              )}
              {/* table*/}
              {block.type === "table" && (
                <table>
                  <colgroup>
                    {(block.data.columnWidths as [])?.map((colWidth, colIndex) => {
                      return <col key={colIndex} width={`${colWidth * 100 + "%"}`} />;
                    })}
                  </colgroup>
                  <tbody>
                    {block.data.content?.map((row, rowIndex) => {
                      return (
                        <tr key={`'tr-' + ${rowIndex}`}>
                          {row?.map((col, colIndex) => {
                            return (
                              <React.Fragment key={`'th-' + ${colIndex}`}>
                                {block.data.headers && block.data.headers[rowIndex][colIndex] ? (
                                  <th
                                    dangerouslySetInnerHTML={{ __html: col }}
                                    rowSpan={`${block.data?.rowSpans[rowIndex][colIndex]}` as any}
                                    colSpan={`${block.data?.colSpans[rowIndex][colIndex]}` as any}
                                  ></th>
                                ) : (
                                  <td
                                    dangerouslySetInnerHTML={{ __html: col }}
                                    rowSpan={`${block.data?.rowSpans[rowIndex][colIndex]}` as any}
                                    colSpan={`${block.data?.colSpans[rowIndex][colIndex]}` as any}
                                  ></td>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {/* alert */}
              {block.type === "alert" && block.data?.message && (
                <section
                  className={`alert ${block.data.type}`}
                  dangerouslySetInnerHTML={{ __html: block.data?.message }}
                ></section>
              )}
              {/* quote */}
              {block.type === "quote" && (
                <>
                  {block.data.caption && (
                    <blockquote>
                      <div dangerouslySetInnerHTML={{ __html: block.data.text ?? "" }}></div>
                      <div>
                        <hr />
                        <em dangerouslySetInnerHTML={{ __html: block.data.caption }}></em>
                      </div>
                    </blockquote>
                  )}
                </>
              )}
              {/* raw html */}
              {block.type === "raw" && block.data.html && (
                <div dangerouslySetInnerHTML={{ __html: block.data.html }}></div>
              )}
            </React.Fragment>
          );
        })}
      </article>
    </>
  );
};
export default EditorView;
