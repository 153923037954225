import Footer from "component/footer/Footer";
import Header from "component/header/Header";
import { useEffect } from "react";

const Chicruiting = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `${import.meta.env.VITE_APP_CHICRUITING_URL}/assets/index.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="relative min-h-[100vh] pb-[200px]">
      <Header current="chicruiting" />
      <div id="moreden-chicruiting-root"></div>
      <Footer />
    </section>
  );
};

export default Chicruiting;
