import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import SideMenu from "../../component/mypage/SideMenu";
import { api } from "../../utils/util";
import ForumListTable from "../../component/forum/ForumListTable";
import BoardListTable from "../../component/board/BoardListTable";
import ManageListTable from "../../component/management/ManageListTable";
import ContentsListTable from "../../component/contents/ContentsListTable";
import MypageTableLoader from "./MypageTableLoader";
import { useLocation, useNavigate } from "react-router-dom";
import QnaListTable from "../../component/qna/QnaListTable";

function MyScrap() {
  const navigate = useNavigate();
  const location = useLocation();
  const tabList = [
    { name: "Q&A", value: "qna" },
    { name: "콘텐츠", value: "contents" },
    { name: "임상포럼", value: "forum" },
    { name: "게시판", value: "board" },
    { name: "구인구직", value: "recruit" },
    { name: "병원양도/임대", value: "estate" },
    { name: "중고장터", value: "market" },
    { name: "세미나", value: "seminar" },
  ];
  const [article, setArticle] = useState({ items: [], load: false });
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const tab = urlParam.get("tab") || tabList[0].value;

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    param.append("tab", tab);
    navigate(`/mypage/myscrap?${param}`);
    window.scrollTo(0, 0);
  };

  const onTabClick = (value) => {
    const param = new URLSearchParams();
    param.append("page", 1);
    param.append("tab", value);
    setArticle({ load: false });
    navigate(`${window.location.pathname}?${param}`);
  };

  useEffect(() => {
    const data = new URLSearchParams();
    page && data.append("page", page);
    api
      .get(`/mypage/myscrap/${tab}`, {
        params: data,
      })
      .then((res) => {
        setArticle({ ...res.data, load: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, page]);

  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="my_scrap" />
            <div className="con__layout">
              <section className="bookmark" style={{ marginBottom: "113.5px" }}>
                <article className="section__tit__box">
                  <div className="tit-box">
                    <span className="tit-txt">내가 스크랩한 글</span>
                  </div>
                </article>

                <article className="section__tit__box2 border-none" style={{ marginTop: "20.5px" }}>
                  <div className="tit-box">
                    {tabList.map((item) => {
                      return (
                        <button
                          className={tab === item.value ? "tab-name selected" : "tab-name"}
                          onClick={() => onTabClick(item.value)}
                          key={item.value}
                        >
                          {item.name}
                        </button>
                      );
                    })}
                  </div>
                </article>

                <div className="list__wrap">
                  {article.load ? (
                    article.items && article.items.length > 0 ? (
                      <>
                        {tab === "qna" && (
                          <article className="list__table2">
                            <QnaListTable articles={article.items} />
                          </article>
                        )}
                        {tab === "forum" && (
                          <article className="list__table2">
                            <ForumListTable articles={article.items} />
                          </article>
                        )}
                        {tab === "board" && (
                          <article className="list__table2">
                            <BoardListTable articles={article.items} board={0} />
                          </article>
                        )}
                        {tab === "contents" && <ContentsListTable articles={article.items} />}
                        {(tab === "recruit" ||
                          tab === "estate" ||
                          tab === "market" ||
                          tab === "seminar") && (
                          <article className="list__table2">
                            <ManageListTable current={tab} articles={article.items} />
                          </article>
                        )}
                      </>
                    ) : (
                      <article className="no-data my-post">
                        <img
                          src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_no_history_data.svg`}
                          alt="이미지"
                        />
                        <div className="txt">내가 스크랩한 글이 없습니다.</div>
                      </article>
                    )
                  ) : (
                    <MypageTableLoader />
                  )}
                </div>

                {article.pages > 0 && (
                  <article className="mypage paging" style={{ paddingTop: "70px" }}>
                    <ReactPaginate
                      previousLabel="←"
                      previousLinkClassName="before"
                      nextLabel="→"
                      nextLinkClassName="next"
                      breakLabel="..."
                      pageCount={article.pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={4}
                      pageLinkClassName="num"
                      activeClassName=""
                      activeLinkClassName="current"
                      onPageChange={onPageClick}
                      style={{ cursor: "pointer" }}
                    />
                  </article>
                )}
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MyScrap;
