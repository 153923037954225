import { Extension } from "../core/extension"
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

export const Doc = Extension.Create({
  name: 'doc',

  type: 'node',
  
  defineSpec() {
    return {
      content: 'block+',
    }
  }
})

export const Text = Extension.Create({
  name: 'text',

  type: 'node',

  defineSpec() {
    return {
      group: 'inline',
    }
  }
})

export const HardBreak = Extension.Create({
  name: 'hard_break',

  type: 'node',

  defineSpec() {
    return {
      inline: true,
      group: 'inline',
      selectable: true,
      atom: true,
      parseDOM: [{tag: 'br'}],
      toDOM() {
        return ['br', {class: MEDISTREAM_SCHEMA_STYLE.nodes.br}]
      },
    }
  },

  addCommands() {
    return {
      insertHardBreak: (state, dispatch) => {
        if (dispatch)
          dispatch(state.tr.replaceSelectionWith(state.schema.nodes[this.name].create()).scrollIntoView())
        return true
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      'Shift-Enter': this.editor.commands.insertHardBreak,
    }
  }
})
