export const TeefitLogo = () => {
  return (
    <svg
      width="128"
      height="37"
      viewBox="0 0 128 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.81413 23.5667V12.2855H0L0.134556 8.888H13.052L13.1673 12.2855H8.35318V23.5667H4.81413Z"
        fill="#0067FF"
      />
      <path
        d="M23.0137 23.5667V8.888H34.577L34.7115 12.1373H26.5335V14.4857H33.0307V17.5683H26.5335V20.315H34.9636L34.8482 23.5643H23.0158L23.0137 23.5667Z"
        fill="#0067FF"
      />
      <path
        d="M45.1719 23.5667V8.888H56.7352L56.8697 12.1373H48.6917V14.4857H55.1889V17.5683H48.6917V20.315H57.1218L57.0064 23.5643H45.174L45.1719 23.5667Z"
        fill="#0067FF"
      />
      <path
        d="M84.3838 1.89364L87.4914 1.02515C88.8818 0.636064 90.3427 0.652276 91.7246 1.07147L94.1915 1.81721C96.968 2.65791 98.7664 5.5691 98.4225 8.67251C97.9655 12.8019 97.9035 17.0286 96.8356 21.0514C95.9151 24.5207 93.9138 29.243 90.1996 29.873C86.3188 30.5307 81.9426 25.9265 81.4983 21.9176L80.0438 8.78136C79.6957 5.62931 81.5517 2.6857 84.3859 1.89364H84.3838Z"
        fill="#0067FF"
      />
      <path
        d="M69.4334 1.18994L70.5718 0.872653C71.5564 0.597051 72.6029 0.74759 73.4872 1.29184L77.1565 3.54529C79.7836 5.15953 81.2316 8.35326 80.8066 11.5933L79.4482 21.9272C78.9783 26.1678 75.6593 29.3615 71.7208 29.3615C67.7504 28.4096 64.7282 24.9102 64.1131 20.5515L62.8529 11.6327C62.3232 6.85482 65.1383 2.38962 69.4355 1.18763L69.4334 1.18994Z"
        fill="#0067FF"
      />
      <path
        d="M105.703 0.0802894C107.493 0.337363 109.165 1.20817 111.009 1.9076C111.978 2.27584 112.852 2.09751 113.901 2.03497C118.437 1.76632 122.978 4.05914 125.616 8.08894C126.923 10.083 127.711 12.4476 127.854 14.891C128.046 18.131 126.91 21.2483 127.064 24.4698C127.219 27.7284 126.972 31.3877 124.58 33.7013C121.694 36.4944 116.555 36.8626 113.264 34.857C112.608 34.4563 111.989 33.9654 111.271 33.7175C107.79 32.5179 105.116 32.5619 102.109 29.7873C99.362 27.2537 97.5658 23.6546 97.6961 19.6827C97.6961 19.6827 98.1339 6.38901 98.1361 6.37048C98.2322 3.42688 100.054 1.15027 102.619 0.328099C103.715 -0.0239296 104.725 -0.0656172 105.701 0.0756574L105.703 0.0802894Z"
        fill="#0067FF"
      />
      <path
        d="M68.1797 20.2317V8.888H77.0113L77.101 11.4147H70.9157V13.7145H76.1014V16.1602H70.9157V20.227H68.1818L68.1797 20.2317Z"
        fill="white"
      />
      <path d="M88.1035 20.2317V8.888H90.8374V20.2293H88.1035V20.2317Z" fill="white" />
      <path
        d="M104.656 20.2316V11.5143H100.936L101.04 8.89026H111.021L111.111 11.5143H107.39V20.2316H104.656V20.2316Z"
        fill="white"
      />
    </svg>
  );
};
