import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";

import { useRecoilValue } from "recoil";
import dayjs from "dayjs";

import CoursePopup from "./CoursePopup";
import { VodPlay } from "./Curriculum";
import LectureVideo from "./LectureVideo";

import { CLASS_ROUTE } from "App";
import { tokenState } from "state";
import { publicApi, klassExpiredAt, marketUrl } from "utils/util";
import { isOfflineClass } from "utils/marketUtil";
import { Course, Progress } from "types/klass";

type KlassPurchaseButtonProps = {
  simple?: boolean;
  course: Course;
  progress?: Progress;
  defaultCourseId?: string;
};

const KlassPurchaseButton: React.FC<KlassPurchaseButtonProps> = ({
  simple,
  course,
  progress,
  defaultCourseId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const params = new URLSearchParams(location.search);
  const chapterId = params.get("chapterId");
  const lectureId = params.get("lectureId");
  const token = useRecoilValue(tokenState);

  const [coursePopup, setCouresPopup] = useState<string>("");
  const [vodPopup, setVodPopup] = useState<VodPlay>({ popup: false, bookmark: 0, lectureId: "" });
  const [createOrderLoading, setCreateOrderLoading] = useState<boolean>(false);

  const isForSale = dayjs(course?.sellStartAt) <= dayjs() && dayjs() < dayjs(course?.sellEndAt); // 판매중인지 확인
  const isFinished = dayjs().isAfter(dayjs(course.learnEndAt)); // 교육종료된 강의인지 확인
  const isExpired = progress && dayjs().isAfter(dayjs(progress?.expireAt)); // 수강종료된 강의인지 확인
  const productId = course?.products[0]?.productId;
  const variantId = course?.products[0]?.variantId;

  const clickContinuePlay = () => {
    // 마지막으로 본 강의가 있는 경우 해당 강의의 id로 이동, 없는 경우는 첫번째 id로 이동
    const targetChapterId = progress?.lastLectureId
      ? progress.lastLectureId.chapterId
      : course?.chapters[0]?.chapterId;
    const targetLectureId = progress?.lastLectureId
      ? progress.lastLectureId.lectureId
      : course?.chapters[0]?.lectures[0]?.lectureId;

    const bookmark =
      progress?.chapters
        .find(({ chapterId }) => targetChapterId === chapterId)
        ?.lectures.find(({ lectureId }) => lectureId === targetLectureId)?.latestTime ?? 0;

    navigate(
      `/${CLASS_ROUTE}/course/${id}?chapterId=${targetChapterId}&lectureId=${targetLectureId}`,
      {
        replace: true,
      },
    );
    return setVodPopup({
      popup: true,
      bookmark: bookmark,
      lectureId: targetLectureId,
    });
  };

  const clickOrder = () => {
    if (!isForSale || createOrderLoading) return;
    if (!token) {
      navigate("/login?pathname=" + "/class/course/" + id);
      return;
    } else if (token?.user_status === "pending") {
      alert("인증 완료(치과의사 및 치과대학생)후 주문가능합니다. 인증완료 후 구매해 주세요");
    } else if (course?.isFree) {
      token?.user_status === "pending"
        ? alert("인증 완료(치과의사 및 치과대학생)후 주문가능합니다. 인증완료 후 구매해 주세요")
        : setCouresPopup("true");
      return;
    } else {
      setCreateOrderLoading(true);
      createOrder.mutate();
      window.scrollTo(0, 0);
      return;
    }
  };

  const createOrder = useMutation(
    () =>
      (publicApi as any)
        .post(`/market/orders`, {
          items: [
            {
              product_id: productId,
              variant_id: variantId,
              quantity: 1,
            },
          ],
        })
        .then((res: any) => res.data.data),
    {
      onSuccess: (data: any) => {
        if (!data.id || !data.grand_total) {
          alert("주문서 생성 실패"); // alert 변경 필요
          return;
        }

        window.location.replace(
          marketUrl(
            `/checkout/${data.id}${defaultCourseId ? `?defaultCourseId=${defaultCourseId}` : ""}`,
          ),
        );
        setCreateOrderLoading(false);
      },
      onError: () => {
        setCreateOrderLoading(false);
        alert("주문서 생성 실패"); // alert 변경 필요
      },
    },
  );

  return (
    <>
      {vodPopup.popup && (
        <LectureVideo
          courseId={id as string}
          chapterId={chapterId as string}
          lectureId={lectureId as string}
          setPopup={setVodPopup}
          bookmark={vodPopup.bookmark}
          progressPercent={progress?.progressPct}
        />
      )}
      {coursePopup && <CoursePopup title={course.title} setPopup={setCouresPopup} />}
      {isFinished && (
        <button
          className="w-full h-full bg-primary-800 flex items-center justify-center rounded-md hover:bg-primary-900 disabled:bg-gray-300"
          disabled
        >
          <span className="text-[#fff] font-medium">종료된 강의입니다</span>
        </button>
      )}
      {!isFinished && progress && !isExpired && (
        <button
          className="w-full h-full bg-primary-800 flex items-center justify-center rounded-md hover:bg-primary-900 disabled:bg-gray-300"
          onClick={clickContinuePlay}
          disabled={dayjs() < dayjs(course?.learnStartAt) || isOfflineClass(course)}
        >
          {!simple && dayjs() < dayjs(course?.learnStartAt) ? (
            <div className="flex items-center text-[#fff] text-lg font-medium">
              {dayjs(course?.learnStartAt).format("MM월 DD일")}부터 수강 가능합니다
            </div>
          ) : (
            <>
              {!simple && !isOfflineClass(course) && (
                <div className="flex items-center text-[#fff] text-lg font-medium mr-5">
                  <i className="ii-calendar-event-outline text-[#fff] mr-3"></i>
                  남은기한: {klassExpiredAt(course, progress)}
                </div>
              )}
              <div className="text-[#fff] font-semibold">
                {!isOfflineClass(course) ? "이어서 보기" : simple ? "신청 완료" : "수강 신청 완료"}
              </div>
            </>
          )}
        </button>
      )}

      {!isFinished && (!progress || isExpired) && (
        <button
          className="w-full h-full bg-primary-800 flex items-center justify-center rounded-md hover:bg-primary-900 disabled:bg-gray-300"
          onClick={clickOrder}
          disabled={!isForSale}
        >
          <span className="text-[#fff] font-medium">수강 신청</span>
        </button>
      )}
    </>
  );
};

export default KlassPurchaseButton;
