const ProsemirrorMenuFontSize = ({
  Open,
  Context,
  NodeType,
  menubarClassName,
  dispatchMetaData,
  cls,
}) => {
  const funtSizes = () => {
    const textFormat = NodeType === "heading" ? "제목" : "본문";
    return [
      [`${textFormat}1`, "1.73em"],
      [`${textFormat}2`, "1.47em"],
      [`${textFormat}3`, "1.27em"],
      [`${textFormat}4`, "reset-style"],
      [`${textFormat}5`, "0.87em"],
    ];
  };

  const handleClick = (e) => {
    const fontSize = e.target.dataset.fontsize;

    if (Context === "fontSize") {
      dispatchMetaData({ type: "setFontSize", fontSize });
    }
  };

  return (
    <div>
      <ul
        className={cls(
          menubarClassName + "__fontSize-box",
          Open ? "!grid" : "hidden"
        )}
      >
        {funtSizes().map((fontSize, index) => {
          return (
            <li
              key={index}
              onClick={handleClick}
              className={menubarClassName + "__fontSize-box__fontSize"}
              style={{ fontSize: fontSize[1] }}
              title={fontSize[0]}
              data-fontsize={fontSize[1]}
            >
              {fontSize[0]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProsemirrorMenuFontSize;
