import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { CLASS_ROUTE } from "App";
import { Link } from "react-router-dom";
import { cls } from "utils/util";
import { createHighlightedTextWrapper } from "utils/searchUtil";
import SearchItemArticle from "component/search/item/SearchItemArticle";
import SearchItemSeminar from "component/search/item/SearchItemSeminar";
import SearchItemRecruit from "component/search/item/SearchItemRecruit";
import SearchItemContent from "component/search/item/SearchItemContent";
import ProductUnit from "component/market/product/ProductUnit";
import CourseCard from "component/klass/CourseCard";
import { useSearchSegemnet } from "hooks/useSearchSegment";

const SearchResultAll = ({ searchResult, marketSearchResult, onSelectUid }) => {
  // ---------- 페이지 결과 총합 ----------
  const totalList = useMemo(() => {
    if (!searchResult) return [];
    return searchResult?.[0]?.results || [];
  }, [searchResult]);

  const [searchParams] = useSearchParams();
  const params = {
    indexUid: searchParams.get("indexUid") || "all",
    q: searchParams.get("q") || "",
    searchTarget: searchParams.get("searchTarget") || "",
    topics: JSON.parse(searchParams.get("topics") || "[]"),
  };

  // ---------- 마켓(market) 관련 로직 ----------
  const marketResultInfo = useMemo(() => {
    if (!marketSearchResult?.length) return;
    return marketSearchResult?.[0] || [];
  }, [marketSearchResult]);

  // ---------- 클래스(classes) 관련 로직 ----------
  const classesResultInfo = useMemo(() => {
    if (!totalList?.length) return;
    const classesIndexUidList = ["classes"];
    return totalList.filter((item) => classesIndexUidList.includes(item.indexUid))[0];
  }, [totalList]);

  // ---------- 게시판(post) 관련 로직 ----------
  const postResultInfo = useMemo(() => {
    if (!totalList?.length) return;
    const postIndexUidList = [
      "dentist",
      "dentist_m",
      "dentist_f",
      "student",
      "student_f",
      "student_m",
    ];
    return totalList.filter((item) => postIndexUidList.includes(item.indexUid))[0];
  }, [totalList]);

  // ---------- QnA(qna) 관련 로직 ----------
  const qnaResultInfo = useMemo(() => {
    if (!totalList?.length) return;
    const qnaIndexUidList = ["qna"];
    return totalList.filter((item) => qnaIndexUidList.includes(item.indexUid))[0];
  }, [totalList]);

  // ---------- 임상포럼(forum) 관련 로직 ----------
  const forumResultInfo = useMemo(() => {
    if (!totalList?.length) return;
    // MARK: 포럼이지만 api 요청시 uid를 content를 이용하고 filter의 board_name을 다르게 보내고 있음.
    const forumIndexUidList = ["content"];
    return (totalList?.filter(
      (item) =>
        forumIndexUidList.includes(item.indexUid) &&
        item.hits.some((hit) => hit.board_name === "임상포럼"),
    ) || [])[0];
  }, [totalList]);

  // ---------- 세미나(seminar) 관련 로직 ----------
  const seminarResultInfo = useMemo(() => {
    if (!totalList?.length) return;
    const seminarIndexUidList = ["seminar"];
    return totalList.filter((item) => seminarIndexUidList.includes(item.indexUid))[0];
  }, [totalList]);

  // ---------- 구인구직(recruit) 관련 로직 ----------
  const recruitResultInfo = useMemo(() => {
    if (!totalList?.length) return;
    const recruitIndexUidList = ["recruit"];
    return totalList.filter((item) => recruitIndexUidList.includes(item.indexUid))[0];
  }, [totalList]);

  // ---------- 콘텐츠(content) 관련 로직 ----------
  const contentResultInfo = useMemo(() => {
    if (!totalList?.length) return;
    const contentIndexUidList = ["content"];
    return (totalList?.filter(
      (item) =>
        contentIndexUidList.includes(item.indexUid) &&
        item.hits.some((hit) => hit.board_name === "컨텐츠"),
    ) || [])[0];
  }, [totalList]);

  return (
    <div className="mt-[20px]">
      <div className="flex flex-col gap-[32px]">
        {marketResultInfo && marketResultInfo?.estimatedTotalHits > 0 && (
          <Market marketResultInfo={marketResultInfo} onSelectUid={onSelectUid} />
        )}
        {classesResultInfo && classesResultInfo?.estimatedTotalHits > 0 && (
          <Classes
            classesResultInfo={classesResultInfo}
            onSelectUid={onSelectUid}
            params={params}
          />
        )}
        {postResultInfo && postResultInfo?.estimatedTotalHits > 0 && (
          <Post postResultInfo={postResultInfo} onSelectUid={onSelectUid} />
        )}
        {qnaResultInfo && qnaResultInfo?.estimatedTotalHits > 0 && (
          <QnA qnaResultInfo={qnaResultInfo} onSelectUid={onSelectUid} />
        )}
        {forumResultInfo && forumResultInfo?.estimatedTotalHits > 0 && (
          <Forum forumResultInfo={forumResultInfo} onSelectUid={onSelectUid} />
        )}
        {seminarResultInfo && seminarResultInfo?.estimatedTotalHits > 0 && (
          <Seminar seminarResultInfo={seminarResultInfo} onSelectUid={onSelectUid} />
        )}
        {recruitResultInfo && recruitResultInfo?.estimatedTotalHits > 0 && (
          <Recruit recruitResultInfo={recruitResultInfo} onSelectUid={onSelectUid} />
        )}
        {contentResultInfo && contentResultInfo?.estimatedTotalHits > 0 && (
          <Content contentResultInfo={contentResultInfo} onSelectUid={onSelectUid} />
        )}
      </div>
    </div>
  );
};
export default SearchResultAll;

const Market = ({ marketResultInfo, onSelectUid }) => {
  const displayedMarketAmount = 3;
  const displayedMarketList = (marketResultInfo?.hits || []).slice(0, displayedMarketAmount);
  const handleClickViewMore = () => {
    onSelectUid("market");
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">마켓</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {marketResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : marketResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div
        className={cls(
          "w-full grid grid-cols-2 gap-x-[12px] gap-y-[24px]",
          "lg:grid-cols-3 lg:gap-x-[20px] lg:gap-y-[20px]",
        )}
      >
        {displayedMarketList.map((product) => (
          <ProductUnit
            key={product.id}
            id={product.id}
            name={product?.meta_title || product?.product?.meta_title || product?.name || ""}
            regularPrice={product.regular_price}
            salePrice={product.sale_price}
            discountPercent={Math.round(
              ((product.regular_price - product.sale_price) / product.regular_price) * 100,
            )}
            thumbnail={product?.images?.[0]?.url || product?.image}
            categoryIds={product?.category_ids || []}
            productCode={product?.product_code}
            brandName={product?.brand?.englishName}
            isSoldOut={product?.is_sold_out}
            isLoadingUnit={false}
            isSearchResult
          />
        ))}
      </div>
    </div>
  );
};

const Classes = ({ classesResultInfo, onSelectUid, params }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  const displayedClassesAmount = 3;
  const displayedClassesList = (classesResultInfo?.hits || []).slice(0, displayedClassesAmount);
  const handleClickViewMore = () => {
    onSelectUid("classes");
  };
  const highlightTextWrapper = createHighlightedTextWrapper(params?.q || "");

  const GridCourseCard = ({ classCourseList, highlightedText }) => {
    if (!classCourseList) return null;
    return (
      <>
        {classCourseList.map((course) => {
          return (
            <Link
              className="block"
              to={`/${CLASS_ROUTE}/course/${course.id}`}
              key={course.id}
              onClick={() => handleSearchItemClick({ title: course.title, courseId: course.id })}
            >
              <CourseCard
                thumbnail={course.thumbnailImageUrl}
                lecturers={course.lecturers}
                title={course.title}
                highlightedText={highlightedText}
              />
            </Link>
          );
        })}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">클래스</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {classesResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : classesResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div className="flex flex-col gap-[32px]">
        {classesResultInfo?.estimatedTotalHits > 0 && (
          <div className="lg:ml-[-10px] grid grid-cols-2 lg:grid-cols-3">
            <GridCourseCard
              classCourseList={displayedClassesList}
              highlightedText={highlightTextWrapper}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Post = ({ postResultInfo, onSelectUid }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  const displayedPostAmount = 3;
  const displayedPostList = (postResultInfo?.hits || []).slice(0, displayedPostAmount);
  const handleClickViewMore = () => {
    onSelectUid("post");
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">게시판</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {postResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : postResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div className="px-[20px] py-[8px] border border-solid border-secondary-200 rounded-lg">
        {displayedPostList?.length > 0 &&
          displayedPostList.map((post, index) => (
            <Link
              className={cls(
                index === displayedPostList.length - 1
                  ? ""
                  : "border-b border-solid border-b-gray-200",
                "block cursor-pointer",
              )}
              to={`/post/article/${post.id.split("_")[1]}`}
              key={post.id}
              onClick={() => handleSearchItemClick({ title: post.title, id: post.id })}
            >
              <SearchItemArticle
                tag={post.topic}
                title={post.title}
                board_name={post.board_name}
                description={post.content}
                thumbnail={post.thumbnail}
                nickname={post.nickname}
                user_level={post.user_level}
                view={post.view}
                like={post.like}
                comment_count={post.comment_count}
                reg_dttm={post.reg_dttm}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};

const QnA = ({ qnaResultInfo, onSelectUid }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  const displayedQnaAmount = 3;
  const displayedQnaList = (qnaResultInfo?.hits || []).slice(0, displayedQnaAmount);
  const handleClickViewMore = () => {
    onSelectUid("qna");
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">Q&A</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {qnaResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : qnaResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div className="px-[20px] py-[8px] border border-solid border-secondary-200 rounded-lg">
        {displayedQnaList?.length > 0 &&
          displayedQnaList.map((qna, index) => (
            <Link
              className={cls(
                index === displayedQnaList.length - 1
                  ? ""
                  : "border-b border-solid border-b-gray-200",
                "block cursor-pointer",
              )}
              to={`/qna/article/${qna.id.includes("qna_comment") ? qna.bid : qna.id.split("_")[1]}`}
              onClick={() => handleSearchItemClick({ title: qna.title, id: qna.id })}
              key={qna.id}
            >
              <SearchItemArticle
                tag={qna.topic}
                title={qna.title}
                description={qna.content}
                thumbnail={qna.thumbnail}
                nickname={qna.nickname}
                user_level={qna.user_level}
                view={qna.view}
                like={qna.like}
                comment_count={qna.comment_count}
                reg_dttm={qna.reg_dttm}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};

const Forum = ({ forumResultInfo, onSelectUid }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  const displayedForumAmount = 3;
  const displayedForumList = (forumResultInfo?.hits || []).slice(0, displayedForumAmount);
  const handleClickViewMore = () => {
    onSelectUid("forum");
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">임상포럼</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {forumResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : forumResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div className="px-[20px] py-[8px] border border-solid border-secondary-200 rounded-lg">
        {displayedForumList?.length > 0 &&
          displayedForumList.map((forum, index) => (
            <Link
              className={cls(
                index === displayedForumList.length - 1
                  ? ""
                  : "border-b border-solid border-b-gray-200",
                "block cursor-pointer",
              )}
              to={`/forum/article/${
                forum.id.includes("forum_comment") ? forum.bid : forum.id.split("_")[2]
              }`}
              onClick={() => handleSearchItemClick({ title: forum.title, id: forum.id })}
              key={forum.id}
            >
              <SearchItemArticle
                tag={forum.topic}
                title={forum.title}
                description={forum.content}
                thumbnail={forum.thumbnail}
                nickname={forum.nickname}
                user_level={forum.user_level}
                view={forum.view}
                like={forum.like}
                comment_count={forum.comment_count}
                reg_dttm={forum.reg_dttm}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};

const Seminar = ({ seminarResultInfo, onSelectUid }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  const displayedSeminarAmount = 3;
  const displayedSeminarList = (seminarResultInfo?.hits || []).slice(0, displayedSeminarAmount);
  const handleClickViewMore = () => {
    onSelectUid("seminar");
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">세미나</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {seminarResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : seminarResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div className="px-[20px] py-[8px] border border-solid border-secondary-200 rounded-lg">
        {displayedSeminarList?.length > 0 &&
          displayedSeminarList.map((seminar, index) => (
            <Link
              className={cls(
                index === displayedSeminarList.length - 1
                  ? ""
                  : "border-b border-solid border-b-gray-200",
                "block cursor-pointer",
              )}
              to={`/management/seminar/${seminar.id.split("_")[1]}`}
              onClick={() => handleSearchItemClick({ title: seminar.title, id: seminar.id })}
              key={seminar.id}
            >
              <SearchItemSeminar
                tag={seminar.topic}
                title={seminar.title}
                location1={seminar.location1}
                location2={seminar.location2}
                end_date={seminar.end_date}
                start_date={seminar.start_date}
                thumbnail={seminar.thumbnail}
                point={seminar.point}
                key={seminar.id}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};

const Recruit = ({ recruitResultInfo, onSelectUid }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  const displayedRecruitAmount = 3;
  const displayedRecruitList = (recruitResultInfo?.hits || []).slice(0, displayedRecruitAmount);
  const handleClickViewMore = () => {
    onSelectUid("recruit");
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">구인구직</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {recruitResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : recruitResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div className="px-[20px] py-[8px] border border-solid border-secondary-200 rounded-lg">
        {displayedRecruitList?.length > 0 &&
          displayedRecruitList.map((recruit, index) => (
            <Link
              className={cls(
                index === displayedRecruitList.length - 1
                  ? ""
                  : "border-b border-solid border-b-gray-200",
                "block cursor-pointer",
              )}
              onClick={() => handleSearchItemClick({ title: recruit.title, id: recruit.id })}
              to={`/recruit/doctor/${recruit.id.split("_")[1]}`}
              key={recruit.id}
            >
              <SearchItemRecruit
                title={recruit.title}
                content={recruit.content}
                location1={recruit.location1}
                location2={recruit.location2}
                hospital_name={recruit.hospital_name}
                view={recruit.view}
                reg_dttm={recruit.reg_dttm}
                key={recruit.id}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};

const Content = ({ contentResultInfo, onSelectUid }) => {
  const displayedContentAmount = 2;
  const displayedContentList = (contentResultInfo?.hits || []).slice(0, displayedContentAmount);
  const handleClickViewMore = () => {
    onSelectUid("content");
  };

  const GridContentCard = ({ contentList }) => {
    return (
      <>
        {contentList.map((content) => {
          const splitId = content.id.split("_");
          const articleNumber = parseInt(splitId[splitId.length - 1]);
          return (
            <SearchItemContent
              bid={articleNumber}
              param=""
              title={content.title}
              tag={content.tag}
              thumbnail={content.thumbnail}
              published_at={content.published_at_unix}
              key={content.id}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[8px]">
          <h4 className="text-[18px] font-bold">콘텐츠</h4>
          <span className="text-[18px] text-secondary-500 font-semibold">
            {contentResultInfo?.estimatedTotalHits > 999
              ? "1000개 이상"
              : contentResultInfo?.estimatedTotalHits}
          </span>
        </div>
        <span
          className="flex items-center text-[15px] text-secondary-500 font-semibold hover:cursor-pointer"
          onClick={handleClickViewMore}
        >
          더보기
          <i className="ii ii-chevron-right ii-weight-600 text-secondary-500"></i>
        </span>
      </div>
      <div className="flex flex-col gap-[32px]">
        {contentResultInfo?.estimatedTotalHits > 0 && (
          <div className="grid grid-cols-2 gap-5">
            <GridContentCard contentList={displayedContentList} />
          </div>
        )}
      </div>
    </div>
  );
};
