import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

export const Code = Extension.Create({
  name: 'code',

  type: 'mark',

  defineSpec() {
    return {
      toDOM() {
        return ['code', {class: MEDISTREAM_SCHEMA_STYLE.marks.code}, 0]
      },
      parseDOM: [{tag: 'code'}],
    }
  },
})
