import LoanPage from "./LoanPage";
import LoanWrite from "./LoanWrite";
import LoanDetail from "./LoanDetail";
import { Route, Routes } from "react-router-dom";
import LoanHana from "./LoanHana";
import LoanKn from "./LoanKN";
import LoanIBK from "./LoanIBK";

const Loan = () => {
  return (
    <Routes>
      <Route path="/*" element={<LoanPage />} />
      <Route path="/hana" element={<LoanHana />} />
      {/* <Route path="/kn" element={<LoanKn />} /> */}
      <Route path="/ibk" element={<LoanIBK />} />
      <Route path="/article/:id" element={<LoanDetail />} />
      <Route path="/write/:cate" element={<LoanWrite />} />
      <Route path="/modify/:bid" element={<LoanWrite />} />
    </Routes>
  );
};

export default Loan;
