import { toggleMark } from "prosemirror-commands";
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

export const Underline = Extension.Create({
  name: 'underline',

  type: 'mark',

  defineSpec() {
    return {
      toDOM: () => ['u', {class: MEDISTREAM_SCHEMA_STYLE.marks.u}, 0],
      parseDOM: [{tag: 'u'}, {style: 'text-decoration=underline'}],
    }
  },

  addCommands() {
    return {
      toggleUnderline: (state, dispatch) => toggleMark(state.schema.marks[this.name])(state, dispatch),
    }
  },
  
  addKeyboardShortcuts() {
    return {
      'Mod-U': this.editor.commands.toggleUnderline,
      'Mod-u': this.editor.commands.toggleUnderline,
    }
  }
})
