import { Badge } from "api/user/api";
import { useUserBadgesQuery } from "api/user/hooks";
import OwnerBadge from "component/OwnerBadge";
import Tooltip from "component/shared/Tooltip";
import { useNavigate } from "react-router-dom";
import { cls } from "utils/util";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Badges = ({
  uid,
  isOwner,
  maxNum = 2,
}: {
  uid: number;
  isOwner: boolean;
  maxNum?: number;
}) => {
  const hasUid = uid && uid > 0 ? true : false;

  const { data: badges, isLoading: isBadgesLoading } = useUserBadgesQuery(uid);

  if (isBadgesLoading) {
    return <></>;
  }

  return (
    <div className="flex gap-1.5 items-center">
      {isOwner && <OwnerBadge uid={uid} showTooltip={false} />}
      {hasUid &&
        badges
          ?.sort((a, b) => {
            if (a.sort === null || b.sort === null) {
              return 0;
            }
            return a.sort - b.sort;
          })
          // type이 동일한 뱃지는 1개만 보여주기 위해 중복 제거
          .filter((badge, index, self) => self.findIndex((b) => b.type === badge.type) === index)
          .slice(0, isOwner ? maxNum - 1 : maxNum)
          .map((badge) => <BadgeView badge={badge} key={badge.id} showTooltip={false} />)}
    </div>
  );
};
export default Badges;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BadgeView = ({
  badge,
  showTooltip = true,
}: {
  badge: Badge;
  showTooltip?: boolean;
}) => {
  const navigate = useNavigate();

  if (!badge) return <></>;
  return (
    <Tooltip text={badge.name} showTooltip={showTooltip}>
      <img
        className={cls(
          "w-[25px] h-[25px]",
          badge.link && "cursor-pointer",
          // badge.link && "hover:scale-110 ",
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          badge.link && navigate(badge.link);
        }}
        key={`${badge.id}`}
        src={badge.imgUrl}
        alt={badge.name}
      />
    </Tooltip>
  );
};
