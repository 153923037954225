import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { api, scrollTopList } from "../../utils/util";
import Loading from "../Loading";
import BoardListUI from "./BoardListUI";

function BoardContent({ current }) {
  const navigate = useNavigate();
  const location = useLocation();
  const article = parseInt(useParams().bid || 0);
  const urlParam = new URLSearchParams(location.state ? location.state : location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q");
  const cate = parseInt(urlParam.get("cate")) || 0;
  const type = urlParam.get("type");
  const sort = urlParam.get("sort");
  const params = { page, q, sort_type: sort };
  const pickParams = { type, page, q, sort_type: sort };
  const [searchKeyword, setSearchKeyword] = useState(q);

  const param = (() => {
    const p = new URLSearchParams();
    page && p.append("page", page);
    cate && p.append("cate", cate);
    q && p.append("q", q);
    return p.toString();
  })();

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["boardList", cate, params, pickParams],
    () =>
      api
        .get(`/board/list/${cate}`, { params: type === "pick" ? pickParams : params })
        .then((res) => res.data),
  );

  const boardScroll = useRef(); // TODO 컴포넌트화하면서 master 코드 그대로 사용안됨

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    const p = new URLSearchParams(location.search);
    const type = p.get("type");
    type && param.append("type", type);
    param.append("page", 1);
    cate && param.append("cate", cate);
    searchKeyword && param.append("q", searchKeyword);
    navigate(current === "board_page" ? `/post?${param}` : `/post/article/${article}?${param}`);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    const p = new URLSearchParams(location.search);
    const type = p.get("type");
    const sort = p.get("sort");

    type && param.append("type", type);
    param.append("page", selected);
    cate && param.append("cate", cate);
    q && param.append("q", q);
    sort && param.append("sort", sort);
    navigate(current === "board_page" ? `/post?${param}` : `/post/article/${article}?${param}`);
    current === "board_detail" ? scrollTopList(boardScroll) : window.scrollTo(0, 0);
  };

  if (pageInfoLoading || !pageInfo) return <Loading />;

  return (
    <>
      {pageInfo && (
        <BoardListUI
          pageInfo={pageInfo}
          board={cate}
          param={param}
          onPageClick={onPageClick}
          scroll={boardScroll}
        />
      )}
      <article className="list__search__form" style={{ marginTop: "47px" }}>
        <form className="search__form__box">
          <input
            type="search"
            placeholder="검색어를 입력해주세요."
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <button
            className="search-btn center"
            onClick={(e) => search(e)}
            style={{ border: "none", outline: "none", cursor: "pointer" }}
          ></button>
        </form>
      </article>
    </>
  );
}

export default BoardContent;
