import { Img, resizedImg } from "../../utils/util";
import { Link } from "react-router-dom";

const TodayContents = ({ recentContents }) => {
  return (
    <div className="w-full p-4 lg:p-0">
      <div className="text-xl mb-3 lg:mb-5 tracking-tighter font-semibold animate-fadedown whitespace-nowrap">
        오늘의 콘텐츠 🔔
      </div>
      {recentContents && (
        <div className="content">
          <Link className="w-full group" to={`/contents/article/${recentContents.bid}`}>
            <div className="rounded-md aspect-contents overflow-hidden">
              <img
                className="w-full h-full aspect-contents group-hover:scale-105 transition-all duration-300"
                src={resizedImg(Img(recentContents.thumbnail), 1000)}
                alt=""
              />
            </div>
            <div className="font-semibold text-base lg:text-xl my-2 w-4/5 truncate group-hover:text-primary-800">
              {recentContents.title}
            </div>
            <div className="hidden lg:block font-medium text-base text-gray-600 truncate">
              {recentContents.text_content}
            </div>
          </Link>
          <div className="flex mt-3">
            {recentContents.tag
              .split(",")
              .slice(0, 3)
              .map((keyword, idx) => {
                return (
                  <Link
                    to={`/contents/search?tag=${keyword}`}
                    className="text-tiny lg:text-base bg-gray-100 text-gray-600 rounded-sm px-2 py-1 mr-3 lg:mt-auto cursor-pointer hover:bg-primary-100 hover:!text-primary-800"
                    key={idx}
                  >
                    {keyword}
                  </Link>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TodayContents;
