export class CommandManager {
  /**
   * @param {import('./editor').IntegrationEditor} editor 
   */
  constructor(editor) {
    this.editor = editor
    this.unprocessedCommands = this.editor.extensionManager.commands
  }

  get commands() {
    return this.unprocessedCommands
  }
}
